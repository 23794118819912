import { API_TEMPLATE_URL_GO } from "constants/config";
import { createUrl } from "../../helpers/url";

const useGetExportEmployees = (entity_id: number) => {
  const query: { entity_id: string } = { entity_id: entity_id.toString() };

  const url = createUrl({
    path: API_TEMPLATE_URL_GO + "/employee/employees/GetExcelEmployees",
    query: query,
  });

  return fetch(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("ERAccessToken")}`,
    }
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.blob();
  });
};

export default useGetExportEmployees; 
