import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { AttributeOption } from "types/employee";
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';

type SelectType = {
  Id: string;
  values: AttributeOption[];
  value: any;
  label?: string;
  onChange: (e) => void;
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));


export default function NativeSelectDemo(props: SelectType) {
  return (
      <FormControl fullWidth size='small' variant="standard">
        <NativeSelect size='small'
          id={props.Id}
          onChange={props.onChange}
          value={props.value}
          input={<BootstrapInput />}
        >
          <option value={""}></option>
          {props.values.map((option, index) => {
            return <option id={`${props.Id}Option_${option.id}`} key={option.id} value={option.value}>{option.value}</option>
          })}
        </NativeSelect>
      </FormControl>
  );
}