import http from "api/https";
import { createUrl } from "../helpers/url";

export const getEntitySettingByEntity = (idEntity: number): Promise<any> => {
  const query: { idEntity: string; } = { idEntity: idEntity.toString() };
  if (http.isAPIGO){
    const url = createUrl({
      path: "subscription/entity_settings/GetOneByIdEntity",
      query: query,
    });
    return http.getGO(url);
  }
  else {
    const url = createUrl({
      path: "/entity_settings/GetOneByIdEntity",
      query: query,
    });
    return http.get(url);
  }
};
