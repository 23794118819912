import http from "api/https";
import { Question } from "types/templates";

export const addSurvey = (project_id: number, survey: string, survey_name: string, locales: string[], questions: Question[]): Promise<any> => {
  let data = {
    id: 0,
    project_id: project_id,
    questionary_survey: survey,
    name: survey_name,
    locales: locales,
    questionJson: questions
  }
  if(http.isAPIGO){
    return http.postGO('/sm/sm_survey', data)
  }else{
    return http.post('/sm_survey', data)
  }
};
