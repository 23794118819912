import { surveyLocalization } from "survey-core"

export const tamilStrings = {
  pagePrevText: "முந்தைய",
  pageNextText: "அடுத்தது",
  completeText: "சமர்ப்பிக்கவும்",
  otherItemText: "மற்றவை (விவரிக்க)",
  noneItemText: "மேலே எதுவும் இல்லை",
  selectAllItemText: "அனைத்தையும் தெரிவுசெய்",
  progressText: "பக்கம் {0} இல் {1}",
  indexText: "{0} இல் {1}",
  panelDynamicProgressText: "{0} இல் {1}",
  questionsProgressText: "பதிலளிக்கப்பட்டது {0}/{1} கேள்விகளுக்கு",
  emptySurvey: "கணக்கெடுப்பில் தெரியும் பக்கங்கள் அல்லது கேள்விகள் இல்லை",
  completingSurvey: "கணக்கெடுப்பை முடித்ததற்கு நன்றி",
  completingSurveyBefore: "நீங்கள் தயாராகிவிட்டீர்கள்! நீங்கள் ஏற்கனவே இந்தக் கருத்துக்கணிப்பை முடித்துவிட்டதாக எங்கள் பதிவுகள் காட்டுகின்றன",
  loadingSurvey: "நாங்கள் கணக்கெடுப்பை ஏற்றும் வரை காத்திருக்கவும்",
  placeholder: "தேர்ந்தெடு",
  ratingOptionsCaption: "தேர்ந்தெடு",
  requiredError: "பதில் தேவை",
  booleanCheckedLabel: "ஆம்",
  booleanUncheckedLabel: "இல்லை",
  questionTitlePatternText: "கேள்வி தலைப்பு",
};

surveyLocalization.locales["ta"] = tamilStrings
surveyLocalization.localeNames["ta"] = "Tamil / தமிழ்"
