import { TDasbboardFilter, TDashboardGlobalFilter } from "types/dashboard";

export const getUnitSegmentsName = (
    filter: TDasbboardFilter,
    segment: string,
    fullList: TDashboardGlobalFilter[],
    isUnit?: boolean
) => {
    if (filter.unit_or_attr?.name === "Unit" || isUnit) {
        const fullFilter = fullList.find((el) => el?.name === "Unit");
        if (fullFilter) {
            const findedsegment = fullFilter.values.find((el) => el.id.toString() === segment);
            {
                if (findedsegment) {
                    return findedsegment?.name || "";
                }
                return null;
            }
        }
        return null;
    }

    return segment;
};

export const getUnitSegmentName = (segment: string, fullList: TDashboardGlobalFilter[]) => {
    const fullFilter = fullList.find((el) => el.name === "Unit");
    if (fullFilter) {
        const findedsegment = fullFilter.values.find((el) => el.id.toString() === segment);
        {
            if (findedsegment) {
                return findedsegment?.name || "";
            }
            return null;
        }
    }
    return null;
};
