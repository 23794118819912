import { FC } from "react";

interface RemoveIconProps {
  color?: string;
}

const RemoveIcon: FC<RemoveIconProps> = ({ color = "#1477F8" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 16 17"
      fill="none"
      style={{
        marginRight: 2,
      }}
    >
      <path
        d="M8.83287 6.22749C8.83287 5.01191 7.8483 4.02734 6.63272 4.02734C5.41714 4.02734 4.43257 5.01191 4.43257 6.22749C4.43257 7.44307 5.41714 8.42764 6.63272 8.42764C7.8483 8.42764 8.83287 7.44307 8.83287 6.22749ZM10.483 7.32757V8.42764H13.7832V7.32757H10.483ZM2.23242 11.7279V12.8279H11.033V11.7279C11.033 10.2648 8.10132 9.52772 6.63272 9.52772C5.16412 9.52772 2.23242 10.2648 2.23242 11.7279Z"
        fill={color}
      />
    </svg>
  );
};

export default RemoveIcon;
