import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

import { AFTER_HEADER_MENU } from "../../constants/menu";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import StorePermission from "components/workspaces-sidebar/StorePermission";

type HeaderMenuProps = {};

const HeaderMenu: FC<HeaderMenuProps> = observer(() => {
  const location = useLocation()

  return (
    <HeaderMenuWrapper>
      {StorePermission.hasAnyEntityPermission && AFTER_HEADER_MENU.map((menuItem) => {
        let disabled = false;
        let hidden = false;
        let customPath = menuItem.path
        if (StoreLayout.currentEntityId === 0) {
          if (
            menuItem.path === "/entityPermissions" ||
            menuItem.path === "/employees" ||
            menuItem.path === "/dashboard" ||
            menuItem.path === "/templates"
          )
            return null;
        } else {
          if (menuItem.path === "/entities" || menuItem.path === "/workspacePermissions") return null;
        }
        if (menuItem.path === "/projects" && StoreLayout.currentEntityId === 0) return null;
        if (menuItem.path === "/projects") {
          hidden = !StorePermission.features.surveys.hasFeature;
          disabled = !StorePermission.entityPermissions.viewSurveySettings.hasPermission
        } else if (menuItem.path === "/templates") {
          hidden = !StorePermission.features.all_templates.hasFeature;
          disabled = !StorePermission.entityPermissions.createAndEditSurvey.hasPermission;
        } else if (menuItem.path === "/employees") {
          hidden = !StorePermission.entityPermissions.viewEmployee.hasPermission;
        } else if (menuItem.path === "/entityPermissions") {
          hidden = (!StorePermission.entityPermissions.viewEntityPermissions.hasPermission) && (!StorePermission.entityPermissions.addAndRemoveEntityUsers.hasPermission);
          if(!StorePermission.entityPermissions.addAndRemoveEntityUsers.hasPermission){
            customPath = "/entityPermissions?nav=role"
          }
        } else if (menuItem.path === "/workspacePermissions") {
          hidden = (!StorePermission.workspacePermissions.viewWorkspacePermissions.hasPermission) && (!StorePermission.workspacePermissions.addAndRemoveWorkspaceUsers.hasPermission);
          if(!StorePermission.workspacePermissions.addAndRemoveWorkspaceUsers.hasPermission){
            customPath = "/workspacePermissions?nav=role"
          }
        }
        if (hidden) return;

        return (
          <ItemMenu $disabled={disabled} key={menuItem.name} $active={location.pathname.includes(menuItem.path)}>
            <Link id={"MenuItem" + menuItem.path} to={customPath}>{menuItem.name}</Link>
          </ItemMenu>
        );
      })}
    </HeaderMenuWrapper>
  );
});

export default HeaderMenu;

const HeaderMenuWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--colorNeutralBackground1);
  padding: 0 80px;
`;

const ItemMenu = styled.li<{ $active?: boolean, $disabled?: boolean }>`
  list-style-type: none;
  height: 56px;

  a {
    cursor: pointer;
    display: flex;
    align-items: center;

    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    pointer-events: ${(props) => props.$disabled && `none`};
    color: ${(props) => props.$disabled ? "var(--colorNeutralForeground5)" : props.$active ? "var(--colorBrandForeground1)" : "var(--colorPaletteVioletBackground1)"};
    width: fit-content;
    border-bottom: ${(props) => props.$active && `4px solid var(--colorBrandForeground1)`};
    height: 100%;
    padding: ${(props) => (props.$active ? "4px" : "0px")} 16px 0;

    &:hover {
      text-decoration: none !important;
    }
  }
`;
