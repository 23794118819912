import http from "api/https";
import { AxiosResponse } from "axios";

class UnexpectedApiError extends Error {
  constructor(response: AxiosResponse<any, any>) {
    super();
  }
}

export const checkUniqueQuestionaireTemplateName = async (
  name: string,
  idTemplate: number,
  entityId: number,
): Promise<any> => {
  const data = {
    name: name,
    entity_id: entityId,
    template_id: idTemplate,
  };
  if (http.isAPIGO) {
    const response = await http.postGO("/template/check_unique_questionaire_templateName", data);
    if ([200, 201].includes(response.status)) {
      return response.data;
    }
    throw new UnexpectedApiError(response);
  } else {
    const response = await http.post(
      "/templ_template_questionary/CheckUniqueQuestionaireTemplateName",
      data,
    );
    if ([200, 201].includes(response.status)) {
      return response.data;
    }
    throw new UnexpectedApiError(response);
  }
};
