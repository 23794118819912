import { observer } from "mobx-react";
import { TTrendsQuestion } from "types/dashboard";
import { FC, useEffect, useState } from "react";
import { Button, Dialog, DialogContent, FormControlLabel, Radio } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import ClearIcon from "@mui/icons-material/Clear";

type SelectQuestionPopupProps = {
  cardIndex: number;
  selectedQuestionsList: number[];
  localQuestionsList: TTrendsQuestion[];
};

const SelectQuestionPopup: FC<SelectQuestionPopupProps> = observer((props) => {
  const [localSelectedQuestion, setLocalSelectedQuestion] = useState<number>(null);

  useEffect(() => {
    if (props.selectedQuestionsList) setLocalSelectedQuestion(props.selectedQuestionsList[0]);
  }, [props.selectedQuestionsList]);

  const changeQuestionChecked = (questionId: number) => {
    if (localSelectedQuestion === questionId) {
      setLocalSelectedQuestion(null);
    } else setLocalSelectedQuestion(questionId);
  };

  const returnSelectedQuestionsCount = (dimension: string) => {
    let count = 0;

    const currDimension = props.localQuestionsList.filter((el) => el.dimension === dimension)[0];

    if (currDimension) {
      currDimension.list.forEach((el) => {
        if (localSelectedQuestion === el?.id) {
          count++;
        } else return;
      });
      return count > 0 ? `(${count})` : "";
    } else return "";
  };

  return (
    <StyledDialog open={store.isOpenSelectQuestionPopup}>
      <CloseBtnWrapper>
        <CloseButton onClick={() => store.setData(false, "isOpenSelectQuestionPopup")}>
          <ClearIcon />
        </CloseButton>
      </CloseBtnWrapper>
      <DialogTitleWrapper>
        <DialogTitle>Select question</DialogTitle>
      </DialogTitleWrapper>
      <StyledDialogContent>
        <Content>
          <DimensionsListWrapper>
            <DimensionsList>
              {props.localQuestionsList.map((el, index) => (
                <DimensionItem
                  $active={el?.dimension === store.activeSelectQuestionListDimension?.dimension}
                  key={el.dimension}
                  onClick={() => store.setData(el, "activeSelectQuestionListDimension")}
                >
                  {el.dimension} {returnSelectedQuestionsCount(el.dimension)}
                </DimensionItem>
              ))}
            </DimensionsList>
          </DimensionsListWrapper>

          <QuestionsList>
            <QuestionListTitleWrapper>
              <QuestionListTitle>
                {store.activeSelectQuestionListDimension &&
                  store.activeSelectQuestionListDimension?.dimension}
              </QuestionListTitle>
              <CounterQuestion>
                {(store.activeSelectQuestionListDimension &&
                  store.activeSelectQuestionListDimension?.list.length) ||
                  "0"}{" "}
                {store.activeSelectQuestionListDimension &&
                  store.activeSelectQuestionListDimension?.list.length === 1
                  ? "question"
                  : "questions"}
              </CounterQuestion>
            </QuestionListTitleWrapper>
            {store.activeSelectQuestionListDimension &&
              store.activeSelectQuestionListDimension.list.map((question) => (
                <QuestionWrapper key={question.id}>
                  <StyledRadio
                    checked={localSelectedQuestion === question?.id}
                    control={<Radio />}
                    label={<QuestionText>{question.title}</QuestionText>}
                    onChange={() => changeQuestionChecked(question?.id)}
                    value={question.id}
                  />
                </QuestionWrapper>
              ))}
          </QuestionsList>
        </Content>
      </StyledDialogContent>
      <ButtonWrapper>
        <SaveButton
          onClick={() => {
            store.changeChartCardSettings(props.cardIndex, "selectedQuestionsList", [
              localSelectedQuestion,
            ]);
            store.setData(false, "isOpenSelectQuestionPopup");
          }}
          variant="contained"
          type="submit"
          disabled={!localSelectedQuestion}
        >
          Save changes
        </SaveButton>
      </ButtonWrapper>
    </StyledDialog>
  );
});

export default SelectQuestionPopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: none !important;
    width: 740px !important;
    overflow: hidden !important;
    border-radius: 10px !important;
  }

  .MuiDialogContent-root {
    padding: 32px 0px 32px 0px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: var(--colorNeutralForeground8);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const DialogTitleWrapper = styled.div`
  box-shadow: 0px 4px 4px 0px var(--colorNeutralBackground3);
  padding: 0px 40px 24px 40px;
`;

const DialogTitle = styled.h1`
  font-family: Roboto;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 16px 40px;
  box-shadow: 0px -4px 4px 0px var(--colorNeutralBackground3);
`;

const SaveButton = styled(Button)`
  height: 36px;
  width: 100%;
  text-transform: none !important;
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 24px 24px 0px 24px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;

  svg {
    width: 18px;
    height: 18px;
    color: var(--colorBrandForeground1);
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const DimensionsListWrapper = styled.div`
  height: max-content;
  width: 100%;
  max-width: 189px;
  border-radius: 0px 16px 16px 0px;
  box-shadow: 0px 2px 5px 0px var(--colorNeutralBackground3);
  position: sticky;
  top: 0px;
`;

const DimensionsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 28px 0px;
  border-radius: 0px 16px 16px 0px;
  background-color: var(--colorNeutralBackground1);
`;

const DimensionItem = styled.span<{ $active: boolean }>`
  width: 100%;
  padding: ${(props) => (props.$active ? "8px 26px" : "8px 26px 8px 30px")};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: ${(props) =>
    props.$active ? "var(--colorBrandForeground1)" : "var(--colorNeutralForeground1)"};
  ${(props) => props.$active && "border-left: 4px solid var(--colorBrandForeground1)"};
  overflow-wrap: anywhere;
  cursor: pointer;
  background-color: var(--colorNeutralBackground1);
  margin: 4px 0px;
`;

const QuestionsList = styled.div`
  width: calc(100% - 189px);
  display: flex;
  flex-direction: column;
  padding: 0px 42px;
`;

const QuestionListTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const QuestionListTitle = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  color: var(--colorNeutralForeground1);
`;

const CounterQuestion = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
`;

const QuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 18px;
  border-radius: 8px;
  margin-top: 8px;
  border: 1px solid var(--colorNeutralBackground3);
  align-items: start;
`;

const QuestionText = styled.p`
  width: 100%;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
`;

const StyledRadio = styled(FormControlLabel)`
  width: 100% !important;
  align-items: flex-start !important;
  margin: 0px !important;

  span {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    padding: 0px !important;

    p {
      margin: 0px 0px 0px 12px !important;
    }
  }
`;
