
const PreviewIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none">
      <path d="M13.9416 5.225C12.5281 2.00063 9.869 0 7 0C4.131 0 1.47193 2.00063 0.058419 5.225C0.0198881 5.31174 0 5.40536 0 5.5C0 5.59464 0.0198881 5.68826 0.058419 5.775C1.47193 8.99937 4.131 11 7 11C9.869 11 12.5281 8.99937 13.9416 5.775C13.9801 5.68826 14 5.59464 14 5.5C14 5.40536 13.9801 5.31174 13.9416 5.225ZM7 9.625C4.77478 9.625 2.6825 8.05062 1.47193 5.5C2.6825 2.94937 4.77478 1.375 7 1.375C9.22522 1.375 11.3175 2.94937 12.5281 5.5C11.3175 8.05062 9.22522 9.625 7 9.625ZM7 2.75C6.4464 2.75 5.90524 2.91128 5.44495 3.21346C4.98465 3.51563 4.62589 3.94512 4.41404 4.44762C4.20219 4.95012 4.14676 5.50305 4.25476 6.0365C4.36276 6.56995 4.62934 7.05995 5.02079 7.44454C5.41224 7.82914 5.91098 8.09105 6.45394 8.19716C6.99689 8.30327 7.55969 8.24881 8.07114 8.04067C8.5826 7.83253 9.01974 7.48005 9.3273 7.02782C9.63486 6.57558 9.79902 6.0439 9.79902 5.5C9.79902 4.77065 9.50413 4.07118 8.97921 3.55546C8.45429 3.03973 7.74235 2.75 7 2.75ZM7 6.875C6.7232 6.875 6.45262 6.79436 6.22247 6.64327C5.99232 6.49218 5.81294 6.27744 5.70702 6.02619C5.60109 5.77494 5.57338 5.49847 5.62738 5.23175C5.68138 4.96503 5.81467 4.72003 6.0104 4.52773C6.20612 4.33543 6.45549 4.20447 6.72697 4.15142C6.99845 4.09837 7.27984 4.1256 7.53557 4.22967C7.7913 4.33374 8.00987 4.50997 8.16365 4.73609C8.31743 4.96221 8.39951 5.22805 8.39951 5.5C8.39951 5.86467 8.25206 6.21441 7.9896 6.47227C7.72714 6.73013 7.37117 6.875 7 6.875Z" fill="#1477F8" />
    </svg>
  );
};

export default PreviewIcon;
