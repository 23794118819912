import http from "api/https";

export const verifyResetPassword = (email_code: string) => {
    return http.postUnauthenticatedGO(
        "/permission/ResetPassword/Verify",
        {
            email_code,
        },
        {}
    )
}