import { createUrl } from "helpers/url";
import { AxiosResponse } from "axios";
import http from "api/https";

class UnexpectedApiError extends Error {
  constructor(response: AxiosResponse<any, any>) {
    super();
  }
}

export const useLogoutImpersonated = async (regionID: number) => {
  const query: { regionID: number } = { regionID: regionID };
  let response: AxiosResponse<any, any>;
  const url = createUrl({
    path: "/permission/LogoutImpersonationClient",
  });
  response = await http.postGO(url, query);
  if ([200, 201].includes(response.status)) {
    return response.data;
  }
  throw new UnexpectedApiError(response);
};
