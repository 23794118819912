import { SurveyTagsType } from "api/go/useGetSurveyTags";
import http from "api/https";
import { AxiosResponse } from "axios";

class UnexpectedApiError extends Error {
  constructor(response: AxiosResponse<any, any>) {
    super();
  }
}

export type templaceCommsProps = {
  id: number;
  name: string;
  tags?: SurveyTagsType[];
  tagNames?: string[];
};

export const updateTemplateComms = async (data: templaceCommsProps): Promise<any> => {
  const obj = {
    name: data.name,
    id: data.id,
    tags: data.tags,
    tagNames: data.tagNames,
  };
  if (http.isAPIGO) {
    const response = await http.postGO("/template/CustomUpdateTemplate", obj);
    if ([200, 201].includes(response.status)) {
      return response.data;
    }
    throw new UnexpectedApiError(response);
  } else {
    const response = await http.post("/templ_template_comms/CustomUpdateTemplate", obj);
    if ([200, 201].includes(response.status)) {
      return response.data;
    }
    throw new UnexpectedApiError(response);
  }
};
