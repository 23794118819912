import { makeAutoObservable } from "mobx";
import { saveStructure } from "api/go/useSaveStructure";
import { getStructure } from "api/go/useGetStructure";
import { getCountEmployeeInOrganization } from "api/go/useGetCountEmployeeInOrganization";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { NestedStructure } from "./TreeView";
import MainStore from "MainStore";
import { getUnitHeads } from "api/go/useGetUnitHeads";
import { OrganizationLevel } from "types/employee";
import { levelStructure } from "api/go/useLevelStructure";
import useGetExcelStructure from "api/go/useGetExcelStructure";
import { downloadFile } from "utils/downloadFile";
import { GetEmployeesByEntity, GetEmployeesByEntityType } from "api/go/useGetEmployeesByEntity";

class Store {
  data: NestedStructure = {
    id: 1,
    name: StoreLayout.currentEntityName,
    isNew: true,
    edit: false,
    details: "",
    description: "",
    virtual_id: null,
    parent_id: null,
    virtual_parent_id: null,
    idTypeName: null,
    children: [],
    level: 0,
    emails: [],
  };
  // TODO need to find another option for the visibility of the button add level
  expandedIds: string[] = ['1'];
  emailCount = 0;
  unitHeadsByLevel: OrganizationLevel[] = [];
  searchedValue: string = "";
  resultSearchValue: string = "";
  isEmail = false;
  openPanelRemove = false;
  removePanelId = 0;
  removePanelLevel = 0;
  removePanelName = "";
  removePanelCountEmployees = 0;
  isFormChanged: boolean = false;
  levelStructure: boolean = true;
  optionsEmployees: GetEmployeesByEntityType[] = [];
  loadingEmployees: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  getUnitHeads = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getUnitHeads(StoreLayout.currentEntityId, this.resultSearchValue);
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.unitHeadsByLevel = response.data;
        this.changeValue("unitHeadsByLevel", response.data);
      } else throw new Error();
    } catch {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  onSearchClicked = () => {
    this.resultSearchValue = this.searchedValue;
    this.searchedValue = "";
    this.getUnitHeads();
  };

  changeValue = (field: string, value: string) => {
    this[field] = value;
  };

  setStructureData = (data: NestedStructure) => {
    this.data = data;
    let ids = [];
    const getId = (nested: NestedStructure) => {
      ids.push(String(nested.id));
      nested.children.forEach((y) => {
        getId(y);
      });
    };
    getId(data);
    this.expandedIds = ids;
  };

  getStructure = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getStructure(StoreLayout.currentEntityId);
      if ((response.status === 200 || response.status === 201)) {
        if (response?.data !== null) { 
          this.setStructureData(response.data);
        }
      } else if (response.status === 204) {
      } else throw new Error();
    } catch {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  doLoad = () => {
    if (!StoreLayout.currentEntityId) return;
    this.getStructure();
    this.getUnitHeads();
  };

  clearStore = () => {
    this.data = {
      id: 1,
      name: StoreLayout.currentEntityName,
      isNew: true,
      edit: false,
      children: [],
      level: 0,
      emails: [],
    };
    this.expandedIds = [];
    this.emailCount = 0;
    this.unitHeadsByLevel = [];
    this.searchedValue = "";
    this.resultSearchValue = "";
    this.isEmail = false;
    this.openPanelRemove = false;
    this.removePanelId = 0;
    this.removePanelLevel = 0;
    this.removePanelName = "";
    this.removePanelCountEmployees = 0;
    this.isFormChanged = false;
  };

  formChanged = (flag: boolean) => {
    if (flag !== this.isFormChanged) {
      this.isFormChanged = flag;
    }
  };

  checkNamesInData = (data = this.data) => {
    return (
      data.name && (!data.children || data.children.every((child) => this.checkNamesInData(child)))
    );
  };

  changeField = (id: number, field: string, value: string | boolean) => {
    if (field === "name") this.formChanged(true);

    const updateField = (nodes: NestedStructure) => {
      if (nodes.id === id) {
        nodes[field] = value;
        return;
      }
      if (Array.isArray(nodes.children)) {
        nodes.children.forEach(updateField);
      }
    };
    updateField(this.data);
  };

  getMaxId = () => {
    let maxId = 0;
    const updateMaxId = (node: NestedStructure) => {
      if (node.id >= maxId) {
        maxId = node.id + 1;
      }
      if (Array.isArray(node.children)) {
        node.children.forEach(updateMaxId);
      }
    };
    updateMaxId(this.data);
    return maxId;
  };

  addNode = (id: number) => {
    this.formChanged(true);
    const maxId = this.getMaxId();
    const addNewChild = (node: NestedStructure) => {
      if (node.id === id) {
        node.children.push({
          id: maxId,
          name: "",
          emails: [],
          isNew: true,
          edit: false,
          level: node.level + 1,
          children: [],
        });
      } else {
        node.children.forEach(addNewChild);
      }
    };
    addNewChild(this.data);
    this.expandedIds.push(String(maxId));
  };

  private removeNodeById = (node: NestedStructure, id: number): boolean => {
    node.children = node.children.filter((child) => this.removeNodeById(child, id));
    return node.id !== id;
  };

  private updateChildren = (
    children: NestedStructure[],
    id: number,
    newParentId: number,
    newVirtualParentId: number,
    level: number,
  ): NestedStructure[] => {
    const updatedChildren: NestedStructure[] = [];
    for (const child of children) {
      if (child.id === id) {
        child.children.forEach((c) => {
          this.promoteChildren(child.children, newParentId, newVirtualParentId, level);
          updatedChildren.push(c);
        });
      } else {
        child.children = this.updateChildren(
          child.children,
          id,
          child.id,
          child.virtual_id,
          child.level,
        );
        updatedChildren.push(child);
      }
    }
    return updatedChildren;
  };

  private promoteChildren = (
    children: NestedStructure[],
    newParentId: number | null,
    newVirtualParentId: number,
    parentLevel: number,
  ) => {
    children.forEach((child) => {
      child.parent_id = newParentId;
      child.level = parentLevel + 1;
      child.virtual_parent_id = newVirtualParentId;
      if (child.children.length > 0) {
        this.promoteChildren(child.children, child.id, child.virtual_parent_id, child.level);
      }
    });
  };

  removePanelOpen = (idNode: number, level: number, name: string, isNew: boolean) => {
    this.openPanelRemove = true;
    this.removePanelId = idNode;
    this.removePanelLevel = level;
    this.removePanelName = name;
    if (!isNew) {
      this.getCountEmployees(idNode);
    }
  };

  getCountEmployees = async (idNode: number) => {
    try {
      MainStore.changeLoader(true);
      const response = await getCountEmployeeInOrganization(idNode);
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.changeValue("removePanelCountEmployees", response.data);
      } else throw new Error();
    } catch {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  changeRemovePanel = (flag: boolean) => {
    this.openPanelRemove = flag;
    this.removePanelLevel = 0;
    this.removePanelId = 0;
    this.removePanelCountEmployees = 0;
  };

  removeNode = (id: number) => {
    this.formChanged(true);
    this.changeRemovePanel(false);
    this.removePanelLevel = 0;
    this.removePanelId = 0;
    this.removePanelCountEmployees = 0;
    // this.data.children = this.data.children.filter((child) => this.removeNodeById(child, id));
    this.data.children = this.updateChildren(this.data.children, id, null, null, 0);
  };

  isValidUnitNames = () => {
    let blankUnits = [];
    const getId = (nested: NestedStructure) => {
      if (nested.name === "" || nested.name === null) {
        blankUnits.push(String(nested.id));
      }
      nested.children.forEach((y) => {
        getId(y);
      });
    };
    getId(this.data);
    return blankUnits.length === 0;
  };
  // All that remains is to distribute the IDs in the correct format.
  createStructureData = () => {
    const newData = [
      {
        id: this.data.id,
        name: this.data.name,
        emails: this.data.emails,
        details: this.data?.details || null,
        description: this.data?.description || null,
        virtual_id: this.data.isNew ? this.data.id : this.data?.virtual_id || null,
        parent_id: this.data?.parent_id || null,
        virtual_parent_id: this.data?.virtual_parent_id || null,
        level: this.data.level,
        edit: this.data.edit,
        isNew: this.data.isNew,
        idTypeName: this.data?.idTypeName || null,
      },
    ];
    const createData = (nodes: NestedStructure) => {
      nodes.children.forEach((item) => {
        const newItem = {
          id: item.isNew ? 0 : item.id,
          name: item.name,
          emails: item.emails,
          details: item?.details || null,
          description: item?.description || null,
          virtual_id: item.isNew ? item.id : item?.virtual_id || null,
          parent_id: nodes.isNew ? null : item?.parent_id || nodes.id,
          virtual_parent_id: nodes.isNew ? nodes.id : item?.virtual_parent_id || null,
          level: item.level,
          edit: item.edit,
          isNew: item.isNew,
          idTypeName: item?.idTypeName || null,
        };
        newData.push(newItem);
        createData(item);
      });
    };
    createData(this.data);
    return newData;
  };

  SaveStructure = async () => {
    if (!this.isValidUnitNames()) {
      MainStore.setSnackbar("Unit names cannot be null", "error");
      return;
    }

    const newData = this.createStructureData();

    this.formChanged(false);
    try {
      MainStore.changeLoader(true);
      const response = await saveStructure(newData, StoreLayout.currentEntityId);
      if (!response) {
        throw new Error();
      }
      this.clearStore();
      MainStore.setSnackbar("Organizational structure successfully saved");
      await this.getUnitHeads();
      await this.getLevelStructure();
      await this.getStructure();
      MainStore.changeLoader(false);
      this.formChanged(false);
    } catch {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getLevelStructure = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await levelStructure(StoreLayout.currentEntityId);
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.levelStructure = response.data;
        MainStore.changeLoader(false);
      } else throw new Error();
    } catch {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  deleteEmail = (id: number, mail: string) => {
    this.formChanged(true);
    const getData = (nodes: NestedStructure) => {
      if (nodes.id === id) {
        nodes.emails = nodes.emails.filter((obj) => obj !== mail);
        return;
      }
      if (Array.isArray(nodes.children)) {
        nodes.children.forEach((node) => getData(node));
      }
    };
    getData(this.data);
  };

  addEmail = (id: number, emails: string) => {
    this.formChanged(true);
    const getData = (nodes: NestedStructure) => {
      const lowerCaseEmail = emails.toLowerCase();
      const emailReg = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
      this.isEmail = emailReg.test(lowerCaseEmail);
      if (nodes.id === id) {
        if (nodes.emails == null) {
          nodes.emails = [emails];
        } else if (this.isEmail && !nodes.emails.includes(lowerCaseEmail)) {
          nodes.emails.push(lowerCaseEmail);
        }
        return;
      }
      if (Array.isArray(nodes.children)) {
        nodes.children.map((node) => getData(node));
      }
    };
    getData(this.data);
    this.emailCount = this.emailCount + 1;
  };

  saveExcelStructure = async () => {
    try {
      MainStore.changeLoader(true);
      const data = await useGetExcelStructure(StoreLayout.currentEntityId);
      downloadFile(
        data,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        `Organization structure ${StoreLayout.currentEntityName}.xlsx`,
      );
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  changeOptions = (options: GetEmployeesByEntityType[]) => {
    this.optionsEmployees = options;
  };

  changeLoading = (loading: boolean) => {
    this.loadingEmployees = loading;
  };

  loadEmployees = async (searchValue: string) => {
    try {
      const response = await GetEmployeesByEntity(StoreLayout.currentEntityId, searchValue);
      this.changeOptions([...response]);
    } catch {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      this.changeLoading(false);
    }
  };
}

const store = new Store();
export default store;
