import { FC } from "react";
import { Error403Screen } from "features/errors";

type Error403PageProps = {};

const Error403Page: FC<Error403PageProps> = () => {
  return <Error403Screen />;
};

export default Error403Page;
