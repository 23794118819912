import { FC, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { Edit } from "@mui/icons-material";
import Delete from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import { FormControl, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import CustomButton from "components/button/Button";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { MilestoneDaysSurveyProject } from "types/entityAttribute";
import { QuantityInput } from "components/number-input-counter/NumberInputCounter";
import storeSurveyList from "features/Survey/SurveyList/store";
import store from "./store";

type SurveyMillstoneProps = {};

const SurveyMillstone: FC<SurveyMillstoneProps> = observer(() => {
  const [changeDay, setChangeDay] = useState<{ index: number; value: number } | null>(null);

  const extractNumbers = (str: string) => {
    const daySurvey = str.match(/(\d+)\s*(\((\d+)\))+/);
    const doublesSurvey = str.match(/\((\d+)\)/g);
    if (doublesSurvey && daySurvey) {
      return {
        day: parseInt(daySurvey[1]),
        doubles: doublesSurvey?.map((match) => parseInt(match.match(/\d+/)[0])),
      };
    }
    return {
      day: null,
      doubles: [],
    };
  };

  const updatedMillstoneDays = store.millstoneDays?.map((el) => {
    const foundItem = storeSurveyList.data?.find((item) => item.id === el.id);
    if (foundItem) {
      const obj = extractNumbers(foundItem.name);
      return {
        ...el,
        double: obj.day === el.days,
        doubles: obj.doubles,
      };
    }
    return el;
  });

  const doubleDayVerification = (day: MilestoneDaysSurveyProject): boolean => {
    return Boolean(!day.is_new && day.double && day.doubles.length > 0);
  };

  return (
    <Container id="Survey_Project_EditPopup_Millstone">
      <Title id="Survey_Project_EditPopup_Millstone_Title">
        Set this project up for milestone surveys
      </Title>
      <Text id="Survey_Project_EditPopup_Millstone_SubTitle">
        Surveys will be based on calculations from (Day 0) for:
      </Text>
      <FormControl>
        <StyledAutocomplete
          disablePortal
          id="Survey_Project_EditPopup_Millstone_Select_Attribute"
          onChange={(e, value: any) => {
            store.setData(value, "selected_date_attribute");
          }}
          value={store.selected_date_attribute}
          options={store.entity_attributes.filter((x) => x.type === "date")}
          getOptionLabel={(option: any) => option.name}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} placeholder="Select date attribute" />}
        />
      </FormControl>
      <Text id="Survey_Project_EditPopup_Millstone_Description">
        The number of milestones listed below will correspond to the number of surveys sent out to
        recipients.
      </Text>
      <DaysWrapper>
        {updatedMillstoneDays.map((day, index) => {
          if (day.deleted) return null;
          return (
            <DaysItem id={`Survey_Project_EditPopup_Millstone_${index}`} key={index}>
              <DaysItemLeftContent
                id={`Survey_Project_EditPopup_Milestone_DaysItemLeftContent_${index}`}
              >
                <DaysItemLeftContentText
                  id={`Survey_Project_EditPopup_Milestone_DaysItemLeftContent_Day_${index}`}
                >
                  Day
                </DaysItemLeftContentText>
                {changeDay && changeDay.index === index ? (
                  <QuantityInput
                    id={`Survey_Project_EditPopup_Milestone_DaysItemRightContent_Input_${index}`}
                    value={changeDay.value}
                    onChange={(_, value) => setChangeDay({ index, value })}
                  />
                ) : (
                  <p id={`Survey_Project_EditPopup_Milestone_DaysItemLeftContent_Number_${index}`}>
                    {day.days}
                    {doubleDayVerification(day) &&
                      day.doubles.map((el, i) => <span key={i}> ({el}) </span>)}
                  </p>
                )}
              </DaysItemLeftContent>
              <DaysItemRightContent>
                {changeDay && changeDay.index === index ? (
                  <SaveEditRemoveButton
                    id={`Survey_Project_EditPopup_Milestone_DaysItemRightContent_${index}`}
                    variant="outlined"
                    onClick={() => {
                      store.updateMillstoneDay(index, changeDay.value);
                      setChangeDay(null);
                    }}
                  >
                    Save
                  </SaveEditRemoveButton>
                ) : (
                  <SaveEditRemoveButton
                    id={`Survey_Project_EditPopup_Milestone_DaysItemRightContent_Edit_Button_${index}`}
                    onClick={() => setChangeDay({ value: +day.days, index: index })}
                  >
                    <Edit fontSize="small" />
                    Edit
                  </SaveEditRemoveButton>
                )}
                {changeDay && changeDay.index === index ? (
                  <SaveEditRemoveButton
                    id={`Survey_Project_EditPopup_Milestone_DaysItemRightContent_Cancel_Button_${index}`}
                    onClick={() => setChangeDay(null)}
                  >
                    Cancel
                  </SaveEditRemoveButton>
                ) : (
                  <SaveEditRemoveButton
                    id={`Survey_Project_EditPopup_Milestone_DaysItemRightContent_Delete_Button_${index}`}
                    onClick={() => store.removeMillstoneDay(index)}
                  >
                    <Delete fontSize="small" />
                    Delete
                  </SaveEditRemoveButton>
                )}
              </DaysItemRightContent>
            </DaysItem>
          );
        })}

        {store.millstoneDays.length < 12 && (
          <AddMillstoneBtn
            id="Survey_Project_EditPopup_Milestone_AddMilestone_Button"
            variant="outlined"
            onClick={store.addMilstoneDay}
          >
            <IconWrapper>
              <AddIcon />
            </IconWrapper>
            Add milestone
          </AddMillstoneBtn>
        )}
        {store.millstoneDays.length >= 10 && store.millstoneDays.length < 12 ? (
          <WarningWrapper $redColor={false}>
            <WarningAmberIcon />
            <WarningText
              id="Survey_Project_EditPopup_Milestone_MaximumProject_Warning"
              $redColor={false}
            >
              The maximum number of milestones per project is 12. You may add{" "}
              {12 - store.millstoneDays.length} more.
            </WarningText>
          </WarningWrapper>
        ) : store.millstoneDays.length === 12 ? (
          <WarningWrapper $redColor>
            <WarningAmberIcon />
            <WarningText
              id="Survey_Project_EditPopup_Milestone_ReachedMaximumProject_Warning"
              $redColor
            >
              You have reached the maximum number of milestones that can be created per project.
            </WarningText>
          </WarningWrapper>
        ) : null}
      </DaysWrapper>
    </Container>
  );
});

export default SurveyMillstone;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const Title = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
  margin-bottom: 10px;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
  margin: 20px 0px 16px 0px;
`;

const StyledSelect = styled(Select)`
  height: 34px;
  width: 100%;
  max-width: 225px;

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }
`;

const DaysWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DaysItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid var(--colorPaletteBlueBackground1);
  padding: 5px 20px;
  margin-bottom: 10px;
`;

const AddMillstoneBtn = styled(CustomButton)`
  background-color: var(--colorPaletteGrayBackground1) !important;
  border: 1px solid var(--colorPaletteBlueBackground1) !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  color: var(--colorBrandForeground1) !important;
  height: 50px !important;
  box-shadow: none !important;
`;

const IconWrapper = styled.span`
  border-radius: 50%;
  background-color: var(--colorNeutralBackground1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;

  svg {
    font-size: 16px;
  }
`;

const DaysItemLeftContent = styled.div`
  display: flex;
  align-items: center;
`;

const DaysItemRightContent = styled.div``;

const SaveEditRemoveButton = styled(CustomButton)`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const DaysItemLeftContentText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-right: 10px;
  color: var(--colorNeutralForeground1);
`;

const WarningWrapper = styled.div<{ $redColor: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;

  svg {
    font-size: 20px;
    color: ${(props) =>
      props.$redColor ? "var(--colorPaletteRedForeground2)" : "var(--colorNeutralForeground1)"};
  }
`;

const WarningText = styled.p<{ $redColor: boolean }>`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 4px;
  color: ${(props) =>
    props.$redColor ? "var(--colorPaletteRedForeground2)" : "var(--colorNeutralForeground1)"};
`;

const StyledAutocomplete = styled(Autocomplete)`
  input {
    border-radius: 2px !important;
    padding: 0px !important;
    font-family: Roboto !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .MuiOutlinedInput-root {
    border-radius: 2px !important;
  }
`;
