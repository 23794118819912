import http from "api/https";
import { createUrl } from "../helpers/url";

export const GetOneSurveyWithTags = async (id: number): Promise<any> => {
  const query: { id: string;} = {
    id: id.toString(),
  };

  if(http.isAPIGO){
    const urlGo = createUrl({
      path: "/sm/sm_survey/GetWithTags",
      query: query,
    });
    return http.getGO(urlGo)
  }else{
    const url = createUrl({
      path: "/sm_survey/GetWithTags",
      query: query,
    });
  return http.get(url)
  }
};
