import { makeAutoObservable, reaction } from "mobx";
import { GetEmployeesByEntityType } from "api/go/useGetEmployeesByEntity";
import { AddOrEditEmployee } from "api/go/useAddOrEditEmployee";
import { getUnitByEntity } from "api/go/useGetUnitByEntity";
import { GetAttibutesByEntityAndEmployee } from "api/go/useGetAttibutesByEntityAndEmployee";
import { GetOneEmployee } from "api/go/useGetOneEmployee";
import { GetOneEmployeeType, AttibutesByEntityAndEmployeeType } from "types/employee";
import MainStore from "MainStore";
import { getRolesByEntity } from "api/go/useGetRolesByEntity";
import { Role } from "types/permission";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { validate } from "./valid";
import { GetStructureToEmployee } from "api/go/useGetStructureToEmployee";
import { checkUniqueParamsEmployees } from "api/go/useCheckUniqueParamsEmployees";
import { OrganizationUnit } from "types/organization_unit";
import employeeListStore from "./../ListEmployee/store";
import storeEmployees from "features/Employees/store";
import dayjs, { Dayjs } from "dayjs";
import storeListEmployee from "../ListEmployee/store";
import { searchEmployeeForManager } from "api/go/useSearchEmployeeForManager";

class Store {
  id = 0;
  fullName = "";
  preferedName = "";
  email = "";
  uniqueId = "";
  startDate = "";
  isManager = false;
  isUnitHead = false;

  selectedManager: GetEmployeesByEntityType = null;

  errorfullName = "";
  errorpreferedName = "";
  erroremail = "";
  erroruniqueId = "";
  errorstartDate = "";

  openAddSegment = false;
  idAttributeOnAddSegment = 0;
  newSegmentValue = "";

  selectedRoles: Role[] = [];
  roles: Role[] = [];
  AddRolePanel = false;
  Structures: OrganizationUnit[] = [];
  idStructure = 0;
  SelectedStructure: OrganizationUnit = null;
  isUniqueEmail: boolean = false;
  isUniqueUniqId: boolean = false;

  selectedEmployees: GetEmployeesByEntityType[] = [];
  selectedUnits: OrganizationUnit[] = [];

  Attributes: AttibutesByEntityAndEmployeeType[] = [];

  editCheckEmployee: boolean = false;

  optionsEmployees: GetEmployeesByEntityType[] = [];
  loadingEmployees: boolean = false;

  loadingSearchUnitOptions: boolean = false;
  searchUnitOptions: OrganizationUnit[] = [];

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => [this.email, this.uniqueId],
      ([newEmail, newUniqueId]) => {
        this.isExistsUnique(newEmail, newUniqueId, this.id);
      },
      { delay: 1000 }
    );
  }

  async saveEmployee(props: any) {
    const validated = (errorField: string, errorText: string) => {
      this[errorField] = errorText;
    };

    let canSave = true;
    canSave = validate("fullName", this.fullName, validated) && canSave;
    canSave = validate("preferedName", this.preferedName, validated) && canSave;
    canSave = validate("email", this.email, validated) && canSave;
    canSave = validate("uniqueId", this.uniqueId, validated) && canSave;
    canSave = validate("startDate", this.startDate, validated) && canSave;
    canSave = !this.isUniqueEmail && !this.isUniqueUniqId && canSave;
    if (this.email === "" && this.uniqueId === "") {
      this.erroremail = "Required field";
      canSave = false;
    }

    if (this.isManager) {
      canSave = this.selectedEmployees?.length > 0;
    }

    const respUnique = await this.isExistsUnique(this.email, this.uniqueId, this.id)
    
    if (respUnique) {
      canSave = false;
    }

    if (!canSave) {
      return MainStore.setSnackbar("Fill in the fields correctly!", "error");
    }

    var data = {
      id: this.id,
      full_name: this.fullName,
      email: this.email,
      entity_id: props.idEntity,
      preffered_name: this.preferedName,
      unique_id: this.uniqueId,
      start_date: this.startDate,
      attributes: this.Attributes,
      roles: this.selectedRoles,
      structure_id: this.idStructure,
      under_manager_employee_ids: this.selectedEmployees?.map((x) => x.entity_employee_id),
      under_head_untis_ids: this.selectedUnits?.map((x) => x.id),
      manager_id: this.selectedManager?.entity_employee_id,
      manager: this.selectedManager,
    };

    MainStore.changeLoader(true);
    try {
      const response = await AddOrEditEmployee(data);
      if (response.status === 200 || response.status === 201) {
        MainStore.changeLoader(false);
        props.handleHide(false);
        props.onSavedClicked(response.data);
        await storeListEmployee.loadUsers();
        storeEmployees.loadCheckManagersWithoutRoles();
      } else if(response.response?.status === 422){
        MainStore.setSnackbar(response.response?.data?.error, "error");
      } else throw new Error();
    } catch (error) {
      MainStore.setSnackbar("Unable to save at this point in time. Please try again later", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  async isExistsUnique(mail: string, uid: string, emp: number) {
    const response = await checkUniqueParamsEmployees(StoreLayout.currentEntityId, mail, uid, emp);
    if (response.status === 200 && response.data) {
      
      this.isUniqueUniqId = Boolean(response.data.uid);
      this.isUniqueEmail = Boolean(response.data.email);

      if (this.isUniqueUniqId || this.isUniqueEmail) {
        return true;
      }
      
    } else {
      MainStore.setSnackbar("Error checking unique", "error");
    }
    return false;
  }

  clearStore = () => {
    this.id = 0;
    this.fullName = "";
    this.preferedName = "";
    this.selectedManager = null;
    this.SelectedStructure = null;
    this.email = "";
    this.uniqueId = "";
    this.startDate = "";
    this.isManager = false;
    this.isUnitHead = false;
    this.selectedEmployees = [];
    this.selectedUnits = [];
    this.Attributes = [];
    this.openAddSegment = false;
    this.roles = [];
    this.selectedRoles = [];
    this.errorfullName = "";
    this.errorpreferedName = "";
    this.erroremail = "";
    this.erroruniqueId = "";
    this.errorstartDate = "";
    this.idStructure = 0;
    this.Structures = [];
    this.editCheckEmployee = false;
    this.searchUnitOptions = [];
    this.loadingSearchUnitOptions = false;
  };

  setAttributes(data: AttibutesByEntityAndEmployeeType[]) {
    this.Attributes = data;
  }

  setEmployee(data: GetOneEmployeeType) {
    this.id = data.id;
    this.fullName = data.full_name;
    this.preferedName = data.preffered_name;
    this.email = data.email;
    this.uniqueId = data.unique_id;
    this.startDate = data.start_date;
    this.selectedRoles = data.roles;
    this.idStructure = data.structure_id;
    this.selectedEmployees = data.under_manager_employee;
    this.selectedUnits = data.under_head_untis;
    this.selectedManager = data.manager;
    this.isManager = data.under_manager_employee?.length > 0;
    this.isUnitHead = data.under_head_untis?.length > 0;
  }

  handleChange(name: string, value: any) {
    this[name] = value;
  }

  changeField(name: string, value: any) {
    this[name] = value;
    if (name === "email" && value === "") {
      this.changeField("selectedRoles", []);
    }
    validate(name, value, (errorField: string, errorText: string) => {
      this[errorField] = errorText;
    });
  }

  dateChangeField = (value: Dayjs) => {
    const formattedDate = dayjs(value)?.format("YYYY-MM-DD");
    this.startDate = formattedDate;
  };

  getRoles = async () => {
    try {
      const response = await getRolesByEntity(StoreLayout.currentEntityId);
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        this.roles = response.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  };

  saveSegment = (i: number) => {
    let value =
      typeof this.newSegmentValue === "string"
        ? this.newSegmentValue
        : dayjs(store.newSegmentValue).format("DD.MM.YYYY").toString();

    this.Attributes[i].values.push({
      id: this.Attributes[i].values.length + 1,
      value: value,
    });
  };

  loadAttributes = async (employee_id: number, entity_id: number) => {
    if (entity_id === 0) return;
    try {
      MainStore.changeLoader(true);
      const response = await GetAttibutesByEntityAndEmployee(entity_id, employee_id);
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        this.setAttributes(response.data);
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false)
    }
  };

  changeAttribute = (i: number, value: string) => {
    var attrs = this.Attributes;
    attrs[i].value = value;
    this.Attributes = attrs;
  };

  async loadEmployee(idEmployee: number) {
    if (idEmployee === 0) return;
    MainStore.changeLoader(true);
    try {
      const response = await GetOneEmployee(idEmployee, StoreLayout.currentEntityId);
      if (response.status === 200 || response.status === 201) {
        if (employeeListStore.Employees.length === 1) {
          this.editCheckEmployee = true;
        }
        this.setEmployee(response.data);
      } else throw new Error();
    } catch (error) {
      console.error(error);
    } finally {
      MainStore.changeLoader(false);
    }
  }

  loadStructures = async () => {
    MainStore.changeLoader(true);
    try {
      const response = await GetStructureToEmployee(StoreLayout.currentEntityId);
      if (response.status === 200 || response.status === 201) {
        this.Structures = response.data;
        this.SelectedStructure = response.data.find((a) => a.id === this.idStructure);
      } else throw new Error();
    } catch (error) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  setSearchUnitOptions = (units: OrganizationUnit[]) => {
    this.searchUnitOptions = units;
  };

  loadUnits = async (searchValue: string) => {
    this.loadingSearchUnitOptions = true;
    try {
      const response = await getUnitByEntity(StoreLayout.currentEntityId, searchValue, this.id);
      if (response.status === 200 || response.status === 201) {
        this.searchUnitOptions = response.data;
      } else throw new Error();
    } catch (error) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
    this.loadingSearchUnitOptions = false;
  };

  addSelectedEmployees = (value: GetEmployeesByEntityType) => {
    let exists = this.selectedEmployees?.find((x) => x.id === value?.id);
    if (!exists) {
      if (this.selectedEmployees) this.selectedEmployees = [value, ...this.selectedEmployees];
      else this.selectedEmployees = [value];
    }
  };

  deleteSelectedEmployee(id: number) {
    this.selectedEmployees = this.selectedEmployees?.filter((x) => x.id !== id);
  }

  addSelectedUnit = (value: OrganizationUnit) => {
    let exists = this.selectedUnits?.find((x) => x.id === value?.id);
    if (!exists) {
      if (this.selectedUnits) this.selectedUnits = [value, ...this.selectedUnits];
      else this.selectedUnits = [value];
    }
  };

  deleteSelectedUnit(id: number) {
    this.selectedUnits = this.selectedUnits?.filter((x) => x.id !== id);
  }

  onAddRoleClick = () => {
    this.AddRolePanel = true;
  };

  onAddRoleCancelClick = () => {
    this.AddRolePanel = false;
  };

  onAddRoleSaveClick = () => {
    this.getRoles();
    this.AddRolePanel = false;
  };

  changeOptions = (options: GetEmployeesByEntityType[]) => {
    this.optionsEmployees = options;
  };

  changeLoading = (loading: boolean) => {
    this.loadingEmployees = loading;
  };

  changeSelectedManager = (value: GetEmployeesByEntityType) => {
    this.selectedManager = value;
  };

  loadEmployees = async (searchValue: string) => {
    try {
      const response = await searchEmployeeForManager(
        StoreLayout.currentEntityId,
        searchValue,
        this.id
      );
      this.changeOptions([...response]);
    } catch {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      this.changeLoading(false);
    }
  };
}

const store = new Store();
export default store;
