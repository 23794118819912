import http from "../https";

type TRequestBody = {
  project_id: number;
  attributes: {
    id: number;
    name: string;
    segments: string[];
  }[];
  benchmarkType?: number;
  viewType: string;
  trendType: number;
  questionIds?: number[];
  trendAccross: string;
  scoredType?: string;
  answered?: string[];
  benchMarkId?: number;
};

export const useGetDaboardTrends = (requestData: TRequestBody) =>
  http.post(`/Dashboard/trend/chart`, requestData);
