import http from "api/https";

export const getTemplatesFilter = (
  group: string,
  type: string,
  entity_id: number,
  search_value: string,
  order_by: string,
  order_type: string,
  type_id?: number
): Promise<any> => {
  let quvery = "type=" + type +
    "&entity_id=" + entity_id +
    "&search_value=" + search_value +
    "&type_id=" + (type_id === null || type_id === undefined ? 0 : type_id) +
    "&order_by=" + order_by +
    "&order_type=" + order_type
  if (http.isAPIGO) {
    let groupType = group === null || group === "" ? "all" : group;
    switch (groupType) {
      case "engageRocket":
        return http.getGO("/template/get_template_fielder_engage_rocket?" + quvery);
        break;
      case "shared":
        return http.getGO("/template/get_template_fielder_shared?" + quvery);
        break;
      default:
        return http.getGO("/template/get_template_fielder_entity?" + quvery);
        break;
    }
  } else {
    return http.get("/templ_template_comms/GetTemplatesFilter?group=" + group + "&" + quvery);
  }
};