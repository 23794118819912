import React, { ReactElement } from "react";
import styled from "styled-components";
import { observer } from "mobx-react"
import store from './store'
import Card from './card'
import {
  Grid, Divider
} from "@mui/material"
import EntityAddPopupView from './add/popup'
import EntityAddEditView from './addedit/popup'
import NoEntityCard from "./noEntityCard";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import ActiveSeatsIcon from "./icons/ActiveSeatsIcon";

const EntityCardListView = observer((): ReactElement => {

  React.useEffect(() => {
    store.loadEntities(StoreLayout.currentWorkspaceId)
    store.loadWorkspaceSettings(StoreLayout.currentWorkspaceId)
  }, []);

  return (
    <Container >

      <EntityAddPopupView
        openPanel={store.openPanelCreateEntity}
        onBtnCancelClick={() => {
          store.changeOpenPanelCreateEntity(false)
        }}
        onSaved={() => {
          store.changeOpenPanelCreateEntity(false)
          store.loadEntities(StoreLayout.currentWorkspaceId)
        }}
        workspacename="Test"
      />
      <EntityAddEditView
        openPanel={store.openPanelAddEditEntity}
        id={store.currentEntityId}
        onBtnCancelClick={() => {
          store.currentEntityId = 0
          store.changeOpenPanelAddEditEntity(false)
        }}
        onSaved={() => {
          store.changeOpenPanelAddEditEntity(false)
          store.loadEntities(StoreLayout.currentWorkspaceId)
        }}
        workspacename="Test"
      />

      {store.data.length != 0 ? <><HeaderContainer>
        <Header id="ManageEntites_Title">
          Manage entites&nbsp;({store.data.length})
        </Header>

        <WorkspaceSeats>
          <ActiveSeatsIcon />

          <WorkspaceSeatsText id="ManageEntites_SubTitle">
            Workspace seats: {store.workspaceSetting?.active_seat_count_total} / {store.workspaceSetting?.active_seat_count}
          </WorkspaceSeatsText>
        </WorkspaceSeats>

      </HeaderContainer>

        <DividerStyled />

        <Grid container spacing={3}>
          {store.data.map((content) => <Grid item xs={12} key={content.id} md={4}>
            <Card
              cardContent={content}
            />
          </Grid>)}
        </Grid>
      </> : <NoEntityCard />}
    </Container>
  );
});


const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
  background-color: var(--colorNeutralForegroundInverted1, #f2f0f4);
  padding: 48px 64px;
  margin-bottom: 100px;
`;

const WorkspaceSeats = styled.div`
  display: flex;
  background-color: var(--colorPaletteBlueBackground3, #E4E9FF);
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  margin-left: 30px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const DividerStyled = styled(Divider)`
  margin-bottom: 20px !important;
`;

const WorkspaceSeatsText = styled.span`
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  margin-left: 10px;
`;

const Header = styled.h1`
  color: var(--colorNeutralForeground1, #3E4450);
  /* Product/v1/Heading/H1 */
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 112.5% */
`;


export default EntityCardListView;
