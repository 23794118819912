import { FC } from "react";
import { templ_template_comms } from "types/templates";
import styled from "styled-components";
import CustomTable from "../SurveyList/components/CustomTable";
import { Checkbox } from "@mui/material";
import store from "./storeSurvey";
import dayjs from "dayjs";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import { TEMPLATE_TYPES } from "constants/template-types";
import shortStringLength from "components/shortStringLength/shortStringLength";

type CommunicationTableTableProps = {
  list: templ_template_comms[];
};

const CommunicationTableTable: FC<CommunicationTableTableProps> = (props) => {
  const hasFeature = (template: templ_template_comms): boolean => {
    if (!template) return true;
    if (template?.type === "communication") {
      return StorePermission.features.communication_template.hasFeature;
    } else {
      return (
        template?.type_id_code === null ||
        (template?.type_id_code === TEMPLATE_TYPES.surveys &&
          StorePermission.features.survey_template.hasFeature) ||
        (template?.type_id_code === TEMPLATE_TYPES.milestones &&
          StorePermission.features.milestone_template.hasFeature) ||
        (template?.type_id_code === TEMPLATE_TYPES.reviews &&
          StorePermission.features.review_template.hasFeature)
      );
    }
  };

  const tableData = {
    headers: [
      {
        title: "TEMPLATE NAME",
        value: "name",
        filter: false,
        sort: true,
        renderRow: (row: templ_template_comms) => (
          <TableCellWrapper>
            <TableName id={`Template_Select_Table_name_${row.type}_${row.id}`}>
              <Checkbox
                id={`Template_Select_Table_checkbox_${row.type}_${row.id}`}
                disabled={!hasFeature(row)}
                checked={
                  row.id === store.selectedCommunication || row.id === store.selectedQuestionnaireId
                }
                onChange={() => store.setChecked(row.type_name, row.id)}
              />
              {shortStringLength(row.name)}
            </TableName>
          </TableCellWrapper>
        ),
      },
      {
        title: "TEMPLATE TYPE",
        value: "type",
        filter: false,
        sort: false,
        renderRow: (row: templ_template_comms) => (
          <TableCellWrapper>
            <TableName id={`Template_Select_Table_type_${row.type}_${row.id}`}>
              {shortStringLength(row.type_name)}
            </TableName>
          </TableCellWrapper>
        ),
      },
      {
        title: "QUESTIONS",
        value: "questions_count",
        filter: false,
        sort: false,
        renderRow: (row: templ_template_comms) => (
          <TableCellWrapper>
            <TableName id={`Template_Select_Table_questions_${row.type}_${row.id}`}>
              {row.questions_count}
            </TableName>
          </TableCellWrapper>
        ),
      },
      {
        title: "TAGS",
        value: "tags",
        filter: false,
        sort: false,
        renderRow: (row: templ_template_comms) => (
          <TableCellWrapper>
            <TableName id={`Template_Select_Table_tag_${row.type}_${row.id}`}>
              {shortStringLength(row.tag_names)}
            </TableName>
          </TableCellWrapper>
        ),
      },
      {
        title: "LAST UPDATE",
        value: "strupdated_at",
        filter: false,
        sort: true,
        renderRow: (row: templ_template_comms) => {
          let formattedUpdateDate = "";

          if (row.updated_at) {
            const da = dayjs(row.updated_at);
            const survey_date_update = dayjs(da).toDate();
            formattedUpdateDate = new Intl.DateTimeFormat("en-US", {
              day: "numeric",
              month: "short",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }).format(survey_date_update);
          }

          let formattedCreatedDate = "";

          if (row.created_at) {
            const created = dayjs(row.created_at);
            const survey_date_created = created.toDate();
            formattedCreatedDate = new Intl.DateTimeFormat("en-US", {
              day: "numeric",
              month: "short",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }).format(survey_date_created);
          }

          return (
            <TableCellWrapper>
              <TableName id={`Template_Select_Table_dates_${row.type}_${row.id}`}>
                <UpdatedBlock>
                  <UpdatedDiv id={`Templates_Table_updated_${row.type}_${row.id}`}>
                    {formattedUpdateDate}
                  </UpdatedDiv>
                  <CreatedDiv id={`Templates_Table_created_${row.type}_${row.id}`}>
                    {`Created on ${formattedCreatedDate}`}
                  </CreatedDiv>
                </UpdatedBlock>
              </TableName>
            </TableCellWrapper>
          );
        },
      },
    ],
  };

  return (
    <CustomTable
      id="Templates_Select_"
      list={props.list}
      sort={store.sortField}
      hideAddButton
      sortType={store.sortType}
      data={tableData}
      changeSort={(s: string, t: string) => store.changeSort(s, t)}
    />
  );
};

const UpdatedBlock = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: var(--colorPaletteVioletBackground1);
  margin: 0;
`;

const UpdatedDiv = styled.p`
  color: var(--colorNeutralForeground1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const CreatedDiv = styled.div`
  color: var(--colorNeutralForeground2);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const TableCellWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TableName = styled.div`
  color: var(--colorNeutralForeground1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export default CommunicationTableTable;
