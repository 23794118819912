import { FC, useEffect } from "react";
import BasicTable from './RolesTable'
import { Box } from "@mui/material";
import styled from "styled-components";
import AddIcon from '@mui/icons-material/Add';
import AddEditRoleName from './AddEditRole/popupAddEditRoleName'
import store from './store'
import { observer } from "mobx-react";
import CustomButton from "components/button/Button";
import StorePermission from "components/workspaces-sidebar/StorePermission";

type WorkspacePermissionsRolesProps = {
  changeTab: (tab: string) => void;
};

const WorkspacePermissionsRoles: FC<WorkspacePermissionsRolesProps> = observer((props) => {

  useEffect(() => {
    store.doLoad()
  }, []);

  return <>

    <HeaderActions>
      <Header id="WorkspacePermissionsRole_Title">
        Roles and permissions
      </Header>

      <Box>
        <CustomButton
        id="WorkspacePermissionsRole_CreateNewRole"
        disabled={!StorePermission.workspacePermissions.editWorkspacePermissions.hasPermission}
        variant="contained" onClick={() => {
          store.changeAddEditRoleNamePanel(true)
        }}>
          <AddIcon />
          Create new role
        </CustomButton>
      </Box>

    </HeaderActions>

    <AddEditRoleName
      openPanel={store.addEditRoleNamePanel}
      onBtnCancelClick={() => store.changeAddEditRoleNamePanel(false)}
      onClose={() => store.changeAddEditRoleNamePanel(false)}
      onSaved={(id) => {
        store.changeAddEditRoleNamePanel(false)
        store.doLoad()
      }}
      idRole={0} roleName={""}
    />

    <BasicTable changeTab={props.changeTab} />
  </>
})


const HeaderActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;


const Header = styled.h1`
  font-family: Roboto;
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;


export default WorkspacePermissionsRoles;
