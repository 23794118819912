import React from "react";
import { observer } from "mobx-react";
import { Box, Badge, FormControlLabel, Checkbox } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import WorkIcon from "@mui/icons-material/Work";
import ErrorIcon from "@mui/icons-material/Error";
import EmailIcon from "@mui/icons-material/Email";
import CustomButton from "components/button/Button";
import SettingsIcon from "@mui/icons-material/Settings";
import Pagination from "components/pagination/Pagination";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CustomTable from "./CustomTable";
import { TColumn } from "types/recipients";
import store from "./store";
import styled from "styled-components";
import dayjs, { Dayjs } from "dayjs";
import { DEFAULT_ROLES } from "constants/default-roles";
import { AdminIcon } from "../Roles/Icons/AdminIcon";
import { RoleIcon } from "../Roles/Icons/RoleIcon";
import { User } from "types/permission";


interface DataTableProps { }

const DataTable: React.FC<DataTableProps> = observer((props) => {
  const [activePage, setActivePage] = React.useState(1);
  const [countPerPage, setCountPerPage] = React.useState(10);

  const indexOfLastRecord = activePage * countPerPage;
  const indexOfFirstRecord = indexOfLastRecord - countPerPage;
  const currentRecords = store.invitedUsers.slice(indexOfFirstRecord, indexOfLastRecord);

  const selectionChangeHandler = (isSelected?: boolean, row?: User) => {
    const newSelection = isSelected
      ? store.selectedData.filter((id) => id !== row.id)
      : [...store.selectedData, row.id];
    store.changeSelectedData(newSelection);
  };


  const columns = [
    {
      width: 20,
      label: (
        <CheckBoxContainer>
          <StyledFormControlLabel
            control={
              <StyledCheckBox
                id={`EntityPermissions_EntityUsers_Grid_Table_ChecboxAll`}
                disabled={store.invitedUsers.length === 0}
                checked={store.selectedData.length === store.invitedUsers.length}
                onChange={() => {
                  store.changeSelectedData(
                    store.selectedData.length !== store.invitedUsers.length
                      ? store.invitedUsers.map((item) => item.id)
                      : []
                  );
                }}
              />
            }
            label={false}
          />
        </CheckBoxContainer>
      ),
      render: (row: any, index, indexRow) => {
        const isSelected = store.selectedData.includes(row.id);
        return (
          <CheckBoxContainer>
            <StyledFormControlLabel
              control={<StyledCheckBox id={`EntityPermissions_EntityUsers_Grid_Table_Checbox_${index}_${indexRow}_${row.id}`} checked={isSelected} />}
              label={false}
            />
          </CheckBoxContainer>
        );
      },
    },
    { id: "email", label: "Email", width: 400 },
    {
      id: "roleName",
      label: "Role",
      width: 400,
      render: (param: any, index, rowIndex) => {
        return (
          <Box>
            <Box display={"flex"} alignItems="center">
              {(param.isDefaultRole || param.roleCode === DEFAULT_ROLES.managers || param.roleCode === DEFAULT_ROLES.unit_heads)
                ? <AdminIcon /> : <RoleIcon />}
              <span style={{ fontWeight: 500 }}>{param.roleName}</span>
              <CustomButton
              id={`EntityPermission_UsersGridInvite_RemoveFromRole_Btn`}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  store.changeRemoveFromRolePanel(true);
                  store.currentRoleId = param.roleId;
                  store.currentRoleName = param.roleName;
                  store.selectedData = [param.idUser];
                  store.currentUser = param;
                }}
              >
                Remove from role
              </CustomButton>
            </Box>
            <Box id={`EntityPermissions_EntityUsers_Grid_Table_Subtitle_${index}_${rowIndex}_${param.id}`} style={{ fontSize: 12 }}>{param.inEntityOrWorkspace}</Box>
          </Box>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      width: 400,
      render: (param: any, index, rowIndex) => {
        return (
          <Box>
            <Box>Invited on {dayjs(param.strlastInvitedTime).format("ddd, DD MMM YYYY, hh:mm A")}</Box>
            {/* <Box>Invited on {param.strlastInvitedTime}</Box> */}
            {param.inviteExpiredDaysAgo && (
              <StyledBox>
                <StyledErrorIcon style={{ fontSize: 16 }} />
                Invite has expired {param.inviteExpiredDaysAgo} days ago
              </StyledBox>
            )}
          </Box>
        );
      },
    },
  ] as TColumn[];

  return (
    <div style={{ width: "100%" }}>
      <HeaderWrapperBox>
        <Box id={`EntityPermission_UsersGridInvite_Title`}>Users who are invited but have not created an account will appear here</Box>
        <Box>
          <CustomButton
            id={`EntityPermissions_EntityUsers_Grid_InviitAgainBtn`}
            style={{ margin: 5 }}
            disabled={store.selectedData.length === 0}
            variant="contained"
            color="inherit"
            size="small"
            onClick={() => {
              store.changeInviteAgainPanel(true);
            }}
          >
            <EmailIcon fontSize="small" /> Invite again
          </CustomButton>
        </Box>
      </HeaderWrapperBox>
      <div style={{ width: "100%" }}>
        <CustomTable
          data={currentRecords}
          columns={columns}
          changeHandler={selectionChangeHandler}
          noDataTitle={{
            title: "No users yet.",
            description: "Get started by adding users.",
          }}
        />
      </div>
      <Pagination
       id={`EntityPermission_UsersGridInvite_InviteAgain`}
        activePage={activePage}
        itemsCountPerPage={countPerPage}
        onChange={(value) => {
          setActivePage(value);
        }}
        pageRangeDisplayed={countPerPage}
        totalItemsCount={store.invitedUsers.length}
        totalPages={store.invitedUsers.length / countPerPage}
        setCount={(value) => {
          setActivePage(1);
          setCountPerPage(Number(value));
        }}
      />
    </div>
  );
});

export default DataTable;

const StyledBadge = styled(Badge)`
  margin-bottom: 5px;
  margin-right: 5px;
`;

const StyledStarIcon = styled(StarIcon)`
  background: var(--ER-Theme-white, #fff);
  color: var(--Bright-purple, #6e31ff);
  border-radius: 10px;
`;

const StyledSettingsIcon = styled(SettingsIcon)`
  background: var(--ER-Theme-white, #fff);
  color: var(--Bright-purple, #6e31ff);
  border-radius: 10px;
`;

const StyledBox = styled(Box)`
  font-size: 12px;
  display: flex;
  align-items: center;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  color: var(--ER-Theme, #fa6b6b);
  margin-right: 5px;
`;

const HeaderWrapperBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  background-color: var(--grenBackgroundColor2);
  padding: 0 16px;
`;

const CheckBoxContainer = styled.div``;

const StyledCheckBox = styled(Checkbox)`
  padding: 0px 10px !important;
  color: var(--colorBrandForeground1) !important;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);

  .MuiCheckbox-root {
    &:hover {
      background-color: transparent !important;
    }
  }
`;
