import { createContext, ReactNode, useContext } from "react";
import { cognitoService, type CognitoService } from './CognitoService';
import { Outlet } from "react-router-dom";

interface CognitoProviderProps {
  children: ReactNode;
}

const CognitoContext = createContext<CognitoService | null>(null);

export const CognitoProvider: React.FC<CognitoProviderProps> = ({ children }) => {
  const value: CognitoService = {
    login: cognitoService.login,
    logout: cognitoService.logout,
    refreshToken: cognitoService.refreshToken,
    setNewPassword: cognitoService.setNewPassword,
    getAccessToken: cognitoService.getAccessToken,
    getRefreshToken: cognitoService.getRefreshToken,
    requestNewPassword: cognitoService.requestNewPassword,
    confirmNewPassword: cognitoService.confirmNewPassword,
  };

  return (
    <CognitoContext.Provider value={value}>
      <Outlet />
    </CognitoContext.Provider>
  );
};

export const useCognito = () => {
  const context = useContext(CognitoContext);
  if (!context) {
    throw new Error('useCognito must be used within a CognitoProvider');
  }
  return context;
};