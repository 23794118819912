import http from "api/https";
import { createUrl } from "../../helpers/url";
import { TRecipientsFilterSearch } from "types/recipients";


export const getAllEmployeesNoRecipients = (survey_id: number,value: string, attributes: TRecipientsFilterSearch[], page: number, limit: number,sort: string, order: boolean): Promise<any> => {
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/sm/sm_survey_recepients/GetAllEmployeesNoRecipients",
    });
    return http.postGO(url,{
      survey_id: survey_id,
      value: value,
      attributes: attributes,
      page: page,
      limit: limit,
      sort: sort,
      order: order
    });
  } else {
    const url = createUrl({
      path: "/sm_survey_recepients/GetAllEmployeesNoRecipients",
    });
    return http.post(url,{
      survey_id: survey_id,
      value: value,
      attributes: attributes,
      page: page,
      limit: limit
    });
  }
};
