import { observer } from "mobx-react";
import { Button, TextField } from "@mui/material";
import { TOpenEndedResponse } from "types/dashboard";
import { FC, useEffect, useState } from "react";

import store from "./store";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

type TopicConstructorProps = {
  topicList: string[];
  changeIsShowConstructor: (state: boolean) => void;
  localFilters: { id: number; name: string; segments: string[] }[];
  response: TOpenEndedResponse;
};

const TopicConstructor: FC<TopicConstructorProps> = observer((props) => {
  const [topicList, setTopicList] = useState([]);

  useEffect(() => {
    if (props.topicList && props.topicList?.length > 0) setTopicList(props.topicList);
    else setTopicList([""]);
  }, [props.topicList]);

  const addNewTopic = () => {
    if (topicList && topicList?.length > 0) setTopicList((prev) => [...prev, ""]);
    else setTopicList([""]);
  };

  const removeTopic = (indexToRemove: number) => {
    if (topicList.length > 1) {
      setTopicList((prev) => prev.filter((_, index) => indexToRemove !== index));
    } else setTopicList([""]);
  };

  const changeTopic = (indexToChange: number, value: string) =>
    setTopicList((prev) =>
      prev.map((el, index) => {
        if (indexToChange === index) {
          return value;
        } else return el;
      })
    );

  const editTopicList = () => {
    store.editTopicList(props.response.Id, topicList, props.localFilters);
    props.changeIsShowConstructor(false);
  };

  return (
    <Container>
      <TopicCounter>
        {topicList.length} {topicList.length > 1 ? "topics" : "topic"}
      </TopicCounter>
      {topicList.map((topic, index, array) => {
        const duplicates: string[] = topicList.filter((e, i, a) => a.indexOf(e) !== i);
        return (
          <TopicCol>
            <TopicRow key={index}>
              <StyledTextField
                fullWidth
                placeholder="Enter topic name"
                variant="outlined"
                type="text"
                value={topic}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changeTopic(index, e.target.value)
                }
                $isError={Boolean(duplicates.includes(topic) && index === array.length - 1)}
              />
              <RemoveTopicButton variant="text" onClick={() => removeTopic(index)}>
                <DeleteIcon />
              </RemoveTopicButton>
            </TopicRow>
            {Boolean(duplicates.includes(topic) && index === array.length - 1) && (
              <Error>You have already added this topic</Error>
            )}
          </TopicCol>
        );
      })}
      <AddNewTopicButton
        variant="text"
        onClick={addNewTopic}
        disabled={
          topicList.includes("") || topicList.filter((e, i, a) => a.indexOf(e) !== i).length > 0
        }
      >
        <AddIcon />
        Add new topic
      </AddNewTopicButton>
      <ButtonsWrapper>
        <SaveButton
          variant="contained"
          disabled={
            topicList.includes("") ||
            Boolean(JSON.stringify(topicList) === JSON.stringify(props.topicList))
          }
          onClick={editTopicList}
        >
          Save changes
        </SaveButton>
        <CancelButton variant="outlined" onClick={() => props.changeIsShowConstructor(false)}>
          Cancel
        </CancelButton>
      </ButtonsWrapper>
    </Container>
  );
});

export default TopicConstructor;

const Container = styled.div`
  width: 100%;
  margin-top: 24px;
`;

const AddNewTopicButton = styled(Button)`
  min-width: 0px !important;
  padding: 0px !important;
  text-transform: none !important;
  font-size: 14px !important;
  margin-top: 16px !important;

  svg {
    font-size: 16px;
  }
`;

const TopicCounter = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
  margin-bottom: 8px;
`;

const TopicRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 20px;
  column-gap: 12px;
  margin-top: 8px;
`;

const RemoveTopicButton = styled(Button)`
  min-width: 0px !important;
  padding: 0px !important;
  text-transform: none !important;
`;

const StyledTextField = styled(TextField) <{ $isError?: boolean }>`
  fieldset {
    border-width: 1px !important;
    ${(props) => props.$isError && "border-color: var(--colorPaletteRedForeground1) !important"};
  }

  input {
    padding: 6px 12px !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    color: var(--colorNeutralForeground1) !important;

    &::placeholder {
      font-family: Roboto !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 22px !important;
      color: var(--colorNeutralForeground6) !important;
    }
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const SaveButton = styled(Button)`
  height: 36px !important;
  text-transform: none !important;
  color: var(--colorNeutralBackground1) !important;
  margin-right: 8px !important;
`;

const CancelButton = styled(Button)`
  height: 36px !important;
  text-transform: none !important;
  background-color: var(--colorNeutralBackground1); !important
  color: var(--colorBrandForeground1) !important;
  border-color: var(--colorNeutralBackground3) !important;
`;

const Error = styled.p`
  margin-top: 4px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--colorPaletteRedForeground2);
`;

const TopicCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
