import http from "api/https";

export const removeFromRoles = (
  idRole: number,
  users_ids: number[],
  real_id: number,
  access_level_code: string
): Promise<any> => {
  if (http.isAPIGO) {
    return http.postGO("/permission/role/RemoveFromRoles", {
      idRole: idRole,
      users_ids: users_ids,
      real_id: real_id,
      access_level_code: access_level_code,
    });
  } else {
    return http.post("/role/RemoveFromRoles", {
      idRole: idRole,
      users_ids: users_ids,
      real_id: real_id,
      access_level_code: access_level_code,
    });
  }
};
