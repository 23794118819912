import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
margin: 2px 5px 0px 0px;
color: #1477F8 !important;
`;

export const RemoveIcon = (
  props: React.HTMLAttributes<HTMLOrSVGElement>
) => (
  <StyledSvg
    width="11px"
    height="11px"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6.46867 5.49941L10.7968 1.17771C10.9262 1.04833 10.9988 0.872856 10.9988 0.689887C10.9988 0.506917 10.9262 0.331442 10.7968 0.202063C10.6674 0.0726843 10.492 0 10.309 0C10.1261 0 9.95061 0.0726843 9.82124 0.202063L5.5 4.53064L1.17876 0.202063C1.04939 0.0726843 0.873935 1.62448e-07 0.690986 1.63812e-07C0.508036 1.65175e-07 0.332579 0.0726843 0.203214 0.202063C0.073849 0.331442 0.0011726 0.506917 0.0011726 0.689887C0.0011726 0.872856 0.073849 1.04833 0.203214 1.17771L4.53133 5.49941L0.203214 9.82112C0.138822 9.88499 0.0877136 9.96098 0.0528354 10.0447C0.0179572 10.1284 0 10.2182 0 10.3089C0 10.3996 0.0179572 10.4894 0.0528354 10.5732C0.0877136 10.6569 0.138822 10.7329 0.203214 10.7968C0.26708 10.8612 0.343063 10.9123 0.42678 10.9472C0.510498 10.982 0.600293 11 0.690986 11C0.781678 11 0.871473 10.982 0.955191 10.9472C1.03891 10.9123 1.11489 10.8612 1.17876 10.7968L5.5 6.46819L9.82124 10.7968C9.88511 10.8612 9.96109 10.9123 10.0448 10.9472C10.1285 10.982 10.2183 11 10.309 11C10.3997 11 10.4895 10.982 10.5732 10.9472C10.6569 10.9123 10.7329 10.8612 10.7968 10.7968C10.8612 10.7329 10.9123 10.6569 10.9472 10.5732C10.982 10.4894 11 10.3996 11 10.3089C11 10.2182 10.982 10.1284 10.9472 10.0447C10.9123 9.96098 10.8612 9.88499 10.7968 9.82112L6.46867 5.49941Z" fill="#1477F8"/>

  </StyledSvg>
);