import { FC, useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import {
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import store from "./store";
import { useLocation } from "react-router";
import SurveyAccessContent from "./FirstAccordion/SurveyAccessAccordionContent";
import ScheduleCommunication from "./SecondAccordion/ScheduleCommunication";
import SendReport from "./ThirdAccordion/SendReport";
import CustomModal from "components/modal/Modal";
import CommsModal from "./Settings/CommsModal";
import { PreviewTemplate } from "./PreviewTemplate";
import SelectTemplate from "features/Survey/SelectTemplate/cardGrid";
import selectStore from "features/Survey/SelectTemplate/storeSurvey";
import { useNavigate, useBlocker } from "react-router-dom";
import CustomButton from "components/button/Button";
import ChevronCircleIcon from "features/Survey/Recipients/ChevronCircleIcon";
import { createUrl } from "helpers/url";
import { newQuestions } from "types/surveys";
import { DiscardPopup } from "layouts/SurveyLayout/discardPopup/DiscardPopup";

type AddEditTemplateProps = {};

const AddEditTemplate: FC<AddEditTemplateProps> = observer(() => {
  const navigate = useNavigate();

  let query = useQuery();
  let idTemplate = query.get("id") != null ? query.get("id") : "0";

  useEffect(() => {
    if (idTemplate === "0") {
      store.changeSelectTemplate(true);
    } else {
      store.LoadAllData(Number(idTemplate));
      store.changeIdTemplate(Number(idTemplate));
    }
  }, []);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (store.checkLeaveHandler) {
      return currentLocation.search !== nextLocation.search;
    }
  });

  const navigateTemplateId = (id: number) => {
    const nav = createUrl({
      path: "/communication-template",
      query: {
        id: String(id),
      },
    });
    return navigate(nav);
  };

  const myOwnQuestionsHandler = () => {
    if (store.idTemplate === 0) {
      store.addTemplate((id: number) => navigateTemplateId(id));
    }
    store.changeSelectTemplate(false);
    selectStore.clearStore();
  };

  const handleHideModal = () => {
    selectStore.clearStore();
    myOwnQuestionsHandler();
    store.changeSelectTemplate(false);
  };

  const handleOnQuestionsAdded = (questions: newQuestions[], commTemplateId: number) => {
    store.changeSelectTemplate(false);
    store.copyCommsTemplateToTemplate(commTemplateId, (id: number) => {
      navigateTemplateId(id);
      store.LoadAllData(Number(id));
      store.changeIdTemplate(Number(id));
    });
  };

  return (
    <StyledContainer sx={{ pb: 15 }}>
      <DiscardPopup
        open={blocker.state === "blocked"}
        closeHandler={() => blocker.reset()}
        cancelHandler={() => blocker.reset()}
        confirmHandler={() => blocker.proceed()}
        title="Leave page and discard changes?"
        description="All changes made will not be saved."
        cancel="Cancel"
        confirm="Leave and discard"
      />
      <CustomModal
        open={store.openCommsModal}
        id={"Template_Communication_Settings"}
        hide={(x) => (store.openCommsModal = x)}
      >
        <CommsModal id={Number(idTemplate)} onSave={(name) => store.setAllInfoName(name)} />
      </CustomModal>

      <StyledCustomModal
        padding="0px"
        id="Template_Select"
        open={store.openSelectTemplate}
        hide={handleHideModal}
        width="100%"
        maxWidth="xl"
      >
        <SelectTemplate
          project_id={0}
          templateType="communication"
          fromTemplate={true}
          MyOwnQuestionsClicked={() => myOwnQuestionsHandler()}
          onQuestionsAdded={handleOnQuestionsAdded}
        />
      </StyledCustomModal>

      <PreviewTemplate />
      <TitleWrapper>
        <TextCommunication id="Template_Communication_Title">
          {store.AllInfo !== null && store.AllInfo.name !== null
            ? store.AllInfo.name
            : "Comms title template"}
          <IconButton
            id="Template_Communication_Title_Edit"
            title="Edit"
            onClick={() => store.setOpenCommsModal(true)}
          >
            <EditIconStyle />
          </IconButton>
        </TextCommunication>
      </TitleWrapper>

      <AccordionWrapped
        expanded={store.expandedTab === "panel1"}
        onChange={store.handleTabChange("panel1")}
        id="Template_Communication_Accordion_Access"
      >
        <AccordionSummaryWrapped
          expandIcon={<ChevronCircleIcon position="bottom" />}
          aria-controls="panel4bh-content"
          id="panel1bh-header"
        >
          <AccordionSummaryDiv>
            <AccordionText id="Template_Communication_Accordion_Access_Label">
              Survey access
            </AccordionText>
          </AccordionSummaryDiv>
        </AccordionSummaryWrapped>
        <AccordionDetails>
          <SurveyAccessContent />
        </AccordionDetails>
      </AccordionWrapped>

      <AccordionWrapped
        expanded={store.expandedTab === "panel2"}
        onChange={store.handleTabChange("panel2")}
        id="Template_Communication_Accordion_Schedule"
      >
        <AccordionSummary
          expandIcon={<ChevronCircleIcon position="bottom" />}
          aria-controls="panel4bh-content"
          id="panel2bh-header"
        >
          <AccordionSummaryDiv>
            <AccordionText id="Template_Communication_Accordion_Schedule_Label">
              Schedule communications
            </AccordionText>
          </AccordionSummaryDiv>
        </AccordionSummary>
        <AccordionDetails>
          <ScheduleCommunication />
        </AccordionDetails>
      </AccordionWrapped>

      <AccordionWrapped
        expanded={store.expandedTab === "panel3"}
        onChange={store.handleTabChange("panel3")}
        id="Template_Communication_Accordion_Report"
      >
        <AccordionSummary
          expandIcon={<ChevronCircleIcon position="bottom" />}
          aria-controls="panel4bh-content"
          id="panel3bh-header"
        >
          <AccordionSummaryDiv>
            <AccordionText id="Template_Communication_Accordion_Report_Label">
              Send report when survey ends
            </AccordionText>
          </AccordionSummaryDiv>
        </AccordionSummary>
        <AccordionDetails>
          <SendReport />
        </AccordionDetails>
      </AccordionWrapped>
    </StyledContainer>
  );
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const StyledContainer = styled(Container)`
  .MuiAccordion-root {
    border: 1px solid var(--colorNeutralBackground1);
  }

  .MuiAccordion-root.Mui-expanded {
    border: 1px solid var(--colorPaletteBlueBackground1);
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
`;

const TextCommunication = styled.span`
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0;
`;

const AccordionText = styled.span`
  color: var(--colorNeutralForeground1);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const AccordionWrapped = styled(Accordion)`
  margin: 0 0 20px 0;
  border-radius: 10px !important;
  box-shadow: none !important;
  &::before {
    content: none !important;
  }
`;

const AccordionSummaryWrapped = styled(AccordionSummary)``;

const AccordionSummaryDiv = styled.div`
  margin: 20px;
`;

const StyledCustomModal = styled(CustomModal)``;

const EditIconStyle = styled(EditIcon)`
  color: var(--colorBrandForeground1);
`;

export default AddEditTemplate;
