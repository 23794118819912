import { Button } from "@mui/material";
import { observer } from "mobx-react";
import { FC, useEffect } from "react";

import store from "./store";
import styled from "styled-components";
import EditIcon from "@mui/icons-material/Edit";
import topStore from "../../store";
import responsesStore from "../store";

type TopicsListProps = {
  localFilters: { id: number; name: string; segments: string[] }[];
};

const TopicsList: FC<TopicsListProps> = observer((props) => {
  useEffect(() => {
    if (
      topStore.selectedSurvey &&
      topStore.activeTab === "responses" &&
      topStore.responsesActiveLocalTab === "open_ended"
    ) {
      store.getDashboardResponsesOpenEndedTopics(topStore.selectedSurvey.value, props.localFilters);
    }
  }, [
    topStore.selectedSurvey,
    topStore.activeTab,
    topStore.responsesActiveLocalTab,
    props.localFilters,
    topStore.overviewBenchmark,
    store.activeOpenEndedQuestion,
  ]);

  return (
    <Container>
      <Label>
        Topics: {store.openEndedTopicsList?.length} (Based on {store.openEndedTopicsCount}{" "}
        responses)
      </Label>
      <TopicList>
        {store.openEndedTopicsList.map((topic, index) => (
          <Topic key={topic.Title + index}>
            <Group>
              <TopicTitle $isHaveTopics={topic.withTopic}>
                {topic.withTopic
                  ? `${topic.Title[0]?.toUpperCase()}${topic.Title.slice(1)}`
                  : "Responses with no topic"}
              </TopicTitle>
              {topic.withTopic && (
                <StyledButton
                  variant="text"
                  onClick={() => {
                    store.setData(topic, "activeOpenEndedTopic");
                    store.setData(true, "isOpenEditTopicPopup");
                  }}
                >
                  <StyledEditIcon />
                </StyledButton>
              )}
            </Group>
            <Group>
              <ResponseCount>
                {topic.responsesCount} {topic.responsesCount > 1 ? "responses" : "response"}
              </ResponseCount>
              <StyledButton
                variant="text"
                onClick={() => {
                  store.setData(true, "isOpenTopicResponseDetail");
                  store.setData(topic, "activeOpenEndedTopic");
                }}
              >
                View
              </StyledButton>
            </Group>
          </Topic>
        ))}
      </TopicList>
    </Container>
  );
});

export default TopicsList;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: var(--colorNeutralForeground2);
  margin-bottom: 12px;
`;

const TopicList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Topic = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border: 1px solid var(--colorNeutralBackground3);
  background-color: var(--colorNeutralForeground8);
  margin-top: 12px;
  border-radius: 6px;
`;

const Group = styled.div`
  display: flex;
  align-items: center;
`;

const TopicTitle = styled.p<{ $isHaveTopics: boolean }>`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: ${(props) =>
    props.$isHaveTopics ? "var(--colorNeutralForeground1)" : "var(--colorNeutralForeground5)"};
  margin-right: 4px;
`;

const StyledButton = styled(Button)`
  text-transform: none !important;
  padding: 2px !important;
  min-width: 0px !important;
`;

const StyledEditIcon = styled(EditIcon)`
  font-size: 16px !important;
`;

const ResponseCount = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--colorNeutralForeground1);
  margin-right: 14px;
`;
