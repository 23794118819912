import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg``;

export const StarIcon = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
    <StyledSvg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3.92683 10.1795L1 13M5.97561 2V4.53846H3.34146L4.80488 6.51282L3.34146 8.20513H5.39024V11.0256L8.02439 9.61538L10.0732 11.0256V8.76923H13L10.9512 6.51282L13 4.82051H10.0732V2L8.02439 3.97436L5.97561 2Z"
            stroke="#1477F8"
            strokeWidth="1.2"
        />
    </StyledSvg>
);
