import { FC } from "react";
import { observer } from "mobx-react-lite";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from "components/button/Button";
import ExportRecipientsTable from "./ExportRecipientsTable";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";
import { ClearIcon } from "@mui/x-date-pickers";

type ExportRecipietnsProps = {};

const ExportRecipietns: FC<ExportRecipietnsProps> = observer(() => {
  return (
    <Container>
      <Header id="Survey_EditCreate_Recipietns_Export_Title">
        {store.Survey?.project_type === SEND_OUT_SURVEY_TYPES.Milestones
          ? "Milestone Recipients"
          : "Survey Recipients"}
      </Header>
      <SettingsWrapper>
        <ExportButton
          id="Survey_EditCreate_Recipietns_Export_ExportAllRecipients_Button"
          variant="outlined"
          disabled={!store.exportRecipientsList.length}
          onClick={() => {
            store.saveExcel();
          }}
        >
          Export all recipients [.xlsx ]
        </ExportButton>
        <Box>
          <StyledSearchInput
            id="Survey_EditCreate_Recipietns_Export_SearchInput"
            style={{ width: "200px", margin: "5px 5px 5px 12px" }}
            size="small"
            placeholder="Search for name/email"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    id="Survey_EditCreate_Recipietns_Export_SearchInput_Button"
                    onClick={() => {
                      store.getAllRecipients();
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                  {store.exportSearchValue &&
                  <IconButton
                    id="Survey_EditCreate_Recipietns_Import_SearchInput_Button"
                    onClick={() =>{
                      store.exportSearchValue = ""
                      store.getAllRecipients()
                    } }
                  >
                    <CloseIcon />
                  </IconButton>}
                </InputAdornment>
              ),
            }}
            value={store.exportSearchValue}
            onKeyDown={store.keyPressExport}
            onChange={(e) => store.searchExportRecipients(e.target.value)}
          />
        </Box>
      </SettingsWrapper>

      {!!store.resultexportSearchValue && (
        <ResultSearch id="Survey_EditCreate_Recipietns_Export_ResultSearch">
          {!store.exportRecipientsList.length ? (
            <SearchText id="Survey_EditCreate_Recipietns_Export_ResultSearch_Text">
              "
              <SearchTextValue id="Survey_EditCreate_Recipietns_Export_ResultSearch_Name">
                {store.resultexportSearchValue}
              </SearchTextValue>
              " No employees match the provided details. Please try using a different search
              keyword.
            </SearchText>
          ) : (
            <SearchText id="Survey_EditCreate_Recipietns_Export_ResultSearch_Text">
              "
              <SearchTextValue id="Survey_EditCreate_Recipietns_Export_ResultSearch_Name">
                {store.resultexportSearchValue}
              </SearchTextValue>
              " found on the employee list.
            </SearchText>
          )}

          <RemoveSearch>
            <CustomButton
              id="Survey_EditCreate_Recipietns_Export_ResultSearch_Remove_Button"
              onClick={() => {
                store.setData("resultexportSearchValue", "");
                store.setData("exportSearchValue", "");
                store.getAllRecipients()
              }}
            >
              <ClearIcon /> Remove search
            </CustomButton>
          </RemoveSearch>
        </ResultSearch>
      )}

      <ExportRecipientsTable />
    </Container>
  );
});

export default ExportRecipietns;

const Container = styled.div`
  width: 50%;
  height: calc(100vh - 128px);
  display: flex;
  flex-direction: column;
  background-color: var(--colorNeutralForeground4);
  padding: 74px 62px;

  @media (max-width: 1450px) {
    width: 100%;
  }
`;

const Header = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
  text-transform: uppercase;
`;

const SettingsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ResultSearch = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--colorPaletteBlueBackground3);
  margin-top: 24px;
  height: 50px;
`;

const SearchText = styled.div`
  margin-left: 16px;
`;

const SearchTextValue = styled.span`
  font-weight: 500;
`;

const RemoveSearch = styled.div`
  margin-right: 24px;
`;

const ExportButton = styled(CustomButton)`
  height: 34px !important;
  border: 1px solid var(--colorNeutralForeground6) !important;
  margin-right: 20px !important;
  &:disabled {
    color: var(--colorBrandForeground2) !important;
  }
`;

const StyledSearchInput = styled(TextField)`
  width: 100% !important;
  max-width: 246px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 4px !important;
  margin: 0 !important;

  div {
    border-radius: 4px !important;
  }

  input {
    padding: 7px 11px !important;
    border-radius: 2px !important;
    font-size: 14px;
  }

  svg {
    font-size: 18px;
    color: var(--colorNeutralForeground6);
  }
`;
