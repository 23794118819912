import http from "api/https";
import { createUrl } from "../helpers/url";

export const getSurveyQuestionDimensions = (survey_id: number): Promise<any> => {
  const query: { survey_id: string } = { survey_id: survey_id.toString() };
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/sm/sm_question/GetBysurvey_id",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/sm_question/GetBysurvey_id",
      query: query,
    });
    return http.get(url);
  }
};
