import { FC, ReactElement } from "react";
import styled from "styled-components";

import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { observer } from "mobx-react";

type AccountInfoCardProps = {
  handleVisibleResetPwdPopup: (state: boolean) => void;
};

const AccountInfoCard: FC<AccountInfoCardProps> = observer((): ReactElement => {
  return (
    <Card>
      <RowLabel>Preferred name</RowLabel>
      <Text>{StoreLayout.currentUserPreferredName}</Text>
      <RowLabel>Full name</RowLabel>
      <Text>{StoreLayout.currentUserFullName}</Text>
      <RowLabel>Email address</RowLabel>
      <AccountsList>
        <AccountWrapper key={StoreLayout.currentUserId} $lastItem={true}>
          <Email>{StoreLayout.currentUserEmail}</Email>
        </AccountWrapper>
      </AccountsList>
    </Card>
  );
});

export default AccountInfoCard;

const Card = styled.div`
  width: 100%;
  height: fit-content;
  max-width: 610px;
  padding: 40px;
  border-radius: 10px;
  background-color: var(--colorNeutralBackground1);
  margin-right: 32px;
`;

const RowLabel = styled.h2`
  width: 100%;
  text-align: start;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 8px;
  color: var(--colorNeutralForeground2);
`;

const Text = styled.p`
  width: 100%;
  text-align: start;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 24px 0;
  color: var(--colorNeutralForeground3);
`;

const AccountsList = styled.div`
  width: 100%;
  margin-top: 8px;
`;

const AccountWrapper = styled.div<{ $lastItem: boolean }>`
  width: 100%;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 var(--colorNeutralBackground3);
  margin-bottom: ${(props) => (props.$lastItem ? "0px" : "16px")};
  display: flex;
  flex-direction: column;
`;

const Email = styled.span`
  width: 100%;
  text-align: start;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground3);
`;
