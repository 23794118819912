import * as React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import CustomButton from "components/button/Button";
import store from "./store";

const UnitHeads = observer(() => {
  const [selectedRow, setSelectedRow] = React.useState<number[]>([]);

  React.useEffect(() => {
    const filteredLevels = store.unitHeadsByLevel.filter((el) => selectedRow?.includes(el.level));
    setSelectedRow(filteredLevels.map((el) => el.level));
  }, [store.unitHeadsByLevel]);

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    const newValue = event.target.value as number[];
    setSelectedRow(newValue);
  };

  const filteredItems =
    selectedRow.length > 0
      ? store.unitHeadsByLevel.filter((item) => selectedRow.includes(item.level))
      : store.unitHeadsByLevel;

  return (
    <UnitHeadsLevel>
      <SegmentsHeaderRight>
        <StyledTextField
          id={`Employees_OrganizationalStructure_Unit_SearcInput`}
          size="small"
          placeholder="Search for level/unit/name/email"
          disabled={selectedRow.length > 0}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  id={`Employees_OrganizationalStructure_Unit_SearcBtn`}
                  disabled={selectedRow.length > 0}
                  onClick={() => store.onSearchClicked()}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={store.searchedValue}
          onKeyDown={(e) => e.keyCode === 13 && store.onSearchClicked()}
          onChange={(e) => store.changeValue("searchedValue", e.target.value)}
        />
        <StyledContainerAutoComplete>
          <h3>Filter Levels</h3>
          <FormControl fullWidth>
            <StyledAutocomplete
              multiple
              displayEmpty
              value={selectedRow}
              onChange={handleSelectChange}
              renderValue={() => <StyledPlaceholderSelect>Select level</StyledPlaceholderSelect>}
              IconComponent={KeyboardArrowDownIcon}
            >
              {store.unitHeadsByLevel.map((el) => (
                <StyledOption key={el.level} value={el.level}>
                  Level {el.level}
                </StyledOption>
              ))}
            </StyledAutocomplete>
          </FormControl>
        </StyledContainerAutoComplete>
      </SegmentsHeaderRight>

      {selectedRow.length > 0 && (
        <StyledContainerChipLevel>
          <p>Filter{selectedRow.length > 1 ? "s:" : ":"}</p>
          {selectedRow.map((el: number) => {
            return (
              <StyledChipLevel key={el}>
                <p>Level {el}</p>
                <IconButton
                  onClick={() => setSelectedRow(selectedRow.filter((item: number) => el !== item))}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </StyledChipLevel>
            );
          })}
        </StyledContainerChipLevel>
      )}

      {store.resultSearchValue !== "" && (
        <ResultSearch>
          {store.unitHeadsByLevel.length === 0 ? (
            <SearchText>
              "<SearchTextValue>{store.resultSearchValue}</SearchTextValue>" No unit/name/email was
              found to match the provided details. Please try using a different search keyword.
            </SearchText>
          ) : (
            <SearchText>
              "<SearchTextValue>{store.resultSearchValue}</SearchTextValue>" can be found in the
              following units/names/emails
            </SearchText>
          )}

          <RemoveSearch>
            <CustomButton
              id={`Employees_OrganizationalStructure_Unit_SearchInputRemove`}
              onClick={() => {
                store.changeValue("resultSearchValue", "");
                store.onSearchClicked();
              }}
            >
              Remove search
            </CustomButton>
          </RemoveSearch>
        </ResultSearch>
      )}

      <Levels>
        <Header id={`Employees_OrganizationalStructure_Unit_Title`}>
          Unit heads of {StoreLayout.currentEntityName} organization structure
        </Header>

        {filteredItems.map((level) => {
          return (
            <StyledGridContainer key={level.level}>
              <LevelName id={`Employees_OrganizationalStructure_Unit_LevelName`}>
                LEVEL {level.level}
              </LevelName>
              <Grid container spacing={3}>
                {level.employees.map((employee, index) => {
                  return (
                    <StyledGrid item lg={12} xl={6} key={employee.email}>
                      <EmployeeBlock>
                        <EmployeeName>
                          <EmployeeFullName
                            id={`Employees_OrganizationalStructure_Unit_Employee_FullName`}
                          >
                            {employee.fullName}
                          </EmployeeFullName>
                          {employee.preferredName && (
                            <EmployeePrefferedName
                              id={`Employees_OrganizationalStructure_Unit_Employee_PreferredName`}
                            >
                              ({employee.preferredName})
                            </EmployeePrefferedName>
                          )}
                        </EmployeeName>

                        <EmployeeEmail id={`Employees_OrganizationalStructure_Unit_Employee_Email`}>
                          {employee.email}
                        </EmployeeEmail>
                      </EmployeeBlock>
                    </StyledGrid>
                  );
                })}
              </Grid>
            </StyledGridContainer>
          );
        })}
      </Levels>
    </UnitHeadsLevel>
  );
});

const StyledTextField = styled(TextField)`
  background-color: var(--colorNeutralBackground1);
  border-radius: 4px !important;
  width: 50%;

  input {
    width: 100%;
  }

  .MuiOutlinedInput-root {
    border-radius: 4px !important;
    border: 1px solid var(--colorPaletteBlueBackground1) !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
`;

const SegmentsHeaderRight = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const StyledContainerAutoComplete = styled.div`
  display: flex;
  align-items: center;
  width: 50%;

  > h3 {
    color: var(--colorNeutralForeground2);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
    width: 140px;
  }
`;

const StyledAutocomplete = styled(Select)`
  width: 100% !important;
  height: 40px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border: 1px solid var(--colorPaletteBlueBackground1) !important;

  input {
    border-radius: 2px !important;
    padding: 0 !important;
    font-family: Roboto, sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    height: 16px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
`;

const StyledPlaceholderSelect = styled.p`
  margin: 3px 0;
  color: var(--colorNeutralForeground5);
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const StyledOption = styled(MenuItem)``;

const StyledContainerChipLevel = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
`;

const StyledChipLevel = styled.div`
  display: flex;
  align-items: center;
  border-radius: 2px;
  background: var(--colorPaletteBlueBackground2);
  > p {
    margin-left: 7px;
    color: var(--colorNeutralBackgroundInverted3);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

const ResultSearch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--colorPaletteBlueBackground3);
  margin-top: 24px;
  height: 50px;
`;

const Header = styled.div`
  color: var(--colorNeutralForeground1);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const Levels = styled.div`
  margin: 20px 0;
`;

const LevelName = styled.div`
  margin: 20px 0 10px 0;
  color: var(--colorNeutralForeground2);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
`;

const EmployeeBlock = styled.div`
  border-radius: 4px;
  border: 1px solid var(--colorNeutralForeground4);
  background: var(--colorNeutralBackground1);
  padding: 20px 10px;
`;

const EmployeeName = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const EmployeeFullName = styled.span`
  color: var(--colorNeutralForeground1);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const EmployeePrefferedName = styled.span`
  color: var(--colorNeutralForeground2);
`;

const EmployeeEmail = styled.span`
  color: var(--colorNeutralForeground2);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const StyledGridContainer = styled.div``;

const StyledGrid = styled(Grid)``;

const SearchText = styled.div`
  margin-left: 16px;
`;
const SearchTextValue = styled.span`
  font-weight: 500;
`;

const RemoveSearch = styled.div`
  margin-right: 24px;
`;

const UnitHeadsLevel = styled.div`
  margin: 50px;
`;

export default UnitHeads;
