import http from "api/https";
import { TRecipientsFilterSearch } from "types/recipients";

export type Employee = {
  id: number;
  email: string;
  fullName: string;
  unique_id: string;
  preferredName: string;
  attributes: EmployeeAttributes[];
};

export type EmployeeAttributes = {
  id: number;
  value: string;
  entity_attribute_id: number;
  entity_user_id: number;
  level: number;
};

export const GetEmployeesWithAttrByEntity = async (
  idEntity: number,
  search: string,
  perPage: number,
  numberPage: number,
  attributes: TRecipientsFilterSearch[],
  sortType: string,
  sortField: string,
  sortAttributeId: number,
  sortOrganizationId: number
): Promise<any> => {

  if(http.isAPIGO){
    return http.postGO("/employee/employees/GetByFilterAttributes", {
      idEntity: idEntity,
      search: search,
      perPage: perPage,
      numberPage: numberPage,
      attributes: attributes,
      sortType,
      sortField,
      sortAttributeId,
      sortOrganizationId
    });
  }else{
    return http.post("/employees/GetByFilterAttributes", {
      idEntity: idEntity,
      search: search,
      perPage: perPage,
      numberPage: numberPage,
      attributes: attributes,
      sortType,
      sortField,
      sortAttributeId,
      sortOrganizationId
    });
  }

};
