import { FC } from "react";
import { observer } from "mobx-react-lite";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";

import store from "./store";
import styled from "styled-components";
import CheckIcon from "@mui/icons-material/Check";
import StorePermission from "components/workspaces-sidebar/StorePermission";

type SendOutSurveysProps = {};

const SendOutSurveys: FC<SendOutSurveysProps> = observer(() => {
  return (
    <Container>
      <Box>
        {StorePermission.features.frequency.hasFeature && (
          <TypeItem
            id="Survey_Project_CreatePopup_SendSurveys_TypeProject_Frequency"
            onClick={() => store.setData(SEND_OUT_SURVEY_TYPES.Frequency, "sendOutSurveysType")}
            $active={store.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.Frequency}
          >
            <TypeItemName id="Survey_Project_CreatePopup_SendSurveys_TypeProject_Frequency_Name">
              Frequency
            </TypeItemName>
            <CheckButton
              id="Survey_Project_CreatePopup_SendSurveys_TypeProject_Frequency_CheckButton"
              $active={store.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.Frequency}
            >
              {store.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.Frequency && <CheckIcon />}
            </CheckButton>
          </TypeItem>
        )}
        {StorePermission.features.one_off.hasFeature && (
          <TypeItem
            id="Survey_Project_CreatePopup_SendSurveys_TypeProject_One-off"
            onClick={() => store.setData(SEND_OUT_SURVEY_TYPES.OneOff, "sendOutSurveysType")}
            $active={store.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.OneOff}
          >
            <TypeItemName id="Survey_Project_CreatePopup_SendSurveys_TypeProject_One-off_Name">
              One-off
            </TypeItemName>
            <CheckButton
              id="Survey_Project_CreatePopup_SendSurveys_TypeProject_One-off_CheckButton"
              $active={store.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.OneOff}
            >
              {store.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.OneOff && <CheckIcon />}
            </CheckButton>
          </TypeItem>
        )}
        {StorePermission.features.milestone.hasFeature && (
          <TypeItem
            id="Survey_Project_CreatePopup_SendSurveys_TypeProject_Milestones"
            onClick={() => store.setData(SEND_OUT_SURVEY_TYPES.Milestones, "sendOutSurveysType")}
            $active={store.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.Milestones}
          >
            <TypeItemName id="Survey_Project_CreatePopup_SendSurveys_TypeProject_Milestones_Name">
              Milestones
            </TypeItemName>
            <CheckButton
              id="Survey_Project_CreatePopup_SendSurveys_TypeProject_Milestones_CheckButton"
              $active={store.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.Milestones}
            >
              {store.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.Milestones && <CheckIcon />}
            </CheckButton>
          </TypeItem>
        )}
      </Box>
    </Container>
  );
});

export default SendOutSurveys;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 18px 0px 43px 0px;
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TypeItem = styled.div<{ $active: boolean }>`
  width: 100%;
  max-width: 187px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px 20px 20px;
  border: ${(props) =>
    props.$active
      ? "1px solid var(--colorBrandForeground1)"
      : "1px solid var(--colorPaletteBlueBackground1)"};
  border-radius: 4px;
  cursor: pointer;
`;

const TypeItemName = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
`;

const CheckButton = styled.button<{ $active: boolean }>`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  background-color: ${(props) =>
    props.$active ? "var(--colorBrandForeground1)" : "var(--colorNeutralBackground3)"};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--colorNeutralBackground1);
    font-size: 18px;
  }
`;
