import http from "api/https";

export const removeEmployeeRecipients = (
  survey_id: number,
  employees: number[],
  is_all: boolean,
) => {
  let data = {
    survey_id: survey_id,
    employees: employees,
    is_all: is_all
  }
  
  if(http.isAPIGO){
    return http.postGO("sm/sm_survey_recepients/RemoveEmployeeRecipients", data, );
  }else{
    return http.post("/sm_survey_recepients/RemoveEmployeeRecipients", data, );
  }
};
