import styled from "styled-components";
import ErrorIcon from "@mui/icons-material/Error";

export const EmptyLabel = () => (
  <EmptyLabelWrapper>
    <ErrorIcon />
    <EmptyLabelText>
      Results will not be shown when segments from 2 or more attributes are used in filters
    </EmptyLabelText>
  </EmptyLabelWrapper>
);

const EmptyLabelWrapper = styled.div`
  width: 100%;
  max-width: 398px;
  padding: 17px;
  display: grid;
  grid-template-columns: 20px 1fr;
  column-gap: 8px;
  background-color: var(--colorPalleteLightBlue);

  svg {
    color: var(--colorPaletteYellowForeground1);
    margin-right: 12px;
  }
`;

const EmptyLabelText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;
