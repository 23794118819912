import { FC, useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { DialogTitle, DialogContent } from "@mui/material";

import { Autocomplete as AutocompleteComponent } from "components/autocomplete-tags/Autocomplete";
import CustomButton from "components/button/Button";
import store from "./store";

type SurveySettingsProps = {
  id: number;
  onSaved: () => void;
};

const SurveySettings: FC<SurveySettingsProps> = observer((props) => {
  useEffect(() => {
    store.idSurvey = props.id;
    if (props.id !== 0) {
      store.loadTags();
    }
  }, [props.id]);

  return (
    <StyledModalContent aria-label={"Create workspace form"}>
      <StyledDialogTitle id="Survey_Creation_SurveySettings_Title">
        Survey settings
      </StyledDialogTitle>

      <AutocompleteComponent
        data={store.tags}
        selected={store.selectedTagNames}
        onChange={(value) => store.changeSelectedTagNames(value)}
        onRemove={(index) =>
          store.changeSelectedTagNames(store.selectedTagNames.filter((_, i) => i !== index))
        }
        titleId="Survey_Creation_SurveySettings_Tags_Title"
        labelId="Survey_Creation_SurveySettings_Tags_Description"
        fieldId="Survey_Creation_SurveySettings_Tags_Input"
      />
      <ButtonWrapper>
        <StyledNextButton
          id="Survey_Creation_SurveySettings_Save_Button"
          variant="contained"
          onClick={() => store.onSave(() => props.onSaved())}
        >
          Save settings
        </StyledNextButton>
      </ButtonWrapper>
    </StyledModalContent>
  );
});

const StyledModalContent = styled(DialogContent)`
  padding: 0 !important;
`;

const StyledDialogTitle = styled(DialogTitle)`
  padding: 20px 0 !important;
`;

const StyledNextButton = styled(CustomButton)`
  width: 180px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  text-align: center;
`;

export default SurveySettings;
