import http from "../https";

import { TGetSurveyResponseCountAsyncResponse } from "types/dashboard";

type TRequestBody = {
    survey_id?: number;
    project_id?: number;
    benchmarkType: number;
    viewType: string;
    attributes: {
        id: number;
        name: string;
        segments: string[];
    }[];
};

export const getSurveyResponseCountAsync = async (
    data: TRequestBody
): Promise<TGetSurveyResponseCountAsyncResponse> => {
    try {
        const response = await http.post(`/Dashboard/global-count-responce`, data);

        if ([200, 201].includes(response.status)) {
            return response.data;
        }

        throw new Error(`Failed to fetch count responce (HTTP status code: ${response.status})`);
    } catch (error) {
        console.error(error);
    }
};
