import { Tooltip, Typography } from "@mui/material";
import React from "react";

const shortStringLength = (
  value: string,
  length: number = 25,
  placement:
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start" = "top",
) => {
  if (value?.length > length) {
    return (
      <Tooltip
        title={
          <Typography variant="body2" style={{ fontSize: "14px" }}>
            {value}
          </Typography>
        }
        placement={placement}
      >
        {<span>{`${value.substring(0, length)}...`}</span>}
      </Tooltip>
    );
  }
  return value;
};

export default shortStringLength;
