import { FC } from "react";
import { Button } from "@mui/material";
import { observer } from "mobx-react";

import store from "./store";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";

type AddNewTrendCardProps = {};

const AddNewTrendCard: FC<AddNewTrendCardProps> = observer(() => {
  return (
    <Container>
      <Label>Trend chart {store.cardList.length + 1}</Label>
      <AddButton variant="contained" onClick={store.addNewChartCard}>
        <AddIcon />
        Add chart
      </AddButton>
    </Container>
  );
});

export default AddNewTrendCard;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  border: 1px solid var(--colorNeutralBackground3);
  border-radius: 6px;
  background-color: var(--colorNeutralBackground1);
  padding: 32px 48px;
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  color: var(--colorNeutralForeground1);
`;

const AddButton = styled(Button)`
  text-transform: none !important;
  font-size: 14px !important;
  font-weight: 500 !important;

  svg {
    margin-right: 8px;
    font-size: 16px;
  }
`;
