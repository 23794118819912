import { FC } from "react";

import styled from "styled-components";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { observer } from "mobx-react";

type BottomProgressUploadingProps = {
  isHistorical?: boolean;
};

const BottomProgressUploading: FC<BottomProgressUploadingProps> = observer(
  ({ isHistorical = false }) => {
    if (!StoreLayout.showImportProgress) return null;
    const progress = StoreLayout.progress;
    const display = progress !== null ? `(${progress}%)` : "";
    return (
      <UploadingWrapper $isHistorical={isHistorical}>
        <Text>Uploading <Progress>{display}</Progress></Text>
        <ProgressText>{StoreLayout.importProgressText}</ProgressText>
      </UploadingWrapper>
    );
  },
);

export default BottomProgressUploading;

const UploadingWrapper = styled.div<{ $isHistorical?: boolean }>`
  position: fixed;
  bottom: 0;
  right: 75px;
  padding: 16px 20px 18px 29px;
  height: 66px;
  background-color: var(--colorPaletteVioletBackground1);
  max-width: 660px;
  width: 100%;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const Text = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorPalleteLightBlue);
`;

const Progress = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralBackground1);
  margin-left: 10px;
`;

const ProgressText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralBackground1);
  margin-right: 13px;
`;
