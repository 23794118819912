import { makeAutoObservable } from "mobx";
import { SurveyCreator } from "survey-creator-react";
import { getSurveyCreatorOptions } from "./SurveyEditor";
import { QuestionType } from "./sharedTypes";
import { handleSyncRatingScaleOptions } from "./surveyEditorOverrides/handleSyncRatingScaleOptions";
import { handleSyncRatingScaleProperties } from "./surveyEditorOverrides/handleSyncRatingScaleProperties";
import "survey-core/survey.i18n";
import displayWhitelistedProperties from "./surveyEditorOverrides/displayWhiteListedProperties";
import "./surveyEditorOverrides/propertyOverrides";
import handleSetQuestionDefaultValues from "./surveyEditorOverrides/handleSetQuestionDefaultValues";
import displayQuestionTitle from "./surveyEditorOverrides/displayQuestionTitle";
import handlePlaceholderModification from "./surveyEditorOverrides/handlePlaceholderModification";
import { changeToENPSButton } from "./surveyEditorOverrides/handleLimitENPSQuestionType";
import {
  ENPS_QUESTION_TYPE,
  ORDERED_QUESTION_TYPES,
} from "./surveyEditorOverrides/surveyJsConstants";
import config from "./config";
import storeSurvey from "features/Survey/SurveyCreation/store";
import { SURVEY_STATUS } from "constants/survey-status";

export type SurveyEditorMode = "open" | "preview";

class Store {
  creator: SurveyCreator = null;

  constructor() {
    makeAutoObservable(this);
  }

  setData = (json: string) => {
    this.creator.text = json;
  };

  setCreator(editorMode: SurveyEditorMode, onModified: (flag: boolean) => void) {
    const creator = new SurveyCreator({
      ...getSurveyCreatorOptions(editorMode),
      questionTypes: ORDERED_QUESTION_TYPES,
      showHeaderInEmptySurvey: true,
      showTitlesInExpressions: true,
    });

    creator.onSelectedElementChanging.add((sender, options) => {
      var el = options.newSelectedElement;

      if (
        el != null &&
        (el.getType() === "page" || el.getType() === "survey")
      ) {
        options.newSelectedElement = sender.selectedElement;
      }
    });

    creator.haveCommercialLicense = config.surveyJsHaveCommercialLicense;
    creator.autoSaveDelay = config.surveyJsAutoSaveDelay;
    creator.showToolbox = false;
    creator.toolbox.changeCategories([
      {
        name: "comment",
        category: "",
      },
      {
        name: "rating",
        category: "",
      },
      {
        name: "checkbox",
        category: "",
      },
      {
        name: "boolean",
        category: "",
      },
      {
        name: "radiogroup",
        category: "",
      },
      {
        name: ENPS_QUESTION_TYPE,
        category: "",
      },
    ]);

    creator.toolbox.orderedQuestions = ORDERED_QUESTION_TYPES;

    creator.toolbarItems.forEach((toolbarItem) => (toolbarItem.visible = false));

    creator.onShowingProperty.add(displayWhitelistedProperties);
    creator.onQuestionAdded.add(handleSetQuestionDefaultValues);
    creator.onGetObjectDisplayName.add(displayQuestionTitle);
    creator.onPropertyEditorCreated.add(handlePlaceholderModification);
    creator.onPropertyValueChanging.add(function(sender, options) { 
      if (options.propertyName === "locale") {
        options.obj.completedHtml = options.obj.jsonObj.completedHtml[options.newValue] ?? options.obj.jsonObj.completedHtml['default']
      };
    })
    creator.onModified.add((e) => {
      if (onModified) onModified(true);
    });
    // @ts-ignore
    creator.onModified.add(handleSyncRatingScaleOptions({ creator }));
    creator.onModified.add(handleSyncRatingScaleProperties({ creator }));

    creator.onElementAllowOperations.add(function (sender, options) {
      const isCompleteSurvey = storeSurvey.Survey?.status_idCode === SURVEY_STATUS.complete;

      if (isCompleteSurvey) {
        options.allowChangeRequired = false;
        options.allowDelete = false;
        options.allowDragging = false;
        options.allowCopy = false;
        options.allowEdit = false;
      }

      options.allowChangeType = false;
      options.allowChangeInputType = false;
      if (options.obj.getType() === QuestionType.enps) {
        if (options.allowCopy === true) {
          options.allowCopy = false;
        }
      }
    });

    creator.onDefineElementMenuItems.add(changeToENPSButton({ creator }));

    creator.onGetPropertyReadOnly.add(function (editor, options) {
      const isCompleteSurvey = storeSurvey.Survey?.status_idCode === SURVEY_STATUS.complete;
      const isRunningSurvey = storeSurvey.Survey?.status_idCode === SURVEY_STATUS.running;
      const isRatingQuestion = options.obj.getType() === QuestionType.rating;

      if (
        isRunningSurvey && 
        isRatingQuestion && 
        options.propertyName === "rateValues"
      ) {
        options.readOnly = true; 
      }

      if (isCompleteSurvey) {
        options.readOnly = true;
      }
    });

    this.creator = creator;
  }
}

const store = new Store();
export default store;
