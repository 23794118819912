import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import store from '../../store';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import LinkIcon from './LinkIcon';


const MtmLookupRoles = observer(() => {
  return (
    <WrapperAutocomplete>
      <Autocomplete
        multiple
        id="Survey_EditCreate_Communication_SendReport_LookupRoles_Input"
        size="small"
        value={store.Roles.filter(x => store.SelectedRolesIds.includes(x.id))}
        disableCloseOnSelect={true}
        ListboxProps={{ style: { maxHeight: '200px' } }}
        onChange={(e, value) => {
          store.changeReportRoles(value.map(x => x.id))
        }}
        fullWidth
        noOptionsText={<NoOption>
          Permission role does not exist. Go to&nbsp;
          <PermissionLink target='_blank' href="/entityPermissions?nav=role">
            Permission roles <LinkIcon />
          </PermissionLink>
          to create role first.
        </NoOption>}
        options={store.Roles}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField {...params}
            label={<span id={`Survey_EditCreate_Communication_SendReport_LookupRoles_Input_TextInfo`}>Roles</span>} />
        )}
      />
    </WrapperAutocomplete>
  );
})

const WrapperAutocomplete = styled.div`
  width: 100%;
`
const NoOption = styled.div`
  display: flex;
`
const PermissionLink = styled.a`
  display: flex;
  align-items: center;
  color: #1477F8 !important;
  text-decoration: underline;
  svg{
    margin: 0 5px 5px 5px;
  }
`

export default MtmLookupRoles;