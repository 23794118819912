import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCognito } from 'CognitoProvider';

import AccountActivationScreen from 'features/Auth/AccountActivationScreen';
import AccountActivationError from 'features/Auth/AccountActivationError';
import { Backdrop, CircularProgress } from '@mui/material';
import { PasswordResetRequiredException, UserLambdaValidationException } from '@aws-sdk/client-cognito-identity-provider';

const ActivateAccountPage: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const cognitoClient = useCognito();

    const [loading, setLoading] = useState(true);
    const [errorType, setErrorType] = useState<'revoked' | 'accepted' | 'expired' | null>(null);

    const getQueryParams = (search: string) => {
        const params = new URLSearchParams(search);

        return {
            base64EncodedEmail: params.get('u'),
            code: params.get('c')
        };
    };

    const initiateAuth = async (email: string | null, code: string | null) => {
        try {
            if (!email || !code) {
                throw new Error('Missing parameters');
            }

            const { ChallengeName } = await cognitoClient.login(email, code, false);

            if (ChallengeName !== 'NEW_PASSWORD_REQUIRED') {
                navigate('/login');
                return;
            }

            setLoading(false);
        } catch (error: any) {
            setLoading(false);

            if (error instanceof PasswordResetRequiredException) {
                setErrorType('expired');
                return;
            }

            if (error instanceof UserLambdaValidationException) {
                if (error.message === 'PreAuthentication failed with error USER_INVITATION_REVOKED.') {
                    setErrorType('revoked');
                    return;
                }

            }

            navigate('/login');
        }
    };

    useEffect(() => {
        const { base64EncodedEmail, code } = getQueryParams(location.search);
        initiateAuth(atob(base64EncodedEmail || ''), code);
    }, [location.search]);

    if (loading) {
        return (
            <Backdrop sx={{ color: "#fff", zIndex: 1000000 }} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    if (errorType) {
        return <AccountActivationError errorType={errorType} />;
    }

    return <AccountActivationScreen />;
};

export default ActivateAccountPage;