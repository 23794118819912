import { FC } from "react";
import TextField, { TextFieldVariants } from "@mui/material/TextField";
import styled from "styled-components";

type CustomInputProps = {
  id?: string;
  label?: string;
  variant: TextFieldVariants | undefined;
  name?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  type?: string;
  autoComplete?: string;
  value?: string;
  onChange?: (value: any) => void;
};

const CustomInput: FC<CustomInputProps> = (props) => {
  const { variant, ...rest } = props;
  return <StyledTextField variant={variant} {...rest} />;
};

export default CustomInput;

const StyledTextField = styled(TextField)`
  input {
    font-size: 18px;
    color: var(--colorNeutralForeground1);

    &::placeholder {
      font-size: 18px;
      font-weight: 400;
      color: var(--grayInputPlaceholder);
    }
  }
`;
