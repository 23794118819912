import { FC } from "react";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import { Box, Typography } from "@mui/material"
import store from "./store"
import { observer } from "mobx-react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

type CheckCommLanguageProps = {
  closePopup: () => void;
};

const CheckCommLanguage: FC<CheckCommLanguageProps> = observer((props) => {

  return (
    <PopupWrapper>
      <BoxStyled onClick={() => {
        store.changeOpenCommLanguageInconsistency(false)
        store.setOPenTemplateCommModal(true)
      }}>
        <BackButton>
          <KeyboardBackspaceIcon />
        </BackButton>
        <BackToTemplates>Back to templates</BackToTemplates>
      </BoxStyled>
      <BoxStyled>
        <Styledh2>Inconsistency in survey and communications template languages</Styledh2>
      </BoxStyled>
      <BoxStyled>
        <CenterTypography>The survey contains languages not present in the chosen communications template.</CenterTypography>
      </BoxStyled>
      <BtnBox>
        <BtnWrapp>
          <CustomButton
            onClick={() => {
              store.setCommsFromTemplate(store.selectedCommtemplate)
            }}
            variant="contained"
          >
            Proceed to load template
          </CustomButton>
        </BtnWrapp>
        <BtnWrapp>
          <CustomButton
            onClick={() => {
              store.changeOpenCommLanguageInconsistency(false)
            }}
            variant="outlined"
          >
            Cancel
          </CustomButton>
        </BtnWrapp>
      </BtnBox>
    </PopupWrapper >
  );
});

const PopupWrapper = styled.div`
  height: 350px;
`
const CenterTypography = styled(Typography)`
  margin: auto !important;
  padding: 20px;
  margin-bottom: 40px;
  border-radius: 5px;
`

const Styledh2 = styled.h2`
  margin: 20px 20px 0px 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
`

const BoxStyled = styled(Box)`
  margin: 20px;
  display: flex;
  justify-content: start;
  align-items: center;
`

const BtnBox = styled(Box)`
  margin: 20px;
  display: flex;
`

const BtnWrapp = styled(Box)`
  margin: 10px;
`

const BackToTemplates = styled.span`
  cursor: pointer;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground5);
`;

const BackButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--colorNeutralForeground5);
  border: none;
  margin-right: 8px;

  svg {
    fill: var(--colorNeutralBackground1);
    width: 16px;
  }
`;




export default CheckCommLanguage;
