import { FC } from "react";
import { observer } from "mobx-react";
import { TProject } from "types/dashboard";
import { MenuItem, Tooltip, TooltipProps, tooltipClasses } from "@mui/material";

import store from "./store";
import styled from "styled-components";

type ProjectSelectorItemProps = {
    project: TProject;
    handleChange: (project: TProject) => void;
    idDisableProjectItem: (projectType: number) => boolean;
};

const ProjectSelectorItem: FC<ProjectSelectorItemProps> = observer((props) => {
    const { project, handleChange, idDisableProjectItem } = props;

    const isDisabled = idDisableProjectItem(project.Type);

    return (
        <CustomWidthTooltip
            key={project.id}
            title={
                "This survey is a one-off survey. Trend results are only available for frequency and milestone surveys."
            }
            placement="top"
            arrow
            disableHoverListener={!isDisabled}
        >
            <StyledMenuItem
                key={project.id}
                value={project.id}
                onClick={() => handleChange(project)}
                $active={store.selectedProject?.id === project.id}
                $isDisabled={isDisabled}
            >
                {project.name}
            </StyledMenuItem>
        </CustomWidthTooltip>
    );
});

export default ProjectSelectorItem;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 200,
        fontWeight: 500,
        fontSize: "12px",
        textAlign: "center",
        background: "var(--colorShadowInverted2)",
    },
});

const StyledMenuItem = styled(MenuItem) <{ $active: boolean; $isDisabled: boolean }>`
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  color: ${(props) =>
        props.$isDisabled
            ? "var(--colorNeutralForegroundLightGray)"
            : "var(--colorNeutralForeground1)"} !important;
  cursor: ${(props) => (props.$isDisabled ? "not-allowed" : "pointer")} !important;
  border-bottom: 1px solid var(--colorPaletteBlueBackground3) !important;
  min-height: 38px !important;
  background-color: ${(props) =>
        props.$active ? "var(--colorPalleteLightBlue)" : "transparent"} !important;
`;
