import { ReactElement } from "react";

const QuestionsIcon = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66536 3H0.332031V12.3333C0.332031 13.0667 0.932031 13.6667 1.66536 13.6667H10.9987V12.3333H1.66536V3ZM12.3333 0.335938H4.33333C3.6 0.335938 3 0.935938 3 1.66927V9.66927C3 10.4026 3.6 11.0026 4.33333 11.0026H12.3333C13.0667 11.0026 13.6667 10.4026 13.6667 9.66927V1.66927C13.6667 0.935938 13.0667 0.335938 12.3333 0.335938ZM11.6667 6.33333H5V5H11.6667V6.33333ZM9 9.00521H5V7.67188H9V9.00521ZM11.6667 3.66927H5V2.33594H11.6667V3.66927Z"
        fill="#707882"
      />
    </svg>
  );
};

export default QuestionsIcon;
