import { Link } from "./components/link/Link";
import { Input } from "./components/form/input/Input";
import { Checkbox } from "./components/form/checkbox/Checkbox";
import { FC, useState } from "react";
import { Form, Formik } from "formik";
import { useSearchParams } from "react-router-dom";
import { useCognito } from "CognitoProvider";

import * as Yup from "yup";
import styled from "styled-components";
import Button from "../../components/button/Button";
import { NotAuthorizedException, UserLambdaValidationException, UserNotFoundException } from "@aws-sdk/client-cognito-identity-provider";
import { Backdrop, CircularProgress } from "@mui/material";

const schema = Yup.object().shape({
  email: Yup.string().email("Email format is invalid").required(""),
  password: Yup.string().required(""),
});

const LoginScreen: FC = () => {
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const { login } = useCognito();

  const submitForm = async (values: any, { setErrors }: any) => {
    try {
      setLoading(true);
      await login(values.email, values.password, values.remember);
      window.location.href = "/select-workspace";
    } catch (error) {
      if (error instanceof NotAuthorizedException || error instanceof UserNotFoundException) {
        setError("Invalid email or password");
        return;
      }

      if (error instanceof UserLambdaValidationException && error.message === "PreAuthentication failed with error LOGIN_ATTEMPT_LIMIT_EXCEEDED.") {
        setError("Your account has been locked for 15 mins due to many failed attempts. Please try again later.");
        return;
      }


      setError("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 1000000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <LoginWrapper>
        <Content>
          <FormContent>
            <FormLeftContainer className="left">
              <img src="/assets/er-login-sidebar.png" alt="sign-in-logo" />
            </FormLeftContainer>
            <FormRightContainer>
              <FormName>Sign In</FormName>
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={schema}
                onSubmit={submitForm}
              >
                {({ isValid, dirty }) => (
                  <Form>
                    {error && <Warning role="alert">{error}</Warning>}
                    <Input
                      type="email"
                      name="email"
                      label="Email address"
                      id="email"
                      placeholder="Enter email address"
                      className={"login-input"}
                      required
                    />
                    <Input
                      type="password"
                      name="password"
                      label="Password"
                      id="password"
                      placeholder="Enter password"
                      className={"login-input"}
                      required
                    />
                    <CheckboxContainer className="field remember-and-forgot-password">
                      <Checkbox label="Remember me" name="remember" labelPosition="right" />
                      <StyledLink
                        to={`/forgot-password?` + searchParams}
                        customClass="forgot-password"
                        activeClassName="forgot password"
                        text="Forgot password?"
                      />
                    </CheckboxContainer>
                    <ButtonWrapper className="action">
                      <LoginButton
                        type="submit"
                        name="submit"
                        variant="contained"
                        disabled={!dirty || !isValid}
                      >
                        Sign in
                      </LoginButton>
                    </ButtonWrapper>
                  </Form>
                )}
              </Formik>
              <BottomLogo className="bottom-logo">
                <img src="/assets/er-logo-light.png" alt="EngageRocket-logo" />
              </BottomLogo>
            </FormRightContainer>
          </FormContent>
          <BottomContainer>
            <BottomText>COPYRIGHT © ENGAGEROCKET {new Date().getFullYear()}</BottomText>
            <BottomText>ALL RIGHTS RESERVED</BottomText>
          </BottomContainer>
        </Content>
      </LoginWrapper>
    </>
  );
};

export default LoginScreen;

const LoginWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
`;

const FormContent = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 50px auto 40px auto;
  display: flex;
  justify-content: space-between;
  background: var(--colorNeutralBackground1);
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BottomText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground5);
`;

const FormLeftContainer = styled.div`
  flex: 0 0 45%;
  background: var(--colorPaletteVioletBackground4);

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
`;

const FormRightContainer = styled.div`
  flex: 1;
  padding: 120px 100px 47px 72px;
  position: relative;
`;

const FormName = styled.h1`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -0.64px;
  margin: 0;
  padding: 0 0 8px 0;
  max-width: 340px;
  color: var(--colorNeutralForeground1);
`;

const Warning = styled.div`
  color: var(--colorPaletteRedForeground2);
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 24px;
`;

const BottomLogo = styled.div`
  position: absolute;
  bottom: 47px;
  margin-top: 82px;

  img {
    max-width: 184px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  padding-bottom: 8px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 8px;
`;

const StyledLink = styled(Link)`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  border-bottom: 1px dashed;
`;

const LoginButton = styled(Button)`
  width: 100%;
  height: 36px;
`;
