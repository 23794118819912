import { FC } from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react";
import { getUnitSegmentsName } from "utils/dashboard/useGetSegmentName";

import store from "./store";
import styled from "styled-components";
import Button from "components/button/Button";
import FiltersPopup from "./FiltersPopup";

type FiltersProps = {
  surveyId: number;
};

const Filters: FC<FiltersProps> = observer((props) => {
  return (
    <Container>
      <Text>
        {store.filters.length > 0 && store.filters[0].unit_or_attr
          ? "Filter"
          : "Filter: None selected"}
      </Text>
      {store.filters.length > 0 && store.filters[0].unit_or_attr && (
        <SelectedFilters>
          {store.filters.map((filter, filterIndex) => {
            if (filter.segments.length > 0)
              return (
                <StyledBox key={filterIndex}>
                  {filterIndex !== 0 && <Divider>and</Divider>}
                  <FilterRow>
                    {filter.segments.map((segment, segmentIndex) => (
                      <Segment key={segment + segmentIndex}>
                        {getUnitSegmentsName(filter, segment, store.filterAttributesList)}
                      </Segment>
                    ))}
                  </FilterRow>
                </StyledBox>
              );
          })}
        </SelectedFilters>
      )}
      <SelectFilterButton onClick={() => store.setData(true, "isOpenFiltersPopup")}>
        {store.filters.length > 0 && store.filters[0].unit_or_attr
          ? "Edit filters"
          : "Select filters"}
      </SelectFilterButton>

      <FiltersPopup
        open={store.isOpenFiltersPopup}
        handleHide={() => store.setData(false, "isOpenFiltersPopup")}
        surveyId={props.surveyId}
      />
    </Container>
  );
});

export default Filters;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 16px;
  background-color: var(--colorPalleteLightBlue);
  border-bottom: 1px solid var(--colorNeutralForeground4);
  padding: 17px 16px;
  z-index: 15;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
  margin-right: 16px;
  white-space: nowrap;
`;

const SelectFilterButton = styled(Button)`
  padding: 4px 10px !important;
  margin-left: 4px !important;
  white-space: nowrap !important;
`;

const SelectedFilters = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;
`;

const FilterRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Segment = styled.span`
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 8px 2px 0px;
  background-color: var(--colorNeutralBackground1);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  color: var(--colorNeutralForeground1);
`;

const Divider = styled.span`
  margin-right: 8px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
  text-transform: uppercase;
`;

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
`;
