import { makeAutoObservable, runInAction } from "mobx";
import { getSurveys } from "api/useGetSurveys";
import { getSurveyResult } from "api/useGetSurveyResult";
import { getSurveyProject } from "api/useGetSurveyProject";
import { GetSurveyTags } from "api/go/useGetSurveyTags";
import MainStore from "../../../MainStore";
import { useDeleteSurveyFull } from "api/survey/useDeleteSurveyFull";
import { surveySaveAsTemplate } from "api/survey/useSaveAsTemplate";
import StoreLayout from "./../../../components/workspaces-sidebar/StoreLayout";
import { duplicateSurvey } from "api/go/useDuplicateSurvey";
import { sm_languages, sm_project, sm_survey, sm_survey_result, sm_tags, OveralScore } from "types/projects";
import { getCountMilestoneUnscheduledRecepients } from "api/useGetCountMilestoneUnscheduledRecepients";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";
import React from "react";
import { useGetOveralScoreSurveys } from "api/dasboard/useGetOveralScoreSurveys";

class Store {
  data: sm_survey[] = [];
  project: sm_project = null;
  sortField = "";
  sortType = "";
  searchedValue: string = "";
  resultSearchValue: string = "";
  openCreateSurveyPopup: boolean = false;
  project_id: number = 0;
  projecttype_code: string = "";
  selectedRow: sm_survey = null;

  openSurveyDeletePopup = false;
  deleteSurvValue: string = "";
  errorSurvTextDelete: string = "";
  isDeleteSurvBtnDisabled = true;

  openSaveAsTempPopup = false;
  isSaveAsTempBtnDisabled = false;
  checkedSurvQuest = false;
  checkedSurvComm = false;
  errorSurvTextChecked: string = "";

  openSurvayResults: boolean = false;
  curentSurveyId: number = 0;
  selectedSurveyData: sm_survey_result;
  curentTags: sm_tags[] = [];
  curentLanguages: sm_languages[] = [];

  openProjectEditPopup: boolean = false;

  openExitModal: boolean = false;
  projectMilestoneCountRecepients: number = 0;
  projectCountRecepients: number = 0;

  tags: sm_tags[] = [];
  selected_tags_ids: number[] = [];
  milestone_days: number[] = [];
  selected_milestone_days: number[] = [];

  setOpenExitModal = (bool: boolean) => {
    this.openExitModal = bool;
  };

  changeSort(field: string, type: string) {
    this.sortField = field;
    this.sortType = type;
    this.getSurveys();
  }

  changeTag = (id: number) => {
    const index = this.selected_tags_ids.indexOf(id, 0);
    let new_arr = this.selected_tags_ids;
    if (index > -1) {
      new_arr.splice(index, 1);
    } else {
      new_arr = [...new_arr, id];
    }
    this.selected_tags_ids = new_arr;
    this.getSurveys();
  };

  changeMilestoneDays = (id: number) => {
    const index = this.selected_milestone_days.indexOf(id, 0);
    let new_arr = this.selected_milestone_days;
    if (index > -1) {
      new_arr.splice(index, 1);
    } else {
      new_arr = [...new_arr, id];
    }
    this.selected_milestone_days = new_arr;
    this.getSurveys();
  };

  setOpenProjectEditPopup = (bool: boolean) => {
    this.openProjectEditPopup = bool;
  };

  getSurveyResult(id: number) {
    this.curentSurveyId = id;
    this.openSurvayResults = true;
    this.loadSurveyData(id);
  }

  clothCurentSurvey() {
    this.curentSurveyId = 0;
    this.openSurvayResults = false;
  }

  setCurentSurveyId(id: number) {
    this.curentSurveyId = id;
  }

  setProjectId(id: number) {
    this.project_id = id;
  }

  getProject = async () => {
    this.projecttype_code = "";
    try {
      MainStore.changeLoader(true);
      const response = await getSurveyProject(this.project_id);
      if ((response.status === 200 || response.status === 201) && response.data) {
        this.project = response.data;
        if (response.data.projecttype_code === SEND_OUT_SURVEY_TYPES.Milestones) {
          this.projecttype_code = response.data.projecttype_code;
          this.countMilestoneProjectRecepients();
        }
      } else throw new Error();
    } catch {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  countMilestoneProjectRecepients = async () => {
    try {
      const response = await getCountMilestoneUnscheduledRecepients(this.project_id);
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.projectMilestoneCountRecepients = response.data?.count_uncscheduled_rec_milestone;
        this.projectCountRecepients = response.data?.count_rec;
      } else throw Error();
    } catch {
      MainStore.setSnackbar("Cant count recepients", "error");
    }
  };

  GetSurveyTags = async () => {
    try {
      const response = await GetSurveyTags();
      this.tags = response;
    } catch {
      MainStore.setSnackbar("Can't found tags", "error");
    }
  };

  setMilestoneDays = (data: sm_survey[]) => {
    const milestone_days = data.filter((x) => x.milestone_day !== null).map((x) => x.milestone_day);
    if (!!milestone_days.length) {
      this.milestone_days = Array.from(new Set(milestone_days));
    }
  };

  getSurveys = async (project_id?: number) => {
    try {
      MainStore.changeLoader(true);
      const response = await getSurveys(
        this.project_id || project_id,
        this.resultSearchValue,
        this.sortField,
        this.sortType,
        this.selected_tags_ids,
        this.selected_milestone_days,
      );
      if ((response.status === 200 || response.status === 201) && response.data) {
        this.data = response.data;
        this.setMilestoneDays(response.data);
        this.getOveralScoreSurveys()
      } else throw new Error();
    } catch {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
  
  getOveralScoreSurveys = async () => {
    try {
      MainStore.changeLoader(true);
      const surveyIds = this.data.map(x => x.id)
      const response = await useGetOveralScoreSurveys(surveyIds);
      if ((response.status === 200 || response.status === 201) && response.data) {
        let surveys = this.data
        surveys.forEach(survey => {
          const score = response.data.find((score: OveralScore) => survey.id === score.SurveyId)
          survey.score = score?.OverallScore
        })
        this.setMilestoneDays(surveys);
      } else throw new Error();
    } catch {
      MainStore.setSnackbar("Cannot load overal score", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  changeOpenCreateSurveyPopup = (flag: boolean) => {
    this.openCreateSurveyPopup = flag;
  };

  loadSurveyData = async (id: number) => {
    try {
      MainStore.changeLoader(true);
      const response = await getSurveyResult(id);
      if ((response.status === 200 || response.status === 201) && response.data) {
        this.selectedSurveyData = response.data;
        this.curentTags = response.data.tags;
        this.curentLanguages = response.data.languages;
        this.project_id = response.data.project_id;
      } else throw new Error();
    } catch {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  keyPress = (e: any) => {
    if (e.keyCode == 13) {
      this.onSearchClicked();
    }
  };

  onSearchClicked = () => {
    this.resultSearchValue = this.searchedValue;
    this.searchedValue = "";
    this.getSurveys();
  };

  changeValue = (name: string, value: string) => {
    this[name] = value;
  };

  valueSurvChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.deleteSurvValue = event.target.value;
    if (this.deleteSurvValue !== "DELETE") {
      this.errorSurvTextDelete = "Please type in the exact letters";
      this.isDeleteSurvBtnDisabled = true;
    }
    if (this.deleteSurvValue === "") {
      this.errorSurvTextDelete = "";
      this.isDeleteSurvBtnDisabled = true;
    }
    if (this.deleteSurvValue === "DELETE") {
      this.errorSurvTextDelete = "";
      this.isDeleteSurvBtnDisabled = false;
    }
  };

  openClickSurveyDeletePopup = (flag: boolean) => {
    this.openSurveyDeletePopup = flag;
  };

  openClickSaveAsTempPopup = (flag: boolean) => {
    this.openSaveAsTempPopup = flag;
    this.checkedSurvQuest = false;
    this.checkedSurvComm = false;
    this.errorSurvTextChecked = "";
    this.isSaveAsTempBtnDisabled = false;
  };

  passIdToPopup = (survey: sm_survey) => {
    this.selectedRow = survey;
  };

  duplicateSurveyClick = async (survey: sm_survey) => {
    this.selectedRow = survey;
    if (this.selectedRow == null) {
      return;
    }
    try {
      MainStore.changeLoader(true);
      const response = await duplicateSurvey(this.selectedRow.id);
      MainStore.changeLoader(false);
      await this.getSurveys();
      const filtered = this.data?.find((item) => item.id === response.data);
      const nameProject = filtered?.name.replace("(", " (");
      MainStore.setSnackbar(
        `"${this.selectedRow.name}" successfully duplicated, creating "${nameProject}"`
      );
    } catch {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  saveAsTempSumbit = (survey_id: number) => {
    if (this.checkedSurvQuest == false || this.checkedSurvComm == false) {
      this.isSaveAsTempBtnDisabled = true;
      this.errorSurvTextChecked = "Please select the components to be included in the template";
    }
    if (this.checkedSurvQuest == true || this.checkedSurvComm == true) {
      this.errorSurvTextChecked = "";
      this.isSaveAsTempBtnDisabled = false;
      MainStore.changeLoader(true);
      surveySaveAsTemplate(
        survey_id,
        StoreLayout.currentEntityId,
        this.checkedSurvQuest,
        this.checkedSurvComm,
      ).then((x) => {
        MainStore.changeLoader(false);
        if (x != null && x.data != null) {
          MainStore.setSnackbar(`"${this.selectedRow.name}" template successfully saved`);
          // this.GetSurveys();
          this.openSaveAsTempPopup = false;
        }
      });
    }
  };

  valueSurvChangeQuest = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.checkedSurvQuest = event.target.checked;
    if (this.checkedSurvQuest == true) {
      this.isSaveAsTempBtnDisabled = false;
      this.errorSurvTextChecked = "";
    } else if (this.checkedSurvQuest == false) {
      this.isSaveAsTempBtnDisabled = true;
      this.errorSurvTextChecked = "Please select the components to be included in the template";
    }
  };

  valueSurvChangeComm = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.checkedSurvComm = event.target.checked;
    if (this.checkedSurvComm == true) {
      this.isSaveAsTempBtnDisabled = false;
      this.errorSurvTextChecked = "";
    } else if (this.checkedSurvComm == false) {
      this.isSaveAsTempBtnDisabled = true;
      this.errorSurvTextChecked = "Please select the components to be included in the template";
    }
  };

  deleteSurvSubmit = (id: number) => {
    MainStore.changeLoader(true);
    useDeleteSurveyFull(id).then((x) => {
      MainStore.changeLoader(false);
      if (x != null && x.data != null) {
        MainStore.setSnackbar(`"${this.selectedRow.name}" successfully deleted`);
        this.deleteSurvValue = "";
        // this.selectedRow = null;
        this.openSurveyDeletePopup = false;
        this.getSurveys();
      }
    });
  };

  changeResultSearchValue = (value: string) => {
    this.resultSearchValue = value;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

const store = new Store();
export default store;
