import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";

import RichTextWithTabsAll from "../../RichTextWithTabsAll";
import CustomButton from "components/button/Button";
import PreviewIcon from "../../PreviewIcon";
import store from "./../../store";

type SecondTabProps = {};

const SecondTab: FC<SecondTabProps> = observer(() => {
  return (
    <>
      <Wrapped>
        <Header id="Survey_EditCreate_Communication_ScheduleCommunications_Invite_Title">
          Invite email
        </Header>

        <SubHeader id="Survey_EditCreate_Communication_ScheduleCommunications_Invite_SubTitle">
          EMAIL SUBJECT
        </SubHeader>

        <RichTextWithTabsAll
          onlyText
          data={store.InviteData}
          width={100}
          changeValue={store.changeInviteData}
          field="subject_email"
          langNameId="Survey_EditCreate_Communication_ScheduleCommunications_Invite_RichTextWith_Language"
          editorId="Survey_EditCreate_Communication_ScheduleCommunications_Invite_Subject_RichTextWith_Editor"
        />

        <SubHeader id="Survey_EditCreate_Communication_ScheduleCommunications_Invite_SubTitle2">
          EMAIL CONTENT
        </SubHeader>

        <RichTextWithTabsAll
          data={store.InviteData}
          width={200}
          changeValue={store.changeInviteData}
          field="body_email"
          langNameId="Survey_EditCreate_Communication_ScheduleCommunications_Invite_RichTextWith_Language"
          editorId="Survey_EditCreate_Communication_ScheduleCommunications_Invite_Content_RichTextWith_Editor"
        />

        <BottomWrapper>
          <ButtonWrapper>
            <StyledNextButton
              id="Survey_EditCreate_Communication_ScheduleCommunications_Invite_Save_Button"
              disabled={!store.saveInviteEmailBtn}
              variant="contained"
              onClick={() => store.saveInviteData()}
            >
              Save changes
            </StyledNextButton>
            <StyledCancelButton
              id="Survey_EditCreate_Communication_ScheduleCommunications_Invite_Cancel_Button"
              variant="outlined"
              disabled={!store.changedSecondTab}
              onClick={() => store.onCancelSecondTab()}
            >
              Cancel
            </StyledCancelButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <LinkPreview
              id="Survey_EditCreate_Communication_ScheduleCommunications_Invite_Preview_Button"
              onClick={() => store.loadPreviewMessage(null, false)}
            >
              <PreviewIcon />
              <LinkPreviewText id="Survey_EditCreate_Communication_ScheduleCommunications_Invite_Preview_Button_Text">
                Preview email
              </LinkPreviewText>
            </LinkPreview>
          </ButtonWrapper>
        </BottomWrapper>
      </Wrapped>
    </>
  );
});

export default SecondTab;

const Wrapped = styled.div`
  margin: 10px 30px;
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: var(--colorNeutralForeground1);
`;

const BottomWrapper = styled.div`
  width: 100%;
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LinkPreviewText = styled.span`
  margin-left: 3px;
`;

const LinkPreview = styled.a`
  margin-left: 5px;
  color: var(--colorBrandForeground1);
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledCancelButton = styled(CustomButton)`
  width: 100%;
  max-width: 75px;
`;

const StyledNextButton = styled(CustomButton)`
  width: 100%;
  max-width: 150px;
`;

const SubHeader = styled.div`
  margin-bottom: 10px;
  margin-top: 30px;
  color: var(--colorNeutralForeground2);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
`;
