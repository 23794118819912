import React, { FC } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { TextField, Chip, Autocomplete as AutocompleteMui, IconButton } from "@mui/material";

import { DeleteIconTags } from "./DeleteIconTags";
import CustomButton from "components/button/Button";
import { EnterIconTags } from "./EnterIconTags";

interface AutocompleteProps {
  data: { name: string }[];
  selected: string[];
  onChange: (value: string[]) => void;
  onRemove: (index: number) => void;
  titleId?: string;
  labelId?: string;
  fieldId?: string;
  chipId?: string;
}

export const Autocomplete: FC<AutocompleteProps> = observer((props) => {
  return (
    <WrapperAutocomplete>
      <HelpText id={props.titleId}>Tags</HelpText>
      <StyledLabelAutocomplete id={props.labelId} htmlFor="size-small-outlined-multi">
        Add multiple tags by adding your own text and hit "Enter" on your keyboard
      </StyledLabelAutocomplete>

      <AutocompleteMui
        multiple
        id={props.fieldId}
        size="small"
        value={props.selected}
        disableCloseOnSelect={true}
        ListboxProps={{ style: { maxHeight: "200px" } }}
        onChange={(e, value) => {
          props.onChange(value);
        }}
        fullWidth
        freeSolo
        options={props.data.map((option) => option.name)}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.inputProps.value && (
                    <StyledEnterButton>
                      Enter
                      <EnterIconTags />
                    </StyledEnterButton>
                  )}
                </>
              ),
            }}
          />
        )}
        renderTags={() => null}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <p>{option}</p>
          </li>
        )}
      />
      {props.selected?.map((option, index) => (
        <StyledChip
          key={index}
          label={option}
          id={props.chipId}
          onDelete={() => props.onRemove(index)}
          deleteIcon={
            <StyledIconButton>
              <DeleteIconTags />
            </StyledIconButton>
          }
        />
      ))}
    </WrapperAutocomplete>
  );
});

const WrapperAutocomplete = styled.div`
  margin-top: 50px;
`;

const StyledChip = styled(Chip)`
  border-radius: 2px !important;
  background: var(--ER-Theme-sys-light-surface-variant, #d9dde7) !important;
  height: 20px !important;
  margin-right: 5px !important;
  margin-top: 5px !important;
`;

const StyledIconButton = styled(IconButton)`
  padding: 3px !important;
`;

const HelpText = styled.p`
  color: var(--ER-Theme-sys-light-on-secondary-container, #3e4450);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
`;

const StyledLabelAutocomplete = styled.label`
  color: var(--ER-Theme-sys-light-on-tertiary-container, #707882);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const StyledEnterButton = styled(CustomButton)`
  border: 1px solid var(--colorNeutralForeground5) !important;
  background: var(--colorNeutralForeground8) !important;
  color: var(--colorNeutralForeground5) !important;
  margin-right: -30px !important;
  padding: 3px !important;
  display: flex;
  align-items: center;
  gap: 5px;
`;
