import http from "api/https";


export const getRecepientsMilestoneByProject = (
  project_id: number,
  search: string,
  perPage: number,
  numberPage: number
): Promise<any> => {
  return http.get(
    "/employees/GetRecepientsMilestoneByProject?project_id=" +
    project_id +
      "&search=" +
      search +
      "&perPage=" +
      perPage +
      "&numberPage=" +
      numberPage
  );
};
