const FeaturesIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 16 18" fill="none">
      <path d="M8.0094 0.5L0.626953 4.70331V13.1404L8.0094 17.3742L15.3918 13.1404V4.70331L8.0094 0.5Z" fill="#FCC658" />
      <path d="M8.00977 0.5V17.3742L15.3922 13.1404V4.70331L8.00977 0.5Z" fill="#FFE19B" />
      <path d="M11.5775 5.24501C11.5385 5.12805 11.4215 5.01109 11.3045 4.9721C9.6671 4.54324 7.99066 5.05007 6.86004 6.37563L6.43119 6.88246L5.37854 6.64854C4.87171 6.4536 4.32589 6.68752 4.09197 7.15537L3.23426 8.67585C3.15629 8.79281 3.15629 8.90978 3.23426 9.02674C3.27325 9.1437 3.39021 9.22167 3.50717 9.26066L4.71576 9.53357C4.5988 9.84546 4.55982 10.1574 4.48184 10.4693C4.48184 10.5862 4.52083 10.7032 4.5988 10.7811L5.8074 11.9897C5.88537 12.0677 5.96334 12.1067 6.0803 12.1067H6.11929C6.47017 12.0677 6.78207 12.0287 7.09396 11.9118L7.32789 13.0814C7.36687 13.1983 7.44485 13.3153 7.56181 13.3543C7.60079 13.3933 7.67877 13.3933 7.71775 13.3933C7.79573 13.3933 7.83471 13.3933 7.91269 13.3543L9.43318 12.4966C9.86203 12.2626 10.096 11.7168 9.97899 11.21L9.70609 10.1184L10.1739 9.68951C11.4605 8.59788 12.0063 6.84347 11.5775 5.24501ZM5.61246 7.85713C5.37854 8.16902 5.14462 8.48092 4.98867 8.79281L4.16995 8.59788L4.75475 7.54523C4.83272 7.38929 4.98867 7.3503 5.18361 7.38929L5.84638 7.54523L5.61246 7.85713ZM9.00432 11.7948L7.95168 12.3796L7.79573 11.5999C8.14661 11.4439 8.45851 11.21 8.73141 10.9761L9.00432 10.7032L9.16027 11.3659C9.23824 11.5609 9.12128 11.7558 9.00432 11.7948ZM9.27723 7.85713C8.96533 7.85713 8.69243 7.58422 8.69243 7.27233C8.69243 6.96043 8.96533 6.68752 9.27723 6.68752C9.58913 6.68752 9.86203 6.96043 9.86203 7.27233C9.86203 7.58422 9.62811 7.85713 9.27723 7.85713Z" fill="#8B10D5" />
    </svg>
  );
};

export default FeaturesIcon;
