import { CreatorBase } from "survey-creator-core";
import { ENPS_QUESTION_TYPE, QUESTION_IDENTIFIER_PROPERTY_NAME } from "./surveyJsConstants";
import ENPSQuestionModel from "./eNPS/ENPSQuestionModel";
import { QuestionRatingModel } from "survey-core";
import MainStore from "MainStore";

const commonDefaultValues = {
  commentText: "Leave a comment (Optional)",
};

const ratingDefaultValues = {
  ...commonDefaultValues,
  maxRateDescription: "Strongly agree",
  minRateDescription: "Strongly disagree",
  rateValues: [1, 2, 3, 4, 5],
  displayMode: "buttons",
};

const enpsDefaultValues = {
  ...commonDefaultValues,
  autoGenerate: true,
  locTitle: {
    default:
      "How likely are you to recommend this organisation as a place to work to friends or family?",
  },
  locMaxRateDescription: {
    default: "Extremely likely",
  },
  locMinRateDescription: {
    default: "Not at all likely",
  },
};

export default function handleSetQuestionDefaultValues(sender: CreatorBase, options: any): void {
  const question = options.question;
  const type = question.getType();

  if (type == "rating") {
    Object.keys(ratingDefaultValues).forEach(
      (key) => (question[key] = ratingDefaultValues[key as keyof typeof ratingDefaultValues])
    );

    setRatingDefaultValues(question);
  } else if (type == ENPS_QUESTION_TYPE) {
    const enps = sender.survey
      .getAllQuestions()
      .find(
        (question) => question.getType() == ENPS_QUESTION_TYPE && question !== options.question
      );

    if (enps) {
      MainStore.setSnackbar(
        "Looks like you already have another eNPS question on the survey. Unable to add another eNPS question.",
        "error"
      );
      options.question.delete();
      sender.selectElement(enps);
    } else {
      setEnpsDefaultValues(question);
    }
  }
}

export function setEnpsDefaultValues(question: ENPSQuestionModel): void {
  Object.keys(enpsDefaultValues.locMaxRateDescription).forEach((locale) => {
    question.locMaxRateDescription.setLocaleText(
      locale,
      enpsDefaultValues.locMaxRateDescription[
        locale as keyof typeof enpsDefaultValues.locMaxRateDescription
      ]
    );
  });

  Object.keys(enpsDefaultValues.locMinRateDescription).forEach((locale) => {
    question.locMinRateDescription.setLocaleText(
      locale,
      enpsDefaultValues.locMinRateDescription[
        locale as keyof typeof enpsDefaultValues.locMinRateDescription
      ]
    );
  });

  Object.keys(enpsDefaultValues.locTitle).forEach((locale) => {
    if (question.locTitle.isEmpty) {
      question.locTitle.setLocaleText(
        locale,
        enpsDefaultValues.locTitle[locale as keyof typeof enpsDefaultValues.locTitle]
      );
    }
  });

  question.rateValues = [];
  question.rateCount = 11;
  question.autoGenerate = true;
  question[QUESTION_IDENTIFIER_PROPERTY_NAME] = "q_eNPS";
}

export function setRatingDefaultValues(question: QuestionRatingModel): void {
  question.displayMode = "buttons";
}
