import http from "api/https";

export const getRolesByEntity = (id: number): Promise<any> => {
  if(http.isAPIGO){
    return http.getGO("/permission/role/GetRolesByEntity?idEntity=" + id); //permission servise API
    }else{
      return http.get("/role/GetRolesByEntity?idEntity=" + id);
  
    }
};
