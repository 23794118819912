import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import useDebounce from 'utils/useDebounse';
import { observer } from "mobx-react";
import store from "./store";

const Asynchronous = observer(() => {
  const [open, setOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [keySearch, setKeySearch] = React.useState(new Date());

  const debouncedValue = useDebounce(searchValue, 600);

  React.useEffect(() => {
    if (!searchValue) {
      store.setSearchUnitOptions([])
      return;
    }
    store.loadUnits(searchValue);

  }, [debouncedValue]);


  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) => option.name + " (Level " + option.level + ")"}
      renderOption={(props, option, state, ownerState) => (
        <Box
          sx={{
            borderRadius: '8px',
            margin: '5px',
          }}
          component="li"
          {...props}
        >
          {option.name} <div style={{ color: "var(--colorNeutralForeground2)" }}> &nbsp; {("(Level " + option.level + ")")}</div>
        </Box>
      )}
      fullWidth
      value={null}
      blurOnSelect={true}
      filterSelectedOptions
      inputValue={searchValue}
      onInputChange={(_, newInputValue) => {
        setSearchValue(newInputValue)
      }}
      key={keySearch.toISOString()}
      onChange={(_, i) => {
        store.setSearchUnitOptions([])
        setSearchValue("");
        setKeySearch(new Date());
        if (i == null) return
        store.addSelectedUnit(i!)
      }}
      clearOnEscape={true}
      options={store.searchUnitOptions}
      noOptionsText={"Email address not found on employee list"}
      loading={store.loadingSearchUnitOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Unit that this unit head belongs to"
          variant="standard"
          fullWidth
          placeholder="Search for unit name"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {store.loadingSearchUnitOptions ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
})

export default Asynchronous;