
import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  margin-right: 5px;
`;

export const RoleIcon = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1174 16.9987C20.1174 17.1404 20.1007 17.2737 20.084 17.407L20.9757 18.0987C21.0507 18.1654 21.0757 18.2737 21.0257 18.3654L20.1924 19.807C20.134 19.8987 20.0257 19.932 19.934 19.8987L18.8924 19.482C18.684 19.6487 18.4424 19.782 18.1924 19.8904L18.034 20.9904C18.0174 21.0904 17.9257 21.1654 17.8257 21.1654H16.159C16.0507 21.1654 15.9674 21.0904 15.9507 20.9904L15.7924 19.8904C15.534 19.7904 15.3007 19.6487 15.084 19.482L14.0507 19.8987C13.9507 19.932 13.8424 19.8987 13.7924 19.807L12.959 18.3654C12.909 18.2737 12.934 18.1654 13.009 18.0987L13.8924 17.407C13.8757 17.2737 13.8674 17.1404 13.8674 16.9987C13.8674 16.857 13.8757 16.7237 13.8924 16.5904L13.009 15.8987C12.9257 15.832 12.909 15.7237 12.959 15.632L13.7924 14.1904C13.8507 14.0987 13.959 14.0654 14.0507 14.0987L15.084 14.5154C15.3007 14.3487 15.5424 14.2154 15.7924 14.107L15.9507 13.007C15.9674 12.907 16.059 12.832 16.159 12.832H17.8257C17.9257 12.832 18.0174 12.907 18.0257 13.007L18.184 14.107C18.4424 14.207 18.6757 14.3487 18.8924 14.5154L19.9257 14.0987C20.0257 14.0654 20.134 14.0987 20.184 14.1904L21.0174 15.632C21.0674 15.7237 21.0424 15.832 20.9674 15.8987L20.084 16.5904C20.109 16.7237 20.1174 16.857 20.1174 16.9987ZM15.7424 16.9987C15.7424 17.6904 16.3007 18.2487 16.9924 18.2487C17.684 18.2487 18.2424 17.6904 18.2424 16.9987C18.2424 16.307 17.684 15.7487 16.9924 15.7487C16.3007 15.7487 15.7424 16.307 15.7424 16.9987Z" fill="#6E31FF" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.66675 3.80078H12.0001V7.13411H20.3334V12.0111C19.827 11.6722 19.2662 11.4083 18.6667 11.2353V8.80078H12.0001V10.4674H13.6667V12.0112C13.6071 12.0511 13.5481 12.0921 13.49 12.1341H12.0001V13.683C11.3682 14.6333 11.0001 15.774 11.0001 17.0008C11.0001 17.6279 11.0963 18.2326 11.2748 18.8008H3.66675V3.80078ZM17.0001 11.0008C16.4218 11.0008 15.8626 11.0826 15.3334 11.2353V10.4674H17.0001V11.0008ZM5.33341 17.1341H7.00008V15.4674H5.33341V17.1341ZM5.33341 13.8008H7.00008V12.1341H5.33341V13.8008ZM5.33341 10.4674H7.00008V8.80078H5.33341V10.4674ZM5.33341 7.13411H7.00008V5.46745H5.33341V7.13411ZM8.66675 17.1341H10.3334V15.4674H8.66675V17.1341ZM8.66675 13.8008H10.3334V12.1341H8.66675V13.8008ZM8.66675 10.4674H10.3334V8.80078H8.66675V10.4674ZM8.66675 7.13411H10.3334V5.46745H8.66675V7.13411Z" fill="#6E31FF" />
  </StyledSvg>
);
