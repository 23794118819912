import { FC } from "react";

interface EditIconProps {
  color?: string;
}

const EditIcon: FC<EditIconProps> = ({ color = "#1477F8" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 10 10"
      fill="none"
      style={{
        marginRight: 4,
      }}
    >
      <path
        d="M0.58252 7.73533V9.62207H2.46926L8.03389 4.05744L6.14715 2.1707L0.58252 7.73533ZM9.49297 2.59836C9.68919 2.40214 9.68919 2.08517 9.49297 1.88895L8.31565 0.711619C8.11942 0.515398 7.80245 0.515398 7.60623 0.711619L6.6855 1.63235L8.57224 3.51909L9.49297 2.59836Z"
        fill={color}
      />
    </svg>
  );
};

export default EditIcon;
