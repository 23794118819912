import { FC } from "react";
import { EntityPermissions } from "../../features/EntityPermissions";

type PermissionsPageProps = {};

const PermissionsPage: FC<PermissionsPageProps> = () => {
  return <EntityPermissions />;
};

export default PermissionsPage;
