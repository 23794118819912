import http from "api/https";
import { createUrl } from "../../helpers/url";

export const getRolesToProjectPermissions = (idProject: number, idEntity: number): Promise<any> => {
  const query: { idProject: string; idEntity: string } = {
    idProject: idProject.toString(),
    idEntity: idEntity.toString(),
  };
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/permission/role/GetRolesToProjectPermissions",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/role/GetRolesToProjectPermissions",
      query: query,
    });
    return http.get(url);
  }
};
