import { styled as sty } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import FormHelperText from "@mui/material/FormHelperText";
import styled from "styled-components";
import { observer } from "mobx-react";

const BootstrapInput = sty(InputBase)(({ theme }) => ({
  "label + &": {},
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

type SelectOption = {
  id: number;
  name: string;
  countRecipients?: number;
};

type SelectType = {
  data: SelectOption[];
  value: number;
  label?: string;
  name?: string;
  errorText: string;
  error: boolean;
  disabledOption?: number[];
  onChange: (e) => void;
};

export const Lookup = observer((props: SelectType) => {
  return (
    <FormControl sx={{ m: 1, minWidth: 200 }} variant="standard" {...props}>
      <InputLabel htmlFor="demo-customized-select-native">{props.label}</InputLabel>
      <NativeSelect
        id="demo-customized-select-native"
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        input={<BootstrapInput />}
      >
        {props.data.map((option) => (
          <option
            key={option.id}
            value={option.id}
            disabled={props.disabledOption?.includes(option.id)}
          >
            {option.name}
          </option>
        ))}
      </NativeSelect>
      {props.error && <FormHelperText error={true}>{props.errorText}</FormHelperText>}
      {props.name === "recipients" && (
        <HelperText>
          {props.data.filter((x) => x.id === Number(props.value))[0]?.countRecipients} recipients
        </HelperText>
      )}
    </FormControl>
  );
});

const HelperText = styled.div`
  margin: 5px 5px;
  color: var(--colorNeutralForeground5);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
