import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg``;

export const UserPermissionsIcon = (
  props: React.HTMLAttributes<HTMLOrSVGElement>
) => (
  <StyledSvg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3337_2544)">
      <path
        d="M9.99154 1.66797C5.39154 1.66797 1.6582 5.4013 1.6582 10.0013C1.6582 14.6013 5.39154 18.3346 9.99154 18.3346C14.5915 18.3346 18.3249 14.6013 18.3249 10.0013C18.3249 5.4013 14.5915 1.66797 9.99154 1.66797ZM12.9999 6.9513C13.8915 6.9513 14.6082 7.66797 14.6082 8.55964C14.6082 9.4513 13.8915 10.168 12.9999 10.168C12.1082 10.168 11.3915 9.4513 11.3915 8.55964C11.3832 7.66797 12.1082 6.9513 12.9999 6.9513ZM7.99987 5.63464C9.0832 5.63464 9.96654 6.51797 9.96654 7.6013C9.96654 8.68464 9.0832 9.56797 7.99987 9.56797C6.91654 9.56797 6.0332 8.68464 6.0332 7.6013C6.0332 6.50964 6.9082 5.63464 7.99987 5.63464ZM7.99987 13.243V16.368C5.99987 15.743 4.41654 14.2013 3.71654 12.2346C4.59154 11.3013 6.77487 10.8263 7.99987 10.8263C8.44154 10.8263 8.99987 10.893 9.5832 11.0096C8.21654 11.7346 7.99987 12.693 7.99987 13.243ZM9.99154 16.668C9.76654 16.668 9.54987 16.6596 9.3332 16.6346V13.243C9.3332 12.0596 11.7832 11.468 12.9999 11.468C13.8915 11.468 15.4332 11.793 16.1999 12.4263C15.2249 14.9013 12.8165 16.668 9.99154 16.668Z"
        fill="#707882"
      />
    </g>
    <defs>
      <clipPath id="clip0_3337_2544">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </StyledSvg>
);
