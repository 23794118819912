import http from "api/https";
import { createUrl } from "../../helpers/url";

export const getStructure = (idEntity: number): Promise<any> => {
  const query: { idEntity: string } = { idEntity: idEntity.toString() };
  if(http.isAPIGO){
    const url = createUrl({
      path: "/employee/organization_unit/GetStructureByIdEntity",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/organization_unit/GetStructureByIdEntity",
      query: query,
    });
    return http.get(url);
  }
};
