import { FC } from "react";
import { EmployeesScreen } from "../../features/Employees";

type EmployeesPageProps = {};

const EmployeesPage: FC<EmployeesPageProps> = () => {
  return <EmployeesScreen />;
};

export default EmployeesPage;
