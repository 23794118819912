import { CircularProgress } from "@mui/material";
import styled, { css } from "styled-components";

type Props = {
  contentHeight?: number;
};

export const CardLoader = ({ contentHeight }: Props) => (
  <Wrap contentHeight={contentHeight}>
    <CircularProgress />
  </Wrap>
)

const Wrap = styled.div<Props>`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => typeof props.contentHeight === "number" && css`
    min-height: ${props.contentHeight}px;
  `}
`