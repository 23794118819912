import http from "api/https";


export const copyCommsTemplateToTemplate = (id:number, newId:number, entityId:number): Promise<any> => {
  let data = {
    id: id,
    new_id: newId,
    entity_id: entityId
  }
  if(http.isAPIGO){
    return http.postGO('/template/CopyCommsTemplateToTemplate', data)
  } else {
    return http.post('/templ_template_comms/CopyCommsTemplateToTemplate', data)
  }
};
