import React, { ReactElement, ChangeEvent, useState, useRef } from "react";
import { Box } from "@mui/material"
import styled from "styled-components";
import ImageIcon from '@mui/icons-material/Image';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button } from "@mui/material";

interface FileInputProps {
  onFileSelect: (file: any) => void;
  label: string;
  secondaryLabel: string;
  errorText: string;
}

const LogoWrapper = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--graySelectBoxBorder);
  border-radius: 8px;
`;
const LogoWrapperNoImage = styled.div`
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--graySelectBoxBorder);
  border-radius: 8px;
`;

const LabelText = styled.p`
font-size: 16px;
line-height: 20px;
font-weight: 600;
display: block;
margin: 30px 0 0 0;
font-family: 'Roboto';
`;

const NoImageText = styled.p`
font-size: 12px;
color: #A2A2A2;
display: block;
margin: 0;
font-family: 'Roboto';
`;

const LabelSecondary = styled.p`
font-size: 14px;
line-height: 20px;
margin: 0 0 20px 0;
color: #A2A2A2;
display: block;
font-family: 'Roboto';
`;

const FileInput: React.FC<FileInputProps> = ({ onFileSelect, ...props }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      onFileSelect(file);
      if (file != undefined) {

        // Создаем Blob из File
        const blob = new Blob([file!]);

        // Создаем временную ссылку на изображение и устанавливаем ее в state
        const imageUrl = URL.createObjectURL(blob);
        setImageSrc(imageUrl);
        setSelectedFile(file!);
      }
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveButtonClick = () => {
    // Очищаем выбранный файл и временную ссылку на изображение
    setSelectedFile(null);
    setImageSrc(null);
    onFileSelect(null);
  };

  return (
    <>
      <LabelText id="FileInputLabel">
        {props.label}
      </LabelText>
      <LabelSecondary id="FileInputSecondLabel">
        {props.secondaryLabel}
      </LabelSecondary>
      {selectedFile ? (
        <div>
          <LogoWrapper>
            <img src={imageSrc || undefined} style={{ maxWidth: 100, maxHeight: 100 }} alt="Selected image" />
          </LogoWrapper>
        </div>
      ) : <div>
        <LogoWrapperNoImage>
          <div style={{ height: 25 }}></div>
          <div style={{ display: "flex", justifyContent: "center" }}><ImageIcon style={{ color: "#D9DDE6", height: 70, fontSize: 40 }} /></div>


          <div style={{ fontSize: 12, display: "flex", justifyContent: "center" }}><NoImageText>No image uploaded</NoImageText></div>
        </LogoWrapperNoImage>
      </div>}

      {props.errorText != "" ? <Box style={{ color: "red", margin: 5, fontSize: 14 }}>
        {props.errorText}
      </Box> : ""}
      <Box display={"flex"} style={{ marginBottom: 50 }} >

        <input
          type="file"
          ref={fileInputRef}
          accept=".jpg, .jpeg, .png"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />

        <label htmlFor={"uploadFile"}>
          <Button id="UploadImage"  variant="outlined" style={{ marginRight: 15 }} onClick={handleButtonClick}> <FileUploadIcon />Upload image</Button>
        </label>

        <Button id="DeleteImage" variant="outlined" onClick={handleRemoveButtonClick}>Delete image</Button>

      </Box>
    </>
  );
};

export default FileInput;
