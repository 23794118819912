import http from "api/https";

export const getRolesByEntity = (idEntity: number): Promise<any> => {
  if(http.isAPIGO){
  return http.getGO("/permission/role/GetRolesByEntity?idEntity=" + idEntity); //permission servise API
  }else{
    return http.get("/role/GetRolesByEntity?idEntity=" + idEntity);

  }
};
