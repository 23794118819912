import { FC } from "react";
import { observer } from "mobx-react";
import { StarIcon } from "./StarIcon";
import { Dialog, DialogContent } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import ClearIcon from "@mui/icons-material/Clear";
import PeopleIcon from "@mui/icons-material/People";

type ConditionalLogicPopupProps = {};

const ConditionalLogicPopup: FC<ConditionalLogicPopupProps> = observer((props) => {
  return (
    <StyledDialog open={store.isOpenConditionalLogicPopup}>
      <CloseBtnWrapper>
        <CloseButton onClick={() => store.setData(false, "isOpenConditionalLogicPopup")}>
          <ClearIcon />
        </CloseButton>
      </CloseBtnWrapper>
      <DialogTitle>"{store.activeScoredQuestion && store.activeScoredQuestion?.title}"</DialogTitle>
      <StyledDialogContent>
        <Row>
          <StyledPeopleIcon />
          <RespondentCount>
            {store.activeScoredQuestion?.respondedCount} respondents
          </RespondentCount>
          <ConditionalLabel>
            <StarIcon />
            Conditional logic applied
          </ConditionalLabel>
        </Row>
        <Label>They are in segments:</Label>
        <SegmentsContainer>
          {store.activeScoredQuestion &&
            store.activeScoredQuestion.conditionalLogic?.segments.map(
              (group, groupIndex, groupArray) => (
                <SegmentRow key={groupIndex}>
                  <SegmentsGroup>
                    {group.map((segment, segmentIndex, segmentArray) => (
                      <Segment
                        $isLast={segmentIndex === segmentArray.length - 1}
                        key={segmentIndex}
                      >
                        {segment}
                      </Segment>
                    ))}
                  </SegmentsGroup>
                  {groupIndex !== groupArray.length - 1 && <SegmnetsDivider>AND</SegmnetsDivider>}
                </SegmentRow>
              )
            )}
        </SegmentsContainer>
        <Label>They answered questions with responses:</Label>
        <EnpsLabel>
          <b>
            {store.activeScoredQuestion && store.activeScoredQuestion.conditionalLogic?.responses}
          </b>
        </EnpsLabel>
      </StyledDialogContent>
    </StyledDialog>
  );
});

export default ConditionalLogicPopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 560px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 0px 64px 64px 64px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const DialogTitle = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px 0px 16px 0px;
  padding: 10px 64px;
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 10px 20px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;

  svg {
    width: 24px;
    height: 24px;
    color: var(--colorBrandForeground1);
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const ConditionalLabel = styled.span`
  display: flex;
  align-items: center;
  padding: 4px;
  background-color: var(--colorNeutralForeground4);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
  border-radius: 4px;

  svg {
    margin-right: 4px;
  }
`;

const StyledPeopleIcon = styled(PeopleIcon)`
  font-size: 16px !important;
  color: var(--colorNeutralForeground5);
`;

const RespondentCount = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  margin: 0px 12px 0px 4px;
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  margin-bottom: 16px;
`;

const EnpsLabel = styled.span`
  width: 100%;
  padding: 4px 6px;
  background-color: var(--grenBackgroundColor3);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const SegmentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
  margin-bottom: 32px;
`;

const SegmentsGroup = styled.div`
  display: flex;
  align-items: center;
`;

const Segment = styled.span<{ $isLast: boolean }>`
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--colorPaletteBlueBackground3);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  ${(props) => !props.$isLast && "margin-right: 8px"};
`;

const SegmnetsDivider = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
  margin: 0px 16px;
`;

const SegmentRow = styled.div`
  display: flex;
  align-items: center;
`;
