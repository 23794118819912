import { FC } from "react";
import { Outlet } from "react-router-dom";

import styled from "styled-components";
import BackToMainLayoutHeader from "./components/back-to-main-layout-header/BackToMainLayoutHeader";

type BackToMainLayoutWrapperProps = {};

const BackToMainLayoutWrapper: FC<BackToMainLayoutWrapperProps> = (props) => {
  return (
    <AppMainWapper>
      <BackToMainLayoutHeader />
      <AppContent>
        <Outlet />
      </AppContent>
    </AppMainWapper>
  );
};

export default BackToMainLayoutWrapper;

const AppMainWapper = styled.div``;

const AppContent = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
  overflow: auto;
  position: relative;
`;
