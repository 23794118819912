import http from "api/https";
import { createUrl } from "../../helpers/url";

export const useGetMyProjectPermissions = (projectId: number) => {
  const query: { project_id: string; } = {
    project_id: projectId.toString(),
  };
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/permission/GetMyProjectPermissions",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/permission/GetMyProjectPermissions",
      query: query,
    });
    return http.get(url);
  }
}
