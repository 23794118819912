import { FC } from "react";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import { Box, TextField, Typography } from "@mui/material";
import store from "./store";
import { observer } from "mobx-react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

type EmployeeDeletePopupProps = {};

const EmployeeDeletePopup: FC<EmployeeDeletePopupProps> = observer((props) => {
  return (
    <PopupWrapper onKeyDown={store.onKeyPress}>
      <Styledh2 id="EmployeeList_DeletePopUp_Title">Are you sure you want to remove {store.selected.length} employee(s)?</Styledh2>
      <BoxStyled>
        <TypographyStyled id="EmployeeList_DeletePopUp_Subtitle">
          Removing this employee will only impact the employee list.
        </TypographyStyled>
        <TypographyStyled id="EmployeeList_DeletePopUp_Info">
          Removing employees on the employee list won't revoke user login and permissions. To remove a user, you must do
          so on the Entity Permissions page.
        </TypographyStyled>
        <BoxGreyStyled>
          <div style={{ display: "flex" }}>
            <HelpOutlineIcon color="disabled" />
            <TypographyGreyStyled id="EmployeeList_DeletePopUp_QustionText">
              What happens to removed employees?
            </TypographyGreyStyled>
          </div>
          <TypographyGreyStyled>
            <ListCustomStyled>
              <li id="EmployeeList_DeletePopUp_li_1">For running surveys, submitted responses will be excluded from results.</li>
              <li id="EmployeeList_DeletePopUp_li_2">For those who haven't responded, removal from the recipient list means they can't submit a response
                through the invite links.
              </li>
              <li id="EmployeeList_DeletePopUp_li_3"> For running reviews, submitted responses by reviewers will be part of the results.</ li>
            </ListCustomStyled>
          </TypographyGreyStyled>
          <br />
        </BoxGreyStyled>
        <Typography id="EmployeeList_DeletePopUp_FooterText" >To confirm your deletion, please enter REMOVE below:</Typography>
      </BoxStyled>
      <BoxTextField>
        <TextFieldStyled
          id="EmployeeList_DeletePopUp_Input"
          label="REMOVE"
          variant="standard"
          fullWidth
          value={store.deleteEmployeeValue}
          onChange={store.valueEmployeeChange}
        />
        <TypographyErrorText>{store.errorEmployeeTextDelete}</TypographyErrorText>
      </BoxTextField>
      <BtnBox>
        <CustomButton
        id="EmployeeList_DeletePopUp_RemoveBtn"
          variant="contained"
          disabled={store.isDeleteEmployeeBtnDisabled}
          onClick={() => {
            store.deleteButtonClick();
          }}>
          Remove employee
        </CustomButton>
        <CustomButtonStyled
        id="EmployeeList_DeletePopUp_CanselBtn"
        variant="outlined" onClick={() => {
          store.handleClickOpenDialog(false);
        }}>
          Cancel</CustomButtonStyled>
      </BtnBox>
    </PopupWrapper>
  );
});

const PopupWrapper = styled.div`
    height: 610px;
`;
const Styledh2 = styled.h2`
    margin: 60px 44px 0px 44px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
`;
const BoxStyled = styled(Box)`
    margin: 40px 44px 0px 44px;
`;
const TypographyStyled = styled(Typography)`
    display: block;
    margin-bottom: 30px !important;
`;

const TypographyGreyStyled = styled.div`
    display: block;
    margin-top: 0;
    color: #707882;
    font-size: 14px;
    line-height: 20px;
    font-family: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
`;

const BoxGreyStyled = styled(Box)`
    background-color: #F9FAFC;
`;

const BtnBox = styled(Box)`
    margin: 20px 50px 20px 40px;
    width: 250px;
    display: flex;
    justify-content: space-between;
`;
const CustomButtonStyled = styled(CustomButton)`
    border: 1px solid #959BA2 !important;
    border-radius: 4px;
    color: #707882 !important;
    width: 90px;
    height: 34px;
`;
const BoxTextField = styled(Box)`
    margin: 20px 50px 0px 40px;
`;
const TypographyErrorText = styled(Typography)`
    color: red;
    font-size: 10px;
`;
const TextFieldStyled = styled(TextField)`
    color: '#D9DDE7' !important;
    width: 484px;
`;

const ListCustomStyled = styled.ul`
    list-style-type: disc;
    color: #707882;

    li {
        margin-bottom: 8px;
    }

    li:last-child {
        list-style-type: none;
    }
`;


export default EmployeeDeletePopup;