import { makeAutoObservable } from "mobx";
import { getPermissionForRoles } from "../../../api/go/useGetPermissionsForRoles";
import { changePermissionForRole } from "../../../api/go/useChangePermissionForRole";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { PermissionForRolesType, PermissionGroup, Role, Project } from "types/permission";
import MainStore from "MainStore";

class Store {
  roles: Role[] = [];
  projects: Project[] = [];
  permissionData: PermissionGroup[] = [];
  addEditRoleNamePanel = false;
  openPanelGrant = false;

  isProjectsExpanded: boolean = true;
  expandedProjects: number[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  changeAddEditRoleNamePanel = (flag: boolean) => {
    this.addEditRoleNamePanel = flag;
  };

  changeopenPanelGrant = (flag: boolean) => {
    this.openPanelGrant = flag;
  };

  clearStore() {}

  setData(data: PermissionForRolesType, firstLoad?: boolean) {
    this.permissionData = data.permissions;
    this.roles = data.roles;
    this.projects = data.projects;
    if(firstLoad){
      this.expandedProjects = data.projects.map(x => x.id)
    }
  }

  changeProjectExpanded = () => {
    this.isProjectsExpanded = !this.isProjectsExpanded;
  };

  openProjectPermissionExpanded = (id: number) => {
    this.expandedProjects = [...this.expandedProjects, id];
  };
  closeProjectPermissionExpanded = (id: number) => {
    let newExpandeds = this.expandedProjects;
    const index = this.expandedProjects.indexOf(id, 0);
    newExpandeds.splice(index, 1);
    this.expandedProjects = newExpandeds;
  };

  async doLoad(firstLoad?: boolean) {
    try {
      MainStore.changeLoader(true);
      const response = await getPermissionForRoles(StoreLayout.currentEntityId, "entity", StoreLayout.currentRegionId);
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        this.setData(response.data, firstLoad);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  handleError() {
    this.doLoad();
    MainStore.setSnackbar("Couldn't change it", "error");
  }

  changePermissionToType = async (
    idRole: number,
    idPermission: number,
    value: boolean,
    realId: number,
    type: string
  ) => {
    try {
      MainStore.changeLoader(true);
      const response = await changePermissionForRole(idRole, idPermission, value, realId, type, StoreLayout.currentRegionId);
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        MainStore.changeLoader(false);
        MainStore.setSnackbar("Success!");
        this.doLoad();
      } else {
        throw new Error();
      }
    } catch {
      this.handleError();
    } finally {
      MainStore.changeLoader(false);
    }
  };

  async changePermission(
    idRole: number,
    idPermission: number,
    value: boolean,
    realId: number,
    type: string,
    childPermissionId: number,
  ) {
    MainStore.openErrorConfirm(
      "Are you sure?",
      "",
      "Yes",
      "No",
      () => {
        this.changePermissionToType(idRole, idPermission, value, realId, type);
        if(childPermissionId){
          this.changePermissionToType(idRole, childPermissionId, true, realId, type);
        }
        MainStore.onCloseConfirm();
      },
      () => {
        MainStore.onCloseConfirm();
      },
      null,
      null,
      "primary",
      "inherit"
    );
  }
}

const store = new Store();
export default store;
