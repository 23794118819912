import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  margin-right: 10px;
`;

export const BackToTemplatesIcon = (
  props: React.HTMLAttributes<HTMLOrSVGElement>
) => (<StyledSvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.25H2.8725L7.065 1.0575L6 0L0 6L6 12L7.0575 10.9425L2.8725 6.75H12V5.25Z" fill="#959BA2" />
</StyledSvg>
);
