import { FC, useEffect } from "react";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import { Box } from "@mui/material"
import store from "../store"
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";


type SetupOrgStructurePopupProps = {
  closePopup: () => void;
};

const SetupOrgStructurePopup: FC<SetupOrgStructurePopupProps> = observer(() => {
  const navigate = useNavigate();

  useEffect(() => {
    store.getLink()
  }, []);

  return (
    <PopupWrapper>
      <BoxStyled>

        <Styledh2 id={`Employees_OrganizationalStructure_PopUp_Title`}>Looks like you have yet to set up your organizational structure</Styledh2>
      </BoxStyled>

      <BoxStyled>
        <Styledh5 id={`Employees_OrganizationalStructure_PopUp_Subtitle`}>
          Setting up an organizational structure would help your organization: <br />
          &#9679; View reports on the dashboard based on your organizational structure. <br />
          &#9679; Easily set up permissions for specific users/ groups on EngageRocket’s platform.
        </Styledh5>
      </BoxStyled>
      <BtnBox>
        <CustomButton
        id={`Employees_OrganizationalStructure_PopUp_SetupBtn`}
          variant="contained"
          onClick={() => {
            window.location.href = '/employees';
          }}
        >
          Setup
        </CustomButton>

      </BtnBox>

    </PopupWrapper>
  );
});

const PopupWrapper = styled.div`
  width: 500px;
  height: 450px;
`
const Styledh2 = styled.h2`
  margin: 70px 0px 0px 70px;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`

const Styledh5 = styled.h5`
  margin: 0px 0px 0px 70px;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`
const BoxStyled = styled(Box)`
  margin: 20px;
  display: flex;
  justify-content: space-around;

`

const BtnBox = styled(Box)`
  margin: 40px 50px 0px 70px;
  width: 300px;
  display: flex;
  justify-content: space-between;
`



export default SetupOrgStructurePopup;
