import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg``;

export const RolePermissionsIcon = (
  props: React.HTMLAttributes<HTMLOrSVGElement>
) => (
  <StyledSvg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.00016 14.1654C7.91683 14.1654 8.66683 13.4154 8.66683 12.4987C8.66683 11.582 7.91683 10.832 7.00016 10.832C6.0835 10.832 5.3335 11.582 5.3335 12.4987C5.3335 13.4154 6.0835 14.1654 7.00016 14.1654ZM12.0002 6.66536H11.1668V4.9987C11.1668 2.6987 9.30016 0.832031 7.00016 0.832031C4.70016 0.832031 2.8335 2.6987 2.8335 4.9987H4.41683C4.41683 3.5737 5.57516 2.41536 7.00016 2.41536C8.42516 2.41536 9.5835 3.5737 9.5835 4.9987V6.66536H2.00016C1.0835 6.66536 0.333496 7.41536 0.333496 8.33203V16.6654C0.333496 17.582 1.0835 18.332 2.00016 18.332H12.0002C12.9168 18.332 13.6668 17.582 13.6668 16.6654V8.33203C13.6668 7.41536 12.9168 6.66536 12.0002 6.66536ZM12.0002 16.6654H2.00016V8.33203H12.0002V16.6654Z"
      fill="#1477F8"
    />
  </StyledSvg>
);
