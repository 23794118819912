import http from "api/https";
import { createUrl } from "../../helpers/url";

export const getAllFooterLanguageMessages = (id: number): Promise<any> => {
  const query: { id: string; } = {id: id.toString(),};
  
  const url = createUrl({
    path: "/sm/sm_survey/GetAllFooterLanguageMessages",
    query: query,
  });
  return http.getGO(url);
};
