import { observer } from "mobx-react";
import { TTrendsCard, TTrendsQuestion } from "types/dashboard";
import { FC, useCallback, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import {
  QUESTION_TYPE,
  QUESTION_TYPE_VALUES,
  SCORE_TYPE_ENPS,
  SCORE_TYPE_OVERAL_SCORED,
  TREND_ACROSS,
} from "constants/dashboard-trends";

import store from "./store";
import styled from "styled-components";
import BarGraph from "./BarGraph";
import topStore from "../store";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomSwitch from "components/switch/Switch";
import ListSubheader from "@mui/material/ListSubheader";
import EmptyTrendCard from "./EmptyTrendCard";
import SelectedQuestions from "./SelectedQuestions";
import SelectQuestionPopup from "./SelectQuestionPopup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DisableTrendsScreen from "./DisableTrendsScreen";

type TrendCardProps = {
  card: TTrendsCard;
  cardIndex: number;
};

const TrendCard: FC<TrendCardProps> = observer((props) => {
  const [scoreTypeList, setScoreTypeList] =
    useState<{ label: string; value: string }[]>(SCORE_TYPE_OVERAL_SCORED);
  const [whoSelectedList, setWhoSelectedList] = useState<{ label: string; value: string }[]>([]);
  const [localQuestionsList, setLocalQuestionsList] = useState<TTrendsQuestion[]>([]);

  useEffect(() => {
    const dimennsionsList = store.ratingQuestionsList
      .filter((obj, idx, arr) => idx === arr.findIndex((t) => t.dimensions === obj.dimensions))
      .map((el) => el.dimensions);

    const formattedArray = dimennsionsList.map((el, index) => {
      const questionsList = store.ratingQuestionsList
        .map((question) => {
          if (question.dimensions === el) {
            return {
              id: question.id,
              title: question.title,
            };
          } else return null;
        })
        .filter((i) => i);

      return {
        dimension: el,
        list: questionsList,
      };
    });

    setLocalQuestionsList(formattedArray);

    formattedArray?.length > 0 &&
      store.setData(formattedArray[0], "activeSelectQuestionListDimension");
  }, [store.ratingQuestionsList]);

  const changeQuestionType = (value: number) => {
    if (value === QUESTION_TYPE_VALUES.enps_question) {
      setScoreTypeList(SCORE_TYPE_ENPS);
      store.changeFuulChartCardSettings(props.cardIndex, {
        ...props.card,
        score_type: SCORE_TYPE_ENPS[0].value,
        selectedQuestionsList: null,
        selectedQuestion: null,
        benchmark: null,
        whoSelected: null,
        question_type: value,
      });
    } else if (value === QUESTION_TYPE_VALUES.scored_question) {
      setScoreTypeList(SCORE_TYPE_OVERAL_SCORED);

      store.changeFuulChartCardSettings(props.cardIndex, {
        ...props.card,
        score_type: SCORE_TYPE_OVERAL_SCORED[0].value,
        selectedQuestionsList:
          (store.ratingQuestionsList &&
            store.ratingQuestionsList.length > 0 && [store.ratingQuestionsList[0]?.id]) ||
          [],
        whoSelected: null,
        benchmark: null,
        question_type: value,
      });
    } else if (value === QUESTION_TYPE_VALUES.yes_no) {
      const selectedQuestion =
        store.yesNoQuestionsList.length > 0 ? store.yesNoQuestionsList[0]?.id : null;

      const whoSelectedQuestion = store.yesNoQuestionsList.find((el) => el.id === selectedQuestion);

      if (whoSelectedQuestion) {
        let formatedArray = whoSelectedQuestion.options.map((el) => ({
          label: el.Value,
          value: el.Id,
        }));
        setWhoSelectedList(formatedArray);
      } else setWhoSelectedList([]);

      store.changeFuulChartCardSettings(props.cardIndex, {
        ...props.card,
        score_type: null,
        selectedQuestion: selectedQuestion,
        whoSelected:
          whoSelectedQuestion &&
            whoSelectedQuestion.options &&
            whoSelectedQuestion.options.length > 0
            ? whoSelectedQuestion.options[0].Id
            : null,
        question_type: value,
        benchmark: null,
      });
    } else if (value === QUESTION_TYPE_VALUES.msq) {
      const selectedQuestion =
        store.singleSelectQuestionsList.length > 0
          ? store.singleSelectQuestionsList[0]?.id
          : store.multipleQuestionsList.length > 0
            ? store.multipleQuestionsList[0]?.id
            : null;

      const singleFind = store.singleSelectQuestionsList.find((el) => el.id === selectedQuestion);

      const multipleFind = store.multipleQuestionsList.find((el) => el.id === selectedQuestion);

      const whoSelectedQuestion = singleFind ? singleFind : multipleFind;

      if (whoSelectedQuestion) {
        let formatedArray = whoSelectedQuestion.options.map((el) => ({
          label: el.Value,
          value: el.Id,
        }));
        setWhoSelectedList(formatedArray);
      } else setWhoSelectedList([]);

      store.changeFuulChartCardSettings(props.cardIndex, {
        ...props.card,
        score_type: null,
        selectedQuestion: selectedQuestion,
        whoSelected:
          whoSelectedQuestion &&
            whoSelectedQuestion.options &&
            whoSelectedQuestion.options.length > 0
            ? [whoSelectedQuestion.options[0].Id]
            : null,
        question_type: value,
        benchmark: null,
      });
    } else {
      setScoreTypeList(SCORE_TYPE_OVERAL_SCORED);
      store.changeFuulChartCardSettings(props.cardIndex, {
        ...props.card,
        score_type: SCORE_TYPE_OVERAL_SCORED[0].value,
        selectedQuestion: null,
        selectedQuestionsList: null,
        whoSelected: null,
        question_type: value,
        benchmark: null,
      });
    }
    store.changeChartCardSettings(props.cardIndex, "isCompareWithBenchmark", false);
  };

  const handleWSelectedQuestion = (e: SelectChangeEvent<number>) => {
    let whoSelectedQuestion = null;

    store.changeChartCardSettings(props.cardIndex, "selectedQuestion", e.target.value);

    if (props.card.question_type === QUESTION_TYPE_VALUES.msq) {
      const singleFind = store.singleSelectQuestionsList.find((el) => el.id === e.target.value);

      const multipleFind = store.multipleQuestionsList.find((el) => el.id === e.target.value);

      whoSelectedQuestion = singleFind ? singleFind : multipleFind;

      if (whoSelectedQuestion) {
        let formatedArray = whoSelectedQuestion.options.map((el) => ({
          label: el.Value,
          value: el.Id,
        }));

        setWhoSelectedList(formatedArray);

        store.changeChartCardSettings(props.cardIndex, "whoSelected", [formatedArray[0].value]);
      } else {
        setWhoSelectedList([]);
        store.changeChartCardSettings(props.cardIndex, "whoSelected", null);
      }
    } else {
      whoSelectedQuestion = store.yesNoQuestionsList.find((el) => el.id === e.target.value);

      if (whoSelectedQuestion) {
        let formatedArray = whoSelectedQuestion.options.map((el) => ({
          label: el.Value,
          value: el.Id,
        }));
        setWhoSelectedList(formatedArray);
        store.changeChartCardSettings(props.cardIndex, "whoSelected", formatedArray[0].value);
      } else {
        setWhoSelectedList([]);
        store.changeChartCardSettings(props.cardIndex, "whoSelected", null);
      }
    }
  };

  const returnGraph = useCallback(() => {
    if (
      !store.graphDataList[props.cardIndex] ||
      !topStore.selectedProject ||
      !topStore.isNotOnlyOpenendQuestions
    )
      return <DisableTrendsScreen />;
    if (!topStore.isNotOnlyOpenendQuestions) return <EmptyTrendCard isOnlyOpenendQns />;
    if (
      topStore.filters &&
      topStore.filters.length > 0 &&
      topStore.filters[0].unit_or_attr &&
      !Boolean(store.graphDataList[props.cardIndex]?.length)
    )
      return <EmptyTrendCard isHaveFilters />;
    if (
      ((props.card.question_type === QUESTION_TYPE_VALUES.yes_no ||
        props.card.question_type === QUESTION_TYPE_VALUES.msq) &&
        !Boolean(store.multipleQuestionsList.length) &&
        !Boolean(store.singleSelectQuestionsList.length)) ||
      (props.card.question_type === QUESTION_TYPE_VALUES.scored_question &&
        !Boolean(store.ratingQuestionsList.length))
    )
      return <EmptyTrendCard isMissingQuestions />;
    if (
      !Boolean(store.graphDataList[props.cardIndex]?.length) &&
      topStore.filters &&
      topStore.filters.length > 0 &&
      !topStore.filters[0].unit_or_attr
    )
      return <EmptyTrendCard />;

    return (
      <BarGraph
        graphData={store.graphDataList[props.cardIndex]}
        card={props.card}
        localQuestionsList={localQuestionsList}
      />
    );
  }, [store.graphDataList[props.cardIndex], topStore.selectedSurvey]);

  const handleBenchmarkFilter = () => {
    if (!props.card.isCompareWithBenchmark && topStore.benchmarkFilterList?.length > 0) {
      store.changeChartCardSettings(
        props.cardIndex,
        "benchmark",
        topStore.benchmarkFilterList[0].value
      );
    } else store.changeChartCardSettings(props.cardIndex, "benchmark", null);
    store.changeChartCardSettings(
      props.cardIndex,
      "isCompareWithBenchmark",
      !props.card.isCompareWithBenchmark
    );
  };

  const returnDisabledBenchmarkTooltip = () => {
    if (
      props.card.score_type !== SCORE_TYPE_OVERAL_SCORED[0].value &&
      (props.card.question_type === QUESTION_TYPE_VALUES.overal_score ||
        props.card.question_type === QUESTION_TYPE_VALUES.scored_question)
    )
      return `Benchmarks are only available for favorable scores.`;
    else if (
      props.card.score_type !== SCORE_TYPE_ENPS[0].value &&
      props.card.question_type === QUESTION_TYPE_VALUES.enps_question
    )
      return `Benchmarks are only available for eNPS score type.`;
    else if (props.card.question_type === QUESTION_TYPE_VALUES.overal_score)
      return `Overall score benchmarks are not available for this project.`;
    else if (props.card.question_type === QUESTION_TYPE_VALUES.scored_question)
      return `Scored questions benchmarks are not available for this project.`;
    else return `eNPS benchmarks are not available for this project.`;
  };

  const returnBenchmarkDisabledValue = () => {
    if (
      (props.card.score_type !== SCORE_TYPE_OVERAL_SCORED[0].value &&
        (props.card.question_type === QUESTION_TYPE_VALUES.overal_score ||
          props.card.question_type === QUESTION_TYPE_VALUES.scored_question)) ||
      (props.card.score_type !== SCORE_TYPE_ENPS[0].value &&
        props.card.question_type === QUESTION_TYPE_VALUES.enps_question) ||
      !Boolean(topStore.benchmarkFilterList?.length) ||
      !topStore.isNotOnlyOpenendQuestions
    )
      return true;
    else return false;
  };

  return (
    <Container>
      <TopContainer>
        <Group>
          <Title>
            Trend chart {topStore.isNotOnlyOpenendQuestions && `${props.cardIndex + 1}:`}
          </Title>
          {topStore.isNotOnlyOpenendQuestions && (
            <QuestionTypeSelect
              id="question_type_selected"
              value={props.card.question_type}
              onChange={(e: SelectChangeEvent<number>) =>
                changeQuestionType(Number(e.target.value))
              }
              displayEmpty
              IconComponent={KeyboardArrowDownIcon}
              MenuProps={{
                PaperProps: { sx: { maxHeight: 300, maxWidth: "10%", minWidth: 100 } },
              }}
            >
              {QUESTION_TYPE.map((scoreType) => (
                <StyledMenuItem key={scoreType.value} value={scoreType.value}>
                  <em>{scoreType.label}</em>
                </StyledMenuItem>
              ))}
            </QuestionTypeSelect>
          )}
        </Group>
        <Group>
          <SettingsLabel $isDisabled={!topStore.isNotOnlyOpenendQuestions}>
            Trend across:
          </SettingsLabel>
          <StyledSelect
            id="trend_across_selected"
            value={props.card.trendAcross}
            onChange={(e: SelectChangeEvent<string>) =>
              store.changeChartCardSettings(props.cardIndex, "trendAcross", e.target.value)
            }
            displayEmpty
            IconComponent={KeyboardArrowDownIcon}
            MenuProps={{
              PaperProps: { sx: { maxHeight: 300, maxWidth: "10%", minWidth: 100 } },
            }}
            disabled={!topStore.isNotOnlyOpenendQuestions}
          >
            {TREND_ACROSS.map((scoreType) => (
              <StyledMenuItem key={scoreType.value} value={scoreType.value}>
                <em>{scoreType.label}</em>
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </Group>
      </TopContainer>

      {props.card.question_type === QUESTION_TYPE_VALUES.scored_question && (
        <SelectedQuestions
          selectedquestionsList={props.card.selectedQuestionsList}
          localQuestionsList={localQuestionsList}
        />
      )}

      {(props.card.question_type === QUESTION_TYPE_VALUES.enps_question ||
        props.card.question_type === QUESTION_TYPE_VALUES.overal_score ||
        props.card.question_type === QUESTION_TYPE_VALUES.scored_question) && (
          <SettingsContainer>
            <SettingsLabel $isDisabled={!topStore.isNotOnlyOpenendQuestions}>
              Score type:
            </SettingsLabel>
            <StyledSelect
              id="score_type_selected"
              value={props.card.score_type}
              onChange={(e: SelectChangeEvent<string>) =>
                store.changeFuulChartCardSettings(props.cardIndex, {
                  ...props.card,
                  score_type: e.target.value,
                  isCompareWithBenchmark: false,
                  benchmark: null,
                })
              }
              displayEmpty
              IconComponent={KeyboardArrowDownIcon}
              MenuProps={{
                PaperProps: { sx: { maxHeight: 300, maxWidth: "10%", minWidth: 100 } },
              }}
              disabled={!topStore.isNotOnlyOpenendQuestions}
            >
              {scoreTypeList.map((scoreType) => (
                <StyledMenuItem key={scoreType.value} value={scoreType.value}>
                  <em>{scoreType.label}</em>
                </StyledMenuItem>
              ))}
            </StyledSelect>
            {/* {props.card.trendAcross === TREND_ACROSS[0].value && (
              <>
                <Divider>|</Divider>
                <YellowSquare $disabled={returnBenchmarkDisabledValue()} />
                <SettingsLabel $isDisabled={returnBenchmarkDisabledValue()}>
                  Compare with benchmark
                </SettingsLabel>
                <CustomWidthTooltip
                  title={returnDisabledBenchmarkTooltip()}
                  placement="top"
                  arrow
                  disableHoverListener={!returnBenchmarkDisabledValue()}
                >
                  <div>
                    <StyledCustomSwitch
                      checked={props.card.isCompareWithBenchmark}
                      onChange={handleBenchmarkFilter}
                      disabled={returnBenchmarkDisabledValue()}
                    />
                  </div>
                </CustomWidthTooltip>

                {props.card.isCompareWithBenchmark && (
                  <StyledSelect
                    id="benchmark_selected"
                    value={props.card.benchmark}
                    onChange={(e: SelectChangeEvent<string>) =>
                      store.changeChartCardSettings(props.cardIndex, "benchmark", e.target.value)
                    }
                    displayEmpty
                    IconComponent={KeyboardArrowDownIcon}
                    MenuProps={{
                      PaperProps: { sx: { maxHeight: 300, maxWidth: "10%", minWidth: 100 } },
                    }}
                  >
                    {topStore.benchmarkFilterList.map((benchmark) => (
                      <StyledMenuItem key={benchmark.value} value={benchmark.value}>
                        <em>{benchmark.label}</em>
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                )}
              </>
            )} */}
          </SettingsContainer>
        )}
      {(props.card.question_type === QUESTION_TYPE_VALUES.msq ||
        props.card.question_type === QUESTION_TYPE_VALUES.yes_no) && (
          <MsqSettingsConainer>
            <SettingsLabel>Select qn:</SettingsLabel>
            <MsqStyledSelect
              id="select_question"
              value={props.card.selectedQuestion}
              onChange={handleWSelectedQuestion}
              displayEmpty
              IconComponent={KeyboardArrowDownIcon}
              MenuProps={{
                PaperProps: { sx: { maxHeight: 300, maxWidth: "10%", minWidth: 100 } },
              }}
            >
              {props.card.question_type === QUESTION_TYPE_VALUES.msq &&
                store.singleSelectQuestionsList?.length > 0 && (
                  <StyledListSubheader>options: single-select</StyledListSubheader>
                )}
              {props.card.question_type === QUESTION_TYPE_VALUES.msq &&
                store.singleSelectQuestionsList.map((question) => (
                  <StyledMenuItem key={question.id} value={question.id}>
                    <em>{question.title}</em>
                  </StyledMenuItem>
                ))}
              {props.card.question_type === QUESTION_TYPE_VALUES.msq &&
                store.multipleQuestionsList?.length > 0 && (
                  <StyledListSubheader>options: multiple selection allowed</StyledListSubheader>
                )}
              {props.card.question_type === QUESTION_TYPE_VALUES.msq &&
                store.multipleQuestionsList.map((question) => (
                  <StyledMenuItem key={question.id} value={question.id}>
                    <em>{question.title}</em>
                  </StyledMenuItem>
                ))}
              {props.card.question_type === QUESTION_TYPE_VALUES.yes_no &&
                store.yesNoQuestionsList.map((question) => (
                  <StyledMenuItem key={question.id} value={question.id}>
                    <em>{question.title}</em>
                  </StyledMenuItem>
                ))}
            </MsqStyledSelect>
            <SettingsLabel>View % who selected:</SettingsLabel>
            {props.card.question_type === QUESTION_TYPE_VALUES.msq ? (
              <StyledSelect
                id="who_selected_multiple"
                value={Array.isArray(props.card.whoSelected) ? props.card.whoSelected : []}
                onChange={(e: SelectChangeEvent<typeof whoSelectedList>) =>
                  store.changeChartCardSettings(props.cardIndex, "whoSelected", e.target.value)
                }
                multiple
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{
                  PaperProps: { sx: { maxHeight: 300, maxWidth: "10%", minWidth: 100 } },
                }}
                renderValue={() => {
                  return (
                    <em>
                      {Array.isArray(props.card.whoSelected) && props.card.whoSelected.length}{" "}
                      {Array.isArray(props.card.whoSelected) && props.card.whoSelected.length === 1
                        ? "option"
                        : "options"}
                    </em>
                  );
                }}
              >
                {whoSelectedList.map((el) => (
                  <StyledMenuItem key={el.value} value={el.value}>
                    <Checkbox
                      checked={
                        Array.isArray(props.card.whoSelected) &&
                        props.card.whoSelected.includes(el.value)
                      }
                    />
                    <em>{el.label}</em>
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            ) : (
              <StyledSelect
                id="who_selected"
                value={props.card.whoSelected}
                onChange={(e: SelectChangeEvent<string>) =>
                  store.changeChartCardSettings(props.cardIndex, "whoSelected", e.target.value)
                }
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{
                  PaperProps: { sx: { maxHeight: 300, maxWidth: "10%", minWidth: 100 } },
                }}
              >
                {whoSelectedList.map((el) => (
                  <StyledMenuItem key={el.value} value={el.value}>
                    <em>{el.label}</em>
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            )}
          </MsqSettingsConainer>
        )}

      {returnGraph()}

      {store.cardList.length > 1 && (
        <ButtonWrapper>
          <RemoveButton variant="text" onClick={() => store.removeChartCard(props.cardIndex)}>
            <DeleteIcon />
            Remove chart
          </RemoveButton>
        </ButtonWrapper>
      )}
      <SelectQuestionPopup
        cardIndex={props.cardIndex}
        selectedQuestionsList={props.card.selectedQuestionsList}
        localQuestionsList={localQuestionsList}
      />
    </Container>
  );
});

export default TrendCard;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  border: 1px solid var(--colorNeutralBackground3);
  border-radius: 6px;
  background-color: var(--colorNeutralBackground1);
`;

const RemoveButton = styled(Button)`
  text-transform: none !important;
  width: fit-content !important;

  svg {
    font-size: 22px;
    margin-right: 4px;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 24px 48px 40px 48px;
  display: flex;
  justify-content: center;
`;

const SettingsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 48px;
  background-color: var(--colorPalleteLightBlue);
  border-bottom: 1px solid var(--colorNeutralForeground4);
`;

const SettingsLabel = styled.p<{ $isDisabled?: boolean }>`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${(props) =>
    props.$isDisabled ? "var(--colorNeutralForeground9)" : "var(--colorNeutralForeground1)"};
  margin-right: 4px;
`;

const Divider = styled.p`
  margin: 0px 24px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground6);
`;

const YellowSquare = styled.span<{ $disabled: boolean }>`
  width: 16px;
  height: 16px;
  background-color: ${(props) =>
    props.$disabled
      ? "var(--colorPaletteOpacityYellowForeground1)"
      : "var(--colorPaletteYellowForeground1)"};
  margin-right: 6px;
`;

const StyledSelect = styled(Select)`
  height: 34px;
  width: 160px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground1) !important;
    font-style: normal !important;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  white-space: normal !important;
  font-size: 14px !important;
  padding: 10px !important;
  em {
    font-style: normal !important;
  }
`;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 48px 16px 48px;
`;

const Group = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  color: var(--colorNeutralForeground1);
`;

const QuestionTypeSelect = styled(Select)`
  height: 36px;
  width: fit-content !important;
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid var(--colorBrandForeground1) !important;
  border-radius: 0px !important;
  margin-left: 16px !important;

  fieldset {
    border: none !important;
  }

  svg {
    color: var(--colorBrandForeground1) !important;
    font-size: 16px;
  }

  em {
    font-family: Roboto !important;
    font-style: normal !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 36px !important;
    color: var(--colorBrandForeground1) !important;
  }
`;

const MsqSettingsConainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 48px;
`;

const MsqStyledSelect = styled(Select)`
  height: 34px;
  width: 100% !important;
  max-width: 360px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-right: 16px !important;

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground1) !important;
    font-style: normal !important;
  }
`;

const StyledListSubheader = styled(ListSubheader)`
  text-transform: uppercase !important;
  color: var(--colorNeutralForeground5) !important;
`;

const StyledCustomSwitch = styled(CustomSwitch)`
  margin: 0px 12px !important;
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "center",
    background: "var(--colorShadowInverted2)",
  },
});
