import Link from "@mui/joy/Link";
import { Box } from "@mui/material";
import { observer } from "mobx-react";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import DisconnectHrisPopup from "../Employee/ListEmployee/DisconnectHrisPopup";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import CustomModal from "components/modal/Modal";

import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import storeEmployees from "features/Employees/store";
import store from "../Employee/ListEmployee/store";
import CustomSwitch from "components/switch/Switch";

const HrisAccordionAttribute = observer(() => {
  return (
    <Box>
      <CustomModal
        open={store.openDisconnectModal}
        hide={(flag) => {
          store.openDisconnectModal = flag;
        }}
        children={
          <DisconnectHrisPopup
            disconnect={() => {
              store.openDisconnectModal = false;
              storeEmployees.hrisToggle = false;
              StoreLayout.lockEdit = false;
            }}
            closePopup={() => {
              store.openDisconnectModal = false;
            }}
          />
        }
      />
      <AccordionStyle sx={{ border: "1px solid #F1F3F8", borderRadius: "4px" }}>
        <AccordionSummary
          style={{ minHeight: "43px", height: "43px" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="Attribute_AttributeList_HrisOpenClose"
        >
          {storeEmployees.curentEntityHris?.hris ? (
            <>
              <TypographyStyle
              id="Attribute_AttributeList_HrisTitle"
              style={{ display: "flex", flexDirection: "row" }}>
                HRIS:
                <CustomSwitch
                  checked={storeEmployees.hrisToggle}
                  onChange={() => {
                    if (storeEmployees.hrisToggle) {
                      store.openDisconnectModal = true;
                    } else {
                      storeEmployees.connectHris();
                    }
                  }}
                />
                {storeEmployees.curentEntityHris?.hris}
              </TypographyStyle>
            </>
          ) : (
            <>
              <TypographyStyle
              id="Attribute_AttributeList_HrisTitle"
              style={{ display: "flex", flexDirection: "row" }}>
                HRIS:
                <span style={{ marginLeft: 20 }}>
                  {storeEmployees.hrisLinkClicked ? (
                    <>We wil be in touch</>
                  ) : (
                    <StyledLink
                      disabled={!StorePermission.features.merge_dev.hasFeature}
                      onClick={(e) => {
                        e.stopPropagation();
                        storeEmployees.hrisLinkNotified();
                      }}
                    >
                      Link to HRIS
                    </StyledLink>
                  )}
                </span>
              </TypographyStyle>
            </>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <InsideAccordion>
            {storeEmployees.curentEntityHris?.hris ? (
              <>
                {storeEmployees.hrisToggle ? (
                  <span id="Attribute_AttributeList_HrisBody">
                    Disable HRIS synchronization to update the employee list and create new
                    attributes. Attributes created on the platform need to match those within HRIS.
                  </span>
                ) : (
                  <span id="Attribute_AttributeList_HrisBody" >Attributes created on the platform need to match those within HRIS.</span>
                )}
              </>
            ) : (
              <>
                {storeEmployees.hrisLinkClicked ? (
                  <span id="Attribute_AttributeList_HrisBody">
                    We have notified your Customer Success Manager of your request and get in touch
                    within 2-3 business days.
                  </span>
                ) : (
                  <span id="Attribute_AttributeList_HrisBody">
                    We will notify your Customer Success Manager of your request and get in touch
                    within 2-3 business days.
                  </span>
                )}
              </>
            )}
          </InsideAccordion>
        </AccordionDetails>
      </AccordionStyle>
    </Box>
  );
});

export default HrisAccordionAttribute;

const AccordionStyle = styled(Accordion)`
  width: 275px;
  margin: 20px 0;

  &:first-of-type {
    margin-top: 10px !important;
  }
`;

const TypographyStyle = styled.div`
  color: var(--colorNeutralForeground2);
  font-style: normal;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
`;

const InsideAccordion = styled.div`
  span {
    color: var(--colorNeutralForeground2);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

const StyledLink = styled(Link)`
  color: var(--colorBrandForeground1) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
`;
