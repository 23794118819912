import { FC } from "react";
import { RecipientsScreen } from "features/Survey/Recipients";

type RecipientsPageProps = {};

const RecipientsPage: FC<RecipientsPageProps> = () => {
  return <RecipientsScreen />;
};

export default RecipientsPage;
