import http from "api/https";
import { sm_question } from "types/surveys";

export const saveSurveyQuestionDimensions = (survey_questions: sm_question[], surveyId: number): Promise<any> => {
  let data = {
    survey_questions: survey_questions,
    survey_id: surveyId
  };
  if (http.isAPIGO) {
    return http.postGO("/sm/sm_question/UpdateQuestionDimension", data);
  } else {
    return http.post("/sm_question/UpdateRange", data);
  }
};
