const NoDataIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="88" height="66" viewBox="0 0 88 66" fill="none">
      <g opacity="0.3">
        <path
          d="M3.1145 63.2539V30.8495L13.8148 3.25391H74.7862L85.4865 30.8495V63.2539H3.1145Z"
          fill="white"
          stroke="#1477F8"
          stroke-width="5"
        />
        <path
          d="M3.1145 63.253V32.8809H21.2381C21.6361 36.5185 22.8916 41.0305 25.6077 44.9649C29.0773 49.9909 34.8578 53.9429 43.7463 53.9429C52.6206 53.9429 58.6115 50.0083 62.3827 45.06C65.399 41.1021 66.9737 36.5388 67.6118 32.8809H85.4865V63.253H3.1145Z"
          fill="#A9CDFF"
          stroke="#1477F8"
          stroke-width="5"
        />
      </g>
    </svg>
  );
};

export default NoDataIcon;
