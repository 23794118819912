import { makeAutoObservable } from "mobx";
import { inviteAgainUsers } from "../../../../api/go/useInviteAgainUsers";
import MainStore from "MainStore";
import { ACCESS_LEVEL_TYPE } from "constants/access-level-type";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import storeWs from "../../User/store"

class Store {
  constructor() {
    makeAutoObservable(this);
  }

  changeValue = (name: string, value: any) => {
    this[name] = value;
  };

  clearStore() {}

  async inviteAgainSubmit(idUsers: number[], onSaved: () => void) {
    try {
      MainStore.changeLoader(true)
      const response = await inviteAgainUsers(idUsers, ACCESS_LEVEL_TYPE.workspace, StoreLayout.currentWorkspaceId, StoreLayout.currentRegionId);
      if ((response.status === 201 || response.status === 200) && response?.data !== undefined) {
        const selected = storeWs.invitedUsers.find(x => x.id === idUsers[0])
        MainStore.setSnackbar(
          idUsers.length === 1 
          ? `“${selected?.email}” has been invited and will receive an email.`
          : `${idUsers.length} users have been invited and will receive an email.`
        );
        onSaved();
        MainStore.changeLoader(false)
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false)
    }
  }
}

const store = new Store();
export default store;
