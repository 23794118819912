import { FC, ReactElement, useEffect } from "react";
import store from './store'
import { observer } from "mobx-react"
import Dialog from '../../../../components/modal/Modal'
import CustomButton from "components/button/Button";
import styled from "styled-components";
import MainStore from "MainStore";


type RemoveFromRoleProps = {
  openPanel: boolean;
  onBtnCancelClick: () => void;
  onSaved: () => void;
  onClose: () => void;
  idRole: number;
  countUsers?: number;
  user_ids: number[];
  roleName: string;
  userEmail?: string;
};

const RemoveFromRole: FC<RemoveFromRoleProps> = observer((
  props
): ReactElement => {

  useEffect(() => {

  }, [props.openPanel])


  return (
    <Dialog
      open={props.openPanel}
      hide={() => { props.onClose() }}
      aria-label="Remove from workspace modal"
    >

      <MainWrapper>
        <Header  id={`WorkspaceUsers-PopUpRemoveRole_Title`}>
          Are you sure want to remove {props.countUsers == 1 ? `"${props.userEmail}"` : `${props.countUsers} users`} from "{props.roleName}" role?
        </Header>

        <Body id={`WorkspaceUsers-PopUpRemoveRole_Body`}>
          They will be removed from the role immediately. If this is their only role, they will lose access to the platform.
        </Body>

        <ButtonsWrapper>

          <ButtonWrapper>
            <CustomButton
            id={`WorkspaceUsers-PopUpRemoveRole_YesBtn`}
              onClick={() => {
                store.removeFromRoleSubmit(props.idRole, props.user_ids, () => {
                  if (props.countUsers == 1) {
                    MainStore.setSnackbar(`"${props.userEmail}" has been removed from "${props.roleName}".`)
                  } else {
                    MainStore.setSnackbar(`${props.countUsers} users have been removed from "${props.roleName}".`)
                  }
                  props.onSaved()
                })
              }} variant="contained" >
              Yes, remove from role
            </CustomButton>
          </ButtonWrapper>

          <ButtonWrapper>
            <CustomButton 
            id={`WorkspaceUsers-PopUpRemoveRole_CanselBtn`}
            onClick={() => { props.onBtnCancelClick() }} variant="contained" color="inherit">
              Cancel
            </CustomButton>
          </ButtonWrapper>

        </ButtonsWrapper>
      </MainWrapper>
    </Dialog >
  );
});

export default RemoveFromRole;

const MainWrapper = styled.div`
  margin: 40px 40px 20px 40px;
`;

const Header = styled.h1`
  font-family: Roboto;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const Body = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 20px 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 40px;
`;

const ButtonWrapper = styled.div`
  margin: 8px;
`;
