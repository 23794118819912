import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  .path-background {
    fill: var(--colorBrandForeground1);
  }
`;

export const ToRightIcon = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
    <path d="M1 0.941406L6.02803 5.94141L1 10.9414" stroke="#707882" />
  </StyledSvg>
);
