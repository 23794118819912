import { FC } from "react";
import styled from "styled-components";
import { observer } from 'mobx-react';
import store from './store'
import ActiveSeatsIcon from "./ActiveSeatsIcon";
import FeaturesIcon from "./FeaturesIcon";

type ActiveSeatsProps = {};

const ActiveSeatsBlock: FC<ActiveSeatsProps> = observer(() => {

  return (
    <MainWrapper>

      <ActiveSeats>
        <ActiveSeatsIcon />
        <ActiveSeatsText id="Eployees_ActiveSeats_ActiveSeats">
          Active seats: <br />
          {store.entitySetting?.active_seat_count_total} / {store.entitySetting?.active_seat_count}
        </ActiveSeatsText>

      </ActiveSeats>

      <ActiveSeats>
        <FeaturesIcon />
        <ActiveSeatsText id="Eployees_ActiveSeats_Features">
          Features: <br />
          {store.entitySetting?.features_count_total} / {store.entitySetting?.features_count}
        </ActiveSeatsText>

      </ActiveSeats>

      <UpgradeButton id="Eployees_ActiveSeats_UpgradeBtn">
        <UpgradeText>
          Upgrade
        </UpgradeText>
      </UpgradeButton>

    </MainWrapper>
  );
});

export default ActiveSeatsBlock;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  background: var(--colorPaletteBlueBackground3, #E4E9FF);
  padding: 20px 15px;
`;

const ActiveSeats = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 100%;
`;

const ActiveSeatsText = styled.div`
  margin-left: 5px;
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const UpgradeText = styled.span`
  color: var(--colorNeutralBackground1, #FFFFFF);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`

const UpgradeButton = styled.button`
  display: flex;
  width: 88px;
  height: 34px;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(92deg, var(--colorPaletteRedForeground4, #F7A79E) 8.55%, var(--colorPaletteVioletBackground3, #6E31FF) 100%);
  border: none;
  cursor: pointer;
`;
