import dayjs from "dayjs";

export function getTimeZone() {
  let offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    (offset < 0 ? "+" : "-") +
    ("00" + Math.floor(o / 60)).slice(-2) +
    ":" +
    ("00" + (o % 60)).slice(-2)
  );
}

export function getLocalTime(time: string): string {
  if (time === null || time === "" || time === undefined) return null;
  const currentDate = new Date();
  const [hours, minutes] = time.split(":").map(Number);
  currentDate.setUTCHours(hours, minutes, 0, 0);
  let day_js = dayjs(currentDate);
  return day_js.format("HH:mm");
}

export function toUtcTime(time: string): string {
  if (time === null || time === "" || time === undefined || time === "Invalid Date") return null;
  if (time.split(":").length !== 2) return null;
  const [hours, minutes] = time.split(":").map(Number);
  const currentDate = new Date();
  currentDate.setHours(hours, minutes, 0, 0);
  const utcTimeString = currentDate.toISOString().substr(11, 5);
  return utcTimeString;
}