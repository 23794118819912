import http from "api/https";
import { AddOrEditEmployeeType } from "types/employee";

export const AddOrEditEmployee = (data: AddOrEditEmployeeType): Promise<any> => {
  if (http.isAPIGO) {
    return http.postGO("/employee/employees/AddOrUpdate", data);
  } else {
    return http.post("/employees/AddOrUpdate", data);
  }
};
