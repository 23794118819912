import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
margin: 5px 10px 0px 0px;
`;

export const SurveyAccessIcon = (
  props: React.HTMLAttributes<HTMLOrSVGElement>
) => (
  <StyledSvg
    width="20px"
    height="20px"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14.167 0.840365L5.83366 0.832031C4.91699 0.832031 4.16699 1.58203 4.16699 2.4987V17.4987C4.16699 18.4154 4.91699 19.1654 5.83366 19.1654H14.167C15.0837 19.1654 15.8337 18.4154 15.8337 17.4987V2.4987C15.8337 1.58203 15.0837 0.840365 14.167 0.840365ZM14.167 14.9987H5.83366V4.9987H14.167V14.9987ZM6.66699 8.33203H13.3337V9.58203H6.66699V8.33203ZM7.50033 10.832H12.5003V12.082H7.50033V10.832Z" fill="#A9AFC6" />

  </StyledSvg>
);
