import http from "api/https";
import {
  QuestionnaireTemplate,
} from "types/templates";

export const addQuestionnaireTemplate = (data: QuestionnaireTemplate): Promise<any> => {
  if(http.isAPIGO){
    return http.postGO('/template/questionaries_save', data)
  } else {
    return http.post('/templ_template_questionary', data)
  }
};
