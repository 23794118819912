export const FolderImageIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <circle cx="12.5" cy="12.5" r="12.5" fill="#A9CDFF" />
      <path
        d="M17.75 7.21054H13.04L12.8 6.4737C12.6444 6.04134 12.3558 5.66723 11.9742 5.40319C11.5925 5.13915 11.1368 4.99825 10.67 5.00002H7.25C6.65326 5.00002 6.08097 5.23291 5.65901 5.64746C5.23705 6.06202 5 6.62427 5 7.21054V16.7895C5 17.3757 5.23705 17.938 5.65901 18.3526C6.08097 18.7671 6.65326 19 7.25 19H17.75C18.3467 19 18.919 18.7671 19.341 18.3526C19.7629 17.938 20 17.3757 20 16.7895V9.42106C20 8.8348 19.7629 8.27254 19.341 7.85799C18.919 7.44343 18.3467 7.21054 17.75 7.21054ZM18.5 16.7895C18.5 16.9849 18.421 17.1723 18.2803 17.3105C18.1397 17.4487 17.9489 17.5263 17.75 17.5263H7.25C7.05109 17.5263 6.86032 17.4487 6.71967 17.3105C6.57902 17.1723 6.5 16.9849 6.5 16.7895V7.21054C6.5 7.01512 6.57902 6.8277 6.71967 6.68951C6.86032 6.55133 7.05109 6.4737 7.25 6.4737H10.67C10.8272 6.4733 10.9806 6.52146 11.1085 6.61137C11.2363 6.70128 11.3322 6.8284 11.3825 6.97475L11.7875 8.18317C11.8378 8.32952 11.9337 8.45664 12.0615 8.54655C12.1894 8.63646 12.3428 8.68462 12.5 8.68422H17.75C17.9489 8.68422 18.1397 8.76185 18.2803 8.90004C18.421 9.03822 18.5 9.22564 18.5 9.42106V16.7895Z"
        fill="white"
      />
    </svg>
  );
};
