import http from "api/https";
import { createUrl } from "../../helpers/url";

export type GetEntitiesResponseData = {
  id: number;
  name: string;
  disabled: boolean;
  created_at: string;
  employeesCount: number;
  entityAdminCount: number;
  signinby: string;
  bold_bi_site_name: string;
  updated_at: string;
  logo_id?: number;
  logo_url?: string;
  details: string;
  id_orgstructure: number;
  hris_merge_integration: string;
  id_orgstructureNavName: string;
  id_orgstructureNavCode: string;
};


export const getEntityInfo = async (id: number): Promise<any> => {

  if (http.isAPIGO) {
    return http.getGO('/entity/GetOneForUpdate/' + id) //TODO check GO endpoints for C#-GetOneById

  } else {
    return http.get('/entity/GetOneById?id=' + id)
  }
};

export const getImportProgress = async (id: number): Promise<any> => {
  const url = createUrl({
    path: "/entity/get_import_progress",
    query: { entity_id: id.toString() },
  });  
  return http.getGO(url)
};

