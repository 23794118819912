import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "components/pagination/Pagination";
import dayjs from "dayjs";
import styled from "styled-components";
import { Checkbox } from "@mui/material";
import "./ScrollContainer.css";
import store from "./store";
import { observer } from "mobx-react";
import { Employee } from "types/milestone";
import NoDataIcon from "features/Employees/NoDataIcon";
import CustomButton from "components/button/Button";
import { useNavigate } from "react-router-dom";
import { createUrl } from "helpers/url";

type TablePermissionProps = {};

const BasicTable: React.FC<TablePermissionProps> = observer(() => {
  const navigate = useNavigate()

  const isSelected = (id: number) => store.millstoneSelectedRecipients.indexOf(id) !== -1;
  const changeCurrentCount = (num: number) => {
    store.paginationItemsPerPage = num;
    store.paginationActivePage = 1;
    store.onSearchClicked();
  };

  const navigateHandler = () => {
    navigate(createUrl({
      path: '/recipients',
      query: {
         id: String(store.surveyId)
         }
     })
    )
  }

  return (
    <>
      <TablesWrapper>
        <MainTableWrapper
          style={{
            boxShadow:
              store.Employees.length > 0 ? "5px 1px 4px -2px var(--colorGrayForeground6)" : "",
          }}
        >
          <Table className="child-surveys" aria-label="simple table">
            <TableHead data-testid={`MilstoneRecepient_TableHead_1_Container`}>
              <SurveyTableRowHeader data-testid={`MilstoneRecepient_TableHead_1_Row`}>
                <SurveyValueTableCellHeader data-testid={`MilstoneRecepient_TableHead_1_FullName`} width={300}>
                  FULL NAME
                </SurveyValueTableCellHeader>
                <SurveyValueTableCellHeader data-testid={`MilstoneRecepient_TableHead_1_PreferredName`} width={150}>PREFERRED NAME</SurveyValueTableCellHeader>
                <SurveyValueTableCellHeader data-testid={`MilstoneRecepient_TableHead_1_EmailAddress`} width={230}>EMAIL ADDRESS</SurveyValueTableCellHeader>
              </SurveyTableRowHeader>
            </TableHead>

          {store.Employees.length > 0 ? (
            <TableBody data-testid={`MilstoneRecepient_TableBody_1`}>
              {store.Employees.slice(
                (store.paginationActivePage - 1) * store.paginationItemsPerPage,
                (store.paginationActivePage - 1) * store.paginationItemsPerPage +
                store.paginationItemsPerPage,
              ).map((employee: Employee, index) => {
                return (
                  <SurveyTableRow data-testid={`MilstoneRecepient_TableBody_1_Row_${index}`} key={employee.id}>
                    <SurveyValueTableCell data-testid={`MilstoneRecepient_TableBody_1_FullName_${index}`} scope="row" width={300}>
                      {employee.fullName?.slice(0, 35)}{" "}
                    </SurveyValueTableCell>
                    <SurveyValueTableCell data-testid={`MilstoneRecepient_TableBody_1_PreferredName_${index}`} scope="row" width={150}>
                      {employee.preferredName?.slice(0, 20)}
                    </SurveyValueTableCell>
                    <SurveyValueTableCell data-testid={`MilstoneRecepient_TableBody_1_EmailAddress_${index}`} scope="row" width={230}>
                      {" "}
                      {employee.email?.slice(0, 30)}{" "}
                    </SurveyValueTableCell>
                  </SurveyTableRow>
                );
              })}
            </TableBody>
            ) : (
              <StyledContainerEmpty></StyledContainerEmpty>
            )}
          </Table>
        </MainTableWrapper>

        <SurveyTableWrapper className="parent-surveys">
          <Table className="child-surveys" aria-label="simple table">
            <TableHead data-testid={`MilstoneRecepient_TableHead_2_Container`}>
              <SurveyTableRowHeader data-testid={`MilstoneRecepient_TableHead_2_Row`}>
                {!!store.AllData?.milestone_attriubute_name && (
                  <SurveyValueTableCellHeader align="left">
                    {store.AllData.milestone_attriubute_name}
                  </SurveyValueTableCellHeader>
                )}
                <SurveyValueTableCellHeader data-testid={`MilstoneRecepient_TableHead_2_Status`} align="left">STATUS</SurveyValueTableCellHeader>

                {store.Surveys.map((x, i) => {
                  return (
                    <SurveyValueTableCellHeader data-testid={`MilstoneRecepient_TableHead_2_${i}`} align="left" key={x.id}>
                      {x.name?.slice(0, 35)}
                    </SurveyValueTableCellHeader>
                  );
                })}
              </SurveyTableRowHeader>
            </TableHead>

          {store.Employees.length > 0 ? (
            <TableBody data-testid={`MilstoneRecepient_TableBody_2`}>
              {store.Employees.slice(
                (store.paginationActivePage - 1) * store.paginationItemsPerPage,
                (store.paginationActivePage - 1) * store.paginationItemsPerPage +
                store.paginationItemsPerPage,
              ).map((employee: Employee, index) => {
                return (
                  <SurveyTableRow data-testid={`MilstoneRecepient_TableBody_2_row_${index}`} key={employee.id}>
                    {!!store.AllData?.milestone_attriubute_name && (
                      <SurveyValueTableCell align="left">
                        {employee.start_date_attribute_value}
                      </SurveyValueTableCell>
                    )}
                    <SurveyValueTableCell data-testid={`MilstoneRecepient_TableBody_2_Status_${index}`} align="left">
                      {employee.automatical ? "Auto-trigger" : "Schedule manually"}
                    </SurveyValueTableCell>
                    {store.Surveys.map((survey, i) => {
                      let date_milestone = employee.recepients.filter(
                        (x) => x.survey_id === survey.id,
                      );
                      let res = "Schedule manually";
                      let status = "unsheduled";
                      const now = dayjs()

                      if (Boolean(date_milestone.length)) {
                        if (Boolean(date_milestone[0]?.DatestartMilstone)) {
                          status = date_milestone[0].status;
                          const day_js = dayjs(new Date(date_milestone[0].DatestartMilstone));
                          const strDate = day_js.format("DD MMM YYYY");
                          if (status === "sent") {
                            res = "Sent on " + strDate;
                          }else if (day_js > now.add(-1, "day")){
                            res = strDate
                          }
                        }
                      }

                      return (
                        <SurveyValueTableCell key={i} align="left">
                          {status === "sent" ? (
                            <>{res}</>
                          ) : (
                            <ScheduleManually
                              data-testid={`MilstoneRecepient_TableBody_2_sheduleManually_${i}_row_${index}`}
                              onClick={() => {
                                if (
                                  Boolean(date_milestone.length) &&
                                  date_milestone[0].DatestartMilstone
                                ) {
                                  let date = dayjs(new Date(date_milestone[0].DatestartMilstone));
                                  store.setDate(date);
                                }
                                store.editSurveySchedule(
                                  survey.id,
                                  employee.id,
                                  survey.name,
                                  employee.fullName,
                                  status,
                                );
                              }}
                            >
                              {res}
                            </ScheduleManually>
                          )}
                        </SurveyValueTableCell>
                      );
                    })}
                  </SurveyTableRow>
                );
              })}
            </TableBody>
           ) : (
                <StyledContainerEmpty>
                  <NoDataIcon />
                  <StyledEditRecipients
                   data-testid={`MilstoneRecepient_EditRecipients_BtnGrid`}
                   variant="outlined"
                   onClick={navigateHandler}
                  >
                    Edit recipients
                  </StyledEditRecipients>
                </StyledContainerEmpty>
           )}
          </Table>
        </SurveyTableWrapper>
      </TablesWrapper>

      <PaginationWrapper>
        <Pagination
          activePage={store.paginationActivePage}
          itemsCountPerPage={store.paginationItemsPerPage}
          onChange={(e) => store.paginationSetActivePage(e)}
          pageRangeDisplayed={store.paginationItemsPerPage}
          totalItemsCount={store.Employees.length}
          totalPages={store.Employees.length / store.paginationItemsPerPage}
          setCount={changeCurrentCount}
        />
      </PaginationWrapper>
    </>
  );
});

const TablesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const MainTableWrapper = styled(TableContainer)`
  position: sticky;
  background-color: var(--colorNeutralBackground1);
`;

const SurveyTableWrapper = styled(TableContainer)`
  background-color: white;
`;

const SurveyTableRow = styled(TableRow)`
  height: 63px;
  max-height: 63px;
`;

const SurveyTableRowHeader = styled(TableRow)`
  height: 70px;
  max-height: 70px;
`;

const SurveyValueTableCellHeader = styled(TableCell)`
  min-width: 150px;
  padding: 10px !important;
  margin: 0;
  background-color: #e4e9ff;
  color: var(--colorNeutralForeground2);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;

  &:first-child {
    padding-left: 20px !important;
  }
`;

const StyledCheckBox = styled(Checkbox)`
  padding: 0 10px !important;
  color: var(--colorBrandForeground1) !important;
`;

const SurveyValueTableCell = styled(TableCell)`
  min-width: 170px;
  padding: 10px !important;
  margin: 0;
  color: var(--colorNeutralForeground1);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  &:first-child {
    padding-left: 20px !important;
  }
`;

const ScheduleManually = styled.span`
  color: var(--colorBrandForeground1);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const StyledContainerEmpty = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  height: 337px;
`;

const StyledEditRecipients = styled(CustomButton)`
  width: 180px;
`;

export default BasicTable;
