import http from "api/https";
import { makeAutoObservable } from "mobx";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { EntityForShare, templ_template_comms } from "types/templates";
import { removeTemplateCommunication } from "api/go/useRemoveTemplateCommunication";
import { removeTemplateQuestionnaire } from "api/go/useRemoveTemplateQuestionnaire";
import { dublicateCommunicationTemplate } from "api/go/useDublicateCommunicationTemplate";
import { duplicateQuestionnaireTemplate } from "api/go/useDuplicateQuestionnaireTemplate";
import { getTemplatesFilter } from "api/go/useGetTemplatesFilter";
import MainStore from "MainStore";
import { getShareEntityTemplates } from "api/go/useGetShareEntityTemplates";
import { shareTemplate } from "api/go/useShareTemplate";
import { sm_tags } from "../../../types/projects";
import { GetSurveyTags } from "../../../api/go/useGetSurveyTags";

class Store {
  data: templ_template_comms[] = [];
  unchanged_data: templ_template_comms[] = [];
  openPanelAddTemplate = false;
  searchedValue: string = "";
  resultSearchValue: string = "";
  showIcon = 0;
  paramUrl = "";
  navUrl = "";
  expandedNav = "";
  groupUrl = "";
  btnIcon = "";
  openDeleteConfirm = false;
  selectedRow: templ_template_comms = null;
  sortField: string | null = null;
  sortType: string | null = null;
  cardData = [];
  openSharePopup = false;
  shareEntityTemplates: EntityForShare[] = [];
  tags: sm_tags[] = [];
  selected_tags_ids: number[] = [];
  types: string[] = ["Communication", "Questionnaire"];
  selected_types: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  changePanelAddTemplate = (flag: boolean) => {
    this.openPanelAddTemplate = flag;
  };

  setData = (data: templ_template_comms[]) => {
    this.data = data;
    this.unchanged_data = data;
  };

  onSearchClicked = () => {
    this.resultSearchValue = this.searchedValue;
    this.searchedValue = "";
    this.getTemplatesFilter();
  };

  RemoveTemplate = (template: templ_template_comms) => {
    this.selectedRow = template;
    this.changeOpenDeleteConfirm(true);
  };

  duplicateTemplate = async (template: templ_template_comms, callback: () => void) => {
    this.selectedRow = template;
    if (this.selectedRow == null) {
      return;
    }

    const successRequest = async (data: any) => {
      MainStore.changeLoader(false);
      await this.getTemplatesFilter();
      const filtered = this.data?.find((item) => {
        if (http.isAPIGO) {
          return item.id === data?.id;
        }
        return item.id === data;
      });
      const nameProject = filtered?.name.replace("(", " (");
      MainStore.setSnackbar(
        `"${this.selectedRow.name}" successfully duplicated, creating "${nameProject}"`
      );
      callback();
    };

    if (this.selectedRow.type === "questionnaire") {
      try {
        MainStore.changeLoader(true);
        const { data } = await duplicateQuestionnaireTemplate(
          this.selectedRow.id,
          StoreLayout.currentEntityId
        );
        await successRequest(data);
      } catch {
        MainStore.setSnackbar("Something went wrong", "error");
      } finally {
        MainStore.changeLoader(false);
      }
    } else if (this.selectedRow.type === "communication") {
      try {
        MainStore.changeLoader(true);
        const { data } = await dublicateCommunicationTemplate(
          this.selectedRow.id,
          StoreLayout.currentEntityId
        );
        await successRequest(data);
      } catch {
        MainStore.setSnackbar("Something went wrong", "error");
      } finally {
        MainStore.changeLoader(false);
      }
    }
  };

  RemoveTemplateSubmit = () => {
    if (this.selectedRow == null) {
      return;
    }

    if (this.selectedRow.type === "questionnaire") {
      MainStore.changeLoader(true);
      removeTemplateQuestionnaire(this.selectedRow.id).then((x) => {
        MainStore.changeLoader(false);
        if (x?.data !== undefined) {
          MainStore.setSnackbar(`"${this.selectedRow.name}" is deleted`);
          this.getTemplatesFilter();
        }
      });
    } else if (this.selectedRow.type === "communication") {
      MainStore.changeLoader(true);
      removeTemplateCommunication(this.selectedRow.id).then((x) => {
        MainStore.changeLoader(false);
        if (x?.data !== undefined) {
          MainStore.setSnackbar(`"${this.selectedRow.name}" is deleted`);
          this.getTemplatesFilter();
        }
      });
    }
  };

  onClickBtn = (nav: string, group: string) => {
    this.navUrl = nav;
    this.groupUrl = group;
    this.getTemplatesFilter();
  };

  extractParamFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const navUrl = urlParams.get("nav");
    const groupUrl = urlParams.get("group");
    this.navUrl = navUrl === null || navUrl === "" ? "entity" : navUrl;
    this.expandedNav = navUrl === null || navUrl === "" ? "entity" : navUrl;
    this.groupUrl = groupUrl === null || groupUrl === "" ? "all" : groupUrl;
    this.loadTemplates();
  };

  changeAccordion =
    (panelId: "engageRocket" | "entity" | "shared") => (event: React.SyntheticEvent) => {
      this.expandedNav = panelId;
    };

  openClickPopup(boolean: boolean) {
    this.openSharePopup = boolean;
  }

  shareTemplateRow = (template: templ_template_comms) => {
    this.selectedRow = template;
  };

  loadEntityTemplates = () => {
    MainStore.changeLoader(true);
    getShareEntityTemplates(
      StoreLayout.currentEntityId,
      this.selectedRow.id,
      this.selectedRow.type,
    ).then((x) => {
      MainStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.shareEntityTemplates = x.data;
        this.openClickPopup(true);
      }
    });
  };
  shareTemplate = async (entity_id: number) => {
    try {
      MainStore.changeLoader(true);
      const response = await shareTemplate(
        entity_id,
        this.selectedRow.id,
        this.selectedRow.type,
        StoreLayout.currentEntityId,
      );
      if (response.status === 201 || response.status === 200) {
        this.loadEntityTemplates();
        MainStore.setSnackbar(`"${this.selectedRow.name}" successfully shared`);
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Someting went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  keyPress = (e: any) => {
    if (e.keyCode == 13) {
      // on enter clicked
      this.onSearchClicked();
    }
  };

  getTemplatesFilter = async () => {
    let nav = this.navUrl === null || this.navUrl === "" ? "entity" : this.navUrl;
    let group = this.groupUrl === null || this.groupUrl === "" ? "all" : this.groupUrl;
    try {
      MainStore.changeLoader(true);
      const response = await getTemplatesFilter(
        nav,
        group,
        StoreLayout.currentEntityId,
        this.resultSearchValue,
        this.sortField,
        this.sortType,
      );
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        this.setData(response.data);
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Someting went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  loadTemplates = () => {
    this.getTemplatesFilter();
  };

  changeValue = (name: string, value: string) => {
    this[name] = value;
  };

  changeSort = (field: string, type: string) => {
    this.sortField = field;
    this.sortType = type;
    this.getTemplatesFilter();
  };

  changeOpenDeleteConfirm = (flag: boolean) => {
    this.openDeleteConfirm = flag;
  };

  changeTag = (id: number) => {
    const index = this.selected_tags_ids.indexOf(id, 0);
    let new_arr = this.selected_tags_ids;
    if (index > -1) {
      new_arr.splice(index, 1);
    } else {
      new_arr = [...new_arr, id];
    }
    this.selected_tags_ids = new_arr;
    this.getTemplatesFilter();
  };

  changeTypes = (type: string) => {
    const index = this.selected_types.indexOf(type, 0);
    let new_arr = this.selected_types;
    if (index > -1) {
      new_arr.splice(index, 1);
    } else {
      new_arr = [...new_arr, type];
    }
    this.selected_types = new_arr;
    this.getTemplatesFilter();
  };

  getSurveyTags = async () => {
    try {
      const response = await GetSurveyTags();
      this.tags = response;
    } catch (err) {
      MainStore.setSnackbar("Can't found tags", "error");
    }
  };
}

const store = new Store();
export default store;
