import { FC } from "react";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import { Box, Typography, TextField } from "@mui/material"
import store from "./store"
import { observer } from "mobx-react";

type NonTestPopupProps = {
  closePopup: () => void;
};

const DeleteAttributePopup: FC<NonTestPopupProps> = observer((props) => {
  const selected = store.data.filter(x => x.id === store.selected?.id)
  if (selected.length === 0) return
  return (
    <PopupWrapper>
      <Styledh2  id="AttributesList_PopUpDelete_Title">Are you sure you want to delete “{store.selected?.name}”?</Styledh2>
      <BoxStyled>
        <Typography  id="AttributesList_PopUpDelete_Subtitle" >
          There are {store.selected?.total_segments} segments in this particular attribute.<br /><br /> If you decide to proceed, all segments within this attribute will be removed from EngageRocket’s platform.
          <br /><br />
          Not to worry, this does not affect your employees, – they won't be going anywhere and will still remain in your employee list.
        </Typography>
      </BoxStyled>
      <BoxStyled>
        <Typography id="AttributesList_PopUpDelete_DeleteInfo">
          To confirm deletion, please enter DELETE below:
        </Typography>
      </BoxStyled>
      <BoxTextField>
        <TextField
          id="AttributesList_PopUpDelete_Delete_Input"
          label="DELETE"
          variant="standard"
          fullWidth
          value={store.deleteValue}
          onChange={(e) => store.changeValue("deleteValue", e.target.value)}
        />
        <TypographyErrorText id="AttributesList_PopUpDelete_Delete_Input_Error">{store.errorTextDelete}</TypographyErrorText>
      </BoxTextField>

      <BtnBox>
        <Btn>
          <CustomButton
          id="AttributesList_PopUpDelete_Delete_Btn"
            variant="contained"
            disabled={store.deleteValue !== "DELETE"}
            onClick={() => { store.deleteClicked() }}
          >
            Remove attribute
          </CustomButton>
        </Btn>
        <Btn>
          <CustomButtonStyled
          id="AttributesList_PopUpDelete_Cancel_Btn"
            onClick={() => {
              props.closePopup()
            }}
            variant="outlined"
          >
            Cancel
          </CustomButtonStyled>
        </Btn>
      </BtnBox>

    </PopupWrapper>
  );
});

const PopupWrapper = styled.div`
  width: 500px;
`
const Styledh2 = styled.h2`
  margin: 70px 50px 0px 70px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
`
const BoxStyled = styled(Box)`
  margin: 40px 50px 0px 70px;
  width: 420px;
`
const BtnBox = styled(Box)`
  margin: 40px 50px 50px 70px;
  display: flex;
`
const Btn = styled.div`
  margin-right: 30px;
`
const CustomButtonStyled = styled(CustomButton)`
  border: 1px solid var(--colorNeutralForeground5, #959BA2) !important;
  border-radius: 4px;
  color:  var(--colorNeutralForeground2, #707882) !important;
  width: 90px;
  height: 34px;
`
const BoxTextField = styled(Box)`
  margin: 20px 50px 0px 70px;
`
const TypographyErrorText = styled(Typography)`
  color: var(--colorPaletteRedForeground2, #BD0202);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`


export default DeleteAttributePopup;
