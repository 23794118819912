import { makeAutoObservable } from "mobx";
import { getManagersWithoutPermissions } from "../../../api/go/useGetManagersWithoutPermissions";
import { getRolesByEntity } from "../../../api/go/useGetRolesByEntity";
import { getEntityInfo } from "api/go/useGetEntityInfo";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { ManagerEmployee } from "types/employee";
import { Role } from "types/permission";
import MainStore from "MainStore";
import { inviteUsersToRole } from "api/go/useInviteUsersToRole";
import { ORG_STRUCTURE_CODES } from "constants/entityManagementTypes";
import { DEFAULT_ROLES } from "constants/default-roles";

class Store {
  managers: ManagerEmployee[] = [];
  roles: Role[] = [];
  selectedData: number[] = [];
  selectedRoles: Role[] = [];

  saved_org_structure_code = "";

  constructor() {
    makeAutoObservable(this);
  }

  changeValue = (name: string, value: any) => {
    this[name] = value;
  };

  getManagers = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getManagersWithoutPermissions(StoreLayout.currentEntityId);
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        this.managers = response.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getRoles = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getRolesByEntity(StoreLayout.currentEntityId);
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        this.roles = response.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  async inviteUsersSubmit() {
    let idRole = 0;
    if(store.saved_org_structure_code === ORG_STRUCTURE_CODES.manager_based) {
      idRole = this.roles.find(x => x.code === DEFAULT_ROLES.managers)?.id
    }else{
      idRole = this.roles.find(x => x.code === DEFAULT_ROLES.unit_heads)?.id
    }
    MainStore.changeLoader(true);
    let emails = this.managers.filter((x) => this.selectedData.includes(x.id)).map((x) => x.email);
    try {
      const response = await inviteUsersToRole(idRole, StoreLayout.currentEntityId, emails, "entity", StoreLayout.currentRegionId);
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        this.selectedData = [];
        this.doLoad();
      } else {
        MainStore.setSnackbar(response.response.data?.error, "error");
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  doLoad = () => {
    this.getManagers();
    this.getRoles();
  };

  clearStore() {
    this.managers = [];
    this.roles = [];
    this.selectedData = [];
    this.selectedRoles = [];
    this.saved_org_structure_code = "";
  }

  getCurrentEntity(idEntity: number) {
    getEntityInfo(idEntity).then((x) => {
      this.saved_org_structure_code = x.data.id_orgstructureNavCode;
    });
  }
}

const store = new Store();
export default store;
