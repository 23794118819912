import { FC } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

type NoSurveysAvailableProps = {};

const NoSurveysAvailable: FC<NoSurveysAvailableProps> = () => {
  const navigate = useNavigate();

  const handleClickButton = () => navigate("/employees");

  return (
    <Container>
      <Content>
        <CustomImage alt={"empty-dashboard-image"} src={"/assets/empty-dasboard.png"} />
        <Label>No surveys available</Label>
        <Description>Get started by setting up your employee list</Description>
        <SetUpEmployeeButton variant="contained" onClick={handleClickButton}>
          Set up employee list
        </SetUpEmployeeButton>
      </Content>
    </Container>
  );
};

export default NoSurveysAvailable;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 109px 64px;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1156px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px;
  background-color: var(--colorNeutralBackground1);
`;

const SetUpEmployeeButton = styled(Button)`
  height: 34px !important;
  text-transform: none !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  margin-top: 24px !important;
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  margin-top: 24px;
  color: var(--colorNeutralForeground1);
`;

const Description = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.23px;
  margin-top: 8px;
  color: var(--colorNeutralForeground2);
`;

const CustomImage = styled.img`
  width: 108px;
  height: 109px;
`;
