import { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import styled from "styled-components";
import EmailIcon from "@mui/icons-material/Email";

import { DeleteIcon } from "features/Employees/AttributeList/components/DeleteIcon";
import ChevronCircleIcon from "features/Survey/Recipients/ChevronCircleIcon";
import RichTextWithTabsAll from "../../RichTextWithTabsAll";
import CustomButton from "components/button/Button";
import { ReminderSurvey } from "types/surveys";
import PreviewIcon from "../../PreviewIcon";
import CalendarIcon from "./icons/CalendarIcon";
import TimeField from "./components/TimeField";
import { Lookup } from "./components/LookUp";
import store from "./../../store";

type ReminderEditProps = {
  reminder: ReminderSurvey;
  index: number;
  short_scheduled_date: string;
};

const ReminderEdit: FC<ReminderEditProps> = observer((props) => {
  const { reminder_days_id } = props.reminder;
  const [selectedDay, setSelectedDay] = useState<null | number | string>(null);

  useEffect(() => {
    const selectedOption = store.ReminderDays.find((option) => option.id === reminder_days_id);
    if (selectedOption) {
      const dayValue = dayjs(store.survey_date_start)
        .add(selectedOption.reminders_after_days, "day")
        .format("ddd, DD MMM, YYYY");
      setSelectedDay(dayValue);
    }
  }, [reminder_days_id, store.survey_date_start]);

  const optionDays =
    store.survey_date_start && store.survey_date_end
      ? store.ReminderDays.filter(
          (x) =>
            dayjs(store.survey_date_start).add(x.reminders_after_days, "day") <
            dayjs(store.survey_date_end)
        )
      : store.ReminderDays;

  const missingIds = store.ReminderDays.map((x) => x.id).filter(
    (id) => !optionDays.map((x) => x.id).includes(id)
  );

  useEffect(() => {
    return () => {
      props.reminder.strtime_send_reminder = null;
    };
  }, []);

  return (
    <Wrapped id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd">
      <TopOfEdit>
        <TopLeft>
          <p id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Title">
            Reminder email {props.index + 1}
          </p>
          <LinkPreview
            id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Remove_Button"
            onClick={() => store.DeleteReminderSubmit(props.index)}
          >
            <DeleteIcon />
            Delete
          </LinkPreview>
        </TopLeft>
        <TopRight>
          <LinkPreview
            id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Close_Button"
            onClick={() => store.changeOpenReminderIndex(props.index, false)}
          >
            Hide
            <ChevronCircleIcon style={{ marginLeft: 8 }} />
          </LinkPreview>
        </TopRight>
      </TopOfEdit>

      <SubHeader id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_RecipientsSelect_Label">
        Recipients For reminder
      </SubHeader>

      <StyledLookup
        data={store.ReminderGroups}
        value={props.reminder.reminder_recipients_group_id}
        errorText=""
        error={false}
        name="recipients"
        onChange={(e) => store.changeReminderGroups(+e.target.value, props.index)}
      />

      <SubHeader id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_RecipientsSelect_Label">
        Send after invite email
      </SubHeader>

      <SendAfter>
        <LookUpWrapper>
          <StyledLookup
            data={store.ReminderDays}
            value={props.reminder.reminder_days_id}
            errorText={"Select a date before the end of the survey"}
            error={missingIds.includes(props.reminder.reminder_days_id)}
            onChange={(e) => store.changeReminderDays(+e.target.value, props.index)}
            disabledOption={missingIds}
          />
          {props.short_scheduled_date && (
            <ScheduledDate>
              <CalendarIconWrapp>
                <CalendarIcon />
              </CalendarIconWrapp>
              {selectedDay}
            </ScheduledDate>
          )}
        </LookUpWrapper>
        <div>
          <TimeField
            onChange={(e) => store.changeReminderTime(e, props.index)}
            value={props.reminder.strtime_send_reminder}
          />
          <TimeZone id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_SendAfterSelect_TimeZone">
            Local timezone: UTC {store.timezone_message}
          </TimeZone>
        </div>
      </SendAfter>

      <SubHeader id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_EmailSubject_Label">
        EMAIL SUBJECT
      </SubHeader>

      <EditorWrapper>
        <RichTextWithTabsAll
          onlyText
          data={props.reminder.reminder_message_languages}
          width={100}
          changeValue={(value: string, field: string, index: number) =>
            store.changeReminderData(value, field, props.index, index)
          }
          field="subject_email"
          editorId="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_Subject_RichTextWith_Editor"
        />
      </EditorWrapper>

      <SubHeader id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_EmailContent_Label">
        EMAIL CONTENT
      </SubHeader>

      <EditorWrapper>
        <RichTextWithTabsAll
          data={props.reminder.reminder_message_languages}
          width={200}
          changeValue={(value: string, field: string, index: number) =>
            store.changeReminderData(value, field, props.index, index)
          }
          field="body_email"
          editorId="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_Content_RichTextWith_Editor"
        />
      </EditorWrapper>

      <BottomWrapper>
        <ButtonWrapper>
          <CustomButton
            id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Save_Button"
            variant="contained"
            disabled={missingIds.includes(props.reminder.reminder_days_id)}
            onClick={() => store.saveReminderData()}
          >
            Save changes
          </CustomButton>

          <StyledCancelButton
            id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Cancel_Button"
            variant="text"
            onClick={() => store.changeOpenReminderIndex(props.index, false)}
          >
            Cancel
          </StyledCancelButton>
        </ButtonWrapper>

        <ButtonWrapper>
          <LinkPreview
            id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Preview_Button"
            onClick={() => store.loadPreviewMessage(props.reminder.id_comms_reminder, false)}
          >
            <PreviewIcon />
            Preview email
          </LinkPreview>

          <ResendEmailButton
            id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Resend_Button"
            variant="outlined"
            onClick={() => store.setData("isOpenResendEmailPoup", true)}
            disabled={dayjs(props.short_scheduled_date) > dayjs()}
          >
            <EmailIcon />
            Resend email
          </ResendEmailButton>
        </ButtonWrapper>
      </BottomWrapper>
    </Wrapped>
  );
});

export default ReminderEdit;

const Wrapped = styled.div`
  min-height: 100px;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid var(--colorPaletteBlueBackground1);
`;

const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
`;

const LinkPreview = styled.a`
  color: var(--colorBrandForeground1);
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledLookup = styled(Lookup)`
  margin: 0 !important;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const StyledCancelButton = styled(CustomButton)`
  color: var(--colorNeutralForeground2) !important;
`;

const TopOfEdit = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const TopLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const TopRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const EditorWrapper = styled.div`
  .MuiBox-root {
    padding: 0 !important;
    border-bottom: 0;
  }
  .MuiTab-root {
    border-radius: 4px 4px 0px 0px !important;
  }
  .Mui-selected {
    border: 1px solid var(--colorBrandForeground1) !important;
    border-bottom: none !important;
    border-radius: 4px 4px 0px 0px !important;
  }
  .rsw-editor {
    border-radius: 0px 0px 4px 4px !important;
  }
`;

const SubHeader = styled.h2`
  color: var(--colorNeutralForeground2);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  margin-top: 25px;
  margin-bottom: 8px;
`;

const SendAfter = styled.div`
  display: flex;
  align-items: start;
  gap: 20px;

  .MuiStack-root {
    padding-top: 10px;
  }
`;

const LookUpWrapper = styled.div`
  padding-top: 10px;
`;

const CalendarIconWrapp = styled.span`
  margin: 5px;
`;

const ScheduledDate = styled.div`
  margin: 10px 0;
  color: var(--colorNeutralForeground5);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const TimeZone = styled.div`
  margin: 10px 0;
  color: var(--colorNeutralForeground5);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const ResendEmailButton = styled(CustomButton)`
  border: 1px solid var(--colorNeutralForeground6) !important;
  padding: 5px 37px !important;
  svg {
    margin-right: 8px;
    font-size: 16px;
    margin-bottom: 2px;
  }
`;
