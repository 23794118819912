import { FC } from "react";
import { DashboardScreen } from "features/Dashboard";

type DashboardPageProps = {};

const DashboardPage: FC<DashboardPageProps> = () => {
  return <DashboardScreen />;
};

export default DashboardPage;
