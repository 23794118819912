import { FC } from "react";
import { sm_question } from "types/templates";
import styled from "styled-components";
import { Autocomplete, Box, Checkbox, Divider, TextField, Tooltip, InputAdornment } from '@mui/material'
import store from './storeSurvey'
import { observer } from "mobx-react";
import FilterListIcon from "@mui/icons-material/FilterList";
import CustomButton from "components/button/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { newQuestions } from "types/surveys";


export type questionTypeProps = {
  item: sm_question[]
  onQuestionsAdded: (questions: newQuestions[], commTemplateId: number) => void
}
const QuestionCard: FC<{ item: sm_question, index: number }> = observer((props) => {

  return <Item key={props.item.id} id={"SelectTemplateQuestionsQuestion_" + props.index}>
    <DimensionName id={"SelectTemplateQuestionsDimensionName_" + props.index}>{props.item.dimension_idNavName}</DimensionName>
    <CardBlock>
      <LeftSideCard>
        <TextSecondary id={"SelectTemplateQuestionsTypeName_" + props.index}>
          {props.item.type_question_idNavName}
        </TextSecondary>
        <TextPrymary id={"SelectTemplateQuestionsTitle_" + props.index}>
          {props.item.title}
        </TextPrymary>
      </LeftSideCard>
      <CheckboxWrapp>
        {store.addedQuestionNames.includes(props.item.id) ?
          <Tooltip id={"SelectTemplateQuestionsCheckboxToolTip_" + props.index} title="Question already added">
            <div>
              <Checkbox
                name="isCheked"
                disabled={true}
                id={"SelectTemplateQuestionsCheckbox_" + props.index}
                onChange={(e) => store.setCheckTemplateQuestion(props.item.id)} size="small"
                checked={true}
              />
            </div>
          </Tooltip> :
          <Checkbox
            name="isCheked"
            disabled={store.addedQuestionNames.includes(props.item.id)}
            id={"SelectTemplateQuestionsCheckbox_" + props.index}
            onChange={(e) => store.setCheckTemplateQuestion(props.item.id)} size="small"
            checked={store.selectedTemplateQuestions.includes(props.item) || store.addedQuestionNames.includes(props.item.id)}
          />}
      </CheckboxWrapp>
    </CardBlock>
  </Item>
});

const QuestionTable: FC<questionTypeProps> = observer((props) => {

  const selectedTemplate = store.templatesData.find((x) => x.id === store.selectedQuestionnaireId);
  const estimation = selectedTemplate?.estimation;

  return <QuestionContent id={"SelectTemplateQuestions"}>

    <HeaderContent>
      <HeaderTitle id={"SelectTemplateQuestionsHeaderLabel"}>{store.templatesData.find(x => x.id === store.selectedQuestionnaireId)?.name}</HeaderTitle>
      <HeaderDescription id={"SelectTemplateQuestionsDescription"}>
        <span id={"SelectTemplateQuestionsCount"}>{store.countCheckedCheckboxes()}</span> questions
        {estimation && <span id="SelectTemplateQuestionsEstimation">, {estimation}</span>}
      </HeaderDescription>
      <HeaderActions>

        <StyledAutocomplete
          id={"SelectTemplateQuestionsDimenstionSelect"}
          options={store.typeDimensions}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              placeholder="All questions"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <StyledFilterListIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          value={store.dimensionTemplateQuestionId}
          getOptionLabel={(option: any) => option.name}
          onChange={(e, value: any) => {
            store.changeQuestionDimensionFilter(value)
          }}
          popupIcon={<KeyboardArrowDownIcon />}
          isOptionEqualToValue={(option, value) => option === value}
        />
        <CustomButton
          variant="contained"
          id={"SelectTemplateQuestionsAddCheckedQuestionsButton"}
          disabled={store.selectedTemplateQuestions.length === 0}
          onClick={() => {
            let add_quest = store.selectedTemplateQuestions.map((x): newQuestions => {
              return {
                id: x.id,
                name: x.name,
                title: x.title,
                dimension: x.dimension_idNavName,
                dimension_id: x.dimension_id,
                type_id: x.type_question_id,
                type: x.type_question_code,
                questionJson: x.questionJson
              }
            })
            props.onQuestionsAdded(add_quest, store.selectedCommunication)
          }}
        >
          Add checked questions
        </CustomButton>
      </HeaderActions>

    </HeaderContent>
      <Divider />

<ContainerItemStyled>
    <SelectButtons>

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <CustomButton
          disabled={store.selectedTemplateQuestions.length === 0}
          id={"SelectTemplateQuestionsDeselectAllButton"}
          onClick={() => {
            store.deselectAllTemplateQuestions()
          }}
        >
          Deselect all
        </CustomButton>
        <Divider orientation="vertical" style={{ height: 16, margin: "0 10px" }} />
        <CustomButton
          id={"SelectTemplateQuestionsSelectAllButton"}
          disabled={store.selectedTemplateQuestions.length + store.addedQuestionNames.filter(x => store.templateQuestions.map(x => x.id).includes(x)).length === store.templateQuestions.length}
          onClick={() => {
            store.selectAllTemplateQuesions()
          }}
        >
          Select all
        </CustomButton>
      </Box>
    </SelectButtons>

    {props.item.map((x, i) => (
      <QuestionCard key={x.id} index={i} item={x} />
    ))}
</ContainerItemStyled>
  </QuestionContent>
});


const CardBlock = styled.div`
  border-radius: 4px;
  border: 1px solid var(--ER-Theme-sys-light-outline, #F1F3F8);
  background: var(--ER-Theme-white, #FFF);
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin: 7px 0px;
`

const Item = styled.div`
  margin-bottom: 20px; 
`

const DimensionName = styled.p`
  color: var(--ER-Theme-sys-light-on-tertiary-container, #707882);
  /* ER Theme/label/large */
  font-family: Roboto;
  font-size: 14px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const StyledAutocomplete = styled(Autocomplete)`
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;

  input {
    width: 300px !important;
    border-radius: 2px !important;
    padding: 0px !important;
    font-family: Roboto !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .MuiOutlinedInput-root {
    border-radius: 2px !important;
  }
`;

const StyledTextField = styled(TextField)`
  ::placeholder {
    color: var(--colorNeutralForeground5);
    font-weight: 400 !important;
    font-family: Roboto !important;
  }
`;

const StyledFilterListIcon = styled(FilterListIcon)`
  color: var(--colorBrandForeground1) !important;
  width: 20px !important;
  margin-right: 6px;
`;


const LeftSideCard = styled.div`

`

const CheckboxWrapp = styled.div`
  min-width: 40px;
  min-height: 40px;
  .MuiSvgIcon-root {
    width: 24px!important;
    height: 24px!important;
  }
`;

const TextSecondary = styled.p`
  color: var(--ER-Theme-sys-light-on-tertiary-fixed-variant, #959BA2);
  /* ER Theme/label/small */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
`;

const ContainerItemStyled = styled.div`
  padding: 0 30px 20px;
  overflow-y: scroll;
  height: 555px;
  background-color: var(--colorNeutralForeground8);

  &::-webkit-scrollbar {
    width: 8px !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: var(--colorGrayForeground6);
  }
`;

const QuestionContent = styled.div`
`;

const HeaderContent = styled.div`
  padding: 20px 30px 10px;
`;

const SelectButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0 0;
`

const HeaderTitle = styled.div`
  color: var(--ER-Theme-sys-light-on-secondary-container, #3E4450);
  /* ER Theme/headline/small */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 180% */
`;
const HeaderDescription = styled.div`
  color: #3E4450;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const HeaderActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0px 10px 0;
`;

const TextPrymary = styled.p`
  color: var(--ER-Theme-sys-light-on-secondary-container, #3E4450);
  /* ER Theme/body/large */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;


export default QuestionTable;