import CustomButton from "components/button/Button";
import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import storeComm from "features/Templates/CommunicationTemplates/store";
import { Divider } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

type HeaderMenuProps = {};

const HeaderMenu: FC<HeaderMenuProps> = observer(() => {
  return (
    <>
      <HeaderMenuWrapper id="Template_Communication_Header_Menu">
        <ItemMenu
          key={"Communication"}
          id="Template_Communication_Header_Menu_Communication"
          $active={true}
        >
          Communication
        </ItemMenu>
        <ButtonsWrapper>
          <ButtonWrapper>
            <TemplatesButton
              id="Template_Communication_Header_Menu_Templates_Button"
              variant="outlined"
              onClick={() => storeComm.changeSelectTemplate(true)}
            >
              Templates
            </TemplatesButton>
          </ButtonWrapper>
          <DividerStyle orientation="vertical" flexItem />
          <ButtonWrapper>
            <SettingsButton
              id="Template_Communication_Header_Menu_Templates_CommsSettings_Button"
              variant="outlined"
              onClick={() => storeComm.setOpenCommsModal(true)}
            >
              <SettingsIcon />
              Comms settings
            </SettingsButton>
          </ButtonWrapper>
        </ButtonsWrapper>
      </HeaderMenuWrapper>
    </>
  );
});

export default HeaderMenu;

const HeaderMenuWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--colorNeutralBackground1);
  padding: 0 40px 0 80px;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonWrapper = styled.div`
  margin: 0 10px;
`;

const ItemMenu = styled.li<{ $active?: boolean }>`
  list-style-type: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: ${(props) =>
    props.$active ? "var(--colorBrandForeground1)" : "var(--colorPaletteVioletBackground1)"};
  width: fit-content;
  border-bottom: ${(props) => props.$active && `4px solid var(--colorBrandForeground1)`};
  height: 100%;
  padding: ${(props) => (props.$active ? "4px" : "0px")} 16px 0;
`;

const DividerStyle = styled(Divider)`
  margin: 11px 10px !important;
`;

const SettingsButton = styled(CustomButton)`
  width: 180px;

  svg {
    margin-right: 5px;
  }
`;

const TemplatesButton = styled(CustomButton)`
  width: 180px;
`;
