import http from "api/https"
import { createUrl } from "../../helpers/url";

export const getAttributesByEntityWithSegments = (entity_id: number): Promise<any> => {
  const query: { entity_id: string } = { entity_id: entity_id.toString() };
  if (http.isAPIGO) {
    return http.getGO("entity/GetWithSegmentsByEntity/" + entity_id);
  } else {
    const url = createUrl({
      path: "/entity_attributes/GetWithSegmentsByEntity",
      query: query,
    });
    return http.get(url);
  }
};
