import http from "api/https";
import { FooterTemplateLanguage } from "types/templates";

export const SaveFooterTemplateMessages = async (
  footer_data: FooterTemplateLanguage[],
  template_id: number,
  logo_file: any,
  remove_file: boolean
) => {

  const formData = new FormData();
  formData.append("template_id", template_id + "");
  formData.append("file", logo_file);
  formData.append("remove_file", remove_file ? "true": "false")
  // formData.append("footer_data", JSON.stringify( footer_data));
  footer_data.forEach((language, index) => {
    Object.entries(language).forEach(([key, value]) => {
      formData.append(`footer_data[${index}].${key}`, value);
    });
  });

  footer_data.forEach(obj => {
    obj["template_id"] = template_id;
  });

  if(http.isAPIGO){
    return http.postGO("/template/save_footer_template_messages", formData);
  } else {
    return http.post("/templ_template_comms/SaveFooterTemplateMessages", formData);
  }
};
