const CustomCheckedRadioIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
    <circle cx="14" cy="14.3984" r="13.5" fill="#1477F8" stroke="#D9DDE7" />
    <path
      d="M10.0005 15.0879L12.7983 17.8857L18.811 10.3984"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default CustomCheckedRadioIcon;
