import { makeAutoObservable, reaction } from "mobx";
import { addOrUpdateRole } from "../../../../api/go/useAddOrUpdateRole";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { checkUniqueRoleName } from "api/go/useCheckUniqueRoleName";
import MainStore from "MainStore";
import { ACCESS_LEVEL_TYPE } from "constants/access-level-type";

class Store {
  idRole = 0;
  name = "";
  errorname = "";
  isCheckingDelay = false;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.name,
      (newName) => {
        this.delayedCheckNameUniqueness(newName);
      },
      { delay: 500 }
    );
  }

  changeValue = (name: string, value: any) => {
    if (name === "name") {
      this.changeValue("isCheckingDelay", true)
      if (!value) {
        this.changeValue("errorname", "Name is a required field");
      } else {
        this.changeValue("errorname", "");
      }
    }
    this[name] = value;
  };

  clearStore() {
    this.name = "";
    this.errorname = "";
  }
  private async delayedCheckNameUniqueness(newName: string) {
    if (this.errorname || !newName) return
    try {
      const response = await checkUniqueRoleName(StoreLayout.currentWorkspaceId, ACCESS_LEVEL_TYPE.workspace, newName, this.idRole, StoreLayout.currentRegionId);
      if ((response.status === 201 || response.status === 200) && response?.data !== undefined) {
        if (response.data === true) {
          this.changeValue("errorname", "");
        } else {
          this.changeValue("errorname", `“${newName}” already exists as a role.`);
        }
        this.changeValue("isCheckingDelay", false)
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }


  async saveRole(idRole: number, onSave: (x: number) => void) {
    if(this.name === ""){
      return this.changeValue("errorname", "Name is a required field");
    }
    try {
      MainStore.changeLoader(true);
      const response = await addOrUpdateRole(
        StoreLayout.currentWorkspaceId,
        idRole,
        this.name,
        "workspace",
        StoreLayout.currentRegionId
      );
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        if (idRole == 0) {
          MainStore.setSnackbar(`"${this.name}" has been created.`);
        } else {
          MainStore.setSnackbar(`Role has been renamed as "${this.name}".`);
        }
        onSave(response.data);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar(`Something went wrong...`, "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }
}

const store = new Store();
export default store;
