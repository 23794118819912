import http from "../https";

export const setOrganizationalStructure = (
  entityId: number,
  structure: string,
  regionId: number
): Promise<any> => {

  if (http.isAPIGO) {
    return http.postGO(`/entity/UpdateEntityStructure`, {
      idEntity: entityId,
      code: structure,
      regionId: regionId,
    });
  } else {
    return http.post(`/entity/UpdateEntityStructure`, {
      idEntity: entityId,
      code: structure,
      regionId: regionId,
    });
  }
};
