import http from "api/https";
import {
  QuestionnaireTemplate,
} from "types/templates";

export const saveQuestionnaireTemplate = (data: QuestionnaireTemplate): Promise<any> => {
  if(http.isAPIGO){
    return http.putGO('/template/templ_template_questionary/' + data.id, data)
  } else {
    return http.put('/templ_template_questionary/' + data.id, data)
  }
};
