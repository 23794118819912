import { FC } from "react";

import Dialog from "@mui/material/Dialog";
import styled from "styled-components";
import DialogContent from "@mui/material/DialogContent";

type ModalProps = {
  open: boolean;
  hide: (state: boolean) => void;
  children: any;
  id?: string;
  width?: string;
  maxWidth?: "xl" | "lg" | "md" | "sm" | "xs" | false;
  minHeight?: string;
  maxHeight?: string;
  minHeightContent?: number;
  maxHeightContent?: number;
  padding?: string;
  closeIcon?: boolean;
  overflow?: "auto" | "clip";
};

const CustomModal: FC<ModalProps> = ({
  open,
  hide,
  children,
  id,
  width,
  maxWidth,
  minHeight,
  maxHeight,
  minHeightContent = 350,
  maxHeightContent,
  padding = "20px 24px",
  closeIcon = true,
  overflow = "auto",
}) => {
  return (
    <StyledDialog
      onClose={() => hide(false)}
      aria-labelledby="customized-dialog-title"
      fullWidth
      id={(id ? id : "") + "CustomModal"}
      open={open}
      maxWidth={maxWidth}
      $width={width}
      // $maxHeight={minHeight}
    >
      {closeIcon && <CloseModal id={(id ? id : "") + "ClosePopup"} onClick={() => hide(false)} />} 
      <DialogContent
        style={{
          overflow: overflow,
          minHeight: minHeightContent,
          padding,
          maxHeight: maxHeightContent,
        }}
      >
        {children}
      </DialogContent>
    </StyledDialog>
  );
};

export default CustomModal;

const StyledDialog = styled(Dialog)<{ $width: string | undefined }>`
  .MuiDialogContent-root {
    width: ${(props) => props.$width && props.$width};
  }
  .MuiDialog-paper {
    width: ${(props) => props.$width && props.$width};
    border-radius: 10px;
  }
`;

const CloseModal = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  font-size: 16px;
  transition: opacity ease 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 10px;
    display: block;
    width: 24px;
    height: 2px;
    background: var(--colorCross);
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;
