import http from "api/https";
import { createUrl } from "../../helpers/url";

export const checkUniqueRoleName = (
  realId: number,
  accessLevel: string,
  name: string,
  roleId: number,
  region_id: number
): Promise<any> => {
  const query: {
    real_id: string,
    access_level: string,
    region_id: string,
    role_id: string,
    name: string
  } = {
    real_id: realId.toString(),
    access_level: accessLevel,
    region_id: region_id.toString(),
    role_id: roleId.toString(),
    name: name
  };

  if (http.isAPIGO) {

    const url = createUrl({
      path: "/permission/role/CheckUniqueRoleName",
      query: query,
    });

    return http.getGO(url);
  }

  const url = createUrl({
    path: "/role/CheckUniqueRoleName",
    query: query,
  });

  return http.get(url);
};
