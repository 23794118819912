export const ROLE_LIST = {
    ADMIN: {
        name: "Admin",
        value: "ADMIN",
    },
    MANAGER: {
        name: "Manager",
        value: "MANAGER",
    },
    UNIT_HEAD: {
        name: "Unit head",
        value: "UNIT_HEAD",
    },
    ROLL_UP_TEAM: {
        name: "Roll-up team",
        value: "ROLL_UP_TEAM",
    },
    DIRECT_TEAM: {
        name: "Direct team",
        value: "DIRECT_TEAM",
    },
};
