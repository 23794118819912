import http from "api/https";

export const GetTemplReminderDays = async (): Promise<any> => {
  if(http.isAPIGO){
    return http.getGO("/template/templ_reminders_days");
  } else {
    return http.get("/templ_reminders_days/GetAll");
  }
};


export const getTemplReminderDays = (): Promise<any> => {
  if(http.isAPIGO){
    return http.getGO("/sm/templ_reminders_days/GetAll");
  } else {
    return http.get("/templ_reminders_days/GetAll");
  }
};

