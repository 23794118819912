import http from "api/https";
import {
  ReminderSurvey,
} from "types/surveys";

export const saveReminderSurveyCommunication = (data: ReminderSurvey): Promise<any> => {
  if (http.isAPIGO) {
    return http.postGO('/sm/sm_survey/SaveReminderSurveyCommunication', data)
  } else {
    return http.post('/sm_survey/SaveReminderSurveyCommunication', data)
  }
};
