export const FEATURES_SUBSCRIPTION = {
  surveys: { code: "surveys", hasFeature: false },
  frequency: { code: "frequency", hasFeature: false },
  milestone: { code: "milestone", hasFeature: false },
  one_off: { code: "one_off", hasFeature: false },
  field_360: { code: "field_360", hasFeature: false },
  email: { code: "email", hasFeature: false },
  dashboard_overview: { code: "dashboard_overview", hasFeature: false },
  dashboard_responses: { code: "dashboard_responses", hasFeature: false },
  dashboard_hotspot: { code: "dashboard_hotspot", hasFeature: false },
  dashboard_trends: { code: "dashboard_trends", hasFeature: false },
  dashboard_prioritize: { code: "dashboard_prioritize", hasFeature: false },
  questionnaire_sharing: { code: "questionnaire_sharing", hasFeature: false },
  communications_sharing: { code: "communications_sharing", hasFeature: false },
  merge_dev: { code: "merge_dev", hasFeature: false },
  azure: { code: "azure", hasFeature: false },
  survey_template: { code: "survey_template", hasFeature: false },
  milestone_template: { code: "milestone_template", hasFeature: false },
  review_template: { code: "review_template", hasFeature: false },
  communication_template: { code: "communication_template", hasFeature: false },
  all_templates: { code: "all_templates", hasFeature: false },
};
