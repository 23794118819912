import { ROLE_LIST } from "constants/role-list";
import { TTrendsCard } from "types/dashboard";
import { FC, useEffect } from "react";
import { QUESTION_TYPE_VALUES } from "constants/dashboard-trends";
import { DASHBOARD_MENU_VALUES } from "constants/dashboard-menu";

import store from "./store";
import topStore from "../store";
import TrendCard from "./TrendCard";

type TrendCardWrapperProps = {
    card: TTrendsCard;
    cardIndex: number;
    localFilters: { id: number; name: string; segments: string[] }[];
};

const TrendCardWrapper: FC<TrendCardWrapperProps> = (props) => {
    useEffect(() => {
        if (
            props.card &&
            topStore.selectedProject &&
            topStore.role &&
            topStore.isDisplayTrends &&
            topStore.activeTab === DASHBOARD_MENU_VALUES.trends
        )
            store.getGraphData(
                {
                    project_id: topStore.selectedProject.id,
                    attributes: props.localFilters,
                    trendType: props.card.question_type,
                    trendAccross: props.card.trendAcross,
                    scoredType:
                        props.card.question_type === QUESTION_TYPE_VALUES.msq ||
                            props.card.question_type === QUESTION_TYPE_VALUES.yes_no
                            ? undefined
                            : props.card.score_type,
                    questionIds:
                        props.card.question_type === QUESTION_TYPE_VALUES.msq ||
                            props.card.question_type === QUESTION_TYPE_VALUES.yes_no
                            ? [props.card.selectedQuestion]
                            : props.card.selectedQuestionsList,
                    answered: Array.isArray(props.card.whoSelected)
                        ? props.card.whoSelected
                        : typeof props.card.whoSelected === "string"
                            ? [props.card.whoSelected]
                            : undefined,
                    benchMarkId: props.card.benchmark || undefined,
                    viewType:
                        topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
                },
                props.cardIndex
            );
    }, [
        props.card,
        topStore.selectedProject,
        props.localFilters,
        topStore.role,
        topStore.isDisplayTrends,
        topStore.activeTab,
    ]);

    return <TrendCard card={props.card} cardIndex={props.cardIndex} />;
};

export default TrendCardWrapper;
