import { makeAutoObservable, reaction } from "mobx";
import { addOrEditEntity } from "../../../api/go/useAddOrEditEntity";
import { checkEntityNameUnique } from "../../../api/go/useCheckEntityNameUnique";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import MainStore from "MainStore";

class Store {
  stepperValue = 0;
  name = "";
  signInMethod = "email_and_password";
  errorname = "";
  file = null;
  errorfile = "";
  logo_url = "";
  delayTimer: NodeJS.Timeout | null = null;
  disabledButton = false

  options = [
    {
      id: 1,
      name: "Via email and password",
      image: null,
      code: "email_and_password",
    },
    { id: 2, name: "Login with", image: "test", code: "microsoft" },
  ];

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.name,
      (newName) => {
        this.delayedCheckNameUniqueness(newName);
        this.disabledButton = false;
      },
      { delay: 500 }
    );
    reaction(
      () => this.name,
      () => {
        this.disabledButton = true;
      }
    )
  }

  changeStepperValue(value: number) {
    this.stepperValue = value;
  }

  clearStore() {
    this.signInMethod = "email_and_password";
    this.name = "";
    this.errorname = "";
    this.logo_url = ""
    this.stepperValue = 0;
    this.file = null;
    this.errorfile = ""
    this.disabledButton = false
  }

  private delayedCheckNameUniqueness(newName: string) {
    checkEntityNameUnique(newName, StoreLayout.currentWorkspaceId, 0)
      .then(x => {
        if (x?.data !== undefined) {
          if (x.data.is_exists) {
            this.errorname = "There is an existing entity with the same name."
          } else {
            this.errorname = ""
          }
        }
      })
  }

  async saveEntity(props: any) {
    if (this.stepperValue == 0) {

      if (this.name === "") {
        return this.errorname = "Field is required"
      }
      this.changeStepperValue(store.stepperValue + 1);

    } else if (this.stepperValue === 0) {
      this.changeStepperValue(store.stepperValue + 1);
    } else if (this.stepperValue === 1) {

      var entity = {
        id: 0,
        name: this.name,
        workspace_id: StoreLayout.currentWorkspaceId,
        file: this.file,
        logo_url: this.logo_url
      };
      MainStore.changeLoader(true)
      addOrEditEntity(entity).then((x) => {
        this.clearStore();
        props.onSaved();
        MainStore.changeLoader(false)
        StoreLayout.doLoad()

      });
    } else {
      this.changeStepperValue(store.stepperValue + 1);
    }
  }

  doLoad(id: number) { }

  changeSignInMethod(value: string) {
    this.signInMethod = value;
  }
  changeName(value: string) {
    this.name = value;
    if (value === "") {
      this.errorname = "Field is required"
    } else {
      if (this.errorname !== "") {
        this.errorname = ""
      }
    }

    if (this.delayTimer) {
      clearTimeout(this.delayTimer);
    }
  }
  changeFile(value: any) {
    if (value == null) {
      if (this.errorfile !== "") {
        this.errorfile = "";
      }
      this.file = null;
      this.logo_url = ""
      return;
    }
    if (value.size > 1000000) {
      this.errorfile = "Image not uploaded as it is larger than 1MB.";
    } else {
      this.file = value;
      if (this.errorfile !== "") {
        this.errorfile = "";
      }
    }
  }
}

const store = new Store();
export default store;
