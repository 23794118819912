import http from "api/https";

export const GetPreviewTemplate = async (idTemplate: number, idLanguage: number, idReminder: number, is_report: boolean): Promise<any> => {
  idLanguage = idLanguage === null ? 0 : idLanguage;
  idReminder = idReminder === null ? 0 : idReminder;
  if(http.isAPIGO){
    return http.getGO("/template/get_preview_email?idTemplate=" + idTemplate + "&idLanguage=" + idLanguage + "&idReminder=" + idReminder + "&is_report=" + is_report);
  } else {
    return http.get("/templ_template_comms/GetPreviewEmail?idTemplate=" + idTemplate + "&idLanguage=" + idLanguage + "&idReminder=" + idReminder + "&is_report=" + is_report);
  }
};
