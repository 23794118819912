import { FC, useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";

import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, Box, Divider, IconButton, InputAdornment, TextField } from "@mui/material";
import { Question } from "types/templates";
import store from "./storeSurvey";
import CustomButton from "components/button/Button";
import LeftAccordions from "./LeftAccordionsPopup";
import CommunicationTableTable from "./PageGridPopup";
import QuestionTable from "./questionPage";
import TemplateQuestions from "./templateQuestions";
import { newQuestions } from "types/surveys";
import ERTemplateItem from "./ERTemplateItem";

type SelectTemplateProps = {
  project_id: number;
  addedQuestionJsons?: Question[];
  templateType?: string;
  fromTemplate?: boolean;
  MyOwnQuestionsClicked: () => void;
  onQuestionsAdded: (questions: newQuestions[], commTemplateId: number) => void;
};

const CardGrid: FC<SelectTemplateProps> = observer((props) => {
  useEffect(() => {
    store.loadtypeQuestioonary();
    store.loadDimensionQuestions();
    store.changeValue("templateType", props.templateType);
    if (props.templateType === "communication") {
      store.onClickBtn("entity", "all");
    } else {
      store.getTemplatesFilter();
    }

    if (props.fromTemplate) {
      store.changeValue("fromTemplate", true);
    }
  }, []);

  useEffect(() => {
    store.setProjectId(props.project_id);
  }, [props.project_id]);

  useEffect(() => {
    if (props.addedQuestionJsons) {
      store.setAddedQuestionJsons(props.addedQuestionJsons);
    }
  }, [props.addedQuestionJsons]);

  return (
    <Root>
      <LeftAccordion>
        <LeftAccordions />
      </LeftAccordion>

      <MainContent>
        {store.navUrl === "question" ? (
          <QuestionTable item={store.questionsData} onQuestionsAdded={props.onQuestionsAdded} />
        ) : store.isQuestionFromTemplateForm ? (
          <TemplateQuestions
            item={store.templateQuestions}
            onQuestionsAdded={props.onQuestionsAdded}
          />
        ) : (
          <>
            <ContainerHeader>
              <MainWrapper>
                <Header id={"Template_SelectPopup_Header_Label"}>
                  {store.templateType === "communication"
                    ? "You may choose one communications template to start with."
                    : store.navUrl === "engageRocket" || store.navUrl === ""
                      ? "Get started by selecting questions from a template that best fits your objectives"
                      : "You may choose up to one survey template and one communications template to start with \n"}
                </Header>
              </MainWrapper>
              {store.navUrl !== "engageRocket" && (
                <TemplatesSelected id={"Template_Select_Templates_Selected_Block"}>
                  {store.templateType !== "communication" && (
                    <CommSelected id={"Template_Select_Survey_Selected"}>
                      Survey selected:{" "}
                      <span id={"Template_Select_Survey_Selected_Count"}>
                        {store.selectedQuestionnaireId === null ? 0 : 1}
                      </span>{" "}
                      of 1
                    </CommSelected>
                  )}

                  {store.templateType !== "communication" && <CommSelected>|</CommSelected>}

                  <CommSelected id={"Template_Select_CommsSelected"}>
                    Comms selected:{" "}
                    <span id={"Template_Select_CommsSelected_Count"}>
                      {store.selectedCommunication === null ? 0 : 1}
                    </span>{" "}
                    of 1
                  </CommSelected>
                </TemplatesSelected>
              )}
              <SecondaryWrapper>
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="flex-start">
                    {(store.navUrl === "engageRocket" || store.navUrl === "") && (
                      <StyledAutocomplete
                        id={"Template_Select_Types_Select"}
                        options={store.typesQuestionary}
                        renderInput={(params) => (
                          <StyledTextField
                            {...params}
                            placeholder="All templates"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <StyledFilterListIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                        value={store.selectedType}
                        getOptionLabel={(option: any) => option.name}
                        onChange={(e, value: any) => {
                          store.changeTemplateType(value);
                        }}
                        popupIcon={<KeyboardArrowDownIcon />}
                        isOptionEqualToValue={(option, value) => option === value}
                      />
                    )}

                    {store.navUrl !== "engageRocket" && store.navUrl !== "question" && (
                      <TextField
                        size="small"
                        id={"Template_Select_Search_TemplateInput"}
                        placeholder="Search for template name"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                id="Template_Select_Search_TemplateInput_Button"
                                onClick={() => {
                                  store.onSearchClicked();
                                }}
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        value={store.searchedValue}
                        onKeyDown={store.keyPress}
                        onChange={(e) => store.changeValue("searchedValue", e.target.value)}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" gap={2} justifyContent="space-between">
                    <CustomButton
                      variant="outlined"
                      id={
                        store.templateType === "communication"
                          ? "Template_Select_IHaveMyOwnComms_Button"
                          : "Template_Select_IHaveMyOwnQustions_Button"
                      }
                      onClick={() => {
                        props.MyOwnQuestionsClicked();
                      }}
                    >
                      {store.templateType === "communication"
                        ? "I have my own comms"
                        : "I have my own questions"}
                    </CustomButton>
                    {store.navUrl !== "engageRocket" && (
                      <CustomButton
                        variant="contained"
                        id={"Template_Select_UseCheckedTemplates_Button"}
                        disabled={
                          store.selectedQuestionnaireId === null &&
                          store.selectedCommunication === null
                        }
                        onClick={() => {
                          if (store.selectedQuestionnaireId) {
                            store.getQuestionsFromTemplateId();
                          } else {
                            props.onQuestionsAdded([], store.selectedCommunication);
                          }
                        }}
                      >
                        Use checked templates
                      </CustomButton>
                    )}
                  </Box>
                </Grid>
              </SecondaryWrapper>
            </ContainerHeader>
            <Divider />

            {store.navUrl === "engageRocket" && (
              <Container id={"Template_Select_EngageRocket_Templates"}>
                {store.templatesData.map((template, i) => (
                  <ERTemplateItem template={template} index={i} key={template.id} />
                ))}
              </Container>
            )}

            {store.navUrl !== "engageRocket" && (
              <GridWrapper id={"Template_Select_Other_Templates"}>
                <CommunicationTableTable list={store.templatesData} />
              </GridWrapper>
            )}
          </>
        )}
      </MainContent>
    </Root>
  );
});

export default CardGrid;

const MainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContainerHeader = styled.div`
  padding: 20px 30px 10px 30px;
`;

const StyledAutocomplete = styled(Autocomplete)`
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;

  input {
    width: 300px !important;
    border-radius: 2px !important;
    padding: 0 !important;
    font-family: Roboto, sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .MuiOutlinedInput-root {
    border-radius: 2px !important;
  }
`;

const StyledTextField = styled(TextField)`
  ::placeholder {
    color: var(--colorNeutralForeground5);
    font-weight: 400 !important;
    font-family: Roboto, sans-serif !important;
  }
`;

const StyledFilterListIcon = styled(FilterListIcon)`
  color: var(--colorBrandForeground1) !important;
  width: 20px !important;
  margin-right: 6px;
`;

const SecondaryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  padding-bottom: 24px;
  height: 50px;
`;

const TemplatesSelected = styled.div`
  color: var(--ER-Theme-sys-light-on-tertiary-fixed-variant, #959ba2);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  text-transform: uppercase;
`;
const CommSelected = styled.span`
  margin-right: 15px;
`;

const Header = styled.h1`
  text-align: left;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #707882;
`;

const GridWrapper = styled.div`
  padding: 20px 30px;
  background-color: var(--colorNeutralForeground8);
  overflow-y: scroll;
  height: 555px;
  padding: 20px 30px;

  &::-webkit-scrollbar {
    width: 8px !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: var(--colorGrayForeground6);
  }
`;

const MainContent = styled.div`
  width: 100%;
  padding: 0 !important;
`;

const LeftAccordion = styled.div`
  min-width: 247px;
`;

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
  background-color: var(--colorNeutralForeground8);
  overflow-y: scroll;
  height: 555px;
  padding: 20px 30px;

  &::-webkit-scrollbar {
    width: 8px !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: var(--colorGrayForeground6);
  }

  @media (max-width: 1440px) {
    grid-template-columns: 1fr 1fr;
  }
`;
