import { FC } from "react";
import  Communications  from "../../features/Survey/Communication/Communications";

type SurveyPageProps = {};

const SurveyPagePage: FC<SurveyPageProps> = () => {
  return <Communications />;
};

export default SurveyPagePage;
