import React, { FC, ReactElement } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { observer } from "mobx-react";

import CustomButton from "components/button/Button";
import Dialog from "../../../components/modal/Modal";
import { createUrl } from "helpers/url";
import store from "./store";

type AddTemplateNameProps = {
  openPanel: boolean;
  onBtnCancelClick: () => void;
  onSaved: (id: number) => void;
  onClose: () => void;
};

const AddTemplate: FC<AddTemplateNameProps> = observer((props): ReactElement => {
  const navigate = useNavigate();

  const TYPE_TEMPLATE = [
    {
      active: store.type_template === "questionnaire",
      click: () => store.changeTemplateType("questionnaire"),
      title: "Questionnaire",
      description: "Survey questions and settings.",
      id: "Template_CreateEdit_PopUp_QuestionnaireRadio",
    },
    {
      active: store.type_template === "communication",
      click: () => store.changeTemplateType("communication"),
      title: "Comms",
      description: "Communication reminder emails.",
      id: "Template_CreateEdit_PopUp_CommunicationRadio",
    },
  ];

  // const nav = (path: string) => {
  //   navigate(
  //     createUrl({
  //       path: `/${path}-template`,
  //       query: {
  //         id: String(0),
  //       },
  //     })
  //   );
  // };

  const navigateHandler = (): void => {
    if (store.type_template === "communication") {
      window.location.href = "/communication-template?id=0";
    } else {
      window.location.href = "/questionnaire-template?id=0";
    }
    props.onClose();
    store.clearStore();
  };

  const closeHandler = (): void => {
    props.onClose();
    store.clearStore();
  };

  return (
    <Dialog open={props.openPanel} hide={closeHandler} aria-label="Create template" width="522px">
      <WrapperRoot>
        <Header id="Template_CreateEdit_PopUp_HederText">Create a new template</Header>

        <RadioWrapper>
          {TYPE_TEMPLATE.map((item, index) => (
            <StyledBoxTypeTemplate
              key={index}
              id={`${item.id}_Container`}
              $active={item.active}
              onClick={item.click}
            >
              <StyledContainerTitle id={`${item.id}_Label`}>
                <TextMain id={`${item.id}_LabelText`}>{item.title}</TextMain>
                <TextSecondary id={`${item.id}_Text`}>{item.description}</TextSecondary>
              </StyledContainerTitle>
              <Checked id={`${item.id}_Input`} $active={item.active}>
                {item.active && <DoneIcon />}
              </Checked>
            </StyledBoxTypeTemplate>
          ))}
        </RadioWrapper>

        <ButtonsWrapper>
          <CustomButton
            id="Template_CreateEdit_PopUp_StartCreatingTemplate_Btn"
            disabled={store.type_template === ""}
            onClick={navigateHandler}
            variant="contained"
          >
            Start creating template
          </CustomButton>
          <CustomButton
            id="Template_CreateEdit_PopUp_Cansel_Btn"
            onClick={closeHandler}
            variant="outlined"
            color="inherit"
          >
            Cancel
          </CustomButton>
        </ButtonsWrapper>
      </WrapperRoot>
    </Dialog>
  );
});

export default AddTemplate;

const RadioWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  width: 100%;
`;

const StyledBoxTypeTemplate = styled.button<{ $active: boolean }>`
  width: 187px;
  height: 131px;
  border: 1px solid
    ${(props) =>
      props.$active ? "var(--colorBrandForeground1)" : "var(--colorNeutralBackground3)"};
  border-radius: 5px;
  background-color: var(--colorNeutralBackground1);
  cursor: pointer;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const StyledContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  text-align: left;
`;

const Checked = styled.span<{ $active?: boolean }>`
  width: 28px;
  min-width: 28px;
  margin-right: -9px;
  height: 28px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.$active ? "var(--colorBrandForeground1)" : "var(--colorNeutralBackground3)"};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 20px;
    color: var(--colorNeutralBackground1);
  }
`;

const TextMain = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const TextSecondary = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const WrapperRoot = styled.div`
  padding: 40px;
`;

const Header = styled.h1`
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px 0 60px 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
  gap: 20px;
`;
