import { makeAutoObservable } from "mobx";

class Store {
  type_template: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  changeValue = (name: string, value: string) => {
    this[name] = value;
  };

  changeTemplateType = (type: string) => {
    this.type_template = type;
  };

  clearStore() {
    this.type_template = "";
  }
}

const store = new Store();
export default store;
