import http from "api/https";

export const UpdateSurveySettings = async (id: number, tagNames: string[]): Promise<any> => {
  if (http.isAPIGO) {
    return http.postGO("/sm/sm_survey/CustomAddOrUpdateSurveyWithTags", {
      id: id,
      tagNames: tagNames,
    });
  } else {
    return http.post("/sm_survey/CustomAddOrUpdateSurveyWithTags", {
      id: id,
      tagNames: tagNames,
    });
  }
};
