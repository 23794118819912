
import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  .path-background {
  }
  cursor: pointer;
`;

export const UpperIcon = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <StyledSvg onClick={(e) => props.onClick(e)} xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <circle cx="12" cy="12.832" r="11.5" fill="#F9FAFC" stroke="#707882" />
    <path d="M7 14.832L12 9.83203L17 14.832" stroke="#707882" />
  </StyledSvg>
);
