import LoginScreen from "features/Auth/LoginScreen";
import { FC } from "react";

type LoginPageProps = {};

const LoginPage: FC<LoginPageProps> = () => {
  return <LoginScreen />
};

export default LoginPage;
