import { ReactElement, useEffect, useMemo, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Dialog, IconButton } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import styled from "styled-components";
import { escape } from "lodash";

import CustomButton from "components/button/Button";
import { EmployeeAttribute } from "../../sharedTypes";
import PopoverAutocomplete from "../../PopoverAutocomplete";
import deconstructEmployeeSegmentToken, {
  deconstructEmployeeAttributeSegments,
} from "./deconstructEmployeeSegmentToken";

type SetupLogicModalProps = {
  isVisible: boolean;
  onDismiss: () => void;
  value: string;
  onApplyLogic: (logic: string) => void;
  headerTitle: string;
  employeeAttributes: EmployeeAttribute[];
};

type AttributeSuggestorState = {
  anchorEl: HTMLElement | null;
  selectedOption: string | undefined;
  searchInput: string | undefined;
  index: number | null;
  condition?: "and" | "or" | null;
  disabled?: boolean;
  segments?: string[];
};

type AttributeWithSegment = {
  attribute: AttributeSuggestorState;
  segment: AttributeSuggestorState;
};

type VariantsButton = {
  $isActive?: boolean;
  $isSegment?: boolean;
  $condition?: boolean;
  $filled?: boolean;
  $disabled?: boolean;
  $isAttribute?: boolean;
};

export function constructLogicText(logics: AttributeWithSegment[]): string {
  let res = "";

  for (let i = 0; i < logics.length; i++) {
    const escapedAttributeName = escape(logics[i].attribute.selectedOption);
    const escapedAttributeValue = escape(logics[i].segment.selectedOption);
    if (i === 0) {
      res += `{${escapedAttributeName}} = '${escapedAttributeValue}'`;
    } else {
      const escapedCondition = escape(logics[i].attribute.condition);
      res += ` ${escapedCondition} {${escapedAttributeName}} = '${escapedAttributeValue}'`;
    }
  }

  return res;
}

const SetupLogicModal = (props: SetupLogicModalProps): ReactElement => {
  const [logics, setLogic] = useState<AttributeWithSegment[]>([]);

  const [andOr, setAndOr] = useState<AttributeSuggestorState>({
    anchorEl: null,
    selectedOption: "",
    searchInput: "",
    index: 0,
  });

  const [equalsState, setEqualsState] = useState<AttributeSuggestorState>({
    anchorEl: null,
    selectedOption: "",
    searchInput: "",
    index: 0,
  });

  const [selectSegmentState, setSelectSegmentState] = useState<AttributeSuggestorState>({
    anchorEl: null,
    selectedOption: "",
    searchInput: "",
    index: 0,
    disabled: false,
  });

  useEffect(() => {
    const { attributeName, attributeValue } = deconstructEmployeeSegmentToken(props.value);
    const attributesWithSegments = deconstructEmployeeAttributeSegments(props.value);

    let res: AttributeWithSegment[] = [];

    for (let index = 0; index < attributesWithSegments.length; index++) {
      res.push({
        attribute: {
          anchorEl: null,
          selectedOption: attributesWithSegments[index].attributeName,
          searchInput: "",
          index: index,
          condition: attributesWithSegments[index].condition,
        },
        segment: {
          anchorEl: null,
          selectedOption: attributesWithSegments[index].attributeValue,
          searchInput: "",
          index: index,
        },
      });
    }

    setLogic(res);
    // setEqualsState(oldEqualsState => ({
    //   ...oldEqualsState,
    //   selectedOption: attributeName,
    // }))

    // setSelectSegmentState(oldSelectSegmentState => ({
    //   ...oldSelectSegmentState,
    //   selectedOption: attributeValue,
    // }))
  }, [props.value]);

  const attributeOptions = useMemo(
    () =>
      props.employeeAttributes.map((employeeAttribute) => ({
        label: employeeAttribute.name,
        value: employeeAttribute.name,
      })),
    [props.employeeAttributes],
  );

  const attributeValueOptions = useMemo(() => {
    const selectedAttribute = props.employeeAttributes.find(
      (employeeAttribute) =>
        employeeAttribute.name === logics[selectSegmentState.index]?.attribute.selectedOption,
    );
    return selectedAttribute
      ? selectedAttribute.values.map((value) => ({
          label: value,
          value,
        }))
      : [];
  }, [props.employeeAttributes, selectSegmentState]);

  const handleClickOk = (): void => {
    const token = constructLogicText(logics);

    props.onApplyLogic(token);
    // if (equalsState.selectedOption && selectSegmentState.selectedOption) {
    //   const token = buildEmployeeSegmentToken({
    //     type: "contains",
    //     args: {
    //       attributeName: equalsState.selectedOption,
    //       attributeValue: selectSegmentState.selectedOption,
    //     },
    //   })

    // }
  };

  const clearAnchorHandler = () => {
    setAndOr({
      anchorEl: null,
      selectedOption: "",
      searchInput: "",
      index: null,
      condition: "and",
    });
  };

  const selectConditionHandler = (type: "and" | "or") => {
    const updatedLogics = [...logics];
    updatedLogics[andOr.index] = {
      ...updatedLogics[andOr.index],
      attribute: {
        ...updatedLogics[andOr.index].attribute,
        condition: type,
      },
    };
    setLogic(updatedLogics);
    clearAnchorHandler();
  };

  const selectAttributeOptionHandler = (value: string) => {
    const selectedAttribute = props.employeeAttributes.find((attr) => attr.name === value);
    const segmentOptions = selectedAttribute
      ? selectedAttribute.values.map((value) => ({
          label: value,
          value,
        }))
      : [];

    const updatedLogics = [...logics];
    const newState = { ...updatedLogics[equalsState.index] };
    newState.attribute = {
      ...newState.attribute,
      selectedOption: value,
    };

    newState.segment = {
      ...newState.segment,
      selectedOption: "",
      disabled: segmentOptions.length === 0,
    };

    updatedLogics[equalsState.index] = newState;
    setLogic(updatedLogics);
    setEqualsState({
      anchorEl: null,
      selectedOption: value,
      searchInput: "",
      index: null,
      condition: "and",
    });
    setSelectSegmentState({
      anchorEl: null,
      selectedOption: "",
      searchInput: "",
      index: null,
      condition: "and",
    });
  };

  const selectSegmentOptionHandler = (value: string) => {
    const updatedLogics = [...logics];
    const newState = { ...updatedLogics[selectSegmentState.index] };
    newState.segment = {
      ...newState.segment,
      selectedOption: value,
    };
    updatedLogics[selectSegmentState.index] = newState;
    setLogic(updatedLogics);
    setSelectSegmentState({
      anchorEl: null,
      selectedOption: "",
      searchInput: "",
      index: null,
      condition: "and",
    });
  };

  const deleteHandler = (index: number) => {
    if (index === 0 && logics?.length === 1) {
      setLogic([
        {
          attribute: {
            anchorEl: null,
            selectedOption: "",
            searchInput: "",
            index: 0,
            condition: "and",
          },
          segment: {
            anchorEl: null,
            selectedOption: "",
            searchInput: "",
            index: 0,
          },
        },
      ]);
    } else {
      const array = [...logics]; // make a separate copy of the array
      array.splice(index, 1);
      setLogic(array);
    }
  };

  const getOptionSegment = (segment: AttributeSuggestorState) => {
    if (!segment.selectedOption && !segment.disabled) {
      return "Select segment...";
    }
    if (segment.disabled) {
      return "No segments available";
    }
    return segment.selectedOption;
  };

  return (
    <StyledModal
      aria-labelledby="setupLogicModalTitle"
      open={props.isVisible}
      fullWidth
      maxWidth="sm"
      onClose={props.onDismiss}
    >
      <StyledCloseContainer>
        <StyledCloseIcon onClick={props.onDismiss}>
          <ClearIcon />
        </StyledCloseIcon>
      </StyledCloseContainer>

      <StyledModalTitle id="setupLogicModalTitle">Visible if</StyledModalTitle>

      {logics.map((logic: AttributeWithSegment, index: number) => {
        return (
          <ConditionContainer key={index}>
            {index === 0 ? (
              <span>If</span>
            ) : (
              <Pill
                $isActive
                $condition
                onClick={(event): void => {
                  setAndOr({
                    anchorEl: event.currentTarget,
                    selectedOption: "",
                    index: index,
                    searchInput: "",
                  });
                }}
              >
                {logic.attribute.condition}
              </Pill>
            )}
            <StyledMenu
              id="basic-menu"
              anchorEl={andOr.anchorEl}
              open={Boolean(andOr.anchorEl)}
              onClose={clearAnchorHandler}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => selectConditionHandler("and")}>and</MenuItem>
              <MenuItem onClick={() => selectConditionHandler("or")}>or</MenuItem>
            </StyledMenu>

            <Pill
              $isActive
              $isAttribute
              onClick={(event): void =>
                setEqualsState({
                  ...selectSegmentState,
                  anchorEl: event.currentTarget,
                  searchInput: "",
                  index: index,
                })
              }
            >
              {logic.attribute.selectedOption || "Select attribute..."}
            </Pill>

            <Pill
              $isSegment
              $filled={!logic.segment.selectedOption}
              disabled={logic.segment.disabled}
              $disabled={logic.segment.disabled}
              onClick={(event): void => {
                setSelectSegmentState({
                  ...selectSegmentState,
                  anchorEl: event.currentTarget,
                  searchInput: "",
                  index: index,
                });
              }}
            >
              {getOptionSegment(logic.segment)}
            </Pill>

            <IconButton className="delete-button" onClick={() => deleteHandler(index)}>
              <DeleteOutlineIcon color="error" />
            </IconButton>

            <PopoverAutocomplete
              popoverProps={{
                ariaLabel: "Equals",
                style: { marginTop: 45 },
                anchorEl: equalsState.anchorEl,
                open: Boolean(equalsState.anchorEl),
                onClose: (): void =>
                  setEqualsState({
                    ...equalsState,
                    anchorEl: null,
                    searchInput: "",
                  }),
              }}
              autocompleteProps={{
                onSelectOption: (value) => selectAttributeOptionHandler(value),
                options: attributeOptions,
              }}
            />

            <PopoverAutocomplete
              popoverProps={{
                ariaLabel: "Select segment",
                style: { marginTop: 45 },
                anchorEl: selectSegmentState.anchorEl,
                open: Boolean(selectSegmentState.anchorEl),
                onClose: (): void =>
                  setSelectSegmentState({
                    ...selectSegmentState,
                    anchorEl: null,
                    searchInput: "",
                  }),
              }}
              autocompleteProps={{
                onSelectOption: (value) => selectSegmentOptionHandler(value),
                options: attributeValueOptions,
              }}
            />
          </ConditionContainer>
        );
      })}

      <AddCondition>
        <Pill
          $isActive
          $condition
          onClick={(): void => {
            setLogic([
              ...logics,
              {
                attribute: {
                  anchorEl: null,
                  selectedOption: undefined,
                  searchInput: "",
                  index: logics.length,
                  condition: "and",
                },
                segment: {
                  anchorEl: null,
                  selectedOption: undefined,
                  searchInput: "",
                  index: logics.length,
                  condition: "and",
                },
              },
            ]);
          }}
        >
          Add condition
        </Pill>
      </AddCondition>

      <ModalFooter>
        <CustomButton
          variant="contained"
          disabled={logics.some((el) => !el.attribute.selectedOption || !el.segment.selectedOption)}
          onClick={handleClickOk}
        >
          Apply
        </CustomButton>
        <CustomButton variant="outlined" onClick={props.onDismiss}>
          Cancel
        </CustomButton>
      </ModalFooter>
    </StyledModal>
  );
};

export default SetupLogicModal;

const getColor = ({
  $isActive,
  $isSegment,
  $condition,
  $filled,
  $disabled,
}: VariantsButton): string => {
  if ($isActive) {
    if ($isSegment) {
      return "var(--colorNeutralForeground14)";
    }

    if ($condition) {
      return "var(--colorNeutralForeground4)";
    }

    return "var(--colorBrandForeground2)";
  }

  if ($disabled) {
    return "var(--colorNeutralForeground4)";
  }

  if ($isSegment) {
    return $filled ? "var(-colorNeutralForeground4)" : "var(--colorNeutralForeground14)";
  }

  return "var(--colorBrandForeground2)";
};

const getColorHover = ({ $isAttribute }: VariantsButton): string => {
  if ($isAttribute) {
    return "var(--colorNeutralForeground13)";
  }
  return "var(--colorNeutralForeground12)";
};

const StyledModal = styled(Dialog)`
  .MuiPaper-root {
    padding: 30px;
  }
`;

const StyledModalTitle = styled.div`
  margin-top: -10px;
  padding: 0 0 calc(2 * var(--sjs-base-unit, var(--base-unit, 8px)));
  color: var(--colorNeutralForeground1);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;

const StyledCloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledCloseIcon = styled(IconButton)`
  width: 40px !important;
`;

const ConditionContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 20px;

  .delete-button {
    opacity: 0;
    transition: opacity 0.1s ease;
  }

  &:hover .delete-button {
    opacity: 1;
  }
`;

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    box-shadow: 0 2px 5px 0 rgba(16, 20, 31, 0.16) !important;
  }
`;

const AddCondition = styled.div`
  margin-bottom: 50px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: start;
  gap: 20px;

  :first-child {
    width: 130px;
  }

  > :last-child {
    font-family: cursive;
    color: var(--colorNeutralForeground2);
    border: 1px solid var(--colorNeutralForeground5);

    &:hover {
      border: 1px solid var(--colorNeutralForeground5);
    }
  }
`;

const Pill = styled.button<{
  $isActive?: boolean;
  $isSegment?: boolean;
  $filled?: boolean;
  $condition?: boolean;
  $disabled?: boolean;
  $isAttribute?: boolean;
}>`
  cursor: pointer;
  border: none;
  border-radius: 24px;
  color: var(--colorNeutralForeground3);
  padding: 12px 24px;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 400;

  &:enabled {
    background-color: ${(props): string => getColor(props)};
    color: var(--colorNeutralForeground1);
    &:hover {
      background-color: ${(props): string => getColorHover(props)};
    }
  }

  &:disabled {
    background-color: var(--colorNeutralForeground4);
    color: var(--colorNeutralForeground5);
    cursor: not-allowed;
  }
`;
