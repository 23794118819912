import { makeAutoObservable } from "mobx";
import { getEntitiesByWorkspace } from "../../api/go/useGetEntitiesByWorkspace";
import { getWorkspaceSetting } from "../../api/go/useGetWorkspaceSetting";
import MainStore from "MainStore";
import { TEntity, TWorkspaceSetting } from 'types/workspace'
import StoreLayout from "components/workspaces-sidebar/StoreLayout";

class Store {
  openPanelCreateEntity = false;
  openPanelAddEditEntity = false;
  currentEntityId = 0;
  workspaceSetting: TWorkspaceSetting = null;
  data: TEntity[] = []

  constructor() {
    makeAutoObservable(this);
  }

  setData(data: TEntity[]) {
    this.data = data
  }
  
  setWorkspaceSetting(setting: TWorkspaceSetting) {
    this.workspaceSetting = setting
  }

  changeOpenPanelCreateEntity(flag: boolean) {
    this.openPanelCreateEntity = flag
  }
  changeOpenPanelAddEditEntity(flag: boolean) {
    this.openPanelAddEditEntity = flag
  }

  onClickEmployeeManagement = (idEntity: number, idWorkspace: number) => {
    const workspace = StoreLayout.data.flatMap(x => x.workspaces).find(x => x.id === idWorkspace)
    const entity = workspace.entities.find(x => x.id == idEntity)

    StoreLayout.selectEntity(entity, workspace)
  }
  
  async loadWorkspaceSettings(idWorkspace: number) {
    try {
      MainStore.changeLoader(true);
      const response = await getWorkspaceSetting(idWorkspace)
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.setWorkspaceSetting(response.data)
      } else throw Error();
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error")
    } finally {
      MainStore.changeLoader(false);
    }
  }

  async loadEntities(id: number) {
    try {
      MainStore.changeLoader(true);
      const response = await getEntitiesByWorkspace(id)
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.setData(response.data)
      } else throw Error();
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error")
    } finally {
      MainStore.changeLoader(false);
    }
  }

}

const store = new Store();
export default store;
