import http from "api/https";

export const deleteEmployees = async (ids: readonly number[]): Promise<any> => {
  try {
    if (http.isAPIGO) {
      const response = await http.postGO("/employee/DeleteRange", { ids: ids });
      if ([200, 201].includes(response.status)) {
        return response.data;
      }
      throw new Error(`Failed to fetch count responce (HTTP status code: ${response.status})`);
    } else {
      const response = await http.post("/employees/DeleteRange", ids);
      if ([200, 201].includes(response.status)) {
        return response.data;
      }
      throw new Error(`Failed to fetch count responce (HTTP status code: ${response.status})`);
    }
  } catch (error) {
    console.error(error);
  }
};
