import { FC, useState } from "react";
import { observer } from "mobx-react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import FirstTab from "./FirstTab/FirstTab";
import styled from "styled-components";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ width: 900 }}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

type SendReportProps = {};

const SendReport: FC<SendReportProps> = observer((props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <MainWrapper>
      <StyledTabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
      >
        <StyledTab label="Email" {...a11yProps(0)} />
      </StyledTabs>
      <TabPanel value={value} index={0}>
        <FirstTab />
      </TabPanel>
    </MainWrapper>
  );
});

export default SendReport;

const MainWrapper = styled.div`
  flex-grow: 1;
  display: flex;
`;

const StyledTabs = styled(Tabs)`
  background-color: var(--colorNeutralForeground8);
  min-width: 245px;
  padding-top: 20px;
  margin: 0 0 -16px -16px;
  border-radius: 0 0 0 9px !important;
  .MuiTabs-indicator {
    left: 0;
    width: 6px;
    background-color: var(--colorPaletteVioletBackground2);
  }
`;

const StyledTab = styled(Tab)`
  text-transform: none !important;
  color: var(--colorNeutralForeground2);
  align-items: baseline !important;
  padding-left: 28px !important;
  &.MuiTab-root.Mui-selected {
    color: var(--colorNeutralForeground2);
  }
`;
