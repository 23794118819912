import * as React from "react";
import styled from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "components/pagination/Pagination";
import { observer } from "mobx-react";
import CustomButton from "components/button/Button";
import { Typography } from "@mui/material";
import MultiSelectTags from "components/multiselect/MultiSelectTags";
import MultiSelectStrings from "components/multiselect/MultiSelectStrings";
import SortBlock from "components/sortBlock/SortBlock";
import store from "../store";

type CustomTableProps = {
  list: { [key: string]: any }[] | [];
  data: ITableData;
  countPerPage?: number;
  sort: string | null;
  hideAddButton: boolean;
  sortType: string | null;
  changeSort: (field: string, type: string) => void;
};

const CustomTable: React.FC<CustomTableProps> = observer((props) => {
  const [countPerPage, setCountPerPage] = React.useState(
    props.countPerPage ? props.countPerPage : 10,
  );
  const [activePage, setActivePage] = React.useState(1);
  const [hoverIndex, setHoverIndex] = React.useState(null);
  const changeCurentCount = (num: number) => {
    setCountPerPage(num);
    setActivePage(1);
    store.onSearchClicked();
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" id={"Templates_Table"}>
          <TableHead sx={{ bgcolor: "#E4E9FF" }} id={"Templates_Table_Head"}>
            <TableRow>
              {props.data.headers.map((header: TableHeader) => (
                <TableCell key={header.value}>
                  <HeaderWrapper $search={false} style={{ minHeight: "30px" }}>
                    <Row $search={false}>
                      <Title id={`Templates_Table_RowHead_${header.value}`}>{header.title}</Title>
                      {header.value === "tag_names" && (
                        <>
                          <MultiSelectTags
                            where="Template_Table_Tags"
                            list={store.tags}
                            selected_list={store.selected_tags_ids}
                            onChange={(id: number) => store.changeTag(id)}
                          />
                        </>
                      )}
                      {header.value === "type_name" && (
                        <>
                          <MultiSelectStrings
                            where="Template_Table_Type"
                            list={store.types}
                            selected_list={store.selected_types}
                            onChange={(type: string) => store.changeTypes(type)}
                          />
                        </>
                      )}
                      {header.sort && (
                        <SortBlock
                          value={header.value}
                          field={store.sortField}
                          type={store.sortType}
                          onChange={store.changeSort}
                        />
                      )}
                    </Row>
                  </HeaderWrapper>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.list !== null && props.list.length > 0 && (
              <>
                {props.list
                  .slice(
                    (activePage - 1) * countPerPage,
                    (activePage - 1) * countPerPage + countPerPage,
                  )
                  .map((row: { [key: string]: any }, index) => {
                    return (
                      <TableRow
                        onMouseEnter={() => setHoverIndex(index)}
                        onMouseLeave={() => setHoverIndex(null)}
                        key={row.id + row.type_name}
                      >
                        {props.data.headers.map((el) => (
                          <TableCell component="th" scope="row" key={el.value}>
                            {el.renderRow ? (
                              el.renderRow(row, index === hoverIndex)
                            ) : (
                              <TableText>{row[el.value]}</TableText>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {props.list.length <= 0 && !store.resultSearchValue && (
        <StyledDiv>
          <TypographyStyled id={"Templates_Table_NoTemplatesShared"}>
            There are currently no templates. Start by creating one.
          </TypographyStyled>
          {!props.hideAddButton && (
            <CustomButtonStyled
              id={"Templates_Table_CreateTemplate_Button"}
              variant="contained"
              onClick={() => {
                store.changePanelAddTemplate(true);
                localStorage.setItem("templateAction", "create");
              }}
            >
              + Create new template
            </CustomButtonStyled>
          )}
        </StyledDiv>
      )}
      {props.list.length <= 0 && store.resultSearchValue && (
        <StyledDiv>
          <Typography id={"Templates_TableNoTemplates_SearchResult"}>
            No templates was found to match the provided details.
            <br />
            Please try using a different search keyword.
          </Typography>
        </StyledDiv>
      )}
      <PaginationWrapper>
        <Pagination
          activePage={activePage}
          itemsCountPerPage={countPerPage}
          id={"Templates_Table"}
          onChange={(e) => setActivePage(e)}
          pageRangeDisplayed={countPerPage}
          totalItemsCount={props.list.length}
          totalPages={props.list.length / countPerPage}
          setCount={changeCurentCount}
        />
      </PaginationWrapper>
    </>
  );
});

export interface ITableData {
  headers: TableHeader[];
}

export type TableHeader = {
  value: string;
  title: string;
  filter: boolean;
  sort: boolean;
  renderRow?: (row: any, isHovered: boolean) => any;
};

export default CustomTable;

const TableText = styled.p`
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground1);
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const IconWrapper = styled.span`
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  margin: 0 2px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  :hover {
    background-color: var(--colorPalleteLightBlue);
  }
`;

const HeaderWrapper = styled.div<{ $search: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.$search ? "column" : "row")};
  align-items: center;
  justify-content: flex-start;
`;

const Title = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground2);
  margin: 0;
`;

const Row = styled.div<{ $search: boolean }>`
  width: ${(props) => (props.$search ? "100%" : "fit-content")};
  display: flex;
  align-items: center;
`;

const StyledDiv = styled.div`
  width: 100%;
  height: 500px;
  background-color: var(--colorNeutralBackground1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const CustomButtonStyled = styled(CustomButton)`
  margin-top: 10px !important;
  font-family: Roboto, sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
`;
const TypographyStyled = styled(Typography)`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--colorNeutralForeground5) !important;
  margin-bottom: 10px;
`;
