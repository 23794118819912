import { isEmpty, unescape } from "lodash";
import { ContainsArgType } from "./buildEmployeeSegmentToken";

export function deconstructEmployeeAttributeSegments(token: string): ContainsArgType[] {
  if (isEmpty(token)) {
    return [
      {
        attributeName: "",
        attributeValue: "",
      },
    ];
  }
  // const operator = "="
  // const attrs =  splitMulti(token, [' and ', ' or '])
  // let res: ContainsArgType[] = []

  // for (let index = 0; index < attrs.length; index++) {

  //   let attributeNameSegment = attrs[index].substring(0, attrs[index].indexOf(operator)).trim()
  //   let attributeValueSegment = attrs[index].substring(attrs[index].indexOf(operator) + 1).trim()
  //   let attributeName = getAttributeName(attributeNameSegment)
  //   let attributeValue = getAttributeValue(attributeValueSegment)
  //   res.push({
  //     attributeName: unescape(attributeName),
  //     attributeValue: unescape(attributeValue),
  //   })
  // }

  // const parts = token.split(/\s(?:and|or)\s/);

  // const results: ContainsArgType[] = parts.map((part) => {
  //   const match = /\{([^}]+)\}\s*=\s*'([^']+)'/g.exec(part);
  //   return {
  //     attributeName: match[1],
  //     attributeValue: match[2],
  //     // Определение условия ('and', 'or') для каждой части строки
  //     condition: /and/i.test(part) ? "and" : /or/i.test(part) ? "or" : null,
  //   };
  // });

  const regex = /\{([^}]+)\}\s*=\s*'([^']+)'\s*(and|or)?/g;

  const result: ContainsArgType[] = [];
  let match;

  while ((match = regex.exec(token)) !== null) {
    const [, attributeName, attributeValue, condition] = match;
    const parsedItem: ContainsArgType = {
      attributeName,
      attributeValue,
      condition: condition ? (condition as "and" | "or") : null,
    };

    result.push(parsedItem);
  }
  let conditions = result.map(x => x.condition)
  let i = 0
  while(i < result.length){
    if(i === 0){
      i++
      continue
    }
    result[i].condition = conditions[i-1]
    i ++
  }

  console.log(result);

  return result;
}

export default function deconstructEmployeeSegmentToken(token: string): ContainsArgType {
  if (isEmpty(token)) {
    return {
      attributeName: "",
      attributeValue: "",
    };
  }

  const operator = "=";

  const attributeNameSegment = token.substring(0, token.indexOf(operator)).trim();
  const attributeValueSegment = token.substring(token.indexOf(operator) + 1).trim();

  const attributeName = getAttributeName(attributeNameSegment);
  const attributeValue = getAttributeValue(attributeValueSegment);

  return {
    attributeName: unescape(attributeName),
    attributeValue: unescape(attributeValue),
  };
}

function getAttributeName(rawSegment: string): string {
  const syntax = {
    start: rawSegment.indexOf("{"),
    end: rawSegment.lastIndexOf("}"),
  };

  const attributeName = rawSegment.substring(syntax.start + 1, syntax.end);

  return attributeName;
}

function getAttributeValue(rawSegment: string): string {
  const syntax = {
    start: rawSegment.indexOf("'"),
    end: rawSegment.lastIndexOf("'"),
  };

  const attributeValue = rawSegment.substring(syntax.start + 1, syntax.end);

  return attributeValue;
}

function splitMulti(str: string, tokens: string[]) {
  let tempChar = tokens[0]; // We can use the first token as a temporary join character
  for (var i = 1; i < tokens.length; i++) {
    str = str.split(tokens[i]).join(tempChar);
  }
  let str1 = str.split(tempChar);
  return str1;
}
