import http from "../https";

type TRequestBody = {
  survey_id: number;
  attributes: {
    id: number;
    name: string;
    segments: string[];
  }[];
  benchmarkType?: number;
  viewType: string;
};

export const useGetDashboardDimensions = (requestData: TRequestBody) =>
  http.post(`/Dashboard/dimensions-score`, requestData);
