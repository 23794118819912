import http from "api/https";

export const AddReminderTemplateEmail = async (idCommTemplate: number) => {
  let data = { idCommTemplate: idCommTemplate };
  if(http.isAPIGO){
    return http.postGO("/template/add_default_reminder_for_template", data);
  } else {
    return http.post("/templ_comms_reminder/AddDefaultReminderForTemplate", data);
  }
};
