import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";

import store from "./../../store";
import CustomButton from "components/button/Button";
import ReminderBlock from "./ReminderBlock";

type ThirdTabProps = {};

const ThirdTab: FC<ThirdTabProps> = observer(() => {
  return (
    <Wrapped>
      <Header id="Template_Communication_Schedule_Reminder_Header_Label">
        Reminder for all recipients
        <StyledCountScheduled> ({store.ReminderData.length} scheduled)</StyledCountScheduled>
      </Header>

      <SubHeader id="Template_Communication_Schedule_Reminder_Message_Text">
        Email will be sent to recipients that have yet to complete the survey
      </SubHeader>

      <LaunchSurvey id="Template_Communication_Schedule_Reminder_LaunchSurvey_Text">
        <span>Launch survey</span> <LaunchSecondText>and send invite email</LaunchSecondText>
      </LaunchSurvey>
      <LaunchSurveyAfter />

      {store.ReminderData.map((reminder, i) => {
        return <ReminderBlock reminder={reminder} index={i} key={i} />;
      })}

      <CustomButtonStyle
        variant="outlined"
        id="Template_Communication_Schedule_Reminder_AddReminder_Button"
        onClick={() => store.AddNewReminder()}
      >
        <StyledPlus>+</StyledPlus>
        Add reminder email
      </CustomButtonStyle>
    </Wrapped>
  );
});

export default ThirdTab;

const Wrapped = styled.div`
  margin: 10px 30px;
`;

const Header = styled.div`
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin-top: 10px;
  color: var(--colorNeutralForeground1);
`;

const StyledCountScheduled = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const LaunchSurvey = styled.div`
  padding: 12px;
  background-color: var(--colorNeutralForeground4);
  border-radius: 4px;
`;

const LaunchSurveyAfter = styled.div`
  height: 15px;
  border-left: 3px dashed var(--colorPaletteBlueBackground2);
  margin-left: 20px;
`;

const LaunchSecondText = styled.span`
  color: var(--colorNeutralForeground2);
`;

const SubHeader = styled.div`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 30px;
`;

const CustomButtonStyle = styled(CustomButton)`
  padding: 5px 40px !important;
  background-color: var(--colorPaletteGrayBackground1) !important;
  border: 1px solid var(--colorPaletteBlueBackground1) !important;

  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const StyledPlus = styled.span`
  background-color: var(--colorNeutralBackground1);
  border-radius: 50%;
  font-size: large;
  padding: 0 5px;
`;
