import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Checkbox, IconButton } from "@mui/material";
import { observer } from "mobx-react";
import styled from "styled-components";

type Option = {
  id: number;
  name: string;
  code: string;
};

type SelectProps = {
  where: string;
  list: Option[];
  selected_list: number[];
  onChange: (id: number) => void;
};

const MultiSelectTags = observer((props: SelectProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MultiSelectWrapper>
      <IconButtonStyle
        id={`${props.where}_Select_Button`}
        aria-label="more"
        aria-controls={open ? `${props.where}_Select_Menu` : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <KeyboardArrowDownIconStyle />
      </IconButtonStyle>
      <MenuStyle
        id={`${props.where}_Select_Menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": `${props.where}_Select_Button`,
        }}
        PaperProps={{
          style: {
            maxHeight: "350px",
            width: "20ch",
          },
        }}
      >
        {props.list.map((option) => {
          const checked = props.selected_list.some((x) => x === option.id);
          return (
            <MenuItem
              id={`${props.where}_Option_${option.id}`}
              key={option.id}
              sx={{ m: 0, p: 0, pr: 1 }}
              onClick={() => props.onChange(option.id)}
            >
              <Checkbox
                id={`${props.where}_Option_Checkbox_${option.id}`}
                size="small"
                checked={checked}
              />
              {option.name}
            </MenuItem>
          );
        })}
      </MenuStyle>
    </MultiSelectWrapper>
  );
});

export default MultiSelectTags;

const MultiSelectWrapper = styled.div`
  height: 4ch;
  width: 100%;
`;

const IconButtonStyle = styled(IconButton)`
  width: 100% !important;
  height: 100% !important;

  &:hover {
    background-color: transparent !important;
  }

  .MuiTouchRipple-root {
    position: unset;
  }
`;

const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon)`
  color: var(--colorNeutralForeground2);
  width: 20px !important;
  height: 20px !important;

  &:hover {
    background-color: transparent;
  }
`;

const MenuStyle = styled(Menu)`
  .MuiMenu-paper {
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--colorGrayForeground6);
      border-radius: 20px;
      border: 3px solid var(--colorGrayForeground6);
    }
  }
`;
