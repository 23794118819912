import { FC } from "react";

import styled from "styled-components";

type EmptyStateProps = {};

const EmptyState: FC<EmptyStateProps> = () => {
    return (
        <EmptyStateContainer>
            <Text>
                Trend results will be available for this project when surveys incorporate questions beyond
                open-ended ones.
            </Text>
        </EmptyStateContainer>
    );
};

export default EmptyState;

const EmptyStateContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: var(--colorNeutralForeground2);
`;
