import { makeAutoObservable } from "mobx";
import MainStore from "MainStore";
import { editSegmentName } from "api/go/useEditSegmentName";
import { checkUniqueSegmentNameInAttriubue } from "api/go/useCheckUniqueSegmentNameInAttriubue";
import storeSegments from "./../storeSegments";
import { EmployeeAttribute } from "types/employee";
import dayjs, { Dayjs } from "dayjs";
import storeAttribute from "../store"

class Store {
  attributeId: number = 0;
  attribute: EmployeeAttribute = null;
  oldName: string = "";
  name = null;
  errorName = "";
  openCombinePopup: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  // rewrite yup
  changeName = (target: any) => {
    if (!target.value || target.value.trim() === "") {
      this.setErrorName("Name is a required field");
    } else if (!target.validity.valid) {
      this.setErrorName(
        "Please provide a valid segment name that contains alphanumeric characters."
      );
    } else {
      this.setErrorName("");
    }
    this.name = target.value;
  };

  changeDate = (value: Dayjs) => {
    if (this.attribute.is_date_attribute) {
      let newValue = dayjs(value);
      let isValid = newValue.isValid();
      if (isValid) {
        this.setErrorName("");
      } else {
        this.setErrorName("Enter the correct date");
      }
    } else {
      this.setErrorName("");
    }
    this.name = value;
  };

  changeOldName = (value: string) => {
    this.oldName = value;
  };

  setSegmentValue = (attribute: EmployeeAttribute, value: string) => {
    this.attribute = attribute;
    this.attributeId = attribute.id;
    this.changeOldName(value);

    if (attribute.is_date_attribute) {
      const date = dayjs(value, "DD.MM.YYYY");
      this.changeDate(date);
    } else {
      this.name = value;
    }
  };

  clearStore = () => {
    this.oldName = "";
    this.name = null;
    this.errorName = "";
  };

  setErrorName = (error: string) => {
    this.errorName = error;
  };

  changeCombinePopup = (flag: boolean) => {
    this.openCombinePopup = flag;
  };

  checkNameUniqueness = async () => {
    if (this.errorName !== "") return;
    let value = this.name;
    if (this.attribute.is_date_attribute) {
      value = dayjs(this.name).format("DD.MM.YYYY").toString();
      if (value === "Invalid Date") {
        this.setErrorName("Enter the correct date");
        return;
      }
    }
    if (this.oldName === value) {
      // if doesnt changed
      this.clearStore();
      storeSegments.changeEditSegmentPanel(false);
      storeSegments.changeValue("selectedRow", null);
      MainStore.setSnackbar("Segment successfully updated");
      return;
    }

    try {
      const response = await checkUniqueSegmentNameInAttriubue(this.attributeId, value);
      if (response) {
        this.save();
      } else {
        this.changeCombinePopup(true);
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  };

  save = async (fromCombine?: boolean) => {
    let value =
      typeof this.name === "string" ? this.name : dayjs(this.name).format("DD.MM.YYYY").toString();

    try {
      MainStore.changeLoader(true);
      if (this.name !== "") {
        await editSegmentName(this.attributeId, this.oldName, value);
        this.clearStore();
        storeSegments.changeEditSegmentPanel(false);
        this.changeCombinePopup(false);
        storeSegments.loadSegments();
        storeSegments.changeValue("selectedRow", null);
        if (fromCombine) {
          MainStore.setSnackbar("Segments successfully combined");
        } else {
          MainStore.setSnackbar("Segment successfully updated");
        }
        storeSegments.clearEditSegmentValue()
        storeAttribute.loadData();
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
}

const store = new Store();
export default store;
