import { FC } from "react";
import { observer } from "mobx-react";

import store from "../store";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import LaunchIcon from "@mui/icons-material/Launch";
import DownloadIcon from "@mui/icons-material/Download";
import DialogContent from "@mui/material/DialogContent";
import LinearProgress from "@mui/material/LinearProgress";

type LoadingPopupProps = {};

const LoadingPopup: FC<LoadingPopupProps> = observer(() => {
    return (
        <StyledDialog open={store.isOpenLoadingPopup}>
            <StyledDialogContent>
                <CloseBtnWrapper>
                    <CloseButton onClick={() => store.setData(false, "isOpenLoadingPopup")}>
                        <ClearIcon />
                    </CloseButton>
                </CloseBtnWrapper>
                <DialogTitle>We’re preparing your Hotspots chart now...</DialogTitle>
                <LoadingWrapper>
                    <Text>Please give us some time as we work our magic on it.</Text>
                    <Percent>45% completed</Percent>
                </LoadingWrapper>
                <BorderLinearProgress variant="determinate" value={45} />
                <SubTitle>In the meantime, you can also:</SubTitle>
                <StyledButton $isFirst>
                    <DownloadIcon />
                    Download .xlsx file with Hotspots data
                </StyledButton>
                <StyledButton>
                    View Dashboard in a new tab
                    <LaunchIcon />
                </StyledButton>
                <Text>
                    Ensure that you do not navigate to the “Hotspots” page as that will affect loading of the
                    chart here.
                </Text>
            </StyledDialogContent>
        </StyledDialog>
    );
});

export default LoadingPopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: none !important;
    width: 720px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 48px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const DialogTitle = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;
  color: var(--colorBrandForeground1);

  svg {
    font-size: 24px;
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
`;

const Percent = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
`;

const BorderLinearProgress = styled(LinearProgress)`
  margin-top: 10px;
  height: 8px !important;
  background-color: var(--colorPaletteBlueBackground1) !important;
  border-radius: 100px;

  .MuiLinearProgress-bar {
    background-color: var(--colorBrandForeground1) !important;
  }
`;

const StyledButton = styled(Button) <{ $isFirst?: boolean }>`
  width: fit-content;
  background-color: var(--colorPaletteGrayBackground1) !important;
  border-radius: 10px !important;
  margin-bottom: 16px !important;
  padding: 16px !important;
  text-transform: none !important;

  svg {
    font-size: 18px;
    margin: ${(props) => (props.$isFirst ? "0px 8px 0px 0px" : "0px 0px 0px 8px")};
  }
`;

const SubTitle = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
  margin: 72px 0px 16px 0px;
`;
