import { observer } from "mobx-react";
import { HighchartsReact } from "highcharts-react-official";
import { FC, useEffect, useState } from "react";
import { SENTIMENT_FILTERS_LIST_VALUES } from "constants/dashboard-responses";

import store from "./store";
import styled from "styled-components";
import Highcharts from "highcharts/highstock";

type PieChartProps = {};

const PieChart: FC<PieChartProps> = observer(() => {
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    if (store.activeOpenEndedQuestionInfo && store.activeOpenEndedQuestionInfo?.graphData) {
      const allGraphData = [
        {
          name: "Positive",
          count: store.activeOpenEndedQuestionInfo.graphData.positiveCount,
          color: "var(--colorPaletteGreenBackground1)",
          y: store.activeOpenEndedQuestionInfo.graphData.positivePercent,
          iconURL: "/assets/oval-green.png",
        },
        {
          name: "Negative",
          count: store.activeOpenEndedQuestionInfo.graphData.negativeCount,
          color: "var(--colorPaletteRedForeground1)",
          y: store.activeOpenEndedQuestionInfo.graphData.negativePercent,
          iconURL: "/assets/oval-red.png",
        },
        {
          name: "Mixed",
          count: store.activeOpenEndedQuestionInfo.graphData.mixedCount,
          color: "var(--colorPaletteYellowForeground1)",
          y: store.activeOpenEndedQuestionInfo.graphData.mixedPercent,
          iconURL: "/assets/oval-orange.png",
        },
        {
          name: "Neutral",
          count: store.activeOpenEndedQuestionInfo.graphData.neutralCount,
          color: "var(--colorNeutralForeground7)",
          y: store.activeOpenEndedQuestionInfo.graphData.neutralPercent,
          iconURL: "/assets/oval-gray.png",
        },
        {
          name: "Unavailable sentiment",
          count: store.activeOpenEndedQuestionInfo.graphData.unavailableCount,
          color: "var(--colorPaletteBlueBackground1)",
          y: store.activeOpenEndedQuestionInfo.graphData.unavailablePercent,
          iconURL: "/assets/oval-light-gray.png",
        },
      ];

      if (store.sentimentFilter !== SENTIMENT_FILTERS_LIST_VALUES.all) {
        const filteredGraphData = allGraphData.filter(
          (el) => el.name.toLocaleLowerCase() === store.sentimentFilter.toLocaleLowerCase()
        );
        setGraphData(filteredGraphData);
      } else setGraphData(allGraphData);
    }
  }, [store.activeOpenEndedQuestionInfo]);

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "pie",
      height: 160,
      width: 1000,
      marginLeft: -800,
      backgroundColor: "transparent",
      events: {
        render: function () {
          const chart = this;
          const textX = chart.plotLeft + chart.series[0].center[0];
          const textY = chart.plotTop + chart.series[0].center[1];

          if (chart.centerText) chart.centerText.destroy();

          chart.centerText = chart.renderer
            .html(
              `<div 
                style="display:flex;
                width: 100%; 
                align-items: center; 
                justify-content: center;
                margin-left: -4px;">
                <img src="/assets/peoples.svg" />
                <p  
                  style="font-size: 20px; margin-left: 4px;">
                  ${store.returnGraphMiddleLabel || "0"}
                </p>
              </div>`,
              textX,
              textY
            )
            .add();

          chart.centerText.attr({
            x: textX - chart.centerText.getBBox().width / 2,
          });
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    title: {
      text: "",
    },

    legend: {
      squareSymbol: false,
      symbolRadius: 0,
      align: "center",
      layout: "horizontal",
      useHTML: true,
      verticalAlign: "vertical",
      symbolWidth: 0,
      width: 500,

      labelFormatter: function () {
        return `
          <span style="display: flex; align-items: center;">
            <img src="${this.iconURL}" style="width: 12px; height:12px; margin-right: 6px"/>
            <p style="margin-right: 6px">${this.name}</p> 
            <p style="margin-right: 4px">${this.y}%</p>
            <p>(${this.count})</p>
          </span>
        `;
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        tooltip: {
          viible: false,
        },
      },
    },
    series: [
      {
        name: "",
        color: "var(--colorNeutralForeground2)",
        lineWidth: 1,
        size: "100%",
        innerSize: "80%",
        borderRadius: 0,
        data: graphData,
      },
    ],
  };

  return (
    <Container>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Container>
  );
});

export default PieChart;

const Container = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 6px;
  background-color: var(--colorNeutralForeground8);
`;
