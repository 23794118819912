import { surveyLocalization } from "survey-core"

export const burmeseStrings = {
  pagePrevText: "အရင်",
  pageNextText: "နောက်တစ်ခု",
  completeText: "တင်သွင်းရန်",
  otherItemText: "မှတ်ချက်တစ်ခု ချန်ထားပါ (ရွေးချယ်စရာ)",
  noneItemText: "တစ်ခုမျှ",
  selectAllItemText: "အားလုံးကို ရွေးပါ",
  progressText: "စာမျက်နှာ {0} ၏ {1}။",
  indexText: "{0} ၏ {1}။",
  panelDynamicProgressText: "{0} ၏ {1}။",
  questionsProgressText: "ဖြေထားသော {0} ၊ {1} မေးခွန်းများ",
  emptySurvey: "ဤစစ်တမ်းတွင် မြင်ရသောစာမျက်နှာများ သို့မဟုတ် မေးခွန်းများမပါဝင်ပါ။",
  completingSurvey: "စစ်တမ်းကို ပြီးမြောက်အောင် ဖြေဆိုသည့်အတွက် ကျေးဇူးတင်ပါသည်။",
  completingSurveyBefore: "အကုန်အဆင်သင့်ဖြစ်ပါပြီ။ ကျွန်ုပ်တို့၏ မှတ်တမ်းအရ သင်သည် ဤစစ်တမ်းကို ဖြည့်ပြီးပြီဟု တွေ့ရပါသည်။",
  loadingSurvey: "စစ်တမ်းအား စစ်ဆေးနေစဥ်တွင် စောင့်ဆိုင်းပေးပါ။",
  placeholder: "ရွေးချယ်ရန်",
  ratingOptionsCaption: "ရွေးချယ်ရန်",
  requiredError: "ပြန်ကြားချက် လိုအပ်သည်",
  booleanCheckedLabel: "ဟုတ်သည်",
  booleanUncheckedLabel: "မဟုတ်ပါ",
  questionTitlePatternText: "မေးခွန်းခေါင်းစဥ်",
};

surveyLocalization.locales["my_MM"] = burmeseStrings
surveyLocalization.locales["en"].completingSurvey = "Thank you for completing the survey! At {{entity_name}}, we appreciate your feedback!"
surveyLocalization.locales["en"].emptySurvey = "This survey does not contain any questions"

surveyLocalization.localeNames["my_MM"] = "Myanma Saya / မြန်မာစာ"
