import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";

import CustomModal from "components/modal/Modal";
import CustomButton from "components/button/Button";

type DiscardPopupProps = {
  open: boolean;
  closeHandler: () => void;
  cancelHandler: () => void;
  confirmHandler: () => void;
  title?: string;
  description?: string;
  confirm?: string;
  cancel?: string;
};

export const DiscardPopup: FC<DiscardPopupProps> = observer((props) => {
  const {
    title = "Unsaved Changes",
    description = "You have unsaved changes on this page. If you continue without saving, your changes will be lost. Would you like to save your changes before continuing?",
    confirm = "Save and continue",
    cancel = "Continue without saving",
  } = props;

  return (
    <CustomModal open={props.open} width="522px" minHeightContent={200} hide={props.closeHandler}>
      <PopupWrapper>
        <StyledHeader data-id="data-DiscardPopUp_Title">{title}</StyledHeader>
        <BoxStyled data-id="data-DiscardPopUp_Discription">{description}</BoxStyled>
        <BtnBox>
          <CustomButton
            data-id="data-DiscardPopUp_LeaveBtn"
            variant="contained"
            onClick={props.confirmHandler}
          >
            {confirm}
          </CustomButton>
          <CustomButton
            data-id="data-DiscardPopUp_CanselBtn"
            onClick={props.cancelHandler}
            variant="outlined"
          >
            {cancel}
          </CustomButton>
        </BtnBox>
      </PopupWrapper>
    </CustomModal>
  );
});

const PopupWrapper = styled.div`
  padding: 42px 40px;
`;

const StyledHeader = styled.div`
  color: var(--colorNeutralForeground1);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;

const BoxStyled = styled.div`
  margin-top: 30px;
  color: var(--colorNeutralForeground1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const BtnBox = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 20px;

  > :last-child {
    color: var(--colorNeutralForeground2);
    border: 1px solid var(--colorNeutralForeground5);
  }
`;
