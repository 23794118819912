import { QuestionRatingModel } from "survey-core"

export default function setSyncableRatingScaleProperties(
  sourceRatingScaleQuestion: QuestionRatingModel,
  targetRatingScaleQuestion: QuestionRatingModel,
): QuestionRatingModel {
  targetRatingScaleQuestion.setPropertyValue("rateCount", sourceRatingScaleQuestion.rateCount)
  targetRatingScaleQuestion.setPropertyValue("rateType", sourceRatingScaleQuestion.rateType)
  targetRatingScaleQuestion.setPropertyValue("rateValues", sourceRatingScaleQuestion.rateValues)
  targetRatingScaleQuestion.setLocalizableStringText("commentText", sourceRatingScaleQuestion.getLocalizableStringText("commentText"))
  targetRatingScaleQuestion.setLocalizableStringText("maxRateDescription", sourceRatingScaleQuestion.getLocalizableStringText("maxRateDescription"))
  syncLocalizableStrings("commentText", sourceRatingScaleQuestion, targetRatingScaleQuestion)
  syncLocalizableStrings("maxRateDescription", sourceRatingScaleQuestion, targetRatingScaleQuestion)
  syncLocalizableStrings("minRateDescription", sourceRatingScaleQuestion, targetRatingScaleQuestion)

  return targetRatingScaleQuestion
}

function syncLocalizableStrings(propertyName: string, sourceRatingScaleQuestion: QuestionRatingModel, targetRatingScaleQuestion: QuestionRatingModel): void {
  const sourceLocalizableString = sourceRatingScaleQuestion.getLocalizableString(propertyName)
  const targetLocalizableString = targetRatingScaleQuestion.getLocalizableString(propertyName)

  sourceLocalizableString.getLocales().forEach(locale => {
    targetLocalizableString.setLocaleText(
      locale,
      sourceLocalizableString.getLocaleText(locale)
    )
  })
}
