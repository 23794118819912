import { FC, useRef, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";

import { ORG_STRUCTURE_CODES } from "constants/entityManagementTypes";
import { MultipleSelect } from "components/multiselect/MultiSelect";
import { TRecipientsFilter } from "types/recipients";
import CustomButton from "components/button/Button";
import storeEmployees from "../../../Employees/store";
import store from "./store";

type FilterAccordionProps = {};

const FilterAccordion: FC<FilterAccordionProps> = observer((props) => {
  const inputRef = useRef(undefined);
  const [searchValue, setSearchValue] = useState<string>("");

  const getAttributeOptions = (index: number) =>
    store.Attributes.map((x) => x.name).filter(
      (option) =>
        !store.filters.some(
          (otherFilter, otherIndex) =>
            otherIndex !== index && otherFilter.select_attribute === option
        )
    );

  const getSegmentOptions = (filter: TRecipientsFilter) =>
    filter.select_attribute
      ? store.Attributes.find((x) => x.name === filter.select_attribute)?.values?.map(
          (x) => x.value
        ) || []
      : [];

  const removeVerification =
    store.filters.length === 1 &&
    !store.filters[0].select_attribute &&
    !store.filters[0].select_segments.length;

  const applyVerification =
    !store.filters.some((el) => el.select_attribute) ||
    store.filters.some((el) => el.select_segments.length === 0);

  const placeholderSelects =
    storeEmployees.saved_org_structure_code === ORG_STRUCTURE_CODES.unit_based ? "unit or" : "";

  const searchFilter = (index: number) => {
    const lowerCaseValue = searchValue?.toLowerCase();
    const filteredSearch =
      getAttributeOptions(index)?.filter((row) => {
        const value = row.toLowerCase();
        return value.includes(lowerCaseValue);
      }) || [];
    return filteredSearch;
  };

  return (
    <AccordionStyle>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="Employee_EmployeeList_FilterEmployees_Panel"
      >
        <CustomTypography id="Employee_EmployeeList_FilterEmployees_Panel_Title">
          FILTER EMPLOYEES
        </CustomTypography>
      </AccordionSummary>
      <AccordionDetails>
        <FiltersList>
          {store.filters.map((filter, index) => {
            return (
              <FilterItem key={index}>
                <StyledAutocomplete
                  id={`Employee_EmployeeList_FilterEmployees_Panel_SelectAtribute_${index}`}
                  name="select_attribute"
                  value={filter.select_attribute}
                  inputProps={{
                    id: `Employee_EmployeeList_FilterEmployees_Panel_SelectAtributeInput_${index}`
                  }}
                  onChange={(e) =>
                    store.changeFilterValues(index, e.target.name, e.target.value as string)
                  }
                  displayEmpty
                  renderValue={() => {
                    if (!filter.select_attribute) {
                      return (
                        <Placeholder
                          id={`Employee_EmployeeList_FilterEmployees_Panel_SelectAtribute_Placeholder_${index}`}
                        >
                          Select {placeholderSelects} attribute
                        </Placeholder>
                      );
                    }
                    return <SelectAttribute>{filter.select_attribute}</SelectAttribute>;
                  }}
                  MenuProps={{
                    autoFocus: false,
                    PaperProps: {
                      sx: { maxHeight: 400 },
                    },
                    MenuListProps: {
                      sx: { padding: 0 },
                    },
                  }}
                  onAnimationEnd={() => inputRef.current?.focus()}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  <StyledContainerSelectAttribute>
                    <StyledLabelCount>{searchFilter(index)?.length} in all</StyledLabelCount>
                    <TextField
                      id={`Employee_EmployeeList_FilterEmployees_Panel_SelectAtribute_InnerInput_${index}`}
                      style={{ width: "100%" }}
                      ref={inputRef}
                      variant="standard"
                      placeholder={`Search for ${placeholderSelects} attribute`}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment  id={`Employee_EmployeeList_FilterEmployees_Panel_SelectAtribute_InnerInput_${index}`} position="start">
                            <IconButton id={`Employee_EmployeeList_FilterEmployees_Panel_SelectAtribute_InnerInput_SearchBtn_${index}`}>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={searchValue}
                      onClick={(e) => e.stopPropagation()}
                      onKeyDown={(e) => e.stopPropagation()}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </StyledContainerSelectAttribute>
                  {searchFilter(index)?.length > 0 ? (
                    searchFilter(index)?.map((option, indexOption) => (
                      <StyledMenuItem
                        id={`Employee_EmployeeList_FilterEmployees_Panel_SelectAtribute_Option_${index}_${indexOption}`}
                        key={option + indexOption}
                        value={option}
                        onClick={() => setSearchValue("")}
                      >
                        {option}
                      </StyledMenuItem>
                    ))
                  ) : (
                    <StyledNotFound
                      id={`Employee_EmployeeList_FilterEmployees_Panel_SelectAtribute_OptionNoData_${index}`}
                    >
                      No data
                    </StyledNotFound>
                  )}
                </StyledAutocomplete>

                <MultipleSelect
                  options={getSegmentOptions(filter)}
                  selectedList={filter.select_segments}
                  onChange={store.changeFilterValues}
                  name="select_segments"
                  index={index}
                  placeholder={`Select ${placeholderSelects} segment`}
                />
                {!removeVerification && (
                  <RemoveFilterButton
                    id={`Employee_EmployeeList_FilterEmployees_Panel_SelectAtribute_ClearBtn_${index}`}
                    onClick={() => store.removeFilter(index)}>
                    Clear
                  </RemoveFilterButton>
                )}
              </FilterItem>
            );
          })}
        </FiltersList>
        <ButtonsWrap>
          <CustomButton
            id={`Employee_EmployeeList_FilterEmployees_Panel_ApplyFilter_Btn`}
            variant="outlined"
            disabled={applyVerification}
            onClick={() => {
              store.loadUsers();
              store.changeValue("filtersResult", store.filters);
            }}
          >
            Apply filter
          </CustomButton>
          {store.filters.length < 6 && (
            <AddFilterBtn
              id={`Employee_EmployeeList_FilterEmployees_Panel_AddFilter_Btn`}
              onClick={() => store.addFilter()}
            >
              + Add filter
            </AddFilterBtn>
          )}
        </ButtonsWrap>
      </AccordionDetails>
    </AccordionStyle>
  );
});

export default FilterAccordion;

const AddFilterBtn = styled(CustomButton)`
  width: fit-content !important;
  padding: 0 !important;
  justify-content: flex-start !important;
  height: 34px;

  &:hover {
    background-color: transparent !important;
    text-decoration: underline !important;
  }

  &:disabled {
    text-decoration: none !important;
    svg {
      color: var(--colorNeutralForeground5) !important;
    }
  }
`;

const FiltersList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledAutocomplete = styled(Select)`
  max-width: 225px;
  width: 100% !important;
  height: 34px;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;

  input {
    border-radius: 2px !important;
    padding: 0 !important;
    font-family: Roboto, sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .MuiOutlinedInput-root {
    border-radius: 2px !important;
    height: 34px;
    padding: 0 9px !important;
  }
`;

const StyledContainerSelectAttribute = styled.div`
  background: var(--colorNeutralForeground8);
  box-shadow: 0px -1px 0px 0px var(--colorNeutralBackground3) inset;
  padding-top: 14px;
`;

const StyledLabelCount = styled.p`
  color: var(--colorNeutralForeground1);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin: 0 8px 10px;
`;

const Placeholder = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: var(--colorNeutralForeground9);
  margin: 3px 0 0 0 !important;
`;

const SelectAttribute = styled.p`
  font-family: Roboto, sans-serif;
  color: var(--colorNeutralForeground1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 1px 0;
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 15px 10px !important;
  border-bottom: 1px solid var(--colorNeutralForeground4) !important;
  &:last-child {
    border-bottom: none !important;
  }
`;

const StyledNotFound = styled(MenuItem)`
  display: flex;
  justify-content: center !important;
  align-items: center;
  height: 200px;
  cursor: default !important;
  &:hover {
    background-color: var(--colorNeutralBackground1) !important;
  }
  &.Mui-selected {
    background-color: var(--colorNeutralBackground1) !important;
  }
`;

const ButtonsWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const FilterItem = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;
`;

const RemoveFilterButton = styled(CustomButton)`
  white-space: nowrap;
  width: fit-content !important;
  padding: 0 !important;
  justify-content: flex-start !important;
  color: var(--colorBrandForeground2) !important;

  &:hover {
    background-color: transparent !important;
    text-decoration: underline !important;
  }

  svg {
    font-size: 20px;
  }
`;

const AccordionStyle = styled(Accordion)`
  margin: 20px 0;
  box-shadow: none !important;
  border: 1px solid var(--colorPaletteBlueBackground1) !important;

  &:first-of-type {
    margin-top: 10px !important;
  }
`;

const CustomTypography = styled(Typography)`
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 1 !important;
  color: var(--colorNeutralForeground2);
`;
