import { TOOLTIP_TYPE_BENCHMARK, TOOLTIP_TYPE_VALUE } from "constants/dashboard-trends";

export const OVERAL_SCORE_TOOLTIP =
    `<div style="padding: 4px;">
       {point.overalTitle}
    </div>` +
    `<tr></tr>` +
    `<div>
        <div 
            style="padding: 8px 0px; 
            font-size: 16px; 
            text-align:center; 
            font-weight: 500;">
            {point.y}%
        </div>
    </div>` +
    `<tr></tr>` +
    `<div>
    {point.respondentsCount}
    </div>`;

export const ENPS_TOOLTIP =
    `<div style="padding: 4px;">
       {point.overalTitle}
    </div>` +
    `<tr></tr>` +
    `<div>
        <div 
            style="padding: 8px 0px; 
            font-size: 16px; 
            text-align:center; 
            font-weight: 500;">
            {point.y}
        </div>
    </div>` +
    `<tr></tr>` +
    `<div>
    {point.respondentsCount}
    </div>`;

export const SCORED_QUESTIONS_TOOLTIP =
    `<div style="padding: 4px; width: 190px;">
        <div style=
             "display:flex;
              align-items:center;
              justify-content:center;
              width: 100%;">         
                <p style="margin-left: 4px;
                    font-size:12px;
                    font-weigth: 500;
                    white-space:wrap;
                    text-align:center;
                    ">
                    {point.text}</p>
        </div>
    </div>` +
    `<tr></tr>` +
    `<div style="display:flex;
                 flex-direction:column;
                 align-items:center;
                 justify-content:center;
                 background-color:var(--colorNeutralBackgroundInverted3);
                 margin: 8px 0px;">
                    {point.centeredTooltipInfo}
    </div>` +
    `<tr></tr>` +
    `<div>
        {point.respondentsCount}
    </div>`;

export const returnRespondents = (count: number) => `<div 
    style="border-top: 1px solid var(--colorNeutralForeground2);
    width: 100%;
    display:flex;
    align-items:center;
    justify-content:center;
    padding-top: 8px;">
    <img src="/assets/participants.svg" 
        style="width: 16px; 
        height: 16px"; />
        <p style="margin-left: 4px; 
        color: var(--colorNeutralForeground6);
        font-size:12px;">
        ${count} responded
    </p></div>`;

export const returnCenteredInfo = (name: string, value: number, type: string) =>
    `<div style=
        "display:flex;
        align-items:center;
        justify-content:center;
        width: 100%;
        margin-top: 14px;">
                  <span style="width: 10px; height:10px; background-color: ${type === TOOLTIP_TYPE_VALUE
        ? "var(--colorPalettePurpleForeground1)"
        : "var(--colorPaletteYellowForeground1)"
    };"></span>
            <p style="margin-left: 4px;
                font-size:12px;">${name}</p>
    </div>
    <div 
        style="padding: 8px 0px; 
        font-size: 16px; 
        text-align:center; 
        font-weight: 500;">
            ${value}%
    </div>`;

export const returnTitle = (title: string, type: string) => `<div style=
    "display:flex;
     align-items:center;
     justify-content:center;
     width: 100%;">
     <span style="width: 10px; height:10px; background-color: ${type === TOOLTIP_TYPE_VALUE
        ? "var(--colorPalettePurpleForeground1)"
        : "var(--colorPaletteYellowForeground1)"
    };"></span>
       <p style="margin-left: 4px;
           font-size:12px;">${title}${type === TOOLTIP_TYPE_BENCHMARK ? " (Benchmark)" : ""}</p>
</div>`;
