import http from 'api/https';
import { createUrl } from "../../helpers/url";

export const getOneEntity = (id: number): Promise<any> => {
  const query: { id: string } = { id: id.toString() };
  if (http.isAPIGO) {
    return http.getGO("/entity/GetOneForUpdate/" + id)
  } else {
    const url = createUrl({
      path: "/entity/GetOneForUpdate",
      query: query,
    });
    return http.get(url);
  }
};
