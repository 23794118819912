import http from "api/https";
import { createUrl } from "helpers/url";

export const useDeleteSurveyFull = async (id_delete: number) => {
  const query: { id: string } = { id: id_delete.toString() };
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/sm/sm_survey/DeleteCascade",
      query: query,
    });
    return http.removeGO(url, {})
  } else{
    const url = createUrl({
      path: "/sm_survey/DeleteCascatde",
      query: query,
    });
    return http.remove(url, {});
  }
};
