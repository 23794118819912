import Checkbox from "./../icons/Checkbox"
import Promoter from "./../icons/Promoter"
import ToggleOff from "./../icons/ToggleOff"
import Subject from "./../icons/Subject"
import LinearScale from "./../icons/LinearScale"
import RadioButtonChecked from "./../icons/RadioButtonChecked"


export const QuestionIcons = {
    "rating" : <LinearScale />,
    "eNPS" : <Promoter />,
    "comment" : <Subject />,
    "radiogroup" : <RadioButtonChecked />,
    "checkbox" : <Checkbox />,
    "boolean" : <ToggleOff />,
}