import { FC, useEffect } from "react";

import store from "./store";
import styled from "styled-components";
import topStore from "../../store";
import QuestionsList from "./QuestionsList";
import DimensionsList from "./DimensionsList";
import responsesStore from "../store";
import ConditionalLogicPopup from "./ConditionalLogicPopup";

type ScoredQuestionsTabProps = {
  localFilters: { id: number; name: string; segments: string[] }[];
};

const ScoredQuestionsTab: FC<ScoredQuestionsTabProps> = (props) => {
  useEffect(() => {
    if (
      topStore.selectedSurvey &&
      topStore.activeTab === "responses" &&
      topStore.responsesActiveLocalTab === "scored_questions"
    ) {
      store.getDashboardResponsesScoredDimensions(
        topStore.selectedSurvey.value,
        props.localFilters
      );
    }
  }, [
    topStore?.selectedSurvey?.value,
    topStore.activeTab,
    topStore.role,
    topStore.overviewBenchmark,
    props.localFilters,
  ]);

  return (
    <Container>
      <DimensionsList />
      <Content>
        <QuestionsList localFilters={props.localFilters} />
      </Content>
      <ConditionalLogicPopup />
    </Container>
  );
};

export default ScoredQuestionsTab;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--colorNeutralBackground1);
  border: 1px solid var(--colorNeutralForegroundInverted2);
  padding: 48px 0px;
`;

const Content = styled.div`
  width: calc(100% - 191px);
`;
