import http from "api/https";
import { createUrl } from "../../helpers/url";


export const getPreviewSurveyCommunication = (idSurvey: number, idLanguage: number, idReminder: number, is_report: boolean): Promise<any> => {
  idLanguage = idLanguage === null ? 0 : idLanguage;
  idReminder = idReminder === null ? 0 : idReminder;

  const query: { idSurvey: string, idLanguage: string, idReminder: string, is_report: string} = {
    idSurvey: idSurvey.toString(),
    idLanguage: idLanguage.toString(),
    idReminder: idReminder.toString(),
    is_report: String(is_report.valueOf())
  };

  if(http.isAPIGO){
    const urlGO = createUrl({
      path: "/sm/sm_survey/GetPreviewEmail",
      query: query,
    });
    return http.getGO(urlGO);
  }else{
    const url = createUrl({
      path: "/sm_survey/GetPreviewEmail",
      query: query,
    });
    return http.get(url);
  }
};
