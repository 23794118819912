import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import FormHelperText from '@mui/material/FormHelperText';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));


type SelectOption = {
  id: number;
  name: string;
}

type SelectType = {
  data: SelectOption[];
  value: number;
  label?: string;
  name?: string;
  errorText: string;
  error: boolean;
  onChange: (e) => void;
  id: string;
}


export default function BaseLookup(props: SelectType) {

  return (
    <FormControl sx={{ minWidth: 200 }} variant="standard">
      <InputLabel htmlFor="demo-customized-select-native">{props.label}</InputLabel>
      <NativeSelect
        id="demo-customized-select-native"
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        input={<BootstrapInput />}
      >
        {props.data.map((option) => <option key={option.id} value={option.id}>{option.name}</option>)}
      </NativeSelect>
      {props.error && <FormHelperText error={true}>{props.errorText}</FormHelperText>}
    </FormControl>
  );
}