import { FC } from "react";
import { observer } from "mobx-react";
import { TOthersDimension } from "types/dashboard";

import styled from "styled-components";
import QuestionCard from "./QuestionCard";

type QuestionGroupProps = {
  dimension: TOthersDimension;
  id: string;
  localFilters: { id: number; name: string; segments: string[] }[];
};

const QuestionGroup: FC<QuestionGroupProps> = observer((props) => {
  return (
    <Container id={props.id}>
      <TitleContainer>
        <Title>{props.dimension?.dimension}</Title>
        <QuestionCounter>
          ({props.dimension?.questionsList.length}{" "}
          {props.dimension?.questionsList.length > 1 ? "questions" : "question"})
        </QuestionCounter>
      </TitleContainer>
      {props.dimension?.questionsList.map((question, index) => (
        <QuestionCard
          question={question}
          key={question.id}
          isLastQuestion={Boolean(index === props.dimension?.questionsList.length - 1)}
          localFilters={props.localFilters}
        />
      ))}
    </Container>
  );
});

export default QuestionGroup;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 80px 32px 75px;
  scroll-margin-top: 320px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
`;

const Title = styled.span`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorPaletteVioletBackground1);
`;

const QuestionCounter = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorPaletteVioletBackground1);
`;
