import { FC } from "react";
import { observer } from "mobx-react";

import store from "../store";
import styled from "styled-components";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

type LeftBarProps = {};

const LeftBar: FC<LeftBarProps> = observer(() => {
  return (
    <Container>
      <RespondentsList>
        {store.graphData?.data?.map((row, rowIndex) => {
          if (row.type !== "all_respondents")
            return (
              <Group key={rowIndex}>
                <TopContainer>
                  {row.segment.map((el, index, array) => {
                    if (index !== array.length - 1)
                      return (
                        <Row key={index}>
                          <TopRespondent key={index}>{el}</TopRespondent>
                          <KeyboardArrowRightIcon />
                        </Row>
                      );
                  })}
                </TopContainer>
                <Respondent>{row.segment[row.segment.length - 1]}</Respondent>
              </Group>
            );
          else
            return (
              <Group key={rowIndex}>
                <Respondent>All respondents</Respondent>
              </Group>
            );
        })}
        {!store.selectedSegments.length && (
          <EmptyLabel>
            <EmptyText>No segments selected</EmptyText>
          </EmptyLabel>
        )}
      </RespondentsList>
    </Container>
  );
});

export default LeftBar;

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 280px;
  background-color: var(--colorNeutralBackground1);
`;

const EmptyLabel = styled.div`
  width: 100%;
  background-color: var(--colorPalleteLightBlue);
  padding: 12px 23px;
`;

const EmptyText = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: var(--colorNeutralForeground1);
`;

const RespondentsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Respondent = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
  cursor: pointer;
`;

const Group = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 64px;
  padding: 4px 24px;
  margin: 1px 0px;
`;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
`;

const TopRespondent = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
`;

const Row = styled.div`
  display: flex;

  svg {
    font-size: 16px;
    color: var(--colorNeutralForeground1);
  }
`;
