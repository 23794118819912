import NavigateToLoginButton from "features/Auth/components/NavigateToLoginButton";
import ForgotPasswordScreen from "features/Auth/ForgotPasswordScreen";
import ResetPasswordMessageScreen from "features/Auth/ResetPasswordMessageScreen";
import { FC, useState } from "react";

type ForgotPasswordPageProps = {};

const ForgotPasswordPage: FC<ForgotPasswordPageProps> = (props) => {
  const [userEmail, setUserEmail] = useState("");

  const sendSubmittedDataToParent = (email: string) => {
    setUserEmail(email);
  }

  return userEmail ? (
    <ResetPasswordMessageScreen title="Success! We have sent you a link to your email to reset your address." messages="Did not receive an email? You might not have an account with us." btnContent={NavigateToLoginButton} />
  ) : (
    <ForgotPasswordScreen sendSubmittedDataToParent={sendSubmittedDataToParent} />
  );
};


export default ForgotPasswordPage;