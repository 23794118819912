import { observer } from "mobx-react";
import { OPEN_ENDED_LOCAL_MENU } from "constants/dashboard-responses";
import { FC, useCallback, useEffect } from "react";

import store from "./store";
import styled from "styled-components";
import topStore from "../../store";
import TopicsList from "./TopicsList";
import QuestionInfo from "./QuestionInfo";
import responsesStore from "../store";

type ResponseByTopicsTabProps = {
    localFilters: { id: number; name: string; segments: string[] }[];
};

const ResponseByTopicsTab: FC<ResponseByTopicsTabProps> = observer((props) => {
    useEffect(() => {
        if (
            topStore.selectedSurvey &&
            topStore.activeTab === "responses" &&
            topStore.responsesActiveLocalTab === "open_ended" &&
            store.activeOpenEndedLocalTab === "responses_by_topics" &&
            store.isOpenTopicResponseDetail
        ) {
            store.getDashboardResponsesOpenEndedResponsesByTopic(
                topStore.selectedSurvey.value,
                props.localFilters
            );
        }
    }, [
        topStore.selectedSurvey,
        topStore.activeTab,
        topStore.responsesActiveLocalTab,
        props.localFilters,
        topStore.overviewBenchmark,
        store.activeOpenEndedQuestion,
        store.activeOpenEndedLocalTab,
        store.isOpenTopicResponseDetail,
        store.sentimentFilter,
        store.pagination.active_page,
    ]);

    const renderTopicTabContent = useCallback(() => {
        if (store.activeOpenEndedLocalTab === OPEN_ENDED_LOCAL_MENU.RESPONSES_BY_TOPICS) {
            if (store.isOpenTopicResponseDetail) {
                return <QuestionInfo localFilters={props.localFilters} />;
            } else return <TopicsList localFilters={props.localFilters} />;
        }
    }, [store.activeOpenEndedLocalTab, store.isOpenTopicResponseDetail]);

    return <Container>{renderTopicTabContent()}</Container>;
});

export default ResponseByTopicsTab;

const Container = styled.div``;
