import { FC, useEffect } from "react";
import { Link, Link as RouterLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { observer } from "mobx-react";
import { Divider, IconButton, TextField, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import PageGrid from "./PageGrid";
import CustomButton from "components/button/Button";
import CustomModal from "components/modal/Modal";
import SurveyDeletePopup from "./components/DeletePopup";
import SaveAsTemplatePopup from "./components/SaveAsTemplatePopup";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";
import { FREQUENCY_TYPES } from "constants/frequency-types";
import SurveyResult from "./SurveyResult";
import EditProjectPopup from "features/Project/EditProject/EditProject";
import { RecepientsIcon } from "./components/RecepientsIcon";
import { BellIcon } from "./components/BellIcon";
import { EditIcon } from "./components/EditIcon";
import { FrequencyIcon } from "./components/FrequencyIcon";
import { SURVEY_STATUS } from "constants/survey-status";
import { createUrl } from "helpers/url";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import storeCreation from "../SurveyCreation/store";
import store from "./store";
import { ORG_STRUCTURE_CODES } from "constants/entityManagementTypes";

type SurveyListProps = {
  project_id: number;
  orgStructureCode: string;
};

const SurveyList: FC<SurveyListProps> = observer((props) => {
  const navigate = useNavigate();
  useEffect(() => {
    store.setProjectId(props.project_id);
    store.getSurveys();
    store.getProject();
    store.GetSurveyTags();
    StorePermission.getProjectPermissions(props.project_id);
  }, [props.project_id]);

  const hasFeature =
    (store.project?.projecttype_code === SEND_OUT_SURVEY_TYPES.OneOff &&
      StorePermission.features.one_off.hasFeature) ||
    (store.project?.projecttype_code === SEND_OUT_SURVEY_TYPES.Milestones &&
      StorePermission.features.milestone.hasFeature) ||
    (store.project?.projecttype_code === SEND_OUT_SURVEY_TYPES.Frequency &&
      StorePermission.features.frequency.hasFeature);

  const hasCreateSurvey =
    !hasFeature ||
    !(
      StorePermission.entityPermissions.createAndEditSurvey.hasPermission ||
      StorePermission.projectPermissions.createAndEditProject.hasPermission
    );

  const handleEditSettings = () => {
    if (hasFeature) {
      store.setOpenProjectEditPopup(true);
    }
  };

  const handleRecipientsButton = () => {
    if (store.data[0].status_idCode === SURVEY_STATUS.running) {
      storeCreation.changeIdProject(store.data[0].project_id);
      storeCreation.openClickRunSurveyEditPopup(true);
    }
    localStorage.setItem("surveyAction", "edit");
  };

  const handleRemoveSearch = () => {
    store.changeResultSearchValue("");
    store.getSurveys();
  };

  const handleCreateSurvey = () => {
    navigate(
      createUrl({
        path: "/survey",
        query: { project_id: store.project_id.toString() },
      }),
    );
    localStorage.setItem("surveyAction", "create");
  };

  const historicalSurveyProjectUrl = createUrl({
    path: "/import-historical-survey",
    query: { project_id: store.project?.id.toString() },
  });

  return (
    <MainContent id="SurveyList_Page">
      <MainWrapper>
        <HeaderLefSide>
          <BackToProjects id="Survey_List_GoBackProject_Button" href="/projects">
            &#60; Back to survey projects
          </BackToProjects>
          <Header id="Survey_List_NameProject">
            {store.openSurvayResults && store.selectedSurveyData
              ? store.selectedSurveyData.name
              : store.project !== null && store.project.name}
          </Header>
        </HeaderLefSide>
        {!store.openSurvayResults && (
          <TextFieldStyled
            id="Survey_List_SearchProjectAndSurveyName"
            size="small"
            placeholder="Search for project/survey name"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => store.onSearchClicked()}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={store.searchedValue}
            onKeyDown={store.keyPress}
            onChange={(e) => store.changeValue("searchedValue", e.target.value)}
          />
        )}
      </MainWrapper>

      <Divider />

      {store.curentSurveyId !== 0 && store.openSurvayResults && store.selectedSurveyData ? (
        <SurveyResult />
      ) : (
        <>
          <AfterDivider>
            <SurveyListTop>
              <Frequency id="Survey_List_TypeProject">
                <FrequencyIconWrap>
                  <FrequencyIcon />
                </FrequencyIconWrap>
                {store.project?.projecttype_code === SEND_OUT_SURVEY_TYPES.OneOff && (
                  <span id="SurveyList_TypeProject_Text">Frequency: One-off</span>
                )}
                {store.project?.projecttype_code === SEND_OUT_SURVEY_TYPES.Frequency && (
                  <span id="SurveyList_TypeProject_Text">
                    Frequency: {FREQUENCY_TYPES[store.project?.frequency_idNavName]}
                  </span>
                )}
                {store.project?.projecttype_code === SEND_OUT_SURVEY_TYPES.Milestones && (
                  <span id="SurveyList_TypeProject_Text"> Milestone</span>
                )}
              </Frequency>

              <Treshold id="Survey_List_ConfidentialityThreshold">
                Confidentiality threshold: {store.project?.min_responses}
              </Treshold>

              <EditSettings
                id="Survey_List_EditSettings_Button"
                $disabled={hasCreateSurvey}
                onClick={handleEditSettings}
              >
                <EditSettingsIcon>
                  <EditIcon />
                </EditSettingsIcon>
                Edit project settings
              </EditSettings>
            </SurveyListTop>

            {store.project?.projecttype_code === SEND_OUT_SURVEY_TYPES.Milestones && (
              <RecipientsButton
                id="Survey_List_Recipients_Button"
                onClick={handleRecipientsButton}
                $disabled={!hasFeature || !StorePermission.entityPermissions.createAndEditSurvey.hasPermission}
                to={`/recipients?id=${store.data[0]?.id}`}
              >
                <RecepientsIcon />
                <RecipientsText id="Survey_List_Recipients_Button_Text">
                  Recipients: {store.projectCountRecepients}
                </RecipientsText>
                {store.projectMilestoneCountRecepients !== 0 && (
                  <MileStoneRecipients>
                    <BellIcon />
                    <RecipientsCount>{store.projectMilestoneCountRecepients}</RecipientsCount>
                  </MileStoneRecipients>
                )}
              </RecipientsButton>
            )}
          </AfterDivider>

          {store.resultSearchValue !== "" && (
            <ResultSearch id="Survey_List_ResultSearch">
              {store.data.length === 0 ? (
                <SearchText id="Survey_List_ResultSearch_Text">
                  "<SearchTextValue>{store.resultSearchValue}</SearchTextValue>" No surveys was
                  found to match the provided details. Please try using a different search keyword.
                </SearchText>
              ) : (
                <SearchText id="Survey_List_ResultSearch_Text">
                  "<SearchTextValue>{store.resultSearchValue}</SearchTextValue>" can be found in the
                  following surveys or its parent project
                </SearchText>
              )}

              <RemoveSearch id="Survey_List_ResultSearch_RemoveSearch">
                <CustomButton
                  id="Survey_List_ResultSearch_RemoveSearch_Button"
                  onClick={handleRemoveSearch}
                >
                  Remove search
                </CustomButton>
              </RemoveSearch>
            </ResultSearch>
          )}

          {store.curentSurveyId === 0 && !store.openSurvayResults && (
            <ButtonsWrapper
              $isMilestone={store.project?.projecttype_code === SEND_OUT_SURVEY_TYPES.Milestones}
            >
              {store.project?.projecttype_code === SEND_OUT_SURVEY_TYPES.Milestones && (
                <TypographyStyled id={"Survey_List_Header_Text"}>
                  Create a new milestone survey by adding a new milestone in the project settings.
                </TypographyStyled>
              )}

              {store.project?.projecttype_code !== SEND_OUT_SURVEY_TYPES.Milestones && (
                <ButtonWrapper>
                  {props.orgStructureCode === ORG_STRUCTURE_CODES.unit_based && (
                    <StyledLink id={`ImportHistoricalSurvey`} to={historicalSurveyProjectUrl}>
                      Import historical survey
                    </StyledLink>
                  )}

                  <CustomButton
                    id="Survey_List_CreateNewSurvey_Button"
                    disabled={hasCreateSurvey}
                    variant="outlined"
                    onClick={handleCreateSurvey}
                  >
                    + Create new survey
                  </CustomButton>
                </ButtonWrapper>
              )}
            </ButtonsWrapper>
          )}

          <EditProjectPopup
            open={store.openProjectEditPopup}
            id_project={store.project_id}
            handleHide={() => store.setOpenProjectEditPopup(false)}
            onSaved={() => store.getProject()}
          />

          <CustomModal
            open={store.openSurveyDeletePopup}
            hide={(flag) => store.openClickSurveyDeletePopup(flag)}
            width="522px"
            children={<SurveyDeletePopup />}
          />
          <CustomModal
            open={store.openSaveAsTempPopup}
            hide={(flag) => store.openClickSaveAsTempPopup(flag)}
            children={<SaveAsTemplatePopup />}
          />
          <PageGrid />
        </>
      )
      }
    </MainContent >
  );
});

export default SurveyList;

const MainContent = styled.div`
  margin: 0 50px 150px 250px;
`;

const MainWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding-top: 50px;
  justify-content: space-between;
  height: 110px;
`;

const ResultSearch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--colorPaletteBlueBackground3);
  margin-top: 24px;
  height: 50px;
`;

const ButtonsWrapper = styled.div<{ $isMilestone: boolean }>`
  width: 100%;
  background-color: var(--grenBackgroundColor2);
  display: flex;
  flex-direction: row;
  justify-content: ${({ $isMilestone }) => ($isMilestone ? "space-between" : "flex-end")};
  align-items: center;
  min-height: 56px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 15px;
  align-items: center;
`;

const RecipientsText = styled.span`
  margin-left: 5px;
  margin-right: 5px;
`;

const RecipientsCount = styled.span`
  color: var(--colorNeutralBackground1);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const MileStoneRecipients = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: var(--colorPaletteVioletBackground1);
  width: 34px;
  height: 22px;
  flex-shrink: 0;
`;

const RecipientsButton = styled(Link) <{ $disabled: boolean }>`
  display: flex;
  margin: 15px;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  pointer-events: ${(props) => props.$disabled && "none"};
  cursor: ${(props) => props.$disabled && "default"};
  height: 34px;
  flex-shrink: 0;
  border-radius: 25px;
  background: var(--colorPaletteBlueBackground2);
  color: var(--colorNeutralForeground1);
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const SearchText = styled.div`
  margin-left: 16px;
`;

const SearchTextValue = styled.span`
  font-weight: 500;
`;

const StyledLink = styled(RouterLink)`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  margin-right: 10px;
  color: var(--colorBrandForeground1, #1477f8);
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

const RemoveSearch = styled.div`
  margin-right: 24px;
`;

const HeaderLefSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const AfterDivider = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FrequencyIconWrap = styled.div`
  margin: 0 5px;
`;

const EditSettingsIcon = styled.div`
  margin: 0 5px;
`;

const Frequency = styled.div`
  display: flex;
  min-height: 60px;
  align-items: center;
  color: var(--colorNeutralForeground2);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-right: 20px;
`;

const Treshold = styled.div`
  display: flex;
  color: var(--colorNeutralForeground2);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-right: 20px;
`;

const SurveyListTop = styled.div`
  display: flex;
  align-items: center;
`;

const EditSettings = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.$disabled
      ? "var(--colorNeutralForeground5, #959ba2)"
      : "var(--colorBrandForeground1, #1477F8)"};
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  cursor: pointer;
  font-weight: 500;
  line-height: 20px;
  margin-right: 20px;
`;

const BackToProjects = styled.a`
  color: var(--colorNeutralForeground2);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    text-decoration-line: underline;
  }
`;

const Header = styled.div`
  color: var(--colorNeutralForeground1);
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;

const TextFieldStyled = styled(TextField)`
  width: 300px;
`;

const TypographyStyled = styled(Typography)`
  color: var(--colorNeutralForeground2);
  margin-left: 20px !important;
`;
