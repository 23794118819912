import { FC } from "react";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import styled from "styled-components";
import MtmLookupRoles from "./MtmLookup";
import { Tooltip, tooltipClasses } from "@mui/material";

import RichTextWithTabsAll from "../../RichTextWithTabsAll";
import DateTimePickerValue from "./DateTimeField";
import CustomButton from "components/button/Button";
import CustomSwitch from "components/switch/Switch";
import PreviewIcon from "../../PreviewIcon";
import LetterIcon from "./LetterIcon";
import store from "./../../store";

type FirstTabProps = {};

const FirstTab: FC<FirstTabProps> = observer(() => {
  return (
    <>
      <Wrapped>
        <Header id="Survey_EditCreate_Communication_SendReport_Title">Report</Header>
        <SwitchDiv>
          <CustomSwitch
            checked={store.AllInfo ? store.AllInfo.is_send_report : false}
            onChange={() => {
              store.changeReportIsSendReport(store.AllInfo ? !store.AllInfo.is_send_report : false);
            }}
          />
          <SwitchLabel id="Survey_EditCreate_Communication_SendReport_Label">
            Send a results report via email to:
          </SwitchLabel>
        </SwitchDiv>

        {store.AllInfo && store.AllInfo.is_send_report && (
          <>
            <MtmLookupRoles />
            <SubHeader id="Survey_EditCreate_Communication_SendReport_SendAfter_Title">
              SEND AFTER SURVEY END DATE
            </SubHeader>

            <SendAfter>
              <div>
                <DarkTooltip
                  arrow
                  placement="top"
                  title={
                    !store.survey_date_end && (
                      <StyledWrapperTitle>
                        <p>Launch and end date of the survey needs to be set</p>
                      </StyledWrapperTitle>
                    )
                  }
                  children={
                    <div>
                      <DateTimePickerValue
                        id="Survey_EditCreate_Communication_SendReport_SendAfter_TimeZone_Container"
                        value={store.AllInfo ? store.time_send_report : null}
                        changeValue={(value: any) => {
                          store.changeValue("time_send_report", value);
                          store.changeValue("changedReportTab", true);
                        }}
                        disabled={!store.survey_date_end}
                        minDateTime={store.setMinDateTime()}
                      />
                    </div>
                  }
                />
                <TimeZone id="Survey_EditCreate_Communication_SendReport_SendAfter_TimeZone">
                  Local timezone: UTC {store.timezone_message}
                </TimeZone>
              </div>
            </SendAfter>

            <SubHeader id="Survey_EditCreate_Communication_SendReport_EmailSubject_Title">
              EMAIL SUBJECT
            </SubHeader>

            <RichTextWithTabsAll
              editorId="Survey_EditCreate_Communication_SendReport_EmailSubject"
              onlyText
              data={store.ReportData}
              width={100}
              changeValue={store.changeReportData}
              field="subject_email"
            />

            <SubHeader id="Survey_EditCreate_Communication_SendReport_EmailContent_Title">
              EMAIL CONTENT
            </SubHeader>

            <RichTextWithTabsAll
              editorId="Survey_EditCreate_Communication_SendReport_EmailContent"
              data={store.ReportData}
              width={200}
              changeValue={store.changeReportData}
              field="body_email"
            />
          </>
        )}

        <BottomWrapper>
          <ButtonWrapper>
            <ButtonWr>
              <StyledNextButton
                id="Survey_EditCreate_Communication_SendReport_Save_Button"
                variant="contained"
                disabled={!store.changedReportTab}
                onClick={() => store.saveReportData()}
              >
                Save changes
              </StyledNextButton>
            </ButtonWr>
            <ButtonWr>
              <StyledCancelButton
                id="Survey_EditCreate_Communication_SendReport_Cancel_Button"
                variant="outlined"
                disabled={!store.changedReportTab}
                onClick={() => store.onCancelReportTab()}
              >
                Cancel
              </StyledCancelButton>
            </ButtonWr>
          </ButtonWrapper>

          <ButtonWrapper>
            <LinkPreview
              id="Survey_EditCreate_Communication_SendReport_PreviewEmail_Button"
              onClick={() => store.loadPreviewMessage(null, true)}
            >
              <PreviewIcon />
              <LinkPreviewText id="Survey_EditCreate_Communication_SendReport_PreviewEmail_Button_Text">
                Preview email
              </LinkPreviewText>
            </LinkPreview>

            {store.time_send_report && store.time_send_report.toDate() < dayjs().toDate() && (
              <ResendCustomButton
                id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Resend_Button"
                onClick={() => store.setData("isOpenResendEmailPoup", true)}
                variant="outlined"
              >
                <LetterIcon /> Resend email
              </ResendCustomButton>
            )}
          </ButtonWrapper>
        </BottomWrapper>
      </Wrapped>
    </>
  );
});

const ResendCustomButton = styled(CustomButton)`
  height: 36px;
  width: 180px;
  max-width: 190px !important;
  margin-left: 25px !important;

  :first-child {
    margin-right: 11px;
  }
`;

const Wrapped = styled.div`
  margin: 10px 30px;
`;

const StyledWrapperTitle = styled.div`
  width: 147.5px;
  padding: 5px;

  > p {
    color: var(--colorNeutralBackground1);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: var(--colorNeutralForeground1);
`;

const BottomWrapper = styled.div`
  width: 100%;
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LinkPreviewText = styled.span`
  margin-left: 3px;
`;

const LinkPreview = styled.a`
  width: 108px;
  color: var(--colorBrandForeground1);
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 30px;
`;

const SendAfter = styled.div`
  display: flex;
  padding-top: 10px;
`;

const TimeZone = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: var(--colorNeutralForeground5);
`;

const ButtonWr = styled.div`
  margin-right: 20px;
`;

const StyledCancelButton = styled(CustomButton)`
  width: 100%;
  max-width: 75px;
  margin-left: 160px;
`;

const StyledNextButton = styled(CustomButton)`
  width: 100%;
  margin-right: 20px;
  max-width: 150px;
`;

const SubHeader = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 10px;
  margin-top: 30px;
  color: var(--colorNeutralForeground2);
`;

const SwitchLabel = styled.p`
  margin-left: 6px;
`;

const DarkTooltip = styled(Tooltip)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--colorTooltipBackground)",
    borderRadius: 0,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "var(--colorTooltipBackground)",
  },
}));

export default FirstTab;
