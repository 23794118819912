import { makeAutoObservable } from "mobx";
import { changeRoleToUsers, changeRoleFromRoleId } from "../../../../api/go/useChangeRoleToUsers";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { RoleUser } from "types/permission";
import MainStore from "MainStore";
import { ACCESS_LEVEL_TYPE } from "constants/access-level-type";

class Store {
  roles = [];
  new_idRole = 0;
  new_idRoleName = "";
  errornew_new_idRole = "";

  roleName = "";

  constructor() {
    makeAutoObservable(this);
  }

  changeValue = (name: string, value: any) => {
    if (name == "new_idRole") {
      if (value === 0 || value == null) {
        this.errornew_new_idRole = "Value cannot be blank";
      } else {
        this.errornew_new_idRole = "";
      }
    }
    this[name] = value;
  };

  clearStore() {
    this.roles = [];
    this.new_idRole = 0;
    this.new_idRoleName = "";
    this.errornew_new_idRole = "";
    this.roleName = "";
  }

  setRoles(roles: RoleUser[], idRole: number) {
    this.roles = roles.filter((x) => x.id !== idRole);
    var role = roles.filter((x) => x.id === idRole);
    if (role.length !== 0) {
      this.roleName = role[0].roleName;
    }
  }

  async changeRoleToUsers(
    userIds: number[],
    oldRoleId: number,
    countUsers: number,
    userEmail: string,
    roleName: string,
    onSaved: (x: number) => void
  ) {
    try {
      MainStore.changeLoader(true);
      const response = await changeRoleToUsers(
        userIds,
        this.new_idRole,
        oldRoleId,
        StoreLayout.currentWorkspaceId,
        ACCESS_LEVEL_TYPE.workspace
      );
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        if (countUsers === 1) {
          MainStore.setSnackbar(
            `"${userEmail}" has been removed from "${roleName}" and added to "${this.new_idRoleName}".`
          );
        } else {
          MainStore.setSnackbar(
            `${countUsers} users have been removed from "${roleName}" and added to "${this.new_idRoleName}".`
          );
        }
        onSaved(response.data);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar(`Something went wrong...`, "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  async changeRoleFromRoleId(oldRoleId: number, roleName: string, onSaved: (x: number) => void) {
    try {
      MainStore.changeLoader(true);
      const response = await changeRoleFromRoleId(
        oldRoleId,
        this.new_idRole,
        StoreLayout.currentWorkspaceId,
        ACCESS_LEVEL_TYPE.workspace
      );
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        if (response.data === 1) {
          MainStore.setSnackbar(
            `${response.data} user have been removed from "${roleName}" and added to "${this.new_idRoleName}".`
          );
        } else {
          MainStore.setSnackbar(
            `${response.data} users have been removed from "${roleName}" and added to "${this.new_idRoleName}".`
          );
        }
        onSaved(response.data);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar(`Something went wrong...`, "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }
}

const store = new Store();
export default store;
