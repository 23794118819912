import { FC } from "react";
import styled from "styled-components";
import workSettingsIcon from "../../assets/images/work_settings.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

type SelectWorkspaceProps = {};

export const WORKSPACES_SIDEBAR = [
  {
    email: "y.lang@consultancy.co",
    id: 1,
    wokspaces: [
      {
        name: "EM Services",
        id: 1,
        logo: "/assets/em-service-logo.png",
        entity: [
          {
            name: "Singapore",
            id: 1,
          },
        ],
      },
      {
        name: "Love, Bonito",
        id: 2,
        logo: "/assets/love-bonito-logo.png",
        entity: [
          {
            name: "Singapore 2",
            id: 2,
          },
          {
            name: "Singapore 3",
            id: 3,
          },
        ],
      },
      {
        name: "Shopback",
        id: 3,
        logo: "/assets/shop-back-logo.png",
        entity: [
          {
            name: "Singapore 4",
            id: 4,
          },
          {
            name: "Singapore 5",
            id: 5,
          },
        ],
      },
      {
        name: "Grab",
        id: 4,
        logo: "/assets/grab-logo.png",
        entity: [
          {
            name: "Singapore 6",
            id: 6,
          },
        ],
      },
    ],
  },

  {
    email: "yves.lang@widgets.co",
    id: 2,
    wokspaces: [
      {
        name: "EM Services",
        id: 1,
        logo: "/assets/em-service-logo.png",
        entity: [
          {
            name: "Singapore",
            id: 1,
          },
        ],
      },
      {
        name: "Love, Bonito",
        id: 2,
        logo: "/assets/love-bonito-logo.png",
        entity: [
          {
            name: "Singapore 2",
            id: 2,
          },
          {
            name: "Singapore 3",
            id: 3,
          },
        ],
      },
    ],
  },
];

export type Entity = {
  name: string;
  id: number;
}

export type Workflow = {
  name: string;
  id: number;
  logo?: File;
  entity: Entity[]
};


const SelectWorkspaceScreen: FC<SelectWorkspaceProps> = () => {
  return (
    <SignedInPageWrapper>
      <Content>
        <AccountsList>
          {WORKSPACES_SIDEBAR.map((account) => (
            <AccountContainer key={account.email}>
              <AccountContainerHeader>{account.email}</AccountContainerHeader>
              <CompanyList>
                {account.wokspaces.map((workspace) => (
                  <AccountContainerCompany key={workspace.id}>
                    {/* <AccountContainerCompanyLeft>
                      <LogoWorkspace src={workspaceLogo} alt={"company-logo"} />
                    </AccountContainerCompanyLeft> */}
                    <AccountContainerCompanyRight aria-label={workspace.name}>
                      <CompanyName>{workspace.name}</CompanyName>
                      <WorkSpaceList>
                        <a key={workspace.id} href="#">
                          <WorkSpaceItem>
                            <span>
                              <p>Workspace</p>
                              <Icon src={workSettingsIcon} alt={"workSettingsIcon"} />
                            </span>
                            <ArrowForwardIcon />
                          </WorkSpaceItem>
                          {workspace.entity.map((entity) => (
                            <WorkSpaceItem key={entity.id}>
                              <span>
                                <p>{entity.name}</p>
                              </span>
                              <ArrowForwardIcon />
                            </WorkSpaceItem>
                          ))}
                        </a>
                      </WorkSpaceList>
                    </AccountContainerCompanyRight>
                  </AccountContainerCompany>
                ))}
              </CompanyList>
            </AccountContainer>
          ))}
        </AccountsList>
      </Content>
    </SignedInPageWrapper>
  );
};

export default SelectWorkspaceScreen;

const SignedInPageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 80px;
  display: flex;
  justify-content: center;
  background-color: var(--colorNeutralBackground4);

  @media screen and (max-width: 768px) {
    padding: 80px 20px;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 508px;
  display: flex;
  flex-direction: column;
`;


const AccountsList = styled.div`
  margin-top: 28px;
`;

const AccountContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid var(--graySelectBoxBorder);
  margin-top: 16px;
`;

const AccountContainerHeader = styled.span`
  width: 100%;
  padding: 10px 24px;
  background-color: var(--colorPaletteVioletBackground1);
  color: var(--colorNeutralBackground1);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

const CompanyList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const AccountContainerCompany = styled.div`
  width: 100%;
  display: flex;
  padding: 24px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;


const AccountContainerCompanyRight = styled.div`
  width: 100%;
  max-width: 356px;
  padding: 40px 32px;
  background-color: var(--colorNeutralBackground1);
`;

const CompanyName = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const WorkSpaceList = styled.div`
  margin-top: 24px;
`;

const WorkSpaceItem = styled.div`
  width: 100%;
  padding: 18px 16px;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px var(--colorNeutralBackground3);
  margin-top: 8px;
  background-color: var(--colorNeutralBackground1);
  cursor: pointer;
  transition: all 0.3s;

  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    margin-left: 8px;
  }

  &:hover {
    background-color: var(--colorPalleteLightBlue);
  }

  svg {
    color: var(--colorBrandForeground1);
  }

  span {
    display: flex;
    align-items: center;

    p {
      margin: 0px;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
  }
`;

const Icon = styled.img<{ src: string }>`
  width: 18px;
  height: 18px;
  margin-left: 10px;
`;

