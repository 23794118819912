import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
margin: 10px 10px 0px 0px;
`; 

export const ExcelIcon = (
  props: React.HTMLAttributes<HTMLOrSVGElement>
) => (
  <StyledSvg
    width="20"
    height="20"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M2.97225 0.96875V4.58165H0.391602V13.7688H2.97225V16.9688H16.3916V0.96875H2.97225ZM2.97225 7.1623H4.33999L5.03676 8.19456L5.73354 7.1623H7.10128L5.73354 9.22681L7.10128 11.2913H5.73354L5.03676 10.2591L4.33999 11.2913H2.97225L4.33999 9.22681L2.97225 7.1623ZM3.5916 16.1687H15.5916V1.76875H3.5916V4.53798H9.9916V5.64567H14.0643V6.75337H9.9916V7.86106H14.0643V8.96875H9.9916V10.0764H14.0643V11.1841H9.9916V13.7072H3.5916V16.1687Z" fill="#3E4450"/>
    {/* <path
      d="M7.0469 11.9774C7.05471 9.19281 9.29422 6.98388 12.1014 6.99221C14.8362 7.00002 17.0539 9.26307 17.0471 12.0388C17.0403 14.7843 14.7711 16.9985 11.9764 16.9855C9.26454 16.973 7.03908 14.7109 7.0469 11.9774Z"
      fill="white"
    />
    <circle cx="12" cy="12" r="3" fill="#005DEA" /> */}
  </StyledSvg>
);
