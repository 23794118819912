import React, { FC } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { DialogContent, DialogTitle, TextField } from "@mui/material";

import CustomButton from "components/button/Button";
import StoreLayout from "./../workspaces-sidebar/StoreLayout";

type editModalPropsType = {};

const EditModal: FC<editModalPropsType> = observer((props) => {
  const proceedVerification =
    !StoreLayout.currentUserPreferredName || !StoreLayout.currentUserFullNameEdit;

  return (
    <StyledModalContent aria-label={"Create workspace form"}>
      <StyledDialogTitle id="WelcomePopUP_Title">Welcome to EngageRocket!</StyledDialogTitle>
      <p id="WelcomePopUP_Subtitle">👋🏻 Before we proceed, can we get your name?</p>
      <StyledFieldContainer>
        <TextField
          name="fullname"
          id="WelcomePopUp_Fullname_Input"
          placeholder="Enter your full name"
          fullWidth
          label="Your full name"
          variant="standard"
          type="text"
          value={StoreLayout.currentUserFullNameEdit}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            StoreLayout.changeFullName(e.target.value)
          }
        />

        <TextField
          name="preffererdname"
          id="WelcomePopUp_PereferedName_Input"
          placeholder="Enter  your preffered name"
          fullWidth
          label="Your preferred name"
          variant="standard"
          type="text"
          value={StoreLayout.currentUserPreferredName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            StoreLayout.changePreferredName(e.target.value)
          }
        />
      </StyledFieldContainer>

      <ButtonWrapper>
        <StyledNextButton
          id="WelcomePopUp_Proceed"
          variant="contained"
          disabled={proceedVerification}
          onClick={() => StoreLayout.saveEntity()}
        >
          Proceed
        </StyledNextButton>
      </ButtonWrapper>
    </StyledModalContent>
  );
});

export default EditModal;

const StyledModalContent = styled(DialogContent)`
  padding: 44px 50px !important;
  width: auto !important;
`;

const StyledDialogTitle = styled(DialogTitle)`
  padding: 0 !important;
  font-size: 28px !important;
  color: var(--colorNeutralForeground1);
`;

const StyledFieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 48px 0;
`;

const StyledNextButton = styled(CustomButton)`
  width: 100%;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
