import { makeAutoObservable } from "mobx";
import { getAttributesByEntity } from "api/go/useGetAttributesByEntity";
import { deleteAttribute } from "api/go/useDeleteAttribute";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import storeSegments from "./storeSegments";
import MainStore from "MainStore";
import { EmployeeAttribute } from "types/employee";
import storeAddEditAttribute from "features/Employees/AttributeList/AddEditAttribute/store";

class Store {
  data: EmployeeAttribute[] = [];
  selected: EmployeeAttribute = null;
  openPanel: boolean = false;
  isEditing: boolean = false;
  deleteValue: string = "";
  errorTextDelete: string = "";
  deleteFormPopup: boolean = false;
  deleteSegmentsPopup: boolean = false;
  addFooter: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  changeFooterAdd = (flag: boolean) => {
    this.addFooter = flag
  }

  setOpenPanel(boolean: boolean) {
    this.openPanel = boolean;
  }

  changeDeletePopup = (open: boolean) => {
    this.deleteFormPopup = open;
    this.deleteValue = "";
    this.errorTextDelete = "";
  };

  changeValue = (field: string, value: any) => {
    if (field === "deleteValue") {
      if (value !== "DELETE") {
        this.changeValue("errorTextDelete", "Please type in the exact letters");
      } else {
        this.changeValue("errorTextDelete", "");
      }
    }
    this[field] = value;
  };

  updateData(updatedData: any[]) {
    this.data = updatedData;
  }

  changeSelected = (value: any) => {
    if(value.length === 0){
      this.selected = null
    }else{
      this.selected = this.data.find(x => x.id === value[0])
    }
    if(value === null) return
    storeSegments.setAttribute(this.selected);
    storeSegments.loadSegments();
  };

  setAttributes = (data: EmployeeAttribute[]) => {
    data.forEach(x => {
      if(x.is_unit_based){
        x.id = x.id * -1
      }
    })
    this.data = data
  }

  async loadData() {
    try {
      MainStore.changeLoader(true);
      const response = await getAttributesByEntity(StoreLayout.currentEntityId);
      if ((response.status === 201 || response.status === 200) && response?.data !== undefined) {
        this.setAttributes(response.data)
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  deleteClicked = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await deleteAttribute(this.selected?.id);
      if (response.status === 201 || response.status === 200) {
        MainStore.setSnackbar("Attribute successfully deleted");
        storeSegments.clearStore();
        this.changeSelected([]);
        this.changeDeletePopup(false);
        this.loadData();
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Failed to delete", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  clearHandler = () => {
    store.changeFooterAdd(false);
    store.setOpenPanel(false);
    storeAddEditAttribute.clearStore();
    storeSegments.clearEditSegmentValue();
  };

}

const store = new Store();
export default store;
