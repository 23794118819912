import http from "api/https";

export const shareTemplate = (entity_id: number, template_id: number, type: string, curr_entity_id: number): Promise<any> => {
  let data = {
    entity_id: entity_id,
    template_id: template_id,
    type: type,
    curr_entity_id: curr_entity_id
  }
  if(http.isAPIGO){
    return http.postGO("/template/share_template", data);
  } else {
    return http.post("/templ_template_questionary/ShareTemplate", data);
  }
};
