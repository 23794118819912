import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { getEmployeesByEntityAndManager } from 'api/go/useGetEmployeesByEntityAndManager';
import { GetEmployeesByEntityType } from 'api/go/useGetEmployeesByEntity';
import useDebounce from 'utils/useDebounse';
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { observer } from "mobx-react";
import store from "./store";
import MainStore from 'MainStore';

interface AsynchronousSearchType {
  addSelectedEmployees: (GetEmployeesByEntityType: GetEmployeesByEntityType) => void;
}

const Asynchronous = observer((props: AsynchronousSearchType) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<GetEmployeesByEntityType[]>([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [keySearch, setKeySearch] = React.useState(new Date());

  const debouncedValue = useDebounce(searchValue, 600);
  const loadEmployees = async () => {
    try {
      const response = await getEmployeesByEntityAndManager(StoreLayout.currentEntityId, searchValue, store.id);
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        setOptions(response.data);
        setLoading(false);
      } else throw new Error();
    } catch {
      MainStore.setSnackbar("Something went wrong", "error");
    }
  }

  React.useEffect(() => {
    if (searchValue == null || searchValue === "") {
      setOptions([]);
      return;
    }
    setLoading(true);

    loadEmployees()
  }, [debouncedValue]);


  return (
    <Autocomplete
      id="EmployeeList_AddEditPopUpisUnitHead-CheckboxIsManager_SearchInputAsynchronousDemo"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      // isOptionEqualToValue={(option, value) => option.full_name === value.full_name}
      getOptionLabel={(option) => option.full_name + " " + option.email}
      renderOption={(props, option, state, ownerState) => (
        <Box
          sx={{
            borderRadius: '8px',
            margin: '5px',
          }}
          component="li"
          {...props}
        >
          {option.full_name} {option.preffered_name && ("(" + option.preffered_name + ")")}<br />
          {option.email}
        </Box>
      )}
      fullWidth
      value={null}
      blurOnSelect={true}
      filterSelectedOptions
      inputValue={searchValue}
      onInputChange={(event, newInputValue) => {
        setSearchValue(newInputValue)
        // store.searchValue = newInputValue;
      }}
      key={keySearch.toISOString()}
      onChange={(e, i) => {
        setOptions([]);
        setSearchValue("");
        // store.searchOptions = [];
        // store.searchValue = "";
        setKeySearch(new Date());
        // store.keySearch = new Date();
        if (i == null) return
        props.addSelectedEmployees(i!)
      }}
      clearOnEscape={true}
      options={options}
      loading={loading}
      noOptionsText={"Email address not found on employee list"}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Employees under this manager"
          variant="standard"
          // value={store.searchValue}
          // onChange={(e) => { store.searchValue = e.target.value; }}
          fullWidth
          error={store.selectedEmployees?.length === 0}
          placeholder="Search for employee's email address"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
})

export default Asynchronous;