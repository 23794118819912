import CustomButton from "components/button/Button";
import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import storeComm from "features/Templates/QuestionnaireTemplates/store";
import QuestionModal from "features/QuestionTemplate/QuestionModal";
import CustomModal from "components/modal/Modal";
import SettingsIcon from "@mui/icons-material/Settings";
import { Divider } from "@mui/material";

type HeaderMenuProps = {};

const HeaderMenu: FC<HeaderMenuProps> = observer((props) => {
  return (
    <>
      <HeaderMenuWrapper id="Template_Questionnaire_Header_Menu">
        <LinksWrapper>
          <CustomModal
            id={"Template_Questionnaire_Settings"}
            open={storeComm.openModalTags}
            hide={(flag) => storeComm.setModalOpen(flag)}
          >
            <QuestionModal />
          </CustomModal>

          <ItemMenu
            id="Template_Questionnaire_Header_Menu_Questionnaire"
            $active={storeComm.activePage === "edit"}
            onClick={() => storeComm.changeActivePage("edit")}
          >
            Questionnaire
          </ItemMenu>
        </LinksWrapper>
        <ButtonsWrapper>
          <ButtonWrapper>
            <TemplatesLibraryButton
              variant="outlined"
              id="Template_Questionnaire_Header_Menu_Templates_Button"
              onClick={() => storeComm.changeSelectTemplate(true)}
            >
              Templates and library
            </TemplatesLibraryButton>
          </ButtonWrapper>
          <DividerStyle orientation="vertical" flexItem />
          <ButtonWrapper>
            <SettingsButton
              variant="outlined"
              id="Template_Questionnaire_Header_Menu_Survey_Settings_Button"
              onClick={() => storeComm.setModalOpen(true)}
            >
              <SettingsIcon />
              Survey settings
            </SettingsButton>
          </ButtonWrapper>
        </ButtonsWrapper>
      </HeaderMenuWrapper>
    </>
  );
});

export default HeaderMenu;

const HeaderMenuWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--colorNeutralBackground1);
  padding: 0 41px 0 80px;
  position: absolute;
  box-shadow: -1px 2px 10px rgb(0 0 0 / 0.2);
  z-index: 200;
`;

const LinksWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  background-color: var(--colorNeutralBackground1);
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--colorNeutralBackground1);
`;

const ButtonWrapper = styled.div`
  margin: 10px;
`;

const ItemMenu = styled.li<{ $active?: boolean }>`
  list-style-type: none;
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: ${(props) =>
    props.$active ? "var(--colorBrandForeground1)" : "var(--colorPaletteVioletBackground1)"};
  width: fit-content;
  border-bottom: ${(props) => props.$active && `4px solid var(--colorBrandForeground1)`};
  height: 100%;
  padding-top: ${(props) => (props.$active ? "4px" : "0px")};
`;

const SettingsButton = styled(CustomButton)`
  width: 180px;

  svg {
    margin-right: 5px;
  }
`;

const DividerStyle = styled(Divider)`
  margin: 11px 10px !important;
`;

const TemplatesLibraryButton = styled(CustomButton)`
  width: 180px;
`;
