import { makeAutoObservable, runInAction } from "mobx";
import { GetCommunicationTemplateInformation } from "api/go/useGetCommunicationTemplateInformation";
import { GetAllFooterLanguageMessages } from "api/go/useGetAllFooterLanguageMessages";
import { GetPreviewTemplate } from "api/go/useGetPreviewTemplate";
import { GetAllInviteLanguageMessages } from "api/go/useGetAllInviteLanguageMessages";
import { GetAllReportLanguageMessages } from "api/go/useGetAllReportLanguageMessages";
import { GetAllReminderLanguageMessages } from "api/go/useGetAllReminderLanguageMessages";
import { GetTemplReminderDays } from "api/go/useGetTemplReminderDays";
import { GetTemplReminderRecipientGroup } from "api/go/useGetTemplReminderRecipientGroup";
import { SaveFooterTemplateMessages } from "api/go/useSaveFooterTemplateMessages";
import { SaveInviteTemplateMessages } from "api/go/useSaveInviteTemplateMessages";
import { SaveReportTemplateMessages } from "api/go/useSaveReportTemplateMessages";
import { SaveTemplateReminderMessages } from "api/go/useSaveTemplateReminderMessages";
import { SaveAccessesForTemplate } from "api/go/useSaveAccessesForTemplate";
import { ChangeAccessCommTemplate } from "api/useChangeAccessCommTemplate";
import { RemoveReminderTemplate } from "api/go/useRemoveReminderTemplate";
import { AddReminderTemplateEmail } from "api/go/useAddReminderTemplateEmail";
import {
  Access,
  AllInfoCommunicationTemplate,
  FooterTemplateLanguage,
  ReminderDays,
  ReminderGroups,
  ReminderMessage,
  TemplateLanguage,
} from "types/templates";
import MainStore from "MainStore";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { addTemplate } from "api/go/useAddTemplate";
import { copyCommsTemplateToTemplate } from "api/go/useCopyCommsTemplateToTemplate";
import { getTimeZone } from "utils/timeFormatter";
import dayjs from "dayjs";

class Store {
  idTemplate = 0;
  surveyAccessArray: Access[] = [];
  FooterData: FooterTemplateLanguage[] = [];
  InviteData: TemplateLanguage[] = [];
  ReminderData: ReminderMessage[] = [];
  ReportData: TemplateLanguage[] = [];
  AllInfo: AllInfoCommunicationTemplate = null;
  ReminderGroups: ReminderGroups[] = [];
  ReminderDays: ReminderDays[] = [];
  file = null;
  remove_file: boolean = false;
  openReminderIndex: null | number = null;
  reportDays_id = 0;
  is_send_report: boolean = false;

  openPreviewEmail: boolean = false;
  previewLastLanguageId: number = null;
  previewEmailText: string = "";
  timezone_message: string = getTimeZone();

  accessed_changed: boolean = false;
  changedFirstTab: boolean = false;
  changedSecondTab: boolean = false;
  changedReportTab: boolean = false;
  changedReminderTab: boolean = false;

  openCommsModal: boolean = false;
  openSelectTemplate: boolean = false;

  expandedTab: string | boolean;

  valueTabSecondAccordion: number = 0

  constructor() {
    makeAutoObservable(this);
  }

  get checkLeaveHandler() {
    return Boolean(
      this.accessed_changed ||
        this.changedFirstTab ||
        this.changedSecondTab ||
        this.changedReportTab ||
        this.changedReminderTab
    );
  }

  toastSuccessSaveTemplate = () => {
    MainStore.setSnackbar(`“${this.AllInfo?.name || 'Comms title template'}” template successfully saved`);
  }

  LoadAllData = (id: number) => {
    if (id === 0) {
      return alert(id);
    }
    this.LoadMainInformation(id);
    this.LoadFooterData(id);
    this.LoadInviteData(id);
    this.LoadReportData(id);
    this.LoadReminderData(id);

    this.LoadReminderDays();
    this.LoadReminderGroups();
  };

  LoadMainInformation = async (id: number) => {
    try {
      MainStore.changeLoader(true);
      const resp = await GetCommunicationTemplateInformation(id);
      MainStore.changeLoader(false);
      runInAction(() => {
        this.setMainInfor(resp.data);
        this.setAccesses(resp.data.accesses);
      });
    } catch (error) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  LoadInviteData = async (id: number) => {
    try {
      MainStore.changeLoader(true);
      const resp = await GetAllInviteLanguageMessages(id);
      MainStore.changeLoader(false);
      runInAction(() => {
        this.setInviteData(resp.data);
      });
    } catch (error) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  setOpenCommsModal = (val: boolean) => {
    this.openCommsModal = val;
  };

  setAllInfoName = (name: string) => {
    this.AllInfo.name = name;
  };

  async copyCommsTemplateToTemplate(idComm: number, callbackOnSaved: (id: number) => void) {
    try {
      MainStore.changeLoader(true);
      const response = await copyCommsTemplateToTemplate(
        idComm,
        this.idTemplate,
        StoreLayout.currentEntityId,
      );
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        callbackOnSaved(response.data);
      };
    } catch (error) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  async addTemplate(callbackOnSaved: (id: number) => void) {
    try {
      MainStore.changeLoader(true);
      const response = await addTemplate(StoreLayout.currentEntityId);
      runInAction(() => {
        callbackOnSaved(response.data);
        this.LoadAllData(response.data);
        this.setTemplateId(response.data);
      });
    } catch (error) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  changeSelectTemplate = (flag: boolean) => {
    this.openSelectTemplate = flag;
  };

  LoadReportData = async (id: number) => {
    try {
      MainStore.changeLoader(true);
      const resp = await GetAllReportLanguageMessages(id);
      MainStore.changeLoader(false);
      runInAction(() => {
        this.setReportData(resp.data);
      });
    } catch (error) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  LoadReminderData = async (id: number) => {
    try {
      MainStore.changeLoader(true);
      const resp = await GetAllReminderLanguageMessages(id);
      MainStore.changeLoader(false);
      runInAction(() => {
        this.setReminderData(resp.data);
        this.openReminderIndex = null;
      });
    } catch (error) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  LoadFooterData = async (id: number) => {
    try {
      MainStore.changeLoader(true);
      const resp = await GetAllFooterLanguageMessages(id);
      MainStore.changeLoader(false);
      runInAction(() => {
        this.setFooterData(resp.data);
      });
    } catch (error) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  LoadReminderDays = async () => {
    try {
      MainStore.changeLoader(true);
      const resp = await GetTemplReminderDays();
      MainStore.changeLoader(false);
      runInAction(() => {
        this.setReminderDays(resp.data);
      });
    } catch (error) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  LoadReminderGroups = async () => {
    try {
      MainStore.changeLoader(true);
      const resp = await GetTemplReminderRecipientGroup();
      MainStore.changeLoader(false);
      runInAction(() => {
        this.setReminderGroups(resp.data);
      });
    } catch (error) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  setMainInfor = (data: AllInfoCommunicationTemplate) => {
    this.AllInfo = data;
  };
  setAccesses = (data: Access[]) => {
    this.surveyAccessArray = data;
  };
  setFooterData = (data: FooterTemplateLanguage[]) => {
    this.FooterData = data;
  };
  setInviteData = (data: TemplateLanguage[]) => {
    this.InviteData = data;
  };
  setReminderData = (data: ReminderMessage[]) => {
    this.ReminderData = data;
  };
  setReportData = (data: TemplateLanguage[]) => {
    this.ReportData = data;
  };
  setReminderDays = (data: ReminderDays[]) => {
    this.ReminderDays = data;
  };
  setReminderGroups = (data: ReminderGroups[]) => {
    this.ReminderGroups = data;
  };
  setTemplateId = (id: number) => {
    this.idTemplate = id;
  };

  SaveAccessesClicked = async () => {
    try {
      MainStore.changeLoader(true);
      await SaveAccessesForTemplate(
        this.surveyAccessArray.filter((el) => el.valueChecked),
        this.idTemplate
      );
      MainStore.changeLoader(false);
      runInAction(() => {
        this.accessed_changed = false;
        this.toastSuccessSaveTemplate();
        this.LoadMainInformation(this.idTemplate);
        this.expandedTab = "panel2";
      });
    } catch (error) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  SaveFooterData = async () => {
    try {
      MainStore.changeLoader(true);
      await SaveFooterTemplateMessages(
        this.FooterData,
        this.idTemplate,
        this.file,
        this.remove_file,
      );
      MainStore.changeLoader(false);
      runInAction(() => {
        this.toastSuccessSaveTemplate();
        this.LoadFooterData(this.idTemplate);
        this.LoadMainInformation(this.idTemplate);
        this.changedFirstTab = false;
        this.remove_file = false;
        this.valueTabSecondAccordion = 1
      });
    } catch (error) {
      MainStore.setSnackbar("Email settings not saved!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  onCancelFirstTab() {
    this.LoadFooterData(this.idTemplate);
    this.LoadMainInformation(this.idTemplate);
    this.changedFirstTab = false;
    this.remove_file = false;
  }

  onCancelSecondTab() {
    this.LoadInviteData(this.idTemplate);
    this.changedFirstTab = false;
  }

  onCancelReportTab() {
    this.LoadReportData(this.idTemplate);
    this.LoadMainInformation(this.idTemplate);
    this.changedReportTab = false;
  }

  SaveInviteData = async () => {
    try {
      MainStore.changeLoader(true);
      await SaveInviteTemplateMessages(this.InviteData);
      MainStore.changeLoader(false);
      runInAction(() => {
        this.toastSuccessSaveTemplate();
        this.LoadInviteData(this.idTemplate);
        if (store.AllInfo.emails_completed === false) {
          store.AllInfo.emails_completed = true;
        }
        this.changedSecondTab = false;
        this.valueTabSecondAccordion = 2;
      });
    } catch (error) {
      MainStore.setSnackbar("The invitation letter was not saved!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  SaveReminderData = async () => {
    try {
      MainStore.changeLoader(true);
      await SaveTemplateReminderMessages({
        ...this.ReminderData[this.openReminderIndex],
        time_send_reminder: dayjs(
          this.ReminderData[this.openReminderIndex].time_send_reminder
        ).toISOString(),
      });
      MainStore.changeLoader(false);
      this.changedReminderTab = false;
      runInAction(() => {
        this.toastSuccessSaveTemplate();
        this.LoadReminderData(this.idTemplate);
        this.openReminderIndex = null;
        this.expandedTab = "panel3";
      });
    } catch (error) {
      MainStore.setSnackbar("Reminder not saved!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  SaveReportData = async () => {
    try {
      MainStore.changeLoader(true);
      await SaveReportTemplateMessages(
        this.ReportData,
        this.AllInfo.is_send_report,
        this.idTemplate,
        dayjs(this.AllInfo.time_send_report).toISOString(),
        this.AllInfo.reminder_days_id === null
          ? this.ReminderDays.length > 0
            ? this.ReminderDays[0].id
            : null
          : this.AllInfo.reminder_days_id,
      );
      MainStore.changeLoader(false);
      runInAction(() => {
        this.toastSuccessSaveTemplate();
        this.LoadReportData(this.idTemplate);
        this.LoadMainInformation(this.idTemplate);
        if (store.AllInfo.report_completed === false) {
          store.AllInfo.report_completed = true;
        }
        this.changedReportTab = false;
        this.expandedTab = false;
      });
    } catch (error) {
      MainStore.setSnackbar("Report not saved!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  changeValue = (field: string, value: any) => {
    this[field] = value;
  };

  checkedChange = (id: number, value: any, access_code: string) => {
    const updatedSurveyAccessArray = this.surveyAccessArray.map((el) => {
      if (el.id === id) {
        return { ...el, valueChecked: value };
      }
      return el;
    });
    this.surveyAccessArray = updatedSurveyAccessArray;
    // this.changeAccessCommTemplate(access_code);
    this.accessed_changed = true;

    let count_active = this.surveyAccessArray.filter((resp) => resp.valueChecked === true).length;
    if (this.AllInfo !== null) {
      if (count_active === 0) {
        this.AllInfo.accesses_completed = false;
      } else {
        this.AllInfo.accesses_completed = true;
      }
    }
  };

  changeAccessCommTemplate = (access_code: string) => {
    ChangeAccessCommTemplate(access_code, this.idTemplate);
  };

  changeFooterData = (value: string, index: number) => {
    this.changedFirstTab = true;
    this.FooterData[index].footer_email = value;
  };
  changeInviteData = (value: string, field: string, index: number) => {
    this.changedSecondTab = true;
    this.InviteData[index][field] = value;
  };
  changeReminderData = (value: string, field: string, index: number, indexLanguage: number) => {
    this.ReminderData[index].reminder_message_languages[indexLanguage][field] = value;
    this.changedReminderTab = true;
  };
  changeReportData = (value: string, field: string, index: number) => {
    this.changedReportTab = true;
    this.ReportData[index][field] = value;
  };
  changeReminderGroups = (value: number, index: number) => {
    this.ReminderData[index].reminder_recipients_group_id = value;
    this.changedReminderTab = true;
  };
  changeReminderDays = (value: number, index: number) => {
    this.ReminderData[index].reminder_days_id = value;
    this.changedReminderTab = true;
  };
  changeReminderTime = (value: any, index: number) => {
    this.ReminderData[index].time_send_reminder = value;
    this.changedReminderTab = true;
  };

  changeReportTime = (value: any) => {
    this.AllInfo.time_send_report = value;
  };
  changeReportDays = (value: number) => {
    this.changedReportTab = true;
    this.AllInfo.reminder_days_id = value;
  };
  changeReportIsSendReport = (value: boolean) => {
    this.changedReportTab = true;
    this.AllInfo.is_send_report = value;
  };

  AddNewReminder = async () => {
    try {
      MainStore.changeLoader(true);
      await AddReminderTemplateEmail(this.idTemplate);
      MainStore.changeLoader(false);
      runInAction(async () => {
        MainStore.setSnackbar("Added new reminder!");
        await this.LoadReminderData(this.idTemplate);
        this.openReminderIndex = this.ReminderData.length - 1;
      });
    } catch (error) {
      MainStore.setSnackbar("No new reminder added!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  DeleteReminderSubmit = async (index: number) => {
    try {
      MainStore.openErrorConfirm(
        `Are you sure you want to delete "Reminder email ${index + 1}"?`,
        "",
        "Delete template",
        "Cancel",
        async () => {
          MainStore.changeLoader(true);
          await RemoveReminderTemplate(this.ReminderData[index].id_comms_reminder);
          MainStore.changeLoader(false);
          runInAction(() => {
            MainStore.setSnackbar("Reminder deleted!");
            this.LoadReminderData(this.idTemplate);
            this.openReminderIndex = null;
          });
          MainStore.onCloseConfirm();
        },
        () => {
          MainStore.onCloseConfirm();
        },
        null,
        null,
        "primary",
        "inherit",
      );
    } catch (error) {
      MainStore.setSnackbar("Reminder not deleted!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  changeOpenReminderIndex = (index: number, flag: boolean) => {
    if (flag === true) {
      this.openReminderIndex = index;
    } else {
      this.openReminderIndex = null;
      this.LoadReminderData(this.idTemplate);
    }
  };

  changeFile(value: any) {
    this.file = value;
    this.changedFirstTab = true;
    // this.AllInfo.header_logo_id = null
  }

  deleteFile() {
    this.file = null;
    this.AllInfo.header_logo = null;
    this.remove_file = true;
    this.changedFirstTab = true;
  }

  changeOpenPreviewEmail(flag: boolean) {
    this.openPreviewEmail = flag;
    if (flag === false) {
    }
  }
  changeLastLanguage(idLanguage: number) {
    this.previewLastLanguageId = idLanguage;
  }

  LoadPreviewMessage = async (idReminder: number, is_report: boolean) => {
    try {
      MainStore.changeLoader(true);
      const resp = await GetPreviewTemplate(
        this.idTemplate,
        this.previewLastLanguageId,
        idReminder,
        is_report,
      );
      MainStore.changeLoader(false);
      runInAction(() => {
        this.previewEmailText = resp.data;
        this.changeOpenPreviewEmail(true);
      });
    } catch (error) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  changeIdTemplate = (id: number) => {
    this.idTemplate = id;
  };

  handleTabChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    isExpanded ? (this.expandedTab = panel) : (this.expandedTab = false);
  };

  handleTabSecondAccordionChange = (event: React.SyntheticEvent, newValue: number) => {
    this.valueTabSecondAccordion = newValue;
  };
}

const store = new Store();
export default store;
