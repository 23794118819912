import { FC } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListSubheader,
  TextField,
} from "@mui/material";
import { getUnitSegmentName } from "utils/dashboard/useGetSegmentName";

import styled from "styled-components";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import CustomButton from "components/button/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ShapeIcon from "./ShapeIcon";
import store from "../../features/Dashboard/store";

type MultipleSelectProps = {
  options?: string[] | { value: string; label: string }[];
  selectedList?: string[] | { value: string; label: string }[];
  onChange?: (
    changeIndex: number,
    field: string,
    value: string | string[] | { value: string; label: string } | { value: string; label: string }[]
  ) => void;
  name?: string;
  index?: number;
  selectedAll?: () => void;
  value?: string;
  placeholder?: string;
};

export const MultipleSelect: FC<MultipleSelectProps> = (props) => {
  const { placeholder = "Select segment" } = props;

  const selectAllHandler = () => {
    if (
      props.options.length > 0 &&
      typeof props.options[0] === "string" &&
      props.selectedList.length !== props.options.length
    ) {
      return props.onChange(props.index, props.name, props.options);
    }

    if (
      props.options.length > 0 &&
      typeof props.options[0] !== "string" &&
      props.selectedList.length !== props.options.length
    ) {
      const list = props.options.map((el) => el?.value);

      return props.onChange(props.index, props.name, list);
    }

    return props.onChange(props.index, props.name, []);
  };

  const displayInputValue = () => {
    if (props.value) return props.value;

    if (props.options.length > 0 && typeof props.options[0] !== "string") {
      const list = props.selectedList.map((el) => {
        return getUnitSegmentName(el, store.filterAttributesList);
      });

      return list.join(", ");
    }
    return props.selectedList.join(", ");
  };

  return (
    <StyledFormControl
      id={!isNaN(props.index) && `EmployeesList_FilterEmployees_SigmentSelect_li_${props.index}`}
    >
      <StyledSelect
        multiple
        value={props.selectedList}
        inputProps={{ id: "lsdvks" }}
        onChange={(e: SelectChangeEvent<typeof props.selectedList>) => {
          props.onChange(
            props.index,
            e.target.name,
            typeof e.target?.value === "string" ? e.target?.value.split(",") : e.target?.value
          );
        }}
        input={
          <OutlinedInput
            id={!isNaN(props.index) && `EmployeesList_FilterEmployees_SigmentSelect_${props.index}`}
            placeholder="Select segment"
          />
        }
        displayEmpty
        name={props.name}
        renderValue={() => {
          if (props.selectedList.length === 0) {
            return (
              <Placeholder
                id={
                  !isNaN(props.index) &&
                  `EmployeesList_FilterEmployees_SigmentSelect_Placeholder_${props.index}`
                }
              >
                {placeholder}
              </Placeholder>
            );
          }

          return displayInputValue();
        }}
        IconComponent={KeyboardArrowDownIcon}
      >
        <StyledListSubheader
          id={
            !isNaN(props.index) && `EmployeesList_FilterEmployees_SigmentSelect_li_${props.index}`
          }
        >
          <Counter
            id={
              !isNaN(props.index) &&
              `EmployeesList_FilterEmployees_SigmentSelect_Counter_${props.index}`
            }
          >
            {props.options.length} in all
          </Counter>
          <AllButton
            id={
              !isNaN(props.index) &&
              `EmployeesList_FilterEmployees_SigmentSelect_AllBtn_${props.index}`
            }
            variant="outlined"
            onClick={selectAllHandler}
          >
            <ShapeIcon />
            <AllText>All</AllText>
          </AllButton>
        </StyledListSubheader>
        <StyledSearchListSubheader>
          <StyledSearchInput
            id={
              !isNaN(props.index) &&
              `EmployeesList_FilterEmployees_SigmentSelect_SearchInput_${props.index}`
            }
            style={{ width: "200px", margin: "5px 5px 5px 12px" }}
            size="small"
            placeholder="Search for segment"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    id={
                      !isNaN(props.index) &&
                      `EmployeesList_FilterEmployees_SigmentSelect_SearchBtn_${props.index}`
                    }
                    onClick={() => { }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={""}
            onChange={(e) => { }}
          />
        </StyledSearchListSubheader>
        {props.options.map((option, index) => (
          <StyledMenuItem
            id={
              !isNaN(props.index) &&
              `EmployeesList_FilterEmployees_SigmentSelect_SearchInput_option_${props.index}_${index}`
            }
            key={typeof option !== "string" ? option?.label + index : option + index}
            value={typeof option !== "string" ? option?.value : option}
          >
            {typeof option !== "string" ? option.label : option}
            <FormControlLabel
              id={
                !isNaN(props.index) &&
                `EmployeesList_FilterEmployees_SigmentSelect_SearchInput_option_label_${props.index}_${index}`
              }
              style={{ marginLeft: "auto", marginRight: "0" }}
              control={
                <StyledCheckBox
                  id={
                    !isNaN(props.index) &&
                    `EmployeesList_FilterEmployees_SigmentSelect_SearchInput_option_Checkbox_${props.index}_${index}`
                  }
                  checked={props.selectedList.includes(
                    typeof option !== "string" ? option?.value : option
                  )}
                />
              }
              label={false}
            />
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
};

const StyledFormControl = styled(FormControl)`
  width: 100%;
  max-width: 225px;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;

  .MuiOutlinedInput-root {
    border-radius: 2px !important;
    height: 34px !important;
    font-family: Roboto !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .MuiOutlinedInput-input {
    padding: 0 32px 0 8px !important;
  }
`;

const StyledCheckBox = styled(Checkbox)`
  padding: 0 !important;
  color: var(--colorBrandForeground1) !important;
`;

const StyledSelect = styled(Select)``;

const StyledMenuItem = styled(MenuItem)`
  border: 1px solid #f1f3f8 !important;
  margin: 10px 10px 0 10px !important;
  border-radius: 4px !important;
  font-family: Roboto, sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: var(--colorNeutralBackgroundInverted4);

  .Mui-selected {
    background-color: var(--colorPaletteBlueBackground1) !important;
  }
`;

const Placeholder = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: var(--colorNeutralForeground9);
  margin: 3px 0 0 0 !important;
`;

const StyledListSubheader = styled(ListSubheader)`
  border-bottom: 1px solid var(--colorNeutralForeground4);
  padding: 11px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSearchListSubheader = styled(ListSubheader)`
  padding: 0 !important;
`;

const AllButton = styled(CustomButton)`
  border: 1px solid var(--colorNeutralForeground6) !important;
  height: 24px !important;
  width: 56px !important;
  color: var(--colorTextAllButton) !important;
  font-size: 12px !important;
  padding: 6px 16px 6px 10px !important;
`;

const AllText = styled.span`
  margin-top: 1px;
`;

const StyledSearchInput = styled(TextField)`
  width: 100% !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  margin: 0 !important;
  border-bottom: 1px solid var(--colorNeutralForeground4) !important;

  div {
    border-radius: 2px !important;
  }

  input {
    padding: 8px 11px !important;
    border-radius: 2px !important;
    font-family: Roboto, sans-serif;
    font-size: 14px;
  }

  fieldset {
    border: none !important;
  }

  svg {
    font-size: 18px;
    color: var(--colorNeutralForeground6);
  }
`;

const Counter = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;
