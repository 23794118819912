import * as React from "react";
import styled from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "components/pagination/Pagination";
import { observer } from "mobx-react";
import { Typography } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import store from "../store";
import MultiSelectTags from "./MultiSelectTags";
import MultiSelectDay from "./MultiSelectDay";

type CustomTableProps = {
  list: { [key: string]: any }[] | [];
  data: ITableData;
  countPerPage?: number;
  sort: string | null;
  hideAddButton: boolean;
  sortType: string | null;
  changeSort: (field: string, type: string) => void;
  id?: string;
};

const SORT_STATUS = {
  disabled: "disabled",
  asc: "asc",
  desc: "desc",
  enabled: "enabled",
};

const CustomTable: React.FC<CustomTableProps> = observer((props) => {
  const [countPerPage, setCountPerPage] = React.useState(
    props.countPerPage ? props.countPerPage : 10
  );
  const [activePage, setActivePage] = React.useState(1);
  const [hoverIndex, setHoverIndex] = React.useState(null);

  const changePageCount = (num: number) => {
    setActivePage(1);
    setCountPerPage(num);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          id={`${props.id || ""}Table`}
        >
          <TableHead sx={{ bgcolor: "#E4E9FF" }} id={`${props.id || ""}TableHead`}>
            <TableRow>
              {props.data.headers
                .filter((x) => !x.not_visible)
                .map((header: TableHeader, i: number) => (
                  <TableCell style={{ position: "relative" }} key={header.value + i}>
                    <HeaderWrapper $search={false} style={{ minHeight: "30px" }}>
                      <Row $search={false}>
                        <Title id={`${props.id || ""}TableRowHead_${header.value}`}>
                          {header.title}
                        </Title>
                        {header.value === "tags" && (
                          <MultiSelectTags
                            list={store.tags}
                            selected_list={store.selected_tags_ids}
                            onChange={(id: number) => store.changeTag(id)}
                          />
                        )}
                        {header.value === "milestone_day" && (
                          <MultiSelectDay
                            list={store.milestone_days}
                            selected_list={store.selected_milestone_days}
                            onChange={(id: number) => store.changeMilestoneDays(id)}
                          />
                        )}
                        {header.sort && (
                          <BlockSortStyle>
                            <KeyboardArrowUpIconStyle
                              id={`${props.id || "" }TableRowSort_asc_${header.value}`}
                              style={
                                props.sort === header.value && props.sortType === SORT_STATUS.asc
                                  ? { color: "var(--colorBrandForeground1)" }
                                  : null
                              }
                              onClick={() => props.changeSort(header.value, "asc")}
                            />
                            <PlugStyle />
                            <KeyboardArrowDownIconStyle
                              id={`${props.id || ""}TableRowSort_desc_ ${header.value}`}
                              style={
                                props.sort === header.value && props.sortType === SORT_STATUS.desc
                                  ? { color: "var(--colorBrandForeground1)" }
                                  : null
                              }
                              onClick={() => props.changeSort(header.value, "desc")}
                            />
                          </BlockSortStyle>
                        )}
                      </Row>
                    </HeaderWrapper>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody id={`${props.id || ""}TableBody`}>
            {props && props?.list?.length > 0 && (
              <>
                {props.list
                  .slice(
                    (activePage - 1) * countPerPage,
                    (activePage - 1) * countPerPage + countPerPage
                  )
                  .map((row: { [key: string]: any }, index) => (
                    <TableRow
                      id={`${props.id || ""}TableRow`}
                      onMouseEnter={() => setHoverIndex(index)}
                      onMouseLeave={() => setHoverIndex(null)}
                      key={row.id}
                    >
                      {props.data.headers
                        .filter((x) => !x.not_visible)
                        .map((el) => (
                          <TableCell component="th" scope="row" key={el.value}>
                            {el.renderRow ? (
                              <TableText>{el.renderRow(row, index === hoverIndex)}</TableText>
                            ) : (
                              <TableText>{row[el.value]}</TableText>
                            )}
                          </TableCell>
                        ))}
                    </TableRow>
                  ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {props && props.list.length <= 0 && (
        <StyledDiv>
          <TypographyStyled id={`${props.id || ""}TableNoItem`}>
            No surveys. {props.hideAddButton ? "" : <span>Start by creating one.</span>}
          </TypographyStyled>
        </StyledDiv>
      )}

      <PaginationWrapper>
        <Pagination
          id={props.id}
          activePage={activePage}
          itemsCountPerPage={countPerPage}
          onChange={(e) => setActivePage(e)}
          pageRangeDisplayed={countPerPage}
          totalItemsCount={props.list.length}
          totalPages={props.list.length / countPerPage}
          setCount={changePageCount}
        />
      </PaginationWrapper>
    </>
  );
});

export interface ITableData {
  headers: TableHeader[];
}

export type TableHeader = {
  value: string;
  title: string;
  filter: boolean;
  sort: boolean;
  not_visible?: boolean;
  renderRow?: (row: any, isHovered: boolean) => any;
};

export default CustomTable;

const TableText = styled.div`
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  div {
    max-width: 300px;
    word-wrap: break-word;
  }
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const HeaderWrapper = styled.div<{ $search: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.$search ? "column" : "row")};
  align-items: center;
  justify-content: flex-start;
`;

const Title = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground2);
  margin: 0;
`;

const Row = styled.div<{ $search: boolean }>`
  width: ${(props) => (props.$search ? "100%" : "fit-content")};
  display: flex;
  align-items: center;
`;

const StyledDiv = styled.div`
  width: 100%;
  height: 370px;
  background-color: var(--colorNeutralBackground1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TypographyStyled = styled(Typography)`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--colorNeutralForeground5);
  margin-bottom: 10px;
`;

const BlockSortStyle = styled.span`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 10px;
  height: 20px;
  top: 0;
  left: 14px;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
`;

const KeyboardArrowUpIconStyle = styled(KeyboardArrowUpIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: -6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: 6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const PlugStyle = styled.div`
  width: 20px;
  height: 8px;
  background-color: transparent;
  position: absolute;
  top: 6px;
  left: -5px;
  z-index: 999;
`;
