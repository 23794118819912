import { FC } from "react";
import { observer } from "mobx-react";
import store from './store'
import SurveyEditor from "components/surveyEditor/SurveyEditor";


type SurveyJsProps = {};

const SurveyJs: FC<SurveyJsProps> = observer(() => {
  return (
    <div>
      {store.survey_text && <SurveyEditor
        editorMode={"preview"}
        surveyJson={store.survey_text}
        newQuestions={[]}
        onSurveySaved={(json, title, locales) => {}}
      />}
    </div>
  );
});

export default SurveyJs;
