import http from "api/https";
import { createUrl } from "../helpers/url";

export const uploadExcel = (params: {
  replace?: boolean;
  identity?: number;
  currentStep?: string;
  file?: File;
}): Promise<any> => {
  const query: { replace: string; identity: string; insert?: string; } = {
    replace: params.replace.toString(),
    identity: params.identity.toString(),
  };
  if (params.currentStep === "ReviewChanges") {
    query.insert = "1";
  } else {
    query.insert = "0";
  }

  if (http.isAPIGO) {

    const formData = new FormData();
    formData.append("file", params.file);
    formData.append("entity_id", query.identity);
    formData.append("insert", query.insert);
    formData.append("replace", params.replace ? "1" : "0");
    formData.append("region_id", "1");

    return http.postGO("/employee/employees/Import", formData, {
      "Content-Type": "multipart/form-data",
    });
  }
  const url = createUrl({
    path: "/file/uploadExcel",
    query: query,
  });

  const formData = new FormData();
  formData.append("file", params.file);

  return http.post(url, formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const cancelImport = (params: {
  identity?: number;
}): Promise<any> => {
  const query: { identity: string; } = {
    identity: params.identity.toString(),
  };
  const formData = new FormData();
  formData.append("entity_id", query.identity);
  formData.append("region_id", "1");

  return http.postGO("/employee/employees/ImportCancel", formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const importExcel = (params: {
  replace?: boolean;
  identity?: number;
  currentStep?: string;
}): Promise<any> => {
  const query: { replace: string; identity: string; insert?: string; fileGuid?: string } = {
    replace: params.replace.toString(),
    identity: params.identity.toString(),
  };
  const data = {
    guid: localStorage.getItem("fileGuid"),
    entity_id: Number(params.identity),
    insert: Boolean(params.currentStep === "ReviewChanges"),
    replace: Boolean(params.replace),
    region_id: Number("1")
  }

  return http.postGO("/employee/employees/ConfirmImport", data);
};

export const reviewImport = (params: {
  replace?: boolean;
  identity?: number;
  currentStep?: string;
}): Promise<any> => {
  const query: { replace: string; identity: string; insert?: string; fileGuid?: string } = {
    replace: params.replace.toString(),
    identity: params.identity.toString(),
  };
  const data = {
    guid: localStorage.getItem("fileGuid"),
    entity_id: Number(params.identity),
    insert: Boolean(true),
    replace: Boolean(params.replace),
    region_id: Number("1")
  }

  return http.postGO("/employee/employees/ReviewImport", data);
};
