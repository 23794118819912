import http from "api/https";

export const getAttribute = (id: number): Promise<any> => {
  if(http.isAPIGO){
    return http.getGO("/entity/entity_attributes/" + id);
  }else{
    return http.get("/entity_attributes/GetOneById?id=" + id);

  }
};
