import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import EditIcon from "@mui/icons-material/Edit";

import store from "./../../store";
import ReminderEdit from "./ReminderEdit";
import PreviewIcon from "../../PreviewIcon";
import { ReminderMessage } from "types/templates";

type ReminderBlockProps = {
  reminder: ReminderMessage;
  index: number;
};

const ReminderBlock: FC<ReminderBlockProps> = observer((props) => {
  return (
    <div id={`Template_Communication_Schedule_Reminder_Block_${props.index}`}>
      {store.openReminderIndex === props.index ? (
        <>
          <ReminderEdit {...props} />
          <LaunchSurveyAfter />
        </>
      ) : (
        <>
          <LaunchSurvey>
            <LauncSurveyText
              id={`Template_Communication_Schedule_Reminder_EmailText_${props.index}`}
            >
              <LaunchMainText
                id={`Template_Communication_Schedule_Reminder_EmailText_Number_${props.index}`}
              >
                Reminder email {props.index + 1}{" "}
              </LaunchMainText>
              <LaunchSecondText
                id={`Template_Communication_Schedule_Reminder_EmailText_To_${props.index}`}
              >
                to {props.reminder.reminder_recipients_group_name}
              </LaunchSecondText>
            </LauncSurveyText>
            <LaunchButtons>
              <LaunchButtonWrapper>
                <LinkPreview
                  id={`Template_Communication_Schedule_Reminder_Preview_Button_${props.index}`}
                  onClick={() => store.LoadPreviewMessage(props.reminder.id_comms_reminder, false)}
                >
                  <PreviewIcon />
                  Preview email
                </LinkPreview>
                <LinkPreview
                  id={`Template_Communication_Schedule_Reminder_Edit_Button_${props.index}`}
                  onClick={() => store.changeOpenReminderIndex(props.index, true)}
                >
                  <EditIcon fontSize="inherit" />
                  Edit
                </LinkPreview>
              </LaunchButtonWrapper>
            </LaunchButtons>
          </LaunchSurvey>
          <LaunchSurveyAfter />
        </>
      )}
    </div>
  );
});

const LaunchSurvey = styled.div`
  padding: 12px;
  background-color: var(--colorNeutralForeground4);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LinkPreview = styled.a`
  display: flex;
  align-items: center;
  color: var(--colorBrandForeground1);
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  gap: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const LaunchButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LaunchButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const LaunchSurveyAfter = styled.div`
  height: 15px;
  border-left: 3px dashed var(--colorPaletteBlueBackground2);
  margin-left: 20px;
`;

const LauncSurveyText = styled.div``;

const LaunchMainText = styled.span``;

const LaunchSecondText = styled.span`
  color: var(--colorNeutralForeground2);
`;

export default ReminderBlock;
