import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Grid, Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import { SurveyAccessIcon } from "./SurveyAccessIcon";
import CustomButton from "components/button/Button";
import store from "./../store";

type SurveyAccessContentProps = {};

const SurveyAccessContent: FC<SurveyAccessContentProps> = observer((props) => {
  return (
    <Grid container>
      <Grid item>
        <BoxText>
          <SurveyAccessIcon />
          <BoxMainText id="Template_Communication_Access_Message">
            These will be included in the email communications sent out for respondents to access
            the survey.
          </BoxMainText>
        </BoxText>
      </Grid>
      <GridWrapped item>
        <HowWouldText id="Template_Communication_Access_Label">
          How would you like recipients to access the survey?
        </HowWouldText>
        <Buttons>
          {store.surveyAccessArray?.map((el) => {
            return (
              <BoxStyle
                id={`Template_Communication_Access_Checkbox_Label_${el.code}`}
                key={el.id}
                $active={el.valueChecked}
                onClick={() => store.checkedChange(el.id, !el.valueChecked, el.code)}
              >
                {el.name}
                <Checkbox
                  id={`Template_Communication_Access_Checkbox_${el.code}`}
                  name={el.name}
                  checked={el.valueChecked}
                />
              </BoxStyle>
            );
          })}
        </Buttons>
        <ButtonWrapper>
          <StyledNextButton
            disabled={!store.accessed_changed}
            variant="contained"
            id={"Template_Communication_Access_SaveButton"}
            onClick={() => store.SaveAccessesClicked()}
          >
            Save changes
          </StyledNextButton>
        </ButtonWrapper>
      </GridWrapped>
    </Grid>
  );
});

export default SurveyAccessContent;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledNextButton = styled(CustomButton)`
  width: 100%;
  margin-right: 20px;
  max-width: 150px;
`;

const BoxText = styled(Box)`
  width: 230px;
  height: 78px;
  color: var(--colorNeutralForeground2);
  margin-left: 20px;
  display: flex;
  flex-direction: row;
`;

const GridWrapped = styled(Grid)`
  margin: 0px 10px 20px 50px !important;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
`;

const HowWouldText = styled.span`
  color: var(--colorNeutralForeground2);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
`;

const BoxMainText = styled.div`
  width: 200px;
  color: var(--colorNeutralForeground2);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const BoxStyle = styled(Box)<{ $active?: boolean }>`
  cursor: pointer;
  border: ${(props) =>
    props.$active
      ? "1px solid var(--colorBrandForeground1)"
      : "1px solid var(--colorPaletteBlueBackground1)"} !important;
  width: 260px;
  height: 80px;
  border-radius: 4px;
  margin: 20px 20px 20px 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
