import { CreatorBase } from "survey-creator-core"

const placeHolderTexts = {
  question: {
    title: "Enter question",
    description: "Enter question description",
  },
};

type QuestionKey = keyof typeof placeHolderTexts.question

export default function handlePlaceholderModification(_: CreatorBase, options: any): void {
  if (!options.obj.isQuestion) {
    return
  }

  const propertyName: QuestionKey = options.property.name

  if (Object.keys(placeHolderTexts.question).indexOf(propertyName) == -1) {
    return
  }

  options.editor.placeHolder = placeHolderTexts.question[propertyName]
}
