import React from "react";
import { observer } from "mobx-react";
import store from './store'
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import "./survey.css";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import { CustomSurveyEditorStyles } from "./CustomSurveyJsStyles";
import { Question } from "types/templates";

const creatorOptions = {
  showLogicTab: false,
  isAutoSave: true,
  showTranslationTab: true,
  showToolbar: false,
  showDesignerTab: false,
  allowModifyPages: false,
  showPreviewTab: false,
  showTabs: false,
};

type SurveyJsProps = {};

const SurveyJs: React.FC<SurveyJsProps> = observer(() => {
  const creator = new SurveyCreator(creatorOptions);
  creator.text = (store.QuestionnaireTemplate != null && store.QuestionnaireTemplate.survey != null)
    ? store.QuestionnaireTemplate.survey : "";

  creator.onCanShowProperty.add(function (sender, options) {
    if (options.property.name === 'name') {
      options.canShow = false;
    }
  });


  creator.toolbox.removeItem("file");
  creator.toolbox.removeItem("radiogroup");
  creator.toolbox.removeItem("panel");
  creator.toolbox.removeItem("paneldynamic");
  creator.toolbox.removeItem("matrix");
  creator.toolbox.removeItem("matrixdropdown");
  creator.toolbox.removeItem("matrixdynamic");

  creator.toolbox.removeItem("html");
  creator.toolbox.removeItem("expression");
  creator.toolbox.removeItem("image");
  creator.toolbox.removeItem("signaturepad");
  creator.toolbox.removeItem("multipletext");
  creator.toolbox.removeItem("checkbox");
  creator.toolbox.removeItem("imagepicker");
  creator.toolbox.removeItem("comment");
  creator.toolbox.removeItem("ranking");
  creator.toolbox.removeCategories()
  creator.showToolboxValue = false;
  creator.showDesignerTab = false;
  creator.autoSaveDelay = 0;

  creator.toolbox.getItemByName("text").title = "Open-ended";
  creator.toolbox.getItemByName("dropdown").title = "Single choice";
  creator.toolbox.getItemByName("tagbox").title = "Multiple choice";
  creator.toolbox.getItemByName("boolean").title = "Yes/No";

  creator.saveSurveyFunc = (saveNo, callback) => {
    callback(saveNo, true);
    store.changeMainSurvey(creator.text);
    store.changeUsedLocales(creator.survey.getUsedLocales())

    let questions: Question[] = []
    creator.survey.pages.forEach(page => {
      page.questions.forEach(x => {
        questions.push({
          name: x.name,
          questionJson: JSON.stringify(x.toJSON()),
          title: x.title,
          type: x.getType(),
          isQuestionConfition: Boolean(x.visibleIf) || Boolean(x.visibleIfAnswer),
        })
      })
    });
    store.changeQuestions(questions)
  };
  creator.onModified.add((e) => {
    store.setTranslationChanged(true);
  });

  return (
    <div id="TemplateQuestionnaire_Translations_Tab">
      <CustomSurveyEditorStyles $showTopBar={false}>
        <SurveyCreatorComponent creator={creator} />
      </CustomSurveyEditorStyles>
    </div>
  );
});

export default SurveyJs;
