import { FC, ReactElement, useEffect } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import AddTemplatePopup from "./../AddTemplate/AddPopup";
import store from "./store";
import CustomButton from "components/button/Button";
import PageGrid from "./PageGrid";
import { Divider, IconButton, TextField, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import LeftAccordions from "./LeftAccordions";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import DeleteConfirm from "components/deleteConfirm/DeleteConfirm";
import { RemoveIcon } from "../../Project/ProjectList/components/RemoveIcon";

type TemplateListProps = {};

const ListTemplate: FC<TemplateListProps> = observer((props): ReactElement => {
  useEffect(() => {
    store.extractParamFromUrl();
    store.getSurveyTags();
  }, []);

  let header = "";

  if (store.navUrl === "entity")
    header = StoreLayout.currentWorkspaceName + ": " + StoreLayout.currentEntityName;
  else if (store.navUrl === "engageRocket") header = "EngageRocket ";
  else if (store.navUrl === "shared") header = "Shared ";

  return (
    <Root>
      <LeftAccordion>
        <LeftAccordions />
      </LeftAccordion>
      <MainContent>
        <MainWrapper>
          <Header id={"Templates_Header_Label"}>{header} templates</Header>

          <TextField
            size="small"
            placeholder="Search for template name"
            id={"Templates_SearchInput"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    id="Templates_SearchInput_Button"
                    onClick={() => {
                      store.onSearchClicked();
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={store.searchedValue}
            onKeyDown={store.keyPress}
            onChange={(e) => store.changeValue("searchedValue", e.target.value)}
          />
        </MainWrapper>

        <Divider />

        {store.resultSearchValue !== "" && (
          <ResultSearch>
            {store.data.length === 0 ? (
              <SearchText id={"Templates_ResultSearch"}>
                "
                <SearchTextValue id={"Templates_ResultSearch_Value"}>
                  {store.resultSearchValue}
                </SearchTextValue>
                " No templates was found to match the provided details. Please try using a different
                search keyword.
              </SearchText>
            ) : (
              <SearchText id={"Templates_ResultSearch"}>
                "
                <SearchTextValue id={"Templates_ResultSearch_Value"}>
                  {store.resultSearchValue}
                </SearchTextValue>
                " can be found in the following templates
              </SearchText>
            )}

            <RemoveSearch>
              <CustomButtonStyled
                id={"Templates_RemoveSearch_Button"}
                onClick={() => {
                  store.changeValue("resultSearchValue", "");
                  store.loadTemplates();
                }}
              >
                <RemoveIcon />
                Remove search
              </CustomButtonStyled>
            </RemoveSearch>
          </ResultSearch>
        )}

        <TemplateListWrapper>
          <ButtonsWrapper>
            <TypographyStyled id={"Templates_Table_Header_Text"}>
              {store.navUrl === "entity"
                ? "Templates created this within this entity."
                : store.navUrl === "shared"
                  ? "These templates have been shared with your entity."
                  : null}
            </TypographyStyled>
            <ButtonWrapper>
              <CustomButton
                id={"Templates_CreateButton"}
                variant="contained"
                onClick={() => {
                  store.changePanelAddTemplate(true);
                  localStorage.setItem("templateAction", "create");
                }}
              >
                + Create new template
              </CustomButton>
            </ButtonWrapper>
          </ButtonsWrapper>

          <PageGrid list={store.data} />
        </TemplateListWrapper>

        <AddTemplatePopup
          openPanel={store.openPanelAddTemplate}
          onBtnCancelClick={() => {
            store.changePanelAddTemplate(false);
          }}
          onClose={() => {
            store.changePanelAddTemplate(false);
          }}
          onSaved={() => {
            store.changePanelAddTemplate(false);
          }}
        />
        <DeleteConfirm
          open={store.openDeleteConfirm}
          onCancel={() => {
            store.changeOpenDeleteConfirm(false);
          }}
          onSubmit={() => {
            store.changeOpenDeleteConfirm(false);
            store.RemoveTemplateSubmit();
          }}
          removeSubmitText="Delete template"
        >
          <>
            <HeaderDelete id={"Template_Delete_HeaderLabel"}>
              Are you sure you want to delete the template "{store.selectedRow?.name}"?
            </HeaderDelete>
            <DescriptionDelete id={"Template_Delete_Description"}>
              Questionnaire or Communications that have previously used this template will not be
              affected.
            </DescriptionDelete>
          </>
        </DeleteConfirm>
      </MainContent>
    </Root>
  );
});

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;
`;

const LeftAccordion = styled.div`
  position: fixed;
`;

const MainContent = styled.div`
  width: 100%;
  margin: 51px 50px 50px 270px;
`;

const ResultSearch = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--colorPaletteBlueBackground3);
  margin-top: 12px;
  height: 36px;
  position: absolute;
`;
const SearchText = styled.div`
  margin-left: 16px;
`;
const SearchTextValue = styled.span`
  font-weight: 500;
`;

const RemoveSearch = styled.div`
  margin-right: 24px;
`;

const MainWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const Header = styled.h1`
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0;
`;

const HeaderDelete = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0;
`;

const DescriptionDelete = styled.div`
  margin-top: 20px;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  background-color: var(--grenBackgroundColor2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px;
`;

const CustomButtonStyled = styled(CustomButton)``;

const TypographyStyled = styled(Typography)`
  color: var(--colorNeutralForeground2);
  margin-left: 20px !important;
`;

const TemplateListWrapper = styled.div`
  position: relative;
  top: 60px;
`;

export default ListTemplate;
