import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import CustomButton from "components/button/Button";
import store from "../store";

type NonTestPopupProps = {
  closePopup: () => void;
};

const NonTestPopup: FC<NonTestPopupProps> = observer((props) => {
  return (
    <PopupWrapper>
      <h2 id="Survey_Project_NonTestPopup" style={{ margin: 0 }}>
        You are about to mark “{store.selectedRow.name}” as a{" "}
        {store.selectedRow.test ? "non-test" : "test"} project
      </h2>
      <Box>
        <Typography id="Survey_Project_NonTestPopup_Description">
          Projects that are marked as "test" will have their data excluded for benchmarks. We should
          ensure that only real (not pilot or test) projects are included for benchmarks so that the
          benchmarks are scientifically rigorous.
        </Typography>
      </Box>
      <BtnBox>
        <CustomButton
          id="Survey_Project_NonTestPopup_MarkProject_Button"
          variant="contained"
          onClick={() => store.markAsTestProject(store.selectedRow.id, props.closePopup)}
        >
          Mark as {store.selectedRow.test ? "non-test" : "test"} project
        </CustomButton>
        <CustomButtonStyled id="Survey_Project_NonTestPopup_Cancel_Button" onClick={() => props.closePopup()} variant="outlined">
          Cancel
        </CustomButtonStyled>
      </BtnBox>
    </PopupWrapper>
  );
});

export default NonTestPopup;

const PopupWrapper = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const BtnBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 23px;
`;

const CustomButtonStyled = styled(CustomButton)`
  border: 1px solid var(--colorNeutralForeground5) !important;
  color: var(--colorNeutralForeground2) !important;
`;
