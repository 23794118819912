import { FC } from "react";
import { SurveyList } from "features/Survey/SurveyList";

type SurveysPageProps = {};

const SurveysPage: FC<SurveysPageProps> = () => {
  return <div>
    {/* <SurveyList /> */}
  </div>;
};

export default SurveysPage;
