import { FC, useState } from "react";
import { useNavigate } from "react-router";
import { observer } from "mobx-react";

import styled from "styled-components";
import CustomButton from "components/button/Button";
import storeQuestionnaireTemplate from "features/Templates/QuestionnaireTemplates/store";
import BackSpaceIcon from "features/Templates/icons/BackSpaceIcon";
import { DiscardPopup } from "layouts/SurveyLayout/discardPopup/DiscardPopup";
import {  useBlocker } from "react-router-dom";
import storeQuestionTemplate from "features/Templates/QuestionnaireTemplates/store";

type BackToMainLayoutHeaderProps = {};

const BackToMainLayoutHeader: FC<BackToMainLayoutHeaderProps> = observer(() => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handlerSaveTemplate = async () => {
    const valid = await storeQuestionnaireTemplate.checkValidTitle();
    if (valid) {
      await storeQuestionnaireTemplate.saveQuestionnaireTemplate((path: string) => navigate(path));
    }
  };

  const blockerTranslation = useBlocker(({ currentLocation, nextLocation }) => {
    if (storeQuestionTemplate.changedTranslation) {
      return currentLocation.pathname !== nextLocation.pathname;
    }
  });

  const backHandler = () => {
    if (storeQuestionnaireTemplate.changedSurvey) {
      setOpenModal(true);
    } else {
      navigate("/templates");
    }
  };

  const saveHandler = async () => {
    navigate("/templates");
  };

  return (
    <>
      <DiscardPopup
        open={openModal}
        closeHandler={() => setOpenModal(false)}
        cancelHandler={() => setOpenModal(false)}
        confirmHandler={saveHandler}
        title="Leave page and discard changes?"
        description="All changes made will not be saved if you choose not to apply changes."
        cancel="Cancel"
        confirm="Leave and discard"
      />
      <DiscardPopup
        open={blockerTranslation.state === "blocked"}
        closeHandler={() => blockerTranslation.reset()}
        cancelHandler={() => blockerTranslation.proceed()}
        confirmHandler={async () => {
          await handlerSaveTemplate();
          blockerTranslation.proceed();
        }}
      />
      <BackToMainLayoutHeaderWrapper id="Template_Questionnaire_Header">
        <BackContainer>
          <BackButton id="Template_Questionnaire_Back_Button" onClick={backHandler}>
            <BackSpaceIcon />
            <BackToModule id="Template_Questionnaire_Header_Back_Button_Label">
              Back to templates
            </BackToModule>
          </BackButton>
        </BackContainer>
        <CreateTemplateText id="Template_Questionnaire_Header_Label">
          {localStorage.getItem("templateAction") === "create"
            ? "Create new template"
            : "Editing template"}
        </CreateTemplateText>
        <SaveTemplateButton
          variant="contained"
          id={"Template_Questionnaire_Save_Template"}
          onClick={handlerSaveTemplate}
        >
          Save template
        </SaveTemplateButton>
      </BackToMainLayoutHeaderWrapper>
    </>
  );
});

export default BackToMainLayoutHeader;

const BackToMainLayoutHeaderWrapper = styled.div`
  height: 64px;
  padding: 20px 51px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--colorPaletteVioletBackground1);
`;

const BackToModule = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralBackground1);
`;
const BackContainer = styled.div`
  display: flex;
  align-items: center;
`;
const CreateTemplateText = styled.div`
  color: var(--colorNeutralBackground1);
  font-size: 16px;
  font-weight: 500;
`;

const BackButton = styled.button`
  background: var(--colorPaletteVioletBackground1);
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;

const SaveTemplateButton = styled(CustomButton)`
  width: 180px;
`;
