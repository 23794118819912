import http from "api/https";
import { createUrl } from "../../helpers/url";

export const getPermissionForRoles = (
  real_id: number,
  access_level_code: string,
  region_id: number
): Promise<any> => {

  const query: {
    real_id: string,
    access_level_code: string,
    region_id: string
  } = {
    real_id: real_id.toString(),
    access_level_code: access_level_code,
    region_id: region_id.toString()
  };

  const url = createUrl({
    path: "/permission/GetPermissionsByRealIdAndCode",
    query: query,
  });

  if (http.isAPIGO) {
    return http.getGO(url);
  }

  return http.get(url);
};
