import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled as styledMui } from "@mui/material/styles";
import { Badge } from "@mui/material";
import { PermissionIcons } from "./mock";
import { Link as RouterLink } from "react-router-dom";
import "./ScrollContainer.css";
import store from "./store";
import { observer } from "mobx-react";
import BusinessIcon from "@mui/icons-material/Business";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import StarsIcon from "@mui/icons-material/Stars";
import SettingsIcon from "@mui/icons-material/Settings";
import styled from "styled-components";
import { WorkspaceAdminIcon } from "features/EntityPermissions/Roles/Icons/WorkspaceAdminIcon";
import { WorkspaceRoleIcon } from "features/EntityPermissions/Roles/Icons/WorkspaceRoleIcon";
import CustomSwitch from "components/switch/Switch";
import { WORKSPACE_PERMISSIONS } from "constants/permissions";
import { LevelPermission } from "types/permission";

type TablePermissionProps = {
  changeTab: (tab: string) => void;
};

const BasicTable: React.FC<TablePermissionProps> = observer((props) => {
  return (
    <>
      <TableContainerWrapper component={Paper} className="parent">
        <TableWrapper className="child" aria-label="simple table">
          <TableHead>
            <TableRow>
              <EmptyTableCellNav></EmptyTableCellNav>

              {store.roles.map((role, index) => {
                return (
                  <TableCellRoles align="left" key={role.id}>
                    <RouterLink
                      id={`WorkspacePermissionsRoles_GridHeaderRoleLink_${index}`}
                      to={"/workspacePermissions?nav=user&idRole=" + role.id}>
                      <FlexAlignCenter>
                        {role.is_default ? <WorkspaceAdminIcon /> : <WorkspaceRoleIcon />}
                        <RoleName
                          id={`WorkspacePermissionsRole_RoleNav_Name_${index}`}>
                          {role.name} ({role.countUsers})
                        </RoleName>
                      </FlexAlignCenter>
                    </RouterLink>
                  </TableCellRoles>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {store.permissionData.map((row, indexRow) => (
              <React.Fragment key={row.id}>
                <StyledTableRow id={`WorkspacePermissionsRoles_Grid_Subgrid_${indexRow}_${row.id}`}>
                  <TableCellCategoryName id={`WorkspacePermissionsRoles_Grid_Subgrid_Name_${indexRow}_${row.id}`}>
                    <FlexAlignCenter>
                      {PermissionIcons.filter((x) => x.code === row.code).length > 0 ? (
                        <PermissionName>
                          {PermissionIcons.filter((x) => x.code === row.code)[0].icon}
                        </PermissionName>
                      ) : (
                        ""
                      )}
                      {row.name}
                    </FlexAlignCenter>
                  </TableCellCategoryName>
                  {store.roles.map((x) => (
                    <TableCellEmptyCategory align="right" key={x.id}></TableCellEmptyCategory>
                  ))}
                </StyledTableRow>

                {row.permissions.map((value, indexSubrow) => {
                  return (
                    <TableRow
                      id={`WorkspacePermissionsRoles_Grid_Subgrid_Row_${indexSubrow}_${indexRow}_${row.id}`}
                      key={value.id}>
                      <TableCellPermName id={`WorkspacePermissionsRoles_Grid_Subgrid_Subtable_0_${indexSubrow}_${indexRow}_${row.id}`}>
                        <FlexAlignCenter >
                          {PermissionIcons.filter((x) => x.code === value.code).length > 0 ? (
                            <PermissionName>
                              {PermissionIcons.filter((x) => x.code === value.code)[0].icon}
                            </PermissionName>
                          ) : (
                            ""
                          )}

                          {value.name}
                        </FlexAlignCenter>
                      </TableCellPermName>
                      {store.roles.map((role, indexTable) => {
                        let isActive =
                          value.level_permissions.filter((x) => x.role_id === role.id).length != 0;
                        let isAlways = role.is_default;

                        let disabled = CheckDisabledSwitch(value.code, row.permissions, role.id);
                        let childPermissionId: number = GetChildPermissionID(value.code, row.permissions, role.id);
                        return (
                          <TableCellSwitch
                            id={`WorkspacePermissionsRoles_Grid_Subgrid_Subtable_${indexTable + 1}_${indexSubrow}_${indexRow}_${row.id}`}
                            key={role.id} align="left">
                            {isAlways ? (
                              <AlwaysOn>Always on</AlwaysOn>
                            ) : (
                              <CustomSwitch
                                id={`WorkspacePermissionsRoles_Grid_Subgrid_Subtable_${indexTable + 1}_${indexSubrow}_${indexRow}_${row.id}`}
                                checked={isActive}
                                disabled={disabled}
                                onChange={(e) => {
                                  let roleId = role.id;
                                  let permissionId = value.id;
                                  store.changePermission(roleId, permissionId, e.target.checked, childPermissionId);
                                }}
                              />
                            )}
                          </TableCellSwitch>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </React.Fragment>
            ))}
          </TableBody>
        </TableWrapper>
      </TableContainerWrapper>
    </>
  );
});

const CheckDisabledSwitch = (code: string, levelPermissions: LevelPermission[], roleID: number): boolean => {
  if (code === WORKSPACE_PERMISSIONS.viewWorkspacePermissions.code) {
    const editWorkspacePermissionsPermission = levelPermissions.find(x => x.code === WORKSPACE_PERMISSIONS.editWorkspacePermissions.code)
    if (editWorkspacePermissionsPermission.level_permissions.find(x => x.role_id === roleID) !== undefined) {
      return true
    }
  }
  return false
}

const GetChildPermissionID = (code: string, levelPermissions: LevelPermission[], roleID: number): number => {
  if (code === WORKSPACE_PERMISSIONS.editWorkspacePermissions.code) {
    const viewWorkspacePermissionsPermission = levelPermissions.find(x => x.code === WORKSPACE_PERMISSIONS.viewWorkspacePermissions.code)
    if (viewWorkspacePermissionsPermission.level_permissions.find(x => x.role_id === roleID) === undefined) {
      return viewWorkspacePermissionsPermission?.id
    }
  }
  return null
}

const StyledTableRow = styledMui(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "var(--colorPalleteLightBlue, #EEEFFF)",
  },
}));

const TableContainerWrapper = styled(TableContainer)`
  margin-bottom: 200px;
`;

const TableWrapper = styled(Table)`
  min-width: 300px;
`;

const EmptyTableCellNav = styled(TableCell)`
  position: sticky;
  left: 0;
  background: var(--colorPalettePurpleBackground2, #f9f9f9);
  z-index: 2;
  min-width: 300px;
  max-width: 300px;
  width: 300px;
`;
const TableCellRoles = styled(TableCell)`
  min-width: 200px;
`;
const TableCellEmptyCategory = styled(TableCell)`
  background-color: var(--colorPalleteLightBlue, #eeefff);
  min-width: 200px;
`;
const TableCellPermName = styled(TableCell)`
  position: sticky;
  left: 0;
  background: var(--colorPalettePurpleBackground2, #f9f9f9);
  z-index: 1;
  min-width: 300px;
  max-width: 300px;
  width: 300px;
`;
const TableCellSwitch = styled(TableCell)`
  width: 200px;
`;

const TableCellCategoryName = styled(TableCell)`
  position: sticky;
  left: 0;
  padding: 10px 15px;
  font-weight: 500;
  background-color: var(--colorPalleteLightBlue, #eeefff);
  z-index: 2;
  min-width: 300px;
  max-width: 300px;
  width: 300px;
`;

const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
`;
const PermissionName = styled.span`
  color: var(--colorNeutralForeground6, #a9afc6);
  margin-right: 8px;
`;
const AlwaysOn = styled.span`
  font-weight: 500;
`;

const BadgeWrap = styled(Badge)`
  margin-right: 5px;
  margin-bottom: 5px;
`;

const RoleName = styled.span`
  color: var(--colorNeutralForeground1, #3e4450);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  border-bottom: 1px dashed var(--colorPaletteVioletBackground3, #6e31ff);
`;

const StarsIconWr = styled(StarsIcon)`
  font-size: 20px !important;
  background-color: white;
  color: var(--colorPaletteVioletBackground3, #6e31ff);
  border-radius: 10px;
`;

const SettingsIconWr = styled(SettingsIcon)`
  font-size: 20px !important;
  background-color: white;
  color: var(--colorPaletteVioletBackground3, #6e31ff);
  border-radius: 10px;
`;

const AccountCircleIconWr = styled(AccountCircleIcon)`
  font-size: 30px !important;
`;
const BusinessIconWr = styled(BusinessIcon)`
  font-size: 30px !important;
  color: var(--colorPaletteVioletBackground3, #6e31ff);
`;

export default BasicTable;
