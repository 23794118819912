import { useGetRecipientsList } from "api/dasboard/useGetDashboardRecipients";
import { useGetDashboardParticipation } from "api/dasboard/useGetDashboardParticipation";
import { makeAutoObservable, runInAction } from "mobx";
import { TDasbboardFilter, TParticipationRate, TRecipient } from "types/dashboard";
import { ROLE_LIST } from "constants/role-list";

import MainStore from "MainStore";
import DashboardStore from "../store";

class Store {
  sortBy: string = "preff_name_a_z";
  searchValue: string = "";

  filters: TDasbboardFilter[] = [];

  surveyId: number = null;

  surveyInfo: TParticipationRate = null;
  recipientsList: TRecipient[] = [];

  surveyGlobalInfo: { projectName: string; status: string; surveyName: string } = null;

  requestData = {
    activePage: 1,
    itemsCountPerPage: 10,
    totalItemsCount: 0,
    totalPages: 0,
    sort_by: "preferred",
    sort_type: "asc",
    search: "",
  };

  constructor() {
    makeAutoObservable(this);
  }

  setData = (field: string, value: any) => {
    this[field] = value;
  };

  getRecipientsList = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[],
    searchValue?: string
  ) => {
    try {
      MainStore.changeLoader(true);
      const response = await useGetRecipientsList({
        survey_id,
        viewType: DashboardStore.role === ROLE_LIST.MANAGER.value ? DashboardStore.secondaryRole : DashboardStore.role,
        attributes: filters,
        search: searchValue && searchValue.length > 0 ? searchValue : null,
        pagination: {
          offset: this.requestData.activePage,
          limit: this.requestData.itemsCountPerPage,
          sort_by: this.requestData.sort_by,
          sort_type: this.requestData.sort_type,
        },
      });
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          this.recipientsList = response.data.data;
          this.requestData = {
            ...this.requestData,
            totalItemsCount: response.data.totalItemsCount,
            totalPages: response.data.totalPages,
          };
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getRecipientsInfo = async (
    survey_id: number,
    filters: { id: number; name: string; segments: string[] }[]
  ) => {
    try {
      const response = await useGetDashboardParticipation({
        survey_id,
        attributes: filters,
      });
      if (response.status === 200 || response.status === 201) {
        runInAction(() => {
          this.surveyInfo = response.data;
        });
      } else throw new Error();
    } catch (error) {
      console.error(error);
    }
  };
}

const store = new Store();

export default store;
