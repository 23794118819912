import { FC } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import BasicTable from './BasicTable'

type MillstoneSheduleTableProps = {};

const MillstoneSheduleTable: FC<MillstoneSheduleTableProps> = observer(() => {
  return (
    <Container>
      <BasicTable />
    </Container>
  );
});

export default MillstoneSheduleTable;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;
