import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { DialogTitle, DialogContent, TextField } from "@mui/material";

import { Autocomplete as AutocompleteComponent } from "components/autocomplete-tags/Autocomplete";
import CustomButton from "components/button/Button";
import store from "./store";

type editModalPropsType = {
  id: number;
  onSave: (name: string) => void;
};

const CommsModal: FC<editModalPropsType> = observer((props) => {
  useEffect(() => {
    store.clearStore();
    store.loadTags();
    store.templateId = props.id;
    store.loadSettingbyid(props.id);
  }, [props.id]);

  return (
    <StyledModalContent aria-label={"Create workspace form"}>
      <StyledDialogTitle id="Template_Communication_Settings_Header_Label">
        Comms settings
      </StyledDialogTitle>
      <FieldWrapper>
        <HelpText id="Template_Communication_Settings_NameInput_Label">
          Comms template name
        </HelpText>
        <TextField
          name="templateName"
          id="Template_Communication_Settings_NameInput"
          placeholder="Comms template name"
          fullWidth
          variant="standard"
          type="text"
          value={store.templateName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            store.changeTemplateName(e.target.value);
          }}
        />
      </FieldWrapper>

      <AutocompleteComponent
        data={store.tags}
        selected={store.selectedTagNames}
        onChange={(value: string[]) => store.changeSelectedTagName(value)}
        onRemove={(index: number) =>
          store.changeSelectedTagName(store.selectedTagNames.filter((_, i: number) => index !== i))
        }
        titleId="Template_Communication_Settings_Tag_Header"
        labelId="Template_Communication_Settings_Tag_Label"
        fieldId="Template_Communication_Settings_Tags_Input"
        chipId="Communication_Settings_Tags_Chip"
      />

      <ButtonWrapper>
        <StyledNextButton
          variant="contained"
          disabled={store.templateName === "" || store.templateName === null}
          id="Template_Communication_Settings_Save_Button"
          onClick={() => {
            store.onSave();
            props.onSave(store.templateName);
          }}
        >
          Save settings
        </StyledNextButton>
      </ButtonWrapper>
    </StyledModalContent>
  );
});

const StyledModalContent = styled(DialogContent)`
  padding: 10px !important;

  display: flex;
  flex-direction: column;
`;

const StyledDialogTitle = styled(DialogTitle)`
  padding: 0 !important;

  color: var(--ER-Theme-sys-light-on-secondary-container, #3e4450);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;

const StyledNextButton = styled(CustomButton)`
  width: 180px;
`;

const HelpText = styled.p`
  color: var(--ER-Theme-sys-light-on-secondary-container, #3e4450);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
`;

const FieldWrapper = styled.div`
  margin-top: 15px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

export default CommsModal;
