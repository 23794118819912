
import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  margin-right: 5px;
`;

export const WorkspaceRoleIcon = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6665 7.00002H15.3332V5.33335C15.3332 4.40835 14.5915 3.66669 13.6665 3.66669H10.3332C9.40817 3.66669 8.6665 4.40835 8.6665 5.33335V7.00002H5.33317C4.40817 7.00002 3.67484 7.74169 3.67484 8.66669L3.6665 17.8334C3.6665 18.7584 4.40817 19.5 5.33317 19.5H11.7279C11.3679 18.7423 11.1665 17.8947 11.1665 17C11.1665 13.7784 13.7782 11.1667 16.9998 11.1667C18.2392 11.1667 19.3883 11.5532 20.3332 12.2123V8.66669C20.3332 7.74169 19.5915 7.00002 18.6665 7.00002ZM13.6665 7.00002H10.3332V5.33335H13.6665V7.00002Z" fill="#6E31FF" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1173 17C20.1173 17.1417 20.1006 17.275 20.084 17.4084L20.9756 18.1C21.0506 18.1667 21.0756 18.275 21.0256 18.3667L20.1923 19.8084C20.134 19.9 20.0256 19.9334 19.934 19.9L18.8923 19.4834C18.684 19.65 18.4423 19.7834 18.1923 19.8917L18.034 20.9917C18.0173 21.0917 17.9256 21.1667 17.8256 21.1667H16.159C16.0506 21.1667 15.9673 21.0917 15.9506 20.9917L15.7923 19.8917C15.534 19.7917 15.3006 19.65 15.084 19.4834L14.0506 19.9C13.9506 19.9334 13.8423 19.9 13.7923 19.8084L12.959 18.3667C12.909 18.275 12.934 18.1667 13.009 18.1L13.8923 17.4084C13.8756 17.275 13.8673 17.1417 13.8673 17C13.8673 16.8584 13.8756 16.725 13.8923 16.5917L13.009 15.9C12.9256 15.8334 12.909 15.725 12.959 15.6334L13.7923 14.1917C13.8506 14.1 13.959 14.0667 14.0506 14.1L15.084 14.5167C15.3006 14.35 15.5423 14.2167 15.7923 14.1084L15.9506 13.0084C15.9673 12.9084 16.059 12.8334 16.159 12.8334H17.8256C17.9256 12.8334 18.0173 12.9084 18.0256 13.0084L18.184 14.1084C18.4423 14.2084 18.6756 14.35 18.8923 14.5167L19.9256 14.1C20.0256 14.0667 20.134 14.1 20.184 14.1917L21.0173 15.6334C21.0673 15.725 21.0423 15.8334 20.9673 15.9L20.084 16.5917C20.109 16.725 20.1173 16.8584 20.1173 17ZM15.7423 17C15.7423 17.6917 16.3006 18.25 16.9923 18.25C17.684 18.25 18.2423 17.6917 18.2423 17C18.2423 16.3084 17.684 15.75 16.9923 15.75C16.3006 15.75 15.7423 16.3084 15.7423 17Z" fill="#6E31FF" />
  </StyledSvg>
);
