import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box } from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import store from './store'
import { observer } from 'mobx-react';
import CustomButton from "components/button/Button";
import { RoleUser } from "types/permission";
import styled from "styled-components";

const columns: GridColDef[] = [
  {
    field: 'full_name', headerName: 'Name', width: 400, 
    renderCell: (param) => {
      return <>
        <FullName  
        id={`WorkspacePermissionsUsers_Grid_NameColumn_${param.id}}`}
         onClick={() => {
          store.changeCheckEmployeePanel(true)
          store.changeValue('currentUserId', param.row.id)
        }}>
          {param.row.full_name}
        </FullName>

      </>
    },
    renderHeader: (param) => {
      return <span id={`WorkspacePermissionsUsers_Grid_NameColumn}`}>{param.colDef.headerName}</span>
    }
  },
  { field: 'email', headerName: 'Email', width: 400, 
  renderHeader: (param) => {
    return <span id={`WorkspacePermissionsUsers_Grid_EmailColumn}`}>{param.colDef.headerName}</span>
  },
  renderCell: (param) => {
    return <span  id={`WorkspacePermissionsUsers_Grid_EmailColumn_${param.id}}`}>{param.value}</span>
  }
},
];

interface TableProps {
  data: RoleUser;
}

const DataTable = observer((props: TableProps) => {
  return (
    <div style={{ width: '100%' }}>
      <HeaderWrapperBox>
        {props.data.isDefaultRole ? <Box id={`WorkspaceUsers-InfoText`}>This is default permission role and cannot be edited or deleted.</Box> :
          <Box>
            <CustomButton
            id={`WorkspaceUsers-EditNameBtn`}
            style={{ margin: 5 }} onClick={() => {
              store.changeAddEditRoleNamePanel(true)
              store.changeValue("currentRoleId", props.data.id)
              store.changeValue("currentRoleName", props.data.roleName)

            }}>Edit name</CustomButton>
            <CustomButton
            id={`WorkspaceUsers-DeleteRoleBtn`}
            style={{ margin: 5 }} onClick={() => {
              store.changeDeleteRolePanel(true)
              store.changeValue("currentRoleId", props.data.id)
              store.changeValue("currentRoleName", props.data.roleName)
            }}>Delete role</CustomButton>
          </Box>
        }
        <Box >
          <CustomButton
            id={`WorkspaceUsers-AddUsersBtn`}
            style={{ margin: 5 }}
            variant='contained'
            size="small"
            onClick={() => {
              store.changeInviteUsersPanel(true)
              store.changeValue("inviteUsersPanel", true)
              store.changeValue("currentRoleId", props.data.id)
            }}
          >
            <PersonAddIcon fontSize="small" /> Add users
          </CustomButton>

          <CustomButton
          id={`WorkspaceUsers-ChangeRoleBtn`}
            style={{ margin: 5 }}
            disabled={store.selectedData.length === 0}
            variant='contained'
            color="inherit"
            size="small"
            onClick={() => {
              store.changeChangeRolePanel(true)
              store.changeValue("currentRoleId", props.data.id)
              store.changeValue("currentRoleName", props.data.roleName)
              var selected_users = props.data.users.filter(x => store.selectedData.includes(x.id))
              if (selected_users.length === 1) {
                store.changeValue("currentUser", selected_users[0])
              }
            }}
          >Change role</CustomButton>

          <CustomButton
            id={`WorkspaceUsers-RemoveFromRoleBtn`}
            style={{ margin: 5 }}
            disabled={store.selectedData.length === 0}
            variant='contained'
            color="inherit"
            size="small"
            onClick={() => {
              store.changeRemoveFromRolePanel(true)
              store.changeValue("currentRoleId", props.data.id)
              store.changeValue("currentRoleName", props.data.roleName)
              var selected_users = props.data.users.filter(x => store.selectedData.includes(x.id))
              if (selected_users.length === 1) {
                store.changeValue("currentUser", selected_users[0])
              }
            }}
          ><DeleteIcon fontSize="small" />
            Remove from role
          </CustomButton>

        </Box>
      </HeaderWrapperBox>
      <DataGrid
        sx={{ minHeight: 300 }}
        rows={props.data.users}
        onRowSelectionModelChange={(rows, details) => {
          store.changeValue('selectedData', rows)
        }}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </div>
  );
})

const HeaderWrapperBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  background-color: var(--grenBackgroundColor2);
  padding: 0 16px;
`;

const FullName = styled.a`
&:hover {
  text-decoration: underline;
  color: blue;
}
`;

export default DataTable