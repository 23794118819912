import React from "react";
import styled from "styled-components";

const ShapeIcon = () => {
  return (
    <StyledSvg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.24911 6.86991L1.81661 4.43741L0.988281 5.25991L4.24911 8.52074L11.2491 1.52074L10.4266 0.698242L4.24911 6.86991Z"
        fill="black"
      />
    </StyledSvg>
  );
};

export default ShapeIcon;

const StyledSvg = styled.svg`
  margin-right: 6px;
  position: relative;
  top: -1px;
`;

