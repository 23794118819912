import http from "api/https";
import { FooterSurveyLanguage } from "types/surveys";

export const saveFooterSurveyCommunication = (
  footer_data: FooterSurveyLanguage[],
  survey_id: number,
  logo_file: any,
  remove_file: boolean
) => {
  
  const formData = new FormData();
  formData.append("survey_id", survey_id + "");
  formData.append("file", logo_file);
  formData.append("remove_file", remove_file ? "true": "false")
  // formData.append("footer_data", JSON.stringify( footer_data));
  footer_data.forEach((language, index) => {
    Object.entries(language).forEach(([key, value]) => {
      formData.append(`footer_data[${index}].${key}`, value);
    });
  });
//   for ( var key in footer_data ) {
//     formData.append(key, footer_data[key] as any);
// }
  if(http.isAPIGO){
    return http.postGO("/sm/sm_survey/SaveFooterSurveyCommunication", formData);
  } else {
    return http.post("/sm_survey/SaveFooterSurveyCommunication", formData);
  }  
};
