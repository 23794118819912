import { FC } from "react";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import { Box, Typography } from "@mui/material"
import storeSurveyCreation from "../../SurveyCreation/store";
import { observer } from "mobx-react";
import { QuestionIcon } from "./QuestionIcon"
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useNavigate } from "react-router";

type EditSurveyPopupProps = {
  hide: () => void
};

const EditSurveyPopup: FC<EditSurveyPopupProps> = observer((props) => {
    const navigate = useNavigate();
    return (
        <PopupWrapper id="EditSurveyPopup_Container">
            <Styledh2 id="EditSurveyPopup_Title">Are you sure you want to make changes to a running survey?</Styledh2>
            <BoxStyled id="EditSurveyPopup_Body_1_Container">
                <WarningAmberIcon id="EditSurveyPopup_Body_Icon_1"/>
                <TypographyStyled id="EditSurveyPopup_Body_Text_1">
                    This action might affect responses of recipients whom have already responded to the survey.
                    Previous question setup will be replaced by your new changes across survey and dashboard.
                </TypographyStyled>
            </BoxStyled>
            <StyledBox>
                <QuestionBox id="EditSurveyPopup_Body_2_Container">
                    <QuestionIcon id="EditSurveyPopup_Body_Icon_2"/>
                    <TypographyQuestion id="EditSurveyPopup_Body_Text_2">What does this mean?</TypographyQuestion>
                </QuestionBox>
                <BoxTypography id="EditSurveyPopup_Body_3_Container">
                    <Typography id="EditSurveyPopup_Body_Text_3">
                        The dashboard will only reflect the final version with the latest changes made to the questionnaire.
                        This can affect the meaningfulness of the insights.
                    </Typography>
                </BoxTypography>

            </StyledBox>

            <BtnBox>
                <CustomButton
                    id="EditSurveyPopup_EditSurveyAnyeay_Btn"
                    variant="contained"
                    onClick={props.hide}
                >
                    Edit survey anyway
                </CustomButton>
                <CustomButtonStyled
                    id="EditSurveyPopup_Cansel_AndLeave_Btn"
                    variant="outlined"
                    onClick={() => navigate("/projects?project_id=" + storeSurveyCreation.idProject)}
                >
                    Cancel and leave
                </CustomButtonStyled>
            </BtnBox>

        </PopupWrapper>
    );
});

const PopupWrapper = styled.div`
  width: 500px;
  height: 500px;
`
const Styledh2 = styled.h2`
  margin: 70px 50px 0px 70px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
`
const BoxStyled = styled(Box)`
  margin: 40px 50px 0px 70px;
  width: 430px;
  svg {
  font-size: 20px;
  color: var(--colorPaletteRedForeground2);
  float: left;
  margin-right: 5px;
}
`
const TypographyStyled = styled(Typography)`
  display: block;
  margin-bottom: 30px !important;
`
const BtnBox = styled(Box)`
  margin: 40px 50px 0px 70px;
  width: 350px;
  display: flex;
  justify-content: space-between;
`
const CustomButtonStyled = styled(CustomButton)`
  border: 1px solid var(--colorNeutralForeground5, #959ba2) !important;
  border-radius: 4px;
  color:  #707882 !important;
  width: 150px;
  height: 34px;
`
const StyledBox = styled(Box)`
  margin: 40px 50px 0px 70px;
  padding: 15px 0px 0px 15px;
  width: 430px;
  height: 140px;
  background-color: #F9FAFC;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`
const TypographyQuestion = styled(Typography)`
  margin: 0px 0px 15px 0px !important;
`
const BoxTypography = styled.div`
  width: 400px;
`
const QuestionBox = styled.div`
  display: flex;
  flex-direction: row;
`

export default EditSurveyPopup;
