import { ReactElement } from "react";

const AddRecipientsIcon = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18" fill="none">
      <path
        d="M14.4 7.938C14.1613 7.938 13.9324 8.03282 13.7636 8.2016C13.5948 8.37039 13.5 8.5993 13.5 8.838V15.3C13.5 15.5387 13.4052 15.7676 13.2364 15.9364C13.0676 16.1052 12.8387 16.2 12.6 16.2H2.7C2.46131 16.2 2.23239 16.1052 2.0636 15.9364C1.89482 15.7676 1.8 15.5387 1.8 15.3V5.4C1.8 5.1613 1.89482 4.93239 2.0636 4.7636C2.23239 4.59482 2.46131 4.5 2.7 4.5H9.162C9.40069 4.5 9.62961 4.40518 9.7984 4.2364C9.96718 4.06761 10.062 3.83869 10.062 3.6C10.062 3.3613 9.96718 3.13239 9.7984 2.9636C9.62961 2.79482 9.40069 2.7 9.162 2.7H2.7C1.98392 2.7 1.29716 2.98446 0.790812 3.49081C0.284464 3.99716 0 4.68392 0 5.4V15.3C0 16.0161 0.284464 16.7028 0.790812 17.2092C1.29716 17.7155 1.98392 18 2.7 18H12.6C13.3161 18 14.0028 17.7155 14.5092 17.2092C15.0155 16.7028 15.3 16.0161 15.3 15.3V8.838C15.3 8.5993 15.2052 8.37039 15.0364 8.2016C14.8676 8.03282 14.6387 7.938 14.4 7.938ZM17.928 0.558C17.8367 0.338086 17.6619 0.163327 17.442 0.0719999C17.3338 0.0258831 17.2176 0.00142199 17.1 0H11.7C11.4613 0 11.2324 0.0948211 11.0636 0.263604C10.8948 0.432387 10.8 0.661305 10.8 0.9C10.8 1.13869 10.8948 1.36761 11.0636 1.5364C11.2324 1.70518 11.4613 1.8 11.7 1.8H14.931L5.661 11.061C5.57664 11.1447 5.50969 11.2442 5.464 11.3539C5.41831 11.4636 5.39478 11.5812 5.39478 11.7C5.39478 11.8188 5.41831 11.9364 5.464 12.0461C5.50969 12.1558 5.57664 12.2553 5.661 12.339C5.74467 12.4234 5.84421 12.4903 5.95388 12.536C6.06355 12.5817 6.18119 12.6052 6.3 12.6052C6.41881 12.6052 6.53645 12.5817 6.64612 12.536C6.75579 12.4903 6.85533 12.4234 6.939 12.339L16.2 3.069V6.3C16.2 6.53869 16.2948 6.76761 16.4636 6.9364C16.6324 7.10518 16.8613 7.2 17.1 7.2C17.3387 7.2 17.5676 7.10518 17.7364 6.9364C17.9052 6.76761 18 6.53869 18 6.3V0.9C17.9986 0.78239 17.9741 0.6662 17.928 0.558Z"
        fill="#1477F8"
      />
    </svg>
  );
};

export default AddRecipientsIcon;
