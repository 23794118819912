import http from "api/https";

export const inviteAgainUsers = (
  invited_ids: number[],
  access_level_code: string,
  real_id: number,
  region_id: number
): Promise<any> => {
  if (http.isAPIGO) {
    return http.postGO("/permission/role/InviteAgainUsers", {
      invited_ids: invited_ids,
      access_level_code: access_level_code,
      real_id: real_id,
      region_id: region_id,
    });
  } else {
    return http.post("/role/InviteAgainUsers", {
      invited_ids: invited_ids,
      access_level_code: access_level_code,
      real_id: real_id,
      region_id: region_id,
    });
  }
};
