import { FC } from "react";
import { observer } from "mobx-react";
import { TScoredQuestionsDimension } from "types/dashboard";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import InfoIcon from "@mui/icons-material/Info";
import QuestionCard from "./QuestionCard";
import EnpsCard from "./EnpsCard";

type QuestionGroupProps = {
  dimension: TScoredQuestionsDimension;
  id: string;
  localFilters: { id: number; name: string; segments: string[] }[];
};

const QuestionGroup: FC<QuestionGroupProps> = observer((props) => {
  return (
    <Container id={props.id}>
      <TitleContainer>
        <TitleWrapper>
          <Title>{props.dimension?.dimension}</Title>
          {props.dimension.type === "enps" && (
            <CustomWidthTooltip
              title="The employee Net Promoter Score (eNPS) is a concept that based on the Net Promoter Score (NPS). 
            It allows companies to measure and get a quick easy snapshot of employee advocacy within their company."
              placement="top"
              arrow
            >
              <StyledInfoIcon />
            </CustomWidthTooltip>
          )}
        </TitleWrapper>
        {props.dimension.type !== "enps" && (
          <QuestionCounter>
            ({props.dimension?.questionsList.length}{" "}
            {props.dimension?.questionsList.length > 1 ? "questions" : "question"})
          </QuestionCounter>
        )}
      </TitleContainer>
      {props.dimension.type !== "enps" && (
        <QuestionCard
          question={{
            id: 0,
            title: `${props.dimension.dimension} score`,
            commentsCount: null,
            respondedCount: null,
            benchmarkDiff: props.dimension.benchmarkDiff,
            benchmark: props.dimension.allRespondentsPercentDiff,
            allRespondentsPercent: props.dimension.allRespondentsPercent,
            allRespondentsPercentDiff: props.dimension.allRespondentsPercentDiff,
            favourablePercent: props.dimension.favourablePercent,
            unFavourablePercent: props.dimension.unFavourablePercent,
            neutralPercent: props.dimension.neutralPercent,
            conditionalLogic: null,
            positiveCommentsCount: props.dimension.positiveCommentsCount,
            negativeCommentsCount: props.dimension.negativeCommentsCount,
            overall: false,
            passiveCommentsCount: props.dimension.passiveCommentsCount,
          }}
          key={`${props.dimension.dimension}-score`}
          isLastQuestion={Boolean(props.dimension?.questionsList.length < 1)}
          localFilters={props.localFilters}
        />
      )}
      {props.dimension?.questionsList.map((question, index) => {
        if (props.dimension.type !== "enps") {
          return (
            <QuestionCard
              question={question}
              key={question.id}
              isLastQuestion={Boolean(index === props.dimension?.questionsList.length - 1)}
              localFilters={props.localFilters}
              isQuestion
            />
          );
        } else
          return (
            <EnpsCard
              question={question}
              key={question.id}
              isLastQuestion={Boolean(index === props.dimension?.questionsList.length - 1)}
              localFilters={props.localFilters}
            />
          );
      })}
    </Container>
  );
});

export default QuestionGroup;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 80px 32px 75px;
  scroll-margin-top: 320px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
`;

const Title = styled.span`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorPaletteVioletBackground1);
`;

const QuestionCounter = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorPaletteVioletBackground1);
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "center",
    background: "var(--colorShadowInverted2)",
    marginLeft: "6px",
  },
});

const StyledInfoIcon = styled(InfoIcon)`
  color: var(--colorBrandForeground1);
  font-size: 20px !important;
  margin-left: 6px;
`;
