import { FC } from "react";

import styled from "styled-components";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

type EditingLabelProps = {
  text: string;
};

const EditingLabel: FC<EditingLabelProps> = (props) => {
  return (
    <Container>
      <WarningAmberIcon />
      <Text>
        {props.text}
      </Text>
    </Container>
  );
};

export default EditingLabel;

const Container = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 200px;
  background-color: var(--colorPaletteVioletBackground3);

  svg {
    color: var(--colorNeutralBackground1);
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralBackground1);
  margin-left: 10px;
`;
