import http from "api/https";
import {
  templ_question,
} from "types/templates";

export const saveTemplateQuestionDimensions = (templ_question_data: templ_question[]): Promise<any> => {
  let data = {
    templ_questions: templ_question_data
  }
  if(http.isAPIGO){
    return http.postGO('/template/templ_question_update_range', data)
  } else {
    return http.post("/templ_question/UpdateRange", data);
  }
};
