import http from "api/https";

export const getQuestionnaireTemplate = (id: number): Promise<any> => {
  if(http.isAPIGO){
    return http.getGO("/template/templ_template_questionary/" + id);
  } else {
    return http.get("/templ_template_questionary/" + id);
  }
};

export const getQuestionnaireTemplateForPreview = (id: number): Promise<any> => {
  if(http.isAPIGO){
    return http.getGO("/template/preview_templ_template_questionary/" + id);
  } else {
    return http.get("/templ_template_questionary/Preview/" + id);
  }
};