import http from "api/https";
import { createUrl } from "../../helpers/url";

export const levelStructure = (idEntity: number) => {
  const query: { entity_id: string } = { entity_id: idEntity.toString() };

  if (http.isAPIGO) {
    const url = createUrl({
      path: "/employee/organization_unit/has_configured",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/organization_unit/has_configured",
      query: query,
    });
    return http.get(url);
  }
};
