import http from "api/https";

export const hasManagersWithoutPermission = (
  idEntity: number
): Promise<any> => {
  if (http.isAPIGO) {
    return http.getGO("/employee/HasManagersWithoutPermission?idEntity=" + idEntity)
  } else {
    return http.get("/employees/HasManagersWithoutPermission?idEntity=" + idEntity)
  }
};
