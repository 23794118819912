import { Box, DialogContent, DialogTitle, FormControlLabel } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import CustomButton from "components/button/Button";
import Button from "components/button/Button";
import CustomRadioButton from "components/radioButton/CustomRadioButton";
import AttentionIcon from "./icons/AttentionIcon";
import store from "./store";
import storeEmployees from "./store";
import { CHOOSE_POINT, ORG_STRUCTURE_CODES } from "constants/entityManagementTypes";

type EmployeeStructuerePropsType = {
  onClose: () => void;
};

const EmployeeStructuereModal: FC<EmployeeStructuerePropsType> = observer(({ onClose }) => {
  const navigate = useNavigate();
  const { entityOrgStructure } = toJS(store);
  const [choose, setChoose] = React.useState<string>("");

  return (
    <StyledModalContent aria-label={"Create workspace form"}>
      <DialogTitleWrapper id={`Employees_OrganizationalStructure_SettingPopUp_Title_1`} >Setting up an organizational structure</DialogTitleWrapper>
      <TextBlock>
        <TextAttention>
          <AttentionIcon/> <span id={`Employees_OrganizationalStructure_SettingPopUp_Title_Error`}>Organizational structure needs to be set up before launching the first survey</span>      
        </TextAttention>
        {!entityOrgStructure ? (
          <>
            <TextMain id={`Employees_OrganizationalStructure_SettingPopUp_Body_Title`}>
              Setting up an organizational structure would help your organization:
              <ul>
                <li id={`Employees_OrganizationalStructure_SettingPopUp_Body_Li_1`}>View reports on the dashboard based on your organizational structure.</li>
                <li id={`Employees_OrganizationalStructure_SettingPopUp_Body_Li_2`} >
                  Easily set up permissions for specific users/ groups on EngageRocket’s platform
                </li>
              </ul>
            </TextMain>
            <TextPoint>
              <ol>
                <li id={`Employees_OrganizationalStructure_SettingPopUp_Body_Li_3`}>Define your organization structure</li>
              </ol>
              <span id={`Employees_OrganizationalStructure_SettingPopUp_Body_Li_4`}>
                Once setup, the organization structure cannot be changed as this determines how
                reports are structured.
              </span>
            </TextPoint>
          </>
        ) : (
          <TextPoint>
            <ol start={2}>
              <li id={`Employees_OrganizationalStructure_SettingPopUp_Body_Li_5`}>Choose how to import your employees</li>
            </ol>
          </TextPoint>
        )}
      </TextBlock>
      <Box display={"flex"}>
        {!entityOrgStructure ? (
          <RadioWrapper
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            onChange={(e) =>
              setTimeout(() => store.setOrgStructure(e.target.value.toString()), 200)
            }
          >
            <StyledRadioWrapper
              key={1}
              $isChecked={entityOrgStructure === ORG_STRUCTURE_CODES.manager_based}
            >
              <StyledRadio
              id={`Employees_OrganizationalStructure_SettingPopUp_RadioManagerBased`}
                value={ORG_STRUCTURE_CODES.manager_based}
                control={<CustomRadioButton id={`Employees_OrganizationalStructure_SettingPopUp_RadioManagerBased_Btn`} />}
                label={
                  <React.Fragment>
                    <RadioLabel>
                      <TextMain id={`Employees_OrganizationalStructure_SettingPopUp_RadioManagerBased_Title`}>Manager-based</TextMain>
                      <TextSecondary id={`Employees_OrganizationalStructure_SettingPopUp_RadioManagerBased_Subtitle`} >All employees are tagged to their managers.</TextSecondary>
                    </RadioLabel>
                  </React.Fragment>
                }
                labelPlacement="start"
              />
            </StyledRadioWrapper>

            <StyledRadioWrapper
              key={2}
              $isChecked={entityOrgStructure === ORG_STRUCTURE_CODES.unit_based}
            >
              <StyledRadio
              id={`Employees_OrganizationalStructure_SettingPopUp_RadioUnitBased`}
                value={ORG_STRUCTURE_CODES.unit_based}
                control={<CustomRadioButton id={`Employees_OrganizationalStructure_SettingPopUp_RadioUnitBased_Btn`} />}
                label={
                  <React.Fragment>
                    <RadioLabel>
                      <TextMain id={`Employees_OrganizationalStructure_SettingPopUp_RadioUnitBased_Title`}>Unit-based</TextMain>
                      <TextSecondary id={`Employees_OrganizationalStructure_SettingPopUp_RadioUnitBased_Subtitle`}>
                        Employees are grouped by units (Examples of units are departments/
                        divisions).
                      </TextSecondary>
                    </RadioLabel>
                  </React.Fragment>
                }
                labelPlacement="start"
              />
            </StyledRadioWrapper>
          </RadioWrapper>
        ) : (
          <RadioWrapper
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            onChange={(e) => setChoose(e.target.value.toString())}
          >
            <StyledRadioWrapper key={1} $isChecked={choose === CHOOSE_POINT.excel_import}>
              <StyledRadio
              id={`Employees_OrganizationalStructure_SettingPopUp_RadioExcelImport`}
                value={CHOOSE_POINT.excel_import}
                control={<CustomRadioButton id={`Employees_OrganizationalStructure_SettingPopUp_RadioExcelImport_Btn`}/>}
                label={
                  <React.Fragment>
                    <RadioLabel>
                      <TextMain id={`Employees_OrganizationalStructure_SettingPopUp_RadioExcelImport_Title`}>Excel import</TextMain>
                      <TextSecondary id={`Employees_OrganizationalStructure_SettingPopUp_RadioExcelImport_Subtitle`}>
                        A customized Excel template will be created once your attributes are
                        established, allowing for easy uploading.
                      </TextSecondary>
                    </RadioLabel>
                  </React.Fragment>
                }
                labelPlacement="start"
              />
            </StyledRadioWrapper>

            <StyledRadioWrapper key={2} $isChecked={choose === CHOOSE_POINT.link_to_hris}>
              <StyledRadio
              id={`Employees_OrganizationalStructure_SettingPopUp_RadioLinkToHRIS`}
                value={CHOOSE_POINT.link_to_hris}
                control={<CustomRadioButton id={`Employees_OrganizationalStructure_SettingPopUp_RadioLinkToHRIS_Btn`}/>}
                label={
                  <React.Fragment>
                    <RadioLabel>
                      <TextMain id={`Employees_OrganizationalStructure_SettingPopUp_RadioLinkToHRIS_Title`}>Link to HRIS</TextMain>
                      <TextSecondary id={`Employees_OrganizationalStructure_SettingPopUp_RadioLinkToHRIS_Subtitle`}>
                        Your EngageRocket Customer Success Manager will get in touch within 1-2
                        business days
                      </TextSecondary>
                    </RadioLabel>
                  </React.Fragment>
                }
                labelPlacement="start"
              />
            </StyledRadioWrapper>
          </RadioWrapper>
        )}
      </Box>

      {(entityOrgStructure === ORG_STRUCTURE_CODES.manager_based ||
        entityOrgStructure === ORG_STRUCTURE_CODES.unit_based) && (
        <>
          {entityOrgStructure === ORG_STRUCTURE_CODES.manager_based ? (
            <TextPoint>
              <ol start={3}>
                <li id={`Employees_OrganizationalStructure_SettingPopUp_Footer_Title`}>Set up attributes</li>
              </ol>
              <span id={`Employees_OrganizationalStructure_SettingPopUp_Footer_Subtitle`}>Setup attributes to help you slice and dice your survey data.</span>
            </TextPoint>
          ) : (
            <TextPoint>
              <ol start={3}>
                <li id={`Employees_OrganizationalStructure_SettingPopUp_Footer_Subtitle2`}>Set up orgranizational structure</li>
              </ol>
            </TextPoint>
          )}
          <ButtonWrapper>
            <StyledNextButton
              id={`Employees_OrganizationalStructure_SettingPopUp_Btn`}
              variant="contained"
              disabled={!entityOrgStructure || !choose}
              onClick={() => {
                store.saveOrgStructure(() => onClose());
                if (store.entityOrgStructure === ORG_STRUCTURE_CODES.manager_based) {
                  if (choose === CHOOSE_POINT.link_to_hris) {
                    storeEmployees.hrisLinkNotified();
                    navigate("/employees?nav=attributeList");
                  } else {
                    navigate("/employees?nav=attributeList");
                  }
                }
                if (store.entityOrgStructure === ORG_STRUCTURE_CODES.unit_based) {
                  if (choose === CHOOSE_POINT.link_to_hris) {
                    storeEmployees.hrisLinkNotified();
                    navigate("/employees?nav=structure");
                  } else {
                    navigate("/employees?nav=structure");
                  }
                }
              }}
            >
              {entityOrgStructure === ORG_STRUCTURE_CODES.manager_based
                ? "+ Add attributes"
                : "Set up"}
            </StyledNextButton>
          </ButtonWrapper>
        </>
      )}

      <PointWrapper>
        <CustomCircleButton
        id={`Employees_OrganizationalStructure_SettingPopUp_Footer_PageBtn_1`}
        $isCheckedPage={!entityOrgStructure} variant="text">
          1
        </CustomCircleButton>
        <CustomCircleButton
          id={`Employees_OrganizationalStructure_SettingPopUp_Footer_PageBtn_2`}
          $isCheckedPage={
            entityOrgStructure === ORG_STRUCTURE_CODES.manager_based ||
            entityOrgStructure === ORG_STRUCTURE_CODES.unit_based
          }
          variant="text"
        >
          2
        </CustomCircleButton>
      </PointWrapper>
    </StyledModalContent>
  );
});

const StyledModalContent = styled(DialogContent)`
  //margin: 10px 20px;
`;

const RadioWrapper = styled(RadioGroup)`
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
  column-gap: 11px;
`;

const RadioLabel = styled.div`
  margin: 0;
`;

const StyledRadioWrapper = styled.div<{ $isChecked: boolean }>`
  width: 187px;
  min-height: 170px;
  display: flex;
  flex-direction: column;
  border: 1px solid
    ${(props) =>
      props.$isChecked ? "var(--colorBrandForeground1)" : "var(--colorPaletteBlueBackground1)"};
  border-radius: 4px;
  position: relative;
  padding: 20px 9px 20px 22px;
`;

const StyledNextButton = styled(CustomButton)`
  width: 100%;
  margin-right: 20px;
  max-width: 200px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  align-items: center;
`;

const DialogTitleWrapper = styled(DialogTitle)`
  color: var(--colorNeutralForeground1);
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 32px !important;
  padding-left: 0 !important;
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const TextMain = styled.div`
  color: var(--colorNeutralForeground1);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;

  ul {
    padding-left: 16px;
  }
`;

const TextPoint = styled.div`
  color: var(--colorNeutralForeground1);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  ol {
    padding-left: 24px;
    margin-bottom: 5px;
  }

  span {
    color: var(--colorNeutralForeground2);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

const TextAttention = styled.span`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  border-radius: 4px;
  background: var(--graySelectBoxBorder);
  margin-bottom: 30px;

  svg {
    margin-right: 9px;
  }
`;

const TextSecondary = styled(TextMain)`
  color: var(--colorNeutralForeground2);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 156px;
  margin-top: 20px;
`;

const StyledRadio = styled(FormControlLabel)`
  width: 100%;
  max-width: 164px;
  //padding: 0 0 20px;
  margin: 0 !important;
  justify-content: space-between;

  span {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    padding: 0;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  .MuiRadio-root {
    position: absolute;
    top: 17px;
  }
`;

const PointWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const CustomCircleButton = styled(Button)<{ $isCheckedPage: boolean }>`
  width: 16px;
  height: 16px;
  min-width: 16px !important;
  margin-right: 10px !important;
  border: 1px solid
    ${(props) =>
      props.$isCheckedPage
        ? "var(--graySelectBoxBorder)"
        : "var(--colorGrayForeground6)"} !important;
  background-color: ${(props) =>
    props.$isCheckedPage ? "var(--graySelectBoxBorder)" : "var(--colorGrayForeground6)"} !important;
  border-radius: 50% !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  color: ${(props) =>
    props.$isCheckedPage
      ? "var(--colorNeutralForeground2)"
      : "var(--colorNeutralForeground6)"} !important;
  padding: 0 !important;
  cursor: not-allowed !important;
`;

export default EmployeeStructuereModal;
