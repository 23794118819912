import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

import store from "../../store";
import styled from "styled-components";
import Highcharts from "highcharts";
import solidGauge from "highcharts/modules/solid-gauge";
import highchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CardLoader } from "features/Dashboard/CardLoader";

type OpenEndedQuestionsCardProps = {};

highchartsMore(Highcharts);
solidGauge(Highcharts);

const OpenEndedQuestionsCard: FC<OpenEndedQuestionsCardProps> = observer(() => {
  const [graphData, setGraphData] = useState([]);
  const [graphOptions, setGraphOptions] = useState({
    maxX: 0,
    minX: 0,

    minY: 0,
    maxY: 0,
  });

  const calculateYValue = (
    sentimentValue: number,
    arrayRepeats: { value: number; countRepeat: number }[],
    maxY: number,
    indexEl: number
  ) => {
    let yValue: number = 0;

    const objCounterRepeats = arrayRepeats.find((el) => el.value === sentimentValue);

    if (objCounterRepeats.countRepeat === 1) {
      yValue = Math.floor(Math.random() * (maxY - 40) + 20);
    } else {
      yValue = 10 + ((maxY - 25) / objCounterRepeats.countRepeat) * indexEl;
    }

    return yValue;
  };

  useEffect(() => {
    if (
      store.projectInfo &&
      store.projectInfo?.openEndQuestion &&
      store.projectInfo?.openEndQuestion.length
    ) {
      const minXvalue = store.projectInfo?.openEndQuestion.sort(
        (a, b) => a.sentiment - b.sentiment
      )[0]?.sentiment;
      const maxXvalue = store.projectInfo?.openEndQuestion.sort(
        (a, b) => b.sentiment - a.sentiment
      )[0]?.sentiment;

      const minX = minXvalue - 20;
      const maxX = maxXvalue + 20;

      const step = (Math.abs(maxX) + Math.abs(minX)) / 5;

      //********* calculate y position on graph *********//

      const counterRepeatXValues = {};

      store.projectInfo?.openEndQuestion.forEach(function (item) {
        if (counterRepeatXValues[item.sentiment]) {
          counterRepeatXValues[item.sentiment].countRepeat += 1;
        } else {
          counterRepeatXValues[item.sentiment] = { ...item, value: item.sentiment, countRepeat: 1 };
        }
      });

      const maxRepeatXValue: any = Object.values(counterRepeatXValues).reduce(
        (
          acc: { countRepeat: number; value: number },
          curr: { countRepeat: number; value: number }
        ) => (acc.countRepeat > curr.countRepeat ? acc : curr)
      );

      const arrayWithArrays = [];

      Object.values(counterRepeatXValues).map((el: { countRepeat: number; value: number }) => {
        let count = 0;
        const newArray = store.projectInfo?.openEndQuestion.map((item) => {
          if (item.sentiment === el.value) {
            count++;
            return {
              ...item,
              innerIndex: count,
            };
          } else return null;
        });
        arrayWithArrays.push(newArray.filter((el) => el));
      });

      const resultModifyArray = [].concat(...arrayWithArrays);

      //********* ********* * ******** ** ***** *********//

      //********* calculating the color of a circle depending on the value *********//

      const getColor = (el: { sentiment: number }) => {
        if (el.sentiment < step && el.sentiment > -step) {
          return "#F1F1F1";
        }
        if (el.sentiment > minX && el.sentiment < minX + step) {
          return "#BD0202";
        }
        if (el.sentiment < step && el.sentiment > minX + step) {
          return "#FFD8D8";
        }
        if (el.sentiment > step && el.sentiment < maxX - step) {
          return "#D8FAFF";
        }
        return "#007B8C";
      };

      const newData = resultModifyArray.map((el, index) => ({
        x: el.sentiment,
        y: calculateYValue(
          el.sentiment,
          Object.values(counterRepeatXValues),
          maxRepeatXValue.countRepeat * 10 + 20,
          el.innerIndex
        ),
        z: el.size,
        name: el.name,
        color: getColor(el),
      }));

      //********* *********** *** ***** ** * ****** ********* ** *** ***** *********//

      setGraphData(newData);
      setGraphOptions({
        maxX,
        minX,
        minY: 0,
        maxY: maxRepeatXValue.countRepeat * 10 + 20,
      });
    }
  }, [store.projectInfo?.openEndQuestion]);

  const chartOptions = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "bubble",
      marginTop: 50,
      height: 450,
    },
    title: {
      text: "",
    },

    tooltip: {
      useHTML: true,
      backgroundColor: "var(--colorShadowInverted2)",
      headerFormat: "",
      pointFormat: `<b>{point.name}</b><br/><td>Sentiments: {point.x}</td>`,
      shared: false,
      style: {
        color: "var(--colorNeutralBackground1)",
      },
    },

    plotOptions: {
      bubble: {
        animation: false,
        showInLegend: false,
        crisp: true,
        dataLabels: {
          enabled: true,
          format: "{point.name}",
          style: {
            color: "var(--colorNeutralForeground1)",
            textOutline: "none",
            fontWeight: "500",
            fontSize: "14",
            textOverflow: "ellipsis",
          },
        },
      },
    },
    xAxis: {
      height: 400,
      width: "95%",
      visible: true,
      ceiling: graphOptions.maxX,
      floor: graphOptions.minX,
      max: graphOptions.maxX,
      min: graphOptions.minX,
      lineColor: "transparent",
      tickWidth: 0,
      gridLineWidth: 1,
      showLastLabel: true,
      angle: 20,
      offset: -450,
      left: 50,
      labels: {
        enabled: true,
        useHtml: true,
        formatter: (e) => {
          if (e.isFirst) {
            return `<p style="font-size: 14px; color: var(--colorNeutralForeground1); font-weight: 400; width: 50px"><b>Negative</b> </br> ${e.value}% </p>`;
          } else if (e.isLast) {
            return `<p style="font-size: 14px; color: var(--colorNeutralForeground1); font-weight: 400;"><b>Positive</b> </br> ${e.value}% </p>`;
          } else if (e.value === 0) {
            return `<p style="font-size: 14px; color: var(--colorNeutralForeground1); font-weight: 400;"><b>Neutral</b> </br> ${e.value}% </p>`;
          } else return ``;
        },
        align: "center",
      },
    },
    yAxis: {
      height: 400,
      visible: false,
      ceiling: graphOptions.maxY,
      floor: graphOptions.minY,
      min: graphOptions.minY,
      max: graphOptions.maxY,
    },

    series: {
      dataLabels: {
        enabled: true,
        format: "{point.name}",
        style: {
          color: "var(--colorNeutralForeground1)",
          textOutline: "none",
          fontWeight: "500",
          fontSize: "14",
          textOverflow: "ellipsis",
        },
      },

      maxSize: "35%",
      minSize: "15%",
      data: graphData,
      enableMouseTracking: true,
    },
  };

  const isPending = store.isOpenEndPending || !store.projectInfo;

  return (
    <Card>
      <TitleRow>
        <Title>Topics from open-ended questions</Title>
        {!isPending && (
          <ViewLink onClick={store.redirectOpenEnded}>View open-ended responses</ViewLink>
        )}
      </TitleRow>
      {!isPending && (
        <>
          <TitleSelect>
            <TitleText>Question selected:</TitleText>
            <StyledSelect
              id="question_selected"
              value={store.questionSelectedOpenEndedCard}
              onChange={(e: SelectChangeEvent<string>) =>
                store.setData(e.target.value, "questionSelectedOpenEndedCard")
              }
              displayEmpty
              IconComponent={KeyboardArrowDownIcon}
              MenuProps={{
                PaperProps: { sx: { maxHeight: 300, maxWidth: "10%", minWidth: 100 } },
              }}
            >
              {store.surveyQuestionsList.map((question) => (
                <StyledMenuItem key={question.value} value={question.value}>
                  <em>{question.label}</em>
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </TitleSelect>
          <TitleInfo>
            <Group>
              <TitleText>Topics: {store.openEndedTopics || "-"}</TitleText>
              <TitleText>Topic-valid responses: {store.openEndedTopicsValid || "-"}</TitleText>
            </Group>
          </TitleInfo>
        </>
      )}
      <ChartWrapper>
        {isPending ? <CardLoader contentHeight={720} /> : <HighchartsReact highcharts={Highcharts} options={chartOptions} />}
      </ChartWrapper>
    </Card>
  );
});

export default OpenEndedQuestionsCard;

const Card = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--colorNeutralBackground1);
`;

const TitleRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-right: 4px;
`;

const ViewLink = styled.a`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  margin-left: 8px;
  color: var(--colorBrandForeground1);
  transition: all 0.3s;

  &:hover {
    color: var(--colorBrandForeground2);
  }
`;

const ChartWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  min-height: 500px;
`;

const TitleSelect = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 48px;
`;

const TitleInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  padding: 16px 0px;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
`;

const TitleText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-right: 20px;
  white-space: nowrap;
`;

const Group = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchText = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  color: var(--colorNeutralForeground1);
  margin-right: 9px;
  white-space: nowrap;
`;

const StyledSelect = styled(Select)`
  height: 34px;
  width: 100% !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  font-weight: 400 !important;

  em {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground1) !important;
    font-style: normal !important;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  white-space: normal !important;
  em {
    font-style: normal !important;
  }
`;
