import { ENPS } from "constants/dashboard-overview";
import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import {
    TTrendCardData,
    TTrendCardDataWithBenchmark,
    TTrendsCard,
    TTrendsQuestion,
} from "types/dashboard";
import {
    GRAPH_LABELS,
    QUESTION_TYPE_VALUES,
    SCORE_TYPE_ENPS_VALUES,
    SCORE_TYPE_OVERAL_SCORED_VALUES,
    TOOLTIP_TYPE_BENCHMARK,
    TOOLTIP_TYPE_VALUE,
} from "constants/dashboard-trends";
import {
    ENPS_TOOLTIP,
    OVERAL_SCORE_TOOLTIP,
    SCORED_QUESTIONS_TOOLTIP,
    returnCenteredInfo,
    returnRespondents,
    returnTitle,
} from "./tooltips";

import store from "../store";
import styled from "styled-components";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

type BarGraphProps = {
    graphData: TTrendCardDataWithBenchmark[] | TTrendCardData[];
    card: TTrendsCard;
    localQuestionsList: TTrendsQuestion[];
};

const BarGraph: FC<BarGraphProps> = observer((props) => {
    const [graphValues, setGraphValues] = useState([]);
    const [graphCategories, setGraphCategories] = useState([]);

    const returnGraphLabels = () => {
        if (props.card.question_type === QUESTION_TYPE_VALUES.enps_question)
            return SCORE_TYPE_ENPS_VALUES[props.card.score_type];
        else if (
            props.card.question_type === QUESTION_TYPE_VALUES.overal_score ||
            props.card.question_type === QUESTION_TYPE_VALUES.scored_question
        )
            return SCORE_TYPE_OVERAL_SCORED_VALUES[props.card.score_type];
        else return GRAPH_LABELS.percent_respondents;
    };

    const returnTooltipText = () => {
        let dimension = "";
        let question = "";

        if (props.card.selectedQuestionsList && props.card.selectedQuestionsList.length > 0) {
            props.localQuestionsList.forEach((el) => {
                let findedQuestion = el.list.find(
                    (question) => question.id === props.card.selectedQuestionsList[0]
                );
                if (findedQuestion) {
                    dimension = el.dimension;
                    question = findedQuestion.title;
                }
            });
        }

        return `${dimension}: ${question}`;
    };

    useEffect(() => {
        let categories = null;
        let values = null;
        if (props.graphData) {
            if (props.card.isCompareWithBenchmark) {
                //@ts-ignore
                categories = props.graphData[0]?.data?.map((el) => el.name);
                values = props.graphData?.map((el, index) => {
                    const newList = el?.data?.map((item) => ({
                        y: item.value,
                        name: item.name,
                        text: returnTooltipText(),
                        respondentsCount: index % 2 === 0 ? returnRespondents(item.respondents) : "",
                        overalTitle: returnTitle(
                            item.name,
                            index % 2 === 0 ? TOOLTIP_TYPE_VALUE : TOOLTIP_TYPE_BENCHMARK
                        ),
                        centeredTooltipInfo:
                            index % 2 === 0
                                ? returnCenteredInfo(item.name, item.value, TOOLTIP_TYPE_VALUE)
                                : returnCenteredInfo(
                                    `Benchmark across ${item.name.toLowerCase()}`,
                                    item.value,
                                    TOOLTIP_TYPE_BENCHMARK
                                ),
                        score: item.score,
                        type: index % 2 === 0 ? TOOLTIP_TYPE_VALUE : TOOLTIP_TYPE_BENCHMARK,
                    }));
                    return {
                        ...el,
                        data: newList,
                        colors: index % 2 === 0 ? ["#DB9EFF"] : ["#FCC658"],
                        tooltip: {
                            customTooltipPerSeries: function () {
                                return this.series.name;
                            },
                        },
                    };
                });
            } else {
                categories = props.graphData?.map((el) => el.name);
                values = {
                    data: props.graphData?.map((el) => ({
                        y: el.value,
                        name: el.name,
                        score: el.name,
                        text: returnTooltipText(),
                        respondentsCount: returnRespondents(el.respondents),
                        overalTitle: returnTitle(el.name, TOOLTIP_TYPE_VALUE),
                        centeredTooltipInfo: returnCenteredInfo(el.name, el.value, TOOLTIP_TYPE_VALUE),
                    })),
                };
            }
            setGraphCategories(categories);
            setGraphValues(values);
        }
    }, [props.card]);

    const chartOptions = {
        credits: {
            enabled: false,
        },
        chart: {
            type: "column",
            height: "410px",
            spacingLeft: 0,
            spacingRight: 0,
            spacing: [10, 0, 10, 0],
            events: {
                load: function () {
                    this.reflow();
                },
            },
        },

        tooltip: {
            useHTML: true,
            backgroundColor: "var(--colorShadowInverted2)",
            headerFormat: '<span style="font-size:12px;"></span><table>',
            pointFormat:
                props.card.question_type === QUESTION_TYPE_VALUES.scored_question
                    ? SCORED_QUESTIONS_TOOLTIP
                    : props.card.question_type === QUESTION_TYPE_VALUES.enps_question &&
                        props.card.score_type === SCORE_TYPE_ENPS_VALUES.ENPS.toLocaleUpperCase()
                        ? ENPS_TOOLTIP
                        : OVERAL_SCORE_TOOLTIP,
            footerFormat: "</table>",
            shared: false,
            style: {
                color: "var(--colorNeutralBackground1)",
            },
        },
        title: {
            text: "",
        },

        series: graphValues,

        plotOptions: {
            column: {
                maxPointWidth:
                    props.card.isCompareWithBenchmark &&
                        props.card.question_type !== QUESTION_TYPE_VALUES.yes_no &&
                        props.card.question_type !== QUESTION_TYPE_VALUES.msq
                        ? 24
                        : 36,
                borderRadius: 0,
                allowPointSelect: false,
                cursor: "pointer",
                dataLabels: {
                    enabled: false,
                },
                showInLegend: false,
                colorByPoint: true,
                colors: ["#DB9EFF"],
            },
        },
        xAxis: {
            categories: graphCategories,
            gridLineWidth: 0,
            lineWidth: 1,
            lineColor: `var(--colorNeutralForeground6)`,
            crosshair: true,
        },
        yAxis: {
            visible: true,
            gridLineWidth: 1,
            lineWidth: 1,
            lineColor: `var(--colorNeutralForeground6)`,
            title: null,
            labels: {
                align: "right",
                distance: 5,
                formatter: (e) => {
                    if (e.value !== 0 && store.overviewTrendCardFilter !== ENPS) {
                        return `<p style="
                  font-family: Roboto;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  color:var(--colorNeutralForeground2);">
                  ${props.card.question_type === QUESTION_TYPE_VALUES.enps_question
                                ? e.value
                                : `${e.value}%`
                            }
                  </p>`;
                    } else
                        return `<p style="
                          font-family: Roboto;
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 20px;
                          color:var(--colorNeutralForeground2);">
                          ${e.value}
                          </p>`;
                },
            },
        },
    };

    return (
        <Container>
            <TypeLabel>{returnGraphLabels()}</TypeLabel>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} style={{ width: "100%" }} />
        </Container>
    );
});

export default BarGraph;

const Container = styled.div`
  width: 100%;
  padding: 0px 48px;
  margin-top: 40px;
`;

const TypeLabel = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  margin-bottom: 10px;
`;
