import { makeAutoObservable } from "mobx";
import {
  QuestionnaireTemplate,
  dictionary_language,
  question_dimension,
  templ_question,
} from "types/templates";
import { sm_survey } from "types/surveys";
import PreviewStore from "layouts/SurveyPreview/PreviewStore";
import { getQuestionnaireTemplateForPreview } from "api/go/useGetQuestionnaireTemplate";
import { getSurveyForPreview } from "api/useGetSurvey";
import { getAllLanguagesForSurvey } from "api/go/useGetAllLanguagesForSurvey";
import { getTemplateQuestionDimensions } from "api/go/useGetTemplateQuestionDimensions";
import { getAllQuestionDimensions } from "api/go/useGetAllQuestionDimensions";
import modalStore from "features/QuestionTemplate/store";
import { surveyLocalization } from "survey-core";

class Store {
  idTemplate = 0;
  idSurvey = "";

  QuestionnaireTemplate: QuestionnaireTemplate = null;
  Survey: sm_survey = null;
  SupportedLanguages: dictionary_language[] = [];
  TemplateQuestionDimensions: templ_question[] = [];
  QuestionDimensions: question_dimension[] = [];

  survey_text: string = "";
  activePage: "edit" | "dimension" = "edit";

  constructor() {
    makeAutoObservable(this);
  }

  loadAllDataTemplate = (id: number) => {
    if (id === 0) {
      return alert(id);
    }
    this.loadMainInformation(id);
    this.getAllSupportedLanguages();
    this.getAllQuestionDimensions();
  };
  loadAllDataSurvey = (id: string) => {
    if (id === "") {
      return alert(id);
    }
    this.loadSurvey(id);
    this.getAllSupportedLanguages();
    this.getAllQuestionDimensions();
  };

  loadMainInformation(id: number) {
    PreviewStore.changeLoader(true);
    getQuestionnaireTemplateForPreview(id).then((x) => {
      PreviewStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.QuestionnaireTemplate = x.data;
        modalStore.templateId = id;
        this.changeMainSurvey(x.data.survey)
      }
    });
  }

  loadSurvey(id_survey: string) {
    PreviewStore.changeLoader(true);
    getSurveyForPreview(id_survey).then((x) => {
      PreviewStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.Survey = x.data;
        this.changeMainSurvey(x.data.questionary_survey)
      }
    });
  }

  getAllSupportedLanguages() {
    PreviewStore.changeLoader(true);
    getAllLanguagesForSurvey().then((x) => {
      PreviewStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.SupportedLanguages = x.data;
        surveyLocalization.supportedLocales = this.SupportedLanguages.map((lang) => lang.iso);
      }
    });
  }

  getTemplateQuestionDimensions() {
    PreviewStore.changeLoader(true);
    getTemplateQuestionDimensions(this.idTemplate).then((x) => {
      PreviewStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.TemplateQuestionDimensions = x.data;
      }
    });
  }

  getAllQuestionDimensions() {
    PreviewStore.changeLoader(true);
    getAllQuestionDimensions().then((x) => {
      PreviewStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.QuestionDimensions = x.data;
      }
    });
  }

  copyLinkToClipboard = () => {
    if (this.Survey !== null) {
      if (this.Survey.code) {
        navigator.clipboard.writeText((window["_env_"]?.REACT_APP_MAIN_FRONTEND_URL ?? process.env.REACT_APP_MAIN_FRONTEND_URL) + "survey-preview?idSurvey=" + this.Survey.code);
      } else {
        navigator.clipboard.writeText((window["_env_"]?.REACT_APP_MAIN_FRONTEND_URL ?? process.env.REACT_APP_MAIN_FRONTEND_URL) + "survey-preview?idSurvey=" + store.idSurvey);
      }
      PreviewStore.setSnackbar("Link copied to clipboard!")
    }
    if (this.QuestionnaireTemplate !== null) {
      navigator.clipboard.writeText((window["_env_"]?.REACT_APP_MAIN_FRONTEND_URL ?? process.env.REACT_APP_MAIN_FRONTEND_URL) + "survey-preview?idTemplate=" + store.idTemplate)
      PreviewStore.setSnackbar("Link copied to clipboard!")
    }
  }

  changeMainSurvey = (survey_text: string) => {
    this.survey_text = survey_text;
  };
}

const store = new Store();
export default store;
