import { ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { SurveyQuestionElementBase } from "survey-react-ui";
import ENPSQuestionModel from "./ENPSQuestionModel";

const Container = styled.div`
  display: flex;
  column-gap: 8px;
  max-width: 100%;
  width: 100%;
`

const RatingContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: ${({ $isMobile }): string => $isMobile ? "column" : "row"};
`

const Fieldset = styled.fieldset<{ $isMobile: boolean }>`
  display: flex;
  border: none;
  padding: 0 0 calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))) 0;
  flex-wrap: wrap;
  gap: calc(1 * var(--sjs-base-unit, var(--base-unit, 8px)));
  margin-inline-start: 0;
  align-items: center;

  ${({ $isMobile }): string => $isMobile ? `
    gap: 0;
    justify-content: space-between;
  `: ""}
`

const Label = styled.label<{ $isFixedSize?: boolean, $isSelected: boolean, $isMobile: boolean }>`
  position: relative;
  background: var(--sjs-general-backcolor, var(--background, #fff));
  box-shadow: 0px 1px 2px rgba(0,0,0,.15);
  border-radius: calc(12.5 * var(--sjs-base-unit, var(--base-unit, 8px)));
  white-space: nowrap;
  padding: calc(0.5 * var(--sjs-base-unit, var(--base-unit, 8px))) calc(2.5 * var(--sjs-base-unit, var(--base-unit, 8px)));
  height: calc(6 * var(--sjs-base-unit, var(--base-unit, 8px)));
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-width: calc(6 * var(--sjs-base-unit, var(--base-unit, 8px)));
  text-align: center;
  border: 2px solid var(--sjs-general-backcolor, var(--background, #fff));
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  fill: var(--sjs-general-forecolor, var(--foreground, #161616));
  font-size: calc(1 * var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px)))));

  ${({ $isFixedSize }): string => $isFixedSize ? `
    width: calc(6 * var(--sjs-base-unit, var(--base-unit, 8px)));
    padding: 0;
  ` : ""}

  &:hover {
    background-color: var(--sjs-questionpanel-hovercolor, var(--sjs-general-backcolor-dark, rgb(248, 248, 248)));
    border-color: var(--sjs-questionpanel-hovercolor, var(--sjs-general-backcolor-dark, rgb(248, 248, 248)));
  }

  ${({ $isSelected }): string => $isSelected ? `
    background-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
    border-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
    color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
    font-weight: 600;
    box-shadow: 0px 1px 2px rgba(0,0,0,0);

    &:hover {
      background-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
      border-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
    }
  ` : ""}

  ${({ $isMobile }): string => $isMobile ? `
    height: 24px;
    width: 24px;
    min-width: 24px;
  ` : ""}
`

const RadioInput = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
`

const RateDescriptionContainerMobile = styled.div`
  display: flex;
  justify-content: space-between;
`

const RateDescription = styled.span`
  max-width: 140px;
  white-space: initial;

  &.sd-rating__max-text {
    text-align: right;
  }

  && {
    && {
      font-size: 12px;
      margin: 0;
    }
  }
`

export default class ENPSSurveyQuestion extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.handleSelectValue = this.handleSelectValue.bind(this);
    this.state = { value: this.question.value };
  }

  protected get question(): ENPSQuestionModel {
    return this.questionBase as ENPSQuestionModel;
  }
  
  handleSelectValue(event: any): void {
    this.question.setValueFromClick(event.target.value);
    this.setState({ value: this.question.value });
  }

  protected renderItem(item: any, index: number): ReactElement {
    const renderRadio = (children: ReactNode, hasFixedSize: boolean): ReactElement => (
      <Label
        key={item.value}
        $isSelected={this.question.value === item.value}
        $isFixedSize={hasFixedSize}
        $isMobile={this.question.isMobile}
      >
        <RadioInput
          type="radio"
          name={this.question.name}
          id={this.question.getInputId(index)}
          value={item.value}
          disabled={this.isDisplayMode}
          checked={this.question.value === item.value}
          onClick={this.handleSelectValue}
          onChange={(): void => void 0}
          aria-required={this.question.ariaRequired}
          aria-label={this.question.ariaLabel}
          aria-invalid={this.question.ariaInvalid}
          aria-describedby={this.question.ariaDescribedBy}
        />
        {children}
      </Label>
    )

    return renderRadio(item.text, true)
  }

  protected renderElement(): ReactElement {
    const readOnly = this.props.creator.readOnly

    const minText = this.question.minRateDescription
      ? this.renderLocString(this.question.locMinRateDescription)
      : null;

    const maxText = this.question.maxRateDescription
      ? this.renderLocString(this.question.locMaxRateDescription)
      : null;
      
    return (
      <Container>
        <RatingContainer $isMobile={this.question.isMobile}>
          {this.question.isMobile ? (
            <RateDescriptionContainerMobile>
              {!!this.question.minRateDescription ? (
                <RateDescription className="sd-rating__item-text sd-rating__min-text">
                  {minText}
                </RateDescription>
              ) : null}

              {!!this.question.maxRateDescription ? (
                <RateDescription className="sd-rating__item-text sd-rating__max-text">
                  {maxText}
                </RateDescription>
              ) : null}
            </RateDescriptionContainerMobile>
          ) : null}

          {this.question.isMobile ? null : (
            !!this.question.minRateDescription ? (
              <span className="sd-rating__item-text sd-rating__min-text">
                {minText}
              </span>
            ) : null
          )}

          <Fieldset $isMobile={this.question.isMobile}>
            {this.question.renderedRateItems.map((item, index) => this.renderItem(item, index))}
          </Fieldset>

          {this.question.isMobile ? null : (
            !!this.question.maxRateDescription ? (
              <span className="sd-rating__item-text sd-rating__max-text">
                {maxText}
              </span>
            ) : null
          )}
        </RatingContainer>
      </Container>
    )
  }
}
