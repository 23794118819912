import { FC } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router";

import { createUrl } from "helpers/url";
import CustomButton from "components/button/Button";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";
import { CircleChevronContainedIcon } from "../icons/CircleChevronContainedIcon";
import storeSurveyCreation from "features/Survey/SurveyCreation/store";
import storeSurveyAccess from "features/Survey/Communication/store";
import storeRecipients from "features/Survey/Recipients/store";
import storeSurvey from "features/Survey/SurveyList/store";

type BackToMainLayoutHeaderProps = { idSurvey: string };

export const BackToMainLayoutHeader: FC<BackToMainLayoutHeaderProps> = observer((props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const SAVE_BUTTONS_SURVEY = [
    {
      name: "Save and continue",
      path: "/survey",
      click: async () => {
        if (storeSurveyCreation.activePage === "dimension") {
          storeSurveyCreation.saveDimensions((path: string) => {
            navigate(path);
          });
        } else {
          storeSurveyCreation.saveSurvey((path: string) => {
            navigate(path);
          });
        }
      },
      dataTestId: "Survey_EditCreate_Questionnaire_MainLayoutHeader_Menu_Save_Button",
    },
    {
      name: "Save and continue",
      path: "/recipients-millstone-schedule",
      click: () =>
        navigate(
          createUrl({
            path: "/recipients",
            query: {
              id: props.idSurvey,
            },
          })
        ),
      dataTestId: "",
    },
    {
      name: "Save and continue",
      path: "/recipients",
      click: () =>
        navigate(
          createUrl({
            path: "/communications",
            query: {
              id: props.idSurvey,
            },
          })
        ),
      dataTestId: "Survey_EditCreate_Recipients_MainLayoutHeader_Menu_Save_Button",
    },
    {
      name: "Continue",
      path: "/communications",
      click: () => {
        storeSurveyAccess.savingOnlyAfter(navigate);
      },
      dataTestId: "Survey_EditCreate_Communication_MainLayoutHeader_Menu_Continue_Button",
    },
  ];

  const backNavigatePath = createUrl({
    path: "/projects",
    query: {
      project_id: String(storeSurvey.project_id || storeSurveyCreation.idProject),
    },
  });

  const backProjectHandler = () => {
    if (storeSurveyCreation.translationChanged) {
      storeSurveyCreation.openUnsavedTranslationModal(true, backNavigatePath);
    } else {
      return navigate(backNavigatePath);
    }
  };

  const titleHeader = (): string => {
    if (localStorage.getItem("surveyAction") === "create") {
      return "Create new survey";
    }
    if (
      storeRecipients.Survey?.project_type === SEND_OUT_SURVEY_TYPES.Milestones &&
      location.pathname.includes("recipients")
    ) {
      return "Editing milestone recipients";
    }
    return "Editing survey";
  };

  const saveButtons = SAVE_BUTTONS_SURVEY.map((el, index) => {
    if (location.pathname === el.path) {
      return (
        <SaveButton id={el.dataTestId} key={index} variant="contained" onClick={el.click}>
          {el.name}
        </SaveButton>
      );
    }
    return null;
  });

  const DATA_TEST_ID = [
    {
      path: "/survey",
      backButtonId: "Survey_EditCreate_Questionnaire_MainLayoutHeader_Menu_Back_Button",
      backModuleId: "Survey_EditCreate_Questionnaire_MainLayoutHeader_Menu_Back_Button_Text",
      titleHeaderId: "Survey_EditCreate_Questionnaire_MainLayoutHeader_Menu_Title",
    },
    {
      path: "/recipients",
      backButtonId: "Survey_EditCreate_Recipients_MainLayoutHeader_Menu_Back_Button",
      backModuleId: "Survey_EditCreate_Recipients_MainLayoutHeader_Menu_Back_Button_Text",
      titleHeaderId: "Survey_EditCreate_Recipients_MainLayoutHeader_Menu_Title",
    },
    {
      path: "/communications",
      backButtonId: "Survey_EditCreate_Communication_MainLayoutHeader_Menu_Back_Button",
      backModuleId: "Survey_EditCreate_Communication_MainLayoutHeader_Menu_Back_Button_Text",
      titleHeaderId: "Survey_EditCreate_Communication_MainLayoutHeader_Menu_Title",
    },
  ];

  const toGiveIdForElements = (element: string): string => {
    const id = DATA_TEST_ID.map((el) => {
      if (el.path === location.pathname) {
        return el[element];
      }
      return "";
    });
    return id.filter((el) => el !== "")[0];
  };

  return (
    <BackToMainLayoutHeaderWrapper id="Survey_EditCreate_Header">
      <BackToMainButton
        id={toGiveIdForElements("backButtonId")}
        variant="text"
        onClick={backProjectHandler}
      >
        <CircleChevronContainedIcon />
        <BackToModule id={toGiveIdForElements("backModuleId")}>Back to survey project</BackToModule>
      </BackToMainButton>
      <Container>
        <Header id={toGiveIdForElements("titleHeaderId")}>{titleHeader()}</Header>
      </Container>
      <Container>{saveButtons}</Container>
    </BackToMainLayoutHeaderWrapper>
  );
});

const BackToMainLayoutHeaderWrapper = styled.div`
  width: 100%;
  height: 72px;
  padding: 20px 50px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--colorPaletteVioletBackground1);
`;

const BackToMainButton = styled(CustomButton)`
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 10px;

  &:hover {
    background-color: none !important;
  }
  &:active {
    background-color: none !important;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const BackToModule = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorPaletteGrayBackground1);
`;

const Header = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  color: var(--colorNeutralBackground1);
`;

const SaveButton = styled(CustomButton)`
  height: 34px;
  min-width: 180px !important;
  width: 100% !important;
`;
