import { FC } from "react";
import { WorkspacePermissionsScreen } from "../../features/WorkspacePermissions";

type PermissionsPageProps = {};

const PermissionsPage: FC<PermissionsPageProps> = () => {
  return <WorkspacePermissionsScreen />;
};

export default PermissionsPage;
