import { Backdrop, CircularProgress } from "@mui/material";
import { verifyResetPassword } from "api/go/useVerifyResetPasswordRequest";
import ResetPasswordScreenButton from "features/Auth/components/ResetPasswordScreenButton";
import InputNewPasswordScreen from "features/Auth/InputNewPasswordScreen";
import ResetPasswordMessageScreen from "features/Auth/ResetPasswordMessageScreen";
import { FC, useEffect, useState } from "react";

type ResetPasswordPageProps = {};

const ResetPasswordPage: FC<ResetPasswordPageProps> = (props) => {
    const [linkValid, setLinkValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [code, setCode] = useState("");

    const getQueryParams = (search: string) => {
        const params = new URLSearchParams(search);

        return {
            emailCode: params.get('emailCode'),
        };
    };


    const validateLink = async () => {
        try {
            const { emailCode } = getQueryParams(window.location.search);
            if (!emailCode) {
                return;
            }

            await verifyResetPassword(emailCode);
            setLinkValid(true);
            setCode(emailCode);
        } catch (error) {
            setLinkValid(false);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        validateLink();
    }, []);

    if (isLoading) {
        return (
            <Backdrop sx={{ color: "#fff", zIndex: 1000000 }} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }


    return !linkValid ? (
        <ResetPasswordMessageScreen title="Your reset password link is invalid or has expired. " messages="We will email you with a new reset password link." btnContent={ResetPasswordScreenButton} />
    ) : (
        <InputNewPasswordScreen code={code} />
    );
};

export default ResetPasswordPage;