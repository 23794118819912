import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import store from "./store";
import storeSurvey from "features/Survey/SurveyList/store";
import { ToRightIcon } from "./components/ToRightIcon";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";

type LeftAccordionsProps = {};

const LeftAccordions: FC<LeftAccordionsProps> = observer(() => {
  return (
    <MainBox>
      <SurveyProjectTitle id="Survey_LeftAccordions_Title">Survey projects</SurveyProjectTitle>

      <ProjectList>
        {store.data.map((project, i) => {
          return (
            <div key={project.id}>
              <ProjectBlock
                id={`Survey_LeftAccordions_Project_${i}`}
                href={`/projects?project_id=${project.id}`}
                $selected={store.project_id === project.id}
              >
                <ProjectText>
                  <SelectedProject
                    id={`Survey_LeftAccordions_Project_Name_${i}`}
                    $selected={store.project_id === project.id}
                  />
                  {project.name}
                </ProjectText>
                <StyledIcon>
                  <ToRightIcon />
                </StyledIcon>
              </ProjectBlock>
              {store.project_id === project.id &&
                project.projecttype_code === SEND_OUT_SURVEY_TYPES.Milestones && (
                  <MilestoneEmployeeCount>
                    Recipients{" "}
                    {storeSurvey.projectMilestoneCountRecepients !== 0 && (
                      <CountEmployeesMilestone>
                        {storeSurvey.projectMilestoneCountRecepients}
                      </CountEmployeesMilestone>
                    )}
                  </MilestoneEmployeeCount>
                )}
            </div>
          );
        })}
      </ProjectList>
    </MainBox>
  );
});

const StyledIcon = styled.div`
  width: 7px;
`;

const MainBox = styled.div`
  width: 100%;
  max-width: 225px;
  height: 100vh;
  background-color: var(--colorNeutralBackground1, #ffffff);
  position: fixed;
`;

const SelectedProject = styled.div<{ $selected: boolean }>`
  width: 6px;
  height: 30px;
  background-color: ${(props) =>
    props.$selected
      ? "var(--colorPaletteVioletBackground2, #7562FC)"
      : "var(--colorPalleteLightBlue, #EEEFFF)"};
  margin-right: 15px;
`;

const ProjectText = styled.div`
  display: flex;
  align-items: center;
`;

const CountEmployeesMilestone = styled.div`
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  border-radius: 50px;
  background: var(--colorPaletteVioletBackground1, #1e004d);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--colorNeutralBackground1, #ffffff);
  margin-left: 10px;
`;

const MilestoneEmployeeCount = styled.div`
  color: var(--colorNeutralForeground2, #707882);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-bottom: 1px solid var(--colorNeutralForeground4, #f1f3f8);
  padding: 0 20px 20px 20px;
  background-color: var(--colorPalleteLightBlue, #eeefff);
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ProjectBlock = styled.a<{ $selected: boolean }>`
  border-bottom: 1px solid var(--colorNeutralForeground4, #f1f3f8);
  padding: 20px 20px 20px 0;
  color: var(--colorNeutralForeground2, #707882);
  font-size: 14px;
  background-color: ${(props) =>
    props.$selected
      ? "var(--colorPalleteLightBlue, #EEEFFF)"
      : "var(--colorNeutralForeground8, #F9FAFC)"};
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--colorPalleteLightBlue, #eeefff);
    text-decoration: none !important;
  }
`;

const ProjectList = styled.div`
  height: 100vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const SurveyProjectTitle = styled.div`
  background-color: var(--colorPaletteBlueBackground1, #cdd3ec);
  padding: 10px 20px;
  color: var(--colorNeutralForeground2, #707882);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export default LeftAccordions;
