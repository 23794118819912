import http from "api/https";
import { SurveyLanguage } from "types/surveys";
import { Role } from "types/permission";

export const saveReportSurveyCommunication = (
  report_data: SurveyLanguage[],
  is_send_report: boolean,
  survey_id: number,
  strtime_send_report: string,
  reminder_days_id: number,
  report_roles: Role[]
): Promise<any> => {
  let body = {
    survey_id: survey_id,
    is_send_report: is_send_report,
    reminder_days_id: reminder_days_id,
    time_send_report: strtime_send_report,
    report_data: report_data,
    report_roles: report_roles,
  }

  if (http.isAPIGO) {
    return http.postGO("/sm/sm_survey/SaveReportSurveyCommunication", body);
  } else {
    return http.post("/sm_survey/SaveReportSurveyCommunication", body);
  }
};
