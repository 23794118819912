import http from "api/https";

export type GetMyWorkspacesNavbarType = {
  id: number;
  email: string;
  workspaces: WorkSpaceType[];
};

export type WorkSpaceType = {
  id: number;
  name: string;
  logo_id?: number;
  logo_url?: string
  industry?: string;
  has_access: boolean;
  region_id: number;
  entities: EntityType[];
};

export type EntityType = {
  id: number;
  name: string;
  logo_id?: number;
  logo_url?: string
  region_id: number;
};


export const GetMyWorkspacesNavbar = async (): Promise<any> => {
  //TEMPORARY
  if (http.isAPIGO) {
    try {
      return http.getGO('/workspace/MyWorkspacesNavbar');
    } catch (error) {
      throw error;
    }
  } else 
  {
    try {
      return http.get('/workspace/MyWorkspacesNavbar');
    } catch (error) {
      throw error;
    }
  }
};
