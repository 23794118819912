import * as React from "react";

interface EditIconProps {
  color?: string;
}

export const EditIcon: React.FC<EditIconProps> = (
  props: React.HTMLAttributes<HTMLOrSVGElement>
) => {
  const { color = "#1477F8" } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M2 15.2726V18.6055H5.33287L15.1626 8.77572L11.8297 5.44285L2 15.2726ZM17.74 6.1983C18.0867 5.85168 18.0867 5.29176 17.74 4.94514L15.6603 2.86543C15.3137 2.51881 14.7538 2.51881 14.4072 2.86543L12.7807 4.49187L16.1136 7.82474L17.74 6.1983Z"
        fill={color}
      />
    </svg>
  );
};
