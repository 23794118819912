import { JsonObjectProperty, Question } from "survey-core"
import { EMPLOYEE_SEGMENT_LOGIC_FIELD_TYPE } from "./surveyEditorOverrides/surveyJsConstants"

export default function getCustomConditionProperties(
  property1: Pick<JsonObjectProperty, "name" | "displayName">,
  propertyName2: Pick<JsonObjectProperty, "name" | "displayName">,
  mergeToPropertyName: string,
): Pick<JsonObjectProperty, "name" | "category" | "displayName" | "onSetValue" | "type">[] {
  return [
    {
      name: property1.name,
      category: "logic",
      displayName: property1.displayName,
      type: "condition",
      onSetValue: (surveyElement: Question, value: string): void => {
        surveyElement.setPropertyValue(property1.name, value)
  
        const conditions = [
          surveyElement.getPropertyValue(property1.name),
          surveyElement.getPropertyValue(propertyName2.name),
        ].filter(Boolean)
  
        surveyElement.setPropertyValue(mergeToPropertyName, conditions.join(" and "))
      },
    },
    {
      name: propertyName2.name,
      category: "logic",
      displayName: propertyName2.displayName,
      type: EMPLOYEE_SEGMENT_LOGIC_FIELD_TYPE,
      onSetValue: (surveyElement: Question, value: string): void => {
        surveyElement.setPropertyValue(propertyName2.name, value)
  
        const conditions = [
          surveyElement.getPropertyValue(property1.name),
          surveyElement.getPropertyValue(propertyName2.name),
        ].filter(Boolean)
  
        surveyElement.setPropertyValue(mergeToPropertyName, conditions.join(" and "))
      },
    },
  ]
}
