import { GetPropsCommonOptions, UseComboboxGetMenuPropsOptions, useCombobox } from "downshift"
import { isEmpty } from "lodash"
import { ReactElement, useState, ComponentProps } from "react"
import styled from "styled-components"

const StyledInput = styled.input`
  margin: 0;
`

const Container = styled.div`
  position: relative;
  min-height: 300px;
`

const SuggestionList = styled.ul<{ $isOpen: boolean }>`
  visibility: ${({ $isOpen }): string => $isOpen ? "visible" : "hidden"};
  background-color: var(--colorNeutralBackground1);
  border: 1px solid var(--colorNeutralStroke2);
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 12px 0;
  width: 100%;
  max-height: 189px;
  overflow-y: auto;
`

const SuggestionItem = styled.li<{ $isActive: boolean }>`
  padding: 3px 11px;
  height: 30px;
  color: var(--colorNeutralForeground3);
  font-size: var(--fontSizeH6);
  line-height: 24px;
  cursor: pointer;

  &:hover {
    background-color: var(--colorPalettePurpleBackground2);
  }

  ${({ $isActive }): string => $isActive ? `
    background-color: var(--colorPalettePurpleBackground2);
  ` : ""}
`

export type Option = {
  label: string
  value: string
}

type InputProps = Omit<ComponentProps<"input">, "onChange">

type RenderSuggestionListArg = {
  getMenuProps: (options?: UseComboboxGetMenuPropsOptions | undefined, otherOptions?: GetPropsCommonOptions | undefined) => any
  isOpen: boolean
  items: Option[]
  highlightedIndex: number | null
  getItemProps: (options: { item: Option, index: number }) => any
}

export type AutocompleteProps = {
  onChange?: (value: string | undefined) => void
  onSelectOption?: (value: string | undefined) => void
  value?: string | undefined
  options: Option[]
  renderSearchInput?: (props: any) => ReactElement
  renderSuggestionList?: (arg: RenderSuggestionListArg) => ReactElement
} & InputProps

const Autocomplete = ({
  className,
  onChange,
  onSelectOption,
  value,
  options,
  renderSearchInput,
  renderSuggestionList,
  ...otherInputProps
}: AutocompleteProps): ReactElement => {
  const [items, setItems] = useState(options)

  const {
    isOpen,
    highlightedIndex,
    inputValue,
    getInputProps,
    getItemProps,
    getMenuProps,
  } = useCombobox({
    itemToString: (item): string => item ? item.label : "",
    items,
    onInputValueChange: ({ inputValue }) => {
      onChange?.(inputValue)
      const filteredOptions = options.filter(getOptionsFilter(inputValue))
      setItems(filteredOptions)
    },
    onSelectedItemChange: ({ selectedItem }) => {
      onSelectOption?.(selectedItem?.value)
      onChange?.(selectedItem?.label)
    },
  })

  const inputProps = {
    ...getInputProps({
      ...otherInputProps,
      onChange: (event) => onChange?.(event.currentTarget.value),
      onKeyDown: (event) => {
        if (inputValue && isEmpty(items)) {
          (event.nativeEvent as any).preventDownshiftDefault = true
        }
        otherInputProps.onKeyDown?.(event)
      },
      value,
      type: "text",
    })
  }

  return (
    <Container className={className}>
      <div>
        {renderSearchInput ? renderSearchInput(inputProps) : (
          <StyledInput
            type="text"
            // variant="contained"
            {...inputProps}
          />
        )}
      </div>

      {renderSuggestionList ? renderSuggestionList({
        getMenuProps,
        isOpen,
        items,
        highlightedIndex,
        getItemProps,
      }) : (
        <SuggestionList {...getMenuProps()} $isOpen={isOpen}>
          {isOpen
            ? items.map((item, index) => (
              <SuggestionItem
                key={item.label}
                $isActive={highlightedIndex === index}
                {...getItemProps({
                  index,
                  item,
                })}
              >
                {item.label}
              </SuggestionItem>
            ))
            : null}
        </SuggestionList>
      )}
    </Container>
  )
}

export default Autocomplete

function getOptionsFilter(inputValue: string | undefined): (option: Option) => boolean {
  const lowerCasedInputValue = (inputValue || "")?.toLowerCase()

  return (option: Option): boolean => {
    return (
      !inputValue ||
      option.label?.toLowerCase().includes(lowerCasedInputValue)
    )
  }
}
