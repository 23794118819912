import MainStore from "MainStore";
import { getRecepientsMilestone } from "api/useGetEmployeesWithMilestones";
import getExcelMilestoneRecepients from "api/useGetExcelMilestoneRecepients";
import { getRecepientsMilestoneByProject } from "api/useGetRecepientsMilestoneByProject";
import { SetRecepientMilestoneSchedule } from "api/useSetRecepientMilestoneSchedule";
import { Dayjs } from "dayjs";
import { makeAutoObservable, runInAction } from "mobx";
import { SuveyRecepientData, Employee, Survey } from "types/milestone";
import { getTimeZone } from "utils/timeFormatter";

class Store {
  surveyId: number = 0;
  projectId: number = 0;
  AllData: SuveyRecepientData = null;
  Employees: Employee[] = [];
  Surveys: Survey[] = [];

  millstoneSelectedRecipients = [];
  searchNameEmail: string = "";
  resultSearchValue: string = "";

  openEditSchedule: boolean = false;
  date: Dayjs | null = null;
  activeSheduleType = "customDate";
  selected_survey_id: number = 0;
  selected_survey_name: string = "";
  selected_employee_id: number = 0;
  selected_employee_name: string = "";
  selectedEmployeeNoAttr: boolean = false;
  timezone: string = getTimeZone();

  paginationItemsPerPage = 10;
  paginationActivePage: number = 1;

  constructor() {
    makeAutoObservable(this);
  }

  doLoad = () => {
    this.getMilestoneData();
  };

  setIdSurvey = (survey_id: number) => {
    this.surveyId = survey_id;
  };

  setIdProject = (projectId: number) => {
    this.projectId = projectId;
  };

  paginationSetActivePage = (page: number) => {
    this.paginationActivePage = page;
  };

  escapeRegexSpecialChars(input: string) {
    if (input === null || input === undefined) return input
    const specialChars = [
      '.', '*', '+', '?', '^', '$', '(', ')', '[', ']', '{', '}', '\\', '|'
    ];

    let escapedString = input;
    specialChars.forEach(char => {
      const escapeChar = '\\' + char;
      const regex = new RegExp(char, 'g');
      escapedString = escapedString.replace(regex, escapeChar);
    });

    return escapedString;
  }


  getMilestoneData = async () => {
    try {
      MainStore.changeLoader(true);
      if (this.surveyId && this.surveyId !== 0) {
        const response = await getRecepientsMilestone(this.surveyId, this.resultSearchValue, 0, 10); // get by surveyId
        if ((response.status === 200 || response.status === 201) && response?.data !== null) {
          this.AllData = response.data;
          this.Employees = response.data.employees;
          this.Surveys = response.data.surveys;
        } else throw Error();
      } else {
        const response = await getRecepientsMilestoneByProject(
          this.projectId,
          this.escapeRegexSpecialChars(this.resultSearchValue),
          0,
          10
        ); // get by projectId
        if ((response.status === 200 || response.status === 201) && response?.data !== null) {
          this.AllData = response.data;
          this.Employees = response.data.employees;
          this.Surveys = response.data.surveys;
        } else throw Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  setData = (field: string, value: any) => {
    this[field] = value;
  };

  editSurveySchedule = (
    id_survey: number,
    id_employee: number,
    survey_name: string,
    employee_name: string,
    activeSheduleType: string
  ) => {
    this.onChangeOpenEditSchedule(true);
    const currEmployee = this.Employees.find((x) => x.id === id_employee);
    if (!currEmployee?.start_date_attribute_value) {
      this.selectedEmployeeNoAttr = true;
    }
    this.selected_survey_id = id_survey;
    this.selected_employee_id = id_employee;
    this.selected_survey_name = survey_name;
    this.selected_employee_name = employee_name;
    this.activeSheduleType = activeSheduleType
  };

  onChangeOpenEditSchedule = (flag: boolean) => {
    if (flag === false) {
      this.date = null;
      this.activeSheduleType = "customDate";
      this.selected_employee_id = 0;
      this.selected_survey_id = 0;
      this.selected_employee_name = "";
      this.selected_survey_name = "";
      this.selectedEmployeeNoAttr = false;
    }
    this.openEditSchedule = flag;
  };

  setDate = (date: Dayjs) => {
    this.date = date;
  };

  changeSelected = (id: number) => {
    let new_selected = this.millstoneSelectedRecipients;
    const index = new_selected.indexOf(id, 0);
    if (index > -1) {
      new_selected.splice(index, 1);
    } else {
      new_selected = [...new_selected, id];
    }
    this.millstoneSelectedRecipients = new_selected;
  };

  changeValue = (name: string, value: string) => {
    this[name] = value;
  };

  keyPress = (e: any) => {
    if (e.keyCode == 13) {
      this.onSearchClicked();
    }
  };

  onSearchClicked = () => {
    this.resultSearchValue = this.searchNameEmail;
    this.searchNameEmail = "";
    this.getMilestoneData();
  };

  exportRecepientsExcel = async () => {
    let currentSurvey = this.Surveys.find(x => x.id === this.surveyId);
    let surveyName = currentSurvey?.name ?? ""
    try {
      MainStore.changeLoader(true);
      const data = await getExcelMilestoneRecepients(this.surveyId);
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "Milestone recipients_" + surveyName + ".xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  SaveSchedule = async () => {
    // format the schedule
    try {
      runInAction(() => {
        MainStore.changeLoader(true);
      });
      const resp = await SetRecepientMilestoneSchedule(
        this.selected_survey_id,
        this.selected_employee_id,
        this.date ? this.date.toISOString() : null,
        this.activeSheduleType
      );
      if (resp.status === 200 || resp.status === 201) {
        runInAction(() => {
          this.doLoad();
          this.onChangeOpenEditSchedule(false);
        });
      } else throw new Error();
    } catch (error) {
    } finally {
      runInAction(() => {
        MainStore.changeLoader(false);
      });
    }
  };
}

const store = new Store();
export default store;
