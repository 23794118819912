import { FC, ReactElement, useEffect } from "react";
import store from './store'
import {
  DialogTitle,
  DialogContent,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import { observer } from "mobx-react"
import FileInput from "./UploadImage";
import { Dialog } from '@mui/material'
import CustomButton from "components/button/Button";


type ModalChnagePasswordProps = {
  openPanel: boolean;
  onBtnCancelClick: () => void;
  onSaved: () => void;
  id: number;
  workspacename: string;
};

const ModalCreateWorkspace: FC<ModalChnagePasswordProps> = observer((
  props
): ReactElement => {


  useEffect(() => {
    if(props.openPanel){
      store.doLoad(props.id)
    }
  }, [props.openPanel]);

  return (
    <Dialog
      open={props.openPanel}
      onClose={() => props.onBtnCancelClick()}
      aria-label="Create workspace modal"
    >
      <StyledModalContent aria-label={"Create workspace form"}>
        <StyledDialogTitle id="ManageEntites_Card_AddEditPopUp_title">Edit entity</StyledDialogTitle>
        <TextField
          name="name"
          id="ManageEntites_Card_AddEditPopUp_Input"
          placeholder="Enter entity name"
          fullWidth
          label="Entity name"
          variant="standard"
          helperText={store.errorname}
          error={store.errorname != ""}
          type="text"
          value={store.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            store.changeName(e.target.value)
          }}
        />

        <FileInput />

        <ButtonWrapper>
          <StyledNextButton
            id="ManageEntites_Card_AddEditPopUp_SaveBtn"
            variant="contained"
            disabled={store.errorname !== "" || store.errorDelayCheckName || store.errorfile !== ""}
            onClick={() => {
              store.saveEntity(props)
            }}
          >
            Save changes
          </StyledNextButton>
          <StyledCancelButton
            id="ManageEntites_Card_AddEditPopUp_CanselBtn"
            variant="outlined"
            onClick={() => {
              props.onBtnCancelClick()
              store.clearStore()
            }}
          >
            Cancel
          </StyledCancelButton>
        </ButtonWrapper>
      </StyledModalContent>
    </Dialog>
  );
});

const StyledModalContent = styled(DialogContent)`
  margin: 40px 50px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const StyledCancelButton = styled(CustomButton)`
  width: 100%;
  max-width: 100px;
  margin-left: 16px;
`;

const StyledNextButton = styled(CustomButton)`
  width: 100%;
  margin-right: 20px !important;
  max-width: 150px !important;
`;

const StyledDialogTitle = styled.h2`
  font-size: 24px;
  font-style: normal;
  line-height: 28px;
  font-weight: 500;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin-bottom: 44px;
`;

export default ModalCreateWorkspace;
