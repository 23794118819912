import { makeAutoObservable } from "mobx";
import { inviteAgainUsers } from "../../../../api/go/useInviteAgainUsers";
import MainStore from "MainStore";
import { ACCESS_LEVEL_TYPE } from "constants/access-level-type";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";

class Store {
  constructor() {
    makeAutoObservable(this);
  }

  changeValue = (name: string, value: any) => {
    this[name] = value;
  };

  clearStore() {}

  async inviteAgainSubmit(idUsers: number[], onSaved: () => void) {
    try {
      const response = await inviteAgainUsers(idUsers, ACCESS_LEVEL_TYPE.entity, StoreLayout.currentEntityId, StoreLayout.currentRegionId);
      if ((response.status === 201 || response.status === 200) && response?.data !== undefined) {
        MainStore.setSnackbar("Success invited again");
        onSaved();
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }
}

const store = new Store();
export default store;
