import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg``;

export const AppsIcon = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <StyledSvg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3337_21556)">
      <path
        d="M4.6665 9.33073H9.33317V4.66406H4.6665V9.33073ZM11.6665 23.3307H16.3332V18.6641H11.6665V23.3307ZM4.6665 23.3307H9.33317V18.6641H4.6665V23.3307ZM4.6665 16.3307H9.33317V11.6641H4.6665V16.3307ZM11.6665 16.3307H16.3332V11.6641H11.6665V16.3307ZM18.6665 4.66406V9.33073H23.3332V4.66406H18.6665ZM11.6665 9.33073H16.3332V4.66406H11.6665V9.33073ZM18.6665 16.3307H23.3332V11.6641H18.6665V16.3307ZM18.6665 23.3307H23.3332V18.6641H18.6665V23.3307Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3337_21556">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </StyledSvg>
);
