import { ReactElement, useCallback, useState } from "react";

import styled from "styled-components";
import { observer } from "mobx-react"
import {
  Grid, Box, Divider, Button
} from "@mui/material"
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';

const Connector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#bdbdcb',
    borderTopWidth: 4,
    borderRadius: 2,
    margin: 2
  },
}));

const ConnectorPassed = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: 'blue',
    borderTopWidth: 4,
    borderRadius: 2,
    margin: 2
  },
}));


export type StepperProps = {
  value: number;
  steps: number;
  changeValue: (value: number) => void;
  hideButtons?: boolean;
};

const CustomStepper = observer((props: StepperProps): ReactElement => {

  var steps = new Array(props.steps).fill(0);

  return (
    <>
      <Box display={"flex"} style={{ marginBottom: 30 }}>
        {steps.map((x, i) => i == props.value ? <ConnectorPassed key={i}/>: <Connector key={i} />)}
      </Box>

      {props.hideButtons ? "": <Box display={"flex"} justifyContent={"space-between"} style={{ marginBottom: 30 }}>
        {props.value == 0 ? <div></div>: <Button  style={{ width: 170 }} onClick={() => props.changeValue(props.value - 1)}>
          <WestIcon style={{ marginRight: 5 }} /> Back
        </Button>}
        {props.value == props.steps -1 ? "": <Button variant="contained" style={{ width: 170 }} onClick={() => props.changeValue(props.value + 1)}>
          Next<EastIcon style={{ marginLeft: 5 }} />
        </Button>}
      </Box>}
    </>
  );
});

export default CustomStepper;
