import { FC } from "react";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import { Box, Typography, TextField } from "@mui/material";
import store from "../store";
import { observer } from "mobx-react";

type SurveyDeletePopupProps = {};

const SurveyDeletePopup: FC<SurveyDeletePopupProps> = observer((props) => {
  return (
    <PopupWrapper>
      <div>
        <Styledh2 id="SurveyList_PopupDelete_TitleAndName">
          Are you sure you want to delete “{store.selectedRow.name}”?
        </Styledh2>
        <StyledTypography id="SurveyList_PopupDelete_Description">
          All survey data and dashboard data within the survey will be lost.
        </StyledTypography>
      </div>
      <StyledTypography id="SurveyList_PopupDelete_Description_Delete">
        To confirm deletion, please enter DELETE below:
      </StyledTypography>
      <BoxTextField
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          store.deleteSurvSubmit(store.selectedRow.id);
        }}
      >
        <TextFieldStyled
          id="SurveyList_PopupDelete_DeleteInput"
          label="DELETE"
          variant="standard"
          autoComplete="off"
          value={store.deleteSurvValue}
          onChange={store.valueSurvChange}
        />
        <TypographyErrorText id="SurveyList_PopupDelete_DeleteInput_errorMessage">
          {store.errorSurvTextDelete}
        </TypographyErrorText>
        <BtnBox>
          <CustomButton
            type="submit"
            id="SurveyList_PopupDelete_ConfirmDelete_Button"
            variant="contained"
            disabled={store.isDeleteSurvBtnDisabled}
            onClick={() => {}}
          >
            Delete survey
          </CustomButton>
          <CustomButtonStyled
            id="SurveyList_PopupDelete_CancelDelete_Button"
            variant="outlined"
            onClick={() => {
              store.openClickSurveyDeletePopup(false);
            }}
          >
            Cancel
          </CustomButtonStyled>
        </BtnBox>
      </BoxTextField>
    </PopupWrapper>
  );
});

const PopupWrapper = styled.div`
  padding: 40px 42px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Styledh2 = styled.h2`
  color: var(--colorNeutralForeground1);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin: 0 0 20px;
`;

const StyledTypography = styled(Typography)`
  color: var(--colorNeutralForeground1);
  font-size: 14px;
  font-style: normal;
`;

const BtnBox = styled(Box)`
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const CustomButtonStyled = styled(CustomButton)`
  border: 1px solid var(--colorNeutralForeground5) !important;
  border-radius: 4px;
  color: var(--colorNeutralForeground2) !important;
  width: 90px;
  height: 34px;
`;

const BoxTextField = styled(Box)``;

const TypographyErrorText = styled(Typography)`
  color: var(--colorPaletteRedForeground2);
  font-size: 10px;
`;

const TextFieldStyled = styled(TextField)`
  color: var(--colorNeutralBackground3) !important;
  width: 100%;
`;

export default SurveyDeletePopup;
