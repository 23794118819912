export const DeleteIconTags = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
      <path
        d="M5.88061 4.99947L9.81526 1.07065C9.93286 0.953029 9.99893 0.793505 9.99893 0.62717C9.99893 0.460834 9.93286 0.301311 9.81526 0.183694C9.69766 0.0660767 9.53815 0 9.37183 0C9.20551 0 9.04601 0.0660767 8.9284 0.183694L5 4.11876L1.0716 0.183694C0.953993 0.0660767 0.794487 1.4768e-07 0.628169 1.4892e-07C0.461851 1.50159e-07 0.302345 0.0660767 0.18474 0.183694C0.0671355 0.301311 0.001066 0.460834 0.001066 0.62717C0.001066 0.793505 0.0671355 0.953029 0.18474 1.07065L4.11939 4.99947L0.18474 8.92829C0.126202 8.98635 0.0797396 9.05544 0.0480322 9.13155C0.0163247 9.20767 0 9.28931 0 9.37176C0 9.45422 0.0163247 9.53586 0.0480322 9.61198C0.0797396 9.68809 0.126202 9.75717 0.18474 9.81524C0.2428 9.87378 0.311875 9.92025 0.387982 9.95196C0.464089 9.98367 0.545721 10 0.628169 10C0.710616 10 0.792248 9.98367 0.868355 9.95196C0.944462 9.92025 1.01354 9.87378 1.0716 9.81524L5 5.88017L8.9284 9.81524C8.98646 9.87378 9.05554 9.92025 9.13165 9.95196C9.20775 9.98367 9.28938 10 9.37183 10C9.45428 10 9.53591 9.98367 9.61202 9.95196C9.68812 9.92025 9.7572 9.87378 9.81526 9.81524C9.8738 9.75717 9.92026 9.68809 9.95197 9.61198C9.98368 9.53586 10 9.45422 10 9.37176C10 9.28931 9.98368 9.20767 9.95197 9.13155C9.92026 9.05544 9.8738 8.98635 9.81526 8.92829L5.88061 4.99947Z"
        fill="#707882"
      />
    </svg>
  );
};
