import { observer } from "mobx-react";
import { ROLE_LIST } from "constants/role-list";
import { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";

import store from "./store";
import styled from "styled-components";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import storeLayout from "components/workspaces-sidebar/StoreLayout";
import VisibilityIcon from "@mui/icons-material/Visibility";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const ViewAsComponent = observer(() => {
    const [roleList, setRoleList] = useState([]);

    useEffect(() => {
        if (storeLayout.isAdmin && storeLayout.isManager && !storeLayout.isUnitHead) {
            setRoleList([ROLE_LIST.ADMIN, ROLE_LIST.MANAGER]);
        } else if (storeLayout.isAdmin && !storeLayout.isManager && storeLayout.isUnitHead) {
            setRoleList([ROLE_LIST.ADMIN, ROLE_LIST.UNIT_HEAD]);
        } else if (!storeLayout.isAdmin && storeLayout.isManager && !storeLayout.isUnitHead) {
            store.setData(ROLE_LIST.MANAGER.value, "role");
        } else if (!storeLayout.isAdmin && !storeLayout.isManager && storeLayout.isUnitHead) {
            store.setData(ROLE_LIST.UNIT_HEAD.value, "role");
        } else setRoleList([]);
    }, [storeLayout.isAdmin, storeLayout.isManager, storeLayout.isUnitHead]);

    return (
        <Container>
            <VisibilityIcon />
            <Text>Viewing as:</Text>
            {storeLayout.isAdmin && !storeLayout.isManager && !storeLayout.isUnitHead && (
                <Text $isRole>Admin</Text>
            )}
            {!storeLayout.isAdmin && !storeLayout.isManager && storeLayout.isUnitHead && (
                <Text $isRole>Unit head</Text>
            )}
            {storeLayout.isAdmin && (storeLayout.isManager || storeLayout.isUnitHead) && (
                <StyledSelect
                    id="role-select"
                    value={store.role}
                    onChange={(e: SelectChangeEvent<string>) => store.setData(e.target.value, "role")}
                    displayEmpty
                    MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                    IconComponent={KeyboardArrowDownIcon}
                >
                    {roleList.map((el) => (
                        <StyledMenuItem key={el.value} value={el.value}>
                            {el.name}
                        </StyledMenuItem>
                    ))}
                </StyledSelect>
            )}
            {store.role === ROLE_LIST.MANAGER.value && (
                <StyledSelect
                    id="secondary-role-select"
                    value={store.secondaryRole}
                    onChange={(e: SelectChangeEvent<string>) =>
                        store.setData(e.target.value, "secondaryRole")
                    }
                    displayEmpty
                    MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                    IconComponent={KeyboardArrowDownIcon}
                    $isSecondary
                >
                    <StyledMenuItem value={ROLE_LIST.ROLL_UP_TEAM.value}>
                        {ROLE_LIST.ROLL_UP_TEAM.name}
                    </StyledMenuItem>
                    <StyledMenuItem value={ROLE_LIST.DIRECT_TEAM.value}>
                        {ROLE_LIST.DIRECT_TEAM.name}
                    </StyledMenuItem>
                </StyledSelect>
            )}
        </Container>
    );
});

export default ViewAsComponent;

const Container = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;

  svg {
    color: var(--colorNeutralForeground2);
    font-size: 16px;
  }
`;

const Text = styled.p<{ $isRole?: boolean }>`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  margin: ${(props) => (props.$isRole ? "0px" : "0px 8px")};
`;

const StyledSelect = styled(Select) <{ $isSecondary?: boolean }>`
  height: 34px;
  width: ${(props) => (props.$isSecondary ? "130px" : "110px")} !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  ${(props) => props.$isSecondary && "margin-left: 8px"};

  svg {
    font-size: 16px;
  }

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px !important;
  font-weight: 400 !important;
`;
