import http from "api/https";


export const surveySaveAsTemplate = async (
    survey_id: number,
    entity_id: number,
    SurveyQuest: boolean,
    SurveyComm: boolean
) => {
    let data = {
        survey_id: survey_id,
        entity_id: entity_id,
        survQuest: SurveyQuest,
        survComm: SurveyComm
    }
    if(http.isAPIGO){
        return http.postGO("/sm/sm_survey/SurveySaveAsTemplate", data);
    }else{
        return http.post("/sm_survey/SurveySaveAsTemplate", data);
    }
};