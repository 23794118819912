import { ReactElement } from "react";
import { SurveyQuestionElementBase } from "survey-react-ui";
import EmployeeSegmentLogicFieldModel from "./EmployeeSegmentLogicFieldModel";
import EmployeeSegmentLogicFieldContainer from "./EmployeeSegmentLogicFieldContainer";

export default class EmployeeSegmentLogicField extends SurveyQuestionElementBase {
  constructor(props: unknown) {
    super(props);
    this.state = { value: this.question.value };
  }

  protected get question(): EmployeeSegmentLogicFieldModel {
    return this.questionBase as EmployeeSegmentLogicFieldModel;
  }

  protected renderElement(): ReactElement {
    return (
      <EmployeeSegmentLogicFieldContainer
        onSetValue={this.handleSetValue}
        value={this.state.value}
        disabled={this.question.readOnly}
        headerTitle={this.question.title}
      />
    )
  }

  handleSetValue = (value: string): void => {
    this.question.value = value
    this.setState({ value })
  }
}
