import http from "api/https";

export const importHistoricalSurvey = (projectId: number, file: File): Promise<any> => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("project_id", projectId.toString());

  return http.postGO("/sm/sm_project/ImportAndParseExcelFile", formData, {
    "Content-Type": "multipart/form-data",
  });
};
