import { makeAutoObservable } from "mobx";
import { inviteUsersToRole } from "../../../../api/go/useInviteUsersToRole";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import MainStore from "MainStore";
import { ACCESS_LEVEL_TYPE } from "constants/access-level-type";

class Store {

  emails: string[] = []
  value: string;

  constructor() {
    makeAutoObservable(this);
  }

  changeFieldValue = (value: string) => {
    this.value = value;
  }

  changeEmail = (email: string[]) => {
    this.emails = email
  }

  changeValue = (name: string, value: any) => {
    this[name] = value;
  };

  addEmail(email?: string) {
    this.emails = [...this.emails, this.value]
    this.value = ""
  }

  deleteEmail(index: number) {
    this.emails = [...this.emails.slice(0, index), ...this.emails.slice(index + 1)]
  }

  clearStore() {
  }

  async inviteUserSubmit(idRole: number, roleName: string, onSaved: () => void) {
    try {
      MainStore.changeLoader(true);
      const response = await inviteUsersToRole(idRole, StoreLayout.currentWorkspaceId, this.emails, ACCESS_LEVEL_TYPE.workspace, StoreLayout.currentRegionId);
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        var data = response.data
        onSaved()
        MainStore.changeLoader(false);

        if (data.length == 1) {
          if(data[0].isNew){
          MainStore.setSnackbar(data[0].email+ " invited as "+roleName, "success");
          }else{
            MainStore.setSnackbar(data[0].email+ " added as "+ roleName, "success");
          }
        }else{
          var newUsers = data.filter(x => x.isNew == true).length
          var existingUser = data.length - newUsers;

          var str = ""
          if(existingUser > 0){
            str+= existingUser+ " users added"
          }

          if(newUsers > 0){
            str.length > 0 ? str += ", and "+newUsers+" new users is invited" : str += newUsers+" new users is invited";
          }
          
          str += " as "+ roleName

          MainStore.setSnackbar(str, "success");
        }
      } else {
        MainStore.changeLoader(false);
        MainStore.setSnackbar(response.response.data?.error, "error");
        throw new Error();
      }
    } catch (err) {}
  }

}

const store = new Store();
export default store;
