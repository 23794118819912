import { SurveyTagsType } from "api/go/useGetSurveyTags";
import http from "api/https";

export type GetOnetempl_template = {
  id: number;
  name: string;
  tags: SurveyTagsType[];
};

export const GetOneTemplate = async (id: number): Promise<any> => {
  if(http.isAPIGO){
    return http.getGO("/template/get_templ_comm_with_tags?id=" + id);
  } else {
    return http.get("/templ_template_comms/GetWithTags?id=" + id)
  }
};
