import http from "api/https";
import {
  TemplateLanguage,
} from "types/templates";

export const SaveInviteTemplateMessages = async (data: TemplateLanguage[]): Promise<any> => {
  if(http.isAPIGO){
    return http.postGO('/template/save_invite_template_messages', data)
  } else {
    return http.post('/templ_template_comms/SaveInviteTemplateMessaSges', data)
  }
};