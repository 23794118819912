import http from "api/https";

export const addOrEditEntity = (data: any): Promise<any> => {
  const formData = new FormData();
  formData.append("id", data.id + "");
  formData.append("name", data.name);
  formData.append("workspace_id", data.workspace_id + "");
  if(data.logo_url){
    formData.append("logo_url", data.logo_url);
  }
  if (data.file) {
    formData.append("Document.name", data.file.name);
    formData.append("Document.file", data.file);
  }

  if(http.isAPIGO){
    return http.postGO("/entity/AddOrUpdateOnlyNameAndLogo", formData, {
      "Content-Type": "multipart/form-data",
    });
  }else{
    return http.post("/entity/AddOrUpdateOnlyNameAndLogo", formData, {
      "Content-Type": "multipart/form-data",
    });
  }

};
