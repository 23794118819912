import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import styled from "styled-components";
import CalendarIcon from './CalendarIcon'
import { observer } from 'mobx-react';

type DateTimePickerValueProps = {
  id?: string;
  value: Dayjs;
  label?: string;
  minDateTime?: Dayjs;
  maxDateTime?: Dayjs;
  disabled?:boolean;
  changeValue: (value: Dayjs) => void;
};


const DateTimePickerValue: React.FC<DateTimePickerValueProps> = observer((props) => {
  return (
    <div  id={props.id}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
        <WrappedTimeField
          format="DD.MM.YYYY hh:mm A"
          value={props.value}
          label={props.label ? props.label : "Select date and time"}
          disabled={props.disabled}
          // timeSteps={{"minutes": 15}}
          onChange={(newValue: Dayjs) => {
              if(newValue instanceof dayjs || newValue === null){
                props.changeValue(newValue)
             }
          }}
          closeOnSelect={false}
          minDateTime={props.minDateTime}
          maxDateTime={props.maxDateTime}
          slots={{
            openPickerIcon: CalendarIcon
          }}
      />
    </LocalizationProvider>
    </div>
  );
})

const WrappedTimeField = styled(DateTimePicker)`
    input {
        padding: 6px 8px;
        border-color: #CDD3EC;
    }

    label {
        position: absolute;
        top: -8px;
    }
`
export default DateTimePickerValue;