
const config = {
  defaultTitlePage: "EngageRocket",
  reqResApiBaseUrl: "https://reqres.in",
  surveySvcApiProxyBaseUrl: "/survey/api/v1/proxy/survey",
  surveyJsAutoSaveDelay: 2,
  surveyJsEnableMCQShowOthers: true,
  surveyJsEnableQuestionComments: true,
  surveyJsHaveCommercialLicense: true,
  surveyJsSupportedLocalizations: ["en"],
  useMockServiceWorker: true,
} as const

// ensureEnvVarsAreSet<keyof typeof config>(
//   config,
//   {
//     optional: ["reqResApiBaseUrl"],
//   }
// )

export default config
