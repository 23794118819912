import React, { FC, useState } from "react";
import { templ_template_comms } from "types/templates";
import styled from "styled-components";
import CustomTable from "./components/CustomTable";
import ShareTemplatePopup from "./PopupShare";
import CustomButton from "components/button/Button";
import { SelectIcon } from "./SelectIcon";
import { IconButton, Menu, MenuItem } from "@mui/material";
import store from "./store";
import { observer } from "mobx-react";
import PreviewIcon from "../CommunicationTemplates/PreviewIcon";
import CustomModal from "components/modal/Modal";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import { TEMPLATE_TYPES } from "constants/template-types";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import shortStringLength from "../../../components/shortStringLength/shortStringLength";

type CommunicationTableTableProps = {
  list: templ_template_comms[];
};

const CommunicationTableTable: FC<CommunicationTableTableProps> = observer((props) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [selectedRow, setSelectedRow] = useState<templ_template_comms | null>(null);

  const hasFeature = (template: templ_template_comms): boolean => {
    if (!template) return true;
    if (template?.type === "communication") {
      return StorePermission.features.communication_template.hasFeature;
    } else {
      return (
        template?.type_id_code === null ||
        (template?.type_id_code === TEMPLATE_TYPES.surveys &&
          StorePermission.features.survey_template.hasFeature) ||
        (template?.type_id_code === TEMPLATE_TYPES.milestones &&
          StorePermission.features.milestone_template.hasFeature) ||
        (template?.type_id_code === TEMPLATE_TYPES.reviews &&
          StorePermission.features.review_template.hasFeature)
      );
    }
  };

  const tableData = {
    headers: [
      {
        title: "TEMPLATE NAME",
        value: "name",
        filter: false,
        sort: true,
        renderRow: (row: templ_template_comms) => (
          <TableCellWrapper>
            <TableName id={`Templates_Table_name_${row.type}_${row.id}`}>
              {shortStringLength(row.name)}
            </TableName>
          </TableCellWrapper>
        ),
      },
      {
        title: "QUESTIONS",
        value: "question",
        filter: false,
        sort: true,
        renderRow: (row: templ_template_comms) => (
          <TableCellWrapper>
            <TableName id={`Templates_Table_questions_${row.type}_${row.id}`}>
              {row.type === "questionnaire" ? (
                <PreviewColumn>
                  <span>{row.questions_count}</span>
                  <LinkPreview
                    id={`Templates_Table_questions_preview_${row.id}`}
                    target="_blank"
                    href={`/survey-preview?idTemplate=${row.id}`}
                  >
                    <PreviewIcon />
                    <LinkPreviewText>Preview</LinkPreviewText>
                  </LinkPreview>
                </PreviewColumn>
              ) : (
                <span id={`Templates_Table_questions_no_question_${row.type}_${row.id}`}> - </span>
              )}
            </TableName>
          </TableCellWrapper>
        ),
      },
      {
        title: "TEMPLATE TYPE",
        value: "type_name",
        filter: false,
        sort: false,
        renderRow: (row: templ_template_comms) => (
          <TableCellWrapper>
            <TableName id={`Templates_Table_type_${row.type}_${row.id}`}>{row.type_name}</TableName>
          </TableCellWrapper>
        ),
      },
      {
        title: "TAGS",
        value: "tag_names",
        filter: false,
        sort: false,
        renderRow: (row: templ_template_comms) => (
          <TableCellWrapper>
            <TableName id={`Templates_Table_tag_${row.type}_${row.id}`}>
              {shortStringLength(row.tag_names)}
            </TableName>
          </TableCellWrapper>
        ),
      },
      {
        title: "LAST UPDATE",
        value: "strupdated_at",
        filter: false,
        sort: true,
        renderRow: (row: templ_template_comms) => {
          let formattedUpdateDate = "";

          if (row.updated_at) {
            const updated = dayjs(row.updated_at);
            const survey_date_update = dayjs(updated).toDate();
            formattedUpdateDate = new Intl.DateTimeFormat("en-US", {
              day: "numeric",
              month: "short",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }).format(survey_date_update);
          }

          let formattedCreatedDate = "";

          if (row.created_at) {
            const created = dayjs(row.created_at);
            const survey_date_created = dayjs(created).toDate();
            formattedCreatedDate = new Intl.DateTimeFormat("en-US", {
              day: "numeric",
              month: "short",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }).format(survey_date_created);
          }

          return (
            <TableCellWrapper>
              <TableName id={`Templates_Table_dates_${row.type}_${row.id}`}>
                <UpdatedBlock>
                  <UpdatedDiv id={`Templates_Table_updated_${row.type}_${row.id}`}>
                    {formattedUpdateDate}
                  </UpdatedDiv>
                  <CreatedDiv id={`Templates_Table_created_${row.type}_${row.id}`}>
                    {`Created on ${formattedCreatedDate}`}
                  </CreatedDiv>
                </UpdatedBlock>
              </TableName>
            </TableCellWrapper>
          );
        },
      },
      {
        title: "",
        value: "id",
        filter: false,
        sort: false,
        renderRow: (row: templ_template_comms) => (
          <TableCellWrapperEnd>
            {store.navUrl === "entity" && (
              <>
                <RevokeAdminBtn
                  id={`Templates_Table_Option_Button_${row.type}_${row.id}`}
                  onClick={(e) => {
                    setSelectedRow(row);
                    handleClick(e);
                  }}
                >
                  <SelectIcon />
                </RevokeAdminBtn>
              </>
            )}
            <TableName id={`Templates_Table_actions_${row.type}_${row.id}`}>
              {store.navUrl !== "entity" ? (
                <CustomButton
                  variant="outlined"
                  disabled={!hasFeature(row)}
                  onClick={() => {
                    store.duplicateTemplate(row, () => {
                      navigate(`/templates?nav=entity&group=${row?.type}`);
                      store.onClickBtn("entity", row?.type);
                      store.expandedNav = "entity";
                    });
                    handleClose();
                    localStorage.setItem("templateAction", "edit");
                  }}
                  id={`Templates_Table_EditOrDuplicate_Button_${row.type}_${row.id}`}
                >
                  {store.navUrl !== "entity" ? "Duplicate" : "Edit Template"}
                </CustomButton>
              ) : (
                <Link
                  $disabled={!hasFeature(row)}
                  href={
                    row.type === "communication"
                      ? `communication-template?id=${row.id}`
                      : `questionnaire-template?id=${row.id}`
                  }
                >
                  <CustomButton
                    id={`Templates_Table_EditOrDuplicate_Button_${row.type}_${row.id}`}
                    disabled={!hasFeature(row)}
                    onClick={() => localStorage.setItem("templateAction", "edit")}
                    variant="outlined"
                  >
                    {store.navUrl !== "entity" ? "Duplicate" : "Edit Template"}
                  </CustomButton>
                </Link>
              )}
            </TableName>
          </TableCellWrapperEnd>
        ),
      },
    ],
  };

  return (
    <>
      <CustomTable
        list={props.list ?? []}
        sort={store.sortField}
        hideAddButton={false}
        sortType={store.sortType}
        data={tableData}
        changeSort={(s: string, t: string) => store.changeSort(s, t)}
      />
      <CustomModal
        width="522px"
        open={store.openSharePopup}
        id={"Template_Share"}
        hide={(flag) => store.openClickPopup(flag)}
        children={<ShareTemplatePopup />}
      />

      <Menu
        anchorEl={anchorEl}
        id="Templates_OptionMenu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {selectedRow?.type !== "communication" && (
          <MenuItem
            id="Templates_OptionMenu_Preview"
            onClick={() => (window.location.href = `/survey-preview?idTemplate=${selectedRow.id}`)}
          >
            Preview {selectedRow?.type === "communication" ? "comms" : "questionnaire"}
          </MenuItem>
        )}
        <MenuItem
          id="Templates_OptionMenu_Duplicate"
          onClick={() => {
            store.duplicateTemplate(selectedRow, () => {
              navigate(`/templates?nav=entity&group=${selectedRow?.type}`);
              store.onClickBtn("entity", selectedRow?.type);
              store.expandedNav = "entity";
            });
            handleClose();
          }}
          disabled={!hasFeature(selectedRow)}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          id="Templates_OptionMenu_Share"
          onClick={() => {
            store.shareTemplateRow(selectedRow);
            handleClose();
            store.loadEntityTemplates();
          }}
          disabled={
            selectedRow?.type === "questionnaire"
              ? !StorePermission.features.questionnaire_sharing.hasFeature
              : !StorePermission.features.communications_sharing.hasFeature
          }
        >
          Share with
        </MenuItem>
        {store.navUrl !== "engageRocket" && (
          <MenuItem
            id="Templates_OptionMenu_Delete"
            onClick={() => {
              store.RemoveTemplate(selectedRow);
              handleClose();
            }}
            disabled={!hasFeature(selectedRow)}
          >
            Delete
          </MenuItem>
        )}
      </Menu>
    </>
  );
});

const RevokeAdminBtn = styled(IconButton)`
  margin-right: 10px !important;
  height: 30px !important;
`;

const TableCellWrapper = styled.div`
  display: flex;
  min-height: 30px;
  min-width: 200px;
  justify-content: flex-start;
  align-items: center;
`;

const TableCellWrapperEnd = styled.div`
  display: flex;
  min-height: 30px;
  min-width: 200px;
  justify-content: flex-end;
  align-items: center;
`;

const LinkPreviewText = styled.span`
  margin-left: 1px;
`;

const LinkPreview = styled.a`
  margin-left: 5px;
  color: var(--colorBrandForeground1);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

const Link = styled.a<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  color: blue;
  pointer-events: ${(props) => props.$disabled && "none"};
  font-size: 14px;

  &:hover {
    text-decoration: none !important;
  }
`;

const PreviewColumn = styled.div`
  display: flex;
  align-items: center;
`;

const TableName = styled.div`
  color: var(--colorNeutralForeground1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const UpdatedBlock = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: var(--colorPaletteVioletBackground1);
  margin: 0;
`;

const UpdatedDiv = styled.p`
  color: var(--colorNeutralForeground1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const CreatedDiv = styled.div`
  color: var(--colorNeutralForeground2);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export default CommunicationTableTable;
