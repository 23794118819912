import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SORTALE_SETTINGS_LIST, SORTALE_SETTINGS_VALUE_LIST } from "constants/dashboard-recipients";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import store from "./store";
import styled from "styled-components";
import Header from "./Header";
import Filters from "../Filters";
import SearchIcon from "@mui/icons-material/Search";
import filtersStore from "../store";
import RecipientsTable from "./RecipientsTable";
import ParticipationCard from "./ParticipationCard";
import useDebounce from "utils/useDebounse";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";

type RecipientsPageProps = {};

const RecipientsPage: FC<RecipientsPageProps> = observer(() => {
  const [localFilters, setLocalFilters] =
    useState<{ id: number; name: string; segments: string[] }[]>(null);

  const navigate = useNavigate();

  const debouncedSearchValue = useDebounce(store.searchValue, 500);
  let { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const survey_id = queryParams.get("survey_id");

  useEffect(() => {
    const filters = filtersStore.filters
      .map(
        (el, index) =>
          el.unit_or_attr && {
            id: index,
            name: el.unit_or_attr.name,
            segments: el.segments,
          },
      )
      .filter((i) => i);
    setLocalFilters(filters);
  }, [filtersStore.filters]);

  useEffect(() => {
    const surveyInfo = localStorage.getItem("dashboardActiveSurvey");

    if (!survey_id) return navigate("/dashboard");
    else {
      store.setData("surveyId", survey_id);
      store.setData("surveyGlobalInfo", JSON.parse(surveyInfo));
    }
  }, []);

  useEffect(() => {
    if (store.surveyId) {
      const stringFilters = localStorage.getItem("dashboard_filters");
      const filters = JSON.parse(stringFilters);
      filtersStore.setData(filters, "filters");
    }
  }, [store.surveyId]);

  useEffect(() => {
    if (store.surveyId && localFilters) {
      store.getRecipientsList(store.surveyId, localFilters, debouncedSearchValue);
      store.getRecipientsInfo(store.surveyId, localFilters);
    }
  }, [
    store.surveyId,
    store.requestData.activePage,
    debouncedSearchValue,
    store.requestData.sort_by,
    store.requestData.sort_type,
    localFilters,
  ]);

  const handleChangeSortableInput = (e: SelectChangeEvent<string>) => {
    store.setData("sortBy", e.target.value);
    switch (e.target.value) {
      case SORTALE_SETTINGS_VALUE_LIST.preff_name_a_z:
        return store.setData("requestData", {
          ...store.requestData,
          sort_by: "preferred",
          sort_type: "asc",
        });
      case SORTALE_SETTINGS_VALUE_LIST.preff_name_z_a:
        return store.setData("requestData", {
          ...store.requestData,
          sort_by: "preferred",
          sort_type: "desc",
        });
      case SORTALE_SETTINGS_VALUE_LIST.name_a_z:
        return store.setData("requestData", {
          ...store.requestData,
          sort_by: "name",
          sort_type: "asc",
        });
      case SORTALE_SETTINGS_VALUE_LIST.name_z_a:
        return store.setData("requestData", {
          ...store.requestData,
          sort_by: "name",
          sort_type: "desc",
        });
      case SORTALE_SETTINGS_VALUE_LIST.email_a_z:
        return store.setData("requestData", {
          ...store.requestData,
          sort_by: "email",
          sort_type: "asc",
        });
      case SORTALE_SETTINGS_VALUE_LIST.email_z_a:
        return store.setData("requestData", {
          ...store.requestData,
          sort_by: "email",
          sort_type: "desc",
        });
      default:
        return store.setData("requestData", {
          ...store.requestData,
          sort_by: "preferred",
          sort_type: "asc",
        });
    }
  };

  return (
    <Container>
      <Header />
      <Content $isImpersonated={StoreLayout.isImpersonated}>
        <TitleRow>
          <Title>
            Recipients ({(store.surveyInfo && store.surveyInfo?.recipients_total) || "0"})
          </Title>
          <Divider />
          <Description>
            {store.surveyGlobalInfo && store.surveyGlobalInfo.projectName}:{" "}
            {store.surveyGlobalInfo && store.surveyGlobalInfo.surveyName}
          </Description>
          <StatusLabel>
            {(store.surveyGlobalInfo && store.surveyGlobalInfo.status) || "None"}
          </StatusLabel>
        </TitleRow>
        <Filters surveyId={store.surveyId} />
        <SortableRow>
          <SortableRowText>Sort by:</SortableRowText>
          <StyledSelect
            id="select_sortable_list"
            value={store.sortBy}
            onChange={handleChangeSortableInput}
            displayEmpty
          >
            {SORTALE_SETTINGS_LIST.map((el) => (
              <MenuItem value={el.value} key={el.value}>
                {el.label}
              </MenuItem>
            ))}
          </StyledSelect>
          <StyledSearchInput
            size="small"
            placeholder="Search for name/email"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => {}}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={store.searchValue}
            onChange={(e) => store.setData("searchValue", e.target.value)}
          />
        </SortableRow>
        <ContentConainer>
          <RecipientsTable />
          <ParticipationCard />
        </ContentConainer>
      </Content>
    </Container>
  );
});

export default RecipientsPage;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Content = styled.div<{ $isImpersonated: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 80px 160px;
  overflow-y: auto;
  ${(props) =>
    props.$isImpersonated ? "height: calc(100vh - 112px)" : "height: calc(100vh - 72px)"};
`;

const TitleRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: 28px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const Description = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin: 8px 8px 0px 0px;
`;

const Divider = styled.hr`
  width: 1px;
  height: 18px;
  margin: 4px 16px 0px 16px;
`;

const StatusLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  background-color: var(--colorNeutralBackground3);
  margin: 4px 0px 0px 0px;
  border-radius: 2px;
  height: 20px;
`;

const SortableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 32px;
`;

const SortableRowText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  color: var(--colorNeutralForeground2);
`;

const StyledSelect = styled(Select)`
  height: 36px;
  width: 100%;
  max-width: 225px;
  background-color: var(--colorNeutralBackground1) !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin: 0px 16px 0px 5px;
  border-radius: 4px !important;
  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }
`;

const StyledSearchInput = styled(TextField)`
  background-color: transparent;
  max-width: 220px;

  input {
    padding: 8px 12px !important;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
  }

  &::placeholder {
    color: var(--colorNeutralForeground5) !important;
  }

  svg {
    font-size: 18px;
    color: var(--colorNeutralForeground5) !important;
  }
`;

const ContentConainer = styled.div`
  width: 100%;
  display: flex;
`;
