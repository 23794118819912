import { FC, useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useLocation } from "react-router";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import store from "./store";
import modalStore from "features/QuestionTemplate/store";
import selectStore from "features/Survey/SelectTemplate/storeSurvey";
import SurveyEdit from "./Survey";
import SurveyTranslations from "./SurveyTranslations";
import CustomButton from "components/button/Button";
import { Dimensions } from "./Dimensions";
import CustomModal from "components/modal/Modal";
import SelectTemplate from "features/Survey/SelectTemplate/cardGrid";
import ReplaceQuestions from "./ReplaceQuestions";
import { createUrl } from "helpers/url";
import QuestionsIcon from "./icons/QuestionsIcon";
import TranslationsIcon from "./icons/TranslationsIcon";
import TagDimensionsIcon from "./icons/TagDimensionsIcon";

type QuestionnaireTemplatesProps = {};

const QuestionnaireTemplates: FC<QuestionnaireTemplatesProps> = observer(() => {
  let query = useQuery();
  let idTemplate = query.get("id") != null ? query.get("id") : "0";

  useEffect(() => {
    if (idTemplate === "0") {
      store.changeSelectTemplate(true);
    } else {
      store.loadAllData(Number(idTemplate));
      modalStore.changeTemplateId(Number(idTemplate));
      store.changeSelectTemplate(false);
    }
    store.getAllSupportedLanguages();
    store.getAllQuestionDimensions();
    store.loadAttributesAndSegmentsByEntity();
  }, []);

  const handlerMyOwnQuestions = () => {
    store.changeSelectTemplate(false);
    selectStore.clearStore();
  };

  const handlerHide = (flag: boolean) => {
    store.changeOpenSelectTemplateConfirm(flag);
    selectStore.clearStore();
  };

  const handlerPreviewSurvey = async () => {
    const valid = await store.checkValidTitle();
    if (valid) {
      await store.saveQuestionnaireTemplate(() => {}, valid);
      window.open(
        createUrl({
          path: "/survey-preview",
          query: {
            idTemplate: store.idTemplate.toString(),
          },
        }),
        "_blank",
      );
    }
  };

  return (
    <>
      <CustomModal
        id="Template_Select"
        padding="0"
        open={store.openSelectTemplate}
        hide={(flag) => store.changeSelectTemplate(flag)}
        width="100%"
        maxWidth="xl"
        overflow="clip"
      >
        <SelectTemplate
          project_id={0}
          templateType="questionnaire"
          addedQuestionJsons={store.questionJsons}
          MyOwnQuestionsClicked={handlerMyOwnQuestions}
          onQuestionsAdded={(questions) => store.templateLibraryAdded(questions)}
        />
      </CustomModal>

      <CustomModal
        id="Template_Replace_Questions"
        open={store.openSelectTemplateConfirm}
        hide={(flag) => handlerHide(flag)}
        maxWidth="sm"
      >
        <ReplaceQuestions />
      </CustomModal>

      <MainWrapper $isError={store.isTitleError}>
        <HeaderMenuWrapper>
          <HeaderMenuWrapper1 id="Template_Questionnaire_Menus">
            <SurveyTabs
              id="Template_Questionnaire_Menu_Questions"
              $active={store.activePage === "edit"}
              onClick={() => store.changeTab("edit")}
            >
              <QuestionsIcon />
              <SurveyText id="Template_Questionnaire_Menu_Questions_Label">
                1.1 Questions
              </SurveyText>
            </SurveyTabs>

            <SurveyTabs
              id="Template_Questionnaire_Menu_Translations"
              $active={store.activePage === "translation"}
              onClick={() => store.changeTab("translation")}
            >
              <TranslationsIcon />
              <SurveyText id="Template_Questionnaire_Menu_Translations_Label">
                1.2 Translations
              </SurveyText>
            </SurveyTabs>

            <SurveyTabs
              id="Template_Questionnaire_Menu_Dimensions"
              $active={store.activePage === "dimension"}
              onClick={() => store.changeTab("dimension")}
            >
              <TagDimensionsIcon />
              <SurveyText id="Template_Questionnaire_Menu_Dimensions_Label">
                1.3 Tag dimensions & set up overall score
              </SurveyText>
            </SurveyTabs>
          </HeaderMenuWrapper1>

          <ButtonWrapper>
            <PreviewSurveyButton
              id="Template_Questionnaire_Menu_Preview_Button"
              variant="outlined"
              onClick={handlerPreviewSurvey}
            >
              <RemoveRedEyeOutlinedIcon />
              Preview survey
            </PreviewSurveyButton>
          </ButtonWrapper>
        </HeaderMenuWrapper>

        {store.activePage === "edit" && <SurveyEdit />}
        {store.activePage === "translation" && <SurveyTranslations />}
        {store.activePage === "dimension" && <Dimensions />}
      </MainWrapper>
    </>
  );
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SurveyTabs = styled.div<{ $active?: boolean }>`
  background-color: ${(props) =>
    props.$active ? "var(--colorPaletteBlueBackground2)" : `var(--colorNeutralForeground4)`};
  padding: 20px 20px 20px 20px;
  cursor: pointer;
  color: var(--colorNeutralForeground1);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
`;
const SurveyText = styled.span`
  margin-left: 10px;
`;

const ButtonWrapper = styled.div`
  margin: 10px;
`;

const HeaderMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--colorNeutralBackground1);
`;

const HeaderMenuWrapper1 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--colorNeutralBackground1);
  padding: 0 80px;
`;

const MainWrapper = styled.div<{ $isError?: boolean }>`
  width: 100%;
  margin-top: 56px;
  min-height: 500px;
  .sd-title .sd-title .sv-string-editor {
    color: ${(props) => props.$isError && "var(--colorPaletteRedForeground2)"};
  }
`;

const PreviewSurveyButton = styled(CustomButton)`
  width: 180px;
  margin-right: 41px !important;

  svg {
    margin-right: 7px;
  }
`;

export default QuestionnaireTemplates;
