import { FC, useEffect } from "react";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import { Box, Typography } from "@mui/material"
import store from "./store"
import { observer } from "mobx-react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";



type QRCodePopupProps = {
  closePopup: () => void;
};

const CheckPopup: FC<QRCodePopupProps> = observer((props) => {

  return (
    <PopupWrapper>
      <BoxStyled onClick={() => {
        store.setOpenTemplateCommCheckModal(false)
        store.setOPenTemplateCommModal(true)
      }}>

        <BackButton>
          <KeyboardBackspaceIcon />
        </BackButton>
        <BackToTemplates>Back to survey project</BackToTemplates>
      </BoxStyled>

      <BoxStyled>
        <Styledh2>Replace existing communications?</Styledh2>
      </BoxStyled>
      <BoxStyled>
        <CenterTypography>Would you like to replace your current communications with the template selected?</CenterTypography>
      </BoxStyled>

      <BtnBox>
        <BtnWrapp>
          <CustomButton
            onClick={() => {
              store.checkCommLanguageInconsistency()
            }}
            variant="contained"
          >
            Yes, replace all comms
          </CustomButton>
        </BtnWrapp>
        <BtnWrapp>
          <CustomButton
            onClick={() => {
              store.closeTemplateCommCheckModal()
            }}
            variant="outlined"
          >
            Keep existing comms
          </CustomButton>
        </BtnWrapp>
      </BtnBox>

    </PopupWrapper >
  );
});

const PopupWrapper = styled.div`
  height: 300px;
`
const CenterTypography = styled(Typography)`
  margin: auto !important;
  padding: 20px;
  margin-bottom: 60px;
  border-radius: 5px;
`

const Styledh2 = styled.h2`
  margin: 20px 20px 0px 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
`

const BoxStyled = styled(Box)`
  margin: 20px;
  display: flex;
  justify-content: start;
  align-items: center;
`

const BtnBox = styled(Box)`
  margin: 20px;
  display: flex;
`

const BtnWrapp = styled(Box)`
  margin: 10px;
`

const BackToTemplates = styled.span`
  cursor: pointer;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground5);
`;

const BackButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--colorNeutralForeground5);
  border: none;
  margin-right: 8px;

  svg {
    fill: var(--colorNeutralBackground1);
    width: 16px;
  }
`;




export default CheckPopup;
