import http from "api/https";
import { createUrl } from "helpers/url";


export const getWorkspaceSetting = (idWorkspace: number): Promise<any> => {
  if (http.isAPIGO) {
    return http.getGO(`/workspace/${idWorkspace}/settings`)
  } else {
    return http.get(createUrl({
      path: "/workspace/GetWorkspaceSetting",
      query: {
        idWorkspace: idWorkspace.toString(),
      },
    }));
  }
};
