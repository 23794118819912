import { makeAutoObservable, runInAction } from "mobx";
import { GetSurveyTags, SurveyTagsType } from "api/go/useGetSurveyTags";
import { updateTemplateComms } from "api/go/useUpdateTemplateComms";
import { GetOneTemplate } from "api/go/useGetOneTempl";
import modalStore from "features/Templates/CommunicationTemplates/store";
import MainStore from "MainStore";

class Store {
  templateId: number = 0;
  templateName: string = "";
  tags: SurveyTagsType[] = [];
  selectedTagNames: string[] = [];
  newTagName: string = "";

  constructor() {
    makeAutoObservable(this);
    this.tags = [];
  }

  loadSettingbyid(id: number) {
    MainStore.changeLoader(true);
    if (id === undefined || id === null || id === 0) return;
    GetOneTemplate(id).then((x) => {
      MainStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.templateId = x.data.id;
        this.templateName = x.data.name;
        this.selectedTagNames = x.data.tags.map((el: { name: string }) => el.name);
      } else if (x?.data !== undefined) {
        MainStore.changeLoader(false);
        MainStore.setSnackbar("Settings successfully saved!");
      }
    });
  }

  changeTemplateName(value: string) {
    this.templateName = value;
  }

  handleChange(name: string, value: any) {
    this[name] = value;
  }

  onSave = async () => {
    const template = {
      id: this.templateId - 0,
      name: this.templateName,
      tagNames: this.selectedTagNames,
    };
    try {
      MainStore.changeLoader(true);
      await updateTemplateComms(template);
      runInAction(() => {
        modalStore.setOpenCommsModal(false);
        MainStore.changeLoader(false);
        MainStore.setSnackbar(`"${this.templateName}" template successfully saved`, "success");
        this.clearStore();
      });
    } catch {
      MainStore.changeLoader(false);
      MainStore.setSnackbar("The title must be unique", "error");
    }
  };

  loadTags = async () => {
    try {
      const response = await GetSurveyTags();
      this.tags = response;
    } catch {
      MainStore.setSnackbar("Can't found tags", "error");
    }
  };

  changeValue = (name: string, value: any) => {
    this[name] = value;
  };

  clearStore() {
    this.templateId = 0;
    this.templateName = "";
    this.tags = [];
    this.selectedTagNames = [];
    this.newTagName = "";
  }

  changeSelectedTagName = (value: string[]) => {
    this.selectedTagNames = value;
  }
}

const store = new Store();
export default store;
