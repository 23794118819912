import { FC } from "react";
import { AccountSettingsScreen } from "../../features/AccountSettings";

type AccountSettingsPageProps = {};

const AccountSettingsPage: FC<AccountSettingsPageProps> = () => {
  return <AccountSettingsScreen />;
};

export default AccountSettingsPage;
