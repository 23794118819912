import http from "api/https";
import { AxiosResponse } from "axios";

class UnexpectedApiError extends Error {
  constructor(response: AxiosResponse<any, any>) {
    super();
  }
}

interface TData {
  survey_id: number;
  strsurvey_date_start: string;
  strsurvey_date_end: string;
  strtimestart_milestone: string;
  leave_date_empty: boolean;
  close_now: boolean;
}

export const saveSurveyDates = async (data: TData): Promise<any> => {
  const url = http.isAPIGO ? "/sm/sm_survey/SaveSurveyDates" : "/sm_survey/SaveSurveyDates";
  const response = http.isAPIGO ? await http.postGO(url, data) : await http.post(url, data);

  if ([200, 201].includes(response.status)) {
    return response.data;
  }

  throw new UnexpectedApiError(response);
};