import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker  } from '@mui/x-date-pickers/TimePicker';
import styled from "styled-components";
import CalendarIcon from './CalendarIcon'
import { observer } from 'mobx-react';

type TimePickerValueProps = {
  value: Dayjs;
  changeValue: (value: Dayjs) => void;
  disabled?: boolean; 
};


const TimePickerValue: React.FC<TimePickerValueProps> = observer((props) => {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <WrappedTimeField
        disabled={props.disabled}
        value={props.value}
        label={"Select time"}
        closeOnSelect={false}
        onChange={(newValue: Dayjs) => {
          if(newValue instanceof dayjs || newValue === null){
            props.changeValue(newValue)
          }
        }}
        slots={{
          openPickerIcon: CalendarIcon
        }}
      />
    </LocalizationProvider>
  );
})

const WrappedTimeField = styled(TimePicker)`
  input{
    padding: 6px 8px;
    border-color: #CDD3EC;
  }
    label {
        position: absolute;
        top: -9px;
    }
`
export default TimePickerValue;