import { FC } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import store from "./store";
import { Chip, Grid } from "@mui/material";
import dayjs from "dayjs";
import CustomButton from "components/button/Button";
import { useNavigate } from "react-router";
import { SEND_OUT_SURVEY_TYPES } from "../../../constants/send-out-survey";
import { createUrl } from "helpers/url";

type SurveyListProps = {};

const SurveyResult: FC<SurveyListProps> = observer(() => {
  const nextSurveyId = () => {
    const milestone_day = store.data.find(x => x.id === store.curentSurveyId)?.milestone_day
    let minDay = Infinity
    let minDayId: number = null
    store.data.forEach(element => {
      if (element?.milestone_day > milestone_day && element?.milestone_day < minDay) {
        minDay = element?.milestone_day
        minDayId = element?.id
      }
    });
    return minDayId
  }
  const navigate = useNavigate();
  return (
    <MainContent>
      <SurveyEndText id="Survey_List_Result_Title">
        Great! The survey is done and will go out with the details listed below
      </SurveyEndText>
      <MainWrapper>
        <Grid container>
          <Grid item xl={3} md={6}>
            <Block>
              <TextSecondary id="Survey_List_Result_Info_Found_Label">
                Found in Project
              </TextSecondary>
              <TextBody id="Survey_List_Result_Info_Found">
                {store.selectedSurveyData?.project_idNavName}
              </TextBody>
            </Block>
            <Block>
              <TextSecondary id="Survey_List_Result_Info_Tags_Label">Tags</TextSecondary>
              <TextBody id="Survey_List_Result_Info_Tags">
                {store.curentTags.map((x) => (
                  <Chip
                    size="small"
                    sx={{ backgroundColor: "#CDD3EC", mr: 1, mb: 1 }}
                    label={x.name}
                  ></Chip>
                ))}
              </TextBody>
            </Block>
          </Grid>
          <Grid item xl={3} md={6}>
            <Block>
              <TextSecondary id="Survey_List_Result_Info_Questions_Label">
                Questions, Est. time
              </TextSecondary>
              <TextBody id="Survey_List_Result_Info_Questions">
                {store.selectedSurveyData?.count_questions + " questions, "}
                {store.selectedSurveyData?.questionary_estimation + " "}
                <Chip
                  size="small"
                  sx={{ backgroundColor: "#CDD3EC", mr: 1, mb: 1 }}
                  label={store.selectedSurveyData?.count_dimensions + " dimensions"}
                ></Chip>
              </TextBody>
            </Block>
            <Block>
              <TextSecondary id="Survey_List_Result_Info_Languages_Label">Languages</TextSecondary>
              <TextBody id="Survey_List_Result_Info_Languages">
                {store.selectedSurveyData?.languages.map((x) => (
                  <Chip
                    size="small"
                    sx={{ backgroundColor: "#CDD3EC", mr: 1, mb: 1 }}
                    label={x.name}
                  ></Chip>
                ))}
              </TextBody>
            </Block>
          </Grid>
          {store.projecttype_code !== SEND_OUT_SURVEY_TYPES.Milestones && (
            <Grid item xl={3} md={6}>
              <Block>
                <TextSecondary id="Survey_List_Result_Info_LaunchDate_Label">
                  Launch date
                </TextSecondary>
                <TextBody id="Survey_List_Result_Info_LaunchDate">
                  {" "}
                  {store.selectedSurveyData?.survey_date_start !== null &&
                    ToString(store.selectedSurveyData?.survey_date_start)}{" "}
                </TextBody>
              </Block>
              <Block>
                <TextSecondary id="Survey_List_Result_Info_EndDate_Label">End date</TextSecondary>
                <TextBody id="Survey_List_Result_Info_EndDate">
                  {store.selectedSurveyData?.survey_date_end !== null &&
                    ToString(store.selectedSurveyData?.survey_date_end)}{" "}
                </TextBody>
              </Block>
            </Grid>
          )}
          <Grid item xl={3} md={6}>
            <Block>
              <TextSecondary id="Survey_List_Result_Info_Recipients_Label">
                Recipients{" "}
              </TextSecondary>
              <TextBody id="Survey_List_Result_Info_Recipients">
                {store.selectedSurveyData?.count_recipients !== 0 &&
                  store.selectedSurveyData?.count_recipients !== null ? (
                  store.selectedSurveyData?.count_recipients + " employees"
                ) : (
                  <>
                    {store.selectedSurveyData?.count_recipients ?? 0}
                    {!store.selectedSurveyData?.count_recipients && (
                      <Link
                        id="Survey_List_Result_Info_Recipients_Link"
                        href={"/recipients?id=" + store.selectedSurveyData?.id}
                      >
                        {" "}
                        + Add recipients
                      </Link>
                    )}
                  </>
                )}
              </TextBody>
            </Block>
            <Block>
              <TextSecondary id="Survey_List_Result_Info_EmailsSentOut_Label">
                emails sent out
              </TextSecondary>
              <TextBody id="Survey_List_Result_Info_EmailsSentOut_Invite">
                {store.selectedSurveyData?.count_invite_sent} invite{" "}
              </TextBody>
              <TextBody id="Survey_List_Result_Info_EmailsSentOut_Reminders">
                {store.selectedSurveyData?.count_reminder_sent} reminders{" "}
              </TextBody>
              <TextBody id="Survey_List_Result_Info_EmailsSentOut_Report">
                {store.selectedSurveyData?.count_report_sent} report{" "}
              </TextBody>
            </Block>
          </Grid>
        </Grid>
      </MainWrapper>
      <SurveyButton>
        {store.projecttype_code === SEND_OUT_SURVEY_TYPES.Milestones &&
          nextSurveyId() ? (
          <ButtonsWrapper>
            <ButtonWrapper>
              <CustomButton
                id="Survey_List_Result_NextMilestone_Button"
                variant="contained"
                onClick={() => {
                  const query: { id: string; } = {
                    id: nextSurveyId().toString(),
                  };
                  navigate(createUrl({
                    path: "/survey",
                    query: query,
                  }));
                }}
              >
                Setup next milestone
              </CustomButton>
            </ButtonWrapper>
          </ButtonsWrapper>
        ) : (
          <ButtonsWrapper>
            <ButtonWrapper>
              <CustomButton
                id="Survey_List_Result_GoToProjects_Button"
                variant="contained"
                onClick={() => {
                  store.clothCurentSurvey();
                }}
              >
                Go to projects
              </CustomButton>
            </ButtonWrapper>
          </ButtonsWrapper>
        )}
        <EditSettings
          id="Survey_List_Result_BackToEdit_Button"
          onClick={() => {
            navigate("/survey?id=" + store.curentSurveyId);
          }}
        >
          Back to edit survey
        </EditSettings>
      </SurveyButton>
    </MainContent>
  );
});

function ToString(date: string) {
  let date_dayjs = dayjs(date);
  return date_dayjs.format("ddd, D MMM YYYY");
}

export default SurveyResult;

const MainContent = styled.div`
  margin-top: 20px;
`;

const SurveyEndText = styled.div`
  color: var(--colorNeutralForeground1, --colorNeutralForeground1);
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 180% */
`;

const Link = styled.a`
  margin-left: 5px;
  color: var(--colorBrandForeground1, --colorBrandForeground1);
  /* ER Theme/body/link */
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */

  &:hover {
    text-decoration-line: underline;
  }
`;

const MainWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  margin-top: 20px;
  justify-content: space-between;
  border-radius: 10px;
  background: var(--colorNeutralBackground1, --colorNeutralBackground1);
  padding: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 20px;
`;

const ButtonsWrapper = styled.div`
  min-height: 50px;
`;

const TextSecondary = styled.div`
  color: var(--colorNeutralForeground2, --colorNeutralForeground2);
  /* ER Theme/label/medium */
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  text-transform: uppercase;
  margin-bottom: 7px;
  margin-top: 10px;
`;

const TextBody = styled.div`
  color: var(--colorNeutralForeground1, --colorNeutralForeground1);
  /* ER Theme/body/medium */
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const Block = styled.div`
  margin: 20px 0;
`;

const EditSettings = styled.div`
  display: flex;
  align-items: center;
  color: var(--colorBrandForeground1, --colorBrandForeground1);
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  cursor: pointer;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin-right: 20px;

  &:hover {
    text-decoration-line: underline;
  }
`;

const SurveyButton = styled.div`
  display: flex;
  align-items: center;
`;
