import http from "../https";

type TRequestBody = {
  survey_id: number;
  attributes: {
    id: number;
    name: string;
    segments: string[];
  }[];
  benchmarkType?: number;
  viewType: string;
  rowSettings: {
    overalScore: boolean;
    eNPS: boolean;
    questions: number[];
  };
};

export const useGetDashboardHotspots = (requestData: TRequestBody) =>
  http.post(`/Dashboard/hotspot`, requestData);
