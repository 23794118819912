import { FC, useEffect } from "react";
import { Box, Divider, IconButton, TextField } from "@mui/material";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import RoleTabs from "./tabs";
import store from "./store";
import InputAdornment from "@mui/material/InputAdornment";
import { observer } from "mobx-react";
import AddEditRoleName from "./../Roles/AddEditRole/popupAddEditRoleName";
import ChangeRolePopupForm from "./../Roles/ChangeRole/popupChangeRole";
import DeleteRolePopupForm from "./../Roles/DeleteRole/popupDeleteRole";
import InviteUsersForm from "./InviteUsers/popupDeleteRole";
import RemoveFromRoleForm from "./RemoveFromRole/removeFromRolePopup";
import InviteAgainForm from "./InviteAgain/inviteAgainPopup";
import CheckEmployee from "./CheckEmployee/popup";
import CustomButton from "components/button/Button";
import { InviteFormModal } from "components/invite-user-popup/InviteFormModal";
import storeInviteUsers from "./InviteUsers/store";

const Header = styled.h1`
  font-family: Roboto;
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

type EntityPermissionsUserProps = {
  changeTab: (tab: string) => void;
  idRole: string;
};

const EntityPermissionsUser: FC<EntityPermissionsUserProps> = observer((props) => {
  useEffect(() => {
    store.loadData().then(() => {
      if (props.idRole === "0") return;
      let roleIndex = store.data.map((x) => x.id).indexOf(+props.idRole);
      if (roleIndex == -1) {
        return;
      }
      store.changeTabId(roleIndex + 1);
    });
  }, []);

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"} sx={{ mb: 3 }}>
        <Header id="EntityPermissions_EntityUsers_Title">Entity users</Header>

        <Box>
          <TextField
            id="EntityPermissions_EntityUsers_SearchInput"
            size="small"
            placeholder="Search for name/email"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    id="EntityPermissions_EntityUsers_SearchBtn"
                    onClick={() => {
                      store.onSearchClicked();
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={store.searchedValue}
            onKeyDown={store.keyPress}
            onChange={(e) => store.changeSearchedValue(e.target.value)}
          />
        </Box>
      </Box>

      <Divider />

      <InviteFormModal
        open={store.inviteUsersPanel}
        onClose={() => store.changeInviteUsersPanel(false)}
        roleName={store.data.find((el) => el.id === store.currentRoleId)?.roleName}
        roleId={store.currentRoleId}
        inviteHandler={() => {
          storeInviteUsers.inviteUserSubmit(store.currentRoleId, () => {
            store.onIvitedUsers();
            store.changeInviteUsersPanel(false);
          });
        }}
        emails={storeInviteUsers.emails}
        value={storeInviteUsers.value}
        onChange={(value) => storeInviteUsers.changeFieldValue(value)}
        submitHandler={() => storeInviteUsers.addEmail()}
        deleteEmail={(index) => storeInviteUsers.deleteEmail(index)}
        clearStates={() => {
          storeInviteUsers.changeFieldValue("");
          storeInviteUsers.changeEmail([]);
        }}
      />

      <AddEditRoleName
        id="EntityPermissions_AddEditRoleNamePopUp"
        openPanel={store.addEditRoleNamePanel}
        onBtnCancelClick={() => store.changeAddEditRoleNamePanel(false)}
        onClose={() => store.changeAddEditRoleNamePanel(false)}
        onSaved={() => {
          store.onSaved();
        }}
        idRole={store.currentRoleId}
        roleName={store.currentRoleName}
      />

      <ChangeRolePopupForm
        id="EntityPermissions_CjangeRoleFormPopUp"
        openPanel={store.changeRolePanel}
        roles={store.data}
        onBtnCancelClick={() => store.changeChangeRolePanel(false)}
        isDeleteRole={false}
        countUsers={store.selectedData.length}
        userIds={store.selectedData}
        roleName={store.currentRoleName}
        userEmail={store.currentUser?.email}
        onClose={() => store.changeChangeRolePanel(false)}
        onSaved={() => {
          store.onRoleChanged();
          // props.changeTab("0")
        }}
        idRole={store.currentRoleId}
      />

      <DeleteRolePopupForm
        id="EntityPermissions_DeleteRolePopUp"
        openPanel={store.deleteRolePanel}
        roles={store.data}
        roleName={store.currentRoleName}
        onBtnCancelClick={() => {
          store.loadData();
          store.changeDeleteRolePanel(false);
        }}
        onClose={() => store.changeDeleteRolePanel(false)}
        onSaved={() => {
          store.onRoleDeleted();
        }}
        idRole={store.currentRoleId}
      />

      <RemoveFromRoleForm
        id="EntityPermissions_RemoveFromRoleFormPopUp"
        openPanel={store.removeFromRolePanel}
        onBtnCancelClick={() => store.changeRemoveFromRolePanel(false)}
        countUsers={store.selectedData.length}
        roleName={store.currentRoleName}
        user_ids={store.selectedData}
        userEmail={store.currentUser?.email}
        onClose={() => store.changeRemoveFromRolePanel(false)}
        onSaved={() => {
          store.onRemovedFromRole();
        }}
        idRole={store.currentRoleId}
      />

      <InviteAgainForm
        id="EntityPermissions_InviteAgainPopUp"
        openPanel={store.inviteAgainPanel}
        onBtnCancelClick={() => store.changeInviteAgainPanel(false)}
        countUsers={store.selectedData.length}
        idUsers={store.selectedData}
        onClose={() => store.changeInviteAgainPanel(false)}
        onSaved={() => {
          store.onInvitedAgain();
        }}
      />

      <CheckEmployee
        openPanel={store.checkEmployeePanel}
        onChangePanel={(bool) => store.changeCheckEmployeePanel(bool)}
        idEmployee={store.currentUserId}
      />

      {store.resultSearchValue != "" ? (
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          style={{ backgroundColor: "#E4E9FF", marginTop: 24, height: 50 }}
        >
          <Box sx={{ ml: 2 }}>
            "<span style={{ fontWeight: 500 }}>{store.resultSearchValue}</span>" can be found in the
            following roles
          </Box>

          <Box sx={{ mr: 3 }}>
            <CustomButton
              id="EntityPermissions_EntityUsers_SearchRemove"
              onClick={() => {
                store.changeResultSearchValue("");
                store.loadData();
              }}
            >
              Remove search
            </CustomButton>
          </Box>
        </Box>
      ) : (
        ""
      )}

      <Box sx={{ mt: 5 }}>
        <RoleTabs />
      </Box>
    </>
  );
});

export default EntityPermissionsUser;
