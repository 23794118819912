import { IconXlsx } from "./IconXlsx";
import { FC, useState } from "react";
import { SELECTED_ACTION_REQUIRED } from "constants/import-emploee";

import styled from "styled-components";
import Button from "components/button/Button";
import DoneIcon from "@mui/icons-material/Done";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { Step } from "constants/layout-types";
import { observer } from "mobx-react";
import CustomButton from "components/button/Button";
import { grey } from '@mui/material/colors';
import {
  Box
} from "@mui/material";

type ActionRequiredProps = {
  file: File;
};

const ActionRequired: FC<ActionRequiredProps> = observer((props) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const formName = "ActionRequired";
  return (
    <ActionRequiredWrapper id={`${formName}_actionRequiredWrapper`}>
      <Content id={`${formName}_content`}>
        <Header id={`${formName}_header`}>Employee List Update: Action Required</Header>
        <FileWrapper id={`${formName}_fileWrapper`}>
          <IconXlsx id={`${formName}_iconXlsx`} />
          <p id={`${formName}_fileName`}>{props.file?.name || "File not found"}</p>
        </FileWrapper>
        <Description id={`${formName}_description`}>
          We've identified {StoreLayout.importDoneAdded} employees not found on the uploaded Excel
          sheet. Please select the necessary course of action:
        </Description>
        <InfoContainer id={`${formName}_infoContainer`}>
          <InfoText id={`${formName}_infoText`}>
            <HelpOutlineIcon id={`${formName}_helpOutlineIcon`} /> What happens to removed
            employees?
          </InfoText>
          <InfoList id={`${formName}_infoList`}>
            <li id={`${formName}_infoListItem1`}>
              If surveys are currently ongoing and a removed employee has already responded, their
              feedback will be permanently discarded, and this action is irreversible.
            </li>
            <li id={`${formName}_infoListItem2`}>
              All login and permissions related to removed employees will be removed.
            </li>
          </InfoList>
        </InfoContainer>
        <SelectContainer id={`${formName}_selectContainer`}>
          <SelectItem
            id={`${formName}_selectItemRemoveEmployees`}
            $left={true}
            $active={Boolean(selectedOption === SELECTED_ACTION_REQUIRED.remove_employees)}
          >
            <SelectButton
              id={`${formName}_selectButtonRemoveEmployees`}
              onClick={() => {
                StoreLayout.changeImportSelectedMergeChoice(
                  SELECTED_ACTION_REQUIRED.remove_employees,
                );
                setSelectedOption(SELECTED_ACTION_REQUIRED.remove_employees);
              }}
            >
              {selectedOption === SELECTED_ACTION_REQUIRED.remove_employees && (
                <DoneIcon id={`${formName}_doneIconRemoveEmployees`} />
              )}
            </SelectButton>
            <span id={`${formName}_spanRemoveEmployees`}>Merge list</span>
            <p id={`${formName}_descriptionRemoveEmployees`}>
              Employee details will be updated. New employees found on the sheet will be added.
              Employees not found on the sheet will remain on the employee list.
            </p>
          </SelectItem>
          <SelectItem
            id={`${formName}_selectItemReplaceList`}
            $active={Boolean(selectedOption === SELECTED_ACTION_REQUIRED.replace_entire_list)}
          >
            <SelectButton
              id={`${formName}_selectButtonReplaceList`}
              onClick={() => {
                StoreLayout.changeImportSelectedMergeChoice(
                  SELECTED_ACTION_REQUIRED.replace_entire_list,
                );
                setSelectedOption(SELECTED_ACTION_REQUIRED.replace_entire_list);
              }}
            >
              {selectedOption === SELECTED_ACTION_REQUIRED.replace_entire_list && (
                <DoneIcon id="doneIconReplaceList" />
              )}
            </SelectButton>
            <span id={`${formName}_spanReplaceList`}>Replace entire list</span>
            <p id={`${formName}_descriptionReplaceList`}>
              Remove employees not found on the sheet. Substitute the existing employee list with
              this file.
            </p>
          </SelectItem>
        </SelectContainer>
      </Content>
      <ButtonWrapper id={`${formName}_buttonWrapper`}>
        <ConfirmButton
          id={`${formName}_confirmButton`}
          variant="contained"
          disabled={!selectedOption}
          onClick={() => {
            StoreLayout.clickReviewImport()
            StoreLayout.changeImportCurrentStep(Step.ReviewChanges)
          }}
        >
          Proceed
        </ConfirmButton>
        <StyledCancelButton
          id={"ImportCancelButton"}
          onClick={() => StoreLayout.changeImportCancel()}
          variant="outlined"
          color="inherit"
        >
          Cancel
        </StyledCancelButton>
      </ButtonWrapper>
    </ActionRequiredWrapper>
  );
});

export default ActionRequired;

const ActionRequiredWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Content = styled.div`
  width: 100%;
  max-width: 660px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
`;

const Header = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0 0 20px 0;
`;

const FileWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 28px;

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: var(--colorNeutralForeground1);
  }

  svg {
    margin-right: 10px;
  }
`;

const Description = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 660px;
  display: flex;
  justify-content: space-between;
`;

const ConfirmButton = styled(Button)`
  width: 100%;
  height: 34px;
  max-width: 180px !important;
  margin-top: 40px !important;
`;

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 22px 16px;
  border-radius: 4px;
  background-color: var(--colorNeutralBackground1);
  margin: 20px 0;
`;

const InfoText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
  display: flex;
  align-items: center;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    color: var(--colorPalettePurpleBackground2);
  }
`;

const InfoList = styled.ul`
  padding: 0 0 0 22px;

  li {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--colorNeutralForeground2);

    &::marker {
      font-size: 20px;
    }
  }
`;

const SelectContainer = styled.div`
  width: 100%;
  display: flex;
`;

const SelectItem = styled.div<{ $left?: boolean; $active: boolean }>`
  width: 100%;
  min-width: 250px;
  padding: 20px;
  border-radius: 4px;
  background-color: var(--colorNeutralBackground1);
  ${(props) => props.$left && " margin-right: 30px;"};
  border: ${(props) =>
    props.$active
      ? "1px solid var(--colorBrandForeground1)"
      : "1px solid var(--colorPaletteBlueBackground1)"};
  position: relative;
  cursor: default;

  button {
    background-color: ${(props) =>
    props.$active ? "var(--colorBrandForeground1)" : "var(--colorNeutralBackground3)"};
  }

  span,
  p {
    font-family: Roboto;
    font-weight: 400;
  }

  span {
    font-size: 16px;
    line-height: 24px;
    color: var(--colorNeutralForeground1);
  }

  p {
    font-size: 14px;
    line-height: 20px;
    color: var(--colorNeutralForeground2);
    margin-top: 20px;
  }
`;

const SelectButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 10px;
  border: none;
  cursor: pointer;
  background-color: var(--colorNeutralBackground3);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    height: 16px;
    color: var(--colorNeutralBackground1);
  }
`;

const ButtonWr = styled.div`
margin-right: 20px;
`;

const StyledCancelButton = styled(CustomButton)`
  width: 100%;
  max-width: 75px;
  margin-left: 160px;
  margin-top: 40px !important;
`;