import { ReactElement } from "react";

const TagDimensionsIcon = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
      <path
        d="M9.75333 0.895938C9.51333 0.555938 9.11333 0.335938 8.66667 0.335938L1.33333 0.342604C0.6 0.342604 0 0.935937 0 1.66927V8.33594C0 9.06927 0.6 9.6626 1.33333 9.6626L8.66667 9.66927C9.11333 9.66927 9.51333 9.44927 9.75333 9.10927L12.6667 5.0026L9.75333 0.895938Z"
        fill="#1E004D"
      />
    </svg>
  );
};

export default TagDimensionsIcon;
