import { FC } from "react";
import { observer } from "mobx-react";
import { MultipleSelect } from "components/multiselect/MultiSelect";
import { getUnitSegmentName } from "utils/dashboard/useGetSegmentName";
import { Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import store from "../store";
import topStore from "../../store";
import styled from "styled-components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type SettingsListProps = {
  localSelectedSegments: { attribute: string; mode: string; value: string | string[] }[];
  handleModeSelect: (
    e: SelectChangeEvent<string>,
    row: { attribute: string; mode: string; value: string | string[] },
    rowIndex: number
  ) => void;
  handleAttributeSelect: (
    e: SelectChangeEvent<string>,
    row: { attribute: string; mode: string; value: string | string[] },
    rowIndex: number
  ) => void;
  changeSegments: (chengedindex: number, field: string, value: any) => void;
  clearLastAttribute: (chengedindex: number) => void;
};

const SettingsList: FC<SettingsListProps> = observer((props) => {
  const getSegmentsList = (filter: any) => {
    if (filter.attribute === "Unit") {
      return (
        store.attributeList
          .find((el) => String(el.label) === "Unit")
          ?.list.map((item) => ({
            value: item.id.toString(),
            label: `${item?.name} (Level ${item.level})`,
          })) || []
      );
    }

    return (
      store.attributeList
        .find((el) => String(el.label) === String(filter.attribute))
        ?.list.map((item) => item.value) || []
    );
  };

  const renderValue = (row: { attribute: string; mode: string; value: string | string[] }) => {
    if (row.attribute === "Unit") {
      return store.attributeList
        .find((el) => String(el.label) === "Unit")
        ?.list.map((item, index) => (
          <MenuItem key={item.value + index} value={item.id.toString()}>
            {`${item?.name} (Level ${item.level})`}
          </MenuItem>
        ));
    }

    return store.attributeList
      .find((el) => String(el.label) === String(row.attribute))
      ?.list.map((item, index) => (
        <MenuItem key={item.value + index} value={item.value}>
          {item.value}
        </MenuItem>
      ));
  };

  return (
    <Container>
      {props.localSelectedSegments.map((row, rowIndex, arraySegments) => (
        <FilterContainer
          $isLast={Boolean(rowIndex + 1 === props.localSelectedSegments.length)}
          key={rowIndex}
        >
          <FilterRow>
            <LabelsRow>
              {arraySegments.map((rowValue, index) => {
                if (index <= rowIndex)
                  if (typeof rowValue.value === "object" && row.value) {
                    return (
                      <LabelGroup>
                        {rowValue.value.map((el, indexLabel) => {
                          if (indexLabel < 10)
                            return (
                              <Label>
                                {rowValue.attribute === "Unit"
                                  ? getUnitSegmentName(el, topStore.filterAttributesList)
                                  : el}
                              </Label>
                            );
                        })}
                        {rowValue.value.length > 10 && (
                          <CounterLabels>+ {rowValue?.value.length - 10} more</CounterLabels>
                        )}
                        {rowIndex === arraySegments.length - 1 && row.value && (
                          <ClearButon
                            variant="text"
                            onClick={() => props.clearLastAttribute(rowIndex)}
                          >
                            Clear all
                          </ClearButon>
                        )}
                      </LabelGroup>
                    );
                  } else if (typeof rowValue.value === "string" && row.value)
                    return (
                      <LabelGroup>
                        <Label>
                          {rowValue.attribute === "Unit"
                            ? getUnitSegmentName(rowValue.value, topStore.filterAttributesList)
                            : rowValue.value}
                        </Label>
                        {index !== arraySegments.length - 1 && <ArrowForwardIosIcon />}
                        {rowIndex === arraySegments.length - 1 && !rowValue.value && (
                          <SelectLabel>Select segment</SelectLabel>
                        )}
                      </LabelGroup>
                    );
                  else return null;
              })}
            </LabelsRow>
          </FilterRow>
          <FilterRow>
            <StyledSelectAttribute
              id="unit_or_attribute"
              value={row.attribute}
              onChange={(e: SelectChangeEvent<string>) => {
                props.handleAttributeSelect(e, row, rowIndex);
              }}
              displayEmpty
              IconComponent={KeyboardArrowDownIcon}
              MenuProps={{ PaperProps: { sx: { maxHeight: 220 } } }}
              renderValue={
                row.attribute
                  ? () => row.attribute
                  : () => <Placeholder>Select unit or attribute</Placeholder>
              }
            >
              {store.attributeList
                .filter(
                  (el) => !props.localSelectedSegments.find((item) => item.attribute === el.label)
                )
                .map((attribute, index) => (
                  <MenuItem key={attribute.value + index} value={attribute.label}>
                    {attribute.label}
                  </MenuItem>
                ))}
            </StyledSelectAttribute>
            <StyledSelectModeValue
              id="mode"
              value={row.mode}
              onChange={(e: SelectChangeEvent<string>) => props.handleModeSelect(e, row, rowIndex)}
              displayEmpty
              IconComponent={KeyboardArrowDownIcon}
              inputProps={{ "aria-label": "Without label" }}
              renderValue={
                row.mode !== ""
                  ? undefined
                  : () => <Placeholder>Select mode of analysis</Placeholder>
              }
            >
              <MenuItem value={"multiple"}>Analyze multiple segments</MenuItem>
              <MenuItem value={"one"}>Select 1 segment to analyze deeper</MenuItem>
            </StyledSelectModeValue>
          </FilterRow>
          {row.mode && row.attribute && (
            <FilterSecondaryRow>
              {row.mode === "one" ? (
                <StyledSelectModeValue
                  id="value"
                  value={row.value || ""}
                  onChange={(e: SelectChangeEvent<string>) => {
                    props.changeSegments(rowIndex, "value", e.target.value);
                  }}
                  displayEmpty
                  IconComponent={KeyboardArrowDownIcon}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 220 } } }}
                  renderValue={
                    row.value !== "" ? undefined : () => <Placeholder>Select segments</Placeholder>
                  }
                >
                  {renderValue(row)}
                </StyledSelectModeValue>
              ) : (
                <MultipleSelectWrapper>
                  <MultipleSelect
                    options={getSegmentsList(row)}
                    selectedList={row.value && typeof row.value === "object" ? row.value : []}
                    onChange={props.changeSegments}
                    name="value"
                    index={rowIndex}
                  />
                </MultipleSelectWrapper>
              )}
            </FilterSecondaryRow>
          )}
        </FilterContainer>
      ))}
    </Container>
  );
});

export default SettingsList;

const Container = styled.div`
  width: 100%;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  background-color: var(--colorPaletteGrayBackground1);
  margin-top: 24px;
  border-radius: 10px;
`;

const FilterContainer = styled.div<{ $isLast?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  border-bottom: ${(props) =>
    props.$isLast ? "none" : "1px solid var(--colorPaletteBlueBackground1)"};
`;

const FilterRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const FilterSecondaryRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
`;

const StyledSelectAttribute = styled(Select)`
  height: 39px;
  width: 170px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-right: 8px;

  div {
    padding: 8px !important;
  }

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }

  svg {
    font-size: 16px;
  }
`;

const StyledSelectModeValue = styled(Select)`
  height: 39px;
  width: calc(100% - 178px) !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;

  div {
    padding: 8px !important;
  }

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }

  svg {
    font-size: 16px;
  }
`;

const LabelsRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

const Label = styled.span`
  padding: 2px 8px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: var(--colorNeutralForeground1);
  background-color: var(--colorPaletteGreenBackground4);
  margin: 0px 4px 4px 0px;
`;

const MultipleSelectWrapper = styled.div`
  width: calc(100% - 178px);

  svg {
    font-size: 16px;
  }
`;

const Placeholder = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground9);
`;

const ClearButon = styled(Button)`
  text-transform: none !important;
  padding: 0px !important;
`;

const LabelGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    font-size: 12px;
    margin-bottom: 4px;
    color: var(--colorNeutralForeground2);
  }
`;

const SelectLabel = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  color: var(--colorNeutralForeground2);
`;

const CounterLabels = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  color: var(--colorNeutralForeground1);
  margin-bottom: 4px;
`;
