import http from "api/https";


export const duplicateSurvey = async (id: number): Promise<any> => {
    const data = {
        id: id,
    }
    if(http.isAPIGO){
        return http.postGO('/sm/sm_survey/DuplicateSurvey', data)
    } else {
        return http.post('/sm_survey/DuplicateSurvey', data)
    }  

};