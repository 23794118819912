import { FC } from "react";

import Avatar from "@mui/material/Avatar";
import styled from "styled-components";

type StyledAvatarProps = {
  width: number;
  height: number;
  alt: string;
  src: string;
};

const StyledAvatar: FC<StyledAvatarProps> = (props) => (
  <CustomAvatar
    alt={props.alt}
    src={props.src}
    sx={{ width: props.width, height: props.height }}
  />
);

export default StyledAvatar;

const CustomAvatar = styled(Avatar)`
  background-color: var(--colorPaletteVioletBackground3) !important;
`;
