const AdminsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 12.375C8.44686 12.375 7.1875 11.1156 7.1875 9.5625C7.1875 8.00936 8.44686 6.75 10 6.75C11.5531 6.75 12.8125 8.00936 12.8125 9.5625C12.8125 11.1156 11.5531 12.375 10 12.375ZM1 11.25C1 13.4473 1.79007 15.4588 3.09829 17.0222C4.43736 14.5877 7.0255 12.9375 10 12.9375C12.9745 12.9375 15.5626 14.5877 16.9017 17.0222C18.2099 15.4588 19 13.4473 19 11.25C19 6.27943 14.9706 2.25 10 2.25C5.02943 2.25 1 6.27943 1 11.25Z" fill="#707882" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.0001 12.9375C7.0256 12.9375 4.43746 14.5877 3.09839 17.0222C4.74925 18.9939 7.22746 20.25 10.0001 20.25C12.7727 20.25 15.251 18.9939 16.9018 17.0222C15.5627 14.5877 12.9746 12.9375 10.0001 12.9375Z" fill="#F5F6FA" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10 6.75C8.44686 6.75 7.1875 8.00936 7.1875 9.5625C7.1875 11.1156 8.44686 12.375 10 12.375C11.5531 12.375 12.8125 11.1156 12.8125 9.5625C12.8125 8.00936 11.5531 6.75 10 6.75Z" fill="#F5F6FA" />
      <path d="M18 9.75L12.75 12.7392V18.7392L18 21.75L23.25 18.7392V12.7392L18 9.75Z" fill="#FCC658" />
      <path d="M18 9.75V21.75L23.25 18.7392V12.7392L18 9.75Z" fill="#FFE19B" />
      <path d="M19.6188 18.375L18 17.462L16.3813 18.3522L16.6875 16.4576L15.375 15.1109L17.1906 14.837L18 13.125L18.8094 14.8598L20.625 15.1337L19.3125 16.4804L19.6188 18.375Z" fill="#FF6F00" />
    </svg>
  );
};

export default AdminsIcon;
