import http from "api/https";


export const DuplicateProject = async (id:number): Promise<any> => {
  let data = {
    id: id,
  }
  if(http.isAPIGO){
    return http.postGO('/sm/sm_project/DuplicateProject', data)
  }else{
  return http.post('/sm_project/DuplicateProject', data)
  }
};