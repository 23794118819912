import { FC } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CustomButton from "components/button/Button";
import store from "features/Survey/SurveyPreview/store";
import { observer } from "mobx-react";

type BackToMainLayoutHeaderProps = {};

const BackToMainLayoutHeader: FC<BackToMainLayoutHeaderProps> = observer(() => {
  const navigate = useNavigate();
  return (
    <BackToMainLayoutHeaderWrapper id="PreviewSurveyHeader">
      <BackContainer>
        <BackButton
          id="PreviewSurveyBackButton"
          onClick={() =>
            navigate(
              store.Survey === null
                ? "/templates"
                : "/projects?project_id=" + store.Survey.project_id
            )
          }
        >
          <StyledContainerKeyboardBackspaceIcon>
            <KeyboardBackspaceIcon />
          </StyledContainerKeyboardBackspaceIcon>
          <BackToModule id="PreviewSurveyBackButtonLabel">
            {store.Survey === null ? "Back to templates" : "Back to surveys"}
          </BackToModule>
        </BackButton>
      </BackContainer>
      <CreateTemplateText id={"PreviewSurveyHeaderLabel"}>Preview</CreateTemplateText>
      <StyledPreviewButton
        id={"PreviewSurveyShareLinkButton"}
        variant="outlined"
        onClick={() => {
          store.copyLinkToClipboard();
        }}
      >
        Share preview link
      </StyledPreviewButton>
    </BackToMainLayoutHeaderWrapper>
  );
});

export default BackToMainLayoutHeader;

const BackToMainLayoutHeaderWrapper = styled.div`
  width: 100%;
  height: 64px;
  padding: 20px 90px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--colorPaletteVioletBackground1);
`;

const BackToModule = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralBackground1);
`;
const BackContainer = styled.div`
  display: flex;
  align-items: center;
`;
const CreateTemplateText = styled.div`
  color: white;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
`;

const BackButton = styled.button`
  background: var(--ER-Theme-sys-light-on-primary-container, #1e004d);
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;

const StyledContainerKeyboardBackspaceIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--colorNeutralForeground5);

  svg {
    fill: var(--colorNeutralBackground1);
    width: 16px;
  }
`;

const StyledPreviewButton = styled(CustomButton)`
  background-color: var(--colorPalleteLightBlue) !important;
`;