import http from "api/https";
import { createUrl } from "../../helpers/url";

export const useGetFeatures = (entityId: number) => {
  const query: { entity_id: string } = {
    entity_id: entityId.toString(),
  };
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/subscription/GetFeaturesForEntity",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/feature/GetFeaturesForEntity",
      query: query,
    });
    return http.get(url);
  }
};
