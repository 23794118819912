import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Box, Badge } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import store from './store'
import { observer } from 'mobx-react';
import CustomButton from "components/button/Button";
import SettingsIcon from '@mui/icons-material/Settings';
import WorkIcon from '@mui/icons-material/Work';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StarIcon from '@mui/icons-material/Star';
import ErrorIcon from '@mui/icons-material/Error';
import styled from 'styled-components';


const DataTable = observer((props) => {

  const columns: GridColDef[] = [
    { field: 'email', headerName: 'Email', width: 400,
    renderHeader: (param) => {
      return  <span id={`WorkspacePermissionsUsers_ColumnEmail`}>{param.colDef.headerName}</span>
    },
    renderCell: (param)  => {
      return <Box>
        <Box>
          <span id={`WorkspacePermissionsUsers_ColumnEmail_${param.id}`}>{param.value}</span>
        </Box>
      </Box>
    },
  },
    {
      field: 'roleName', headerName: 'Role', width: 400,
      renderHeader: (param) => {
        return <span id={`WorkspacePermissionsUsers_ColumnRole`}>{param.colDef.headerName}</span>
      },
      renderCell: (param) => {
        return <Box >
          <Box>
            <Badge
              style={{ marginRight: 5, marginBottom: 5 }}
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                param.row.isDefaultRole ? <StarIcon style={{ fontSize: 15, backgroundColor: 'white', color: '#6E31FF', borderRadius: 10, }} />
                  : <SettingsIcon style={{ fontSize: 15, backgroundColor: 'white', color: '#6E31FF', borderRadius: 10, }} />
              }
            >
              {param.row.isDefaultRole ? <AccountCircleIcon style={{ fontSize: 24, color: '#707882' }} />
                : <WorkIcon style={{ fontSize: 24, color: '#7562FC' }} />}

            </Badge>
            <span id={`WorkspacePermissionsUsers_tableRole_${param.id}`} style={{ fontWeight: 500 }}>{param.row.roleName}</span>
            <CustomButton
            id={`WorkspacePermissionsUsers_RemoveFromRoleBtn_${param.id}`}
            size="small" onClick={() => {
              store.changeRemoveFromRolePanel(true)
              store.currentRoleId = param.row.roleId
              store.currentRoleName = param.row.roleName
              store.selectedData = [param.row.idUser]
              store.currentUser = param.row
            }}>
              Remove from role
            </CustomButton>
          </Box>
          <Box id={`WorkspacePermissionsUsers_tableRole_Text_${param.id}`} style={{ fontSize: 12 }}>
            {param.row.inEntityOrWorkspace}
          </Box>
        </Box>
      }
    },
    {
      field: 'status', headerName: 'Status', width: 400,
      renderHeader: (param) => {
        return <span id={`WorkspacePermissionsUsers_ColumnStatus`}>{param.colDef.headerName}</span>
      },
      renderCell: (param) => {
        return <Box>
          <Box  id={`WorkspacePermissionsUsers_tableStatusDate_${param.id}`}>
            Invited on {param.row.strlastInvitedTime}
          </Box>
          {param.row.inviteExpiredDaysAgo != null ? <Box style={{ fontSize: 12, display: 'flex', alignItems: 'center' }}>
            <ErrorIcon id={`WorkspacePermissionsUsers_tableStatusError`} style={{fontSize: 16, color: '#FA6B6B', marginRight: 5}}/>
            Invite has expired {param.row.inviteExpiredDaysAgo} days ago
          </Box> : ""}
        </Box>
      }
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <HeaderWrapperBox>
        <Box id={"WorkspaceUsers-GridInfo"}>Users who are invited but have not created an account will appear here</Box>
        <Box >
          <CustomButton
          id={`WorkspaceUsers_InviteAgainBtn`}
            style={{ margin: 5 }}
            disabled={store.selectedData.length == 0}
            variant='contained'
            color="inherit"
            size="small"
            onClick={() => {
              store.changeInviteAgainPanel(true)
            }}
          >
            <EmailIcon fontSize="small" /> Invite again
          </CustomButton>
        </Box>
      </HeaderWrapperBox>
      <DataGrid
        sx={{ minHeight: 300 }}
        rowHeight={70}
        rows={store.invitedUsers}
        onRowSelectionModelChange={(rows, details) => { store.changeValue('selectedData', rows) }}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </div>
  );
})

export default DataTable

const HeaderWrapperBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  background-color: var(--grenBackgroundColor2);
  padding: 0 16px;
`;