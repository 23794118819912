import { createElement } from "react";
import { ElementFactory, Serializer } from "survey-core";
import { PropertyGridEditorCollection } from "survey-creator-core";
import { ReactQuestionFactory } from "survey-react-ui";
import { EMPLOYEE_SEGMENT_LOGIC_FIELD_QUESTION_TYPE, EMPLOYEE_SEGMENT_LOGIC_FIELD_TYPE } from "../surveyJsConstants";
import EmployeeSegmentLogicFieldModel from "./EmployeeSegmentLogicFieldModel";
import EmployeeSegmentLogicField from "./EmployeeSegmentLogicField";

ElementFactory.Instance.registerElement(
  EMPLOYEE_SEGMENT_LOGIC_FIELD_QUESTION_TYPE,
  (name: string) => {
    return new EmployeeSegmentLogicFieldModel(name);
  },
);

ReactQuestionFactory.Instance.registerQuestion(
  EMPLOYEE_SEGMENT_LOGIC_FIELD_QUESTION_TYPE,
  (props: unknown) => {
    return createElement(EmployeeSegmentLogicField, props);
  },
);

Serializer.addClass(
  EMPLOYEE_SEGMENT_LOGIC_FIELD_QUESTION_TYPE,
  [],
  () => new EmployeeSegmentLogicFieldModel(""),
  "question"
);

Serializer.addProperty('question', { name: 'dimension_id', visible: false });

PropertyGridEditorCollection.register({
  fit: function (prop: any) {
    return prop.type === EMPLOYEE_SEGMENT_LOGIC_FIELD_TYPE;
  },
  getJSON: function () {
    return {
      type: EMPLOYEE_SEGMENT_LOGIC_FIELD_QUESTION_TYPE
    };
  }
});
