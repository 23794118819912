import { Grid } from "@mui/material";
import { FC, useEffect } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { useGridApiRef } from "@mui/x-data-grid";
import DataTable from "./table";
import SegmentsSide from "./SegmentsSide";
import { EditIcon } from "./components/EditIcon";
import DeleteAttributePopup from "./DeletePopup";
import CustomModal from "components/modal/Modal";
import CustomButton from "components/button/Button";
import { DeleteIcon } from "./components/DeleteIcon";
import HrisAccordionAttribute from "./HrisAccordionAttribute";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import storeSegment from "../AttributeList/storeSegments";
import storeAddEditAttribute from "features/Employees/AttributeList/AddEditAttribute/store";
import store from "./store";
import NavStore from "./../store";

type AttributeListProps = {
  changeTab: (tab: string) => void;
  idAttribute?: number;
  handleRowClick?: (params: number) => any;
};

const AttributeList: FC<AttributeListProps> = observer((props) => {
  const apiRef = useGridApiRef();

  useEffect(() => {
    store.loadData();
  }, []);

  const disabledEditDelete =
    store.selected === null ||
    store.selected?.is_unit_based ||
    store.selected?.is_manager_based ||
    store.selected?.is_default ||
    storeSegment.openEditSegment !== null;

  return (
    <Grid container spacing={0}>

      <CustomModal
        open={store.deleteFormPopup}
        hide={(flag) => store.changeDeletePopup(false)}
        children={<DeleteAttributePopup closePopup={() => store.changeDeletePopup(false)} />}
      />

      <Grid item xs={4}>
        <AttributeWrapper>
          <Header id="Attribute_AttributeList_Title" >Attributes</Header>
          <HrisAccordionAttribute />
          {NavStore.importLock && <TemplateWrapper data-testid='AttributeList_ImportEmployee_Information'>
              Employee List is being updated at the moment. Certain functionalities will not be available until the update is complete.
          </TemplateWrapper>}
          <ButtonsWrapper>
            {!StoreLayout.lockEdit && (
              <>
                <CustomButton
                id="Attribute_AttributeList_AddAttribute_Btn"
                  variant="contained"
                  disabled={storeSegment.openEditSegment !== null || (NavStore.importLock ?? false)}
                  onClick={() => {
                    store.changeSelected([]);
                    store.changeFooterAdd(true)
                    storeAddEditAttribute.clearStore()
                    storeSegment.clearStore()
                  }}
                >
                  + Add attribute
                </CustomButton>

                <BtnWrapp>
                  <CustomButton
                    disabled={disabledEditDelete || (NavStore.importLock ?? false)}
                    onClick={() => {
                      storeAddEditAttribute.changeValue("id", store.selected?.id);
                      store.setOpenPanel(true)
                    }}
                    id="Attribute_AttributeList_Edit_Btn"
                  >
                    <EditIcon color={disabledEditDelete ? "#00000042" : "#1477F8"} />
                    Edit
                  </CustomButton>
                </BtnWrapp>

                <Splitter />

                <BtnWrapp>
                  <CustomButton
                    id="Attribute_AttributeList_Delete_Btn"
                    disabled={disabledEditDelete || (NavStore.importLock ?? false)}
                    onClick={() => store.changeDeletePopup(true)}
                  >
                    <DeleteIcon color={disabledEditDelete ? "#00000042" : "#1477F8"} /> Delete
                  </CustomButton>
                </BtnWrapp>
              </>
            )}
          </ButtonsWrapper>

          <DataTable apiRef={apiRef} />
        </AttributeWrapper>
      </Grid>

      <Grid item xs={8}>
        <SegmentsSide />
      </Grid>
    </Grid>
  );
});

export default AttributeList;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  margin-top: 16px;
`;

const BtnWrapp = styled.div`
  margin: 0 4px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const Splitter = styled.div`
  width: 1px;
  height: 33px;
  background: var(--colorPaletteBlueBackground1);
`;

const AttributeWrapper = styled.div`
  padding-top: 80px;
  margin-left: 40px;
`;

const Header = styled.p`
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  display: inline;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const TemplateWrapper = styled.div`
  width: 100%;
  padding: 20px 30px;
  background-color: var(--colorNeutralBackground1);
  border-radius: 4px;
  border: 1px solid var(--colorNeutralForeground4);
  display: flex;
  flex-direction: column;
`;
