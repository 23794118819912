import http from "api/https";

export const getSurveyProjects = (
  entity_id: number,
  search_value: string,
  order_by: string,
  order_type: string,
  tags_ids: number[],
  statuses: string[]
): Promise<any> => {
  const data = {
    entity_id: entity_id,
    search_value: search_value,
    order_by: order_by,
    order_type: order_type,
    tags_ids: tags_ids,
    statuses: statuses,
  };
  if (http.isAPIGO) {
    return http.postGO("/sm/sm_project/GetFilterProjects", data);
  } else {
    return http.post("/sm_project/GetFilterProjects", data);
  }
};

export const GetSurveyProjectsDashboard = (entity_id: number): Promise<any> => {
  return http.get("/sm_project/GetFilterProjectsDashboard?entity_id=" + entity_id);
};
