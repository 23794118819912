import { observer } from "mobx-react";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { FC, useEffect, useState } from "react";
import { GridColDef, GridRowParams } from "@mui/x-data-grid/models";
import { MenuItem, OutlinedInput, Select } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type DimensionsListProps = {};

const DimensionsList: FC<DimensionsListProps> = observer(() => {
  const [sortModel, setSortModel] = useState([]);

  const allRowIds = store.prioritizeList.map((row) => row.id);

  useEffect(() => {
    store.setData(allRowIds, "selectedPrioritize");
  }, []);

  const handleSelectionChange = (newSelection: number[]) => {
    store.setData(newSelection, "selectedPrioritize");
  };

  useEffect(() => {
    if (store.dimensionsSortBy === "favorable_score" && store.dimensionsSortValue === "value_asc") {
      setSortModel([{ field: "favorable_score", sort: "asc" }]);
    } else if (
      store.dimensionsSortBy === "favorable_score" &&
      store.dimensionsSortValue === "value_desc"
    ) {
      setSortModel([{ field: "favorable_score", sort: "desc" }]);
    } else if (
      store.dimensionsSortBy === "impact_score" &&
      store.dimensionsSortValue === "value_asc"
    ) {
      setSortModel([{ field: "impact_score", sort: "asc" }]);
    } else if (
      store.dimensionsSortBy === "impact_score" &&
      store.dimensionsSortValue === "value_desc"
    ) {
      setSortModel([{ field: "impact_score", sort: "desc" }]);
    } else if (
      store.dimensionsSortBy === "dimensions_name" &&
      store.dimensionsSortValue === "name_asc"
    ) {
      setSortModel([{ field: "name", sort: "asc" }]);
    } else if (
      store.dimensionsSortBy === "dimensions_name" &&
      store.dimensionsSortValue === "name_desc"
    ) {
      setSortModel([{ field: "name", sort: "desc" }]);
    } else return;
  }, [store.dimensionsSortBy, store.dimensionsSortValue, store.prioritizeList]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Dimension",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (param) => {
        return (
          <TableCellName
            $isDisabled={Boolean(
              param.row.favorable_score == null || param.row.impact_score === null
            )}
          >
            <ColumnText>{param.row.name}</ColumnText>
          </TableCellName>
        );
      },
    },
    {
      field: "favorable_score",
      headerName: "Favorable score",
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      renderCell: (param) => {
        return (
          <TableCell>
            <p>{param.row.favorable_score === null ? "-" : `${param.row.favorable_score}%`}</p>
          </TableCell>
        );
      },
    },
    {
      field: "impact_score",
      headerName: "Impact score",
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      renderCell: (param) => {
        return (
          <TableCell>
            <p>{param.row.impact_score === null ? "-" : param.row.impact_score}</p>
          </TableCell>
        );
      },
    },
  ];

  return (
    <Container>
      <TopLabel>Select at least 3 dimensions for the chart to show</TopLabel>
      <FiltersConainer>
        <Text>Sort by:</Text>
        <StyledSelect
          id="select-sort-field"
          value={store.dimensionsSortBy}
          onChange={(e) => {
            store.setData(e.target.value, "dimensionsSortBy");
            if (e.target.value === "dimensions_name") {
              store.setData("name_asc", "dimensionsSortValue");
            } else {
              store.setData("value_asc", "dimensionsSortValue");
            }
          }}
          input={<OutlinedInput id="selecter-sort-field" />}
          IconComponent={KeyboardArrowDownIcon}
        >
          <MenuItem value="favorable_score">Favorable score</MenuItem>
          <MenuItem value="dimensions_name">Dimensions name</MenuItem>
          <MenuItem value="impact_score">Impact score</MenuItem>
        </StyledSelect>
        <StyledSelect
          id="select-sort-by"
          value={store.dimensionsSortValue}
          onChange={(e) => {
            store.setData(e.target.value, "dimensionsSortValue");
          }}
          input={<OutlinedInput id="selecter-sort-by" />}
          IconComponent={KeyboardArrowDownIcon}
        >
          {store.dimensionsSortBy !== "dimensions_name" && (
            <MenuItem value="value_desc">High to low</MenuItem>
          )}
          {store.dimensionsSortBy !== "dimensions_name" && (
            <MenuItem value="value_asc">Low to high</MenuItem>
          )}
          {store.dimensionsSortBy === "dimensions_name" && (
            <MenuItem value="name_asc">A-Z</MenuItem>
          )}
          {store.dimensionsSortBy === "dimensions_name" && (
            <MenuItem value="name_desc">Z-A</MenuItem>
          )}
        </StyledSelect>
      </FiltersConainer>
      <TableContainer>
        <StyledDataGrid
          rows={store.prioritizeList}
          columns={columns}
          checkboxSelection
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={store.selectedPrioritize}
          isRowSelectable={(params: GridRowParams) =>
            (params.row.favorable_score || params.row.favorable_score === 0) &&
            (params.row.impact_score || params.row.impact_score === 0)
          }
          disableColumnFilter
          localeText={{
            noRowsLabel: "No dimensions",
          }}
          hideFooter
          sortingMode="client"
          sortModel={sortModel}
        />
      </TableContainer>
    </Container>
  );
});

export default DimensionsList;

const Container = styled.div`
  width: 37%;
  min-width: 430px;
  background-color: var(--colorNeutralBackground1);
`;

const TopLabel = styled.span`
  width: 100%;
  background-color: var(--colorPalleteLightBlue);
  padding: 12px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  margin: 24px 0px 0px 0px;
`;

const FiltersConainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 32px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  margin: 0px 0px 0px 8px;
  height: 32px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 400 !important;

  em {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  white-space: nowrap;
`;

const StyledDataGrid = styled(DataGrid)`
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 0px !important;
  min-height: 300px !important;
  max-height: 690px;
  border: none !important;

  .MuiDataGrid-columnHeader {
    max-width: none !important;
    min-width: 120px !important;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--colorNeutralForeground1);
    text-overflow: none !important;
  }

  .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-cell:focus,
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-cell {
    cursor: pointer !important;
    border-bottom: none !important;
  }

  .MuiCheckbox-root {
    color: var(--colorBrandForeground1) !important;
  }

  .Mui-disabled {
    color: var(--colorGrayForeground6) !important;
  }

  .MuiDataGrid-root {
    border: none !important;
  }

  .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .MuiDataGrid-overlay {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--colorNeutralForeground5) !important;
  }

  .MuiDataGrid-row.Mui-selected {
    background-color: transparent !important;
  }

  .MuiDataGrid-cellCheckbox {
    min-width: 0px !important;
    width: auto !important;
  }
  .MuiDataGrid-columnHeaderCheckbox {
    min-width: 0px !important;
    width: auto !important;
  }

  .MuiCheckbox-root {
    padding: 0px !important;
  }

  .MuiDataGrid-virtualScroller {
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--colorGrayForeground6);
      border-radius: 20px;
      border: 3px solid var(--colorGrayForeground6);
    }
  }
`;

const TableContainer = styled.div`
  width: 100%;
  padding: 0px 32px;
`;

const TableCell = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--colorNeutralForeground1);
  }
`;

const TableCellName = styled.div<{ $isDisabled?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${(props) =>
      props.$isDisabled ? "var(--colorNeutralForeground5)" : "var(--colorNeutralForeground1)"};
  }
`;

const ColumnText = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
`;
