import { FC, useState, useRef, ChangeEvent } from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react";
import styled from "styled-components";

import { FolderImageIcon } from "features/Templates/CommunicationTemplates/SecondAccordion/FirstTab/FolderImageIcon";
import RichTextWithTabsAll from "../../RichTextWithTabsAll";
import empty from "assets/images/er-logo-square.png";
import CustomButton from "components/button/Button";
import store from "./../../store";

type FirstTabProps = {};

const FirstTab: FC<FirstTabProps> = observer((props) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [errorFileText, seterrorFileText] = useState<string>("");
  const [errorWidthText, seterrorWidthText] = useState<string>("");
  const [errorHeightText, seterrorHeightText] = useState<string>("");

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      store.changeFile(file);

      const blob = new Blob([file!]);
      const imageUrl = URL.createObjectURL(blob);
      setImageSrc(imageUrl);
      setSelectedFile(file!);

      if (file.size > 5000000) {
        seterrorFileText("Image not uploaded as it is larger than 5MB.");
      } else {
        seterrorFileText("");
      }
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const image = new Image();
          image.src = e.target?.result as string;

          image.onload = () => {
            const dimensions = {
              width: image.width,
              height: image.height,
            };

            if (dimensions.width > 650) {
              seterrorWidthText("The image width should not exceed 650px!");
            } else {
              seterrorWidthText("");
            }
            if (dimensions.height > 150) {
              seterrorHeightText("The image height should not exceed 150px!");
            } else {
              seterrorHeightText("");
            }
          };
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleCancelButtonClick = () => {
    setSelectedFile(null);
    setImageSrc(null);
    store.changeFile(null);
    seterrorFileText("");
    seterrorHeightText("");
    seterrorWidthText("");
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const saveVerification =
    !(errorFileText === "" && errorHeightText === "" && errorWidthText === "") ||
    !store.changedFirstTab;

  const imageSrcUrl =
    imageSrc || (store.AllInfo?.header_logo_url ? store.AllInfo.header_logo_url : empty);

  const imageVerification =
    selectedFile || (store.AllInfo && store.AllInfo.header_logo_url !== null);

  return (
    <>
      <Wrapped>
        <Header id="Survey_EditCreate_Communication_ScheduleCommunications_EmailSettings_Title">
          Email settings
        </Header>

        <SubHeader id="Survey_EditCreate_Communication_ScheduleCommunications_EmailSettings_HeaderImage">
          HEADER IMAGE
        </SubHeader>

        <ImageText id="Survey_EditCreate_Communication_ScheduleCommunications_EmailSettings_ImageText">
          This will appear at the top of emails sent out to respondents. (Optional)
        </ImageText>

        <ImageWrapper>
          {imageVerification ? (
            <LogoWrapper>
              <StyledImage
                id="Survey_EditCreate_Communication_ScheduleCommunications_EmailSettings_Img"
                src={imageSrcUrl}
                alt="Selected image"
              />
            </LogoWrapper>
          ) : (
            <LogoWrapper>
              <StyledImage
                id="Survey_EditCreate_Communication_ScheduleCommunications_EmailSettings_Img"
                src={"assets/eg-image.png"}
                alt="Selected image"
              />
            </LogoWrapper>
          )}

          <StyledInput
            id="Survey_EditCreate_Communication_ScheduleCommunications_EmailSettings_Img_FileInput"
            type="file"
            ref={fileInputRef}
            accept=".jpg, .png"
            onChange={handleFileChange}
          />
        </ImageWrapper>

        <BottomImage>
          <ReplaceImage>
            <StyledImageChangeButton
              id="Survey_EditCreate_Communication_ScheduleCommunications_EmailSettings_Img_FileInput_Button"
              onClick={handleButtonClick}
            >
              <FolderImageIcon />
              Replace image
            </StyledImageChangeButton>
          </ReplaceImage>

          <MaxSizeImage id="Survey_EditCreate_Communication_ScheduleCommunications_EmailSettings_Img_FileInput_Description">
            Max file size: 650px(width) x 150px(height), 5MB
          </MaxSizeImage>
        </BottomImage>
        <TypographyBox>
          <TypographyStyled id="Survey_EditCreate_Communication_ScheduleCommunications_EmailSettings_Img_errorFileText">
            {errorFileText}
          </TypographyStyled>
          <TypographyStyled id="Survey_EditCreate_Communication_ScheduleCommunications_EmailSettings_Img_errorWidthText">
            {errorWidthText}
          </TypographyStyled>
          <TypographyStyled id="Survey_EditCreate_Communication_ScheduleCommunications_EmailSettings_Img_errorHeightText">
            {errorHeightText}
          </TypographyStyled>
        </TypographyBox>

        <SubHeader id="Survey_EditCreate_Communication_ScheduleCommunications_EmailSettings_Footer_Title">
          FOOTER
        </SubHeader>

        <RichTextWithTabsAll
          data={store.FooterData}
          width={100}
          changeValue={(value, _, index) => store.changeFooterData(value, index)}
          field="footer_email"
          langNameId="Survey_EditCreate_Communication_ScheduleCommunications_EmailSettings_Footer_RichTextWith_Language"
          editorId="Survey_EditCreate_Communication_ScheduleCommunications_EmailSettings_Footer_RichTextWith_Editor"
        />

        <ButtonWrapper>
          <StyledNextButton
            id="Survey_EditCreate_Communication_ScheduleCommunications_EmailSettings_Save_Button"
            variant="contained"
            disabled={saveVerification}
            onClick={() => store.saveFooterData()}
          >
            Save changes
          </StyledNextButton>
          <StyledCancelButton
            id="Survey_EditCreate_Communication_ScheduleCommunications_EmailSettings_Cancel_Button"
            variant="outlined"
            disabled={!store.changedFirstTab}
            onClick={() => {
              handleCancelButtonClick();
              store.onCancelFirstTab();
            }}
          >
            Cancel
          </StyledCancelButton>
        </ButtonWrapper>
      </Wrapped>
    </>
  );
});

export default FirstTab;

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 400px;
  border: 1px dashed var(--colorPaletteBlueBackground1);
`;

const StyledInput = styled.input`
  display: none;
`;

const Wrapped = styled.div`
  margin: 10px 30px;
  max-width: 900px;
  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 30px;
  margin-top: 10px;
  color: var(--colorNeutralForeground1);
`;

const StyledImageChangeButton = styled(CustomButton)`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 !important;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 40px 0;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledCancelButton = styled(CustomButton)`
  width: 100%;
  max-width: 75px;
  margin-left: 160px;
`;

const StyledNextButton = styled(CustomButton)`
  width: 100%;
  margin-right: 20px;
  max-width: 150px;
`;

const SubHeader = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
  color: var(--colorNeutralForeground2);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
`;

const LogoWrapper = styled.div``;

const ImageText = styled.div`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: var(--colorNeutralForeground1);
`;

const ImageWrapper = styled.div``;

const BottomImage = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: var(--colorNeutralForeground1);

  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ReplaceImage = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: var(--colorNeutralForeground1);
`;

const MaxSizeImage = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: var(--colorNeutralForeground5);
  margin-right: 185px;
`;

const TypographyStyled = styled.span`
  font-size: 12px;
  color: var(--colorPaletteRedForeground2);
`;

const TypographyBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 6px;
`;
