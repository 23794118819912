import http from "api/https";

export const checkUniqueSurveyName = (name: string, id: number, projectId: number): Promise<any> => {
  let data = {
    name: name,
    id: id,
    project_id: projectId,
  };

  if(http.isAPIGO){
    return http.postGO("/sm/sm_survey/CheckUniqueSurveyName", data);
  }else{
    return http.post("/sm_survey/CheckUniqueSurveyName", data);

  }
};
