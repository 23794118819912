import { FC, useCallback, useState, useEffect } from "react";
import { EMPLOYEES_TABS } from "../../constants/menu";

import styled from "styled-components";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";

import LeftNavigationBar from "./LeftNavigationBar";
import EmployeeList from "./../Employees/Employee/ListEmployee";
import AttributeList from "./AttributeList/AttributeList";
import { useLocation, useNavigate } from "react-router";
import { observer } from "mobx-react";
import CustomModal from "components/modal/Modal";
import EmployeeStructuereModal from "./EmployeeStructureModal";
import store from "./store";
import { GrantPermissions } from "./GrantPermissions";

import Structure from "./Structure";
import { ORG_STRUCTURE_CODES } from "constants/entityManagementTypes";
import structureStore from "./Structure/store";
import { createUrl } from "helpers/url";

type EmployeeScreenProps = {};

const EmployeeScreen: FC<EmployeeScreenProps> = observer(() => {
  const [activeTab, setActiveTab] = useState<string>(EMPLOYEES_TABS.employeeList);
  const navigate = useNavigate()

  let query = useQuery();
  let nav = query.get("nav") != null ? query.get("nav") : "employeeList";

  useEffect(() => {
    setActiveTab(nav);
  }, [nav]);

  useEffect(() => {
    if (
      store.saved_org_structure_code === ORG_STRUCTURE_CODES.unit_based &&
      !structureStore.levelStructure
    ) {
      navigate(
        createUrl({
          path: "/employees",
          query: {
            nav: EMPLOYEES_TABS.structureList,
          },
        })
      );
    }
  }, [structureStore.levelStructure, store.saved_org_structure_code]);

  useEffect(() => {
    store.getCurrentEntity(StoreLayout.currentEntityId);
    store.loadCheckManagersWithoutRoles();
    store.getEntitySettingByEntity();
  }, []);

  const changeTab = (tab: string) => setActiveTab(tab);

  const renderTab = useCallback(() => {
    switch (activeTab) {
      case EMPLOYEES_TABS.employeeList:
        return (
          <ColumnEmployee>
            <EmployeeList />
          </ColumnEmployee>
        );
      case EMPLOYEES_TABS.attributeList:
        return <AttributeList changeTab={changeTab} />;
      case EMPLOYEES_TABS.structureList:
        return (
          <ColumnEmployee>
            <Structure />
          </ColumnEmployee>
        );
      default:
        return <EmployeeList />;
    }
  }, [activeTab]);

  return (
    <MainWrapper>
      <Column>
        <LeftNavigationBar changeTab={changeTab} activeTab={activeTab} />
      </Column>

      <CustomModal
        id="OrganizationalStructure"
        width={"660px"}
        maxWidth={false}
        padding={"20px 0"}
        open={store.openModal}
        hide={(x) => navigate(-1)}
      >
        <EmployeeStructuereModal onClose={() => store.changeOpenModal(false)} />
      </CustomModal>
      <GrantPermissions
        openPanel={store.openPanelGrantPermission}
        onClose={() => store.changeopenPanelGrantPermission(false)}
      />

      {renderTab()}
    </MainWrapper>
  );
});

export default EmployeeScreen;

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 202px calc(100% - 220px);
  grid-column-gap: 16px;
`;

const Column = styled.div`
  padding-top: 80px;
`;

const ColumnEmployee = styled.div`
  padding-top: 80px;
  margin: 0 50px;
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
