import MainStore from "MainStore";
import { getRolesToProjectPermissions } from "api/go/useGetRolesToProjectPermissions";
import { savePermissionsForProject } from "api/go/useSavePermissionsForProject";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { makeAutoObservable } from "mobx";
import { Role, RolePermission, LevelPermissionDto } from "types/permission";

class Store {
  projectId: number = 0;
  projectName: string = "";

  roles: Role[] = [];
  selectedRoles: Role[] = [];
  rolePermissions: RolePermission[] = [];
  levelPermissions: LevelPermissionDto[] = [];
  permissionChanged: boolean = false;

  loadRolesWithAccessesToProject = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getRolesToProjectPermissions(this.projectId, StoreLayout.currentEntityId);
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        this.setData(response.data);
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Failed to load attributes", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  setData = (data: any) => {
    this.rolePermissions = data.role_permissions;
    this.levelPermissions = data.level_permissions;

    const rolesWithPermission = this.rolePermissions.map((x) => x.role_id);
    this.selectedRoles = data.roles.filter((x) => rolesWithPermission.includes(x.id)).sort(x => x.id);
    this.roles = data.roles.filter((x) => !rolesWithPermission.includes(x.id));
  };

  constructor() {
    makeAutoObservable(this);
  }

  clearStore = () => {
    this.projectId = 0;
    this.projectName = "";
    this.roles = [];
    this.selectedRoles = [];
    this.rolePermissions = [];
    this.levelPermissions = [];
    this.permissionChanged = false;
  };

  addRoles = (roles: Role[]) => {
    this.selectedRoles = [...this.selectedRoles, ...roles];
    const index = this.roles.indexOf(roles[0], 0);
    let newRoles = this.roles;
    if (index > -1) {
      newRoles.splice(index, 1);
    }
    this.roles = newRoles;
  };

  changeValue = (field: string, value: any) => {
    this[field] = value;
  };

  changeChecked = (flag: boolean, roleId: number, permissionId: number) => {
    this.changeValue("permissionChanged", true)
    if (flag) {
      const rolePerm = this.rolePermissions.find(
        (x) => x.role_id === roleId && x.level_permission_id === permissionId
      );
      if (!rolePerm) {
        this.rolePermissions = [
          ...this.rolePermissions,
          { id: 0, role_id: roleId, level_permission_id: permissionId, account_role_id: 0 },
        ];
      }
    } else {
      const rolePerm = this.rolePermissions.find(
        (x) => x.role_id === roleId && x.level_permission_id === permissionId
      );
      if (rolePerm) {
        const index = this.rolePermissions.indexOf(rolePerm, 0);
        let newRolePerms = this.rolePermissions;
        if (index > -1) {
          newRolePerms.splice(index, 1);
        }
        this.rolePermissions = newRolePerms;
      }
    }
  };

  onSavePermissions = async (callback: () => void) => {
    try {
      MainStore.changeLoader(true);
      const response = await savePermissionsForProject(this.projectId, this.rolePermissions, StoreLayout.currentEntityId);
      if (response.status === 201 || response.status === 200) {
        MainStore.setSnackbar(`"${this.projectName}" permissions successfully saved`);
        callback();
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Cant save permissions", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
}

const store = new Store();
export default store;
