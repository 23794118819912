import { FC } from "react";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, Dialog, DialogContent } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";

import store from "./store";
import styled from "styled-components";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import CustomButton from "components/button/Button";

type ShedulePopupProps = {
  open: boolean;
  hide: (state: boolean) => void;
};

const ShedulePopup: FC<ShedulePopupProps> = observer((props) => {
  return (
    <StyledDialog open={props.open} onClose={props.hide}>
      <CloseBtnWrapper>
        <CloseButton data-testid={`MilstoneRecepient_ShedulePopUp_CloseBtn`} onClick={() => { props.hide(false) }}>
          <ClearIcon />
        </CloseButton>
      </CloseBtnWrapper>
      <StyledDialogContent>
        <TitleWrapper>
          <Title data-testid={`MilstoneRecepient_ShedulePopUp_Title`}>Schedule</Title>
          <TitleLabel data-testid={`MilstoneRecepient_ShedulePopUp_Title_2`}>{store.selected_survey_name}</TitleLabel>
        </TitleWrapper>
        <Description data-testid={`MilstoneRecepient_ShedulePopUp_Subtitle`}>for {store.selected_employee_name}</Description>
        <List>
          <ListItem>
            <Box>
              <ListItemText data-testid={`MilstoneRecepient_ShedulePopUp_Body_1_Text`}>Set when you would like the survey to go out</ListItemText>
              <CalendarWrapper data-testid={`MilstoneRecepient_ShedulePopUp_Body_1_InputContainer`}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StyledCalendar
                    value={store.activeSheduleType === "customDate" ? store.date : null}
                    label={"Select date and time"}
                    closeOnSelect={false}
                    disabled={store.activeSheduleType !== "customDate"}
                    minDateTime={dayjs().add(15, "minute")}
                    onChange={(value: Dayjs) => store.setData("date", value)}
                  />
                </LocalizationProvider>
                <CalendarDescription data-testid={`MilstoneRecepient_ShedulePopUp_Body_1_InputInfo`}>Local timezone: {store.timezone}</CalendarDescription>
              </CalendarWrapper>
            </Box>
            <Checked
              data-testid={`MilstoneRecepient_ShedulePopUp_Body_1_Checkbox`}
              $active={store.activeSheduleType === "customDate"}
              onClick={() => store.setData("activeSheduleType", "customDate")}
            >
              {store.activeSheduleType === "customDate" && <DoneIcon />}
            </Checked>
          </ListItem>
          {!store.selectedEmployeeNoAttr && <ListItem>
            <ListItemText>Auto-schedule based on date attribute</ListItemText>
            <Checked
              $active={store.activeSheduleType === "auto"}
              onClick={() => {
                if (store.selectedEmployeeNoAttr) return;
                store.setData("activeSheduleType", "auto")
              }}
              $disabled={store.selectedEmployeeNoAttr}
            >
              {store.activeSheduleType === "auto" && <DoneIcon />}
            </Checked>
          </ListItem>}
          <ListItem>
            <ListItemText>Keep it unscheduled (I’ll decide later)</ListItemText>
            <Checked
              $active={store.activeSheduleType === "unsheduled"}
              onClick={() => store.setData("activeSheduleType", "unsheduled")}
            >
              {store.activeSheduleType === "unsheduled" && <DoneIcon />}
            </Checked>
          </ListItem>
        </List>
        <ButtonWrapper>
          <SendCustomButton
            variant="contained"
            disabled={store.activeSheduleType === "customDate" && store.date < dayjs().add(15, "minute")}
            type="submit" onClick={() => { store.SaveSchedule() }}
          >
            Save changes
          </SendCustomButton>
          <CancelCustomButton variant="outlined" type="reset" onClick={() => { props.hide(false) }}>
            Cancel
          </CancelCustomButton>
        </ButtonWrapper>
      </StyledDialogContent>
    </StyledDialog>
  );
});

export default ShedulePopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 100% !important;
    max-width: 522px !important;
    overflow: hidden !important;
    border-radius: 10px !important;
  }

  .MuiDialogContent-root {
    padding: 0px 64px !important;
  }
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 10px 20px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 30px 0px 60px 0px;
`;

const SendCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 130px;
  height: 34px;
`;

const CancelCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 90px;
  height: 34px;
  margin-left: 20px !important;
  color: var(--colorNeutralForeground2) !important;
  border-color: var(--colorNeutralForeground2) !important;

  &:hover {
    background: var(--colorNeutralForegroundInverted1) !important;
  }
`;

const Title = styled.span`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
`;

const Description = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorPaletteVioletBackground1);
  margin: 10px 0px 20px 0px;
`;

const TitleLabel = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
  margin: 6px 0px 0px 8px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 48px;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid var(--colorPaletteBlueBackground1);
  padding: 11px 10px;
  margin-bottom: 10px;
`;

const ListItemText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
  margin-top: 5px;
`;

const Checked = styled.span<{ $active?: boolean, $disabled?: boolean }>`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.$active ? "var(--colorBrandForeground1)" : props.$disabled ? "var(--colorNeutralForeground2)" : "var(--colorNeutralBackground3)"};
  cursor: ${(props) => !props.$disabled && "pointer"};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 20px;
    color: var(--colorNeutralBackground1);
  }
`;

const CalendarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const StyledCalendar = styled(DateTimePicker)`
  width: 100%;
  max-width: 246px;
  border-radius: 2px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1) !important;

  :before {
    border-bottom: none !important;
  }
  :after {
    border-bottom: none !important;
  }

  input {
    padding: 7px !important;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
    label {
        position: absolute;
        top: -8px;
    }
`;

const CalendarDescription = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground5);
`;
