import { FC } from "react";
import { observer } from "mobx-react";
import { styled as styledMui } from "@mui/material/styles";

import store from "./store";
import Table from "@mui/material/Table";
import styled from "styled-components";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import CustomSwitch from "components/switch/Switch";
import TableContainer from "@mui/material/TableContainer";
import { PROJECT_PERMISSIONS } from "constants/permissions";
import { LevelPermissionDto } from "types/permission";

type TableRolesProps = {};
const StyledTableRow = styledMui(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styledMui(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F9FAFC",
    color: "var(--ER-Theme-sys-light-on-tertiary-container, #707882)",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "16px",
    textTransform: "uppercase",
  },
  [`&.${tableCellClasses.body}`]: {},
}));

const TableRoles: FC<TableRolesProps> = observer(() => {
  return (
    <StyledTableContainer sx={{ maxHeight: 300 }}>
      <Table id="SyrveyProject_PermissionsPopUp_GridContainer" stickyHeader aria-label="simple table">
        <TableHead id="SyrveyProject_PermissionsPopUp_GridHeader">
          <StyledTableRow>
            <StyledTableCell></StyledTableCell>
            {store.levelPermissions.map((permission, index) => {
              return (
                <StyledTableCell id={`SyrveyProject_PermissionsPopUp_GridHeader_${index}`} key={permission.id} align="right">
                  {permission.permission_idNavName}
                </StyledTableCell>
              );
            })}
          </StyledTableRow>
        </TableHead>
        <TableBody id="SyrveyProject_PermissionsPopUp_GridBody">
          {store.selectedRoles.map((row, index) => (
            <TableRow id={`SyrveyProject_PermissionsPopUp_GridBody_Row_${index}`} key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <StyledTableCell id={`SyrveyProject_PermissionsPopUp_GridBody_TableName_${index}`} component="th" scope="row">
                {row.name}
              </StyledTableCell>

              {store.levelPermissions.map((permission, permissionIndex) => {
                const checked =
                  store.rolePermissions.find(
                    (x) => x.level_permission_id === permission.id && x.role_id === row.id
                  ) !== undefined;

                let disabled = CheckDisabledProjectSwitch(permission.permission_code, store.levelPermissions, row.id);
                let childPermissionId: number = GetChildProjectPermissionID(permission.permission_code, store.levelPermissions, row.id);
                if (permission.permission_code === PROJECT_PERMISSIONS.viewSurveySettingsProject.code) {
                  let createAndEditProjectPermission = store.levelPermissions.find(x => x.permission_code === PROJECT_PERMISSIONS.createAndEditProject.code)
                  disabled = store.rolePermissions.find(x => x.role_id === row.id && x.level_permission_id === createAndEditProjectPermission.id) !== undefined
                }
                if (permission.permission_code === PROJECT_PERMISSIONS.createAndEditProject.code) {
                  let viewSurveySettingsProjectPermission = store.levelPermissions.find(x => x.permission_code === PROJECT_PERMISSIONS.viewSurveySettingsProject.code)
                  const childPermission = store.rolePermissions.find(x => x.role_id === row.id && x.level_permission_id === viewSurveySettingsProjectPermission.id)
                  if (childPermission === undefined) {
                    childPermissionId = viewSurveySettingsProjectPermission.id
                  }
                }
                return (
                  <StyledTableCell align="right" style={{ width: 200 }}>
                    <div>
                      <CustomSwitch
                        id={`SyrveyProject_PermissionsPopUp_GridBody_TableSwitch_${permissionIndex}_${index}`}
                        checked={checked}
                        disabled={disabled}
                        onChange={(e) => {
                          store.changeChecked(e.target.checked, row.id, permission.id);
                          if (childPermissionId) {
                            store.changeChecked(true, row.id, childPermissionId);
                          }
                        }}
                      />
                    </div>
                  </StyledTableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
});

const CheckDisabledProjectSwitch = (code: string, levelPermissions: LevelPermissionDto[], roleID: number): boolean => {
  if (code === PROJECT_PERMISSIONS.viewSurveySettingsProject.code) {
    let createAndEditProjectPermission = levelPermissions.find(x => x.permission_code === PROJECT_PERMISSIONS.createAndEditProject.code)
    return store.rolePermissions.find(x => x.role_id === roleID && x.level_permission_id === createAndEditProjectPermission.id) !== undefined
  }
  return false
}

const GetChildProjectPermissionID = (code: string, levelPermissions: LevelPermissionDto[], roleID: number): number => {
  if (code === PROJECT_PERMISSIONS.createAndEditProject.code) {
    let viewSurveySettingsProjectPermission = levelPermissions.find(x => x.permission_code === PROJECT_PERMISSIONS.viewSurveySettingsProject.code)
    const childPermission = store.rolePermissions.find(x => x.role_id === roleID && x.level_permission_id === viewSurveySettingsProjectPermission.id)
    if (childPermission === undefined) {
      return viewSurveySettingsProjectPermission.id
    }
  }
  return null
}
const StyledTableContainer = styled(TableContainer)`
  border: 1px solid #f1f3f8;
`;

export default TableRoles;
