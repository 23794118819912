import { makeAutoObservable } from "mobx";
import { getPermissionForRoles } from "../../../api/go/useGetPermissionsForRoles";
import { changePermissionForRole } from "../../../api/go/useChangePermissionForRole";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { PermissionForRolesType, PermissionGroup, Role } from "types/permission";
import MainStore from "MainStore";

class Store {
  roles: Role[] = [];
  permissionData: PermissionGroup[] = [];
  addEditRoleNamePanel = false;

  constructor() {
    makeAutoObservable(this);
  }

  changeAddEditRoleNamePanel = (flag: boolean) => {
    this.addEditRoleNamePanel = flag;
  };

  setData(data: PermissionForRolesType) {
    this.permissionData = data.permissions;
    this.roles = data.roles;
  }

  async doLoad() {
    try {
      MainStore.changeLoader(true);
      const response = await getPermissionForRoles(
        StoreLayout.currentWorkspaceId,
        "workspace",
        StoreLayout.currentRegionId
      );
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        this.setData(response.data);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  handleError() {
    this.doLoad();
    MainStore.setSnackbar("Couldn't change it", "error");
  }

  changePermissionToType = async (
    idRole: number,
    idPermission: number,
    value: boolean,
    realId: number,
    type: string
  ) => {
    try {
      MainStore.changeLoader(true);
      const response = await changePermissionForRole(
        idRole,
        idPermission,
        value,
        realId,
        type,
        StoreLayout.currentRegionId
      );
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        MainStore.changeLoader(false);
        MainStore.setSnackbar("Success!");
        this.doLoad();
      } else {
        throw new Error();
      }
    } catch {
      this.handleError();
    } finally {
      MainStore.changeLoader(false);
    }
  };

  async changePermission(
    idRole: number,
    idPermission: number,
    value: boolean,
    childPermissionId: number
  ) {
    MainStore.openErrorConfirm(
      "Are you sure?",
      "",
      "Yes",
      "No",
      () => {
        this.changePermissionToType(
          idRole,
          idPermission,
          value,
          StoreLayout.currentWorkspaceId,
          "workspace"
        );
        if (childPermissionId) {
          this.changePermissionToType(
            idRole,
            childPermissionId,
            value,
            StoreLayout.currentWorkspaceId,
            "workspace"
          );
        }
        MainStore.onCloseConfirm();
      },
      () => {
        MainStore.onCloseConfirm();
      },
      null,
      null,
      "primary",
      "inherit"
    );
  }
}

const store = new Store();
export default store;
