import http from "api/https";
import { createUrl } from "../../helpers/url";

export const changePermissionForRole = (
  idRole: number,
  idPermission: number,
  value: boolean,
  realId: number,
  access_level_code: string,
  region_id: number
): Promise<any> => {

  const query: {
    idRole: string,
    idPermission: string,
    value: string,
    realId: string,
    access_level_code: string,
    region_id: string
  } = {
    idRole: idRole.toString(),
    idPermission: idPermission.toString(),
    value: value + "",
    realId: realId.toString(),
    access_level_code: access_level_code,
    region_id: region_id.toString()
  };

  if (http.isAPIGO) {
    return http.postGO("/permission/ChangePermissionForRole", {
      idRole: idRole,
      idPermission: idPermission,
      value: value,
      realId: realId,
      access_level_code: access_level_code,
      region_id: region_id,
    });
  }

  const url = createUrl({
    path: "/permission/ChangePermissionForRole",
    query: query,
  });

  return http.get(url);
};
