import http from "api/https";
import { createUrl } from "../../helpers/url";

export const useGetMyWorkspacePermissions = (workspaceId: number) =>{
  const query: { workspace_id: string } = { workspace_id: workspaceId.toString() };
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/permission/getMyWorkspacePermissions",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/permission/GetMyWorkspacePermissions",
      query: query,
    });
    return http.get(url);
  }
}

