import { surveyLocalization } from "survey-core"

export const tagalogStrings = {
  pagePrevText: "Nakaraan",
  pageNextText: "Susunod",
  completeText: "Isumite",
  otherItemText: "Iba pa (ilarawan)",
  noneItemText: "Wala",
  selectAllItemText: "Piliin Lahat",
  progressText: "Pahina {0} ng {1}",
  indexText: "{0} ng {1}",
  panelDynamicProgressText: "{0} ng {1}",
  questionsProgressText: "Sinagot ang {0}/{1} mga tanong",
  emptySurvey: "Hindi naglalaman ang sarbey ng mga nakikitang pahina o tanong",
  completingSurvey: "Salamat sa pagkumpleto ng sarbey",
  completingSurveyBefore: "Handa ka na! Ipinapakita ng mga rekord namin na nakumpleto mo na ang sarbey na ito",
  loadingSurvey: "Maghintay lamang habang nilo-load namin ang sarbey",
  placeholder: "Pumili",
  ratingOptionsCaption: "Pumili",
  requiredError: "Kailangan ng tugon",
  booleanCheckedLabel: "Oo",
  booleanUncheckedLabel: "Hindi",
  questionTitlePatternText: "Pamagat ng Tanong",
};

surveyLocalization.locales["tl"] = tagalogStrings
surveyLocalization.localeNames["tl"] = "Tagalog"
