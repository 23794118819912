import { FC, useEffect } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ImageIcon from '@mui/icons-material/Business';

import workSettingsIcon from "../../assets/images/work_settings.png";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";

type SelectWorkspaceProps = {};

const SelectWorkspaceScreen: FC<SelectWorkspaceProps> = observer(() => {

  useEffect(() => {
    StoreLayout.doLoad()
  }, [])

  return (
    <SignedInPageWrapper id="signedInPageWrapper">
      <Content id="content">
        <Header id="header">Select the workspace or entity you’d like to access</Header>
        <AccountsList id="accountsList">
          {StoreLayout.data.map((account, accountIndex) => (
            <AccountContainer id={`accountContainer-${account.id}`} key={account.id}>
              <AccountContainerHeader id={`accountHeader-${account.id}`}>{account.email}</AccountContainerHeader>
              <CompanyList id={`companyList-${account.id}`}>
                {account.workspaces.map((workspace, workspaceIndex) => (
                  <AccountContainerCompany id={`workspaceContainer-${workspace.id}`} key={workspace.id}>
                    <AccountContainerCompanyLeft id={`workspaceLeft-${workspace.id}`}>
                      {workspace.logo_url ?
                        <LogoWorkspace id={`logo-${workspace.id}`} src={workspace.logo_url} alt={"company-logo"} /> : <ImageIcon id={`defaultLogo-${workspace.id}`} />}
                    </AccountContainerCompanyLeft>
                    <AccountContainerCompanyRight id={`workspaceRight-${workspace.id}`} aria-label={workspace.name}>
                      <CompanyName id={`companyName-${workspace.id}`}>{workspace.name}</CompanyName>
                      <WorkSpaceList id={`workspaceList-${workspace.id}`}>
                        {workspace.has_access && (
                          <animateTransform id={`workspaceAccessLink-${workspace.id}`} key={workspace.id} href="/entities"
                            onClick={() => StoreLayout.selectWorkspace(workspace)}>
                            <WorkSpaceItem id={`workspaceItem-${workspace.id}`}>
                              <span id={`workspaceSpan-${workspace.id}`}>
                                <p id={`workspaceText-${workspace.id}`}>Workspace</p>
                                <Icon id={`icon-${workspace.id}`} src={workSettingsIcon} alt={"workSettingsIcon"} />
                              </span>
                              <ArrowForwardIcon id={`forwardIcon-${workspace.id}`} />
                            </WorkSpaceItem>
                          </animateTransform>
                        )}
                        {workspace.entities.map((entity) => (
                          <a id={`entityLink-${entity.id}`} key={workspace.id} href="/dashboard"
                            onClick={() => StoreLayout.selectEntity(entity, workspace)}>
                            <WorkSpaceItem id={`entityItem-${entity.id}`} key={entity.id}>
                              <span id={`entitySpan-${entity.id}`}>
                                <p id={`entityName-${entity.id}`}>{entity.name}</p>
                              </span>
                              <ArrowForwardIcon id={`entityForwardIcon-${entity.id}`} />
                            </WorkSpaceItem>
                          </a>
                        ))}
                      </WorkSpaceList>
                    </AccountContainerCompanyRight>
                  </AccountContainerCompany>
                ))}
              </CompanyList>
            </AccountContainer>
          ))}
        </AccountsList>
      </Content>
    </SignedInPageWrapper >
  );
});

export default SelectWorkspaceScreen;

const SignedInPageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 80px;
  display: flex;
  justify-content: center;
  background-color: var(--colorNeutralBackground4);

  @media screen and (max-width: 768px) {
    padding: 80px 20px;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 508px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.h1`
  width: 100%;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 24px;
`;


const AccountsList = styled.div`
  margin-top: 28px;
`;

const AccountContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 4px 4px;
  border: 1px solid var(--graySelectBoxBorder);
  margin-top: 16px;
`;

const AccountContainerHeader = styled.span`
  width: 100%;
  padding: 10px 24px;
  background-color: var(--colorPaletteVioletBackground1);
  color: var(--colorNeutralBackground1);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

const CompanyList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const AccountContainerCompany = styled.div`
  width: 100%;
  display: flex;
  padding: 24px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

const AccountContainerCompanyLeft = styled.div`
  width: 100%;
  max-width: 104px;
  padding: 24px 16px;
  background-color: var(--colorPalleteLightBlue);

  @media screen and (max-width: 480px) {
    max-width: none;
  }
`;

const AccountContainerCompanyRight = styled.div`
  width: 100%;
  max-width: 356px;
  padding: 40px 32px;
  background-color: var(--colorNeutralBackground1);
`;

const CompanyName = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const WorkSpaceList = styled.div`
  margin-top: 24px;
`;

const WorkSpaceItem = styled.div`
  width: 100%;
  padding: 18px 16px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 var(--colorNeutralBackground3);
  margin-top: 8px;
  background-color: var(--colorNeutralBackground1);
  cursor: pointer;
  transition: all 0.3s;

  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    margin-left: 8px;
  }

  &:hover {
    background-color: var(--colorPalleteLightBlue);
  }

  svg {
    color: var(--colorBrandForeground1);
  }

  span {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
  }
`;

const Icon = styled.img<{ src: string }>`
  width: 18px;
  height: 18px;
  margin-left: 10px;
`;

const LogoWorkspace = styled.img`
  width: 78px;
  height: 78px;
  border-radius: 8px;
`;