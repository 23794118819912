import http from "../https";

type TRequestBody = {
  survey_id: number;
  viewType: string;
  attributes: {
    id: number;
    name: string;
    segments: string[];
  }[];
  search?: string;
  pagination: {
    offset: number;
    limit: number;
    sort_by: string;
    sort_type: string;
  };
};

export const useGetRecipientsList = (requestData: TRequestBody) =>
  http.post(`/Dashboard/recepients`, requestData);
