import React, { FC, useState } from "react";
import { sm_project } from "types/projects";
import styled from "styled-components";
import CustomTable from "./components/CustomTable";
import CustomButton from "components/button/Button";
import { SelectIcon } from "./components/SelectIcon";
import { Menu, MenuItem } from "@mui/material";
import store from "./store";
import { observer } from "mobx-react";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";
import shortStringLength from "components/shortStringLength/shortStringLength";

type ProjectsTableProps = {};

const ProjectsTable: FC<ProjectsTableProps> = observer(() => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [selectedRow, setSelectedRow] = useState<sm_project | null>(null);

  const hasFeature = (): boolean => {
    if (!selectedRow) return true;
    switch (selectedRow.projecttype_code) {
      case SEND_OUT_SURVEY_TYPES.Frequency:
        return StorePermission.features.frequency.hasFeature;
      case SEND_OUT_SURVEY_TYPES.Milestones:
        return StorePermission.features.milestone.hasFeature;
      case SEND_OUT_SURVEY_TYPES.OneOff:
        return StorePermission.features.one_off.hasFeature;
      default:
        return false;
    }
  };

  const tableData = {
    headers: [
      {
        title: "PROJECT NAME",
        value: "name",
        filter: false,
        sort: true,
        renderRow: (row: sm_project) => (
          <TableCellWrapper $minWidth={200}>
            <TableName>
              <LinkProj
                id={`Survey_Project_Table_name_${row.id}`}
                href={`/projects?project_id=${row.id}`}
                onClick={() => store.setProjectId(row.id)}
              >
                {shortStringLength(row.name, 25)}
              </LinkProj>
            </TableName>
          </TableCellWrapper>
        ),
      },
      {
        title: "RECIPIENT TRIGGER",
        value: "recipient_triggers",
        filter: false,
        sort: false,
        renderRow: (row: sm_project) => (
          <TableCellWrapper $minWidth={150}>
            <TableName id={`Survey_Project_Table_RecipientTriggers_${row.id}`}>
              {row.recipient_triggers}
            </TableName>
          </TableCellWrapper>
        ),
      },
      {
        title: "FREQUENCY",
        value: "frequency",
        filter: false,
        sort: false,
        renderRow: (row: sm_project) => (
          <TableCellWrapper $minWidth={100}>
            <TableName id={`Survey_Project_Table_Frequency_${row.id}`}>
              {row.projecttype_idNavName}
            </TableName>
          </TableCellWrapper>
        ),
      },
      {
        title: "TOTAL SURVEYS",
        value: "count_surveys",
        filter: false,
        sort: false,
        renderRow: (row: sm_project) => {
          return (
            <TableCellWrapper>
              <TableName id={`Survey_Project_Table_CountSurveys_${row.id}`}>
                {row.count_surveys}
              </TableName>
            </TableCellWrapper>
          );
        },
      },
      {
        title: "TAGS",
        value: "tags",
        filter: false,
        sort: false,
        renderRow: (row: sm_project) => {
          return (
            <TableCellWrapper>
              <TableName id={`Survey_Project_Table_Tags_${row.id}`}>
                {shortStringLength(row.tag_names, 25)}
              </TableName>
            </TableCellWrapper>
          );
        },
      },
      {
        title: "PROJECT STATUS",
        value: "status",
        filter: false,
        sort: false,
        renderRow: (row: sm_project) => {
          return (
            <TableCellWrapper>
              <TableName id={`Survey_Project_Table_Status_${row.id}`}>
                {row.count_surveys === 0 ? "-" : row.status_idNavName}
              </TableName>
              {row.test && (
                <TestProjectChip id={`Survey_Project_Table_Status_TestProject_${row.id}`}>
                  Test project
                </TestProjectChip>
              )}
            </TableCellWrapper>
          );
        },
      },
      {
        title: "",
        value: "id",
        filter: false,
        sort: false,
        renderRow: (row: sm_project) => (
          <TableCellWrapperEnd>
            <RevokeAdminBtn
              id={`Survey_Project_Table_Option_Button_${row.id}`}
              onClick={(e) => {
                setSelectedRow(row);
                handleClick(e);
              }}
            >
              <SelectIcon />
            </RevokeAdminBtn>
            <TableName>
              <CustomButton
                id={`Survey_Project_Table_ViewProject_Button_${row.id}`}
                onClick={() => (window.location.href = `/projects?project_id=${row.id}`)}
                variant="outlined"
              >
                View project
              </CustomButton>
            </TableName>
          </TableCellWrapperEnd>
        ),
      },
    ],
  };

  return (
    <>
      <CustomTable
        list={store.data}
        sort={store.sortField}
        hideAddButton={store.disableControlsOrgStructure}
        sortType={store.sortType}
        data={tableData}
        changeSort={(s: string, t: string) => store.changeSort(s, t)}
      />

      <Menu
        anchorEl={anchorEl}
        id="Survey_Project_OptionMenu"
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          disabled={!hasFeature() || !selectedRow?.has_edit_permission}
          id="Survey_Project_OptionMenu_Duplicate"
          onClick={() => {
            store.DuplicateSurveyProject(selectedRow);
            handleClose();
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          disabled={!hasFeature() || !selectedRow?.has_edit_permission}
          id="Survey_Project_OptionMenu_TestOrNotTest"
          onClick={() => {
            store.openClickNonTestPopup(true);
            store.setSelectedRow(selectedRow);
            handleClose();
          }}
        >
          Mark as {selectedRow && selectedRow.test ? "non-test" : "test"}
        </MenuItem>
        <MenuItem
          disabled={!hasFeature() || !selectedRow?.has_edit_permission}
          id="Survey_Project_OptionMenu_EditSettings"
          onClick={() => {
            store.setSelectedRow(selectedRow);
            store.changeEditSettingsPopup(true);
            handleClose();
          }}
        >
          Edit settings
        </MenuItem>
        <MenuItem
          disabled={!hasFeature() || !selectedRow?.has_edit_permission}
          id="Survey_Project_OptionMenu_Permissions"
          onClick={() => {
            store.openClickPermissionsPopup(true);
            store.setSelectedRow(selectedRow);
            handleClose();
          }}
        >
          Permissions
        </MenuItem>
        <MenuItem
          disabled={!hasFeature() || !selectedRow?.has_edit_permission}
          id="Survey_Project_OptionMenu_Delete"
          onClick={() => {
            store.setSelectedRow(selectedRow);
            store.openClickDeletePopup(true);
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
});

const RevokeAdminBtn = styled.button`
  border: none;
  margin-right: 10px;
  background-color: transparent;
  outline: none;
  cursor: pointer;
`;

const TableCellWrapper = styled.div<{ $minWidth?: number }>`
  display: flex;
  min-height: 30px;
  min-width: ${(props) => (props.$minWidth ? `${props.$minWidth}px` : "100px")};
  justify-content: flex-start;
  align-items: center;
`;

const TableCellWrapperEnd = styled.div`
  display: flex;
  min-height: 30px;
  min-width: 200px;
  justify-content: flex-end;
  align-items: center;
`;

const TestProjectChip = styled.div`
  color: var(--colorNeutralForeground1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 2px;
  background: var(--colorNeutralBackground3);
  padding: 2px 5px;
  margin-left: 7px;
`;

const LinkProj = styled.a`
  color: var(--colorBrandForeground1);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
`;

const TableName = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export default ProjectsTable;
