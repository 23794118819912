import { FC } from "react";
import { UserPermissionsIcon } from "./UserPermissionsIcon";
import { RolePermissionsIcon } from "./RolePermissionsIcon";
import { WORKSPACE_PERMISSIONS_TABS } from "../../constants/menu";
import { Link as RouterLink } from 'react-router-dom';

import styled from "styled-components";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import { observer } from "mobx-react";

type LeftNavigationBarProps = {
  changeTab: (tab: string) => void;
  activeTab: string;
};

const LeftNavigationBar: FC<LeftNavigationBarProps> = observer((props) => {
  return (
    <LeftNavigationBarWrapper>
      {StorePermission.workspacePermissions.addAndRemoveWorkspaceUsers.hasPermission && <MenuItem
      id="WorkspacePermissions_LeftNavbar_WorkspaceUser"
        to="/workspacePermissions?nav=user"
        $active={Boolean(props.activeTab === WORKSPACE_PERMISSIONS_TABS.user)}
      >
        <UserPermissionsIcon />
        Workspace users
      </MenuItem>}
      {StorePermission.workspacePermissions.viewWorkspacePermissions.hasPermission && <MenuItem
      id="WorkspacePermissions_LeftNavbar_PermissionsRoles"
        to="/workspacePermissions?nav=role"
        $active={Boolean(props.activeTab === WORKSPACE_PERMISSIONS_TABS.role)}
      >
        <RolePermissionsIcon />
        Roles and permissions
      </MenuItem>}
    </LeftNavigationBarWrapper>
  );
});

export default LeftNavigationBar;

const LeftNavigationBarWrapper = styled.div`
  width: 100%;
  max-width: 202px;
  min-height: 160px;
  background-color: var(--colorNeutralBackground1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0px 8px 8px 0px;
`;

const MenuItem = styled(RouterLink) <{ $active?: boolean }>`
  width: 100%;
  padding: 12px 25px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-left: ${(props) =>
    props.$active
      ? "6px solid var(--colorBrandForeground1)"
      : "6px solid transparent"};

  color: ${(props) =>
    props.$active
      ? "var(--colorBrandForeground1)"
      : "var(--colorNeutralForeground1)"};

  svg {
    margin-right: 8px;
    path {
      fill: ${(props) =>
    props.$active
      ? "var(--colorBrandForeground1)"
      : "var(--colorNeutralForeground1)"};
    }
  }
`;
