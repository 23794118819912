import { SurveyTagsType } from "api/go/useGetSurveyTags";
import http from "api/https";

export type templaceQuestionProps = {
  id: number;
  entity_id: number;
  tagNames: string[]
};

export const updateTemplateQuestion = async (props: templaceQuestionProps): Promise<any> => {
  if(http.isAPIGO){
    return http.postGO("/template/CustomUpdateQuestionary", {
      template_id: props.id,
      entity_id: props.entity_id,
      tagNames: props.tagNames
    });
  } else {
    return http.post("/templ_template_questionary/CustomAddOrUpdateTemplate", {
      template_id: props.id,
      entity_id: props.entity_id,
      tagNames: props.tagNames
    });
  }
};
