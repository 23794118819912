import http from "api/https";
import { createUrl } from "../../helpers/url";

export const getSurveyCommunicationInformation = (id: number): Promise<any> => {
  const query: { id: string; } = { id: id.toString() };
  if (http.isAPIGO){
    const url = createUrl({
      path: "/sm/sm_survey/GetSurveyCommunicationInformation",
      query: query,
    });
    return http.getGO(url);
  }
  else {
    const url = createUrl({
      path: "/sm_survey/GetSurveyCommunicationInformation",
      query: query,
    });
    return http.get(url);
  }
};
