import http from "../https";

export const useGetBenchmarkList = (survey_id: number) =>
    http.get(
        `/sm_benchmark/GetBySurveyIdForDashBoard`,
        {},
        {
            params: {
                survey_id,
            },
        }
    );
