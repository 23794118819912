import http from "api/https";
import { AxiosResponse } from "axios";

export type SurveyTagsType = {
  id: number;
  name: string;
  code?: string;
  description?: string;
  iconColor?: string;
  idIcon?: number;
};

class UnexpectedApiError extends Error {
  constructor(response: AxiosResponse<any, any>) {
    super();
  }
}

export const GetSurveyTags = async (): Promise<any> => {
  if (http.isAPIGO) {
    const response = await http.getGO("/template/survey_tags");
    if ([200, 201].includes(response.status)) {
      return response.data;
    }
    throw new UnexpectedApiError(response);
  } else {
    const response = await http.get("/survey_tags/GetAll");
    if ([200, 201].includes(response.status)) {
      return response.data;
    }
    throw new UnexpectedApiError(response);
  }
};
