import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import CustomButton from 'components/button/Button';
import MainStore from 'MainStore';
import { observer } from "mobx-react"
import styled from 'styled-components';

import { Error403Screen } from "features/errors";

const Error403Dialog = observer(() => {

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      fullWidth={true}
      maxWidth={'md'}
      open={MainStore.error.openError403.error}
    >
      <CloseModal onClick={() => {
        MainStore.setOpenError403(false);
      }} />
      <ContentWrapper>

        <Error403Screen />


      </ContentWrapper>
    </Dialog>
  );
})

export default Error403Dialog


const MainText = styled.h1`
  margin: 20px;
`

const ActionsWrapper = styled.div`
  display: flex;
  margin: 50px 30px 3px 30px;
`
const ButtonWrapper = styled.div`
  margin: 10px;
`
const ContentWrapper = styled(MuiDialogContent)`
  margin: 20px;
`
const Header = styled.div`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0 0 30px 0;
`;

const CloseModal = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 38px;
  right: 30px;
  width: 24px;
  height: 24px;
  font-size: 16px;
  opacity: 0.6;
  transition: opacity ease 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 10px;
    display: block;
    width: 18px;
    height: 3px;
    background: var(--colorBrandForeground1);
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;