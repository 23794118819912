import http from "api/https";

export type SaveStructureType = {
  id: number;
  name: string;
  emails: string[];
  details?: string | null;
  description?: string | null;
  virtual_id?: number | null;
  parent_id?: number | null;
  virtual_parent_id?: number | null;
  level: number;
  edit: boolean;
  isNew: boolean;
  idTypeName?: string | null;
};

export const saveStructure = (data: SaveStructureType[], idEntity: number): Promise<any> => {
  if (http.isAPIGO) {
    return http.postGO(`/employee/organization_unit/AddStructureTree`, {
      data: data,
      idEntity: idEntity,
    });
  } else {
    return http.post(`/organization_unit/AddStructureTree`, {
      data: data,
      idEntity: idEntity,
    });
  }
};
