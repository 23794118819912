import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import { observer } from "mobx-react";
import store from "./store";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { ListItemButton, ListItemText } from "@mui/material";
import useDebounce from "utils/useDebounse";
import { EnterIcon } from "features/Survey/SurveyCreation/SurveySettings/EnterIcon";
import styled from "styled-components";
import CustomButton from "components/button/Button";

interface AsynchronousSearchType {
  id: number;
  level: number;
  clickEmail: (email: string) => void;
  setUpdateKey: Dispatch<SetStateAction<number>>;
}

function sleep(duration: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

const AsynchronousTextField = observer((props: AsynchronousSearchType) => {
  const [searchValue, setSearchValue] = React.useState("");
  const [isValidEmail, setIsValidEmail] = React.useState<boolean>(true);

  const debouncedValue = useDebounce(searchValue, 1000);

  React.useEffect(() => {
    if (searchValue == null || searchValue === "") {
      return store.changeOptions([]);
    }
    store.changeLoading(true);
    sleep(1000).then(() => {
      store.loadEmployees(searchValue);
    });
  }, [debouncedValue, searchValue]);

  const handleSelectEmail = (email: string) => {
    const lowerCaseEmail = email.toLowerCase();
    store.addEmail(props.id, lowerCaseEmail);
    setSearchValue("");
    props.setUpdateKey((prevKey) => prevKey + 1);
  };

  const handleClickEnter = () => {
    if (isValidEmail && searchValue.trim() !== "") {
      store.addEmail(props.id, searchValue.toLowerCase());
      props.clickEmail(searchValue.toLowerCase());
      setSearchValue("");
      props.setUpdateKey((prevKey) => prevKey + 1);
    }
  };

  return (
    <Autocomplete
      id={`Employees_OrganizationalStructure_RowLevel_Edit_SearchEmailInput_${props.level}_${props.id}`}
      isOptionEqualToValue={(option, value) => option.email === value.email}
      getOptionLabel={(option) => option.email}
      onChange={(_, value) => {
        if (value) props.clickEmail(value.email);
      }}
      options={store.optionsEmployees}
      renderOption={(_, value) => {
        return (
          <ListItemButton
            id={`Employees_OrganizationalStructure_RowLevel_Edit_SearchEmailSelect_Option_${props.level}_${props.id}_${value.id}`}
            onClick={() => handleSelectEmail(value.email)}
          >
            <ListItemText primary={value.email} />
          </ListItemButton>
        );
      }}
      fullWidth
      noOptionsText={'Unit head email is not found.'}
      loading={store.loadingEmployees}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Email"
          id={`Employees_OrganizationalStructure_RowLevel_Edit_SearchEmailInput_${props.level}_${props.id}`}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                <InputAdornment position="end">
                  {store.loadingEmployees ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : searchValue === "" ? (
                    <SearchIcon />
                  ) : (<></>)}
                </InputAdornment>
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
});

export default AsynchronousTextField;

const StyledEnterButton = styled(CustomButton)`
  border: 1px solid var(--colorNeutralBackground3) !important;
  background: var(--colorNeutralForeground8) !important;
  color: var(--colorNeutralForeground5) !important;
  margin-right: -30px !important;
  padding: 3px !important;
  display: flex;
  align-items: center;
  gap: 5px;
`;