import http from "../https";

import { TGetDashboardResponcesOpenEndedQuestionsAsyncResponse } from "types/dashboard";

type TRequestBody = {
    survey_id: number;
    attributes: {
        id: number;
        name: string;
        segments: string[];
    }[];
    benchmarkType?: number;
    viewType?: string;
};

export const getDashboardResponcesOpenEndedQuestionsAsync = async (
    data: TRequestBody
): Promise<TGetDashboardResponcesOpenEndedQuestionsAsyncResponse[]> => {
    try {
        const response = await http.post(`/Dashboard/responces/openend/questions`, data);

        if ([200, 201].includes(response.status)) {
            return response.data;
        }

        throw new Error(`Failed to fetch questions (HTTP status code: ${response.status})`);
    } catch (err) {
        console.error(err);
    }
};
