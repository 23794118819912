import { FC } from "react";
import { observer } from "mobx-react-lite";
import { QuantityInput } from "components/number-input-counter/NumberInputCounter";

import store from "./store";
import styled from "styled-components";
import { PROJECT_STATUS } from "constants/survey-status";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

type ConfidentialityThresholdProps = {};

const ConfidentialityThreshold: FC<ConfidentialityThresholdProps> = observer(() => {
  return (
    <MainContainer>
      <Container>
        <Text
        id="Survey_Project_CreatePopup_ConfidentialityThreshold_Title">Minimum responses required to display results</Text>
        <QuantityInput
          disabled={store.projectStatus !== PROJECT_STATUS.unscheduled}
          value={store.minResponses}
          onChange={(_, val) => store.setData(val, "minResponses")}
        />
      </Container>
      {store.minResponses < 3 && (
        <WarningWrapper>
          <ErrorOutlineIcon />
          <WarningText>
            We recommend setting it to a <b>minimum of 3</b> for employee engagement surveys.
            Removing confidentiality will affect data integrity.
          </WarningText>
        </WarningWrapper>
      )}
      {store.minResponses === 0 && (
        <WarningWrapper>
          <WarningAmberIcon />
          <WarningText>
            All surveys in this project are set to be non-confidential, and recipients will be
            notified when responding to the survey. Do note that it will be easy to identify survey
            recipients based on their responses.
          </WarningText>
        </WarningWrapper>
      )}
    </MainContainer>
  );
});

export default ConfidentialityThreshold;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
  border-radius: 4px;
  border: 1px solid var(--colorNeutralForeground4);
  padding: 10px 20px;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
`;

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;

  svg {
    font-size: 20px;
    color: var(--colorPaletteRedForeground2);
  }
`;

const WarningText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  margin-left: 6px;
`;
