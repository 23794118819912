import { FC } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { observer } from "mobx-react";

type BottomUploadCompleteProps = {
  isHistorical?: boolean;
};

const BottomUploadComplete: FC<BottomUploadCompleteProps> = observer(({ isHistorical = false }) => {
  if (!StoreLayout.showImportDone) return;
  return (
    <UploadingWrapper $isHistorical={isHistorical}>
      <Text data-testid='BottomUpload_Text'>
        <CheckCircleIcon />
        {StoreLayout.importDoneText}
        {!isHistorical && (
          <Link
            data-testid='BottomUpload_ViewChanges_Btn'
            onClick={() => (StoreLayout.showImportDone = false)}
            to={StoreLayout.importDoneLink}
          >
            View changes
          </Link>
        )}
      </Text>
    </UploadingWrapper>
  );
});

export default BottomUploadComplete;

const UploadingWrapper = styled.div<{ $isHistorical?: boolean }>`
  position: fixed;
  bottom: 0;
  right: 75px;
  padding: 16px 20px 18px 29px;
  height: 66px;
  background-color: var(--colorPaletteGreenBackground1);
  max-width: 660px;
  width: 100%;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: ${({ $isHistorical }) => ($isHistorical ? "flex-end" : "baseline")};
  justify-content: space-between;
`;

const Text = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralBackground1);

  a {
    margin-left: 10px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: underline;
  }

  svg {
    margin-right: 6px;
    width: 16px;
    height: 16px;
  }
`;
