import { FC } from "react";
import { SurveyCreation } from "../../features/Survey/SurveyCreation";

type SurveyPageProps = {};

const SurveyPagePage: FC<SurveyPageProps> = () => {
  return <SurveyCreation />;
};

export default SurveyPagePage;
