import { FC } from "react";
import styled from "styled-components";
import ErrorIcon from "@mui/icons-material/Error";
import { observer } from "mobx-react";
import store from "./store";
import { IconXlsx } from "./Icons/IconXlsx";

type ErrorsProps = {
  file: File;
};

const Errors: FC<ErrorsProps> = observer((props) => {
  return (
    <ErrorsWrapper id={`ImportHistoricalSurvey_ErrorsBlock`}>
      <Content>
        <Header id={`ImportHistoricalSurvey_ErrorsFound`}>Errors found in file</Header>
        <FileWrapper>
          <IconXlsx />
          <p id={`ImportHistoricalSurvey_FileName`}>{props.file?.name || "File not found"}</p>
        </FileWrapper>
        <WarningContainer>
          <ErrorIcon />
          <p id={`ImportHistoricalSurvey_ErrorsDescription`}>This list cannot be uploaded due to errors found on the excel file.</p>
        </WarningContainer>

        {store.surveyParseResult?.errors?.length > 0 && <Unit id={`ImportHistoricalSurvey_ErrorsList`}>
          <br />
          {store.surveyParseResult?.errors?.map((x, i) =>
            <UnitRowText id={`ImportHistoricalSurvey_Error_${i}`} key={x}>{x}</UnitRowText>)}
        </Unit>}
      </Content>
    </ErrorsWrapper>
  );
});

export default Errors;

const ErrorsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 520px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.h1`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0 0 20px 0;
`;

const FileWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 28px;

  p {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: var(--colorNeutralForeground1);
  }

  svg {
    margin-right: 10px;
  }
`;

const WarningContainer = styled.div`
  width: 100%;
  padding: 20px 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: var(--colorNeutralBackground1);

  p {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--colorNeutralForeground2);
  }

  svg {
    color: var(--colorPaletteRedForeground2);
    margin-right: 10px;
  }
`;

const Unit = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
  margin-top: 20px;
  padding-bottom: 15px;
`;

const UnitRowText = styled.li`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;
