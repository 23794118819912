import { FC } from "react";
import { observer } from "mobx-react";

import styled, { css } from "styled-components";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { CardLoader } from "features/Dashboard/CardLoader";
import store from "../../store";

type DimensionsCardProps = {};

const DimensionsCard: FC<DimensionsCardProps> = observer(() => {
  const { isDimensionsEmpty } = store;

  const content = (
    <>
      <ContentRow>
        <StyledIcon src="/assets/green-round-like.png" />
        <DimensionInfoContainer>
          <Label>Top dimension</Label>
          <Info>
            {isDimensionsEmpty && (
              <Text>-</Text>
            )}
            {!isDimensionsEmpty && (
              <>
                <Text>
                  {(store.projectInfo?.dimensions && store.projectInfo?.dimensions.top?.name) || ""}
                </Text>
                <TextGroup>
                  <Text>
                    {(store.projectInfo?.dimensions && store.projectInfo?.dimensions.top?.percent) ||
                      ""}
                    %
                  </Text>
                  {store.projectInfo?.dimensions && store.projectInfo?.dimensions.top.diff ? (
                    <>
                      {store.projectInfo?.dimensions && store.projectInfo?.dimensions.top.diff < 0 ? (
                        <StyledArrowDropDownIcon />
                      ) : (
                        <StyledArrowDropUpIcon />
                      )}
                      <Text>
                        {(store.projectInfo?.dimensions && store.projectInfo?.dimensions.top.diff) ||
                          "0"}
                        %{" "}
                      </Text>
                    </>
                  ) : null}
                </TextGroup>
              </>
            )}
          </Info>
        </DimensionInfoContainer>
      </ContentRow>
      <ContentRow>
        <StyledIcon src="/assets/red-round-warning.png" />
        <DimensionInfoContainer>
          <Label>Bottom dimension</Label>
          <Info>
            {isDimensionsEmpty && (
              <Text>-</Text>
            )}
            {!isDimensionsEmpty && (
              <>
                <Text>
                  {(store.projectInfo?.dimensions && store.projectInfo?.dimensions.bottom?.name) || ""}
                </Text>
                <TextGroup>
                  <Text>
                    {(store.projectInfo?.dimensions &&
                      store.projectInfo?.dimensions.bottom.percent) ||
                      "0"}
                    %
                  </Text>
                  {store.projectInfo?.dimensions && store.projectInfo?.dimensions.bottom.diff ? (
                    <>
                      {store.projectInfo?.dimensions &&
                        store.projectInfo?.dimensions.bottom.diff < 0 ? (
                        <StyledArrowDropDownIcon />
                      ) : (
                        <StyledArrowDropUpIcon />
                      )}
                      <Text>
                        {(store.projectInfo?.dimensions &&
                          store.projectInfo?.dimensions.bottom.diff) ||
                          "0"}
                        %
                      </Text>
                    </>
                  ) : null}
                </TextGroup>
              </>
            )}
          </Info>
        </DimensionInfoContainer>
      </ContentRow>
      <ContentRow>
        <StyledIcon src="/assets/green-round-like.png" />
        <DimensionInfoContainer>
          <Label>Biggest improvement</Label>
          <Info>
            {isDimensionsEmpty && (
              <Text>-</Text>
            )}
            {!isDimensionsEmpty && (
              <>
                <Text>
                  {store.projectInfo?.dimensions &&
                    store.projectInfo?.dimensions.biggestImprovement?.name}
                </Text>
                <TextGroup>
                  <Text>
                    {store.projectInfo?.dimensions &&
                      store.projectInfo?.dimensions.biggestImprovement?.percent}
                    %
                  </Text>
                  {store.projectInfo?.dimensions &&
                    store.projectInfo?.dimensions.biggestImprovement?.diff ? (
                    <>
                      {store.projectInfo?.dimensions &&
                        store.projectInfo?.dimensions.biggestImprovement?.diff < 0 ? (
                        <StyledArrowDropDownIcon />
                      ) : (
                        <StyledArrowDropUpIcon />
                      )}
                      <Text>
                        {store.projectInfo?.dimensions &&
                          store.projectInfo?.dimensions.biggestImprovement?.diff}
                        %
                      </Text>
                    </>
                  ) : null}
                </TextGroup>
              </>
            )}
          </Info>
        </DimensionInfoContainer>
      </ContentRow>
      <ContentRow>
        <StyledIcon src="/assets/red-round-warning.png" />
        <DimensionInfoContainer>
          <Label>Biggest drop</Label>
          <Info>
            {isDimensionsEmpty && (
              <Text>-</Text>
            )}
            {!isDimensionsEmpty && (
              <>
                <Text>
                  {store.projectInfo?.dimensions && store.projectInfo?.dimensions.biggestDrop?.name}
                </Text>
                <TextGroup>
                  <Text>
                    {store.projectInfo?.dimensions &&
                      store.projectInfo?.dimensions.biggestDrop?.percent}
                    %
                  </Text>
                  {store.projectInfo?.dimensions && store.projectInfo?.dimensions.biggestDrop?.diff ? (
                    <>
                      {store.projectInfo?.dimensions &&
                        store.projectInfo?.dimensions.biggestDrop?.diff < 0 ? (
                        <StyledArrowDropDownIcon />
                      ) : (
                        <StyledArrowDropUpIcon />
                      )}
                      <Text>
                        {store.projectInfo?.dimensions &&
                          store.projectInfo?.dimensions.biggestDrop?.diff}
                        %
                      </Text>
                    </>
                  ) : null}
                </TextGroup> 
              </>
            )}
          </Info>
        </DimensionInfoContainer>
      </ContentRow>
    </>
  )

  const isPending = store.isDimensionsPending || !store.projectInfo

  return (
    <Card>
      <TitleRow>
        <Title>Dimensions</Title>
        {isDimensionsEmpty && <EmptyStateTitle>Overall score is not available for this survey.</EmptyStateTitle>}
      </TitleRow>
      <Content isEmpty={isDimensionsEmpty}>
        {isPending ? <CardLoader contentHeight={172} /> : content}
      </Content>
    </Card>
  );
});

export default DimensionsCard;

const Card = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--colorNeutralBackground1);
`;

const TitleRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-right: 4px;
  margin-bottom: 16px;
`;

const EmptyStateTitle = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  color: var(--colorNeutralForeground2);
  margin-left: auto;
  line-height: 16px;
`;

type ContentProps = {
  isEmpty?: boolean;
};

const Content = styled.div<ContentProps>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 38px;

  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
  }

  ${(props) => Boolean(props.isEmpty) && css`
    opacity: 0.4;
    pointer-events: none;
  `}
`;

const ContentRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 26px 1fr;
  column-gap: 12px;
  margin-top: 24px;
`;

const DimensionInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--colorNeutralForeground4);
  padding: 0px 0px 8px 0px;
`;

const Label = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
  margin-bottom: 8px;
`;

const Info = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
`;

const StyledIcon = styled.img`
  width: 26px;
  height: 26px;
`;

const TextGroup = styled.span`
  display: flex;
  align-items: center;
`;

const StyledArrowDropUpIcon = styled(ArrowDropUpIcon)`
  color: var(--colorPaletteGreenBackground2);
  font-size: 24px !important;
`;

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  color: var(--colorPaletteRedForeground1);
  font-size: 24px !important;
`;
