import http from "api/https";
import { TemplateLanguage } from "types/templates";

export const SaveReportTemplateMessages = async (
  report_data: TemplateLanguage[],
  is_send_report: boolean,
  template_id: number,
  time_send_report: string,
  reminder_days_id: number
): Promise<any> => {
  let body = {
    template_id: template_id,
    is_send_report: is_send_report,
    reminder_days_id: reminder_days_id,
    time_send_report: time_send_report,
    report_data: report_data,
  }
  if(http.isAPIGO){
    return http.postGO('/template/save_report_template_messages', body)
  } else {
    return http.post("/templ_template_comms/SaveReportTemplateMessages", body);
  }
};
