export const RESPONSES_OPEN_ENDED_QUESTION_LIST = [
  {
    id: 1,
    question: "What would you do to improve things here?",
    responses_count: 60,
  },
  {
    id: 2,
    question: "What would you do to improve things here?",
    responses_count: 6,
  },
  {
    id: 3,
    question: "What would you do to improve things here?",
    responses_count: 6,
  },
  {
    id: 4,
    question: "What would you do to improve things here?",
    responses_count: 6,
  },
];

export const RESPONSES_OPEN_ENDED_RESPONSES_LIST = [
  {
    id: 1,
    text: "My direct manager provides clear and regular feedback on how well I do my work.",
    value: "positive",
  },
  {
    id: 2,
    text: "Keep reminding everyone what problem we’re solving, and visualize the road to get there.",
    value: "positive",
  },
  {
    id: 3,
    text: "Gnarly",
    value: "positive",
  },
  {
    id: 4,
    text: "My direct manager provides clear and regular feedback on how well I do my work.",
    value: "wicked",
  },
  {
    id: 5,
    text: "This is fine. *Fire burns in background*",
    value: "neutral",
  },
  {
    id: 6,
    text: "Roses are red, violets are blue. Chickens are brown and so are you.",
    value: "message",
  },
];

export const DASHBOARD_RECIPIENTS_LIST = [
  {
    id: 1,
    name: "Chairman (Chairman James Tan)",
    email: "james.tan@widgets.com",
  },
  {
    id: 2,
    name: "Rosetta (Rosetta Greene)",
    email: "rosetta@widgets.com",
  },
  {
    id: 3,
    name: "Sophia (Sophia Crawford)",
    email: "sophia@widgets.com",
  },
  {
    id: 4,
    name: "Louise (Louise McDaniel)",
    email: "louise@widgets.com",
  },
  {
    id: 5,
    name: "You – James (James DeSouza Tan)",
    email: "james.tan@widgets.com",
  },
  {
    id: 6,
    name: "Tommy (Tommy Marsh)",
    email: "tommy.m@widgets.com",
  },
  {
    id: 7,
    name: "Luella (Luella Green)",
    email: "luella@widgets.com",
  },
];

export const HOTSPOTS_RESPONDENTS_LIST = [
  {
    id: 1,
    name: "China",
  },
  {
    id: 2,
    name: "Indonesia",
  },
  {
    id: 3,
    name: "Malaysia",
  },
  {
    id: 4,
    name: "Myanmar",
  },
  {
    id: 5,
    name: "Singapore",
  },
  {
    id: 6,
    name: "Taiwan",
  },
  {
    id: 7,
    name: "USA",
  },
  {
    id: 8,
    name: "Vietnam",
  },
];

// export const DASHBOARD_HOTSPOTS_COL_SETUP_QUESTIONS_LIST = [
//   {
//     id: 1,
//     name: "Accomplishment",
//     list: [
//       { id: 1, name: "I feel accomplished working in my role.", checked: false, type: "enps" },
//       {
//         id: 2,
//         name: "I feel accomplished working in this company.",
//         checked: false,
//         type: "overal_score",
//       },
//     ],
//   },
//   {
//     id: 2,
//     name: "Autonomy",
//     list: [
//       {
//         id: 1,
//         name: "I am given the autonomy to perform well in my role and with teams assigned under me",
//         checked: false,
//         type: "favourable_score",
//       },
//       {
//         id: 2,
//         name: "I feel accomplished working in this company.",
//         checked: false,
//         type: "overal_score",
//       },
//     ],
//   },
//   {
//     id: 3,
//     name: "Clear expectations",
//     list: [
//       {
//         id: 1,
//         name: "I feel accomplished working in my role.",
//         checked: false,
//         type: "favourable_score",
//       },
//       {
//         id: 2,
//         name: "I feel accomplished working in this company.",
//         checked: false,
//         type: "overal_score",
//       },
//     ],
//   },
//   {
//     id: 4,
//     name: "Environment",
//     list: [
//       {
//         id: 1,
//         name: "I feel accomplished working in my role.",
//         checked: false,
//         type: "overal_score",
//       },
//       {
//         id: 2,
//         name: "I feel accomplished working in this company.",
//         checked: false,
//         type: "favourable_score",
//       },
//     ],
//   },
// ];

export const DASHBOARD_HOTSPOTS_GRAPH_DATA = [
  { x: 0, y: 2, value: 80, color: "#9CF0C8" },
  { x: 1, y: 2, value: 64, color: "#E6F9EE" },
  { x: 2, y: 2, value: 82, color: "#9CF0C8" },
  { x: 3, y: 2, value: 64, color: "#E6F9EE" },
  { x: 4, y: 2, value: 64, color: "#E6F9EE" },
  { x: 5, y: 2, value: 64, color: "#9CF0C8" },
  { x: 6, y: 2, value: 64, color: "#E6F9EE" },
  { x: 7, y: 2, value: 64, color: "#E6F9EE" },
  { x: 8, y: 2, value: 64, color: "#E6F9EE" },
  { x: 9, y: 2, value: 64, color: "#E6F9EE" },

  { x: 0, y: 1, value: 0, color: "#F1F1F1" },
  { x: 1, y: 1, value: 64, color: "#E6F9EE" },
  { x: 2, y: 1, value: 82, color: "#9CF0C8" },
  { x: 3, y: 1, value: 64, color: "#E6F9EE" },
  { x: 4, y: 1, value: 64, color: "#E6F9EE" },
  { x: 5, y: 1, value: 64, color: "#9CF0C8" },
  { x: 6, y: 1, value: 56, color: "#FDF0D0" },
  { x: 7, y: 1, value: 64, color: "#E6F9EE" },
  { x: 8, y: 1, value: 64, color: "#E6F9EE" },
  { x: 9, y: 1, value: 58, color: "#FDF0D0" },

  { x: 0, y: 0, value: 80, color: "#9CF0C8" },
  { x: 1, y: 0, value: 24, color: "#FFB6B6" },
  { x: 2, y: 0, value: 82, color: "#9CF0C8" },
  { x: 3, y: 0, value: 64, color: "#E6F9EE" },
  { x: 4, y: 0, value: 64, color: "#E6F9EE" },
  { x: 5, y: 0, value: 64, color: "#9CF0C8" },
  { x: 6, y: 0, value: 24, color: "#FFB6B6" },
  { x: 7, y: 0, value: 64, color: "#E6F9EE" },
  { x: 8, y: 0, value: 64, color: "#9CF0C8" },
  { x: 9, y: 0, value: 64, color: "#E6F9EE" },
];

export const HOTSPOTS_GRAPH_DATA = {
  categories: [
    "Participation",
    "eNPS",
    "Overall score",
    "Accomplishment",
    "Autonomy",
    "Clear expectations",
    "Environment",
    "Growth",
    "Manager’s support",
    "Recognition",
  ],

  data: [
    {
      segment: [],
      type: "all_respondents",
      list: [
        {
          value: 93,
          min: 12,
          max: 16,
          color: "#9CF0C8",
          type: "participation",
        },
        {
          value: 90,
          benchark: 4.32,
          color: "#9CF0C8",
          type: "enps",
        },
        {
          value: 62,
          benchark: 85,
          color: "#FDF0D0",
          type: "overal-score",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
      ],
    },
    {
      type: "enps",
      segment: ["China"],
      list: [
        {
          value: 93,
          min: 12,
          max: 16,
          color: "#9CF0C8",
          type: "participation",
        },
        {
          value: 90,
          benchark: 4.32,
          color: "#9CF0C8",
          type: "enps",
        },
        {
          value: 62,
          benchark: 85,
          color: "#FDF0D0",
          type: "overal-score",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
      ],
    },
    {
      segment: ["China", "Female"],
      type: "segment",
      list: [
        {
          value: 93,
          min: 12,
          max: 16,
          color: "#9CF0C8",
          type: "participation",
        },
        {
          value: 90,
          benchark: 4.32,
          color: "#9CF0C8",
          type: "enps",
        },
        {
          value: 62,
          benchark: 85,
          color: "#FDF0D0",
          type: "overal-score",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
      ],
    },
    {
      segment: ["China", "Female", "Amy Potts"],
      type: "segment",
      list: [
        {
          value: 93,
          min: 12,
          max: 16,
          color: "#9CF0C8",
          type: "participation",
        },
        {
          value: 90,
          benchark: 4.32,
          color: "#9CF0C8",
          type: "enps",
        },
        {
          value: 62,
          benchark: 85,
          color: "#FDF0D0",
          type: "overal-score",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
      ],
    },
    {
      segment: ["China", "Female", "Emilie Flowers"],
      type: "segment",
      list: [
        {
          value: 93,
          min: 12,
          max: 16,
          color: "#9CF0C8",
          type: "participation",
        },
        {
          value: 90,
          benchark: 4.32,
          color: "#9CF0C8",
          type: "enps",
        },
        {
          value: 62,
          benchark: 85,
          color: "#FDF0D0",
          type: "overal-score",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
      ],
    },
    {
      segment: ["Gender"],
      type: "segment",
      list: [
        {
          value: 93,
          min: 12,
          max: 16,
          color: "#9CF0C8",
          type: "participation",
        },
        {
          value: 90,
          benchark: 4.32,
          color: "#9CF0C8",
          type: "enps",
        },
        {
          value: 62,
          benchark: 85,
          color: "#FDF0D0",
          type: "overal-score",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
      ],
    },
    {
      segment: ["Gender", "Male"],
      type: "segment",
      list: [
        {
          value: 93,
          min: 12,
          max: 16,
          color: "#9CF0C8",
          type: "participation",
        },
        {
          value: 90,
          benchark: 4.32,
          color: "#9CF0C8",
          type: "enps",
        },
        {
          value: 62,
          benchark: 85,
          color: "#FDF0D0",
          type: "overal-score",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
        {
          value: 82,
          benchark: 85,
          color: "#E6F9EE",
          type: "segment",
        },
      ],
    },
  ],
};

const requestParams = {
  survey_id: 0,
  benchmark_against: "all_companies",
  segmentsFilters: [
    {
      name: "Language",
      segments: ["French", "English"],
    },
    {
      name: "Gender",
      value: "Male",
    },
  ],
  rowSettings: {
    overalScore: true,
    eNPS: true,
    favourable_score: true,
    questions: [],
  },
};
export const OPEN_ENDED_GRAPH_DATA = [
  {
    name: "Career",
    sentiment: -90,
    size: 1,
  },
  {
    name: "Tester",
    sentiment: -20,
    size: 1,
  },
  {
    name: "Travel",
    sentiment: -23,
    size: 2,
  },
  {
    name: "Customers",
    sentiment: 2,
    size: 3,
  },
  {
    name: "Hire and onboard",
    sentiment: -34,
    size: 2,
  },
  {
    name: "Well being",
    sentiment: -167,
    size: 1,
  },
  {
    name: "Communication",
    sentiment: -52,
    size: 0,
  },
  {
    name: "Innovation",
    sentiment: -180,
    size: 1,
  },
  {
    name: "Transparency",
    sentiment: -90,
    size: 1,
  },
  {
    name: "Belonging",
    sentiment: -45,
    size: 1,
  },
  {
    name: "Learning & Development",
    sentiment: -145,
    size: 3,
  },
  {
    name: "Learning and development",
    sentiment: 1,
    size: 2,
  },
];

export const DASHBOARD_RESPONSES_OTHERS_QUESTION = [
  {
    id: 0,
    dimension: "Autonomy",
    questionsList: [
      {
        id: 0,
        title: "How can we recognise our staff better?",
        respondedCount: 1356,
        type: "multiple",
        min: null,
        max: null,
        commentsCount: 1024,
        otherOptionsReponsesCount: 1024,
        responseList: [
          {
            id: 0,
            title:
              "More company perks like annual retreats, staff dinner, discount coupons and vouchers",
            percentBar: 30,
            percent: 34,
            countMC: 100,
            diffMC: -11,
          },
          {
            id: 1,
            title: "Bonus",
            percentBar: 70,
            percent: 7,
            countMC: 59,
            diffMC: null,
          },
          {
            id: 2,
            title: "Others",
            percentBar: 62,
            percent: 7,
            countMC: 46,
            diffMC: 7,
          },
        ],
      },
      {
        id: 1,
        title: "How can we recognise our staff better?",
        respondedCount: 1356,
        type: "multiple",
        min: 2,
        max: 5,
        commentsCount: 1024,
        otherOptionsReponsesCount: null,
        responseList: [
          {
            id: 0,
            title:
              "More company perks like annual retreats, staff dinner, discount coupons and vouchers",
            percentBar: 30,
            percent: 34,
            countMC: 100,
            diffMC: -11,
          },
          {
            id: 1,
            title: "More salary",
            percentBar: 70,
            percent: 7,
            countMC: 1253,
            diffMC: 7,
          },
          {
            id: 2,
            title: "More company stocks",
            percentBar: 62,
            percent: 7,
            countMC: 46,
            diffMC: 7,
          },
          {
            id: 3,
            title: "Bonus",
            percentBar: 75,
            percent: 7,
            countMC: 59,
            diffMC: null,
          },
          {
            id: 4,
            title: "Via company-wide email",
            percentBar: 42,
            percent: 7,
            countMC: 28,
            diffMC: 7,
          },
        ],
      },
      {
        id: 2,
        title: "How can we recognise our staff better?",
        respondedCount: 1356,
        type: "multiple",
        min: null,
        max: 5,
        commentsCount: 1024,
        otherOptionsReponsesCount: null,
        responseList: [
          {
            id: 0,
            title:
              "More company perks like annual retreats, staff dinner, discount coupons and vouchers",
            percentBar: 30,
            percent: 34,
            countMC: 100,
            diffMC: -11,
          },
          {
            id: 1,
            title: "More salary",
            percentBar: 70,
            percent: 7,
            countMC: 1253,
            diffMC: 7,
          },
          {
            id: 2,
            title: "More company stocks",
            percentBar: 62,
            percent: 7,
            countMC: 46,
            diffMC: 7,
          },
          {
            id: 3,
            title: "Bonus",
            percentBar: 75,
            percent: 7,
            countMC: 59,
            diffMC: null,
          },
          {
            id: 4,
            title: "Via company-wide email",
            percentBar: 42,
            percent: 7,
            countMC: 28,
            diffMC: 7,
          },
        ],
      },
    ],
  },

  {
    id: 1,
    dimension: "Clear expectations",
    questionsList: [
      {
        id: 0,
        title: "How can we recognise our staff better?",
        respondedCount: 1356,
        type: "single",
        min: null,
        max: null,
        commentsCount: 1024,
        otherOptionsReponsesCount: null,
        responseList: [
          {
            id: 0,
            title:
              "More company perks like annual retreats, staff dinner, discount coupons and vouchers",
            percentBar: 30,
            percent: 34,
            countMC: 100,
            diffMC: -11,
          },
          {
            id: 1,
            title: "Bonus",
            percentBar: 70,
            percent: 7,
            countMC: 59,
            diffMC: null,
          },
          {
            id: 2,
            title: "Others",
            percentBar: 62,
            percent: 7,
            countMC: 46,
            diffMC: 7,
          },
        ],
      },
    ],
  },

  {
    id: 2,
    dimension: "Manager’s support",
    questionsList: [
      {
        id: 0,
        title: "How can we recognise our staff better?",
        respondedCount: 1356,
        type: "yes/no",
        min: null,
        max: null,
        commentsCount: 1024,
        otherOptionsReponsesCount: 1024,
        responseList: [
          {
            id: 0,
            title: "Yes",
            percentBar: 30,
            percent: 7,
            countMC: 59,
            diffMC: null,
          },
          {
            id: 1,
            title: "No",
            percentBar: 70,
            percent: 7,
            countMC: 46,
            diffMC: 7,
          },
          {
            id: 2,
            title: "Others",
            percentBar: 62,
            percent: 7,
            countMC: 46,
            diffMC: 7,
          },
        ],
      },
    ],
  },
];

export const DASHBOARD_RESPONSES_SCORED_QUESTIONS = [
  {
    id: 0,
    dimension: "eNPS",
    type: "enps",
    benchmarkDiff: null,
    benchmark: null,
    allRespondentsPercent: null,
    allRespondentsPercentDiff: null,
    favourablePercent: null,
    unFavourablePercent: null,
    neutralPercent: null,
    questionsList: [
      {
        id: 0,
        title: "How likely are you to recommend this company to friends or family?",
        respondedCount: 1356,
        commentsCount: 1024,
        allRespondentsCount: 83,
        allRespondentsDiff: 13,
        benchmarkDiff: -2,
        benchmark: 85,
        reponseList: {
          promoters: {
            percent: 0,
            countByCategories: 935,
            list: [
              {
                countInNumbers: 232,
                percent: 33,
                countReponse: 10,
                graphPercent: 43,
              },
              {
                countInNumbers: 232,
                percent: 33,
                countReponse: 9,
                graphPercent: 94,
              },
            ],
          },
          passives: {
            percent: 66,
            countByCategories: 1302,
            list: [
              {
                countInNumbers: 232,
                percent: 33,
                countReponse: 8,
                graphPercent: 40,
              },
              {
                countInNumbers: 232,
                percent: 33,
                countReponse: 7,
                graphPercent: 40,
              },
            ],
          },
          detractors: {
            percent: 34,
            countByCategories: 635,
            list: [
              {
                countInNumbers: 232,
                percent: 33,
                countReponse: 6,
                graphPercent: 40,
              },
              {
                countInNumbers: 232,
                percent: 33,
                countReponse: 5,
                graphPercent: 74,
              },
              {
                countInNumbers: 232,
                percent: 33,
                countReponse: 4,
                graphPercent: 40,
              },
              {
                countInNumbers: 232,
                percent: 33,
                countReponse: 3,
                graphPercent: 62,
              },
              {
                countInNumbers: 232,
                percent: 33,
                countReponse: 2,
                graphPercent: 40,
              },
              {
                countInNumbers: 232,
                percent: 33,
                countReponse: 1,
                graphPercent: 19,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 1,
    dimension: "Autonomy",
    type: "dimension",
    benchmarkDiff: -2,
    benchmark: 49,
    allRespondentsPercent: 67,
    allRespondentsPercentDiff: 24,
    favourablePercent: 67,
    unFavourablePercent: 21,
    neutralPercent: 12,
    questionsList: [
      {
        id: 0,
        title: "My direct manager provides clear and regular feedback on how well I do my work.",
        commentsCount: 1024,
        respondedCount: 3,
        benchmarkDiff: -2,
        benchmark: 49,
        allRespondentsPercent: 67,
        allRespondentsPercentDiff: 24,
        favourablePercent: 2,
        unFavourablePercent: 3,
        neutralPercent: 95,
        conditionalLogic: null,
      },
      {
        id: 1,
        title: "My direct manager treats me fairly.",
        commentsCount: 1024,
        respondedCount: 3,
        benchmarkDiff: -2,
        benchmark: 49,
        allRespondentsPercent: 67,
        allRespondentsPercentDiff: 24,
        favourablePercent: 67,
        unFavourablePercent: 21,
        neutralPercent: 12,
        conditionalLogic: {
          segments: [
            ["Malaysia", "Singapore"],
            ["Finance", "Human resources"],
          ],
          responses: "9 OR 10",
        },
      },
    ],
  },
  {
    id: 2,
    dimension: "Clear expectations",
    type: "dimension",
    benchmarkDiff: -2,
    benchmark: 49,
    allRespondentsPercent: 67,
    allRespondentsPercentDiff: 24,
    favourablePercent: 5,
    unFavourablePercent: 90,
    neutralPercent: 5,
    questionsList: [
      {
        id: 0,
        title: "My direct manager provides clear and regular feedback on how well I do my work.",
        commentsCount: 1024,
        respondedCount: 3,
        benchmarkDiff: -2,
        benchmark: 49,
        allRespondentsPercent: 67,
        allRespondentsPercentDiff: 24,
        favourablePercent: 5,
        unFavourablePercent: 90,
        neutralPercent: 5,
        conditionalLogic: null,
      },
    ],
  },
  {
    id: 3,
    dimension: "Environment",
    type: "dimension",
    benchmarkDiff: -2,
    benchmark: 49,
    allRespondentsPercent: 67,
    allRespondentsPercentDiff: 24,
    favourablePercent: 67,
    unFavourablePercent: 21,
    neutralPercent: 12,
    questionsList: [
      {
        id: 0,
        title: "My direct manager provides clear and regular feedback on how well I do my work.",
        commentsCount: 1024,
        respondedCount: 3,
        benchmarkDiff: -2,
        benchmark: 49,
        allRespondentsPercent: 67,
        allRespondentsPercentDiff: 24,
        favourablePercent: 20,
        unFavourablePercent: 30,
        neutralPercent: 50,
        conditionalLogic: {
          segments: [
            ["Malaysia", "Singapore"],
            ["Finance", "Human resources"],
          ],
          responses: "9 OR 10",
        },
      },
    ],
  },
  {
    id: 4,
    dimension: "Growth",
    type: "dimension",
    benchmarkDiff: -2,
    benchmark: 49,
    allRespondentsPercent: 67,
    allRespondentsPercentDiff: 24,
    favourablePercent: 67,
    unFavourablePercent: 21,
    neutralPercent: 12,
    questionsList: [
      {
        id: 0,
        title: "My direct manager provides clear and regular feedback on how well I do my work.",
        commentsCount: 1024,
        respondedCount: 3,
        benchmarkDiff: -2,
        benchmark: 49,
        allRespondentsPercent: 67,
        allRespondentsPercentDiff: 24,
        favourablePercent: 0,
        unFavourablePercent: 98,
        neutralPercent: 2,
        conditionalLogic: null,
      },
    ],
  },
  {
    id: 5,
    dimension: "Manager’s support",
    type: "dimension",
    benchmarkDiff: -2,
    benchmark: 49,
    allRespondentsPercent: 67,
    allRespondentsPercentDiff: 24,
    favourablePercent: 67,
    unFavourablePercent: 21,
    neutralPercent: 12,
    questionsList: [
      {
        id: 0,
        title: "My direct manager provides clear and regular feedback on how well I do my work.",
        commentsCount: 1024,
        respondedCount: 3,
        benchmarkDiff: -2,
        benchmark: 49,
        allRespondentsPercent: 67,
        allRespondentsPercentDiff: 24,
        favourablePercent: 98,
        unFavourablePercent: 1,
        neutralPercent: 1,
        conditionalLogic: {
          segments: [
            ["Malaysia", "Singapore"],
            ["Finance", "Human resources"],
          ],
          responses: "9 OR 10",
        },
      },
    ],
  },
];

export const DASHBOARD_RESPONSES_OPEN_ENDED_ALL_QUESTIONS_LIST = [
  {
    id: 0,
    title: "What would you do to improve things here?",
    respondedCount: 120,
  },
  {
    id: 1,
    title: "What would you do to improve things here??",
    respondedCount: 45,
  },
  {
    id: 2,
    title: "What would you do to improve things here???",
    respondedCount: 67,
  },
  {
    id: 3,
    title: "What would you do to improve things here????",
    respondedCount: 89,
  },
];

export const DASHBOARD_RESPONSES_OPEN_ENDED_ALL_RESPONSES = [
  {
    id: 0,
    question: "What would you do to improve things here?",
    respondedCount: 120,
    graphData: {
      positivePercent: 24,
      positiveCount: 20,
      negativePercent: 12,
      negativeCount: 10,
      mixedPercent: 12,
      mixedCount: 10,
      neutralPercent: 16,
      neutralCount: 14,
      unavailablePercent: 16,
      unavailableCount: 16,
      allCount: 60,
    },
    responses: [
      {
        id: 0,
        type: "mixed",
        title: "My direct manager provides clear and regular feedback on how well I do my work.",
        autoTranslation: null,
        topic: ["Manager support"],
      },
      {
        id: 1,
        type: "neutral",
        title:
          "私たちが解決している問題をすべての人に思い出させ、そこにたどり着くまでの道のりを視覚化します。",
        autoTranslation:
          "Keep reminding everyone what problem we’re solving, and visualize the road to get there.",
        topic: ["Collaboration", "Teamwork"],
      },
      {
        id: 2,
        type: "unavailable_segment",
        title: "Gnarly",
        autoTranslation: null,
        topic: null,
      },
      {
        id: 3,
        type: "unavailable_segment",
        title:
          "到目前为止一切对我来说都还好，我唯一能想到的就是鼓励团队学习更多，可能是组织学习或阅读小组。 另一个想法是支持门票和培训假。",
        autoTranslation:
          "So far everything is okay to me, the only I can think of is encourage team to learn more, may be by organize learning or reading group. Another idea is supporting tickets and leaves for training.",
        topic: ["Learning & Development", "Training"],
      },
      {
        id: 4,
        type: "positive",
        title: "Radical",
        autoTranslation: null,
        topic: null,
      },
      {
        id: 5,
        type: "negative",
        title: "I’m not sure. My manager confuses me all the time.",
        autoTranslation: null,
        topic: null,
      },
      {
        id: 6,
        type: "mixed",
        title: "My direct manager provides clear and regular feedback on how well I do my work. ",
        autoTranslation: null,
        topic: null,
      },
    ],
  },
];

export const OPEN_ENDED_TOPICS_LIST = {
  countResponses: 493,
  topicsList: [
    {
      id: 0,
      title: "Collaboration",
      responsesCount: 16,
      withTopic: true,
    },
    {
      id: 1,
      title: "Environment",
      responsesCount: 8,
      withTopic: true,
    },
    {
      id: 2,
      title: "Humility",
      responsesCount: 4,
      withTopic: true,
    },
    {
      id: 3,
      title: "Innovation",
      responsesCount: 8,
      withTopic: true,
    },
    {
      id: 4,
      title: "Interior design",
      responsesCount: 6,
      withTopic: true,
    },
    {
      id: 5,
      title: "Lounge",
      responsesCount: 2,
      withTopic: true,
    },
    {
      id: 6,
      title: "Learning & Development",
      responsesCount: 11,
      withTopic: true,
    },
    {
      id: 7,
      title: "Office cat",
      responsesCount: 5,
      withTopic: true,
    },
    {
      id: 8,
      title: "Tea-time",
      responsesCount: 12,
      withTopic: true,
    },
    {
      id: 9,
      title: "Well-being",
      responsesCount: 10,
      withTopic: true,
    },
    {
      id: 10,
      title: "Responses with no topic",
      responsesCount: 16,
      withTopic: false,
    },
  ],
};

export const DEFAULT_TRENDS_CARD = {
  question_type: 0,
  trendAcross: "SURVEYS",
  score_type: "FAVORABLE",
  isCompareWithBenchmark: false,
  benchmark: null,
  whoSelected: null,
  selectedQuestion: null,
  selectedQuestionsList: null,
};

export const TRENDS_QUESTIONS_LIST = [
  {
    id: 0,
    dimension: "Autonomy",
    list: [
      {
        id: 0,
        title:
          "If you could change one thing to make your organisation a better place to work, what would it be?",
      },
      {
        id: 1,
        title: "How well do you fit into the company's culture in terms of the following?",
      },
      {
        id: 2,
        title:
          "If you could change one thing to make your organisation a better place to work, what would it be?",
      },
      {
        id: 3,
        title:
          "If you could change one thing to make your organisation a better place to work, what would it be?",
      },
      {
        id: 4,
        title:
          "If you could change one thing to make your organisation a better place to work, what would it be?",
      },
      {
        id: 5,
        title:
          "If you could change one thing to make your organisation a better place to work, what would it be?",
      },
      {
        id: 6,
        title:
          "If you could change one thing to make your organisation a better place to work, what would it be?",
      },
      {
        id: 7,
        title:
          "If you could change one thing to make your organisation a better place to work, what would it be?",
      },
      {
        id: 8,
        title:
          "If you could change one thing to make your organisation a better place to work, what would it be?",
      },
    ],
  },
  {
    id: 1,
    dimension: "Clear expectations",
    list: [
      {
        id: 9,
        title:
          "If you could change one thing to make your organisation a better place to work, what would it be?",
      },
      {
        id: 10,
        title: "How well do you fit into the company's culture in terms of the following?",
      },
      {
        id: 11,
        title:
          "If you could change one thing to make your organisation a better place to work, what would it be?",
      },
    ],
  },
  {
    id: 2,
    dimension: "Environment",
    list: [
      {
        id: 12,
        title:
          "If you could change one thing to make your organisation a better place to work, what would it be?",
      },
      {
        id: 13,
        title: "How well do you fit into the company's culture in terms of the following?",
      },
      {
        id: 14,
        title:
          "If you could change one thing to make your organisation a better place to work, what would it be?",
      },
      {
        id: 15,
        title:
          "If you could change one thing to make your organisation a better place to work, what would it be?",
      },
      {
        id: 16,
        title:
          "If you could change one thing to make your organisation a better place to work, what would it be?",
      },
    ],
  },
];

export const TRENDS_QUESTIONS = {
  singleSelect: [
    {
      id: 0,
      title: "How often do you think 1:1 reviews should happen?",
    },
    {
      id: 1,
      title: "What should we include in the welcome pack for new-joiners?",
    },
  ],
  multiple: [
    {
      id: 2,
      title: "What food options should we include for Friday lunch?",
    },
  ],
};

export const TRENDS_CARD_GRAPH_DATA = [
  {
    name: "Survey 2",
    respondents: 1210,
    value: 63,
  },
  {
    name: "Survey 3",
    respondents: 1240,
    value: 75,
  },
  {
    name: "Survey 4",
    respondents: 120,
    value: 23,
  },
  {
    name: "Survey 5",
    respondents: 12320,
    value: 16,
  },
  {
    name: "Survey 6",
    respondents: 1230,
    value: 87,
  },
];

export const TRENDS_CARD_GRAPH_DATA_WITH_BENCHMARK = [
  {
    name: "",
    data: [
      {
        name: "Survey 1",
        respondents: 1210,
        value: 62,
      },
      {
        name: "Survey 2",
        respondents: 1240,
        value: 23,
      },
      {
        name: "Survey 3",
        respondents: 1240,
        value: 89,
      },
      {
        name: "Survey 4",
        respondents: 1240,
        value: 28,
      },
      {
        name: "Survey 5",
        respondents: 1240,
        value: 75,
      },
    ],
  },
  {
    name: "",
    data: [
      {
        name: "Survey 1",
        respondents: 1210,
        value: 63,
      },
      {
        name: "Survey 2",
        respondents: 34,
        value: 34,
      },
      {
        name: "Survey 3",
        respondents: 23,
        value: 67,
      },
      {
        name: "Survey 4",
        respondents: 34,
        value: 12,
      },
      {
        name: "Survey 5",
        respondents: 67,
        value: 56,
      },
    ],
  },
];
