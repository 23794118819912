
const ActiveSeatsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M9 10.625C7.44686 10.625 6.1875 9.36564 6.1875 7.8125C6.1875 6.25936 7.44686 5 9 5C10.5531 5 11.8125 6.25936 11.8125 7.8125C11.8125 9.36564 10.5531 10.625 9 10.625ZM0 9.5C0 11.6973 0.790071 13.7088 2.09829 15.2722C3.43736 12.8377 6.0255 11.1875 9 11.1875C11.9745 11.1875 14.5626 12.8377 15.9017 15.2722C17.2099 13.7088 18 11.6973 18 9.5C18 4.52943 13.9706 0.5 9 0.5C4.02943 0.5 0 4.52943 0 9.5Z" fill="#707882" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.99937 11.1875C6.02487 11.1875 3.43673 12.8377 2.09766 15.2722C3.74851 17.2439 6.22673 18.5 8.99937 18.5C11.772 18.5 14.2502 17.2439 15.9011 15.2722C14.562 12.8377 11.9739 11.1875 8.99937 11.1875Z" fill="#F5F6FA" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 5C7.44686 5 6.1875 6.25936 6.1875 7.8125C6.1875 9.36564 7.44686 10.625 9 10.625C10.5531 10.625 11.8125 9.36564 11.8125 7.8125C11.8125 6.25936 10.5531 5 9 5Z" fill="#F5F6FA" />
      <path d="M17 8L11.75 10.9892V16.9892L17 20L22.25 16.9892V10.9892L17 8Z" fill="#FCC658" />
      <path d="M17 8V20L22.25 16.9892V10.9892L17 8Z" fill="#FFE19B" />
      <path d="M19.5373 11.3781C19.5096 11.2949 19.4264 11.2117 19.3432 11.184C18.1788 10.879 16.9866 11.2394 16.1825 12.1821L15.8776 12.5425L15.129 12.3762C14.7686 12.2375 14.3804 12.4039 14.214 12.7366L13.6041 13.8179C13.5486 13.9011 13.5486 13.9842 13.6041 14.0674C13.6318 14.1506 13.715 14.2061 13.7982 14.2338L14.6577 14.4279C14.5745 14.6497 14.5468 14.8715 14.4913 15.0933C14.4913 15.1764 14.519 15.2596 14.5745 15.3151L15.434 16.1746C15.4894 16.23 15.5449 16.2577 15.628 16.2577H15.6558C15.9053 16.23 16.1271 16.2023 16.3489 16.1191L16.5153 16.9509C16.543 17.034 16.5984 17.1172 16.6816 17.1449C16.7093 17.1727 16.7648 17.1727 16.7925 17.1727C16.848 17.1727 16.8757 17.1727 16.9311 17.1449L18.0124 16.535C18.3174 16.3686 18.4838 15.9805 18.4006 15.62L18.2065 14.8437L18.5392 14.5388C19.4541 13.7624 19.8423 12.5148 19.5373 11.3781ZM15.2953 13.2357C15.129 13.4575 14.9626 13.6793 14.8517 13.9011L14.2695 13.7624L14.6854 13.0139C14.7408 12.903 14.8517 12.8752 14.9904 12.903L15.4617 13.0139L15.2953 13.2357ZM17.7074 16.0359L16.9589 16.4518L16.848 15.8973C17.0975 15.7864 17.3193 15.62 17.5134 15.4537L17.7074 15.2596L17.8183 15.7309C17.8738 15.8696 17.7906 16.0082 17.7074 16.0359ZM17.9015 13.2357C17.6797 13.2357 17.4856 13.0416 17.4856 12.8198C17.4856 12.598 17.6797 12.4039 17.9015 12.4039C18.1233 12.4039 18.3174 12.598 18.3174 12.8198C18.3174 13.0416 18.1511 13.2357 17.9015 13.2357Z" fill="#8B10D5" />
    </svg>
  );
};

export default ActiveSeatsIcon;
