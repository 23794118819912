import { FC } from "react";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import { Box, Typography } from "@mui/material"
import { observer } from "mobx-react";
import { WarningIcon } from "./WarningIcon";

type NonTestPopupProps = {
  closePopup: () => void;
  disconnect: () => void;
};

const DisconnectHrisPopup: FC<NonTestPopupProps> = observer((props) => {
  return (
    <PopupWrapper>
      <Styledh2>Are you sure you want to disconnect your HRIS?</Styledh2>
      <BoxStyled>
        <Typography>
          If there are any changes made to the employee list when your HRIS is disconnected:
        </Typography>
      </BoxStyled>
      <BoxStyled>
        <Typography>
          For employees not found within HRIS, their details will remain unchanged on the EngageRocket platform.
        </Typography>
      </BoxStyled>
      <BoxStyled>
        <Typography>
          <WarningIcon style={{ marginBottom: "-7px" }} />For employees existing within HRIS, their attributes will be overwritten upon reconnection to HRIS.
        </Typography>
      </BoxStyled>

      <BtnBox>
        <Btn>
          <CustomButton
            variant="contained"
            onClick={() => { props.disconnect() }}
          >
            Disconnect HRIS
          </CustomButton>
        </Btn>
        <Btn>
          <CustomButtonStyled
            onClick={() => {
              props.closePopup()
            }}
            variant="outlined"
          >
            Cancel
          </CustomButtonStyled>
        </Btn>
      </BtnBox>

    </PopupWrapper>
  );
});

const PopupWrapper = styled.div`
`
const Styledh2 = styled.h2`
  margin: 70px 50px 0px 70px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
`
const BoxStyled = styled(Box)`
  margin: 40px 50px 0px 70px;
  width: 420px;
`
const BtnBox = styled(Box)`
  margin: 40px 50px 50px 70px;
  display: flex;
`
const Btn = styled.div`
  margin-right: 30px;
`
const CustomButtonStyled = styled(CustomButton)`
  border: 1px solid var(--colorNeutralForeground5, #959BA2) !important;
  border-radius: 4px;
  color:  var(--colorNeutralForeground2, #707882) !important;
  width: 90px;
  height: 34px;
`

export default DisconnectHrisPopup;
