import { FC, useState } from "react";
import { Formik, Form } from "formik";
import { useSearchParams } from "react-router-dom";

import * as Yup from "yup";

import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Input } from "./components/form/input/Input";
import { Link } from "./components/link/Link";
import Button from "components/button/Button"
import { Backdrop, CircularProgress } from "@mui/material";
import { requestResetPassword } from "api/go/useRequestResetPassword";

const schema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});


type ForgotPasswordScreenProps = {
  sendSubmittedDataToParent(email: string): void;
};

const ForgotPasswordScreen: FC<ForgotPasswordScreenProps> = (props: ForgotPasswordScreenProps) => {
  const [loading, setLoading] = useState(false);
  const [summarizedError, setSummarizedError] = useState<string | null>(null);

  const [searchParams] = useSearchParams();

  const submitForm = async (values: any, { setErrors }: any) => {
    setSummarizedError("");
    setLoading(true);

    try {
      await requestResetPassword(values.email);
      props.sendSubmittedDataToParent(values.email);
    } catch (error) {
      setSummarizedError("Something went wrong and we couldn’t process your request. Please try again. Contact support@engagerocket.co if the problem persists.");
    }

    setLoading(false)
  }

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 1000000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ForgotPasswordPageWrapper>
        <PageContainer>
          <CoverImageWrapper>
            <img src="assets/reset-link.png" alt="forgot-password" />
          </CoverImageWrapper>
          <FormContainer>
            <FormContainerContent>
              <StyledBackLink
                to={"/login?" + searchParams}
                text={
                  <LinkContent className="nav-item">
                    <ArrowBackIcon fontSize="small" />
                    <LinkText>Back to sign in page</LinkText>
                  </LinkContent>
                }
              />
              <Header className="header">Forgot your password?</Header>
              <Desc $isHaveError={Boolean(summarizedError)}>
                Enter your email address below and we’ll get you back on track.
              </Desc>
              <Formik initialValues={{ email: "" }} validationSchema={schema} onSubmit={submitForm}>
                <Form>
                  {Boolean(summarizedError) ? (
                    <Warning role="alert">{summarizedError}</Warning>
                  ) : null}
                  <Input label="Email address" name="email" id="email" />
                  <ButtonWrapper className="action">
                    <SendButton type="submit" disabled={loading} name="submit" variant="contained">
                      Send email to reset password
                    </SendButton>
                  </ButtonWrapper>
                </Form>
              </Formik>

              <BottomLogo className="bottom-logo">
                <img src="/assets/er-logo-light.png" alt="EngageRocket-logo" />
              </BottomLogo>
            </FormContainerContent>
          </FormContainer>
        </PageContainer>
      </ForgotPasswordPageWrapper>
    </>
  );
};

export default ForgotPasswordScreen;

const ForgotPasswordPageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  padding: 20px;
`;

const PageContainer = styled.div`
  width: 100%;
  height: 630px;
  max-width: 1080px;
  display: flex;
  background-color: var(--colorNeutralBackground1);
`;

const CoverImageWrapper = styled.div`
  width: 100%;
  max-width: 485px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 30px 20px 20px 0px;
  img {
    width: 276px;
    height: 260px;
  }
`;

const FormContainer = styled.div`
  padding: 91px 80px 48px 80px;
  width: 100%;
  max-width: 595px;
  position: relative;
`;

const FormContainerContent = styled.div`
  width: 100%;
  max-width: 340px;
  padding-bottom: 119px;
`;

const Header = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  color: var(--colorNeutralForeground1);
  margin: 55px 0px 25px 0px;
`;

const Desc = styled.h2<{ $isHaveError: boolean }>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--colorNeutralForeground2);
  margin-bottom: ${(props) => (props.$isHaveError ? "24px" : "56px")};
`;

const DescResult = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--colorNeutralForeground2);
`;

const BottomLogo = styled.div`
  position: absolute;
  bottom: 47px;
  margin-top: 82px;
  img {
    max-width: 184px;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`;

const StyledBackLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const SendButton = styled(Button)`
  width: 100%;
  height: 36px;
`;

const HrefButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
  height: 36px;
`;

const LinkContent = styled.div`
  display: flex;
  align-items: center;
`;

const LinkText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
  margin-left: 10px;
`;

const Warning = styled.div`
  color: var(--colorPaletteRedForeground2);
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 27px;
`;