import * as React from "react";
import styled from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "components/pagination/Pagination";
import { observer } from "mobx-react";
import CustomButton from "components/button/Button";
import { Typography } from "@mui/material";
import store from "../store";
import MultiSelectTags from "components/multiselect/MultiSelectTags";
import MultiSelectStrings from "components/multiselect/MultiSelectStrings";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import SortBlock from "components/sortBlock/SortBlock";

type CustomTableProps = {
  list: { [key: string]: any }[] | [];
  data: ITableData;
  countPerPage?: number;
  sort: string | null;
  hideAddButton: boolean;
  sortType: string | null;
  changeSort: (field: string, type: string) => void;
};

const CustomTable: React.FC<CustomTableProps> = observer((props) => {
  const [activePage, setActivePage] = React.useState(1);
  const [hoverIndex, setHoverIndex] = React.useState(null);
  const [countPerPage, setCountPerPage] = React.useState(
    props.countPerPage ? props.countPerPage : 10,
  );

  const changePageCount = (num: number) => {
    setActivePage(1);
    setCountPerPage(num);
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" id={"Survey_Table"}>
          <TableHead sx={{ bgcolor: "#E4E9FF" }} id={"Survey_Table_Head"}>
            <TableRow>
              {props.data.headers.map((header: TableHeader, i: number) => (
                <TableCell key={header.value + i}>
                  <HeaderWrapper $search={false} style={{ minHeight: "30px" }}>
                    <Row $search={false}>
                      <Title id={`Survey_Table_RowHead_${header.value}`}>{header.title}</Title>
                      {header.value === "tags" && (
                        <>
                          <MultiSelectTags
                            where="Survey_Table_Tags"
                            list={store.tags}
                            selected_list={store.selected_tags_ids}
                            onChange={(id: number) => {
                              store.changeTag(id);
                            }}
                          />
                        </>
                      )}
                      {header.value === "status" && (
                        <>
                          <MultiSelectStrings
                            where="Survey_Project_Table_Statuses"
                            list={store.statuses}
                            selected_list={store.selected_statuses}
                            onChange={(status: string) => {
                              store.changeStatus(status);
                            }}
                          />
                        </>
                      )}
                      {header.sort && (
                        <SortBlock
                          value={header.value}
                          field={store.sortField}
                          type={store.sortType}
                          onChange={store.changeSort}
                        />
                      )}
                    </Row>
                  </HeaderWrapper>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody id={"Survey_Table_Body"}>
            {props != null && props.list != null && props.list.length > 0 ? (
              <>
                {props.list
                  .slice(
                    (activePage - 1) * countPerPage,
                    (activePage - 1) * countPerPage + countPerPage,
                  )
                  .map((row: { [key: string]: any }, index) => (
                    <TableRow
                      id={`Survey_Table_Row_${index}`}
                      onMouseEnter={() => {
                        setHoverIndex(index);
                      }}
                      onMouseLeave={() => {
                        setHoverIndex(null);
                      }}
                      key={row.id}
                    >
                      {props.data.headers.map((el, index) => (
                        <TableCell component="th" scope="row" key={el.value + index}>
                          {el.renderRow ? (
                            el.renderRow(row, index === hoverIndex)
                          ) : (
                            <TableText>{row[el.value]}</TableText>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {props.list.length <= 0 && store.resultSearchValue === "" && (
        <StyledDiv>
          <TypographyStyled id={"Survey_Table_NoSurveyShared"}>
            There are currently no projects.{" "}
            {!props.hideAddButton && <span>Start by creating one.</span>}
          </TypographyStyled>
          {!props.hideAddButton && (
            <CustomButtonStyled
              id={"Survey_Table_CreateSurvey_Button"}
              disabled={!StorePermission.features.surveys.hasFeature || !StorePermission.entityPermissions.createAndEditSurvey.hasPermission}
              variant="contained"
              onClick={() => {
                store.changeOpenCreateProjectPopup(true);
              }}
            >
              + Create new project
            </CustomButtonStyled>
          )}
        </StyledDiv>
      )}

      {props.list.length <= 0 && store.resultSearchValue !== "" && (
        <StyledDiv>
          <Typography id={"Surveys_TableNoSurveys_SearchResult"}>
            No project or survey was found to match the provided details.
            <br />
            Please try using a different search keyword.
          </Typography>
        </StyledDiv>
      )}

      <PaginationWrapper>
        <PaginationText></PaginationText>
        <Pagination
          id={"Surveys_Table"}
          activePage={activePage}
          itemsCountPerPage={countPerPage}
          onChange={(e) => {
            setActivePage(e);
          }}
          pageRangeDisplayed={countPerPage}
          // rowsPerPageOptions={[5, 10, 25]}
          totalItemsCount={props.list.length}
          totalPages={props.list.length / countPerPage}
          setCount={changePageCount}
        />
      </PaginationWrapper>
    </>
  );
});

export interface ITableData {
  headers: TableHeader[];
}

export type TableHeader = {
  value: string;
  title: string;
  filter: boolean;
  sort: boolean;
  renderRow?: (row: any, isHovered: boolean) => any;
};

export default CustomTable;

const TableText = styled.p`
  margin: 0px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground1);
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const PaginationText = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.7384616136550903px;
  text-align: left;
  color: var(--colorNeutralForeground2);
  text-transform: uppercase;
`;

const IconWrapper = styled.span`
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  margin: 0px 2px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  :hover {
    background-color: var(--colorPalleteLightBlue);
  }
`;

const HeaderWrapper = styled.div<{ $search: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.$search ? "column" : "row")};
  align-items: center;
  justify-content: flex-start;
`;

const Title = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground2);
  margin: 0px;
`;

const Row = styled.div<{ $search: boolean }>`
  width: ${(props) => (props.$search ? "100%" : "fit-content")};
  display: flex;
  align-items: center;
`;

const StyledDiv = styled.div`
  width: 100%;
  height: 500px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const CustomButtonStyled = styled(CustomButton)`
  margin-top: 10px !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
`;
const TypographyStyled = styled(Typography)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #959ba2;
  margin-bottom: 10px;
`;
