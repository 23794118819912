import { localization } from "survey-creator-react"
import { ENPS_QUESTION_TYPE } from "./surveyJsConstants"
import { QUESTION_SETTING_CATEGORY } from "./surveyJsConstants"

export default function translationsOverrides(locale: string): any {
  const translations = localization.getLocale(locale)

  translations.pe.completedHtml = "Thank you message"
  translations.pe.description = "Description (Optional)"
  translations.pe.surveyDescriptionPlaceholder = "Write a welcome message"
  translations.ed.surveyPlaceHolder = ""
  translations.pe.tabs.rateValues = "Rating Scale Options"
  translations.pe.tabs[QUESTION_SETTING_CATEGORY] = "Question Settings"
  translations.pe.isRequired = "Required"
  translations.pe.rateCount = "Number of options needed"
  translations.pe.rateType = "Option types"
  translations.pe.rateValues = "Option labels"
  translations.pe.showCommentArea = "Recipients can add a comment"
  translations.pe.showOtherItem = "Allow users to select “Other”"
  translations.pe.showNoneItem = "Allow users to select “None”"
  translations.pe.showSelectAllItem = "Allow users to select all choices at once"
  translations.qt.boolean = "Yes/No"
  translations.qt.checkbox = "Multiple choices"
  translations.qt.comment = "Open-ended"
  translations.qt.rating = "Rating scale"
  translations.qt.radiogroup = "Single choice"
  translations.qt[ENPS_QUESTION_TYPE] = "eNPS"
  translations.pe.title = "Question"

  return translations
}
