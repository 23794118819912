import http from "../https";

export const useGetDaboardTrendsQuestions = (project_id: number) =>
  http.get(
    `/Dashboard/trend/questions`,
    {},
    {
      params: {
        project_id,
      },
    }
  );
