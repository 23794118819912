import http from "api/https"

export const getAttributesByEntity = (entity_id: number): Promise<any> => {
  if (http.isAPIGO)
    return http.getGO('/employee/entity_attributes/GetByentityWithValues_id?entity_id=' + entity_id)
  return http.get('/entity_attributes/GetByentityWithValues_id?entity_id=' + entity_id)
};

export const getAttributesByEntityForGrid = (entity_id: number): Promise<any> => {
  return http.get('/entity_attributes/GetByentityForEmployeeGrid?entity_id=' + entity_id)
};

export const getAttributesByEntityForFilter = (entity_id: number): Promise<any> => {
  if (http.isAPIGO)
    return http.getGO('/employee/entity_attributes/GetByentityWithValues_id?entity_id=' + entity_id)
  return http.get("/entity_attributes/GetByentityWithValues_id?entity_id=" + entity_id);
};

