const EmployeesIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_4298_31654)">
        <path d="M10.6667 7.33594C11.7733 7.33594 12.66 6.4426 12.66 5.33594C12.66 4.22927 11.7733 3.33594 10.6667 3.33594C9.55999 3.33594 8.66666 4.22927 8.66666 5.33594C8.66666 6.4426 9.55999 7.33594 10.6667 7.33594ZM5.33332 7.33594C6.43999 7.33594 7.32666 6.4426 7.32666 5.33594C7.32666 4.22927 6.43999 3.33594 5.33332 3.33594C4.22666 3.33594 3.33332 4.22927 3.33332 5.33594C3.33332 6.4426 4.22666 7.33594 5.33332 7.33594ZM5.33332 8.66927C3.77999 8.66927 0.666656 9.44927 0.666656 11.0026V12.6693H9.99999V11.0026C9.99999 9.44927 6.88666 8.66927 5.33332 8.66927ZM10.6667 8.66927C10.4733 8.66927 10.2533 8.6826 10.02 8.7026C10.7933 9.2626 11.3333 10.0159 11.3333 11.0026V12.6693H15.3333V11.0026C15.3333 9.44927 12.22 8.66927 10.6667 8.66927Z" fill="#6E31FF" />
      </g>
      <defs>
        <clipPath id="clip0_4298_31654">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmployeesIcon;
