import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
`;

export const FrequencyIcon = (
  props: React.HTMLAttributes<HTMLOrSVGElement>
) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" fill="none">
    <path d="M10.4 1.6H9.456C9.29095 1.13316 8.98554 0.728799 8.58165 0.442351C8.17776 0.155903 7.69516 0.00139161 7.2 0H5.6C5.10484 0.00139161 4.62224 0.155903 4.21835 0.442351C3.81446 0.728799 3.50905 1.13316 3.344 1.6H2.4C1.76348 1.6 1.15303 1.85286 0.702944 2.30294C0.252856 2.75303 0 3.36348 0 4V13.6C0 14.2365 0.252856 14.847 0.702944 15.2971C1.15303 15.7471 1.76348 16 2.4 16H10.4C11.0365 16 11.647 15.7471 12.0971 15.2971C12.5471 14.847 12.8 14.2365 12.8 13.6V4C12.8 3.36348 12.5471 2.75303 12.0971 2.30294C11.647 1.85286 11.0365 1.6 10.4 1.6ZM4.8 2.4C4.8 2.18783 4.88429 1.98434 5.03431 1.83431C5.18434 1.68429 5.38783 1.6 5.6 1.6H7.2C7.41217 1.6 7.61566 1.68429 7.76569 1.83431C7.91571 1.98434 8 2.18783 8 2.4V3.2H4.8V2.4ZM11.2 13.6C11.2 13.8122 11.1157 14.0157 10.9657 14.1657C10.8157 14.3157 10.6122 14.4 10.4 14.4H2.4C2.18783 14.4 1.98434 14.3157 1.83431 14.1657C1.68429 14.0157 1.6 13.8122 1.6 13.6V4C1.6 3.78783 1.68429 3.58434 1.83431 3.43431C1.98434 3.28429 2.18783 3.2 2.4 3.2H3.2V4C3.2 4.21217 3.28429 4.41566 3.43431 4.56569C3.58434 4.71571 3.78783 4.8 4 4.8H8.8C9.01217 4.8 9.21566 4.71571 9.36569 4.56569C9.51571 4.41566 9.6 4.21217 9.6 4V3.2H10.4C10.6122 3.2 10.8157 3.28429 10.9657 3.43431C11.1157 3.58434 11.2 3.78783 11.2 4V13.6Z" fill="#707882" />
  </StyledSvg>
);
