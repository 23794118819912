export const OVERAL_SCORE = 0;
export const ENPS = 1;
export const DIMENSION = 2;

export const BENCHMARK_FILTER_LIST = [
    {
        name: "Internal",
        value: "INTERNAL",
    },
    {
        name: "External",
        value: "EXTERNAL",
    },
    {
        name: "All companies",
        value: "EXTERNAL_ALL_COMPANIES",
    },
    {
        name: "Industry",
        value: "EXTERNAL_INDUSTRY",
    },
    {
        name: "Country",
        value: "EXTERNAL_COUNTRY",
    },
    {
        name: "Industry in Country",
        value: "EXTERNAL_INDUSTRY_IN_COUNTRY",
    },
];

export const PROMOTERS = "Promoters";
export const PASSIVES = "Passives";
export const DETRACTORS = "Detractors";

export const FAVOURABLE = "Favorable";
export const NEUTRAL = "Neutral";
export const UNFAVOURABLE = "Unfavorable";

export const FAVOURABLE_VALUE = 0;
export const NEUTRAL_VALUE = 3;
export const UNFAVOURABLE_VALUE = 1;

export const PROMOTERS_VALUE = 0;
export const PASSIVES_VALUE = 3;
export const DETRACTORS_VALUE = 1;
