import { makeAutoObservable, reaction } from "mobx";
import { addOrUpdateAttribute } from "api/go/useAddOrEditAttribute";
import { getAttribute } from "api/go/useGetAttribute";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import MainStore from "MainStore";
import storeAttribute from './../store'
import { EmployeeAttribute } from "types/employee";
import { checkUniqueAttributeName } from "api/go/useCheckUniqueAttributeName";

class Store {
  id: number = 0;
  name = "";
  errorName = "";
  isCheckingDelay = false;

  attribute: EmployeeAttribute = null;

  isDateAttribute: boolean = false;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.name,
      (newName) => {
        this.delayedCheckNameUniqueness(newName);
      },
      { delay: 500 }
    );

    reaction(
      () => [storeAttribute.openPanel],
      () => {
        if (this.id && storeAttribute.openPanel) {
          this.getAttributeData();
        }
      }
    );
  }

  // rewrite with yup
  changeFieldAttributeValue = (target: any) => {
    this.changeValue("isCheckingDelay", true);
    if (!target.value.trim()) {
      this.seterrorName("Name is a required field");
    } else if (!target.validity.valid) {
      this.seterrorName(
        "Please provide a valid attribute name that contains alphanumeric characters."
      );
    } else {
      this.seterrorName("");
    }
    this.name = target.value;
  }

  changeValue = (name: string, value: any) => {
    if (name === "name") {
      this.changeValue("isCheckingDelay", true)
      if (!value.trim()) {
        this.seterrorName("Name is a required field");
      } else {
        this.seterrorName("");
      }
    }
    this[name] = value;
  };

  clearStore = () => {
    this.id = 0;
    this.attribute = null;
    this.name = "";
    this.errorName = "";
    this.isDateAttribute = false;
  };

  setData = (attribute: EmployeeAttribute) => {
    this.attribute = attribute;
    this.name = attribute.name;
    this.isDateAttribute = attribute.is_date_attribute;
  };

  seterrorName = (error: string) => {
    this.errorName = error;
  };

  private async delayedCheckNameUniqueness(newName: string) {
    if (this.errorName !== "") return
    try {
      const response = await checkUniqueAttributeName(StoreLayout.currentEntityId, newName, this.id);
      if ((response.status === 201 || response.status === 200) && response?.data !== undefined) {
        if (response.data === true) {
          this.seterrorName("");
        } else {
          this.seterrorName(
            "The attribute already exists. If the attribute is a different type, please assign it a distinctive name."
          );
        }
        this.changeValue("isCheckingDelay", false)
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }

  getAttributeData = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getAttribute(this.id);
      if ((response.status === 201 || response.status === 200) && response?.data !== undefined) {
        this.setData(response.data);
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  // check = (): boolean => {
  //   storeAttribute.data.filter(x => x.)
  //   return true;
  // }

  async save(props) {
    const data = {
      id: this.attribute?.id,
      name: this.name,
      type: this.attribute?.type,
      is_date_attribute: this.isDateAttribute,
      entity_id: StoreLayout.currentEntityId,
      id_attributetype: this.attribute?.id_attributetype,
      id_parentAttr: this.attribute?.id_parentAttr,
    };
    try {
      MainStore.changeLoader(true);
      if (data.name !== "") {
        const response = await addOrUpdateAttribute(data);
        if ((response.status === 201 || response.status === 200) && response?.data !== undefined) {
          if (this.attribute === null) {
            MainStore.setSnackbar("Attribute successfully added")
          } else {
            MainStore.setSnackbar("Attribute successfully updated")
          }
          props.onSaved();
          this.clearStore();
        } else if (response.response?.status === 422) {
          let errDetail = response.response?.data.details?.find((d: { field: string; }) => d.field == 'ImportLock');
          if (errDetail?.message) {
            MainStore.setSnackbar(errDetail.message, "error");
          } else {
            throw new Error();
          }
        } else {
          throw new Error();
        }
      } else {
        throw new Error();
      }

    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }
}

const store = new Store();
export default store;
