import http from "api/https";
import { createUrl } from "../helpers/url";

export const getRecepientsMilestone = (
  survey_id: number,
  search: string,
  perPage: number,
  numberPage: number
): Promise<any> => {
  const query: { survey_id: string; search: string; perPage: string; numberPage: string } = {
    survey_id: survey_id.toString(),
    search: search,
    perPage: perPage.toString(),
    numberPage: numberPage.toString(),
  };
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/sm/sm_survey_recepients/GetRecepientsMilestone",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/employees/GetRecepientsMilestone",
      query: query,
    });
    return http.get(url);
  }
};
