import http from "api/https"
import { createUrl } from "../helpers/url";

export const checkUniqueProjectName = (entity_id: number, name: string, project_id: number): Promise<any> => {
  const query: { entity_id: string; name: string; project_id: string } = {
    entity_id: entity_id.toString(),
    name: name,
    project_id: project_id.toString(),
  };

  if (http.isAPIGO) {
    const url = createUrl({
      path: "/sm/sm_project/CheckUniqueProjectName",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/sm_project/CheckUniqueProjectName",
      query: query,
    });
    return http.get(url);
  }
};
