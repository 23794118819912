import { makeAutoObservable } from "mobx";
import { deleteRoleWithUsers } from "../../../../api/go/useDeleteRoleWithUsers";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import MainStore from "MainStore";

class Store {
  errornew_new_idRole = "";
  changeRolePanel = false;
  countUsers = 0;
  roleName = "";

  constructor() {
    makeAutoObservable(this);
  }

  changeValue = (name: string, value: any) => {
    this[name] = value;
  };

  clearStore() {
    this.errornew_new_idRole = "";
    this.roleName = "";
  }

  onRoleChanged = () => {
    this.changeChangeRolePanel(false);
  };

  changeChangeRolePanel = (flag: boolean) => {
    this.changeRolePanel = flag;
  };

  async deleteRoleSubmit(idRole: number, onSaved: (id: number) => void) {
    try {
      MainStore.changeLoader(true);
      const response = await deleteRoleWithUsers(
        idRole,
        StoreLayout.currentWorkspaceId,
        "workspace",
        StoreLayout.currentRegionId
      );
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        onSaved(response.data);
        MainStore.setSnackbar(`“${this.roleName}” has been deleted.`);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar(`Something went wrong...`, "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }
}

const store = new Store();
export default store;
