import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
`;

export const EditIcon = (
  props: React.HTMLAttributes<HTMLOrSVGElement>
) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M0 9.50167V12.0017H2.5L9.87333 4.62833L7.37333 2.12833L0 9.50167ZM11.8067 2.695C12.0667 2.435 12.0667 2.015 11.8067 1.755L10.2467 0.195C9.98667 -0.065 9.56667 -0.065 9.30667 0.195L8.08667 1.415L10.5867 3.915L11.8067 2.695Z" fill="#1477F8" />
  </StyledSvg>
);
