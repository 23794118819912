import React, { FC } from "react";
import styled from "styled-components";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type SortBlockProps = {
  value: string;
  field: string;
  type: string;
  onChange: (field: string, type: string) => void;
};

enum SORT_STATUS {
  Asc = "asc",
  Desc = "desc",
}

const SortBlock: FC<SortBlockProps> = (props) => {
  const handlerArrowIconStyle = (sort: string) => {
    if (props.field === props.value && props.type === sort) {
      return { color: "var(--colorBrandForeground1)" };
    }
    return null;
  };

  return (
    <BlockSortStyle>
      <KeyboardArrowUpIconStyle
        id={`EmployeesList_Grid_Column_FullName_Sort_Asc`}
        style={handlerArrowIconStyle(SORT_STATUS.Asc)}
        onClick={() => props.onChange(props.value, "asc")}
      />
      <PlugStyle />
      <KeyboardArrowDownIconStyle
        id={`EmployeesList_Grid_Column_FullName_Sort_Desc`}
        style={handlerArrowIconStyle(SORT_STATUS.Desc)}
        onClick={() => props.onChange(props.value, "desc")}
      />
    </BlockSortStyle>
  );
};

export default SortBlock;

const BlockSortStyle = styled.span`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 10px;
  height: 20px;
  top: 0;
  left: 14px;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
`;

const KeyboardArrowUpIconStyle = styled(KeyboardArrowUpIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: -6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: 6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const PlugStyle = styled.div`
  width: 20px;
  height: 8px;
  background-color: transparent;
  position: absolute;
  top: 6px;
  left: -5px;
  z-index: 999;
`;
