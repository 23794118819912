import { FC } from "react";

interface RemoveIconProps {
  onClick?: () => void;
}

const RemoveIcon: FC<RemoveIconProps> = ({ onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <path
        d="M6.38159 5.56587L10.3162 1.63705C10.4338 1.51943 10.4999 1.35991 10.4999 1.19358C10.4999 1.02724 10.4338 0.867717 10.3162 0.7501C10.1986 0.632483 10.0391 0.566406 9.87281 0.566406C9.70649 0.566406 9.54698 0.632483 9.42938 0.7501L5.50098 4.68517L1.57257 0.7501C1.45497 0.632483 1.29546 0.566406 1.12915 0.566406C0.962827 0.566406 0.803321 0.632483 0.685717 0.7501C0.568112 0.867717 0.502043 1.02724 0.502043 1.19358C0.502043 1.35991 0.568112 1.51943 0.685717 1.63705L4.62037 5.56587L0.685717 9.4947C0.627179 9.55276 0.580716 9.62184 0.549009 9.69796C0.517301 9.77407 0.500977 9.85571 0.500977 9.93817C0.500977 10.0206 0.517301 10.1023 0.549009 10.1784C0.580716 10.2545 0.627179 10.3236 0.685717 10.3816C0.743776 10.4402 0.812852 10.4867 0.888959 10.5184C0.965066 10.5501 1.0467 10.5664 1.12915 10.5664C1.21159 10.5664 1.29322 10.5501 1.36933 10.5184C1.44544 10.4867 1.51451 10.4402 1.57257 10.3816L5.50098 6.44658L9.42938 10.3816C9.48744 10.4402 9.55651 10.4867 9.63262 10.5184C9.70873 10.5501 9.79036 10.5664 9.87281 10.5664C9.95526 10.5664 10.0369 10.5501 10.113 10.5184C10.1891 10.4867 10.2582 10.4402 10.3162 10.3816C10.3748 10.3236 10.4212 10.2545 10.4529 10.1784C10.4847 10.1023 10.501 10.0206 10.501 9.93817C10.501 9.85571 10.4847 9.77407 10.4529 9.69796C10.4212 9.62184 10.3748 9.55276 10.3162 9.4947L6.38159 5.56587Z"
        fill="#3E4450"
      />
    </svg>
  );
};

export default RemoveIcon;
