import { FC } from "react";
import { observer } from "mobx-react"
import styled from "styled-components";
import store from "./storeSurvey"
import { ToRightIcon } from "./ToRightIcon";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


type LeftAccordionsProps = {
};

const LeftAccordions: FC<LeftAccordionsProps> = observer((props) => {

  return (
    <MainBox id={"SelectTemplateLefAccordions"}>
      <SurveyProjectTitle id={"SelectTemplateAllTemplates"} onClick={() => store.onOpenTemplate()}>
        All Templates
        <StyledIcon>
          {store.isTemplateOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </StyledIcon>
      </SurveyProjectTitle>

      {store.isTemplateOpen && (<ProjectList>
        {store.templateType !== "communication" && <ProjectBlock id={"SelectTemplateEngageRocketButton"} onClick={() => { store.onClickBtn("engageRocket", "all") }} $selected={store.navUrl === "engageRocket"}>
          <ProjectText id={"SelectTemplateEngageRocketLabel"}>
            <SelectedProject $selected={store.navUrl === "engageRocket"} />
            EngageRocket templates
          </ProjectText>
          <StyledIcon>
            <ToRightIcon />
          </StyledIcon>
        </ProjectBlock>}
        <ProjectBlock id={"SelectTemplateSharedButton"} onClick={() => { store.onClickBtn("shared", "all") }} $selected={store.navUrl === "shared"}>
          <ProjectText id={"SelectTemplateSharedLabel"}>
            <SelectedProject $selected={store.navUrl === "shared"} />
            Shared templates
          </ProjectText>
          <StyledIcon>
            <ToRightIcon />
          </StyledIcon>
        </ProjectBlock>
        <ProjectBlock id={"SelectTemplateEntityButton"} onClick={() => { store.onClickBtn("entity", "all") }} $selected={store.navUrl === "entity"}>
          <ProjectText id={"SelectTemplateEntityLabel"}>
            <SelectedProject $selected={store.navUrl === "entity"} />
            Entity templates
          </ProjectText>
          <StyledIcon>
            <ToRightIcon />
          </StyledIcon>
        </ProjectBlock>
      </ProjectList>)}

      {store.templateType !== "communication" && <SurveyProjectTitle id={"SelectTemplateQuestionLibrary"} onClick={() => store.onOpenLibrary()}>
        Question library
        <StyledIcon>
          {store.isLibraryOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </StyledIcon>
      </SurveyProjectTitle>}

      {store.isLibraryOpen && store.templateType !== "communication" && (<ProjectList>
        {store.typeDimensions.filter(z => z.id != 0).map(x =>
          <ProjectBlock className={"SelectTemplateQuestionLibrary"} id={"SelectTemplateQuestionLibrary_" + x.id} key={x.id} onClick={() => {
            store.onSetQUrl(x.name)
            store.onClickQuestionBtn("question", "all", x.id)
          }} $selected={store.qUrl === x.name}>
            <ProjectText className={"SelectTemplateQuestionLibraryLabel"} id={"SelectTemplateQuestionLibraryLabel_" + x.id}>
              <SelectedProject $selected={store.qUrl === x.name} />
              {x.name}
            </ProjectText>
            <StyledIcon>
              <ToRightIcon />
            </StyledIcon>
          </ProjectBlock>)}
      </ProjectList>)}
    </MainBox>
  );
})

const StyledIcon = styled.div`
  width: 7px;
`

const MainBox = styled.div`
  width: 100%;
  max-width: 247px;
  background-color: var(--colorNeutralBackground1);
  height: 700px;
  overflow-y: auto;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  /* Styles for scrollbar */
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 5px;
  }
`

const SelectedProject = styled.div <{ $selected: boolean, }>`
  width: 6px;
  height: 30px;
  background-color: ${(props) => (props.$selected ? "#7562FC" : "#EEEFFF")};
  margin-right: 15px;
`

const ProjectText = styled.div`
  display: flex;
  align-items: center;

`

const ProjectBlock = styled.div <{ $selected: boolean, }>`
  border-bottom: 1px solid #F1F3F8;
  padding: 20px 20px 20px 0px;
  color: var(--ER-Theme-sys-light-on-tertiary-container, #707882);
  font-family: Roboto;
  font-size: 14px;
  
  background-color: ${(props) => (props.$selected ? "#EEEFFF" : "#F9FAFC")};
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #EEEFFF;
  }
`
const ProjectList = styled.div`
`

const SurveyProjectTitle = styled.div`
  background-color: #CDD3EC;
  padding: 10px 20px;
  color: var(--ER-Theme-sys-light-on-tertiary-container, #707882);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 20px; /* 142.857% */
`


export default LeftAccordions;

