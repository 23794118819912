import { FC, useState } from "react";

import Dialog from "@mui/material/Dialog";
import styled from "styled-components";
import DialogContent from "@mui/material/DialogContent";
import CustomButton from "components/button/Button";
import { TextField } from "@mui/material";

type DeleteConfirmProps = {
  open: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  children: any;
  removeSubmitText?: string;
};

const DeleteConfirm: FC<DeleteConfirmProps> = (props) => {
  const [value, setValue] = useState("");

  return (
    <StyledDialog
      onClose={() => props.onCancel()}
      aria-labelledby="customized-dialog-title"
      fullWidth
      id={"DeleteTemplateCustomModal"}
      open={props.open}
    >
      <CloseModal id={"DeleteTemplateClosePopup"} onClick={() => props.onCancel()} />
      <StyledDialogContent id={"DeleteTemplateForm"}>

        {props.children}
        <DescriptionDelete id={"DeleteTemplateInputLabel"}>
          To confirm your deletion, please enter DELETE below:
        </DescriptionDelete>

        <WrappedTextField>
          <TextField
            id={"DeleteTemplateInput"}
            value={value}
            onChange={(e) => { setValue(e.target.value) }}
            fullWidth
            variant="standard"
            placeholder="DELETE"
          />
        </WrappedTextField>


        <ButtonWrapper>
          <ButtonWr>
            <StyledNextButton
              variant="contained"
              id={"DeleteTemplateDeleteButton"}
              disabled={value !== "DELETE"}
              onClick={() => {
                props.onSubmit()
                setValue("")
              }}
            >
              {props.removeSubmitText ? props.removeSubmitText : "Delete"}
            </StyledNextButton>
          </ButtonWr>
          <ButtonWr>
            <StyledCancelButton
              variant="outlined"
              id={"DeleteTemplateCancelButton"}
              onClick={() => {
                props.onCancel()
              }}
            >
              Cancel
            </StyledCancelButton>
          </ButtonWr>
        </ButtonWrapper></StyledDialogContent>

    </StyledDialog>
  );
};

export default DeleteConfirm;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 10px;
  }
`;

const WrappedTextField = styled.div`
  margin: 20px 0;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0 0 0;
`;
const DescriptionDelete = styled.div`
  font-family: Roboto, sans-serif;
  margin-top: 20px;
`

const ButtonWr = styled.div`
margin-right: 20px;
`;

const StyledCancelButton = styled(CustomButton)`
  width: 100%;
  max-width: 75px;
  margin-left: 160px;
`;

const StyledNextButton = styled(CustomButton)`
  width: 100%;
  margin-right: 20px;
  max-width: 150px;
`;

const CloseModal = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 38px;
  right: 30px;
  width: 24px;
  height: 24px;
  font-size: 16px;
  opacity: 0.6;
  transition: opacity ease 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 10px;
    display: block;
    width: 18px;
    height: 3px;
    background: var(--colorBrandForeground1);
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

const StyledDialogContent = styled(DialogContent)`
  margin: 50px 40px 50px 40px;
`;
