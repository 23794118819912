import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled as styledMui } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import { PermissionIcons } from "./mock";
import { Link as RouterLink } from "react-router-dom";
import "./ScrollContainer.css";
import store from "./store";
import { observer } from "mobx-react";
import BusinessIcon from "@mui/icons-material/Business";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import StarsIcon from "@mui/icons-material/Stars";
import SettingsIcon from "@mui/icons-material/Settings";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { DownIcon } from "./Icons/DownIcon";
import { UpperIcon } from "./Icons/UpperIcon";
import { FolderIcon } from "./Icons/FolderIcon";
import styled, { css } from "styled-components";
import { AdminIcon } from "./Icons/AdminIcon";
import { RoleIcon } from "./Icons/RoleIcon";
import InfoIcon from "@mui/icons-material/Info";
import CustomSwitch from "components/switch/Switch";
import { DEFAULT_ROLES } from "constants/default-roles";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { ENTITY_PERMISSIONS, PROJECT_PERMISSIONS } from "constants/permissions";
import { LevelPermission } from "types/permission";

type TablePermissionProps = {
  changeTab: (tab: string) => void;
};

const BasicTable: React.FC<TablePermissionProps> = observer((props) => {
  return (
    <>
      <TableContainerWrapper component={Paper} className="parent">
        <TableWrapper className="child" aria-label="simple table">
          <TableHead id={`EntityPermissions_Roles_Grid_Table_Head`}>
            <TableRow>
              <EmptyTableCellNav></EmptyTableCellNav>

              {store.roles.map((role, indexRole) => {
                return (
                  <TableCellRoles
                    id={`EntityPermissions_Roles_Grid_Table_${indexRole}`}
                    align="left" key={role.id}>
                    <RouterLink
                      id={`EntityPermissions_Roles_Grid_Table_Link_${indexRole}`}
                      to={"/entityPermissions?nav=user&idRole=" + role.id}>
                      <FlexAlignCenter>
                        {(role.is_default || role.code === DEFAULT_ROLES.managers || role.code === DEFAULT_ROLES.unit_heads)
                          ? <AdminIcon /> : <RoleIcon />}
                        <RoleName id={`EntityPermissions_Roles_Grid_Table_Name_${indexRole}`}>
                          {role.name} ({role.countUsers})
                        </RoleName>
                      </FlexAlignCenter>
                    </RouterLink>
                  </TableCellRoles>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody id={`EntityPermissions_Roles_Grid_Table_Body`}>
            {store.permissionData.map((row, mainRow) => (
              <React.Fragment key={row.id}>
                <StyledTableRow id={`EntityPermissions_Roles_Grid_mainRow_${mainRow}`}>
                  <TableCellCategoryName>
                    <FlexAlignCenter>
                      {PermissionIcons.filter((x) => x.code === row.code).length > 0 ? (
                        <PermissionName id={`EntityPermissions_EntityRoles_Grid_PermissionsName_${mainRow}_id_${row.id}`}>
                          {PermissionIcons.filter((x) => x.code === row.code)[0].icon}
                        </PermissionName>
                      ) : (
                        ""
                      )}
                      {row.name}
                    </FlexAlignCenter>
                  </TableCellCategoryName>
                  {store.roles.map((x) => (
                    <TableCellEmptyCategory
                      align="right" key={x.id}></TableCellEmptyCategory>
                  ))}
                </StyledTableRow>

                {row.permissions.map((value, rowIndex) => {
                  return (
                    <TableRow
                      id={`EntityPermissions_Roles_Grid_Row_${rowIndex}_mainRow_${mainRow}_row_${row.id}`}
                      key={value.id}>
                      <TableCellPermName>
                        <FlexAlignCenter id={`EntityPermissions_Roles_Grid_Row_Table_0_${rowIndex}_mainRow_${mainRow}_row_${row.id}`}>
                          {PermissionIcons.filter((x) => x.code === value.code).length > 0 ? (
                            <PermissionName>
                              {PermissionIcons.filter((x) => x.code === value.code)[0].icon}
                            </PermissionName>
                          ) : (
                            ""
                          )}

                          {value.name}
                        </FlexAlignCenter>
                      </TableCellPermName>
                      {store.roles.map((role, indexTable) => {
                        let isActive =
                          value.level_permissions.filter((x) => x.role_id === role.id).length != 0;
                        let isAlways = role.is_default;

                        let disabled = CheckDisabledSwitch(value.code, row.permissions, role.id);
                        let childPermissionId: number = GetChildPermissionID(value.code, row.permissions, role.id);
                        return (
                          <TableCellSwitch
                            id={`EntityPermissions_Roles_Grid_Row_Table_${indexTable}_${rowIndex}_mainRow_${mainRow}_row_${row.id}`}
                            key={role.id} align="left">
                            {isAlways ? (
                              <AlwaysOn id={`EntityPermissions_EntityRoles_Grid_TableText_Permissions_${indexTable + 1}_${mainRow}_id_${row.id}`}>Always on</AlwaysOn>
                            ) : (
                              <CustomSwitch
                                id={`EntityPermissions_Roles_Grid_Row_TableSwitch_${indexTable}_${rowIndex}_mainRow_${mainRow}_row_${row.id}`}
                                checked={isActive}
                                disabled={disabled}
                                onChange={(e) => {
                                  let roleId = role.id;
                                  let permissionId = value.id;
                                  store.changePermission(
                                    roleId,
                                    permissionId,
                                    e.target.checked,
                                    StoreLayout.currentEntityId,
                                    "entity",
                                    childPermissionId
                                  );
                                }}
                              />
                            )}
                          </TableCellSwitch>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </React.Fragment>
            ))}

            <StyledTableRow id={`EntityPermissions_Roles_Grid_FooterRow_0`}>
              <TableCellProject component="th" scope="row">
                <div
                  style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                >
                   <TitleSurvey>
                    <FolderOpenIconStyle />
                       Survey projects
                    </TitleSurvey>
                  {store.isProjectsExpanded ? (
                    <UpperIcon
                      id={`EntityPermissions_Roles_Grid_FooterRow_0_UpperBtn`}
                      onClick={() => {
                        store.changeProjectExpanded();
                      }}
                    />
                  ) : (
                    <DownIcon
                      id={`EntityPermissions_Roles_Grid_FooterRow_0_DownBtn`}
                      onClick={() => {
                        store.changeProjectExpanded();
                      }}
                    />
                  )}
                </div>
              </TableCellProject>
              {store.roles.map((x) => (
                <TableCellProjectEmpty align="right" key={x.id}></TableCellProjectEmpty>
              ))}
            </StyledTableRow>

            {store.isProjectsExpanded &&
              store.projects.map((project, indexProject) => (
                <React.Fragment key={project.id}>
                  <TableRow
                    id={`EntityPermissions_Roles_Grid_FooterRow_${indexProject + 1}`}>
                    <TableCellPermName scope="row">
                      <ProjectName id={`EntityPermissions_Roles_Grid_FooterRow_Project_${indexProject + 1}`}>
                        <ContainerNameSurvey>
                          <InsertDriveFileIconStyle />
                          {project.name}
                        </ContainerNameSurvey>
                        {store.expandedProjects.includes(project.id) ? (
                          <UpperIcon
                            id={`EntityPermissions_Roles_Grid_FooterRow_Project_UpperBtn_${indexProject + 1}`}
                            onClick={() => {
                              store.closeProjectPermissionExpanded(project.id);
                            }}
                          />
                        ) : (
                          <DownIcon
                            id={`EntityPermissions_Roles_Grid_FooterRow_Project_DownBtn_${indexProject + 1}`}
                            onClick={() => {
                              store.openProjectPermissionExpanded(project.id);
                            }}
                          />
                        )}
                      </ProjectName>
                    </TableCellPermName>
                    {store.roles.map((x) => (
                      <TableCellRoles key={x.id} align="left"></TableCellRoles>
                    ))}
                  </TableRow>

                  {store.expandedProjects.includes(project.id) &&
                    project.permissions.map((value, indexRow) => {
                      return (
                        <TableRow id={`EntityPermissions_Roles_Grid_FooterRow_Project_Row_${indexRow}_${indexProject + 1}_row_${value.id}`} key={value.id}>
                          <TableCellPermName>
                            <ProjectPermName id={`EntityPermissions_Roles_Grid_FooterRow_Project_Row_Table_0_${indexRow}_${indexProject + 1}_row_${value.id}`}>
                              {value.name}
                              {value.description && value.description !== "" && (
                                <Tooltip title={value.description}>
                                  <StyledInfoIcon />
                                </Tooltip>
                              )}
                            </ProjectPermName>
                          </TableCellPermName>
                          {store.roles.map((role, indexTable) => {
                            let isActive =
                              value.level_permissions.filter((x) => x.role_id === role.id).length !=
                              0;
                            let isAlways = role.is_default;
                            let disabled = CheckDisabledProjectSwitch(value.code, project.permissions, role.id);
                            let childPermissionId: number = GetChildProjectPermissionID(value.code, project.permissions, role.id);
                            return (
                              <TableCellSwitch id={`EntityPermissions_Roles_Grid_FooterRow_Project_Row_Table_${indexTable + 1}_${indexRow}_${indexProject + 1}_row_${value.id}`} key={role.id}>
                                {isAlways ? (
                                  <AlwaysOn>Always on</AlwaysOn>
                                ) : (
                                  <CustomSwitch
                                    id={`EntityPermissions_Roles_Grid_FooterRow_Project_Row_TableSwitch_${indexTable + 1}_${indexRow}_${indexProject + 1}_row_${value.id}`} key={role.id}
                                    checked={isActive}
                                    disabled={disabled}
                                    onChange={(e) => {
                                      let roleId = role.id;
                                      let permissionId = value.id;
                                      store.changePermission(
                                        roleId,
                                        permissionId,
                                        e.target.checked,
                                        project.id,
                                        "project",
                                        childPermissionId
                                      );
                                    }}
                                  />
                                )}
                              </TableCellSwitch>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </React.Fragment>
              ))}
          </TableBody>
        </TableWrapper>
      </TableContainerWrapper>
    </>
  );
});


const CheckDisabledSwitch = (code: string, levelPermissions: LevelPermission[], roleID: number): boolean => {
  if (code === ENTITY_PERMISSIONS.viewEmployee.code) {
    const editEmployeePermission = levelPermissions.find(x => x.code === ENTITY_PERMISSIONS.editEmployee.code)
    if (editEmployeePermission.level_permissions.find(x => x.role_id === roleID) !== undefined) {
      return true
    }
  } else if (code === ENTITY_PERMISSIONS.viewSurveySettings.code) {
    const createAndEditSurveyPermission = levelPermissions.find(x => x.code === ENTITY_PERMISSIONS.createAndEditSurvey.code)
    if (createAndEditSurveyPermission.level_permissions.find(x => x.role_id === roleID) !== undefined) {
      return true
    }
  } else if (code === ENTITY_PERMISSIONS.viewEntityPermissions.code) {
    const editEntityPermissionsPermission = levelPermissions.find(x => x.code === ENTITY_PERMISSIONS.editEntityPermissions.code)
    if (editEntityPermissionsPermission.level_permissions.find(x => x.role_id === roleID) !== undefined) {
      return true
    }
  }
  return false
}

const GetChildPermissionID = (code: string, levelPermissions: LevelPermission[], roleID: number): number => {
  if (code === ENTITY_PERMISSIONS.editEmployee.code) {
    const viewEmployeePermission = levelPermissions.find(x => x.code === ENTITY_PERMISSIONS.viewEmployee.code)
    if (viewEmployeePermission.level_permissions.find(x => x.role_id === roleID) === undefined) {
      return viewEmployeePermission?.id
    }
  } else if (code === ENTITY_PERMISSIONS.createAndEditSurvey.code) {
    const viewSurveySettingsPermission = levelPermissions.find(x => x.code === ENTITY_PERMISSIONS.viewSurveySettings.code)
    if (viewSurveySettingsPermission.level_permissions.find(x => x.role_id === roleID) === undefined) {
      return viewSurveySettingsPermission?.id
    }
  } else if (code === ENTITY_PERMISSIONS.editEntityPermissions.code) {
    const viewEntityPermissionsPermission = levelPermissions.find(x => x.code === ENTITY_PERMISSIONS.viewEntityPermissions.code)
    if (viewEntityPermissionsPermission.level_permissions.find(x => x.role_id === roleID) === undefined) {
      return viewEntityPermissionsPermission?.id
    }
  }
  return null
}

const CheckDisabledProjectSwitch = (code: string, levelPermissions: LevelPermission[], roleID: number): boolean => {
  if (code === PROJECT_PERMISSIONS.viewSurveySettingsProject.code) {
    const createAndEditProjectPermission = levelPermissions.find(x => x.code === PROJECT_PERMISSIONS.createAndEditProject.code)
    if (createAndEditProjectPermission.level_permissions.find(x => x.role_id === roleID) !== undefined) {
      return true
    }
  }
  return false
}

const GetChildProjectPermissionID = (code: string, levelPermissions: LevelPermission[], roleID: number): number => {
  if (code === PROJECT_PERMISSIONS.createAndEditProject.code) {
    const viewSurveySettingsProjectPermission = levelPermissions.find(x => x.code === PROJECT_PERMISSIONS.viewSurveySettingsProject.code)
    if (viewSurveySettingsProjectPermission.level_permissions.find(x => x.role_id === roleID) === undefined) {
      return viewSurveySettingsProjectPermission.id
    }
  }
  return null
}

const StyledTableRow = styledMui(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "var(--colorPalleteLightBlue, #EEEFFF)",
  },
}));


const afterStyle = css`
  content: '';
  position: absolute;
  top: 0;
  right: -5px;
  width: 5px;
  height: 100%;
  background: linear-gradient(
    90deg,
    var(--colorNeutralBackground3) 0,
    var(--colorNeutralBackground1) 6px
  ) !important;
  z-index: 2;
`;


const TableContainerWrapper = styled(TableContainer)`
  margin-bottom: 200px;
  box-shadow: none !important;

    &::-webkit-scrollbar {
      width: 8px;
      background-color: var(--colorNeutralForegroundInverted1);
      border-radius: 0;
    }

    &::-webkit-scrollbar-track {
      background: var(--colorScrollBgc);
      margin-left: 300px !important;
    }
    
    &::-webkit-scrollbar-thumb {
      border-radius: 25px;
      background: var(--colorGrayForeground6);
    }

  .MuiTableCell-root {
    border-bottom: 0 !important;
  }

`;

const TableWrapper = styled(Table)`
  min-width: 300px;
  width: auto !important;
`;

const StyledInfoIcon = styled(InfoIcon)`
  font-size: 16px !important;
  fill: var(--colorNeutralForeground1, #3e4450) !important;
`;

const EmptyTableCellNav = styled(TableCell)`
  position: sticky;
  left: 0;
  background: var(--colorNeutralBackground1) !important;
  z-index: 2;
  min-width: 300px;
  max-width: 300px;
  width: 300px;

   &::after {
    ${afterStyle}
  }
`;
const TableCellRoles = styled(TableCell)`
  min-width: 200px;
  border-right: 1px solid var(--colorNeutralBackground3);
`;
const TableCellEmptyCategory = styled(TableCell)`
  background-color: var(--colorPalleteLightBlue, #eeefff);
  min-width: 200px;
`;
const TableCellPermName = styled(TableCell)`
  position: sticky;
  left: 0;
  background: var(--colorNeutralBackground1) !important;
  z-index: 1;
  min-width: 300px;
  max-width: 300px;
  width: 300px;

  &::after {
    ${afterStyle}
  }
`;
const TableCellSwitch = styled(TableCell)`
  width: 200px;
  border-right: 1px solid var(--colorNeutralBackground3);
`;

const TableCellCategoryName = styled(TableCell)`
  position: sticky;
  left: 0;
  padding: 10px 15px;
  font-weight: 500;
  background-color: var(--colorPalleteLightBlue, #eeefff);
  z-index: 2;
  min-width: 300px;
  max-width: 300px;
  width: 300px;

  &::after {
    ${afterStyle}
  }
`;

const TableCellProject = styled(TableCell)`
  position: sticky;
  left: 0;
  padding: 10px 15px;
  font-weight: 500;
  background-color: var(--colorNeutralForeground4, #f1f3f8);
  z-index: 2;
  min-width: 300px;
  max-width: 300px;
  width: 300px;
`;
const TableCellProjectEmpty = styled(TableCell)`
  background-color: var(--colorNeutralForeground4, #f1f3f8);
  min-width: 300px;
`;

const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const TitleSurvey = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  &::after {
    ${afterStyle}
  }
`
const ProjectPermName = styled.div`
  margin-left: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ProjectName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
`;

const FolderOpenIconStyle = styled(FolderOpenIcon)`
  color: var(--colorPaletteVioletBackground3);
`;

const ContainerNameSurvey = styled.div`
  display: flex;
  gap: 10px;
`;

const InsertDriveFileIconStyle = styled(InsertDriveFileIcon)`
  color: var(--colorNeutralForeground6);
  font-size: 18px !important;
`;

const PermissionName = styled.span`
  color: var(--colorNeutralForeground6);
`;
const AlwaysOn = styled.span`
  font-weight: 500;
`;

const RoleName = styled.span`
  color: var(--colorNeutralForeground1, #3e4450);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-bottom: 1px dashed var(--colorPaletteVioletBackground3, #6e31ff);
`;

const StarsIconWr = styled(StarsIcon)`
  font-size: 20px !important;
  background-color: white;
  color: var(--colorPaletteVioletBackground3, #6e31ff);
  border-radius: 10px;
`;

const SettingsIconWr = styled(SettingsIcon)`
  font-size: 20px !important;
  background-color: white;
  color: var(--colorPaletteVioletBackground3, #6e31ff);
  border-radius: 10px;
`;

const AccountCircleIconWr = styled(AccountCircleIcon)`
  font-size: 30px !important;
`;
const BusinessIconWr = styled(BusinessIcon)`
  font-size: 30px !important;
  color: var(--colorPaletteVioletBackground3, #6e31ff);
`;

export default BasicTable;
