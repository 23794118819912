import http from "api/https";
import { createUrl } from "../../helpers/url";

export const getQuestionnaireTypesForEntity = (entityId: number): Promise<any> => {
  const query: { entity_id: string } = { entity_id: entityId.toString() };
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/template/GetForEntity",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/templ_typequestionary/GetForEntity",
      query: query,
    });
    return http.get(url);
  }
};
