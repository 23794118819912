import http from "../https";

type TRequestBody = {
    survey_id: number;
    questionId: number;
    oldName: string;
    newName: string;
    attributes: {
        id: number;
        name: string;
        segments: string[];
    }[];
};
export const renameDashboardResponcesOpenEndedTopicAsync = async (
    data: TRequestBody
): Promise<any> => {
    try {
        const response = await http.post(`/Dashboard/responces/openend/responces/topics/rename`, data);

        if ([200, 201].includes(response.status)) {
            return response.data;
        }

        throw new Error(`Failed to request by rename topic  (HTTP status code: ${response.status})`);
    } catch (error) {
        console.error(error);
    }
};
