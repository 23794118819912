import { ReactElement, useState } from "react";
import styled from "styled-components";
import SetupLogicModal from "./SetupLogicModal";
import { useEmployeesAttributesContext } from "../../EmployeesAttributesProvider";
import { IconButton } from "@mui/material";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 8px;
`

const ButtonContainer = styled.span`
  display: flex;
`
const Textarea = styled.textarea`
  background: #FFFFFF;
  border: 1px solid #CED3EC;
  color: #3E4450;
  border-radius: 4px;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 20px;
  padding: 16px;
`


const WizardIcon = styled.svg`
  width: 24px;
  height: 24px;
  fill: var(--ER-Theme-sys-light-on-tertiary-container, #707882);
`

const IconButtonStyled = styled(IconButton)`
  :hover{
    fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
  }
`

const CustomizedTextarea = styled(Textarea) <{ $readOnly: boolean }>`
  color: #3E4450;
  max-width: 100%;
  width: 100%;
  opacity: ${({ $readOnly }): string => $readOnly ? "0.25" : "1"};
  appearance: none;
    display: block;
    background-color: var(--sjs-general-backcolor, var(--background, #fff));
    box-sizing: border-box;
    width: 100%;
    height: calc(6*(var(--sjs-base-unit, var(--base-unit, 8px))));
    border: none;
    box-shadow: inset 0 0 0 1px var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
    padding: calc(1.5*(var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2*(var(--sjs-base-unit, var(--base-unit, 8px))));
    outline: none;
    font-size: calc(2*(var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    color: var(--sjs-general-forecolor, var(--foreground, #161616));
`

type EmployeeSegmentLogicFieldContainerProps = {
  headerTitle: string
  disabled: boolean
  onSetValue: (logic: string) => void
  value: string
}

const EmployeeSegmentLogicFieldContainer = ({
  headerTitle,
  disabled,
  onSetValue,
  value,
}: EmployeeSegmentLogicFieldContainerProps): ReactElement => {
  const [isSetupLogicModalOpen, setIsSetupLogicModalOpen] = useState(false)

  const { employeesAttributes } = useEmployeesAttributesContext()

  const handleApplyLogic = (logic: string): void => {
    onSetValue(logic)
    setIsSetupLogicModalOpen(false)
  }

  return (
    <Container>
      {disabled ? null : (
        <ButtonContainer>
          {value ? (
            <IconButtonStyled title={"Clear"} color="primary" onClick={() => onSetValue("")}>
              <WizardIcon>
                <use href="#icon-clear"></use>
              </WizardIcon>
            </IconButtonStyled>
          ) : null}

          <IconButtonStyled title={"Edit"} color="primary" onClick={() => setIsSetupLogicModalOpen(true)}>
            <WizardIcon>
              <use href="#icon-wizard"></use>
            </WizardIcon>
          </IconButtonStyled>

        </ButtonContainer>
      )}

      <CustomizedTextarea
        disabled
        value={value}
        $readOnly={disabled}
      />

      <SetupLogicModal
        isVisible={isSetupLogicModalOpen}
        onDismiss={(): void => setIsSetupLogicModalOpen(false)}
        onApplyLogic={handleApplyLogic}
        value={value}
        headerTitle={headerTitle}
        employeeAttributes={employeesAttributes}
      />
    </Container>
  )
}

export default EmployeeSegmentLogicFieldContainer
