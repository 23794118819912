import { FC, useCallback, useState, useEffect } from "react";
import { ENTITY_PERMISSIONS_TABS } from "../../constants/menu";

import styled from "styled-components";
import LeftNavigationBar from "./LeftNavigationBar";
import EntityPermissionsUser from "./User";
import EntityPermissionsRoles from "./Roles";
import { useLocation } from 'react-router';

type EntityPermissionsScreenProps = {};

const EntityPermissionsScreen: FC<EntityPermissionsScreenProps> = () => {
  let query = useQuery();
  let nav = query.get('nav') != null ? query.get('nav') : "user"
  let idRole = query.get('idRole') != null ? query.get('idRole') : "0"
  
  const [activeTab, setActiveTab] = useState<string>(nav);


  useEffect(() => {
    setActiveTab(nav)
  }, [nav]);


  const changeTab = (tab: string) => setActiveTab(tab);

  const renderTab = useCallback(() => {
    switch (activeTab) {
      case ENTITY_PERMISSIONS_TABS.user:
        return <EntityPermissionsUser idRole={idRole} changeTab={changeTab} />;
      case ENTITY_PERMISSIONS_TABS.role:
        return <EntityPermissionsRoles changeTab={changeTab} />;
      default:
        return <EntityPermissionsUser idRole={idRole} changeTab={changeTab} />;
    }
  }, [activeTab]);

  return (
    <MainWrapper>
      <Column>
        <LeftNavigationBar changeTab={changeTab} activeTab={activeTab} />
      </Column>
      <ColumnContent>{renderTab()}</ColumnContent>
    </MainWrapper>
  );
};

export default EntityPermissionsScreen;

const MainWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 202px calc(100% - 220px);
  padding-top: 80px;
  grid-column-gap: 16px;
`;

const Column = styled.div`
width: 230px;
`;
const ColumnContent = styled.div`
  margin: 0 50px;
`;


function useQuery() {
  return new URLSearchParams(useLocation().search);
}