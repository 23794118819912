import http from "../https";

type TRequestBody = {
    survey_id: number;
    attributes: {
        id: number;
        name: string;
        segments: string[];
    }[];
};

export const useGetQuestionBySurveyId = (requestData: TRequestBody) =>
    http.post(`/Dashboard/open-end-question`, requestData);
