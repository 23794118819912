import styled from "styled-components";
import { SvgRegistry } from "survey-core";
import ReactDOMServer from "react-dom/server";
import { ENPS_QUESTION_TYPE } from "./surveyJsConstants";
import Checkbox from "../icons/Checkbox";
import RadioButtonChecked from "../icons/RadioButtonChecked";
import ToggleOff from "../icons/ToggleOff";
import Subject from "../icons/Subject";
import LinearScale from "../icons/LinearScale";
import Promoter from "../icons/Promoter";

SvgRegistry.registerIconFromSvg("boolean", ReactDOMServer.renderToString(<ToggleOff />));
SvgRegistry.registerIconFromSvg("checkbox", ReactDOMServer.renderToString(<Checkbox />));
SvgRegistry.registerIconFromSvg("comment", ReactDOMServer.renderToString(<Subject />));
SvgRegistry.registerIconFromSvg(
  "radiogroup",
  ReactDOMServer.renderToString(<RadioButtonChecked />)
);
SvgRegistry.registerIconFromSvg("rating", ReactDOMServer.renderToString(<LinearScale />));
SvgRegistry.registerIconFromSvg(ENPS_QUESTION_TYPE, ReactDOMServer.renderToString(<Promoter />));

const CustomSurveyJsStyles = styled.div`
  --primary: #1477f8;
  --background: #ffffff;
  --background-dim: #f5f6fa;
  --background-dim-light: #f5f6fa;
  --primary-foreground: #ffffff;
  --foreground: #1e004d;
  --base-unit: 8px;
  --sjs-primary-backcolor-dark: #005DEA;

  min-height: calc(100vh - var(--layoutNavigationHeight, 0px));
  height: calc(100vh - var(--layoutNavigationHeight, 0px));
  // Rating item selected color correction
  .sd-rating__item-text {
    color: inherit;
  }
`;

export const CustomSurveyEditorStyles = styled(CustomSurveyJsStyles)<{
  $isPreview: boolean;
  $isReadOnly: boolean;
}>`
    
  --layoutNavigationHeight: ${({ $isPreview }): string => $isPreview ? "64" : "186"}px;
  min-height: calc(100vh - var(--layoutNavigationHeight, 0px));
  height: calc(100vh - var(--layoutNavigationHeight, 0px));

  .sv-action__content {
    display: ${({ $isReadOnly}) => $isReadOnly ? "none" : "block" };
  }
    
  .sd-title {
    color: var(--ER-Theme-sys-light-on-primary-container, #1e004d);
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 44px; /* 110% */
  }

  .sd-description {
    span {
      color: var(--ER-Theme-sys-light-on-tertiary-container, #707882);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }

  .sd-title.sd-container-modern__title {
    box-shadow: none !important;
  }

  .svc-tabbed-menu-wrapper {
    visibility: hidden;
  }
  .spg-search-editor_container {
    display: none;
  }
  .spg-root-modern {
    top: auto !important;
    height: auto;
  }
  .sv-list__item--selected .sv-list__item-body {
    background-color: #f8f8f8 !important;
    color: #1e004d;
    &:hover {
      background-color: #f8f8f8 !important;
      color: #1e004d;
    }
  }

  .svc-top-bar {
    background-color: unset;

    @media (min-width: 1200px) {
      display: ${({ $isPreview }): string => $isPreview ? "flex" : "none"};
    }
  }
  .svc-creator__content-wrapper{
    ${({ $isPreview }): string => $isPreview ? "" : "height: auto !important;"}
  }

  .svc-side-bar__container-header,
  .svd-test-results {
    display: none;
  }
  .svc-notifier {
    display: none !important;
  }
`;

export const CustomResponseEditorStyles = styled(CustomSurveyJsStyles)`
  .sd-root-modern {
    min-height: inherit;
  }
`;
