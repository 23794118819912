import { FC } from "react";
import { Dialog, DialogContent } from "@mui/material";

import styled from "styled-components";
import ClearIcon from "@mui/icons-material/Clear";
import CustomButton from "components/button/Button";
import store from "../Recipients/store";

type SendInvitesRecipientsPopupProps = {
  open: boolean;
  hide: (state: boolean) => void;
};

const SendInvitesRecipientsPopup: FC<SendInvitesRecipientsPopupProps> = (props) => {
  return (
    <StyledDialog open={props.open} onClose={props.hide}>
      <CloseBtnWrapper>
        <CloseButton
          onClick={() => {
            store.closeRecipientsModal(false);
          }}
        >
          <ClearIcon />
        </CloseButton>
      </CloseBtnWrapper>
      <StyledDialogContent>
        <Title>
          {store.importSelectionRows.length > 1
            ? "Send invites to new recipients"
            : "Send invite to new recipient"}
        </Title>
        <Description>
          {store.importSelectionRows.length > 1
            ? store.importSelectionRows.length +
              " new recipients will be added. Those with emails will each receive a survey invitation."
            : "A new recipient will be added. The one with an email will receive a survey email invitation."}
        </Description>
        <ButtonWrapper>
          <SendCustomButton
            variant="contained"
            type="submit"
            onClick={() => {
              store.addRecipients(true);
              store.closeRecipientsModal(false);
            }}
          >
            Confirm and send
          </SendCustomButton>
          <CancelCustomButton
            variant="outlined"
            type="reset"
            onClick={() => store.closeRecipientsModal(false)}
          >
            Cancel
          </CancelCustomButton>
        </ButtonWrapper>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default SendInvitesRecipientsPopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 100% !important;
    max-width: 522px !important;
    overflow: hidden !important;
    border-radius: 10px !important;
  }

  .MuiDialogContent-root {
    padding: 0px 64px !important;
  }
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 10px 20px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 0px 60px 0px;
`;

const SendCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 180px;
  height: 34px;
`;

const CancelCustomButton = styled(CustomButton)`
  width: 100%;
  max-width: 90px;
  height: 34px;
  margin-left: 20px !important;
  color: var(--colorNeutralForeground2) !important;
  border-color: var(--colorNeutralForeground2) !important;

  &:hover {
    background: var(--colorNeutralForegroundInverted1) !important;
  }
`;

const Title = styled.span`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
`;

const Description = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  margin: 16px 0px 0px 0px;
`;
