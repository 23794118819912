import { FC } from "react";
import { ProjectList } from "features/Project/ProjectList";

type TemplatesPageProps = {};

const TemplatesPage: FC<TemplatesPageProps> = () => {
  return <div>
    <ProjectList />
  </div>;
};

export default TemplatesPage;
