import * as React from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import store from "./store";
import { observer } from "mobx-react";
import CustomButton from "components/button/Button";
import { RoleUser, User } from "types/permission";
import styled from "styled-components";
import CustomTable from "./CustomTable";
import { TColumn } from "types/recipients";
import Pagination from "components/pagination/Pagination";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DEFAULT_ROLES } from "constants/default-roles";

interface TableProps {
  data: RoleUser;
}

const DataTable = observer((props: TableProps) => {
  const [sortModel, setSortModel] = React.useState({ field: null, sort: null });
  const [activePage, setActivePage] = React.useState(1);
  const [countPerPage, setCountPerPage] = React.useState(10);

  const selectionChangeHandler = (isSelected?: boolean, row?: User) => {
    const newSelection = isSelected
      ? store.selectedData.filter((id) => id !== row.id)
      : [...store.selectedData, row.id];
    store.changeSelectedData(newSelection);
  };

  const changeSort = (field: string, sort: string) => {
    setSortModel({ field, sort });
  };

  const sortedData = [...props.data.users].sort((a, b) => {
    if (sortModel.sort === "asc") {
      if (a[sortModel.field] < b[sortModel.field]) return -1;
      if (a[sortModel.field] > b[sortModel.field]) return 1;
    } else if (sortModel.sort === "desc") {
      if (a[sortModel.field] > b[sortModel.field]) return -1;
      if (a[sortModel.field] < b[sortModel.field]) return 1;
    }
    return 0;
  });

  const indexOfLastRecord = activePage * countPerPage;
  const indexOfFirstRecord = indexOfLastRecord - countPerPage;
  const currentRecords = sortedData.slice(indexOfFirstRecord, indexOfLastRecord);

  const columns = [
    {
      width: 20,
      label: (
        <CheckBoxContainer>
          <StyledFormControlLabel
            control={
              <StyledCheckBox
                id={`EntityPermissions_EntityUsers_Grid_Table_ChecboxAll`}
                disabled={props.data.users.length === 0}
                checked={store.selectedData.length === props.data.users.length}
                onChange={() => {
                  store.changeSelectedData(
                    store.selectedData.length !== props.data.users.length
                      ? props.data.users.map((item) => item.id)
                      : []
                  );
                }}
              />
            }
            label={false}
          />
        </CheckBoxContainer>
      ),
      render: (row: any, index, indexRow) => {
        const isSelected = store.selectedData.includes(row.id);
        return (
          <CheckBoxContainer>
            <StyledFormControlLabel

              control={<StyledCheckBox id={`EntityPermissions_EntityUsers_Grid_Table_Checbox_${index}_${indexRow}_${row.id}`} checked={isSelected} />}
              label={false}
            />
          </CheckBoxContainer>
        );
      },
    },
    {
      id: "full_name",
      width: 200,
      label: (
        <StyledContainerLabelColumn>
          <p id={`EntityPermissions_EntityUsers_Grid_Column_Name`}>Name</p>
          <BlockSortStyle>
            <KeyboardArrowUpIconStyle
              id={`EntityPermissions_EntityUsers_Grid_Table_Checbox_`}
              style={
                sortModel.field === "full_name" && sortModel.sort === "asc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("full_name", "asc")}
            />
            <KeyboardArrowDownIconStyle
             id={`EntityPermissions_EntityUsers_Grid_Column_FullName_DownUpSort`}
              style={
                sortModel.field === "full_name" && sortModel.sort === "desc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("full_name", "desc")}
            />
          </BlockSortStyle>
        </StyledContainerLabelColumn>
      ),
      render: (row: any, index, indexRow) => {
        return (
          <FullName
            id={`EntityPermissions_EntityUsers_Grid_Table_Btn_${index}_${indexRow}${row.id}`}
            onClick={() => {
              store.changeCheckEmployeePanel(true);
              store.changeCurrentRoleId(row.id);
            }}
          >
            {row.full_name}
          </FullName>
        );
      }
    },
    {
      id: "email",
      width: 400,
      label: (
        <StyledContainerLabelColumn>
          <p id={`EntityPermissions_EntityUsers_Grid_Column_Email`}>Email</p>
          <BlockSortStyle>
            <KeyboardArrowUpIconStyle
             id={`EntityPermissions_EntityUsers_Grid_Column_Email_ArrowUpSort`}
              style={
                sortModel.field === "email" && sortModel.sort === "asc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("email", "asc")}
            />
            <KeyboardArrowDownIconStyle
            id={`EntityPermissions_EntityUsers_Grid_Column_Email_ArrowDownSort`}
              style={
                sortModel.field === "email" && sortModel.sort === "desc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("email", "desc")}
            />
          </BlockSortStyle>
        </StyledContainerLabelColumn>
      ),
      render: (row, index, indexRow) =>{
        return <span id={`EntityPermissions_EntityUsers_Grid_Table_Text_${index}_${indexRow}_${row.id}`}>
            {row.email}
        </span>
      },
    },
  ] as TColumn[];

  return (
    <div style={{ width: "100%" }}>
      <HeaderWrapperBox>
        {(props.data.isDefaultRole || props.data.roleCode === DEFAULT_ROLES.managers || props.data.roleCode === DEFAULT_ROLES.unit_heads) ? (
          <Box id={`EntityPermissions_EntityUsers_DefaultRole_Label`}>This is default permission role and cannot be edited or deleted.</Box>
        ) : (
          <Box>
            <CustomButton
              id={`EntityPermissions_EntityUsers_Grid_Table_EditNameBtn`}
              style={{ margin: 5 }}
              onClick={() => {
                store.changeAddEditRoleNamePanel(true);
                store.changeCurrentRoleId(props.data.id);
                store.changeCurrentRoleName(props.data.roleName);
              }}
            >
              Edit name
            </CustomButton>
            <CustomButton
              id={`EntityPermissions_EntityUsers_Grid_Table_DeleteRoleBtn`}
              style={{ margin: 5 }}
              onClick={() => {
                store.changeDeleteRolePanel(true);
                store.changeCurrentRoleId(props.data.id);
                store.changeCurrentRoleName(props.data.roleName);
              }}
            >
              Delete role
            </CustomButton>
          </Box>
        )}
        <Box>
          <CustomButton
            id={`EntityPermissions_EntityUsers_Grid_Table_AddUsersBtn`}
            style={{ margin: 5 }}
            variant="contained"
            size="small"
            onClick={() => {
              store.changeInviteUsersPanel(true);
              store.changeCurrentRoleId(props.data.id);
            }}
          >
            <PersonAddIcon fontSize="small" /> Add users
          </CustomButton>

          <CustomButton
            id={`EntityPermissions_EntityUsers_Grid_Table_ChangeRoleBtn`}
            style={{ margin: 5 }}
            disabled={store.selectedData.length === 0}
            variant="contained"
            color="inherit"
            size="small"
            onClick={() => {
              store.changeChangeRolePanel(true);
              store.changeCurrentRoleId(props.data.id);
              store.changeCurrentRoleName(props.data.roleName);
              var selected_users = props.data.users.filter((x) =>
                store.selectedData.includes(x.id)
              );
              if (selected_users.length === 1) {
                store.changeCurrentUser(selected_users[0]);
              }
            }}
          >
            Change role
          </CustomButton>

          <CustomButton
            id={`EntityPermissions_EntityUsers_Grid_Table_RemoveFromRoleBtn`}
            style={{ margin: 5 }}
            disabled={store.selectedData.length === 0}
            variant="contained"
            color="inherit"
            size="small"
            onClick={() => {
              store.changeRemoveFromRolePanel(true);
              store.changeCurrentRoleId(props.data.id);
              store.changeCurrentRoleName(props.data.roleName);
              var selected_users = props.data.users.filter((x) =>
                store.selectedData.includes(x.id)
              );
              if (selected_users.length === 1) {
                store.changeCurrentUser(selected_users[0]);
              }
            }}
          >
            <DeleteIcon fontSize="small" />
            Remove from role
          </CustomButton>
        </Box>
      </HeaderWrapperBox>
      <div style={{ width: "100%" }}>
        <CustomTable
          data={currentRecords}
          columns={columns}
          changeHandler={selectionChangeHandler}
          noDataTitle={{
            title: "No users yet.",
            description: "Get started by adding users.",
          }}
        />
      </div>
      <Pagination
        activePage={activePage}
        itemsCountPerPage={countPerPage}
        onChange={(value) => {
          setActivePage(value);
        }}
        pageRangeDisplayed={countPerPage}
        totalItemsCount={props.data.users.length}
        totalPages={props.data.users.length / countPerPage}
        setCount={(value) => {
          setActivePage(1);
          setCountPerPage(Number(value));
        }}
      />
    </div>
  );
});

export default DataTable;

const FullName = styled.a`
  &:hover {
    text-decoration: underline;
    color: blue;
  }
`;

const CheckBoxContainer = styled.div``;

const StyledCheckBox = styled(Checkbox)`
  padding: 0px 10px !important;
  color: var(--colorBrandForeground1) !important;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);

  .MuiCheckbox-root {
    &:hover {
      background-color: transparent !important;
    }
  }
`;

const StyledContainerLabelColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const BlockSortStyle = styled.span`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 10px;
  height: 20px;
  top: 0;
  left: 14px;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
`;

const KeyboardArrowUpIconStyle = styled(KeyboardArrowUpIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: -6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: 6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const HeaderWrapperBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  background-color: var(--grenBackgroundColor2);
  padding: 0 16px;
`;