import { FC, useMemo } from "react";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import styled from "styled-components";
import EditIcon from "@mui/icons-material/Edit";

import store from "./../../store";
import ReminderEdit from "./ReminderEdit";
import { ReminderSurvey } from "types/surveys";
import PreviewIcon from "../../PreviewIcon";

type ReminderBlockProps = {
  reminder: ReminderSurvey;
  index: number;
};

const ReminderBlock: FC<ReminderBlockProps> = observer((props) => {
  const scheduledDates = useMemo(() => {
    let scheduled_date = "";
    let short_scheduled_date = "";
    if (store.survey_date_start && props.reminder.strtime_send_reminder) {
      let start = store.survey_date_start;
      let days = store.ReminderDays.filter((x) => x.id === props.reminder.reminder_days_id);
      let added = dayjs();
      if (days.length !== 0) {
        added = start.add(days[0].reminders_after_days, "day");
      } else {
        added = start;
      }
      if (
        props.reminder.strtime_send_reminder &&
        props.reminder.strtime_send_reminder.split(":").length === 2
      ) {
        added = added.set("hour", +props.reminder.strtime_send_reminder.split(":")[0]);
        added = added.set("minute", +props.reminder.strtime_send_reminder.split(":")[1]);
      }
      scheduled_date = added.format("dddd, D MMM, YYYY h:mm A");
      short_scheduled_date = added.format("ddd, D MMM, YYYY");
    }
    return { scheduled_date, short_scheduled_date };
  }, [store.survey_date_start, props.reminder.strtime_send_reminder, store.ReminderDays]);

  const { scheduled_date, short_scheduled_date } = scheduledDates;

  return (
    <>
      {store.openReminderIndex === props.index ? (
        <>
          <ReminderEdit {...props} short_scheduled_date={short_scheduled_date} />
          <LaunchSurveyAfter />
        </>
      ) : (
        <>
          <LaunchSurvey>
            <div>
              <span
                id={`Survey_EditCreate_Communication_ScheduleCommunications_Reminder_Email_${props.index} `}
              >
                Reminder email {props.index + 1}
              </span>
              <LaunchSecondText
                id={`Survey_EditCreate_Communication_ScheduleCommunications_Reminder_Recipients${props.index}`}
              >
                to {props.reminder.reminder_recipients_group_name}
              </LaunchSecondText>
              <ReminderDateText
                id={`Survey_EditCreate_Communication_ScheduleCommunications_Reminder_Date_${props.index}`}
              >
                {scheduled_date}
              </ReminderDateText>
            </div>
            <LaunchButtons>
              <LaunchButtonWrapper>
                <LinkPreview
                  id={`Survey_EditCreate_Communication_ScheduleCommunications_Reminder_Preview_Button_${props.index}`}
                  onClick={() => store.loadPreviewMessage(props.reminder.id_comms_reminder, false)}
                >
                  <PreviewIcon />
                  Preview email
                </LinkPreview>
                <LinkPreview
                  id={`Survey_EditCreate_Communication_ScheduleCommunications_Reminder_Edit_Button_${props.index}`}
                  onClick={() => store.changeOpenReminderIndex(props.index, true)}
                >
                  <EditIcon fontSize="inherit" />
                  Edit
                </LinkPreview>
              </LaunchButtonWrapper>
            </LaunchButtons>
          </LaunchSurvey>
          <LaunchSurveyAfter />
        </>
      )}
    </>
  );
});

export default ReminderBlock;

const LaunchSurvey = styled.div`
  padding: 12px;
  background-color: var(--colorNeutralForeground4);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LinkPreview = styled.a`
  margin-left: 5px;
  color: var(--colorBrandForeground1);
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const LaunchButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LaunchButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const LaunchSurveyAfter = styled.div`
  height: 15px;
  border-left: 3px dashed var(--colorPaletteBlueBackground2);
  margin-left: 20px;
`;
const ReminderDateText = styled.div`
  color: var(--colorNeutralForeground5);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const LaunchSecondText = styled.span`
  color: var(--colorNeutralForeground2);
  margin-left: 6px;
`;
