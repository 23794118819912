import http from "api/https";

export const requestResetPassword = (email: string) => {
    return http.postUnauthenticatedGO(
        "/permission/ResetPassword/Request",
        {
            platform: "main",
            email
        },
        {}
    )
}