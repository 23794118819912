import { FC } from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { observer } from "mobx-react";
import { Snackbar, Alert, Backdrop, CircularProgress } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import PreviewStore from "./PreviewStore";
import BackToMainLayoutHeader from "./BackToMainLayoutHeader";

type BackToMainLayoutWrapperProps = {};

const BackToMainLayoutWrapper: FC<BackToMainLayoutWrapperProps> = observer((props) => {
  return (
    <div>
      <BackToMainLayoutHeader />
      <AppContent>
        <Outlet />
      </AppContent>
      <Backdrop sx={{ color: "#fff", zIndex: 1000000 }} open={PreviewStore.loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={PreviewStore.openSnackbar}
        onClose={() => PreviewStore.changeSnackbar(false)}
        autoHideDuration={3000}
        message={PreviewStore.snackbarMessage}
        key={"bottomleft"}
      >
        <StyledAlert
          icon={
            PreviewStore.snackbarSeverity === "error" ? <CancelIconWrapp /> : <CheckIconWrapp />
          }
          onClose={() => PreviewStore.changeSnackbar(false)}
          severity={PreviewStore.snackbarSeverity}
        >
          {PreviewStore.snackbarMessage}
        </StyledAlert>
      </Snackbar>
    </div>
  );
});

export default BackToMainLayoutWrapper;

const AppContent = styled.div`
  width: 100%;
  overflow: auto;
  position: relative;
`;

const CancelIconWrapp = styled(CancelIcon)`
  color: var(--colorPaletteRedForeground2) !important;
`;

const CheckIconWrapp = styled(CheckCircleIcon)`
  color: var(--colorPaletteGreenBackground2) !important;
`;

const StyledAlert = styled(Alert)`
  background-color: var(--colorNeutralForeground1) !important;
  color: var(--colorNeutralBackground1) !important;
  border-radius: 8px !important;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
