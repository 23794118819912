import React from "react";
import { observer } from "mobx-react";
import store from './store'
import SurveyEditor from "components/surveyEditor/SurveyEditor";
import EmployeesAttributesProvider from "components/surveyEditor/EmployeesAttributesProvider";

type SurveyJsProps = {};

const SurveyJs: React.FC<SurveyJsProps> = observer(() => {
  return (
    <div>
      <EmployeesAttributesProvider employeesAttributes={store.Attributes}>
        <SurveyEditor
          editorMode={"open"}
          surveyJson={store.survey_before}
          newQuestions={store.questions_for_add}
          onModified={store.setSurveyChanged}
          typeAddNewQuestionsAddOrReplace={store.typeReplaceQuestions}
          onSurveySaved={(json, title, locales, questions, onSuccess) => {
            store.changeMainSurvey(json)
            store.changeTitleSurvey(title)
            store.changeUsedLocales(locales)
            store.changeQuestions(questions)
          }}
        />
      </EmployeesAttributesProvider>
    </div>
  );
});

export default SurveyJs;
