import http from "api/https";

export const addReminderForSurvey = (idSurvey: number) => {
  const data = { idSurvey };
  if(http.isAPIGO){
    return http.postGO("/sm/sm_comms_reminder/AddReminderForSurvey",  data);
  } else {
    return http.post("/sm_comms_reminder/AddReminderForSurvey",  data);
  }  
};
