import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg``;

export const WorkspaceIcon = (
  props: React.HTMLAttributes<HTMLOrSVGElement>
) => (
  <StyledSvg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6667 3.99984H12.3334V2.33317C12.3334 1.40817 11.5917 0.666504 10.6667 0.666504H7.33341C6.40841 0.666504 5.66675 1.40817 5.66675 2.33317V3.99984H2.33341C1.40841 3.99984 0.675081 4.7415 0.675081 5.6665L0.666748 14.8332C0.666748 15.7582 1.40841 16.4998 2.33341 16.4998H8.72811C8.36816 15.7421 8.16675 14.8945 8.16675 13.9998C8.16675 10.7782 10.7784 8.1665 14.0001 8.1665C15.2395 8.1665 16.3886 8.55302 17.3334 9.21208V5.6665C17.3334 4.7415 16.5917 3.99984 15.6667 3.99984ZM10.6667 3.99984H7.33341V2.33317H10.6667V3.99984Z"
      fill="#6E31FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1175 13.9998C17.1175 14.1415 17.1009 14.2748 17.0842 14.4082L17.9759 15.0998C18.0509 15.1665 18.0759 15.2748 18.0259 15.3665L17.1925 16.8082C17.1342 16.8998 17.0259 16.9332 16.9342 16.8998L15.8925 16.4832C15.6842 16.6498 15.4425 16.7832 15.1925 16.8915L15.0342 17.9915C15.0175 18.0915 14.9259 18.1665 14.8259 18.1665H13.1592C13.0509 18.1665 12.9675 18.0915 12.9509 17.9915L12.7925 16.8915C12.5342 16.7915 12.3009 16.6498 12.0842 16.4832L11.0509 16.8998C10.9509 16.9332 10.8425 16.8998 10.7925 16.8082L9.9592 15.3665C9.9092 15.2748 9.9342 15.1665 10.0092 15.0998L10.8925 14.4082C10.8759 14.2748 10.8675 14.1415 10.8675 13.9998C10.8675 13.8582 10.8759 13.7248 10.8925 13.5915L10.0092 12.8998C9.92586 12.8332 9.9092 12.7248 9.9592 12.6332L10.7925 11.1915C10.8509 11.0998 10.9592 11.0665 11.0509 11.0998L12.0842 11.5165C12.3009 11.3498 12.5425 11.2165 12.7925 11.1082L12.9509 10.0082C12.9675 9.90817 13.0592 9.83317 13.1592 9.83317H14.8259C14.9259 9.83317 15.0175 9.90817 15.0259 10.0082L15.1842 11.1082C15.4425 11.2082 15.6759 11.3498 15.8925 11.5165L16.9259 11.0998C17.0259 11.0665 17.1342 11.0998 17.1842 11.1915L18.0175 12.6332C18.0675 12.7248 18.0425 12.8332 17.9675 12.8998L17.0842 13.5915C17.1092 13.7248 17.1175 13.8582 17.1175 13.9998ZM12.7425 13.9998C12.7425 14.6915 13.3009 15.2498 13.9925 15.2498C14.6842 15.2498 15.2425 14.6915 15.2425 13.9998C15.2425 13.3082 14.6842 12.7498 13.9925 12.7498C13.3009 12.7498 12.7425 13.3082 12.7425 13.9998Z"
      fill="#6E31FF"
    />
  </StyledSvg>
);
