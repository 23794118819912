import { makeAutoObservable } from "mobx";
import { GetSurveyTags } from "api/go/useGetSurveyTags";
import { UpdateSurveySettings } from "api/useUpdateSurveySettings";
import { GetOneSurveyWithTags } from "api/useGetOneSurveyWithTags";
import { survey_tags } from "types/surveys";
import MainStore from "../../../../MainStore";

class Store {
  idSurvey: number = 0;
  tags: survey_tags[] = [];
  selectedTagNames: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  loadSettingbyid() {
    GetOneSurveyWithTags(this.idSurvey).then((x) => {
      if (x?.data !== undefined) {
        this.idSurvey = x.data.id;
        this.selectedTagNames = this.tags
          .filter((value) => x.data.tags.some((value2) => value2.id === value.id))
          .map((x) => x.name);
      }
    });
  }

  handleChange = (name: string, value: any) => {
    this[name] = value;
  };

  onSave(onSaved: () => void) {
    UpdateSurveySettings(this.idSurvey, this.selectedTagNames).then((x) => {
      if (x !== null && x.data !== null) {
        this.clearStore();
        onSaved();
      }
    });
  }

  loadTags = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await GetSurveyTags();
      this.tags = response;
      this.loadSettingbyid();
    } catch {
      MainStore.setSnackbar("Can't found tags", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  changeValue = (name: string, value: any) => {
    this[name] = value;
  };

  clearStore() {
    this.tags = [];
    this.selectedTagNames = [];
  }

  changeIdSurvey = (idSurvey: number) => {
    this.idSurvey = idSurvey;
  };

  changeSelectedTagNames = (value: string[]) => {
    this.selectedTagNames = value;
  }
}

const store = new Store();
export default store;
