export const SORTALE_SETTINGS_LIST = [
    {
        label: "Preferred name A-Z",
        value: "preff_name_a_z",
    },
    {
        label: "Preferred name Z-A",
        value: "preff_name_z_a",
    },
    {
        label: "Name A-Z",
        value: "name_a_z",
    },
    {
        label: "Name Z-A",
        value: "name_z_a",
    },
    {
        label: "Email A-Z",
        value: "email_a_z",
    },
    {
        label: "Email Z-A",
        value: "email_z_a",
    },
];

export const SORTALE_SETTINGS_VALUE_LIST = {
    preff_name_a_z: "preff_name_a_z",
    preff_name_z_a: "preff_name_z_a",
    name_a_z: "name_a_z",
    name_z_a: "name_z_a",
    email_a_z: "email_a_z",
    email_z_a: "email_z_a",
};
