import { FC } from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react";
import styled from "styled-components";

import empty from "assets/images/er-logo-square.png";
import CustomButton from "components/button/Button";
import store from "./store";

type ShareTemplatePopupProps = {};

const ShareTemplatePopup: FC<ShareTemplatePopupProps> = observer((props) => {
  return (
    <PopupWrapper id={"Template_Share_Form"}>
      <Styledh2 id={"Template_Share_Header_Label"}>Share “{store.selectedRow.name}” with </Styledh2>
      {store.shareEntityTemplates.length === 0 ? (
        <>Sorry you have only one entity</>
      ) : (
        <ContentWrapper>
          {store.shareEntityTemplates.map((el, i) => {
            return (
              <ContentBox key={el.id} id={`Template_Share_Entity_Block_${i}`}>
                <StyledBox>
                  <StyledImageWrapper>
                    <StyledImg src={el.entity_logo_url || empty} />
                  </StyledImageWrapper>
                  <Box id={`Template_Share_Workspace_EntityName_${i}`}>
                    <span id={`Template_Share_Workspace_Name_${i}`}>{el.workspace_name}</span>:{" "}
                    <span id={`Template_Share_EntityName_${i}`}>{el.entity_name}</span>
                  </Box>
                </StyledBox>
                <StyledButton
                  id={`Template_Share_Button_${i}`}
                  variant="outlined"
                  disabled={el.is_shared}
                  onClick={() => store.shareTemplate(el.id)}
                >
                  {el.is_shared ? "Shared" : "Share"}
                </StyledButton>
              </ContentBox>
            );
          })}
        </ContentWrapper>
      )}
    </PopupWrapper>
  );
});

export default ShareTemplatePopup;

const PopupWrapper = styled.div`
  padding: 42px;
`;

const ContentWrapper = styled.div`
  height: 230px;
  overflow: auto;

  display: flex;
  flex-direction: column;
  gap: 15px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: var(--colorGrayForeground6);
  }
`;

const Styledh2 = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin: 0 0 56px;
`;

const ContentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--colorNeutralForeground4);
  border-radius: 4px;
  width: 100%;
  padding: 11px;
`;

const StyledBox = styled(Box)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledImageWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

const StyledImg = styled.img`
  height: 32px;
`;

const StyledButton = styled(CustomButton)`
  width: 90px;
  height: 34px;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 4px;
`;
