import { makeAutoObservable, runInAction } from "mobx";
import { getDashboardResponcesScoredCommentsAsync } from "api/dasboard/useGetDashboardResponcesScoredComments";
import { getDashboardResponcesScoredDimensionsAsync } from "api/dasboard/useGetDashboardResponcesScoredDimensions";
import { TScoredQuestionsDimension, TScoredQuestionsDimensionResponse } from "types/dashboard";

import topStore from "../../store";
import MainStore from "MainStore";

class Store {
    scoredQuestionsList: TScoredQuestionsDimension[] = [];
    activeScoredQuestionDimension: TScoredQuestionsDimension = null;
    isOpenConditionalLogicPopup: boolean = false;
    activeScoredQuestion: TScoredQuestionsDimensionResponse = null;

    constructor() {
        makeAutoObservable(this);
    }

    setData = (value: any, field: string) => {
        this[field] = value;
    };

    getDashboardResponsesScoredDimensions = async (
        survey_id: number,
        filters: { id: number; name: string; segments: string[] }[]
    ) => {
        MainStore.changeLoader(true);

        const response = await getDashboardResponcesScoredDimensionsAsync({
            survey_id,
            attributes: filters,
            benchmarkType: topStore.overviewBenchmark,
        });

        runInAction(() => {
            if (response) {
                this.scoredQuestionsList = response;
                this.activeScoredQuestionDimension = response && response.length > 0 ? response[0] : null;
            }
        });

        MainStore.changeLoader(false);
    };

    getDashboardResponsesScoredComments = async (
        survey_id: number,
        filters: { id: number; name: string; segments: string[] }[],
        questionId: number,
        scoredType: number,
        pagination: { offset: number; limit: number }
    ) => {
        MainStore.changeLoader(true);

        const response = await getDashboardResponcesScoredCommentsAsync({
            survey_id,
            attributes: filters,
            questionId,
            scoredType,
            benchmarkType: topStore.overviewBenchmark,
            pagination,
        });

        MainStore.changeLoader(false);

        return response;
    };
}

const store = new Store();

export default store;
