export const validate = (nameField: string, value: string, validated: (errorField: string, errors: string) => void) => {
  let fullName = "";
  if (nameField === "fullName") {
    let fullNameErrors = [];
    CheckEmptyTextField(value, fullNameErrors)
    fullName = fullNameErrors.join(", ");
    validated("errorfullName", fullName);
  }
  //End of validation field fullName
  
  let preferedName = "";
  if (nameField === "preferedName") {
    let preferedNameErrors = [];
    CheckEmptyTextField(value, preferedNameErrors)
    preferedName = preferedNameErrors.join(", ");
    validated("errorpreferedName", preferedName);
  }
  //End of validation field preferedName
  
  let email = "";
  if (nameField === "email") {
    let emailErrors = [];
    // CheckEmptyTextField(value, emailErrors)
    CheckIsEmail(value, emailErrors)
    email = emailErrors.join(", ");
    validated("erroremail", email);
  }
  //End of validation field email
  
  let uniqueId = "";
  if (nameField === "uniqueId") {
    let uniqueIdErrors = [];
    // CheckEmptyTextField(value, uniqueIdErrors)
    uniqueId = uniqueIdErrors.join(", ");
    validated("erroruniqueId", uniqueId);
  }
  //End of validation field uniqueId
  
  let startDate = "";
  if (nameField === "startDate") {
    let startDateErrors = [];
    // CheckEmptyTextField(value, startDateErrors)
    startDate = startDateErrors.join(", ");
    validated("errorstartDate", startDate);
  }
  //End of validation field startDate



  let canSave =
    true &&
    fullName === "" &&
    preferedName === "" &&
    email === "" &&
    uniqueId === "" &&
    startDate === ""

  return canSave;
};

export function CheckEmptyTextField(s: string, errors: string[]) {
  if (s === "" || s === null){
    errors.push("Required field");
    return;
  }
}

export function CheckIsEmail(s: string, errors: string[]) {
  if (s == null || s === "") return;
  var reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!reg.test(s)) {
      errors.push("Please enter a valid email address");
  }
}