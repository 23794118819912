import * as Yup from "yup";
import React, { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { TextField, Stack, Chip, Box } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import ClearIcon from "@mui/icons-material/Clear";

import CustomModal from "components/modal/Modal";
import CustomButton from "components/button/Button";

interface InviteFormModalProps {
  open: boolean;
  roleName: string;
  roleId: number;
  emails: string[];
  value: string;
  onClose: () => void;
  inviteHandler: () => void;
  onChange: (event: string) => void;
  submitHandler: () => void;
  deleteEmail: (index: number) => void;
  clearStates: () => void;
}

export const InviteFormModal: FC<InviteFormModalProps> = observer((props) => {
  const [isValidEmail, setIsValidEmail] = React.useState<string>("");

  React.useEffect(() => {
    if (!props.open) {
      props.clearStates();
      setIsValidEmail("");
    }
  }, [props.open]);

  const emailSchema = Yup.string()
    .email("Email format is not valid")
    .required("Email is a required field");

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const email = props.value;

    try {
      await emailSchema.validate(email);

      if (props.emails.includes(email)) {
        setIsValidEmail(`“${email}” has already been added below.`);
      } else {
        props.submitHandler();
        setIsValidEmail("");
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setIsValidEmail(error.message);
      }
    }
  };

  return (
    <CustomModal open={props.open} hide={props.onClose} width="600px" padding="0">
      <StyledFormBox component="form" noValidate autoComplete="off" onSubmit={submitHandler}>
        <Header data-testid='EntityPermissions_AddUsers_popup_title'>Add users to “{props.roleName}”</Header>
        <Typography data-testid='EntityPermissions_AddUsers_popup_description'>
          Enter their email address and hit "Enter" on your keyboard to add more.
        </Typography>

        <StyledTextField
          data-testid='EntityPermissions_AddUsers_popup_EmailInput'
          fullWidth
          type="email"
          label="Email address"
          variant="standard"
          error={isValidEmail !== ""}
          helperText={isValidEmail}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />

        <AddedEmailBox>
          <Typography>Added email addresses ({props.emails?.length})</Typography>
          {props.emails?.length > 0 && (
            <StyledStack direction="row" spacing={{ xs: 1, sm: 1 }} useFlexGap flexWrap="wrap">
              {props.emails?.map((value, index) => (
                <Chip
                  data-testid='EntityPermissions_AddUsers_popup_EmailChip'
                  color="primary"
                  size={"small"}
                  variant="outlined"
                  label={value}
                  deleteIcon={<ClearIcon />}
                  onDelete={() => props.deleteEmail(index)}
                />
              ))}
            </StyledStack>
          )}
        </AddedEmailBox>
      </StyledFormBox>
      <StyledHelperText>
        <ErrorIcon color="primary" />
        <Typography data-testid='EntityPermissions_AddUsers_popup_warning'>
          Users added will be sent an invite email immediately. The invite will be valid for 7 days
          from today.
        </Typography>
      </StyledHelperText>
      <BtnWrp>
        <CustomButton data-testid='EntityPermissions_AddUsers_popup_AddBtn' onClick={props.inviteHandler} variant="contained" color="primary">
          Ok, add and invite them
        </CustomButton>
        <CustomButton data-testid='EntityPermissions_AddUsers_popup_CanselBtn' onClick={props.onClose} variant="outlined" color="inherit">
          Cancel
        </CustomButton>
      </BtnWrp>
    </CustomModal>
  );
});

const StyledFormBox = styled(Box)`
  padding: 60px 60px 40px;
`;

const StyledHelperText = styled.div`
  padding: 10px 60px;
  display: flex;
  align-items: flex-start;
  gap: 5px;
  background-color: var(--colorPaletteBlueBackground3);
`;

const BtnWrp = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 24px 60px 48px;
`;

const Header = styled.h1`
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0 0 20px;
`;

const Typography = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const StyledTextField = styled(TextField)`
  margin-top: 20px !important;
`;

const AddedEmailBox = styled.div`
  height: 150px;
  margin-top: 20px;
  overflow: auto;
  border: 1px solid var(--colorNeutralBackground3);
  background: var(--colorPaletteGrayBackground1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 12px;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: var(--colorNeutralForeground5);
  }
`;

const StyledStack = styled(Stack)`
  margin-top: 10px;
`;
