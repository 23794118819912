import { ReactElement } from "react";

const TranslationsIcon = (): ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M6.99203 0.335938C3.31203 0.335938 0.332031 3.3226 0.332031 7.0026C0.332031 10.6826 3.31203 13.6693 6.99203 13.6693C10.6787 13.6693 13.6654 10.6826 13.6654 7.0026C13.6654 3.3226 10.6787 0.335938 6.99203 0.335938ZM11.612 4.33594H9.64536C9.43203 3.5026 9.12536 2.7026 8.72536 1.9626C9.95203 2.3826 10.972 3.23594 11.612 4.33594ZM6.9987 1.69594C7.55203 2.49594 7.98536 3.3826 8.27203 4.33594H5.72536C6.01203 3.3826 6.44536 2.49594 6.9987 1.69594ZM1.8387 8.33594C1.73203 7.90927 1.66536 7.4626 1.66536 7.0026C1.66536 6.5426 1.73203 6.09594 1.8387 5.66927H4.09203C4.0387 6.10927 3.9987 6.54927 3.9987 7.0026C3.9987 7.45594 4.0387 7.89594 4.09203 8.33594H1.8387ZM2.38536 9.66927H4.35203C4.56536 10.5026 4.87203 11.3026 5.27203 12.0426C4.04536 11.6226 3.02536 10.7759 2.38536 9.66927ZM4.35203 4.33594H2.38536C3.02536 3.22927 4.04536 2.3826 5.27203 1.9626C4.87203 2.7026 4.56536 3.5026 4.35203 4.33594ZM6.9987 12.3093C6.44536 11.5093 6.01203 10.6226 5.72536 9.66927H8.27203C7.98536 10.6226 7.55203 11.5093 6.9987 12.3093ZM8.5587 8.33594H5.4387C5.3787 7.89594 5.33203 7.45594 5.33203 7.0026C5.33203 6.54927 5.3787 6.1026 5.4387 5.66927H8.5587C8.6187 6.1026 8.66536 6.54927 8.66536 7.0026C8.66536 7.45594 8.6187 7.89594 8.5587 8.33594ZM8.72536 12.0426C9.12536 11.3026 9.43203 10.5026 9.64536 9.66927H11.612C10.972 10.7693 9.95203 11.6226 8.72536 12.0426ZM9.90536 8.33594C9.9587 7.89594 9.9987 7.45594 9.9987 7.0026C9.9987 6.54927 9.9587 6.10927 9.90536 5.66927H12.1587C12.2654 6.09594 12.332 6.5426 12.332 7.0026C12.332 7.4626 12.2654 7.90927 12.1587 8.33594H9.90536Z"
        fill="#707882"
      />
    </svg>
  );
};

export default TranslationsIcon;
