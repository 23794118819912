import { FC } from "react";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import { Box, Typography, TextField, Checkbox, FormGroup, FormControlLabel, FormControl } from "@mui/material"
import store from "../store";
import { observer } from "mobx-react";

type SaveAsTemplatePopupProps = {

};

const SaveAsTemplatePopup: FC<SaveAsTemplatePopupProps> = observer((props) => {
  return (
    <PopupWrapper id="SaveAsTemplatePopup_Container">
      <Styledh2 id="SaveAsTemplatePopup_Title">Save “{store.selectedRow.name}” as a new template</Styledh2>
      <BoxStyled>
        <TypographyStyled id="SaveAsTemplatePopup_Subtitle">
          To be included in the template:
        </TypographyStyled>
        <FormControl>
          <FormGroup>
            <FormControlLabel
              id="SaveAsTemplatePopup_checbox_1_Label"
              checked={store.checkedSurvQuest}
              onChange={store.valueSurvChangeQuest}
              control={<Checkbox id="SaveAsTemplatePopup_checbox_1_Input" />} label={<p id="SaveAsTemplatePopup_checbox_1_Input_Text">Survey questions and settings</p>} />
            <FormControlLabel
              id="SaveAsTemplatePopup_checbox_2_Label"
              checked={store.checkedSurvComm}
              onChange={store.valueSurvChangeComm}
              control={<Checkbox id="SaveAsTemplatePopup_checbox_2_Input" />} label={<p id="SaveAsTemplatePopup_checbox_2_Input_Text">Survey communications</p>} />
          </FormGroup>
        </FormControl>
        <TypographyErrorText id="SaveAsTemplatePopup_checbox_Errors">{store.errorSurvTextChecked}</TypographyErrorText>
        <Typography id="SaveAsTemplatePopup_Footer_Title">
          Once saved as a template, you may choose to reuse the template within any surveys later on.
        </Typography>
      </BoxStyled>

      <BtnBox>
        <CustomButtonSave
          id="SaveAsTemplatePopup_Save_Btn"
          variant="contained"
          disabled={store.isSaveAsTempBtnDisabled}
          onClick={() => {
            store.saveAsTempSumbit(store.selectedRow.id)
          }} >
          Save
        </CustomButtonSave>
        <CustomButtonStyled
          id="SaveAsTemplatePopup_Cansel_Btn"
          variant="outlined" onClick={() => {
          store.openClickSaveAsTempPopup(false)
        }}>
          Cancel</CustomButtonStyled>
      </BtnBox>

    </PopupWrapper>
  );
});

const PopupWrapper = styled.div`
  width: 500px;
  height: 450px;
`
const Styledh2 = styled.h2`
  margin: 70px 50px 0px 70px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
`
const BoxStyled = styled(Box)`
  margin: 40px 50px 0px 70px;
  width: 420px;
`
const TypographyStyled = styled(Typography)`
display: block;
margin-bottom: 30px !important;
`
const BtnBox = styled(Box)`
margin: 40px 50px 0px 70px;
width: 250px;
display: flex;
justify-content: space-between;
`
const CustomButtonStyled = styled(CustomButton)`
border: 1px solid var(--colorNeutralForeground5, #959ba2) !important;
border-radius: 4px;
color:  var(--colorNeutralForeground2, #707882) !important;
width: 90px;
height: 34px;
`
const CustomButtonSave = styled(CustomButton)`
width: 130px;
height: 34px;
`

const TypographyErrorText = styled(Typography)`
color: red;
font-size: 10px;
`


export default SaveAsTemplatePopup;