import http from "api/https";
import { TRecipientsFilterSearch } from "types/recipients";

export const getAllEmployeesRecipients = (survey_id: number, value: string, attributes: TRecipientsFilterSearch[], page: number, limit: number, sort: string, order: boolean): Promise<any> => {
  if (http.isAPIGO) {
    return http.postGO("/sm/sm_survey_recepients/GetAllEmployeesRecipients", {
      survey_id: survey_id,
      value: value,
      attributes: attributes,
      page: page,
      limit: limit,
      sort: sort,
      order: order
    });
  } else {
    return http.post("/sm_survey_recepients/GetAllEmployeesRecipients", {
      survey_id: survey_id,
      attributes: attributes,
    });
  }
};
