import http from "api/https";
import {
  ReminderMessage,
} from "types/templates";

export const SaveTemplateReminderMessages = async (data: ReminderMessage): Promise<any> => {
  if(http.isAPIGO){
    return http.postGO('/template/save_template_reminder_messages', data)
  } else {
    return http.post('/templ_template_comms/SaveTemplateReminderMessages', data)
  }
};
