import http from "api/https";
import { sm_survey } from "types/surveys";

export const saveSurvey = (data: sm_survey): Promise<any> => {
  if (http.isAPIGO) {
    return http.putGO("/sm/sm_survey", data);
  } else {
    return http.put("/sm_survey/" + data.id, data);
  }
};
