import { FC } from "react";

import styled from "styled-components";

type EmptyStateProps = {};

export const EmptyState: FC<EmptyStateProps> = () => {
    return (
        <EmptyStateContainer>
            <Text>
              Overall score, eNPS or dimensions must be used in a survey for segments to be available.
            </Text>
        </EmptyStateContainer>
    );
};

const EmptyStateContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: var(--colorNeutralForeground2);
`;
