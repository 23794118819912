import { FC, useEffect } from "react";
import { IconXlsx } from "./IconXlsx";

import styled from "styled-components";
import Button from "components/button/Button";
import { observer } from "mobx-react";
import useDownloadChangeLog from "api/go/useDownloadChangeLog";
import store from "./store";

const PreviousImports: FC = observer(() => {
  const formName = "PreviousImports";

  useEffect(() => {
    store.loadPreviousImports();
  }, []);

  if (store.previousImports.length === 0) return null;
  return (
    <ReviewChangesWrapper id={`${formName}_reviewChangesWrapper`}>
      <Content id={`${formName}_content`}>
        <Header id={`${formName}_header`}>Previous imports</Header>
        <HeaderContent id={`${formName}_headerContent`}>
          <HeaderText id={`${formName}_headerText`}>Displaying latest 3 only</HeaderText>
        </HeaderContent>
        {store.previousImports.map(({ id }, index) => (
          <div key={index}>
            <ChangeLogHeader id={`${formName}_changeLogHeader_${index}`}>
              <span id={`${formName}_changelogSpan_${index}`}>Changelog</span>
              <p id={`${formName}_importedDate_${index}`}>{store.formattedDate[index]}</p>
            </ChangeLogHeader>
            <ChangeLogFile id={`${formName}_changeLogFile_${index}`}>
              <IconXlsx
                id={`${formName}_iconXlsx_${index}`}
                style={{ width: "16px", height: "16px" }}
              />
              <p id={`${formName}_changelogText_${index}`}>Change log (xlsx):</p>
              <DownloadButton
                id={`${formName}_downloadButton_${index}`}
                variant="text"
                onClick={() => useDownloadChangeLog(id)}
              >
                Download
              </DownloadButton>
            </ChangeLogFile>
          </div>
        ))}
      </Content>
    </ReviewChangesWrapper>
  );
});

export default PreviousImports;

const Header = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0 0 20px 0;
`;

const HeaderText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  color: var(--colorNeutralForeground2);
`;

const ReviewChangesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const HeaderContent = styled.div`
  width: 100%;
  max-width: 660px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
`;

const Content = styled.div`
  width: 100%;
  max-width: 660px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
`;

const ChangeLogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 32px;

  span,
  p {
    font-weight: 400;
  }

  span {
    font-size: 16px;
    line-height: 24px;
    color: var(--colorNeutralForeground1);
  }

  p {
    font-size: 14px;
    line-height: 20px;
    color: var(--colorNeutralForeground2);
    margin-left: 8px;
  }
`;

const ChangeLogFile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--colorNeutralForeground1);
    margin: 0 10px 0 8px;
  }
`;

const DownloadButton = styled(Button)`
  text-decoration: underline !important;
`;
