import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Box, Typography, TextField } from "@mui/material";

import CustomButton from "components/button/Button";
import store from "../store";

type NonTestPopupProps = {
  closePopup: () => void;
};

const NonTestPopup: FC<NonTestPopupProps> = observer((props) => {
  return (
    <PopupWrapper>
      <div>
        <Styledh2 id="Survey_Project_PopupDelete_TitleAndName">
          Are you sure you want to delete “{store.selectedRow.name}”?
        </Styledh2>
        <StyledTypography id="Survey_Project_PopupDelete_Description">
          All surveys, survey data and dashboard data within the project will be lost.
        </StyledTypography>
      </div>
      <StyledTypography id="Survey_Project_PopupDelete_Description_Delete">
        To confirm deletion, please enter DELETE below:
      </StyledTypography>
      <BoxTextField
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          store.DeleteSubmit(store.selectedRow.id, props.closePopup);
        }}
      >
        <TextFieldStyled
          id="Survey_Project_PopupDelete_DeleteInput"
          label="DELETE"
          variant="standard"
          autoComplete="off"
          value={store.deleteValue}
          onChange={store.onDeleteChange}
        />
        <TypographyErrorText id="Survey_Project_PopupDelete_DeleteInput_errorMessage">
          {store.errorTextDelete}
        </TypographyErrorText>
        <BtnBox>
          <CustomButton
            id="Survey_Project_PopupDelete_ConfirmDelete_Button"
            type="submit"
            variant="contained"
            disabled={store.isDeleteBtnDisabled}
          >
            Delete project
          </CustomButton>
          <CustomButtonStyled
            id="Survey_Project_PopupDelete_CancelDelete_Button"
            onClick={() => {
              store.clearDeleteValues();
              props.closePopup();
            }}
            variant="outlined"
          >
            Cancel
          </CustomButtonStyled>
        </BtnBox>
      </BoxTextField>
    </PopupWrapper>
  );
});

const PopupWrapper = styled.div`
  padding: 40px 42px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Styledh2 = styled.h2`
  color: var(--colorNeutralForeground1);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin: 0 0 20px;
`;

const StyledTypography = styled(Typography)`
  color: var(--colorNeutralForeground1);
  font-size: 14px;
  font-style: normal;
`;

const BtnBox = styled(Box)`
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const CustomButtonStyled = styled(CustomButton)`
  border: 1px solid var(--colorNeutralForeground5) !important;
  border-radius: 4px;
  color: var(--colorNeutralForeground2) !important;
  width: 90px;
  height: 34px;
`;

const BoxTextField = styled(Box)``;

const TypographyErrorText = styled(Typography)`
  color: var(--colorPaletteRedForeground2);
  font-size: 10px;
`;

const TextFieldStyled = styled(TextField)`
  color: var(--colorNeutralBackground3) !important;
  width: 100%;
`;

export default NonTestPopup;
