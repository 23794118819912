import { FC } from "react";
import { observer } from "mobx-react";

import store from "./store";
import styled from "styled-components";

type DimensionsListProps = {};

const DimensionsList: FC<DimensionsListProps> = observer((props) => {
  return (
    <Container>
      <List>
        {store.scoredQuestionsList.map((el) => (
          <ListItem
            $active={el?.dimension === store.activeScoredQuestionDimension?.dimension}
            key={el.dimension}
            onClick={() => {
              const anchor = document.getElementById(`scored-group-anchor-${el.dimension}`);
              store.setData(el, "activeScoredQuestionDimension");
              anchor.scrollIntoView({ block: "start", behavior: "smooth" });
            }}
          >
            {el.dimension}
          </ListItem>
        ))}
      </List>
    </Container>
  );
});

export default DimensionsList;

const Container = styled.div`
  width: 100%;
  max-width: 191px;
  border-radius: 0px 16px 16px 0px;
  box-shadow: 0px 2px 5px 0px var(--colorNeutralBackground3);
  position: sticky;
  top: 322px;
`;

const List = styled.div`
  width: 100%;
  display: flex;
  height: calc(100vh - 322px);
  flex-direction: column;
  padding: 28px 0px;
  border-radius: 0px 16px 16px 0px;
  background-color: var(--colorNeutralBackground1);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const ListItem = styled.span<{ $active: boolean }>`
  width: 100%;
  padding: ${(props) => (props.$active ? "8px 26px" : "8px 26px 8px 30px")};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: ${(props) =>
    props.$active ? "var(--colorBrandForeground1)" : "var(--colorNeutralForeground1)"};
  ${(props) => props.$active && "border-left: 4px solid var(--colorBrandForeground1)"};
  overflow-wrap: anywhere;
  cursor: pointer;
  background-color: var(--colorNeutralBackground1);
  margin: 4px 0px;
`;
