import http from "../https";

import { TGetDashboardResponcesOpenEndedResponsesAsyncResponse } from "types/dashboard";

type TRequestBody = {
    survey_id: number;
    attributes: {
        id: number;
        name: string;
        segments: string[];
    }[];
    question_id: number;
    sentiment?: string;
    benchmarkType?: number;
    topic: string;
    pagination: {
        offset: number;
        limit: number;
    };
};
export const getDashboardResponcesOpenEndedResponsesByTopicAsync = async (
    data: TRequestBody
): Promise<TGetDashboardResponcesOpenEndedResponsesAsyncResponse> => {
    try {
        const response = await http.post(`/Dashboard/responces/openend/responces/topics/list`, data);

        if ([200, 201].includes(response.status)) {
            return response.data;
        }

        throw new Error(`Failed to fetch responses by topic (HTTP status code: ${response.status})`);
    } catch (error) {
        console.error(error);
    }
};
