import { createUrl } from "helpers/url";
import http from "../https";
import { AxiosResponse } from "axios";

class UnexpectedApiError extends Error {
  constructor(response: AxiosResponse<any, any>) {
    super();
  }
}

export const useGetPermissions = async (entityId: number) => {
  let response: AxiosResponse<any, any>;
  const query: { entityId: string } = {
    entityId: entityId.toString(),
  };
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/permission/GetViewPermission",
      query: query,
    });
    response = await http.getGO(url);
  } else {
    const url = createUrl({
      path: "/accounts/GetViewPermission",
      query: query,
    });
    response = await http.get(url);
  }
  if ([200, 201].includes(response.status)) {
    return response.data;
  }
  throw new UnexpectedApiError(response);
};
