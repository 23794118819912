import { userApiClient } from "../../components/ApiHelper";
import { OrganizationUnit } from 'types/organization_unit';
import http from "api/https";


export const GetStructureToEmployee = (
  idEntity: number
): Promise<any> => {


  if (http.isAPIGO) {
    return http.getGO("/employee/organization_unit/GetByEntityId?entity_id=" + idEntity)

  } else {
    return http.get("/organization_unit/GetByentity_id?entity_id=" + idEntity)
  }
};
