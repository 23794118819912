import { FC } from "react";
import { WEEKDAY } from "constants/weekDay";
import { observer } from "mobx-react-lite";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  SelectChangeEvent,
  Select,
} from "@mui/material";

import store from "./store";
import styled from "styled-components";

import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useGetFullDayHook } from "utils/useGetFullDayHook";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

type SurveyFrequencyProps = {};

const SurveyFrequency: FC<SurveyFrequencyProps> = observer(() => {
  const frequency_repeat_every_count_array = [...Array(31)].map((_, i) => i);
  const frequency_repeat_every_week_array = [...Array(4)].map((_, i) => i);
  const frequency_repeat_every_month_array = [...Array(12)].map((_, i) => i);
  const frequency_repeat_every_year_array = [...Array(5)].map((_, i) => i);

  return (
    <Container>
      <Title id="Survey_Project_CreatePopup_Frequency_Title">Survey frequency</Title>
      <Description id="Survey_Project_CreatePopup_Frequency_Description">Repeat survey Send out on the</Description>
      <Box>
        <Text id="Survey_Project_CreatePopup_Frequency_Text_Repeat_Every">Repeat every</Text>
        <StyledFormControl>
          <StyledSelect
            id="Survey_Project_CreatePopup_Frequency_Repeat_Every_Count"
            value={store.frequency_repeat_every_count}
            onChange={(e: SelectChangeEvent<number>) =>
              store.setData(e.target.value, "frequency_repeat_every_count")
            }
            displayEmpty
          >
            {store.frequency_repeat_every_period === "day" &&
              frequency_repeat_every_count_array.map((el) => (
                <MenuItem value={el + 1} key={el}>
                  {el + 1}
                </MenuItem>
              ))}
            {store.frequency_repeat_every_period === "week" &&
              frequency_repeat_every_week_array.map((el) => (
                <MenuItem value={el + 1} key={el}>
                  {el + 1}
                </MenuItem>
              ))}
            {store.frequency_repeat_every_period === "month" &&
              frequency_repeat_every_month_array.map((el) => (
                <MenuItem value={el + 1} key={el}>
                  {el + 1}
                </MenuItem>
              ))}
            {store.frequency_repeat_every_period === "year" &&
              frequency_repeat_every_year_array.map((el) => (
                <MenuItem value={el + 1} key={el}>
                  {el + 1}
                </MenuItem>
              ))}
          </StyledSelect>
        </StyledFormControl>
        <StyledFormControl>
          <StyledSelect
            id="Survey_Project_CreatePopup_Frequency_Repeat_Every_Period"
            value={store.frequency_repeat_every_period}
            onChange={(e: SelectChangeEvent<string>) =>
              store.setData(e.target.value, "frequency_repeat_every_period")
            }
            displayEmpty
          >
            <MenuItem id="Survey_Project_CreatePopup_Frequency_Repeat_Every_Period_day" value={"day"}>day</MenuItem>
            <MenuItem id="Survey_Project_CreatePopup_Frequency_Repeat_Every_Period_week" value={"week"}>week</MenuItem>
            <MenuItem id="Survey_Project_CreatePopup_Frequency_Repeat_Every_Period_month" value={"month"}>month</MenuItem>
            <MenuItem id="Survey_Project_CreatePopup_Frequency_Repeat_Every_Period_year" value={"year"}>year</MenuItem>
          </StyledSelect>
        </StyledFormControl>
      </Box>
      {store.frequency_repeat_every_period !== "day" && (
        <>
          <Description id="Survey_Project_CreatePopup_Frequency_OccursEvery_Title">Occurs every</Description>
          <Box>
            {WEEKDAY.map((day) => (
              <DayItem
                id={"Survey_Project_CreatePopup_Frequency_OccursEvery_Day-" + day}
                onClick={() => store.setData(day, "frequencyDay")}
                $active={store.frequencyDay === day}
                key={day}
              >
                {day}
              </DayItem>
            ))}
          </Box>
        </>
      )}
      {store.frequency_repeat_every_period === "day" ? (
        <Text id="Survey_Project_CreatePopup_Frequency_OccursEvery_Description">
          Survey questions will be sent every{" "}
          <b>
            {store.frequency_repeat_every_count}{" "}
            {store.frequency_repeat_every_count === 1 ? "day" : "days"}
          </b>{" "}
          to employees. Full reports with recommendations will be available when the survey ends.
          You may also check out your dashboard for real-time data.
        </Text>
      ) : store.frequency_repeat_every_period === "week" ? (
        <Text id="Survey_Project_CreatePopup_Frequency_OccursEvery_Description">
          Survey questions will be sent every{" "}
          <b>
            {store.frequency_repeat_every_count > 1 ? store.frequency_repeat_every_count : ""}{" "}
            {store.frequency_repeat_every_count === 1 ? "week" : "weeks"}
          </b>{" "}
          to employees. Full reports with recommendations will be available when the survey ends.
          You may also check out your dashboard for real-time data.
        </Text>
      ) : store.frequency_repeat_every_period === "month" ? (
        <Text id="Survey_Project_CreatePopup_Frequency_OccursEvery_Description">
          Surveys will be sent every{" "}
          <b>
            {store.frequency_repeat_every_count}{" "}
            {store.frequency_repeat_every_count === 1 ? "month" : "months"}
          </b>{" "}
          on the chosen day. The week that survey will be sent out will correspond with the 1st
          survey launch date. For example, if the 1st survey is launched on 2nd{" "}
          {useGetFullDayHook(store.frequencyDay)} of every{" "}
          <b>
            {store.frequency_repeat_every_count}{" "}
            {store.frequency_repeat_every_count === 1 ? "month" : "months"}
          </b>
          , subsequent surveys will be launched on 2nd {useGetFullDayHook(store.frequencyDay)} of
          every{" "}
          <b>
            {store.frequency_repeat_every_count}{" "}
            {store.frequency_repeat_every_count === 1 ? "month" : "months"}
          </b>
          .
        </Text>
      ) : (
        <Text id="Survey_Project_CreatePopup_Frequency_OccursEvery_Description">
          Surveys will be sent every{" "}
          <b>
            {store.frequency_repeat_every_count}{" "}
            {store.frequency_repeat_every_count === 1 ? "year" : "years"}
          </b>{" "}
          on the chosen day. The week that survey will be sent out will correspond with the 1st
          survey launch date. For example, if the 1st survey is launched on 2nd{" "}
          {useGetFullDayHook(store.frequencyDay)} of every{" "}
          <b>
            {store.frequency_repeat_every_count}{" "}
            {store.frequency_repeat_every_count === 1 ? "year" : "years"}
          </b>
          , subsequent surveys will be launched on 2nd {useGetFullDayHook(store.frequencyDay)} of
          every{" "}
          <b>
            {store.frequency_repeat_every_count}{" "}
            {store.frequency_repeat_every_count === 1 ? "year" : "years"}
          </b>
          .
        </Text>
      )}

      {(store.frequency_repeat_every_period === "month" ||
        store.frequency_repeat_every_period === "year") && (
          <Text id="Survey_Project_CreatePopup_Frequency_OccursEvery_Description_MonthOrYear">
            <br />
            Full reports with recommendations will be available when the survey ends. You may also
            check out your dashboard for real-time data.
          </Text>
        )}
      <CalendarWrapper>
        <Description id="Survey_Project_CreatePopup_Frequency_EndDate_Title">
          project End Date <b>*</b>
        </Description>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StyledCalendar
            disabled={store.isKeepItUnscheduled}
            value={store.endDate}
            onChange={(value: Dayjs) => store.setData(value, "endDate")}
            disablePast
            minDate={dayjs().add(15, "minutes")}
            label={"Select date and time"}
            closeOnSelect={false}
            onError={(error) => store.setData(error ? true : false, "isErrorFrequensyDate")}
          />
        </LocalizationProvider>
        <CalendarDescription id="Survey_Project_CreatePopup_Frequency_EndDate_Description">Local timezone: UTC +8:00</CalendarDescription>
      </CalendarWrapper>
      <CheckBoxContainer>
        <StyledFormControlLabel
          control={
            <StyledCheckBox
            id="Survey_Project_CreatePopup_Frequency_unscheduled_CheckBox"
              checked={store.isKeepItUnscheduled}
              onChange={() => store.setData(!store.isKeepItUnscheduled, "isKeepItUnscheduled")}
            />
          }
          label={<span id="Survey_Project_CreatePopup_Frequency_unscheduled_CheckBox_Label">Keep it unscheduled (I’ll decide later)</span>}
        />
      </CheckBoxContainer>
    </Container>
  );
});

export default SurveyFrequency;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--colorNeutralForeground4);
  margin-bottom: 30px;
`;

const Title = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
  margin-bottom: 30px;
`;

const Description = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
  text-transform: uppercase;
  margin-bottom: 16px;

  b {
    color: var(--colorPaletteRedForeground2);
  }
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const CheckBoxContainer = styled.div`
  width: 100%;
  margin: 20px 0px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: var(--colorNeutralForeground2);

  .MuiCheckbox-root {
    &:hover {
      background-color: transparent !important;
    }
  }
`;

const StyledCheckBox = styled(Checkbox)`
  padding: 0px 10px !important;
  color: var(--colorBrandForeground1) !important;
`;

const DayItem = styled.span<{ $active: boolean }>`
  padding: 7px 12px;
  border-radius: 4px;
  border: 1px solid var(--colorPaletteBlueBackground1);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: ${(props) =>
    props.$active ? "var(--colorNeutralForeground1)" : "var(--colorNeutralForeground5)"};
  margin-right: 10px;
  cursor: pointer;
  background-color: ${(props) =>
    props.$active ? "var(--colorPaletteBlueBackground3)" : "transparent"};
`;

const CalendarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const StyledCalendar = styled(DateTimePicker)`
  width: 100%;
  max-width: 246px;
  border-radius: 2px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1) !important;

  :before {
    border-bottom: none !important;
  }
  :after {
    border-bottom: none !important;
  }

  input {
    padding: 7px !important;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  } 
    label {
        position: absolute;
        top: -8px;
    }
`;

const CalendarDescription = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground5);
`;

const StyledSelect = styled(Select)`
  height: 34px;
  width: 100%;
  max-width: 225px;

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }
`;

const StyledFormControl = styled(FormControl)`
  margin: 0px 4px !important;
`;
