import http from "api/https";
import { Dayjs } from "dayjs";
import { MilestoneDaysSurveyProject } from "types/entityAttribute"

export const AddOneOffProject =
  async (entity_id: number,
    project_type: string,
    name: string,
    minResponses: number,
    is_test: boolean,
    is_triggers_required: boolean,
    trigger_attribute_id?: number,
    trigger_attribute_value?: string[]): Promise<any> => {

    let data = {
      id: 0,
      entity_id: entity_id,
      projecttype_code: project_type,
      min_responses: minResponses,
      test: is_test,
      name: name,
      trigger_attribute_id: trigger_attribute_id,
      trigger_attribute_value: trigger_attribute_value,
      is_triggers_required: is_triggers_required
    }
    return http.post('/sm_project', data)
  };


export const AddMilestonesProject =
  async (entity_id: number,
    project_type: string,
    name: string,
    minResponses: number,
    is_test: boolean,
    milestones_days: MilestoneDaysSurveyProject[],
    milestones_date_attribute_id: number,
    is_triggers_required: boolean,
    trigger_attribute_id?: number,
    trigger_attribute_value?: string[]): Promise<any> => {

    let data = {
      id: 0,
      entity_id: entity_id,
      projecttype_code: project_type,
      min_responses: minResponses,
      test: is_test,
      name: name,
      milestones_days: milestones_days,
      dateAttributeMilestone_id: milestones_date_attribute_id,
      trigger_attribute_id: trigger_attribute_id,
      trigger_attribute_value: trigger_attribute_value,
      is_triggers_required: is_triggers_required
    }
    return http.post('/sm_project', data)
  };

export const AddFrequencyProject =
  async (entity_id: number,
    project_type: string,
    name: string,
    minResponses: number,
    is_test: boolean,
    frequency_repeat_every_period: string,
    frequency_repeat_every_count: number,
    frequencyDay: number,
    endDate: Dayjs,
    is_triggers_required: boolean,
    trigger_attribute_id?: number,
    trigger_attribute_value?: string[]
  ): Promise<any> => {

    let data = {
      id: 0,
      entity_id: entity_id,
      projecttype_code: project_type,
      min_responses: minResponses,
      test: is_test,
      name: name,
      repeat_type: frequency_repeat_every_period,
      repeat_value: frequency_repeat_every_count,
      week_day: frequencyDay,
      date_end: endDate?.format(),
      trigger_attribute_id: trigger_attribute_id,
      trigger_attribute_value: trigger_attribute_value,
      is_triggers_required: is_triggers_required
    }
    return http.post('/sm_project', data)
  };

export const AddOneOffProjectCustom =
  async (entity_id: number,
    project_type: string,
    name: string,
    minResponses: number,
    is_test: boolean,
    is_triggers_required: boolean,
    trigger_attribute_id?: number,
    trigger_attribute_value?: string[]): Promise<any> => {

    let data = {
      id: 0,
      entity_id: entity_id,
      projecttype_code: project_type,
      min_responses: minResponses,
      test: is_test,
      name: name,
      trigger_attribute_id: trigger_attribute_id,
      trigger_attribute_value: trigger_attribute_value,
      is_triggers_required: is_triggers_required
    }
    if(http.isAPIGO){
      return http.postGO('/sm/sm_project/CreateNewCustom', data)
    }else{
      return http.post('/sm_project/CreateNewCustom', data)
    }
  };

export const AddMilestonesProjectCustom =
  async (entity_id: number,
    project_type: string,
    name: string,
    minResponses: number,
    is_test: boolean,
    milestones_days: MilestoneDaysSurveyProject[],
    milestones_date_attribute_id: number,
    is_triggers_required: boolean,
    trigger_attribute_id?: number,
    trigger_attribute_value?: string[]): Promise<any> => {

    let data = {
      id: 0,
      entity_id: entity_id,
      projecttype_code: project_type,
      min_responses: minResponses,
      test: is_test,
      name: name,
      milestones_days: milestones_days,
      dateAttributeMilestone_id: milestones_date_attribute_id,
      trigger_attribute_id: trigger_attribute_id,
      trigger_attribute_value: trigger_attribute_value,
      is_triggers_required: is_triggers_required
    }
    if(http.isAPIGO){
      return http.postGO('/sm/sm_project/CreateNewCustom', data)
    }else{
      return http.post('/sm_project/CreateNewCustom', data)
    }
  };

export const AddFrequencyProjectCustom =
  async (entity_id: number,
    project_type: string,
    name: string,
    minResponses: number,
    is_test: boolean,
    frequency_repeat_every_period: string,
    frequency_repeat_every_count: number,
    frequencyDay: number,
    endDate: Dayjs,
    is_triggers_required: boolean,
    trigger_attribute_id?: number,
    trigger_attribute_value?: string[]
  ): Promise<any> => {

    let data = {
      id: 0,
      entity_id: entity_id,
      projecttype_code: project_type,
      min_responses: minResponses,
      test: is_test,
      name: name,
      repeat_type: frequency_repeat_every_period,
      repeat_value: frequency_repeat_every_count,
      week_day: frequencyDay,
      date_end: endDate?.toISOString(),
      trigger_attribute_id: trigger_attribute_id,
      trigger_attribute_value: trigger_attribute_value,
      is_triggers_required: is_triggers_required
    }
    if(http.isAPIGO){
      return http.postGO('/sm/sm_project/CreateNewCustom', data)
    }else{
      return http.post('/sm_project/CreateNewCustom', data)
    }
  };
