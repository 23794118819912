import { FC, useEffect } from "react";
import BasicTable from './RolesTable'
import styled from "styled-components";
import AddIcon from '@mui/icons-material/Add';
import AddEditRoleName from './AddEditRole/popupAddEditRoleName'
import store from './store'
import { observer } from "mobx-react";
import CustomButton from "components/button/Button";
import { GrantPermissions } from "features/Employees/GrantPermissions";
import storeEmployeess from "features/Employees/GrantPermissions/store"
import { ORG_STRUCTURE_CODES } from "constants/entityManagementTypes";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import StorePermission from "components/workspaces-sidebar/StorePermission";

type EntityPermissionsRolesProps = {
  changeTab: (tab: string) => void;
};

const EntityPermissionsRoles: FC<EntityPermissionsRolesProps> = observer((props) => {

  useEffect(() => {
    store.doLoad(true)
  }, []);


  useEffect(() => {
    storeEmployeess.getCurrentEntity(StoreLayout.currentEntityId);
  }, [StoreLayout.currentEntityId]);
  
  return <>

    <HeaderActions>
      <Header id={`EntityPermissions_EntityRoles_Title`}>
        Permissions roles
      </Header>

      <ButtonWrapper>
        <CustomButton
        id={`EntityPermissions_EntityRoles_AddNewManagersInRoles_Btn`}
        onClick={() => {
          store.changeopenPanelGrant(true)
        }}>
          <AddIcon />
          {storeEmployeess.saved_org_structure_code === ORG_STRUCTURE_CODES.manager_based
           ? "Add new managers in roles"
           : "Add new unit head in roles"
          }
        </CustomButton>
        <CustomButton
        id={`EntityPermissions_EntityRoles_CreateNewRole_Btn`}
        disabled={!StorePermission.entityPermissions.editEntityPermissions.hasPermission}
        variant="contained" onClick={() => {
          store.changeAddEditRoleNamePanel(true)
        }}>
          <AddIcon />
          Create new role
        </CustomButton>
      </ButtonWrapper>

    </HeaderActions>

    <GrantPermissions openPanel={store.openPanelGrant} onClose={() => { store.changeopenPanelGrant(false) }} />

    <AddEditRoleName
    id={`EntityPermissions_EntityRoles`}
      openPanel={store.addEditRoleNamePanel}
      onBtnCancelClick={() => store.changeAddEditRoleNamePanel(false)}
      onClose={() => store.changeAddEditRoleNamePanel(false)}
      onSaved={(id) => {
        store.changeAddEditRoleNamePanel(false)
        store.doLoad()
      }}
      idRole={0} roleName={""}
    />

    <BasicTable changeTab={props.changeTab} />
  </>
})


const HeaderActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;


const Header = styled.h1`
  font-family: Roboto;
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export default EntityPermissionsRoles;
