import http from "api/https";


export const duplicateQuestionnaireTemplate = (id:number, entity_id: number): Promise<any> => {
  let data = {
    id: id,
    entity_id: entity_id
  }
  if(http.isAPIGO){
    return http.postGO('/template/dublicate_questionnaire_template', data)
  } else {
    return http.post('/templ_template_questionary/DuplicateQuestionnaireTemplate', data)
  }
};
