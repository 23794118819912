import http from "api/https";
import { TProjectForEdit } from "types/entityAttribute";

export const UpdateProjects = async (data: TProjectForEdit): Promise<any> => {
  if (http.isAPIGO) {
    return http.postGO("/sm/sm_project/CustomUpdateProject", data);
  } else {
    return http.post("/sm_project/CustomUpdateProject", data);
  }
};
