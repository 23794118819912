import { MouseEvent, useEffect, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Table as MuiTable,
  TableHead,
  Checkbox,
  FormControlLabel,
  Paper,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import CustomButton from "components/button/Button";
import storeAddEditAttribute from "features/Employees/AttributeList/AddEditAttribute/store";
import { EmployeeAttribute } from "types/employee";
import storeSegment from "features/Employees/AttributeList/storeSegments";
import store from "./store";

const DataTable = observer((props: any) => {
  const [sortModel, setSortModel] = useState({ field: null, sort: null });

  useEffect(() => {
    store.changeSelected([]);
    storeSegment.clearStore();
  }, []);

  const changeSort = (field: string, sort: string) => {
    setSortModel({ field, sort });
  };

  const sortedData = [...store.data.slice().sort((a, b) => b.id - a.id)].sort((a, b) => {
    const { sort } = sortModel;
    const left = a[sortModel.field];
    const right = b[sortModel.field];

    if (sort !== "asc" && sort !== "desc") {
      return 0;
    }

    if ((sort === "asc" && left < right) || (sort === "desc" && left > right)) {
      return -1;
    }

    return 1;
  });

  const fieldElement = (
    <>
      <TextFieldStyledAttribute
        data-testid={`Data_AtributGrid_AddEdit_Input`}
        fullWidth
        variant="standard"
        value={storeAddEditAttribute.name}
        placeholder="Attribute name"
        error={storeAddEditAttribute.errorName !== ""}
        helperText={storeAddEditAttribute.errorName}
        onClick={(e) => e.stopPropagation()}
        inputProps={{
          pattern: "[a-zA-Z0-9s ]+",
        }}
        onChange={(e) => storeAddEditAttribute.changeFieldAttributeValue(e.target)}
      />
      {storeAddEditAttribute.id === 0 && (
        <StyledFormControlLabel
          data-testid={`Data_AtributGrid_AddLabel`}
          control={
            <StyledCheckBox
              data-testid={`Data_AtributGrid_AddCheckboxs`}
              checked={storeAddEditAttribute.isDateAttribute}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) =>
                storeAddEditAttribute.changeValue(
                  "isDateAttribute",
                  !storeAddEditAttribute.isDateAttribute
                )
              }
            />
          }
          label={
            <span data-testid={`Data_AtributGrid_AddCheckboxs_Text`}>This is a date attribute</span>
          }
        />
      )}
    </>
  );

  const saveButtonVerification =
    storeAddEditAttribute.errorName !== "" ||
    storeAddEditAttribute.name === "" ||
    storeAddEditAttribute.isCheckingDelay;

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!saveButtonVerification) {
      storeAddEditAttribute.save({
        onSaved: () => {
          store.loadData();
          store.clearHandler();
        },
      });
    }
  };

  const buttonElements = (nameButton: string) => (
    <ButtonTableWrapper>
      <StyledSaveButton
        data-testid={`Data_AtributGrid_AddEdit_SaveBtn`}
        variant="outlined"
        disableRipple={saveButtonVerification}
        $disabled={saveButtonVerification}
        onClick={handleButtonClick}
      >
        {nameButton}
      </StyledSaveButton>
      <CustomButton
        data-testid={`Data_AtributGrid_AddEdit_CanselBtn`}
        onClick={(e) => {
          e.stopPropagation();
          store.clearHandler();
        }}
      >
        Cancel
      </CustomButton>
    </ButtonTableWrapper>
  );

  const column = [
    {
      id: "name",
      label: (
        <StyledContainerLabelColumn>
          <StyledTitle data-testid={`Data_AtributGrid_HeaderColumn_Title_0`}>
            ATTRIBUTES
          </StyledTitle>
          <BlockSortStyle>
            <KeyboardArrowUpIconStyle
              style={
                sortModel.field === "name" && sortModel.sort === "asc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("name", "asc")}
            />
            <KeyboardArrowDownIconStyle
              style={
                sortModel.field === "name" && sortModel.sort === "desc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("name", "desc")}
            />
          </BlockSortStyle>
        </StyledContainerLabelColumn>
      ),
      width: 250,
      render: (row: EmployeeAttribute) => {
        if (store.openPanel && row.id === store.selected?.id) {
          return fieldElement;
        }
        return (
          <AttributeColumn>
            {row.name}

            {row.is_default && (
              <Tooltip
                title={
                  <TooltipText>{`"${row.name}" is a fixed attribute that cannot be edited or deleted`}</TooltipText>
                }
              >
                <DateIcon $letter="a">
                  <DLetter>a</DLetter>
                </DateIcon>
              </Tooltip>
            )}

            {row.is_unit_based && (
              <Tooltip
                title={
                  <TooltipText>
                    {`"${row.name}" is a unit-based attribute that cannot be edited or deleted here. Make changes to unit-based attributes on the organizational structure`}
                  </TooltipText>
                }
              >
                <DateIcon $letter="u">
                  <DLetter>u</DLetter>
                </DateIcon>
              </Tooltip>
            )}

            {row.is_date_attribute && (
              <Tooltip title={<TooltipText>{`"${row.name}" is a date attribute`}</TooltipText>}>
                <DateIcon $letter="d">
                  <DLetter>d</DLetter>
                </DateIcon>
              </Tooltip>
            )}
          </AttributeColumn>
        );
      },
    },
    {
      id: "total_segments",
      width: 350,
      label: (
        <StyledContainerLabelColumn>
          <StyledTitle data-testid={`Data_AtributGrid_HeaderColumn_Title_1`}>
            TOTAL SEGMENTS
          </StyledTitle>
          <BlockSortStyle>
            <KeyboardArrowUpIconStyle
              style={
                sortModel.field === "total_segments" && sortModel.sort === "asc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("total_segments", "asc")}
            />
            <KeyboardArrowDownIconStyle
              style={
                sortModel.field === "total_segments" && sortModel.sort === "desc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("total_segments", "desc")}
            />
          </BlockSortStyle>
        </StyledContainerLabelColumn>
      ),
      render: (row: EmployeeAttribute) => {
        if (store.openPanel && row.id === store.selected?.id) {
          return buttonElements("Save changes");
        }
        return <span>{row.total_segments}</span>;
      },
    },
  ];

  return (
    <div style={{ width: "100%" }} id={"Attribute_AttributeList_DataGridContainer"}>
      <StyledContainer component={Paper} sx={{ height: 505 }}>
        <StyledTable stickyHeader>
          <StyledTableHead>
            <StyledTableRowOne data-testid={`Data_AtributGrid_Header`}>
              {column?.map((column, i) => (
                <StyledTableCellHeader
                  data-testid={`Data_AtributGrid_HeaderColumn_${i}`}
                  style={{ width: column.width }}
                  key={column.id}
                >
                  {column.label}
                </StyledTableCellHeader>
              ))}
            </StyledTableRowOne>
          </StyledTableHead>
          <TableBody data-testid={`Data_AtributGrid_BodyContainer`}>
            {sortedData.map((row, i) => (
              <StyledTableRow
                data-testid={`Data_AtributGrid_Row_${i}`}
                key={i}
                $active={store.selected?.id === row.id && !store.openPanel}
                onClick={() => {
                  store.changeSelected([row.id]);
                  store.clearHandler();
                }}
              >
                {column.map((column, index) => (
                  <StyledTableCell
                    data-testid={`Data_AtributGrid_Row_Column_${i}_${index}`}
                    style={{ width: column.width }}
                    key={column.id}
                  >
                    {column.render(row)}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      </StyledContainer>
      {store.addFooter && (
        <StyledTableFooter>
          <div>{fieldElement}</div>
          <div>{buttonElements("Add")}</div>
        </StyledTableFooter>
      )}
    </div>
  );
});
const AttributeColumn = styled.div`
  display: flex;
  align-items: center;
`;

const DLetter = styled.span`
  color: var(--colorNeutralBackground1);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  cursor: default;
  font-weight: 400;
  line-height: 16px;
`;

const TooltipText = styled.span`
  color: var(--colorNeutralBackground1);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const DateIcon = styled.div<{ $letter: string }>`
  background-color: ${(props) =>
    props.$letter === "d"
      ? "var(--colorPaletteVioletBackground2)"
      : props.$letter === "a"
      ? "var(--colorBrandForeground1)"
      : props.$letter === "u"
      ? "var(--colorBrandForeground1)"
      : "var(--colorBrandForeground1)"};
  margin: 5px;
  border-radius: 7px;
  padding: 0;
  font-size: 10px;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextFieldStyledAttribute = styled(TextField)`
  width: 100% !important;
`;

const ButtonTableWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
`;

const StyledTable = styled(MuiTable)`
  min-width: 414px !important;
`;

const StyledTableHead = styled(TableHead)`
  background: var(--colorPaletteBlueBackground3) !important;
`;

const StyledContainer = styled(TableContainer)`
  width: 100% !important;
  box-shadow: none !important;
`;

const StyledTableRowOne = styled(TableRow)`
  height: 56px;
  flex-shrink: 0;
  border-bottom: 1px solid var(--colorNeutralForeground4) !important;
  background: var(--colorPaletteBlueBackground3) !important;
`;

const StyledTableRow = styled(TableRow)<{ $active?: boolean }>`
  width: 100%;
  cursor: pointer;

  border-bottom: 1px solid var(--colorNeutralForeground4);

  background: ${(props) =>
    props.$active
      ? "linear-gradient(90deg, var(--colorNeutralForeground4) 99%, var(--colorBrandForeground1) 6px);"
      : "var(--colorNeutralBackground1)"};
  height: 56px;
`;

const StyledTableCellHeader = styled(TableCell)`
  color: var(--colorNeutralForeground1) !important;
  background: var(--colorPaletteBlueBackground3) !important;
  font-style: normal;
`;

const StyledTableCell = styled(TableCell)`
  color: var(--colorNeutralForeground1) !important;
  font-style: normal;
  > td {
    padding: 0;
  }
`;

const StyledTableFooter = styled.div`
  width: 100%;
  margin-bottom: 100px;
  padding: 16px;
  background-color: var(--colorNeutralBackground1);
  display: flex;
  gap: 20px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 10px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);

  .MuiCheckbox-root {
    &:hover {
      background-color: transparent !important;
    }
  }
`;

const StyledCheckBox = styled(Checkbox)`
  padding: 0px 10px !important;
  color: var(--colorBrandForeground1) !important;
`;

const StyledContainerLabelColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const BlockSortStyle = styled.span`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 10px;
  height: 20px;
  top: 0;
  left: 14px;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
`;

const KeyboardArrowUpIconStyle = styled(KeyboardArrowUpIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: -6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: 6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const StyledSaveButton = styled(CustomButton)<{ $disabled: boolean }>`
  border: ${(props) =>
    props.$disabled
      ? "1px solid var(--colorGrayForeground6)"
      : "1px solid var(--colorBrandForeground1)"} !important;
  color: ${(props) =>
    props.$disabled ? "var(--colorNeutralForeground5)" : "var(--colorBrandForeground1)"} !important;
  background: ${(props) =>
    props.$disabled ? "var(--colorGrayForeground7)" : "var(--colorNeutralBackground1)"} !important;
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")} !important;
`;

const StyledTitle = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: var(--colorNeutralForeground2);
`;

export default DataTable;
