import http from "api/https";
import { createUrl } from "helpers/url";

export const getTemplReminderRecipientGroup = (survey_id: number): Promise<any> => {
  const query: { survey_id: string } = {
    survey_id: survey_id.toString(),
  };

  if (http.isAPIGO) {
    const url = createUrl({
      path: "/sm/templ_reminder_recipientsgroup/GetAllWithCountRecipients",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/templ_reminder_recipientsgroup/GetAllWithCountRecipients",
      query: query,
    });
    return http.get(url);
  }
};
