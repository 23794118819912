import { FC } from "react";
import { TextField, Grid, Checkbox, FormControlLabel, Chip } from "@mui/material";
import styled from "styled-components";
import store from "./store";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import { observer } from "mobx-react";
import EmployeeSearch from "./employeeSearch";
import UnitHeadSearch from "./unitHeadSearch";
import { GetEmployeesByEntityType } from "api/go/useGetEmployeesByEntity";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import MainStore from "MainStore";
import storeList from "features/Employees/store";
import { ORG_STRUCTURE_CODES } from "constants/entityManagementTypes";
import { OrganizationUnit } from "types/organization_unit";
import employeeListStore from "./../ListEmployee/store";

type EmployeeAddEditProps = {};

const EmployeeAddEdit: FC<EmployeeAddEditProps> = observer((props) => {
  return (
    <WrappedContainer>
      <Grid container spacing={1}>
        <Grid item>
          <Header id="EmployeeList_AddEditPopUp_Title">Add employee</Header>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "30px" }}>
          <TextField
            name="fullName"
            id="EmployeeList_AddEditPopUp_fullName"
            label="Full name"
            required
            placeholder="Enter full name"
            helperText={store.errorfullName}
            error={store.errorfullName !== ""}
            variant="standard"
            onChange={(e) => store.changeField(e.target.name, e.target.value)}
            value={store.fullName}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <StyledGrid item xs={12}>
          <TextField
            name="preferedName"
            id="EmployeeList_AddEditPopUp_preferedName"
            label="Preferred name"
            required
            placeholder="Enter preferred name"
            helperText={store.errorpreferedName}
            error={store.errorpreferedName !== ""}
            variant="standard"
            fullWidth
            onChange={(e) => store.changeField(e.target.name, e.target.value)}
            value={store.preferedName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
        </StyledGrid>

        <StyledGrid item xs={12}>
          <TextField
            name="email"
            id="EmployeeList_AddEditPopUp_email"
            label="Email"
            required
            placeholder="Enter email address"
            helperText={
              store.erroremail
                ? store.erroremail
                : store.isUniqueEmail && "Employee with the same E-mail already exists"
            }
            error={store.erroremail !== "" || store.isUniqueEmail}
            variant="standard"
            fullWidth
            onChange={(e) => store.changeField(e.target.name, e.target.value)}
            value={store.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
        </StyledGrid>

        <StyledGrid item xs={12}>
          <TextField
            name="uniqueId"
            id="EmployeeList_AddEditPopUp_uniqueId"
            label="Unique ID"
            required={!store.email.length}
            placeholder="Enter unique ID"
            helperText={
              store.erroruniqueId
                ? store.erroruniqueId
                : store.isUniqueUniqId && "Employee with the same ID already exists"
            }
            error={store.erroruniqueId !== "" || store.isUniqueUniqId}
            variant="standard"
            fullWidth
            onChange={(e) => store.changeField(e.target.name, e.target.value)}
            value={store.uniqueId}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AssignmentIndIcon />
                </InputAdornment>
              ),
            }}
          />
          <StyledRequiredTextUniqueId id="EmployeeList_AddEditPopUp_RequiredMessage">
            <span>*</span>
            Required if email is not present
          </StyledRequiredTextUniqueId>
        </StyledGrid>

        {store.isUnitHead && (
          <Grid style={{ marginTop: "4px" }} item xs={12}>
            <UnitHeadSearch />
          </Grid>
        )}

        {store.selectedUnits?.length > 0 && store.isUnitHead && (
          <Grid
            style={{
              maxHeight: 150,
              overflow: "auto",
            }}
            item
            xs={12}
          >
            {store.selectedUnits?.map((unit: OrganizationUnit) => {
              return (
                <StyledChip
                  size="small"
                  label={unit.name + " (Level " + unit.level + ")"}
                  onDelete={() => {
                    store.deleteSelectedUnit(unit.id);
                  }}
                />
              );
            })}
          </Grid>
        )}


        {store.isManager && (
          <Grid item xs={12}>
            <EmployeeSearch addSelectedEmployees={store.addSelectedEmployees} />
            <LabelSearchText id="EmployeeList_AddEditPopUpisUnitHead-CheckboxIsManager_SearchInput_helper">
              Employees need to exist on the list before they can be added
            </LabelSearchText>
          </Grid>
        )}

        {store.selectedEmployees?.length > 0 && store.isManager && (
          <Grid
            style={{
              maxHeight: 150,
              overflow: "auto",
            }}
            item
            xs={12}
          >
            {store.selectedEmployees?.map((employee: GetEmployeesByEntityType) => {
              return (
                <StyledChip
                  size="small"
                  icon={<EmailIcon />}
                  label={employee.email}
                  onDelete={() => {
                    store.deleteSelectedEmployee(employee.id);
                  }}
                />
              );
            })}
          </Grid>
        )}
      </Grid>
    </WrappedContainer>
  );
});

export default EmployeeAddEdit;

const WrappedContainer = styled.div`
  .MuiFormLabel-asterisk {
    color: var(--colorPaletteRedForeground1);
  }
  .MuiFormLabel-root {
    color: var(--colorNeutralForeground2);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  svg {
    width: 20px;
    height: 20px;
    path {
      fill: var(--colorNeutralForeground6);
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  color: var(--colorBrandForeground1);
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: var(--colorBrandForeground1) !important;
    }
  }
`;

const LabelCheckbox = styled.span`
  font-size: 13px;
  color: var(--colorNeutralForeground2);
`;

const StyledChip = styled(Chip)`
  margin: 2px !important;
  background-color: var(--colorPaletteBlueBackground1) !important;
`;

const StyledRequiredTextUniqueId = styled.p`
  color: var(--colorNeutralForeground1);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 8px;
  > span {
    color: var(--colorPaletteRedForeground1);
  }
`;

const Header = styled.span`
  color: var(--colorNeutralForeground2);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;

const LabelSearchText = styled.span`
  color: var(--colorNeutralForeground2);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const StyledGrid = styled(Grid)`
  margin-top: 20px !important;
`;
