import http from "api/https";
import { AxiosResponse } from "axios";

class UnexpectedApiError extends Error {
  constructor(response: AxiosResponse<any, any>) {
    super();
  }
}

export const MarkAsNonTestProject = async (id: number, test: boolean): Promise<any> => {
  const data = { 
    id: id,
    test: test
   };

   if(http.isAPIGO){
    const response = await http.postGO("/sm/sm_project/UpdateMarkTestProject", data);
    if ([200, 201].includes(response.status)) {
      return response.data;
    }
    throw new UnexpectedApiError(response);
   }else{
    const response = await http.post("/sm_project/UpdateMarkTestProject", data);
    if ([200, 201].includes(response.status)) {
      return response.data;
    }
    throw new UnexpectedApiError(response);
   }

};
