import { FC } from "react";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import { observer } from "mobx-react";

type ReplaceQuestionsConfirmProps = {
  onClose: () => void;
};

const ReplaceQuestionsConfirm: FC<ReplaceQuestionsConfirmProps> = observer((props) => {
  return (<PopupWrapper id={"Template_AddDimension_Validation_Form"}>
    <BodyConfirm>

      <ReplaceQuestion id={"Template_AddDimension_Validation_Header_Label"}>
        Add a dimension for each question
      </ReplaceQuestion>

      <ReplaceQuestionDescription id={"Template_AddDimension_Validation_Description"}>
        Looks like you forgot to add dimensions to each question. Not tagging a question to any dimension would impact dashboard results
      </ReplaceQuestionDescription>

      <ReplaceButtons>
        <ButtonWrapp>
          <CustomButton variant="contained" id={"Template_AddDimension_Validation_AddButton"} onClick={() => {
            props.onClose()
          }}>
            Add dimension
          </CustomButton>
        </ButtonWrapp>
      </ReplaceButtons>
    </BodyConfirm>

  </PopupWrapper>
  );
});

const PopupWrapper = styled.div`
  width: 500px;
  height: 200px;
`

const BackToTemplatesWithIcon = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin: 20px;
`

const ReplaceQuestion = styled.div`
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  margin-top: 20px;
`

const ReplaceQuestionDescription = styled.div`
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-top: 30px;
`

const ButtonWrapp = styled.div`
  margin-right: 10px;
`

const ReplaceButtons = styled.div`
  display: flex;
  margin-top: 40px;
`

const BodyConfirm = styled.div`
  margin: 50px 50px 0 50px;
`

const BackToTemplates = styled.div`
  color: var(--colorNeutralForeground5, #959BA2);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
`



export default ReplaceQuestionsConfirm;