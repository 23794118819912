import http from "api/https";


export const addTemplate = (entity_id: number): Promise<any> => {
  let data = {
    type: "communication",
    entity_id: entity_id,
    owner_entity_id: entity_id,
  }
  if(http.isAPIGO){
    return http.postGO('/template/templ_template_comms', data, { 'Content-Type': 'multipart/form-data' })
  } else {
    return http.post('/templ_template_comms', data, { 'Content-Type': 'multipart/form-data' })
  }
};
