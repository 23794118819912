import * as React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  fill: var(--colorNeutralBackground1, #FFF);
  margin-bottom: 2px;
`;

export const BellIcon = (
  props: React.HTMLAttributes<HTMLOrSVGElement>
) => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" width="11" height="13" viewBox="0 0 11 13" fill="none">
    <path d="M8.33731 6.28758L7.80102 4.28611C7.5612 3.39457 7.02649 2.61322 6.2916 2.08044C5.5567 1.54767 4.66904 1.29784 3.7858 1.3752L3.60198 0.689162C3.55725 0.522236 3.45075 0.37919 3.30591 0.291492C3.16106 0.203794 2.98974 0.178628 2.82963 0.22153C2.66952 0.264432 2.53373 0.371887 2.45214 0.520258C2.37055 0.668628 2.34984 0.84576 2.39457 1.01269L2.57839 1.69872C1.77481 2.07334 1.13098 2.73354 0.760932 3.56238C0.390882 4.39123 0.318489 5.33525 0.556572 6.22725L1.09286 8.22872C0.775368 8.45297 0.534606 8.77501 0.403587 9.15069C0.272568 9.52637 0.257706 9.9373 0.361038 10.3271L0.698329 11.5859C0.743057 11.7529 0.849557 11.8959 0.9944 11.9836C1.13924 12.0713 1.31057 12.0965 1.47068 12.0536L3.36631 11.5456C3.64824 12.0417 4.07788 12.4312 4.58897 12.654C5.10005 12.8768 5.66416 12.9206 6.19428 12.7786C6.72439 12.6365 7.19105 12.3166 7.52225 11.8681C7.85344 11.4195 8.03078 10.8674 8.0269 10.2968L9.92253 9.78889C10.0826 9.74599 10.2184 9.63853 10.3 9.49016C10.3816 9.34179 10.4023 9.16466 10.3576 8.99774L10.0203 7.73895C9.91486 7.34967 9.69653 7.00123 9.39522 6.74139C9.09392 6.48155 8.72439 6.32304 8.33731 6.28758ZM1.76398 5.90373C1.58507 5.23603 1.6679 4.5275 1.99426 3.93402C2.32062 3.34054 2.86376 2.91071 3.50421 2.73911C4.14466 2.5675 4.82995 2.66816 5.40932 3.01895C5.9887 3.36975 6.4147 3.94193 6.59361 4.60963L7.09954 6.49781L2.26992 7.79191L1.76398 5.90373ZM5.86525 11.5506C5.65419 11.6058 5.43172 11.6023 5.21998 11.5407C5.00823 11.4791 4.81456 11.3615 4.65823 11.1995L6.73497 10.643C6.6806 10.8615 6.57171 11.0602 6.41913 11.2194C6.26656 11.3787 6.0756 11.4928 5.86525 11.5506ZM8.98153 8.69187L1.73709 10.633L1.56845 10.0036C1.52372 9.83669 1.54443 9.65956 1.62602 9.51119C1.7076 9.36282 1.84339 9.25536 2.0035 9.21246L8.04054 7.59484C8.20065 7.55194 8.37197 7.5771 8.51682 7.6648C8.66166 7.7525 8.76816 7.89555 8.81289 8.06247L8.98153 8.69187Z" fill="white" />
  </StyledSvg>
);
