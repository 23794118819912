import http from "api/https";
import { createUrl } from "../../helpers/url";
import { AxiosResponse } from "axios";

class UnexpectedApiError extends Error {
  constructor(response: AxiosResponse<any, any>) {
    super();
  }
}

export type GetEmployeesByEntityType = {
  id: number;
  entity_employee_id: number;
  full_name: string;
  preffered_name: string;
  email: string;
};

export const GetEmployeesByEntity = async (
  id: number,
  email: string,
  employee_id?: number,
): Promise<any> => {
  const query: { id: string; email: string; employee_id: string } = {
    id: id.toString(),
    email: email,
    employee_id: (employee_id ?? 0).toString(),
  };

  if (http.isAPIGO) {
    const url = createUrl({
      path: "/permission/GetUnitHeadsByEmail",
      query: query,
    });
    const response = await http.getGO(url);
    if ([200, 201].includes(response.status)) {
      return response.data;
    }
    throw new UnexpectedApiError(response);
  } else {
    const url = createUrl({
      path: "/employees/SearchByFullNameAndByIdEntity",
      query: query,
    });
    const response = await http.get(url);
    if ([200, 201].includes(response.status)) {
      return response.data;
    }
    throw new UnexpectedApiError(response);
  }
};
