import http from "api/https";
import { RolePermission } from "types/permission";

export const savePermissionsForProject = (
  idProject: number,
  rolePermissions: RolePermission[],
  idEntity: number
): Promise<any> => {
  if (http.isAPIGO) {
    return http.postGO("/permission/role_permission/SavePermissionsForProject", {
      project_id: idProject,
      role_permissions: rolePermissions,
      entity_id: idEntity,
    });
  } else {
    return http.post("/role_permission/SavePermissionsForProject", {
      project_id: idProject,
      role_permissions: rolePermissions,
      entity_id: idEntity,
    });
  }
};
