import { FC } from "react";
import { ImportHistoricalSurvey } from "features/Survey/ImportHistoricalSurvey";
import BottomProgressUploading from "components/common/BottomProgressUploading";
import BottomUploadComplete from "components/common/BottomUploadComplete";

type ImportHistoricalSurveyProps = {};

const ImportHistoricalSurveyPage: FC<ImportHistoricalSurveyProps> = () => {
  return (
    <div>
      <ImportHistoricalSurvey />
      <BottomProgressUploading isHistorical />
      <BottomUploadComplete isHistorical />
    </div>
  );
};

export default ImportHistoricalSurveyPage;
