import { FC } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import BackSpaceIcon from "features/Templates/icons/BackSpaceIcon";

type BackToMainLayoutHeaderProps = {};

const BackToMainLayoutHeader: FC<BackToMainLayoutHeaderProps> = () => {
  const navigate = useNavigate();
  return (
    <BackToMainLayoutHeaderWrapper id="Template_Communication_Header">
      <BackContainer>
        <BackButton id="Template_Communication_BackButton" onClick={() => navigate("/templates")}>
            <BackSpaceIcon />
          <BackToModule id="Template_Communication_Header_BackButton_Label">Back to templates</BackToModule>
        </BackButton>
      </BackContainer>
      <CreateTemplateText id="Template_Communication_Header_Label">
        {localStorage.getItem("templateAction") === "create"
          ? "Create new template"
          : "Editing template"}
      </CreateTemplateText>
      <div></div>
    </BackToMainLayoutHeaderWrapper>
  );
};

export default BackToMainLayoutHeader;

const BackToMainLayoutHeaderWrapper = styled.div`
  width: 100%;
  height: 64px;
  padding: 20px 90px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--colorPaletteVioletBackground1);
`;

const BackToModule = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralBackground1);
`;

const BackContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CreateTemplateText = styled.div`
  color: white;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
`;

const BackButton = styled.button`
  background: var(--ER-Theme-sys-light-on-primary-container, #1e004d);
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;
