import http from "api/https";
import { createUrl } from "../../helpers/url";

export const getUsersGroupedByRoles = (
  real_id: number,
  code: string,
  searchValue: string,
  region_id: number
): Promise<any> => {
  const query: { real_id: string; code: string; searchValue: string; region_id: string } = {
    real_id: real_id.toString(),
    code: code,
    searchValue: searchValue,
    region_id: region_id.toString(),
  };
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/permission/account_roles/GetUsersGroupedByRoles",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/permission/GetUsersGroupedByRoles",
      query: query,
    });
    return http.get(url);
  }
};
