import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import store from './store'
import { observer } from 'mobx-react';
import styled from 'styled-components';
import LinkIcon from './LinkIcon';

const MtmLookup = observer(() => {
  return (
    <WrapperAutocomplete>
      <Autocomplete
        multiple
        id="SyrveyProject_PermissionsPopUp_Input"
        size="small"
        value={[]}
        ListboxProps={{ style: { maxHeight: '200px' } }}
        onChange={(e, value) => {
          store.addRoles(value)
        }}
        noOptionsText={<NoOption id="SyrveyProject_PermissionsPopUp_Input_NoOption">
          Permission role does not exist. Go to&nbsp;
          <PermissionLink id="SyrveyProject_PermissionsPopUp_Input_NoOption_Link" target='_blank' href="/entityPermissions?nav=role">
            Permission roles <LinkIcon />
          </PermissionLink>
          to create role first.
        </NoOption>}
        fullWidth
        options={store.roles}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField {...params}
            label='Add multiple role groups by adding your own text and hit "Enter" on your keyboard' />
        )}
      />
    </WrapperAutocomplete>
  );
})

const WrapperAutocomplete = styled.div`
  width: 100%;
`
const NoOption = styled.div`
  display: flex;
`
const PermissionLink = styled.a`
  display: flex;
  align-items: center;
  color: #1477F8 !important;
  text-decoration: underline;
  svg{
    margin: 0 5px 5px 5px;
  }
`

export default MtmLookup;