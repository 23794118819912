import { surveyLocalization } from "survey-core"

export const khmerStrings = {
  pagePrevText: "មុន",
  pageNextText: "နောက်တစ်ခု",
  completeText: "បន្ទាប់មក",
  otherItemText: "ទុកមតិយោបល់ (ជាជម្រើស)",
  noneItemText: "គ្មាន",
  selectAllItemText: "ជ្រើសរើសទាំងអស់​",
  progressText: "ទំព័រ​ {0} នៃ​ {1}",
  indexText: "{0} នៃ​ {1}",
  panelDynamicProgressText: "{0} នៃ​ {1}",
  questionsProgressText: "បានឆ្លើយសំណួរ​ {0}/{1}",
  emptySurvey: "ការស្ទង់មតិ​នេះ​មិន​មាន​ទំព័រឬ​សំណួរ​ដែលអាច​មើលឃើញ​នោះទេ​",
  completingSurvey: "សូមអរគុណដែលបានធ្វើ​​​​ការស្ទង់មតិនេះ។",
  completingSurveyBefore: "​អ្នក​បានធ្វើ​រួចរាល់​! កំណត់ត្រា​របស់​យើង​នឹង​បង្ហាញ​ថា​អ្នក​បាន​បំពេញ​ការស្ទង់មតិនេះ​រួចរាល់​",
  loadingSurvey: "សូមរង់ចាំ​ នៅពេល​យើង​ដាក់បញ្ចូល​ការស្ទង់​មតិ​",
  placeholder: "ជ្រើសរើស​",
  ratingOptionsCaption: "ជ្រើសរើស​",
  requiredError: "តម្រូវ​ឱ្យមាន​ចម្លើយឆ្លើយតប​",
  booleanCheckedLabel: "បាទ/ចាស​",
  booleanUncheckedLabel: "ទេ​​",
  questionTitlePatternText: "ចំណង​ជើង​សំណួរ​",
};

surveyLocalization.locales["khm"] = khmerStrings
surveyLocalization.localeNames["khm"] = "Khmer / ខ្មែរ"
