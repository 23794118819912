import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { QuantityInput } from "components/number-input-counter/NumberInputCounter";
import { FormControl, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "components/button/Button";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Autocomplete from "@mui/material/Autocomplete";

type SurveyMillstoneProps = {};

const SurveyMillstone: FC<SurveyMillstoneProps> = observer(() => {
  const [changeDay, setChangeDay] = useState<{ index: number; value: number } | null>(null);
  return (
    <Container>
      <Title id="Survey_Project_CreatePopup_Milestone_Title">Set this project up for milestone surveys</Title>
      <Text id="Survey_Project_CreatePopup_Milestone_SubTitle">Surveys will be based on calculations from (Day 0) for:</Text>
      <FormControl>
        <StyledAutocomplete
          disablePortal
          id="Survey_Project_CreatePopup_Milestone_Select_Attribute"
          onChange={(e, value: any) => {
            store.setData(value, "selected_date_attribute");
          }}
          options={store.entity_attributes.filter((x) => x.type == "date")}
          getOptionLabel={(option: any) => option.name}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} placeholder="Select date attribute" />}
        />
        {/* 
        <StyledSelect
          id="date_attribute"
          value={store.millstoneDayAttribure}
          onChange={(e: SelectChangeEvent<string>) =>
            store.setData(e.target.value, "millstoneDayAttribure")
          }
          placeholder="Select date attribute"
          displayEmpty
        >
          <MenuItem disabled value="">
            <em>Select date attribute</em>
          </MenuItem>
          <MenuItem value={"1"}>1</MenuItem>
          <MenuItem value={"2"}>2</MenuItem>
          <MenuItem value={"3"}>3</MenuItem>
        </StyledSelect> */}
      </FormControl>
      <Text id="Survey_Project_CreatePopup_Milestone_Description">
        The number of milestones listed below will correspond to the number of surveys sent out to
        recipients.
      </Text>
      <DaysWrapper>
        {store.millstoneDays.map((day, index) => (
          <DaysItem key={index}>
            <DaysItemLeftContent id={`Survey_Project_CreatePopup_Milestone_DaysItemLeftContent_${index}`}>
              <DaysItemLeftContentText id={`Survey_Project_CreatePopup_Milestone_DaysItemLeftContent_Day_${index}`}>Day</DaysItemLeftContentText>
              {changeDay && changeDay.index === index ? (
                <QuantityInput
                  value={changeDay.value}
                  onChange={(_, value) => setChangeDay({ index, value })}
                />
              ) : (
                <p id={`Survey_Project_CreatePopup_Milestone_DaysItemLeftContent_Number_${index}`}>{day.days}</p>
              )}
            </DaysItemLeftContent>
            <DaysItemRightContent id={`Survey_Project_CreatePopup_Milestone_DaysItemRightContent_${index}`}>
              {changeDay && changeDay.index === index ? (
                <SaveEditRemoveButton
                  id={`Survey_Project_CreatePopup_Milestone_DaysItemRightContent_Save_Button_${index}`}
                  variant="outlined"
                  onClick={() => {
                    store.updateMillstoneDay(index, changeDay.value);
                    setChangeDay(null);
                  }}
                >
                  Save
                </SaveEditRemoveButton>
              ) : (
                <SaveEditRemoveButton
                  id={`Survey_Project_CreatePopup_Milestone_DaysItemRightContent_Edit_Button_${index}`}
                  onClick={() => setChangeDay(() => ({ value: day.days, index }))}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M2 15.3078V18.6406H5.33287L15.1626 8.81088L11.8297 5.47801L2 15.3078ZM17.74 6.23346C18.0867 5.88684 18.0867 5.32692 17.74 4.9803L15.6603 2.90059C15.3137 2.55397 14.7538 2.55397 14.4072 2.90059L12.7807 4.52703L16.1136 7.8599L17.74 6.23346Z"
                      fill="#1477F8"
                    />
                  </svg>
                  Edit
                </SaveEditRemoveButton>
              )}
              {changeDay && changeDay.index === index ? (
                <SaveEditRemoveButton id={`Survey_Project_CreatePopup_Milestone_DaysItemRightContent_Cancel_Button_${index}`} onClick={() => setChangeDay(null)}>
                  Cancel
                </SaveEditRemoveButton>
              ) : (
                <SaveEditRemoveButton id={`Survey_Project_CreatePopup_Milestone_DaysItemRightContent_Delete_Button_${index}`} onClick={() => store.removeMillstoneDay(index)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_9194_34255)">
                      <path
                        d="M5.0013 16.474C5.0013 17.3906 5.7513 18.1406 6.66797 18.1406H13.3346C14.2513 18.1406 15.0013 17.3906 15.0013 16.474V6.47396H5.0013V16.474ZM15.8346 3.97396H12.918L12.0846 3.14062H7.91797L7.08464 3.97396H4.16797V5.64062H15.8346V3.97396Z"
                        fill="#1477F8"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_9194_34255">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(0 0.640625)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  Delete
                </SaveEditRemoveButton>
              )}
            </DaysItemRightContent>
          </DaysItem>
        ))}

        {store.millstoneDays.length < 12 && (
          <AddMillstoneBtn id="Survey_Project_CreatePopup_Milestone_AddMilestone_Button" variant="outlined" onClick={store.addMilstoneDay}>
            <IconWrapper>
              <AddIcon />
            </IconWrapper>
            Add milestone
          </AddMillstoneBtn>
        )}
        {store.millstoneDays.length >= 10 && store.millstoneDays.length < 12 ? (
          <WarningWrapper $redColor={false}>
            <WarningAmberIcon />
            <WarningText id="Survey_Project_CreatePopup_Milestone_MaximumProject_Warning" $redColor={false}>
              The maximum number of milestones per project is 12. You may add{" "}
              {12 - store.millstoneDays.length} more.
            </WarningText>
          </WarningWrapper>
        ) : store.millstoneDays.length === 12 ? (
          <WarningWrapper $redColor={true}>
            <WarningAmberIcon />
            <WarningText id="Survey_Project_CreatePopup_Milestone_ReachedMaximumProject_Warning" $redColor={true}>
              You have reached the maximum number of milestones that can be created per project.
            </WarningText>
          </WarningWrapper>
        ) : null}
      </DaysWrapper>
    </Container>
  );
});

export default SurveyMillstone;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const Title = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
  margin-bottom: 10px;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
  margin: 20px 0px 16px 0px;
`;

const StyledSelect = styled(Select)`
  height: 34px;
  width: 100%;
  max-width: 225px;

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }
`;

const DaysWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DaysItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid var(--colorPaletteBlueBackground1);
  padding: 5px 20px;
  margin-bottom: 10px;
`;

const AddMillstoneBtn = styled(CustomButton)`
  background-color: var(--colorPaletteGrayBackground1) !important;
  border: 1px solid var(--colorPaletteBlueBackground1) !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  color: var(--colorBrandForeground1) !important;
  height: 50px !important;
  box-shadow: none !important;
`;

const IconWrapper = styled.span`
  border-radius: 50%;
  background-color: var(--colorNeutralBackground1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;

  svg {
    font-size: 16px;
  }
`;

const DaysItemLeftContent = styled.div`
  display: flex;
  align-items: center;
`;

const DaysItemRightContent = styled.div``;

const SaveEditRemoveButton = styled(CustomButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const DaysItemLeftContentText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-right: 10px;
  color: var(--colorNeutralForeground1);
`;

const WarningWrapper = styled.div<{ $redColor: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;

  svg {
    font-size: 20px;
    color: ${(props) =>
      props.$redColor ? "var(--colorPaletteRedForeground2)" : "var(--colorNeutralForeground1)"};
  }
`;

const WarningText = styled.p<{ $redColor: boolean }>`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 4px;
  color: ${(props) =>
    props.$redColor ? "var(--colorPaletteRedForeground2)" : "var(--colorNeutralForeground1)"};
`;

const StyledAutocomplete = styled(Autocomplete)`
  input {
    border-radius: 2px !important;
    padding: 0px !important;
    font-family: Roboto !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .MuiOutlinedInput-root {
    border-radius: 2px !important;
  }
`;
