import { FC } from "react";
import EntityCards from '../../features/ManageEntities/cards'

type EntitiesPageProps = {};

const EntitiesPage: FC<EntitiesPageProps> = () => {
  return <EntityCards></EntityCards>;
};

export default EntitiesPage;
