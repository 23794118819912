import { CreatorBase } from "survey-creator-core"
import { SurveyCreator } from "survey-creator-react"
import { ItemValue, QuestionRatingModel } from "survey-core"
import { debounce } from "lodash"
import { OnModifiedOptions, PropertyChangedOptions } from "./types"
import { QuestionType } from "../sharedTypes"
import setSyncableRatingScaleProperties from "../setSyncableRatingScaleProperties"

export function handleSyncRatingScaleOptions({ creator }: { creator: SurveyCreator }): (sender: CreatorBase, options: OnModifiedOptions<ItemValue>) => void {
  const onModifiedCallback = (sender: CreatorBase, options: OnModifiedOptions<ItemValue>): void => {
    if (options.type == "PROPERTY_CHANGED" &&
      options.target.getType() == "itemvalue" &&
      options.target.locOwner instanceof QuestionRatingModel &&
      options.target.locOwner.getType() == QuestionType.rating
    ) {
      syncRatingScaleOptions({ creator, sender, options })
      return
    }
  }

  return debounce(onModifiedCallback, 2000)
}

function syncRatingScaleOptions({
  creator,
  sender,
  options,
}: {
  creator: SurveyCreator,
  sender: CreatorBase,
  options: PropertyChangedOptions<ItemValue>,
}): void {
  const surveyCreatorCopy = new SurveyCreator()
  surveyCreatorCopy.JSON = sender.survey.toJSON()

  const ratingScaleQuestions = surveyCreatorCopy.survey
    .getAllQuestions()
    .filter(question => question.getType() == QuestionType.rating) as QuestionRatingModel[]

  const sourceQuestion = options.target.locOwner as QuestionRatingModel

  ratingScaleQuestions.forEach(question => {
    setSyncableRatingScaleProperties(sourceQuestion, question)
  })

  creator.JSON = surveyCreatorCopy.survey.toJSON()
  creator.selectElement(
    creator.survey.getQuestionByName(sourceQuestion.name)
  )
  creator.doSave()
}
