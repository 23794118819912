import http from "../https";

import { TGetDashboardResponcesOtherCommentsAsyncResponse } from "types/dashboard";

type TRequestBody = {
    survey_id: number;
    attributes: {
        id: number;
        name: string;
        segments: string[];
    }[];
    questionId: number;
    benchmarkType?: number;
    pagination: {
        offset: number;
        limit: number;
    };
};
export const getDashboardResponcesOtherOptionsAsync = async (
    data: TRequestBody
): Promise<TGetDashboardResponcesOtherCommentsAsyncResponse> => {
    try {
        const response = await http.post(`/Dashboard/responces/other/comments/other`, data);

        if ([200, 201].includes(response.status)) {
            return response.data;
        }

        throw new Error(
            `Failed to fetch responses for 'Other' options (HTTP status code: ${response.status})`
        );
    } catch (error) {
        console.error(error);
    }
};
