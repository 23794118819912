import { FC } from "react";

import { QuestionnaireTemplate } from '../../features/Templates/QuestionnaireTemplates'

type CreateCommunicationTemplatePageProps = {};

const CreateCommunicationTemplatePage: FC<CreateCommunicationTemplatePageProps> = () => {
  return <div>
    <QuestionnaireTemplate />

  </div>;
};

export default CreateCommunicationTemplatePage;
