import http from "api/https";
import { createUrl } from "../../helpers/url";

export const getUnitHeads = (idEntity: number, searchValue: string): Promise<any> => {
  const query: { id_entity: string; search_value: string } = {
    id_entity: idEntity.toString(),
    search_value: searchValue,
  };

  if (http.isAPIGO) {
    const url = createUrl({
      path: "/employee/organization_unit/GetUnitHeads",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/organization_unit/GetUnitHeads",
      query: query,
    });
    return http.get(url);
  }
};
