import http from "api/https";
import { createUrl } from "../../helpers/url";

export const getTemplateQuestionDimensions = (template_id: number): Promise<any> => {
  const query: { id: string } = { id: template_id.toString() };
  if(http.isAPIGO){
    const url = createUrl({
      path: "/template/templ_question_by_template",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/templ_question/GetBytemplate_id",
      query: query,
    });
    return http.get(url);
  }
};
