import { FC } from "react";

import styled from "styled-components";

type LinearBarProps = {
  favorable: number;
  neutral: number;
  unFavourable: number;
};

const LinearBar: FC<LinearBarProps> = (props) => {
  return (
    <Container>
      <ScaleRow $isFilled={Boolean(props.favorable || props.neutral || props.unFavourable)}>
        {Boolean(props.favorable) && (
          <FavourableScale
            $width={props.favorable}
            $isOne={Boolean(props.favorable && !props.neutral && !props.unFavourable)}
          ></FavourableScale>
        )}
        {Boolean(props.neutral) && (
          <NeutralScale
            $width={props.neutral}
            $isOne={Boolean(props.neutral && !props.unFavourable && !props.favorable)}
            $isFirst={Boolean(!props.favorable)}
          ></NeutralScale>
        )}
        {Boolean(props.unFavourable) && (
          <UnFavorableScale
            $width={props.unFavourable}
            $isOne={Boolean(!props.neutral && props.unFavourable && !props.favorable)}
          ></UnFavorableScale>
        )}
      </ScaleRow>
      {props.favorable || props.neutral || props.unFavourable ? (
        <LabelsRow>
          {Boolean(props.favorable) && (
            <FavourableLabel $width={props.favorable}>
              <Label>{props.favorable}%</Label>
            </FavourableLabel>
          )}
          {Boolean(props.neutral) && (
            <NeutralLabel $width={props.neutral}>
              <Label>{props.neutral}%</Label>
            </NeutralLabel>
          )}
          {Boolean(props.unFavourable) && (
            <UnFavorableLabel $width={props.unFavourable}>
              <Label>{props.unFavourable}%</Label>
            </UnFavorableLabel>
          )}
        </LabelsRow>
      ) : null}
    </Container>
  );
};

export default LinearBar;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ScaleRow = styled.div<{ $isFilled: boolean }>`
  width: 100%;
  display: flex;
  height: 20px;
  ${(props) => !props.$isFilled && "border-radius: 6px"};
  ${(props) => !props.$isFilled && "background-color: var(--colorNeutralForegroundInverted2)"};

  :first-child {
    border-radius: 6px 0px 0px 6px;
  }

  :last-child {
    border-radius: 0px 6px 6px 0px;
  }
`;

const LabelsRow = styled.div`
  width: 100%;
  display: flex;
`;

const FavourableScale = styled.div<{ $width: number; $isOne: boolean }>`
  width: ${(props) => `${props.$width}%`};
  height: 20px;
  background-color: var(--colorPaletteGreenBackground3);
  ${(props) => props.$width < 1 && "display:none"};
  min-width: 20px;
  ${(props) => props.$isOne && "border-radius: 6px !important"};
`;

const FavourableLabel = styled.div<{ $width: number }>`
  width: ${(props) => `${props.$width}%`};
  ${(props) => props.$width < 1 && "display:none"};
  min-width: 20px;
`;

const NeutralScale = styled.div<{ $width: number; $isOne: boolean; $isFirst: boolean }>`
  width: ${(props) => `${props.$width}%`};
  height: 20px;
  background-color: var(--colorNeutralForeground10);
  ${(props) => props.$width < 1 && "display:none"};
  min-width: 20px;
  ${(props) =>
    props.$isOne
      ? "border-radius: 6px !important"
      : props.$isFirst
        ? "border-radius: 6px 0px 0px 6px !important"
        : null};
`;

const NeutralLabel = styled.div<{ $width: number }>`
  width: ${(props) => `${props.$width}%`};
  ${(props) => props.$width < 1 && "display:none"};
  min-width: 20px;
`;

const UnFavorableScale = styled.div<{ $width: number; $isOne: boolean }>`
  width: ${(props) => `${props.$width}%`};
  height: 20px;
  background-color: var(--colorPaletteRedForeground1);
  ${(props) => props.$width < 1 && "display:none"};
  min-width: 20px;
  ${(props) => props.$isOne && "border-radius: 6px !important"};
`;

const UnFavorableLabel = styled.div<{ $width: number }>`
  width: ${(props) => `${props.$width}%`};
  ${(props) => props.$width < 1 && "display:none"};
  min-width: 20px;
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.081px;
  color: var(--colorNeutralForeground3);
  margin-top: 5px;
`;
