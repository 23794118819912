import http from "api/https";
import { Access } from "types/surveys";

export const saveAccessesForSurvey = (
  accesses: Access[],
  survey_id: number,
) => {
  let data = {
    survey_id: survey_id,
    accesses: accesses
  }
  
  if (http.isAPIGO) {
    return http.postGO("/sm/sm_survey/SaveAccessesForSurvey", data, );
  } else {
    return http.post("/sm_survey/SaveAccessesForSurvey", data, );
  }
};
