import http from "api/https";
import { createUrl } from "../../helpers/url";

export const getUniqueValuesFromOrgUnitId = (orgUnitId: number): Promise<any> => {
  const query: {
    org_unit_id: string;
  } = {
    org_unit_id: orgUnitId.toString(),
  };
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/employee/organization_unit/GetUniqueValuesFromOrgUnitId",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/entity_employee_option_attributes/GetUniqueValuesFromOrgUnitId",
      query: query,
    });
    return http.get(url);
  }
};
