import { FC } from "react";
import { observer } from "mobx-react";
import { THotspotsQuestion } from "types/dashboard";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";

import store from "../store";
import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

type QuestionsListProps = {
    questionsList: THotspotsQuestion[];
    isENPS: boolean;
    isOveralScore: boolean;
    changeIsOveralScore: (value: boolean) => void;
    changeIsENPS: (value: boolean) => void;
    chekAllQuestions: (sectionId: number, value: boolean) => void;
    changeColSetupQuestion: (sectionId: number, questionId: number, value: boolean) => void;
    changeCheckQuestionsList: (value: boolean) => void;
};

const QuestionsList: FC<QuestionsListProps> = observer((props) => {
    const returnQuestionsCount = (el: THotspotsQuestion) => {
        return Boolean(!el.list.find((el) => !el.checked))
            ? `All questions selected`
            : `${Object.values(el.list).filter((el) => el.checked === true).length} question selected`;
    };

    return (
        <Container>
            <Header>
                <StyledFormControlLabel
                    label={<LabelMenuCheckBox id="overal_score_label">Overall score</LabelMenuCheckBox>}
                    control={
                        <StyledCheckbox
                            name="isOveralScore"
                            id="overal_score"
                            onChange={(e) => props.changeIsOveralScore(e.target.checked)}
                            size="small"
                            checked={props.isOveralScore}
                        />
                    }
                />
                <CustomWidthTooltip
                    title={<p>eNPS cannot be selected as the eNPS question was not added to This review.</p>}
                    placement="top"
                    arrow
                    disableHoverListener={store.isActiveEnps}
                >
                    <StyledFormControlLabel
                        label={<LabelMenuCheckBox id="enps_label">eNPS</LabelMenuCheckBox>}
                        control={
                            <StyledCheckbox
                                name="isENPS"
                                id="enps"
                                onChange={(e) => props.changeIsENPS(e.target.checked)}
                                size="small"
                                checked={props.isENPS}
                                disabled={!store.isActiveEnps}
                            />
                        }
                    />
                </CustomWidthTooltip>
                <StyledFormControlLabel
                    label={
                        <LabelMenuCheckBox id="favourable_score_label">Favourable score</LabelMenuCheckBox>
                    }
                    control={
                        <StyledCheckbox
                            name="isFavourableScore"
                            id="favourable_score"
                            onChange={() =>
                                props.changeCheckQuestionsList(
                                    !props.questionsList.some((el) => el.list.find((item) => item.checked))
                                )
                            }
                            size="small"
                            checked={!props.questionsList.some((el) => el.list.find((item) => !item.checked))}
                        />
                    }
                />
            </Header>
            {props.questionsList.map((el) => (
                <QuestionSetion key={el.dimensionId}>
                    <SectionTitle>
                        <FormControlLabel
                            label={
                                <LabelCheckbox id={`section_title_${el.dimensionId}_label`}>
                                    {el.dimensionName}
                                </LabelCheckbox>
                            }
                            control={
                                <StyledCheckbox
                                    id={`section_title_${el.dimensionId}`}
                                    onChange={(e) =>
                                        props.chekAllQuestions(
                                            el.dimensionId,
                                            Boolean(!el.list.find((el) => el.checked))
                                        )
                                    }
                                    size="small"
                                    checked={Boolean(!el.list.find((el) => !el.checked))}
                                />
                            }
                        />
                        <SelectedCounter>{returnQuestionsCount(el)}</SelectedCounter>
                    </SectionTitle>
                    <List>
                        {el.list.map((question) => (
                            <RowFormControlLabel
                                key={question.questionId}
                                label={
                                    <LabelCheckbox id={`question_${question.questionId}_${el.dimensionId}_label`}>
                                        {question.question}
                                    </LabelCheckbox>
                                }
                                control={
                                    <StyledCheckbox
                                        id={`question_${question.questionId}_${el.dimensionId}`}
                                        onChange={(e) =>
                                            props.changeColSetupQuestion(
                                                el.dimensionId,
                                                question.questionId,
                                                !question.checked
                                            )
                                        }
                                        size="small"
                                        checked={question.checked}
                                    />
                                }
                            />
                        ))}
                    </List>
                </QuestionSetion>
            ))}
        </Container>
    );
});

export default QuestionsList;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const QuestionSetion = styled.div`
  width: 100%;
  margin-top: 16px;
  padding: 16px 24px;
  border-radius: 10px;
  background-color: var(--colorPaletteGrayBackground1);
`;

const LabelMenuCheckBox = styled.p`
  color: var(--colorPaletteVioletBackground1);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

const LabelCheckbox = styled.p`
  color: var(--colorPaletteVioletBackground1);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

const SectionTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
  margin-bottom: 4px;
`;

const SelectedCounter = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorPaletteVioletBackground1);
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 0px;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
  margin: 32px 0px 16px 0px;
`;

const StyledCheckbox = styled(Checkbox)`
  color: var(--colorBrandForeground3) !important;
`;

const RowFormControlLabel = styled(FormControlLabel)`
  margin-top: 4px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  .Mui-disabled {
    color: var(--colorGrayForeground6) !important;
    p {
      color: var(--colorGrayForeground6) !important;
    }
  }
`;

const MyTooltip = styled(Tooltip) <{ $isDimension: boolean }>``;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <MyTooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 200,
        fontWeight: 500,
        fontSize: "12px",
        textAlign: "center",
        background: "var(--colorShadowInverted2)",
    },
});

const List = styled.div`
  display: flex;
  flex-direction: column;
`;
