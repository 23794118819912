import { FC } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import BackSpaceIcon from "features/Templates/icons/BackSpaceIcon";
import store from "features/Survey/ImportHistoricalSurvey/store";
import { createUrl } from "helpers/url";

type BackToMainLayoutHeaderProps = {};

const BackToMainLayoutHeader: FC<BackToMainLayoutHeaderProps> = () => {
  const navigate = useNavigate();

  const handleBacktoProjects = () => {
    navigate(
      createUrl({
        path: "/projects",
        query: { project_id: store.projectId.toString() },
      })
    );
    localStorage.setItem("surveyAction", "create");
  };

  return (
    <BackToMainLayoutHeaderWrapper id="ImportHistoricalSurvey_LayoutHeader">
      <BackContainer>
        <BackButton id="ImportHistoricalSurvey_LayoutBackButton" onClick={handleBacktoProjects}>
          <BackSpaceIcon />
          <BackToModule id="ImportHistoricalSurvey_LayoutBackButton_Label">Back to survey project</BackToModule>
        </BackButton>
      </BackContainer>
      <CreateTemplateText id="ImportHistoricalSurvey_LayoutHeader_Label">
        Import historic survey
      </CreateTemplateText>
      <div></div>

    </BackToMainLayoutHeaderWrapper>
  );
};

export default BackToMainLayoutHeader;

const BackToMainLayoutHeaderWrapper = styled.div`
  width: 100%;
  height: 64px;
  padding: 20px 90px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--colorPaletteVioletBackground1);
`;

const BackToModule = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralBackground1);
`;

const BackContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CreateTemplateText = styled.div`
  color: white;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
`;

const BackButton = styled.button`
  background: var(--ER-Theme-sys-light-on-primary-container, #1e004d);
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;
