import { FC } from "react";
import store from './store'
import { observer } from "mobx-react"

import {
  Divider, Box, Button
} from "@mui/material"
import CustomButton from "components/button/Button";

type NoEntityCardType = {
};

const NoEntityCard: FC<NoEntityCardType> = observer((props) => {
  return (
    <Box style={{ margin: 50, padding: 100, backgroundColor: 'white', borderRadius: '30px' }} display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
      <Box>
        <img alt={"noEntity"} width={140} height={160} src={"/assets/rocket.png"} />
      </Box>
      <Box style={{ margin: 10, fontSize: 30, width: 440, textAlign: 'center', fontWeight: 600 }}>
        Looks like no entities have been created yet.
      </Box>
      <Box style={{ width: 300, margin: 30 }}>
        <CustomButton fullWidth variant="contained" onClick={() => { store.changeOpenPanelCreateEntity(true) }}>
          Create an entity
        </CustomButton>
      </Box>
    </Box>
  );
});

export default NoEntityCard;
