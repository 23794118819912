import http from "./https";
import { createUrl } from "../helpers/url";

export const getPreviousImports = (entityId: number) =>{
  const query: { entity_id: string } = { entity_id: entityId.toString() };
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/employee/GetPreviousImports",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/entity_employees/GetPreviousImports",
      query: query,
    });
    return http.get(url);
  }
}
