import * as React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { GetEmployeesByEntityType } from "api/go/useGetEmployeesByEntity";
import useDebounce from "utils/useDebounse";
import { observer } from "mobx-react";
import store from "./store";

interface AsynchronousSearchType {}

const Asynchronous = observer((props: AsynchronousSearchType) => {
  const [open, setOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [keySearch, setKeySearch] = React.useState(new Date());

  const debouncedValue = useDebounce(searchValue, 600);

  React.useEffect(() => {
    if (searchValue == null || searchValue === "") {
      store.changeOptions([]);
      return;
    }
    store.changeLoading(true);
    store.loadEmployees(searchValue);
  }, [debouncedValue]);

  const handleChangeSearchValue = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: GetEmployeesByEntityType,
  ) => {
    store.changeOptions([]);
    setSearchValue("");
    setKeySearch(new Date());
    if (value === null) return;
    store.changeSelectedManager(value);
  };

  return (
    <Autocomplete
      id="EmployeeList_AddEditPopUp_ManageAttributes_Content_Email_AsynchronousDemoInput"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionLabel={(option) => `${option.full_name} ${option.email}`}
      renderOption={(props, option) => (
        <Box
          sx={{
            borderRadius: "8px",
            margin: "5px",
          }}
          component="li"
          {...props}
        >
          {option.full_name} {option.preffered_name && `(${option.preffered_name})`}
          <br />
          {option.email}
        </Box>
      )}
      fullWidth
      value={null}
      blurOnSelect={true}
      filterSelectedOptions
      inputValue={searchValue}
      onInputChange={(_, newInputValue) => setSearchValue(newInputValue)}
      key={keySearch.toISOString()}
      onChange={handleChangeSearchValue}
      clearOnEscape={true}
      options={store.optionsEmployees}
      loading={store.loadingEmployees}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          fullWidth
          placeholder="Search for name/email"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {store.loadingEmployees && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
});

export default Asynchronous;
