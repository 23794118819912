import { ReactElement, createContext, useContext, useMemo } from "react"
import { EmployeeAttribute } from "./sharedTypes"

type EmployeesAttributesContextValue = {
  employeesAttributes: EmployeeAttribute[]
}

const EmployeesAttributesContext = createContext<EmployeesAttributesContextValue | undefined>(undefined)

type EmployeesAttributesProviderProps = {
  employeesAttributes: EmployeeAttribute[] | undefined
  children: ReactElement
}

const EmployeesAttributesProvider = ({
  employeesAttributes,
  children,
}: EmployeesAttributesProviderProps): ReactElement => {
  const value = useMemo(() => {
    const filteredEmployeeAttributes = (employeesAttributes || [])

    return {
      employeesAttributes: filteredEmployeeAttributes,
    }
  }, [employeesAttributes])

  return (
    <EmployeesAttributesContext.Provider value={value}>
      {children}
    </EmployeesAttributesContext.Provider>
  )
}

export default EmployeesAttributesProvider

export function useEmployeesAttributesContext(): EmployeesAttributesContextValue {
  const context = useContext(EmployeesAttributesContext)

  if (context === undefined) {
    throw new Error("useEmployeesAttributesContext must be used within a EmployeesAttributesProvider")
  }

  return context
}
