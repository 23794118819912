import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router";
import DoneIcon from "@mui/icons-material/Done";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import SettingsIcon from "@mui/icons-material/Settings";

import { createUrl } from "helpers/url";
import CustomButton from "components/button/Button";
import storePermission from "components/workspaces-sidebar/StorePermission";
import storeCommunication from "features/Survey/Communication/store";
import storeRecipients from "features/Survey/Recipients/store";
import storeSurvey from "features/Survey/SurveyCreation/store";
import { Divider } from "@mui/material";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import { SURVEY_STATUS } from "constants/survey-status";

type HeaderMenuProps = {};

type HeaderMenuButton = {
  id: number;
  name: string;
  path: string;
  click: (path?: any) => void;
  filled?: boolean;
  disabled?: boolean;
  dataTestId: string;
  template?: boolean;
  icon?: React.ReactNode;
  check?: boolean;
  render?: React.ReactNode;
};

export const HeaderMenu: FC<HeaderMenuProps> = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuButton, setMenuButton] = useState<HeaderMenuButton[]>([]);

  let query = useQuery();
  let idSurvey = query.get("id") !== null ? query.get("id") : "0";
  let idProject = query.get("project_id") !== null ? query.get("project_id") : "0";

  let id_survey = Number(idSurvey);
  let project_id = Number(idProject);

  if (isNaN(id_survey) || id_survey === 0) {
    if (isNaN(project_id) || project_id === 0) {
      window.location.href = "/projects";
    }
  }

  useEffect(() => {
    if (id_survey === 0) {
      setMenuButton(
        menuButton.map((el) => {
          return {
            ...el,
            filled: false,
          };
        })
      );
    } else {
      storeSurvey.loadMainInformation(id_survey);
    }
  }, [id_survey]);

  useEffect(() => {
    return () => (storeSurvey.clearStore())
  }, []);

  useEffect(() => {
    setMenuButton([
      {
        id: 1,
        name: "Questionnaire",
        path: "/survey",
        click: (path: string) => navigateHandler(path),
        filled: storeSurvey.checkSurvey !== null,
        dataTestId: "Survey_EditCreate_Questionnaire_Header_Menu_",
      },
      {
        id: 2,
        name: "Recipients",
        path:
          storeSurvey.Survey?.project_type === SEND_OUT_SURVEY_TYPES.Milestones
            ? "/recipients-millstone-schedule"
            : "/recipients",
        click: (path: string) => {
          if (storeSurvey.translationChanged) {
            storeSurvey.openUnsavedTranslationModal(true, navigatePath(path));
          } else {
            if (storeSurvey.checkSurvey !== null) {
              navigateHandler(path);
            }
          }
        },
        filled: storeRecipients.exportRecipientsList?.length > 0,
        disabled: storeSurvey.checkSurvey === null,
        dataTestId: "Survey_EditCreate_Recipients_Header_Menu_",
      },
      {
        id: 3,
        name: "Set date and schedule communications",
        path: "/communications",
        click: (path: string) => {
          if (storeSurvey.translationChanged) {
            storeSurvey.openUnsavedTranslationModal(true, navigatePath(path));
          } else {
            if (storeSurvey.checkSurvey !== null) {
              navigateHandler(path);
            }
          }
        },
        filled: storeCommunication.checkStartDate !== null,
        disabled: storeSurvey.checkSurvey === null,
        dataTestId: "Survey_EditCreate_Communication_Header_Menu_",
      },
    ]);
  }, [
    storeSurvey.checkSurvey,
    storeRecipients.exportRecipientsList,
    storeCommunication.checkStartDate,
  ]);

  const navigatePath = (path: string): string => {
    return createUrl({
      path: path,
      query: {
        id: String(id_survey),
      },
    });
  };

  const navigateHandler = (path: string) => {
    navigate(navigatePath(path));
  };

  const SETTING_TEMPLATE_BUTTONS: HeaderMenuButton[] = [
    {
      id: 2,
      path: "/survey",
      name: "Survey settings",
      template: false,
      icon: <SettingsIcon />,
      click: () => storeSurvey.changeOpenSettings(true),
      dataTestId: "Survey_EditCreate_Communication_Header_Menu_SurveySettings_Button",
    },
  ];

  SETTING_TEMPLATE_BUTTONS.push(
    {
      id: 1,
      path: "/survey",
      name: "Templates and library",
      template: true,
      click: () => storeSurvey.changeOpenSelectTemplate(true),
      dataTestId: "Survey_EditCreate_Communication_Header_Menu_TemplatesLibrary_Button",
      check:
        storeSurvey.Survey?.status_idCode === SURVEY_STATUS.running ||
        storeSurvey.Survey?.status_idCode === SURVEY_STATUS.complete,
      render: <DividerStyle orientation="vertical" flexItem />,
    },
    {
      id: 3,
      path: "/communications",
      name: "Templates",
      template: true,
      click: () => {
        storeCommunication.setOPenTemplateCommModal(true);
        storeCommunication.setCurentSurveyId(id_survey);
      },
      dataTestId: "Survey_EditCreate_Communication_Header_Menu_Templates_Button",
    }
  );

  const settingTemplateButtons = SETTING_TEMPLATE_BUTTONS.sort((a, b) => a.id - b.id).map(
    (el, index) => {
      if (location.pathname === el.path) {
        if (el.template && !StorePermission.features.all_templates.hasFeature) return;
        if (el.check) return;
        return (
          <>
            <ButtonWrapper key={index}>
              <StyledMenuButton id={el.dataTestId} variant="outlined" onClick={el.click}>
                {el.icon}
                {el.name}
              </StyledMenuButton>
            </ButtonWrapper>
            {el.render}
          </>
        );
      }
      return null;
    }
  );

  return (
    <HeaderMenuWrapper>
      <LinksWrapper>
        {menuButton.map((menuItem: HeaderMenuButton, index: number) => (
          <ItemMenuWrapper key={index + menuItem.name}>
            <ItemMenu
              $disabled={menuItem.disabled}
              $active={menuItem.path.includes(location.pathname)}
              onClick={() => menuItem.click(menuItem.path)}
            >
              <span id={`${menuItem.dataTestId}${id_survey}`}>
                {menuItem.filled ? (
                  <SuccessPoint>
                    <DoneIcon />
                  </SuccessPoint>
                ) : (
                  !menuItem.filled && `${menuItem.id}. `
                )}
                {menuItem.name}
              </span>
            </ItemMenu>
            {index !== menuButton.length - 1 && (
              <ArrowWrapper>
                <ArrowRightAltIcon />
              </ArrowWrapper>
            )}
          </ItemMenuWrapper>
        ))}
      </LinksWrapper>

      <ButtonsWrapper>{settingTemplateButtons}</ButtonsWrapper>
    </HeaderMenuWrapper>
  );
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const HeaderMenuWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--colorNeutralBackground1);
  padding: 0px 40px 0 80px;
  position: absolute;
  box-shadow: -1px 2px 10px rgb(0 0 0 / 0.2);
  z-index: 200;
`;

const LinksWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  background-color: var(--colorNeutralBackground1);
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  background-color: var(--colorNeutralBackground1);
`;

const ButtonWrapper = styled.div`
  margin: 0px 10px;
`;

const ItemMenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ItemMenu = styled.li<{ $active?: boolean; $disabled?: boolean }>`
  list-style-type: none;
  height: 56px;

  a,
  span {
    cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: ${(props) =>
    props.$active ? "var(--colorBrandForeground1)" : "var(--colorPaletteVioletBackground1)"};
    width: fit-content;
    border-bottom: ${(props) => props.$active && `4px solid var(--colorBrandForeground1)`};
    height: 100%;
    padding-top: ${(props) => (props.$active ? "4px" : "0px")};
  }
`;

const ArrowWrapper = styled.div`
  padding: 0 16px;

  svg {
    color: var(--colorPaletteBlueBackground1);
  }
`;

const SuccessPoint = styled.p`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--colorPalleteLightBlue);
  margin-right: 6px;

  svg {
    font-size: 12px;
    color: var(--colorNeutralForeground1);
  }
`;

const StyledMenuButton = styled(CustomButton)`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 180px;

  svg {
    margin-right: 5px;
  }
`;

const DividerStyle = styled(Divider)`
  margin: 11px 0 !important;
`;
