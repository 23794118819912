import http from "api/https";

export const deleteRoleWithUsers = (
  idRole: number,
  real_id: number,
  access_level_code: string,
  region_id: number
): Promise<any> => {
  if (http.isAPIGO) {
    return http.postGO("/permission/DeleteRoleWithUsers", {
      idRole: idRole,
      real_id: real_id,
      access_level_code: access_level_code,
      region_id: region_id
    });
  } else {
    return http.post("/role/DeleteRoleWithUsers", {
      idRole: idRole,
      real_id: real_id,
      access_level_code: access_level_code,
      region_id: region_id
    });
  }
};
