import * as React from "react";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import { styled } from "@mui/system";
import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
} from "@mui/base/Unstable_NumberInput";

const NumberInput = React.forwardRef(function CustomNumberInput(
  props: NumberInputProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <BaseNumberInput
      id={ props.id ? `BaseNumberInput_${props.id}` : `BaseNumberInput`}
      slots={{
        root: StyledInputRoot,
        input: StyledInput,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          id: props.id ?  "NumberInput_Increment_" + props.id : "NumberInput_Increment",
          children: <AddIcon fontSize="small" />,
          className: "increment",
        },
        decrementButton: {
          id: props.id ?  "NumberInput_Decrement_" + props.id : "NumberInput_Decrement",
          children: <RemoveIcon fontSize="small" />,
          className: "decrement",
        },
      }}
      {...props}
      ref={ref}
      defaultValue={1}
    />
  );
});

export const QuantityInput = ({
  value,
  onChange,
  disabled,
  id
}: {
  value: number;
  onChange: (e: any, val: number) => void;
  disabled?: boolean;
  id?: string;
}) => {
  return (
    <NumberInput
      id={id ? `Quantity_Input_${id}` : `Quantity_Input`}
      aria-label="Quantity Input"
      disabled={disabled}
      min={0}
      value={value}
      onChange={onChange}
    />
  );
};

const StyledInputRoot = styled("div")`
  font-family: "Roboto";
  font-weight: 400;
  color: var(--colorNeutralForeground1);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

const StyledInput = styled("input")`
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
  background: transparent;
  border: 1px solid var(--colorPaletteBlueBackground1);
  margin: 0px;
  height: 40px;
  width: 43px;
  outline: 0;
  min-width: 0;
  text-align: center;
`;

const StyledButton = styled("button")`
  font-family: "Roboto";
  font-size: 14px;
  box-sizing: border-box;
  line-height: 24px;
  border: 1px solid;
  border-color: var(--colorPaletteBlueBackground1);
  background: transparent;
  color: var(--colorBrandForeground1);
  width: 43px;
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    cursor: pointer;
    background: var(--colorNeutralForegroundInverted1);
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
    border-radius: 0px 4px 4px 0px;
  }

  &.decrement {
    border-radius: 4px 0px 0px 4px;
  }
`;
