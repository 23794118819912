import React, { PureComponent } from 'react'

import {
  InputLabel
} from '@mui/material';
import FormHelperText from '@mui/material';

import DropdownTreeSelect from "react-dropdown-tree-select";
import 'react-dropdown-tree-select/dist/styles.css'
import "./treeLookUp.css";

import i18n from 'i18next';


class WithOptions extends PureComponent {
  constructor(props) {
    super(props)
  }

  createDataTree = (dataset, id) => {
    if (id == null) id = 0;
    const hashTable = Object.create(null);

    dataset.forEach(aData => hashTable[aData.id] = {
      label: this.props.fieldNameDisplay == null ? aData.name : this.props.fieldNameDisplay(aData),
      value: aData.id,
      expanded: true,
      checked: aData.id == id,
      children: []
    });
    const dataTree = [{
      label: "No chosen",
      value: 0,
      expanded: true,
      checked: id == 0,
      children: []
    }];
    dataset.forEach(aData => {
      if (aData.parent_id && hashTable[aData.parent_id]) {
        if (hashTable[aData.parent_id].children == null) hashTable[aData.parent_id].children = []
        hashTable[aData.parent_id].children.push(hashTable[aData.id])
      }
      else dataTree.push(hashTable[aData.id])
    });
    return dataTree;
  };

  onChange = (curNode, selectedNodes) => {
    console.log('onChange::', curNode, selectedNodes)
  }

  render() {
    var current = this.props.data.find(x => x.id == this.props.id)
    return (
      <div style={{width: '100%'}}>
        <InputLabel style={{width: '100%'}} error={this.props.error}>{this.props.label}</InputLabel>
        <DropdownTreeSelect
        style={{width: '100%'}}
          id={this.props.id}
          className="mdl-demo"
          data={this.createDataTree(this.props.data, this.props.value)}
          onChange={(curNode, selectedNodes) => {
            var value = 0;
            if (curNode.checked == true) {
              value = curNode.value;
            }
            var event = { target: { name: this.props.name, value: value } };
            this.props.onChange(event);
          }}
          keepTreeOnSearch={true}
          // onAction={this.onAction}
          // onNodeToggle={this.onNodeToggle}
          mode={"radioSelect"}
          inlineSearchInput={true}
          texts={{
            placeholder:
              current == null ? " " :
                this.props.fieldNameDisplay == null ?
                  current.name
                  :
                  this.props.fieldNameDisplay(current)

            , inlineSearchPlaceholder: i18n.t("common:search")
          }}
        />

        {/* <React.Fragment>
                    <FormControl fullWidth required={this.props.required} disabled={this.props.disabled}>
                        <Grid container direction='row' spacing={0}>
                            <Grid item xs={12}>
                                <InputLabel id='demo-simple-select-error-label' error={this.props.error}>{this.props.label}</InputLabel>
                         
                            </Grid>
                        </Grid>
                        {this.props.error ? <FormHelperText error={true}>{this.props.helperText}</FormHelperText> : ''}
                    </FormControl>

                </React.Fragment> */}

      </div>
    )
  }
}
export default WithOptions
