
import http from "api/https";
import { createUrl } from "../../helpers/url";

export const getEmployeesByEntityAndManager = (id: number, email: string, employee_id?: number): Promise<any> => {
  const query: { employee_id: string, id: string, email: string } = {
    employee_id: employee_id.toString(),
    id: id.toString(),
    email: email.toString(),
  };

  if (http.isAPIGO) {
    const url = createUrl({
      path: "/employee/employees/SearchByFullNameAndByIdEntityNotUnderManager",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/employees/SearchByFullNameAndByIdEntityNotUnderManager",
      query: query,
    });
    return http.get(url);
  }
};
