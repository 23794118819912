import { FC } from "react";

import styled from "styled-components";

type AccessRestrictedProps = {};

const AccessRestricted: FC<AccessRestrictedProps> = () => {
  return (
    <Container>
      <Content>
        <CustomImage alt={"access-restricted-image"} src={"/assets/access-restricted.png"} />
        <Label>Access Restricted</Label>
        <Description>Please reach out to your administrator for assistance.</Description>
      </Content>
    </Container>
  );
};

export default AccessRestricted;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 109px 64px;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1156px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px;
  background-color: var(--colorNeutralBackground1);
`;

const Label = styled.p`
  font-family: Roboto,serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  margin-top: 24px;
  color: var(--colorNeutralForeground1);
`;

const Description = styled.p`
  font-family: Roboto,serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.23px;
  margin-top: 8px;
  color: var(--colorNeutralForeground2);
`;

const CustomImage = styled.img`
  width: 108px;
  height: 109px;
`;
