import { FC } from "react";
import { observer } from "mobx-react";
import { Button, Dialog, DialogContent } from "@mui/material";

import store from "./../store";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";

type PreviewTemplateProps = {};

const PreviewTemplate: FC<PreviewTemplateProps> = observer(() => {
  return (
    <StyledDialog
      maxWidth={"lg"}
      open={store.openPreviewEmail}
      onClose={() => {
        store.changeOpenPreviewEmail(false);
      }}
    >
      <ClosePreviewButton onClick={() => store.changeOpenPreviewEmail(false)}>
        <CloseIcon />
        Close preview
      </ClosePreviewButton>
      <StyledDialogContent
        dangerouslySetInnerHTML={{ __html: store.previewEmailText }}
      ></StyledDialogContent>
    </StyledDialog>
  );
});

const ClosePreviewButton = styled(Button)`
  width: 140px;
  height: 34px;
  position: absolute !important;
  top: -52px !important;
  left: calc(50% - 70px);
  background-color: var(--colorNeutralBackground1) !important;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2) !important;
  text-transform: none !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  border: 1px solid var(--colorNeutralForeground5) !important;
  gap: 8px;

  svg {
    font-size: 16px;
  }
`;

const StyledDialog = styled(Dialog)`
  padding: 50px !important;

  .MuiPaper-root {
    overflow: inherit !important;
  }
  
  .MuiDialog-paper {
    border-radius: 9px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  width: 700px;
  border-radius: 9px;
  padding: 0 !important;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6) !important;
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

export default PreviewTemplate;
