
import { FC, } from "react";
import { observer } from "mobx-react"
import {
  Grid, Box, FormControlLabel, Checkbox, Divider
} from "@mui/material"
import styled from "styled-components";
import CustomButton from "components/button/Button";
import store from "./../store";
import DateTimeField from "./DateTimeField";
import TimeField from "./TimeField";
import CalendarIcon from "./CalendarIcon";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";
import { SURVEY_STATUS } from "constants/survey-status";
import dayjs from "dayjs";

type SurveyDatesProps = {};

const SurveyDates: FC<SurveyDatesProps> = observer((props) => {
  return (
    <>
      <Container>
        <Grid item>
          <BoxText>
            <IconWrapped>
              <CalendarIcon />
            </IconWrapped>
            <BoxMainText id="Survey_EditCreate_Communication_SetSurveyDates_Description">
              The survey will automatically go out on the date and time
            </BoxMainText>
          </BoxText>
        </Grid>
        <GridWrapped item>
          <HowWouldText id="Survey_EditCreate_Communication_SetSurveyDates_Surveylaunch_Label">
            Survey launch{" "}
            {store.AllInfo?.project_type === SEND_OUT_SURVEY_TYPES.Milestones ? (
              <>time</>
            ) : (
              <>date</>
            )}
          </HowWouldText>

          <WrappedTimeField>
            {store.AllInfo?.project_type === SEND_OUT_SURVEY_TYPES.Milestones && (
              <TimeField
                value={store.timestart_milestone}
                disabled={
                  store.leave_date_empty || store.AllInfo?.survey_status === SURVEY_STATUS.complete
                }
                changeValue={(value: any) => {
                  store.changeValue("timestart_milestone", value);
                  store.changeDatesTab(true);
                }}
              />
            )}

            {store.AllInfo?.project_type !== SEND_OUT_SURVEY_TYPES.Milestones && (
              <DateTimeField
                id="Survey_EditCreate_Communication_SetSurveyDates_StartDaySet_InputContainer"
                value={store.survey_date_start}
                changeValue={(value: any) => {
                  store.changeValue("survey_date_start", value);
                  store.changeDatesTab(true);
                }}
                disabled={
                  store.leave_date_empty ||
                  store.AllInfo?.survey_status === SURVEY_STATUS.running ||
                  store.AllInfo?.survey_status === SURVEY_STATUS.complete
                }
                minDateTime={dayjs().add(15, "minute")}
              />
            )}
            <CalendarDescription id="Survey_EditCreate_Communication_SetSurveyDates_Surveylaunch_Description">
              Local timezone: UTC {store.timezone_message}
            </CalendarDescription>
          </WrappedTimeField>

          <HowWouldText id="Survey_EditCreate_Communication_SetSurveyDates_SurveyEnd_Label">
            Survey end date
          </HowWouldText>

          <WrappedTimeField>
            <DateTimeField
              id="Survey_EditCreate_Communication_SetSurveyDates_EndDaySet_InputContainer"
              value={store.survey_date_end}
              changeValue={(value: any) => {
                store.changeValue("survey_date_end", value);
                store.changeDatesTab(true);
              }}
              minDateTime={
                store.survey_date_start
                  ? store.survey_date_start.add(1, "minute")
                  : dayjs().add(15, "minute")
              }
              disabled={
                store.leave_date_empty || store.AllInfo?.survey_status === SURVEY_STATUS.complete
              }
            />
            <CalendarDescription id="Survey_EditCreate_Communication_SetSurveyDates_SurveyEnd_Description">
              Local timezone: UTC {store.timezone_message}
            </CalendarDescription>
          </WrappedTimeField>

          <Divider />

          {store.AllInfo?.survey_status === SURVEY_STATUS.running ? (
            <FormControlLabel
              label={
                <LabelCheckbox id="Survey_EditCreate_Communication_SetSurveyDates_Checkbox_Label">
                  End survey now
                </LabelCheckbox>
              }
              control={
                <Checkbox
                  id="Survey_EditCreate_Communication_SetSurveyDates_Checkbox"
                  onChange={(e) => {
                    store.close_now = e.target.checked;
                    store.changeDatesTab(true);
                  }}
                  size="small"
                  checked={store.close_now}
                  disabled={store.AllInfo?.survey_status === SURVEY_STATUS.complete}
                />
              }
            />
          ) : (
            <FormControlLabel
              label={
                <LabelCheckbox id="Survey_EditCreate_Communication_SetSurveyDates_Checkbox_Label">
                  Keep it unscheduled (I'll decide later)
                </LabelCheckbox>
              }
              control={
                <Checkbox
                  id="Survey_EditCreate_Communication_SetSurveyDates_Checkbox"
                  onChange={(e) => {
                    store.changeValue("leave_date_empty", e.target.checked);
                    store.changeDatesTab(true);
                  }}
                  size="small"
                  checked={store.leave_date_empty}
                  disabled={
                    store.AllInfo?.survey_status === SURVEY_STATUS.running ||
                    store.AllInfo?.survey_status === SURVEY_STATUS.complete
                  }
                />
              }
            />
          )}

          <ButtonWrapper>
            <ButtonWr>
              <StyledNextButton
                id="Survey_EditCreate_Communication_SetSurveyDates_Save_Button"
                disabled={!store.dates_changed}
                variant="contained"
                onClick={() => {
                  store.SaveSurveyDates();
                }}
              >
                Save changes
              </StyledNextButton>
            </ButtonWr>
          </ButtonWrapper>
        </GridWrapped>
      </Container>
    </>
  );
});

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 50px;
  margin-bottom: 20px;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const ButtonWr = styled.div`
  margin-right: 20px;
`;

const StyledNextButton = styled(CustomButton)`
  width: 100%;
  margin-right: 20px;
  max-width: 150px;
`;

const IconWrapped = styled.div`
  margin: 0 5px;
`;

const BoxText = styled(Box)`
  width: 230px;
  height: 78px;
  color: var(--colorNeutralForeground2);
  margin-left: 20px;
  display: flex;
  flex-direction: row;
`;

const LabelCheckbox = styled.span`
  color: var(--ER-Theme-sys-light-on-tertiary-container, #707882);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const GridWrapped = styled(Grid)`
  /* margin: 0px 10px 20px 50px !important; */
  width: 100%;
`

const WrappedTimeField = styled.div`
  margin: 20px 0px;
`;
const CalendarDescription = styled.div`
  font-family: Roboto;
  font-size: 14px;
  margin-top: 5px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground5);
`;

const HowWouldText = styled.div`
  color: var(--colorNeutralForeground2);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
`;
const BoxMainText = styled.div`
  width: 200px;
  color: var(--ER-Theme-sys-light-on-tertiary-container, #707882);
  /* ER Theme/body/medium */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;
export default SurveyDates;
