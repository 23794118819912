import React, { ChangeEvent, useState, useRef } from "react";
import styled from "styled-components";
import ImageIcon from '@mui/icons-material/Image';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CustomButton from "components/button/Button";
import { observer } from "mobx-react";
import store from './store'

interface FileInputProps {
}


const FileInput: React.FC<FileInputProps> = observer(() => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      store.changeFile(file);
      if (file != undefined) {
        const blob = new Blob([file!]);
        const imageUrl = URL.createObjectURL(blob);
        setImageSrc(imageUrl);
        setSelectedFile(file!);
      }
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveButtonClick = () => {
    setSelectedFile(null);
    setImageSrc(null);
    store.changeFile(null);
  };

  return (
    <>
      <LabelText id="FileInputLabel">
        Company logo (Optional)
      </LabelText>
      <LabelSecondary id="FileInputSecondLabel">
        Only .jpg and .png formats with max file size of 1MB accepted
      </LabelSecondary>
      {(selectedFile || store.logo_url) ? (
        <LogoWrapper>
          <ImgWrapp src={imageSrc || store.logo_url || undefined} alt="Selected image" />
        </LogoWrapper>
      ) :
        <LogoWrapperNoImage>
          <NoImageItems>
            <ImageIconStyled />
            <NoImageText>No image uploaded</NoImageText>
          </NoImageItems>
        </LogoWrapperNoImage>}

      {store.errorfile !== "" ? <ErrorText>
        {store.errorfile}
      </ErrorText> : ""}
      <ButtonsWrapper>
        <input
          type="file"
          ref={fileInputRef}
          accept=".jpg, .jpeg, .png"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <UploadButton id="UploadImage" variant="outlined" onClick={handleButtonClick}> <FileUploadIcon fontSize="small" />Upload image</UploadButton>
        <CustomButton id="DeleteImage" onClick={handleRemoveButtonClick}>Delete image</CustomButton>
      </ButtonsWrapper>
    </>
  );
});


const LogoWrapper = styled.div`
  width: 130px;
  height: 130px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--colorPaletteBlueBackground1, #cdd3ec);
  border-radius: 8px;
`;
const LogoWrapperNoImage = styled.div`
  width: 130px;
  height: 130px;
  border-radius: 8px;
  border: 1px solid var(--colorPaletteBlueBackground1, #CDD3EC);
  background: #F9FAFC;
`;

const ErrorText = styled.div`
  color: var(--colorPaletteRedForeground2, #BD0202);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

const ImageIconStyled = styled(ImageIcon)`
  color: var(--colorNeutralForeground11, #D9DDE6);
  height: 70px !important;
  font-size: 40px !important;
`

const NoImageItems = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  align-items: center;
  flex-direction: column;
`;

const LabelText = styled.div`
  margin-top: 15px;
  font-size: 16px;
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
`;

const ImgWrapp = styled.img`
  max-width: 110px;
  max-height: 110px;
`

const NoImageText = styled.p`
  color: var(--colorNeutralForeground10, #A2A2A2);
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;

const LabelSecondary = styled.div`
  color: var(--colorNeutralForeground5, #959BA2);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 125% */
  margin-bottom: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin: 10px 0;
`;

const UploadButton = styled(CustomButton)`
  margin-right: 5px !important;
`



export default FileInput;
