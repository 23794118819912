import { FC } from "react";
import { SurveyPreview } from '../../features/Survey/SurveyPreview'

type CreateCommunicationTemplatePageProps = {};

const CreateCommunicationTemplatePage: FC<CreateCommunicationTemplatePageProps> = () => {
  return <div>
    <SurveyPreview />

  </div>;
};

export default CreateCommunicationTemplatePage;
