import { Button } from "@mui/material";
import { observer } from "mobx-react";
import { StarIcon } from "./StarIcon";
import { FC, Fragment, useEffect, useState } from "react";
import { DEFAULT_PAGINATION_DATA } from "constants/dashboard-responses";
import {
  FAVOURABLE,
  FAVOURABLE_VALUE,
  NEUTRAL,
  NEUTRAL_VALUE,
  UNFAVOURABLE,
  UNFAVOURABLE_VALUE,
} from "constants/dashboard-overview";
import { TDefaultPagination, TScoredQuestionsDimensionResponse } from "types/dashboard";

import store from "./store";
import styled from "styled-components";
import topStore from "../../store";
import Accordion from "@mui/material/Accordion";
import LinearBar from "./LinearBar";
import PeopleIcon from "@mui/icons-material/People";
import RemoveIcon from "@mui/icons-material/Remove";
import MessageIcon from "@mui/icons-material/Message";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import PaginationList from "../PaginationList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

type QuestionCardProps = {
  question: TScoredQuestionsDimensionResponse;
  isLastQuestion: boolean;
  localFilters: { id: number; name: string; segments: string[] }[];
  isQuestion?: boolean;
};

const QuestionCard: FC<QuestionCardProps> = observer((props) => {
  const [isCommentsExpanded, setIsCommentsExpanded] = useState<boolean>(false);
  const [activeCommentsTab, setActiveCommentsTab] = useState<number>(FAVOURABLE_VALUE);

  const [commentsList, setCommentsList] = useState<string[]>([]);
  const [commentsPagination, setCommentsPagination] =
    useState<TDefaultPagination>(DEFAULT_PAGINATION_DATA);

  const changeCommentsPagination = (field: string, value: number) =>
    setCommentsPagination((prev) => ({ ...prev, [field]: value }));

  const changeCommentsTab = (tab: number) => {
    setActiveCommentsTab(tab);
    setCommentsPagination(DEFAULT_PAGINATION_DATA);
  };

  useEffect(() => {
    const getData = async () => {
      if (isCommentsExpanded && topStore.selectedSurvey && props.question.id) {
        const response = await store.getDashboardResponsesScoredComments(
          topStore.selectedSurvey.value,
          props.localFilters,
          props.question.id,
          activeCommentsTab,
          { offset: commentsPagination.active_page, limit: commentsPagination.count_per_page }
        );

        setCommentsList(response.data);
        setCommentsPagination({
          active_page: response.active_page,
          count_per_page: response.count_per_page,
          total_count: response.total_count,
          total_pages: response.total_pages,
        });
      }
    };

    getData();
  }, [
    isCommentsExpanded,
    activeCommentsTab,
    topStore.selectedSurvey,
    commentsPagination.active_page,
    commentsPagination.count_per_page,
    topStore.overviewBenchmark,
    props.localFilters
  ]);

  return (
    <Container $isLast={props.isLastQuestion}>
      <ColGroup>
        <Col>
          <Title>{props.question?.title}</Title>

          {props.isQuestion && (
            <Group>
              <StyledPeopleIcon />
              <SmallText>{props.question?.respondedCount} responded</SmallText>
            </Group>
          )}

          {props.question.overall && (
            <Group>
              <StyledDataUsageIcon />
              <SmallText>Contributes to overall score</SmallText>
            </Group>
          )}
          {props.question.conditionalLogic && (
            <Group>
              <ConditionalLogicPopupButton
                variant="text"
                onClick={() => {
                  store.setData(props.question, "activeScoredQuestion");
                  store.setData(true, "isOpenConditionalLogicPopup");
                }}
              >
                <StarIcon />
                Conditional logic applied
              </ConditionalLogicPopupButton>
            </Group>
          )}
        </Col>
        <Col>
          <Label>Filtered respondents</Label>
          <Row>
            <BoldText>{props.question.allRespondentsPercent}%</BoldText>
            {!topStore.selectedSurvey?.isFirst && (
              <Fragment>
                {props.question.allRespondentsPercentDiff > 0 ? (
                  <StyledArrowDropUpIcon />
                ) : props.question.allRespondentsPercentDiff < 0 ? (
                  <StyledArrowDropDownIcon />
                ) : null}
                {Math.abs(props.question.allRespondentsPercentDiff) !== 0 && (
                  <Text>{props.question.allRespondentsPercentDiff}%</Text>
                )}
              </Fragment>
            )}
          </Row>
          <Row>
            <LinearBar
              favorable={props.question.favourablePercent}
              neutral={props.question.neutralPercent}
              unFavourable={props.question.unFavourablePercent}
            />
          </Row>
        </Col>
        <Col>
          {/* <Label>Benchmark:</Label>
          <Row>
            <Benchmark>{props.question.benchmarkDiff}%</Benchmark>
            <Text>({props.question.benchmark}%)</Text>
          </Row> */}
        </Col>
      </ColGroup>
      {props.question.commentsCount > 0 && (
        <StyledAccordion
          expanded={isCommentsExpanded}
          onChange={() => setIsCommentsExpanded(!isCommentsExpanded)}
        >
          <AccordionSummary aria-controls="comments-accordion" id="comments">
            <AccordionTitle>
              <AccordionTitleGroup>
                <StyledMessageIcon />
                <AccordionTitleText>Comments ({props.question.commentsCount})</AccordionTitleText>
              </AccordionTitleGroup>
              <AccordionToogleButton>
                {isCommentsExpanded ? "Hide" : "Show"}
                <AccordionToogleButtonLabel>
                  {isCommentsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </AccordionToogleButtonLabel>
              </AccordionToogleButton>
            </AccordionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <AccordionMenu>
              <StyledTab
                $active={Boolean(activeCommentsTab === FAVOURABLE_VALUE)}
                onClick={() => changeCommentsTab(FAVOURABLE_VALUE)}
              >
                <StyledTabText>
                  {FAVOURABLE} ({props.question.positiveCommentsCount || 0})
                </StyledTabText>
              </StyledTab>
              <StyledTab
                $active={Boolean(activeCommentsTab === NEUTRAL_VALUE)}
                onClick={() => changeCommentsTab(NEUTRAL_VALUE)}
              >
                <StyledTabText>
                  {NEUTRAL} ({props.question.passiveCommentsCount || 0})
                </StyledTabText>
              </StyledTab>
              <StyledTab
                $active={Boolean(activeCommentsTab === UNFAVOURABLE_VALUE)}
                onClick={() => changeCommentsTab(UNFAVOURABLE_VALUE)}
              >
                <StyledTabText>
                  {UNFAVOURABLE} ({props.question.negativeCommentsCount || 0})
                </StyledTabText>
              </StyledTab>
            </AccordionMenu>
            <AccordionContent>
              <PaginationList
                list={commentsList}
                pagination={commentsPagination}
                changePagination={changeCommentsPagination}
              />
            </AccordionContent>
          </AccordionDetails>
        </StyledAccordion>
      )}
    </Container>
  );
});

export default QuestionCard;

const Container = styled.div<{ $isLast: boolean }>`
  width: 100%;
  padding-bottom: 48px;
  ${(props) => !props.$isLast && "border-bottom: 1px solid var(--colorPaletteBlueBackground1)"};
  margin-top: 24px;
`;

const ColGroup = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  column-gap: 32px;
  margin-bottom: 32px;
`;

const Col = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
  margin-bottom: 12px;
`;

const Group = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

const SmallText = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
  margin-left: 4px;
`;

const StyledPeopleIcon = styled(PeopleIcon)`
  font-size: 16px !important;
  color: var(--colorNeutralForeground5);
`;

const StyledDataUsageIcon = styled(DataUsageIcon)`
  font-size: 16px !important;
  color: var(--colorPaletteVioletBackground3);
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const StyledArrowDropUpIcon = styled(ArrowDropUpIcon)`
  color: var(--colorPaletteGreenBackground2);
  font-size: 24px !important;
  margin-top: -2px !important;
`;

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  color: var(--colorPaletteRedForeground1);
  font-size: 24px !important;
  margin-top: -4px !important;
`;

const StyledRemoveIcon = styled(RemoveIcon)`
  color: var(--colorNeutralForeground5);
  font-size: 18px !important;
  margin-left: 8px;
`;

const AccordionTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0px;
`;

const StyledMessageIcon = styled(MessageIcon)`
  color: var(--colorNeutralForeground5);
  font-size: 16px !important;
`;

const AccordionTitleText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  margin-left: 6px;
`;

const AccordionToogleButton = styled.button`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorBrandForeground1);
  border: none;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
`;

const StyledAccordion = styled(Accordion)`
  margin-top: 8px;
  border-radius: 10px !important;
  border: 1px solid var(--colorPaletteBlueBackground1) !important;
  box-shadow: 0px 2px 8px 0px var(--colorNeutralBackground3) !important;

  &:before {
    display: none !important;
  }
`;

const AccordionTitleGroup = styled.div`
  display: flex;
  align-items: center;
`;

const AccordionToogleButtonLabel = styled.span`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 0px 2px 5px 0px var(--colorNeutralBackground3);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

const AccordionContent = styled.div`
  width: 100%;
  padding: 24px;
  background-color: var(--colorNeutralForeground8);
`;

const StyledTab = styled.span<{ $active: boolean }>`
  background-color: ${(props) =>
    props.$active ? "var(--colorNeutralBackground1)" : "var(--colorPaletteGrayBackground1)"};
  border: 1px solid var(--colorPaletteBlueBackground1);
  padding: 10px 24px;
  height: 40px;
  min-height: 0;
  width: fit-content;
  border-radius: 10px 10px 0px 0px;
  margin: 0px 4px;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    color: ${(props) =>
    props.$active ? "var(--colorBrandForeground1)" : "var(--colorNeutralForeground2)"};
  }
`;

const StyledTabText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AccordionMenu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const Benchmark = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  color: var(--colorPaletteVioletBackground3);
  margin: -2px 4px 0px 0px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const BoldText = styled.p`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
`;

const ConditionalLogicPopupButton = styled(Button)`
  padding: 2px !important;
  text-transform: none !important;

  svg {
    margin-right: 4px;
  }
`;
