import { FC } from "react";
import styled from "styled-components";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import { TEntity } from 'types/workspace'
import {
  Divider
} from "@mui/material"
import store from "./store";
import empty from "assets/images/er-logo-square.png";
import EmployeesIcon from "./icons/EmployeesIcon";
import AdminsIcon from "./icons/AdminsIcon";
import LinkHubIcon from "./icons/LinkHubIcon";
import ActiveSeatsIcon from "./icons/ActiveSeatsIcon";
import CustomButton from "components/button/Button";


type WorkspaceCardProps = {
  cardContent: TEntity;
};

const WorkspaceCard: FC<WorkspaceCardProps> = (props) => {
  return (
    <Container>
      <TopSide>

        <LogoWrapper>
          <ImageWrap
            src={props.cardContent.logo_url ? props.cardContent.logo_url : empty}
            alt={"company-logo"} />

        </LogoWrapper>

        <EditButton
        id="ManageEntites_Cards_EditBtn"
        onClick={() => {
          store.currentEntityId = props.cardContent.id
          store.changeOpenPanelAddEditEntity(true)
        }}>
          <ModeEditSharpIcon
            sx={{ fontSize: 16 }}
            style={{ color: "var(--colorBrandForeground1)" }}
          />
        </EditButton>
      </TopSide>
      <InfoWrapper>
        <Title id="ManageEntites_Card_Title"  $disabled={props.cardContent.disabled}>{props.cardContent.name}</Title>

        {props.cardContent.disabled ? "" : <DividerWrap />}

        {props.cardContent.disabled ? <DisabledText>
          Your entity is being set up in the meantime.
        </DisabledText> : <LoginType>
          <EmployeeListText id="ManageEntites_Card_Subtitle">
            Employee list
          </EmployeeListText>

          {/* <ManageEmployees onClick={() => store.onClickEmployeeManagement(props.cardContent.id, props.cardContent.workspace_id)}>
            Manage employees <LaunchIcon sx={{ fontSize: 14, margin: '5px 5px 0 0px' }} />
          </ManageEmployees> */}


        </LoginType>}
        {props.cardContent.disabled ? "" : <><AdminCounter>
          <EmployeesIcon />
          <CountEmployees id="ManageEntites_Card_EmployeesCount">
            {props.cardContent.settings?.employee_count_total !== 0 ? `${props.cardContent.settings?.employee_count_total} employees` : "-"}
          </CountEmployees>
        </AdminCounter>
          <AdminCounter>
            <AdminsIcon />
            <CountEmployees id="ManageEntites_Card_EntityAdmin">
              {props.cardContent.settings?.admin_count_total} entity admin
            </CountEmployees>
          </AdminCounter>
          <AdminCounter>
            <LinkHubIconWrap id="ManageEntites_Card_LinkHub">
              <LinkHubIcon />
            </LinkHubIconWrap>
            <CountEmployees>
              Access Link Hub
            </CountEmployees>
          </AdminCounter>
        </>}


        <EntitySeats>
          <EntitySeatsLeft>
            <ActiveSeatsIcon />

            <ActiveSeats>
              <WorkspaceSeatsText id="ManageEntites_Card_ActiveSeats">
                Active seats: {props.cardContent.settings?.active_seat_count_total} / {props.cardContent.settings?.active_seat_count}
              </WorkspaceSeatsText>
              <WorkspaceSeatsText id="ManageEntites_Card_Features">
                Features: {props.cardContent.settings?.features_count_total} / {props.cardContent.settings?.features_count}
              </WorkspaceSeatsText>
            </ActiveSeats>
          </EntitySeatsLeft>

          <CustomButton id="ManageEntites_Card_UbgradeBtn">
            Upgrade
          </CustomButton>
        </EntitySeats>

      </InfoWrapper>
    </Container>
  );
};

const LinkHubIconWrap = styled.div`
  border-radius: 2px;
  border: 1px solid var(--colorBrandForeground1, #1477F8);
  padding: 1px;
`

const DividerWrap = styled(Divider)`
  margin: 10px 0 !important;
`

const Container = styled.div`
  width: 100%;
  padding: 40px;
  height:100%;
  background-color: var(--colorNeutralBackground1, #ffffff);
  border-radius: 15px;
`;

const EntitySeats = styled.div`
  display: flex;
  background-color: var(--colorPaletteBlueBackground3, #E4E9FF);
  border-radius: 10px;
  padding: 12px;
  justify-content: space-between;
  margin-top: 15px;
`;

const EntitySeatsLeft = styled.div`
  display: flex;
`;

const ActiveSeats = styled.div`
  margin-left: 5px;
`;

const WorkspaceSeatsText = styled.div`
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  display: flex;
  align-items: center;
`;


const TopSide = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;


const CountEmployees = styled.span`
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: 5px;
  line-height: 20px; /* 142.857% */
`;

const ImageWrap = styled.img`
  max-width: 100px;
  max-height: 100px;
`

const LogoWrapper = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--graySelectBoxBorder, #cdd3ec);
  border-radius: 8px;
`;

const InfoWrapper = styled.div`
  margin-top: 24px;
`;

const Title = styled.h1<{ $disabled: boolean }>`
  font-size: 24px;
  font-style: normal;
  line-height: 28px; /* 116.667% */
  font-family: Roboto;
  font-weight: 500;
  text-align: left;
  color: ${(props) => (props.$disabled ? "var(--colorNeutralForeground5, #959ba2)" : "var(--colorNeutralForeground1, #3e4450)")};
  margin: 0px;
`;

const LoginType = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 4px 0px 0px 0px;
`;

const DisabledText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  margin: 25px 0;
  align-items: center;
  line-height: 10px;
  text-align: left;
  color: var(--colorNeutralForeground5, #959ba2);
`;

const EmployeeListText = styled.span`
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  margin-right: 5px;
`;

const AdminCounter = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  margin-top: 10px;
  align-items: center;
  line-height: 10px;
  text-align: left;
  color: var(--colorNeutralForeground1, #3e4450);
`;

const EditButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: var(--colorPalleteLightBlue, #eeefff);
  margin-left: 58px;
  transition: background-color 0.3s;
  :hover {
    background-color: var(--colorNeutralBackground3, #d9dde7);
  }
`;


export default WorkspaceCard;
