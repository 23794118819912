import { FC } from "react";
import { TDefaultPagination } from "types/dashboard";
import { MenuItem, Pagination, Select, SelectChangeEvent } from "@mui/material";

import styled from "styled-components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type PaginationListProps = {
  list: string[];
  pagination: TDefaultPagination;
  changePagination: (filed: string, value: number) => void;
};

const PaginationList: FC<PaginationListProps> = (props) => {
  const returnBottomPaginationValue = () => {
    if (props.pagination.active_page === 1) return 1;
    return (props.pagination.active_page - 1) * props.pagination.count_per_page;
  };

  const returnTopPaginationValue = () => {
    if (props.pagination.active_page === props.pagination.total_pages)
      return props.pagination.total_count;
    if (props.pagination.active_page === 0) {
      if (props.pagination.count_per_page > props.pagination.total_count)
        return props.pagination.total_count;
      return props.pagination.count_per_page;
    }
    return props.pagination.active_page * props.pagination.count_per_page;
  };

  const changeCountPerPage = (value: number) => {
    props.changePagination("count_per_page", value);
    props.changePagination("active_page", 1);
  };

  return (
    <Container>
      <List>
        {props.list.map((item, index) => (
          <ListItem key={index}>{item}</ListItem>
        ))}

        {props.list.length === 0 && (
          <EmptyList>
            <EmptyListText>List is empty</EmptyListText>
          </EmptyList>
        )}
      </List>
      <Paginator>
        <PaginationGroup>
          <PaginationText>
            Viewing {returnBottomPaginationValue()} - {returnTopPaginationValue()} of{" "}
            {props.pagination.total_count}
          </PaginationText>

          <RowPerPage>
            <PaginationText>Rows per page</PaginationText>

            <StyledSelect
              id="count_per_page"
              value={props.pagination.count_per_page}
              onChange={(event: SelectChangeEvent<number>) =>
                changeCountPerPage(Number(event.target.value))
              }
              displayEmpty
              MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
              IconComponent={KeyboardArrowDownIcon}
            >
              {[10, 15, 25, 50].map((el) => (
                <StyledMenuItem key={el} value={el}>
                  {el}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </RowPerPage>
        </PaginationGroup>
        <Pagination
          variant="outlined"
          shape="rounded"
          page={props.pagination.active_page}
          count={props.pagination.total_pages}
          onChange={(_, page) => props.changePagination("active_page", page)}
        />
      </Paginator>
    </Container>
  );
};

export default PaginationList;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: var(--colorNeutralBackground1);
  margin-bottom: 12px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--colorNeutralForeground1);
`;

const Paginator = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .MuiButtonBase-root {
    margin: 0;
    width: 36px;
    height: 36px;
    border: 1px solid var(--colorNeutralForeground4);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--colorNeutralForeground2);
    background-color: var(--colorNeutralBackground1);
    border-radius: 0;
  }

  .Mui-selected {
    background-color: var(--colorPaletteGrayBackground1) !important;
  }

  .MuiToolbar-root {
    p {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      color: var(--colorNeutralForeground2);
    }
  }
`;

const PaginationText = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.73px;
  color: var(--colorNeutralForeground2);
  text-transform: uppercase;
`;

const EmptyList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
`;

const EmptyListText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const StyledSelect = styled(Select)`
  height: 34px;
  width: 80px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-left: 20px !important;

  svg {
    font-size: 16px;
  }

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px !important;
  font-weight: 400 !important;
`;

const PaginationGroup = styled.div`
  display: flex;
  align-items: center;
`;

const RowPerPage = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
`;
