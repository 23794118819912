import http from "../https";
import { createUrl } from "../../helpers/url";

export const useGetSurvey = (project_id: number) =>
  http.get(`/sm_survey/GetFilterSurveys?project_id=${project_id}`);

  
export const useGetSurveyDashboard = (project_id: number) => {
  const query: { project_id: string } = { project_id: project_id.toString() };
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/sm/sm_survey/GetForDashboard",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/sm_survey/GetForDashboard",
      query: query,
    });
    return http.get(url);
  }
}
