import { FC } from "react";
import { observer } from "mobx-react";
import { DASHBOARD_MENU, PROJECT_TYPES } from "constants/dashboard-menu";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import StorePermission from "components/workspaces-sidebar/StorePermission";

type MenuProps = {
  isSticky: boolean;
};

const Menu: FC<MenuProps> = observer((props) => {
  const hasFeature = (menu: string): boolean => {
    if (menu === "overview") {
      return StorePermission.features.dashboard_overview.hasFeature;
    } else if (menu === "responses") {
      return StorePermission.features.dashboard_responses.hasFeature;
    } else if (menu === "hotspots") {
      return StorePermission.features.dashboard_hotspot.hasFeature;
    } else if (menu === "trend") {
      return StorePermission.features.dashboard_trends.hasFeature;
    } else if (menu === "prioritize") {
      return StorePermission.features.dashboard_prioritize.hasFeature;
    }
    return true;
  };

  const isDisableMenu = (tab: string) =>
    Boolean(
      store.selectedProject &&
      store.selectedProject.Type === PROJECT_TYPES.oneOff &&
      tab === "trend"
    );

  const changeMenu = (menuItem: { label: string; value: string }) =>
    !isDisableMenu(menuItem.value) && store.setData(menuItem.value, "activeTab");

  return (
    <Container $isSticky={props.isSticky}>
      <MenuList>
        {DASHBOARD_MENU.map((menuItem, index, array) => {
          if (!hasFeature(menuItem.value)) {
            return;
          }
          return (
            <CustomWidthTooltip
              key={index}
              title={`This survey is a one-off survey. Trend results are only available for frequency and milestone surveys.`}
              placement="top"
              arrow
              disableHoverListener={!isDisableMenu(menuItem.value)}
            >
              <CustomMenuItem
                $isDisabled={isDisableMenu(menuItem.value)}
                $first={index === 0}
                $last={index === array.length - 1}
                $active={store.activeTab === menuItem.value}
                onClick={() => changeMenu(menuItem)}
              >
                {menuItem.label}
              </CustomMenuItem>
            </CustomWidthTooltip>
          );
        })}
      </MenuList>
    </Container>
  );
});

export default Menu;

const Container = styled.div<{ $isSticky: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  border-bottom: 1px solid var(--graySelectBoxBorder);
  transition: all 0.3s;
  z-index: 15;
`;

const MenuList = styled.div`
  display: flex;
  align-items: center;
`;

const CustomMenuItem = styled.span<{
  $isDisabled: boolean;
  $active?: boolean;
  $first?: boolean;
  $last?: boolean;
}>`
  margin: ${(props) =>
    props.$first ? `0px 12px 0px 0px` : props.$last ? `0px 0px 0px 12px` : `0px 12px`};
  padding: 12px 0px;
  cursor: ${(props) => (props.$isDisabled ? "not-allowed" : "pointer")};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: ${(props) =>
    props.$isDisabled
      ? "var(--colorNeutralForeground5, #959ba2)"
      : props.$active
        ? `var(--colorBrandForeground1)`
        : `var(--colorPaletteVioletBackground1)`};
  ${(props) => props.$active && `border-bottom: 4px solid var(--colorBrandForeground1)`};
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "center",
    background: "var(--colorShadowInverted2)",
  },
});
