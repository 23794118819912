import http from "api/https";

export const getSurveys = (
  project_id: number,
  search_value: string,
  order_by: string,
  order_type: string,
  tags_ids: number[],
  milestone_days: number[],
): Promise<any> => {
  const data = {
    project_id: project_id,
    search_value: search_value,
    order_by: order_by,
    order_type: order_type,
    tags_ids: tags_ids,
    milestone_days: milestone_days,
  }

  if(http.isAPIGO){
    return http.postGO("/sm/sm_survey/GetFilterSurveyspost", data);

  }else{
    return http.post("/sm_survey/GetFilterSurveyspost", data);

  }
};
