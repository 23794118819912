import http from "api/https";
import {
  SurveyLanguage,
} from "types/surveys";

export const saveInviteSurveyCommunication = (data: SurveyLanguage[]): Promise<any> => {
  if (http.isAPIGO) {
    return http.postGO('/sm/sm_survey/SaveInviteSurveyCommunication', data)
  } else {
    return http.post('/sm_survey/SaveInviteSurveyCommunication', data)
  }
};
