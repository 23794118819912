import http from "api/https";

export const copySurveyTemplates = (idSurvey: number, idComm: number, project_id: number, curentSurvey_id?: number): Promise<any> => {
  let data = {
    idSurvey: idSurvey,
    idComm: idComm,
    project_id: project_id,
    curentSurvey_id: curentSurvey_id === 0 ? null : curentSurvey_id
  }
  return http.post("/sm_survey/CopySurveyFromTemplate", data);
};
