import { makeAutoObservable } from "mobx";
import { getAttributesOptionsByAttirbuteId } from "api/go/useGetAttributesOptionsByAttirbuteId";
import { getUniqueValuesFromOrgUnitId } from "api/go/useGetUniqueValuesFromOrgUnitId";
import { getUniqueValuesFromManagersEmailEntityId } from "api/go/useGetUniqueValuesFromManagersEmailEntityId";
import { getUniqueValuesFromUnitBasedEmailEntityId } from "api/go/useGetUniqueValuesFromUnitBasedEmailEntityId";
import MainStore from "MainStore";
import { EmployeeAttribute, AttributeSegments } from "types/employee";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import editSegmentStore from "./EditSegment/store";
import { deleteSegments } from "api/go/useDeleteSegments";
import attributeStore from './store'

class Store {
  attribute: EmployeeAttribute = null;
  dataWithoutSearch: AttributeSegments[] = [];
  data: AttributeSegments[] = [];
  selected: number[] = [];
  searchedValue: string = "";
  resultSearchValue: string = "";
  editSegmentPanel: boolean = false;
  selectedRows: AttributeSegments[] = [];

  deleteValue: string = "";
  errorTextDelete: string = "";
  deleteSegmentsPopup: boolean = false;

  openEditSegment: number | null = null;
  valueEdit: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  clearEditSegmentValue = () => {
    this.openEditSegment = null;
    this.selected = [];
  }

  setOpenEditSegment = () => {
    if (this.selected.length === 0) return;
    const selectedRow = this.data.find((x) => x.id === this.selected[0]);
    editSegmentStore.setSegmentValue(this.attribute, selectedRow?.value);
    this.openEditSegment = this.selected[0];
  };

  changeEditSegmentPanel = (flag: boolean) => {
    this.editSegmentPanel = flag;
  };

  changeValue = (field: string, value: any) => {
    if (field === "deleteValue") {
      if (value !== "DELETE") {
        this.changeValue("errorTextDelete", "Please type in the exact letters");
      } else {
        this.changeValue("errorTextDelete", "");
      }
    }
    this[field] = value;
  };
  setDeleteRow = () => {
    if (this.selected.length === 0) return;
    this.selectedRows = this.data.filter((x) => this.selected.includes(x.id));
    this.changeDeleteSegmentsPopup(true);
  };

  setEditRow = () => {
    if (this.selected.length === 0) return;
    const selectedRow = this.data.find((x) => x.id === this.selected[0]);
    editSegmentStore.setSegmentValue(this.attribute, selectedRow.value)
    this.changeEditSegmentPanel(true);
  };

  setSegments = (data: AttributeSegments[]) => {
    this.data = data;
    this.dataWithoutSearch = data;
  };

  deleteClicked = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await deleteSegments(this.attribute.id, this.selectedRows.map(x => x.value));
      if (response.status === 201 || response.status === 200) {
        MainStore.setSnackbar("Segment successfully deleted");
        this.changeDeleteSegmentsPopup(false)
        this.loadSegments()
        attributeStore.loadData()
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Failed to delete", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  onSearchClicked = () => {
    this.resultSearchValue = this.searchedValue;
    this.searchedValue = "";
    this.data = this.dataWithoutSearch.filter((x) => {
      return x.value?.toLowerCase().includes(this.resultSearchValue.toLowerCase());
    });
  };

  changeDeleteSegmentsPopup = (open: boolean) => {
    this.deleteSegmentsPopup = open;
    this.deleteValue = "";
    this.errorTextDelete = "";
  };

  clearStore = () => {
    this.attribute = null;
    this.dataWithoutSearch = [];
    this.data = [];
    this.selected = [];
    this.searchedValue = "";
    this.resultSearchValue = "";
    this.editSegmentPanel = false;
    this.selectedRows = [];
    this.deleteValue = "";
    this.errorTextDelete = "";
    this.deleteSegmentsPopup = false;
  }

  async loadSegmentsFromOrgUnit(idUnit: number) {
    try {
      MainStore.changeLoader(true);
      const response = await getUniqueValuesFromOrgUnitId(idUnit);
      if ((response.status === 201 || response.status === 200) && response?.data !== undefined) {
        this.setSegments(response.data);
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  async loadSegmentsFromManagerBased() {
    try {
      MainStore.changeLoader(true);
      const response = await getUniqueValuesFromManagersEmailEntityId(StoreLayout.currentEntityId);
      if ((response.status === 201 || response.status === 200) && response?.data !== undefined) {
        this.setSegments(response.data);
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }
  async loadSegmentsFromUnitBasedEmails() {
    try {
      MainStore.changeLoader(true);
      const response = await getUniqueValuesFromUnitBasedEmailEntityId(StoreLayout.currentEntityId);
      if ((response.status === 201 || response.status === 200) && response?.data !== undefined) {
        this.setSegments(response.data);
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  async loadJustAttributeSegments(attrbuteId: number) {
    try {
      MainStore.changeLoader(true);
      const response = await getAttributesOptionsByAttirbuteId(attrbuteId);
      if ((response.status === 201 || response.status === 200) && response?.data !== undefined) {
        this.setSegments(response.data);
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

  setAttribute(attribute: EmployeeAttribute) {
    this.attribute = attribute;
  }

  loadSegments() {
    this.resultSearchValue = "";
    if (this.attribute === null) {
      return;
    }
    if(this.attribute.is_unit_heads_emails){
      this.loadSegmentsFromUnitBasedEmails();
    } else if (this.attribute.is_unit_based) {
      this.loadSegmentsFromOrgUnit(this.attribute.id * -1);
    } else if (this.attribute.id === -1) {
      this.loadSegmentsFromManagerBased();
    } else {
      this.loadJustAttributeSegments(this.attribute.id);
    }
  }
}

const store = new Store();
export default store;
