import { FC, Fragment } from "react";

import styled from "styled-components";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";

type CustomSwitchProps = {
    id?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    disabled?: boolean;
    defaultChecked?: boolean;
};

const CustomSwitch: FC<CustomSwitchProps> = (props) => {
    return (
        <StyledSwitch
            id={props.id && `${props.id}_switchInput`}
            onClick={(event) => {
                event.stopPropagation();
            }}
            onChange={props.onChange}
            checked={props.checked}
            $checked={props.checked}
            $disabled={props.disabled}
            slotProps={{
                track: {
                    children: (
                        <Fragment>
                            {props.checked && (
                                <StyledTypography id={props.id && props.id + "CustomSwitch_On"} $isOn>
                                    On
                                </StyledTypography>
                            )}
                            {!props.checked && (
                                <StyledTypography id={props.id && props.id + "CustomSwitch_On"}>
                                    Off
                                </StyledTypography>
                            )}
                        </Fragment>
                    ),
                },
            }}
            sx={{
                "--Switch-thumbSize": "16px",
                "--Switch-trackWidth": "48px",
                "--Switch-trackHeight": "22px",
                "--Switch-trackBackground": "var(--colorNeutralForeground5)",
            }}
            {...props}
        />
    );
};

export default CustomSwitch;

const StyledTypography = styled(Typography) <{ $isOn?: boolean }>`
  text-transform: uppercase !important;
  font-family: Roboto !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  color: var(--colorNeutralBackground1) !important;
  padding: ${(props) => (props.$isOn ? "0 8px" : "1px 0 0 22px")};
`;

const StyledSwitch = styled(Switch)<{ $checked: boolean, $disabled: boolean }>`
  input {
    position: static !important;
  }

  .MuiSwitch-track {
    background-color: ${(props) => {
      if (props.$disabled) {
         return "var(--colorBrandForeground2)";
      }
      if (props.$checked) {
         return "var(--colorBrandForeground1)";
      } 
      return "var(--colorNeutralForeground5)";
    }};

    > span {
        background-color: var(--colorNeutralBackground1);
    }
  }
`;
