import http from "api/https";
import { Access } from "types/templates";

export const SaveAccessesForTemplate = async (
  accesses: Access[],
  template_id: number,
) => {
  let data = {
    template_id: template_id,
    accesses: accesses
  }
  if(http.isAPIGO){
    return http.postGO("/template/replace_comm_accesses", data, );
  } else {
    return http.post("/templ_template_comms/SaveAccessesForTemplate", data, );
  }
};
