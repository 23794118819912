import MainStore from "MainStore";
import { makeAutoObservable, runInAction } from "mobx";
import { TImportHistoricSurveyResult } from "types/surveys";
import { importHistoricalSurvey } from "api/useImportHistoricalSurvey";
import { importHistoricalSurveyConfirm } from "api/useImportHistoricalSurveyConfirm";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { Step } from "constants/layout-types";
import useGetSurveyTemplate from "api/go/useGetExcelSurveyTemplate";
import { downloadFileFromAssets } from "utils/downloadFile";
import storeSurveyList from "../SurveyList/store";

class Store {
  projectId: number = 0;

  file: File = null;
  fileName: string | null = null;
  importCurrentStep: Step | null = null;
  showImportProgress: boolean = false;
  success: boolean = false;
  surveyParseResult: TImportHistoricSurveyResult = null;
  surveyName: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  doLoad = (projectId: number) => {
    this.projectId = projectId;
  };

  setFile = (file: File) => {
    this.file = file;
  };

  clearStore = () => {
    runInAction(() => {
      this.projectId = 0;
      this.file = null;
      this.fileName = null;
      this.importCurrentStep = null;
      this.showImportProgress = false;
      this.success = false;
      this.surveyParseResult = null;
      this.surveyName = "";
      StoreLayout.showImportDone = false;
    });
  };

  setExcelParseResult = (surveyParseResult: TImportHistoricSurveyResult, isConfirm = false) => {
    this.surveyParseResult = surveyParseResult;
    if (isConfirm) {
      this.importCurrentStep = Step.Success;
      StoreLayout.changeShowImportProgress(false);
      StoreLayout.changeCurrentAndTotalProgress(1);
      StoreLayout.changeImportDoneText("Upload complete!");
      StoreLayout.changeShowImportDone(true);
    } else {
      this.importCurrentStep = Step.ReviewChanges;
      StoreLayout.changeShowImportProgress(false);
      StoreLayout.changeCurrentAndTotalProgress(1);
      StoreLayout.changeImportDoneText("Upload complete!");
      StoreLayout.changeShowImportDone(true);
      this.surveyName = surveyParseResult.surveyName;
    }
  };

  parseExcel = async () => {
    this.importCurrentStep = Step.ProgressLoader;
    try {
      MainStore.changeLoader(true);
      StoreLayout.changeCurrentAndTotalProgress(0.25);
      StoreLayout.changeProgressAndStep("No changes can be made while importing historical survey");
      const response = await importHistoricalSurvey(this.projectId, this.file);
      StoreLayout.changeCurrentAndTotalProgress(0.5);
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.setExcelParseResult({
          surveyName: response.data ?? "Survey name",
        } as TImportHistoricSurveyResult);
      } else if (response.response?.status === 422) {
        MainStore.changeSnackbar(false);
        this.importCurrentStep = Step.Error;
        StoreLayout.changeCurrentAndTotalProgress(1);
        StoreLayout.changeShowImportDone(false);
        StoreLayout.changeShowImportProgress(false);

        this.surveyParseResult = {
          errors: response?.response?.data?.details?.map((x) => x.message) ?? [
            "Something went wrong",
          ],
        } as TImportHistoricSurveyResult;
      } else {
        throw new Error();
      }
    } catch (err) {
      StoreLayout.changeCurrentAndTotalProgress(1);
      StoreLayout.changeShowImportDone(false);
      MainStore.setSnackbar("Something went wrong", "error");
      this.importCurrentStep = null;
    } finally {
      MainStore.changeLoader(false);
    }
  };

  confirmAndImport = async () => {
    this.importCurrentStep = Step.ProgressLoader;
    try {
      MainStore.changeLoader(true);
      const response = await importHistoricalSurveyConfirm(this.projectId, this.file);
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.setExcelParseResult(response.data, true);
        await storeSurveyList.getSurveys(this.projectId);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
      this.importCurrentStep = null;
    } finally {
      MainStore.changeLoader(false);
    }
  };

  saveExcelStructure = async () => {
    downloadFileFromAssets("/assets/survey_template.xlsx", "survey_template.xlsx");
  };
}

const store = new Store();
export default store;
