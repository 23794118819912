export const OTHER_QUESTIN_TYPE = {
    MULTIPLE: "multiple",
    SINGLE: "single",
    YES_NO: "yes/no",
};

export const OPEN_ENDED_LOCAL_MENU = {
    ALL_RESPONSES: "all_responses",
    RESPONSES_BY_TOPICS: "responses_by_topics",
};

export const RESPONSE_TYPES = {
    MIXED: "mixed",
    NEUTRAL: "neutral",
    UNAVAILABLE_SEGMENT: "unavailable_segment",
    POSITIVE: "positive",
    NEGATIVE: "negative",
};

export const SENTIMENT_FILTERS_LIST = [
    {
        value: "all",
        label: "All sentiments",
    },
    {
        value: "positive",
        label: "Positive",
    },
    {
        value: "negative",
        label: "Negative",
    },
    {
        value: "mixed",
        label: "Mixed",
    },
    {
        value: "neutral",
        label: "Neutral",
    },
    {
        value: "unavailable",
        label: "Unavailable",
    },
];

export const SENTIMENT_FILTERS_LIST_VALUES = {
    all: "all",
    positive: "positive",
    negative: "negative",
    mixed: "mixed",
    neutral: "neutral",
    unavailable: "unavailable",
};

export const DEFAULT_PAGINATION_DATA = {
    total_count: 0,
    total_pages: 0,
    active_page: 1,
    count_per_page: 10,
};
