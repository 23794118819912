import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton } from "@mui/material";
import { Checkbox } from "@mui/material";
import { observer } from "mobx-react";
import styled from "styled-components";

type SelectProps = {
  list: number[];
  selected_list: number[];
  onChange: (id: number) => void;
}

const MultiSelectDay = observer((props: SelectProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MultiSelectWrapper>
      <IconButtonStyle
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <KeyboardArrowDownIconStyle />
      </IconButtonStyle>
      <MenuStyle
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: "350px",
            width: "20ch"
          }
        }}
      >
        {props.list.map((option, i) => {
          let checked = props.selected_list.filter(x => x === option).length !== 0;
          return (
            <MenuItem
              key={i}
              sx={{ m: 0, p: 0, pr: 1 }}
              onClick={() => props.onChange(option)}
            >
              <Checkbox size="small" checked={checked} />
              {option}
            </MenuItem>
          );
        })}
      </MenuStyle>
    </MultiSelectWrapper>
  );
});

export default MultiSelectDay;

const MultiSelectWrapper = styled.div`
  position: absolute;
  left: 8px;
  height: 8ch;
  width: 100%;
`;

const IconButtonStyle = styled(IconButton)`
  position: relative;
  top: 1px;
  left: -3px;
  width: 100% !important;
  height: 100% !important;

  &:hover {
    background-color: transparent !important;
  }

  .MuiTouchRipple-root {
    position: unset;
  }
`;

const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: 23px;
  left: 3ch;
  width: 20px !important;
  height: 20px !important;

  &:hover {
    background-color: transparent;
  }
`;

const MenuStyle = styled(Menu)`
  .MuiMenu-paper {

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--colorGrayForeground6);
      border-radius: 20px;
      border: 3px solid var(--colorGrayForeground6);
    }
  }
`;
