import { FC } from "react";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import styled from "styled-components";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import RichTextWithTabsAll from "../../RichTextWithTabs";
import CustomSwitch from "components/switch/Switch";
import CustomButton from "components/button/Button";
import PreviewIcon from "../../PreviewIcon";
import Lookup from "./LookUp";
import store from "./../../store";

type FirstTabProps = {};

const FirstTab: FC<FirstTabProps> = observer(() => {
  const isSendReportVerification = store.AllInfo && store.AllInfo.is_send_report;

  return (
    <Wrapped>
      <Header id="Template_Communication_Report_Header_Label">Report</Header>
      <SwitchDiv>
        <CustomSwitch
          checked={store.AllInfo ? store.AllInfo.is_send_report : false}
          onChange={(e) =>
            store.changeReportIsSendReport(store.AllInfo ? !store.AllInfo.is_send_report : false)
          }
        />
        <SwitchLabel id="Template_Communication_Report_Header_SwitchLabel">
          Send a results report via email to:
        </SwitchLabel>
      </SwitchDiv>

      {isSendReportVerification && (
        <>
          <SubHeader id="Template_Communication_Report_SendAfter_Label">
            SEND AFTER SURVEY END DATE
          </SubHeader>

          <SendAfter>
            <LookUpWrapper>
              <Lookup
                id="Template_Communication_Report_Reminder_Days_Input"
                data={store.ReminderDays}
                value={store.AllInfo ? store.AllInfo.reminder_days_id : 0}
                errorText=""
                error={false}
                onChange={(e) => store.changeReportDays(Number(e.target.value))}
              />
            </LookUpWrapper>
            <TimeSend>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimeField"]}>
                  <WrappedTimeField
                    value={
                      store.AllInfo?.time_send_report && dayjs(store.AllInfo.time_send_report)
                    }
                    closeOnSelect={false}
                    slotProps={{
                      textField: {
                        placeholder: "Select time",
                      },
                    }}
                    onChange={(e) => store.changeReportTime(e)}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <TimeZone id="Template_Communication_Report_Reminder_Timezone">
                Local timezone: UTC {store.timezone_message}
              </TimeZone>
            </TimeSend>
          </SendAfter>

          <SubHeader id="Template_Communication_Report_Reminder_Email_Subject_Label">
            EMAIL SUBJECT
          </SubHeader>

          <RichTextWithTabsAll
            onlyText
            data={store.ReportData}
            width={100}
            changeValue={store.changeReportData}
            field="subject_email"
            variantTestId="report"
            boxId="Template_Communication_Report_RichText"
            langNameId="Template_Communication_Schedule_Report_Tab_Label"
            editorId="Template_Communication_Schedule_Report_Input"
          />

          <SubHeader id="Template_Communication_Report_Reminder_Email_Content_Label">
            EMAIL CONTENT
          </SubHeader>

          <RichTextWithTabsAll
            data={store.ReportData}
            width={200}
            changeValue={store.changeReportData}
            field="body_email"
            variantTestId="report"
            boxId="Template_Communication_Report_RichText"
            langNameId="Template_Communication_Schedule_Report_Tab_Label"
            editorId="Template_Communication_Schedule_Report_Input"
          />
        </>
      )}

      <BottomWrapper>
        <ButtonWrapper>
          <StyledNextButton
            id="Template_Communication_Report_Reminder_Save_Button"
            variant="contained"
            onClick={() => store.SaveReportData()}
          >
            Save changes
          </StyledNextButton>
          {isSendReportVerification && (
            <StyledCancelButton
              id="Template_Communication_Report_Reminder_Cancel_Button"
              variant="outlined"
              disabled={!store.changedReportTab}
              onClick={() => store.onCancelReportTab()}
            >
              Cancel
            </StyledCancelButton>
          )}
        </ButtonWrapper>
        {isSendReportVerification && (
          <ButtonWrapper>
            <LinkPreview
              id="Template_Communication_Report_Reminder_PreviewEmail_Button"
              onClick={() => store.LoadPreviewMessage(null, true)}
            >
              <PreviewIcon />
              <LinkPreviewText>Preview email</LinkPreviewText>
            </LinkPreview>
          </ButtonWrapper>
        )}
      </BottomWrapper>
    </Wrapped>
  );
});

export default FirstTab;

const Wrapped = styled.div`
  margin: 10px 30px;
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: var(--colorNeutralForeground1);
`;

const BottomWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LinkPreviewText = styled.span`
  margin-left: 3px;
`;

const LinkPreview = styled.a`
  margin-left: 5px;
  color: var(--colorBrandForeground1);
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const LookUpWrapper = styled.div``;

const SwitchDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 30px;
`;
const SendAfter = styled.div`
  display: flex;
  align-items: start;
  gap: 10px;

  .MuiStack-root {
    padding-top: 1px;
  }
`;

const TimeSend = styled.div``;

const WrappedTimeField = styled(TimePicker)`
  .MuiOutlinedInput-root {
    margin-bottom: 5px;
  }
  input {
    padding: 11px;
  }
  p {
    margin: 0;
  }
`;

const TimeZone = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: var(--colorNeutralForeground5);
`;

const StyledCancelButton = styled(CustomButton)``;

const StyledNextButton = styled(CustomButton)``;

const SubHeader = styled.div`
  margin-bottom: 10px;
  margin-top: 30px;
  color: var(--colorNeutralForeground2);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
`;

const SwitchLabel = styled.p`
  margin-left: 6px;
`;
