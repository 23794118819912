import { ReactElement, useState } from "react";
import { MOCK_ACCOUNT_SETTINGS_DATA } from "../../__mocks__/account-settings";

import styled from "styled-components";
import WorkspacesCard from "./components/WorkspacesCard";
import AccountInfoCard from "./components/AccountInfoCard";
import ModalChnagePassword from "./components/ModalChangePassword";

const AccountSettingsScreen = (): ReactElement => {
  const [isOpenResetPwdPopup, setIsOpenResetPwdPopup] =
    useState<boolean>(false);

  const handleVisibleResetPwdPopup = (state: boolean) =>
    setIsOpenResetPwdPopup(state);

  return (
    <Container>
      <Content>
        <Header>Account settings</Header>
        <InfoWrapper>
          <AccountInfoCard
            handleVisibleResetPwdPopup={handleVisibleResetPwdPopup}
          />
          <AccountsList>
            {/* {MOCK_ACCOUNT_SETTINGS_DATA.accounts.map((account) => (
              <WorkspacesCard account={account} key={account.id} />
            ))} */}
          </AccountsList>
        </InfoWrapper>
      </Content>

      <ModalChnagePassword
        open={isOpenResetPwdPopup}
        hide={handleVisibleResetPwdPopup}
      />
    </Container>
  );
};

export default AccountSettingsScreen;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 64px);
  background-color: var(--colorNeutralForegroundInverted1);
`;

const Content = styled.div`
  width: 100%;
  max-width: 1040px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 72px 40px 40px 40px;
`;

const Header = styled.h1`
  width: 100%;
  text-align: start;
  font-family: Roboto;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  margin: 0px 0px 48px 0px;
  color: var(--colorNeutralBackgroundInverted1);
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const AccountsList = styled.div`
  width: 100%;
  max-width: 398px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
