import { FC } from "react";
import { observer } from "mobx-react";
import { useSearchParams } from "react-router-dom";
import { SURVEY_STATUS_DASHBOARD } from "constants/survey-status";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { DASHBOARD_MENU_VALUES, EMPTY_STRING_FILTERS } from "constants/dashboard-menu";

import store from "./store";
import styled from "styled-components";
import ProjectSelector from "./ProjectSelector";
import ViewAsComponent from "./ViewAs";

type HeaderProps = {};

const Header: FC<HeaderProps> = observer(() => {
  const [queryParams, setQueryParams] = useSearchParams();

  const changeSurvey = (e: SelectChangeEvent<string>) => {
    store.setData(undefined, "overviewBenchmark");
    store.setData(false, "isSurveyHaveResponseCount");
    const stringFilters = JSON.stringify(EMPTY_STRING_FILTERS);
    localStorage.setItem("dashboard_filters", stringFilters);
    store.setData(EMPTY_STRING_FILTERS, "filters");
    const selectSurvey = store.surveyList.filter((el) => el.value === e.target.value)[0];
    store.setData(
      {
        value: e.target.value,
        label: selectSurvey.label,
        status: selectSurvey.status,
        threshold: selectSurvey.threshold,
        isFirst: selectSurvey.isFirst,
      },
      "selectedSurvey"
    );
    localStorage.setItem(
      "dashboardActiveSurvey",
      JSON.stringify({
        projectName: store.selectedProject.name,
        surveyName: store.selectedSurvey.label,
        status: store.selectedSurvey.status,
      })
    );
    setQueryParams({ project_id: queryParams.get("project_id"), survey_id: e.target.value });
  };

  return (
    <Container>
      <Group>
        <ProjectSelector />
        {store.activeTab !== DASHBOARD_MENU_VALUES.trends && (
          <StyledSelect
            id="select_survey"
            value={store.selectedSurvey?.value ? store.selectedSurvey?.value : 0}
            onChange={changeSurvey}
            placeholder="Select Survey"
            displayEmpty
          >
            <MenuItem disabled value="">
              <em>Select Survey</em>
            </MenuItem>
            {store.surveyList.map((el) => (
              <MenuItem key={el.value} value={el.value}>
                {el.label}
              </MenuItem>
            ))}
          </StyledSelect>
        )}
        {store.activeTab !== DASHBOARD_MENU_VALUES.trends && (
          <StatusLabel status={store.selectedSurvey?.status}>
            {store.selectedSurvey?.status || "None"}
          </StatusLabel>
        )}
      </Group>
      <Group>
        <ViewAsComponent />
      </Group>
    </Container>
  );
});

export default Header;

const getBgcColor = (status: string) => {
  switch (status) {
    case SURVEY_STATUS_DASHBOARD.None:
      return "var(--colorNeutralBackground3)";
    case SURVEY_STATUS_DASHBOARD.Running:
      return "var(--colorPaletteGreenBackground2)";
    case SURVEY_STATUS_DASHBOARD.Historical:
      return "var(--colorPaletteYellowForeground1)";
    default:
      return "var(--colorPaletteGreenBackground4)";
  }
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 15;
`;

const Group = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: var(--colorNeutralForeground1);
    font-size: 16px;
    margin-bottom: 2px;
  }
`;

const StatusLabel = styled.span<{ status: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--colorNeutralForeground1);
  background-color: ${(status) => getBgcColor(status.status)};
  margin-left: 8px;
  border-radius: 2px;
  height: 20px;
`;

const StyledSelect = styled(Select)`
  height: 34px;
  width: 140px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-family: Roboto, sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-left: 24px;

  em {
    font-family: Roboto, sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }
`;
