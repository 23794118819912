import GroupIcon from '@mui/icons-material/Group';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import SettingsIcon from '@mui/icons-material/Settings';

export const PermissionIcons = [
  { code: "workspaceManagement", icon: <AssignmentIcon style={{ fontSize: 20, color: "#6E31FF" }} /> },
  { code: "entityManagement", icon: <GroupIcon style={{ fontSize: 20, color: "#6E31FF" }} /> },
  { code: "editEntity", icon: <AssignmentIcon style={{ fontSize: 20 }} /> },
  { code: "viewEntity", icon: <MarkunreadIcon style={{ fontSize: 20 }} /> },
  { code: "employeeManagement", icon: <GroupIcon style={{ fontSize: 20, color: "#6E31FF" }} /> },
  { code: "editEmployee", icon: <MarkunreadIcon style={{ fontSize: 20 }} /> },
  { code: "viewEmployee", icon: <DataUsageIcon style={{ fontSize: 20 }} /> },
  { code: "surveyFeatures", icon: <GroupIcon style={{ fontSize: 20, color: "#6E31FF" }} /> },
  { code: "createAndEditSurvey", icon: <MarkunreadIcon style={{ fontSize: 20 }} /> },
  { code: "createAndEditInvite", icon: <MarkunreadIcon style={{ fontSize: 20 }} /> },
  { code: "autoAddViewDashboard", icon: <SettingsIcon style={{ fontSize: 20 }} /> },
  { code: "autoAddViewCompareSegments", icon: <SettingsIcon style={{ fontSize: 20 }} /> },
  { code: "reviewFeatures", icon: <GroupIcon style={{ fontSize: 20, color: "#6E31FF" }} /> },
  { code: "createAndEditSurveys", icon: <ViewModuleIcon style={{ fontSize: 20 }} /> },
  { code: "createAndEditInviteReminders", icon: <ViewModuleIcon style={{ fontSize: 20 }} /> },
  { code: "autoAddedToViewDashboard", icon: <DataUsageIcon style={{ fontSize: 20 }} /> },
]
