import { FC } from "react";

import styled from "styled-components";



type ResetPasswordMessageScreenProps = {
    title: string;
    messages: string;
    btnContent: React.ComponentType;
};

const ResetPasswordMessageScreen: FC<ResetPasswordMessageScreenProps> = (props: ResetPasswordMessageScreenProps) => {
    return (
        <>
            <ForgotPasswordPageWrapper>
                <PageContainer>
                    <CoverImageWrapper>
                        <img src="/assets/reset-link.png" alt="forgot-password" />
                    </CoverImageWrapper>
                    <FormContainer>
                        <FormContainerContent>
                            <Header className="header">{props.title}</Header>
                            <ButtonWrapper className="action">
                                <props.btnContent />
                            </ButtonWrapper>
                            <Desc>
                                {props.messages}
                            </Desc>

                            <BottomLogo className="bottom-logo">
                                <img src="/assets/er-logo-light.png" alt="EngageRocket-logo" />
                            </BottomLogo>
                        </FormContainerContent>
                    </FormContainer>
                </PageContainer>
            </ForgotPasswordPageWrapper>
        </>
    );
};

export default ResetPasswordMessageScreen;

const ForgotPasswordPageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  padding: 20px;
`;

const PageContainer = styled.div`
  width: 100%;
  height: 630px;
  max-width: 1080px;
  display: flex;
  background-color: var(--colorNeutralBackground1);
`;

const CoverImageWrapper = styled.div`
  width: 100%;
  max-width: 485px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 30px 20px 20px 0px;
  img {
    width: 276px;
    height: 260px;
  }
`;

const FormContainer = styled.div`
  padding: 150px 80px 48px 80px;
  width: 100%;
  max-width: 595px;
  position: relative;
`;

const FormContainerContent = styled.div`
  width: 100%;
  max-width: 340px;
`;

const Header = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  color: var(--colorNeutralForeground1);
  margin: 55px 0px 25px 0px;
`;

const Desc = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--colorNeutralForeground2);
`;

const DescResult = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--colorNeutralForeground2);
`;

const BottomLogo = styled.div`
  position: absolute;
  bottom: 47px;
  margin-top: 82px;
  img {
    max-width: 184px;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`;


const Warning = styled.div`
  color: var(--colorPaletteRedForeground2);
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 27px;
`;