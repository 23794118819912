import { FC } from "react";
import { SelectWorkspaceScreen } from "../../features/SelectWorkspace";

type SelectWorkspacePageProps = {};

const SelectWorkspacePage: FC<SelectWorkspacePageProps> = () => {
  return <SelectWorkspaceScreen />;
};

export default SelectWorkspacePage;
