import { Serializer, JsonObjectProperty } from "survey-core"
import { METRIC_PROPERTY_NAME, QUESTION_IDENTIFIER_PROPERTY_NAME, REQUIRED_IF_ANSWER_PROPERTY_NAME, REQUIRED_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME, VISIBLE_IF_ANSWER_PROPERTY_NAME, VISIBLE_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME } from "./surveyJsConstants"
import { QUESTION_SETTING_CATEGORY, QUESTION_TITLE_DISPLAY_NAME_VALUE } from "./surveyJsConstants"
import { QuestionType } from "../sharedTypes"
import { surveyLocalization } from "survey-core"
import config from "../config"
import getCustomConditionProperties from "../getCustomConditionProperties"

export const customVisibleIfProperties = getCustomConditionProperties(
  {
    name: VISIBLE_IF_ANSWER_PROPERTY_NAME,
    displayName: "Question is visible based on answer from another question",
  },
  {
    name: VISIBLE_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME,
    displayName: "Question is visible based on employee segment",
  },
  "visibleIf",
)

export const customRequiredIfProperties = getCustomConditionProperties(
  {
    name: REQUIRED_IF_ANSWER_PROPERTY_NAME,
    displayName: "Question is required based on answer from another question",
  },
  {
    name: REQUIRED_IF_EMPLOYEE_SEGMENT_PROPERTY_NAME,
    displayName: "Question is required based on employee segment",
  },
  "requiredIf",
)

const setCategory = (property: JsonObjectProperty): void => {
  property.category = QUESTION_SETTING_CATEGORY
  property.categoryIndex = 1
}

Serializer.findProperties(QuestionType.rating, [
  "description",
  "isRequired",
  "showCommentArea",
  "title",
]).forEach(setCategory)

Serializer.findProperty(QuestionType.rating, "showCommentArea").visible = config.surveyJsEnableQuestionComments
Serializer.findProperty(QuestionType.rating, "title").displayName = QUESTION_TITLE_DISPLAY_NAME_VALUE
Serializer.findProperty('survey', 'widthMode').defaultValue = 'responsive';
Serializer.findProperty('survey', 'logoHeight').defaultValue = '80px';

Serializer.addProperties(QuestionType.rating, [
  {
    name: `${METRIC_PROPERTY_NAME}:shorttext`,
    category: "general",
  },
  {
    name: `${QUESTION_IDENTIFIER_PROPERTY_NAME}:shorttext`,
    category: "general",
  },
  ...customVisibleIfProperties,
  ...customRequiredIfProperties,
])

Serializer.findProperties(QuestionType.radiogroup, [
  "description",
  "isRequired",
  "title",
]).forEach(setCategory)

Serializer.findProperty(QuestionType.radiogroup, "title").displayName = QUESTION_TITLE_DISPLAY_NAME_VALUE

Serializer.addProperties(QuestionType.radiogroup, [
  {
    name: `${METRIC_PROPERTY_NAME}:shorttext`,
    category: "general",
  },
  {
    name: `${QUESTION_IDENTIFIER_PROPERTY_NAME}:shorttext`,
    category: "general",
  },
  ...customVisibleIfProperties,
  ...customRequiredIfProperties,
])

Serializer.findProperty(QuestionType.radiogroup, "choicesFromQuestion").visibleIndex = 6

Serializer.findProperties(QuestionType.boolean, [
  "description",
  "isRequired",
  "showCommentArea",
  "title",
]).forEach(setCategory)

Serializer.addProperties(QuestionType.boolean, [
  {
    name: `${METRIC_PROPERTY_NAME}:shorttext`,
    category: "general",
  },
  {
    name: `${QUESTION_IDENTIFIER_PROPERTY_NAME}:shorttext`,
    category: "general",
  },
  ...customVisibleIfProperties,
  ...customRequiredIfProperties,
])

Serializer.findProperty(QuestionType.boolean, "showCommentArea").visible = config.surveyJsEnableQuestionComments
Serializer.findProperty(QuestionType.boolean, "title").displayName = QUESTION_TITLE_DISPLAY_NAME_VALUE

Serializer.findProperties(QuestionType.checkbox, [
  "description",
  "isRequired",
  "showCommentArea",
  "title",
]).forEach(setCategory)

Serializer.findProperty(QuestionType.checkbox, "title").displayName = QUESTION_TITLE_DISPLAY_NAME_VALUE
Serializer.findProperty(QuestionType.checkbox, "otherText").visibleIf = (): boolean => true
Serializer.findProperty(QuestionType.checkbox, "otherText").visibleIndex = 8
Serializer.findProperty(QuestionType.checkbox, "otherText").visible = config.surveyJsEnableMCQShowOthers
Serializer.findProperty(QuestionType.checkbox, "showOtherItem").visible = config.surveyJsEnableMCQShowOthers
Serializer.findProperty(QuestionType.checkbox, "choicesFromQuestion").visibleIndex = 9

Serializer.addProperties(QuestionType.checkbox, [
  {
    name: `${METRIC_PROPERTY_NAME}:shorttext`,
    category: "general",
  },
  {
    name: `${QUESTION_IDENTIFIER_PROPERTY_NAME}:shorttext`,
    category: "general",
  },
  ...customVisibleIfProperties,
  ...customRequiredIfProperties,
])

Serializer.findProperties(QuestionType.comment, [
  "description",
  "isRequired",
  "title",
]).forEach(setCategory)

Serializer.findProperty(QuestionType.comment, "title").displayName = QUESTION_TITLE_DISPLAY_NAME_VALUE

Serializer.addProperties(QuestionType.comment, [
  {
    name: `${METRIC_PROPERTY_NAME}:shorttext`,
    category: "general",
  },
  {
    name: `${QUESTION_IDENTIFIER_PROPERTY_NAME}:shorttext`,
    category: "general",
  },
  ...customVisibleIfProperties,
  ...customRequiredIfProperties,
])

Serializer.findProperties(QuestionType.enps, [
  "description",
  "isRequired",
  "showCommentArea",
  "title",
]).forEach(setCategory)

Serializer.addProperties(QuestionType.enps, [
  ...customVisibleIfProperties,
  ...customRequiredIfProperties,
])

Serializer.findProperty("question", "title").onPropertyEditorUpdate = (): void => void 0
