import { makeAutoObservable } from "mobx";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import MainStore from "MainStore";
import { typeQuestionary, typeQuestion, templ_template_comms, sm_question } from "types/templates";
import { getTemplatesFilter } from "api/go/useGetTemplatesFilter";
import { getQuestionDimention } from "api/go/useGetQuestionDimention";
import { getQuestionLibrary } from "api/go/useGetQuestionLibrary";
import { getQuestionsFromTemplateId } from "api/go/useGetQuestionsFromTemplateId";
import { Question } from "types/templates";
import { getQuestionnaireTypesForEntity } from "api/go/getQuestionnaireTypesForEntity";

class Store {
  project_id: number = 0;
  templatesData: templ_template_comms[] = [];
  sortField = "";
  sortType = "";
  searchedValue: string = "";
  resultSearchValue: string = "";

  templateType: "all" | "communication" | "questionnaire" = "all";
  fromTemplate: boolean = false;

  selectedType: typeQuestionary = null;

  navUrl = "engageRocket";
  qUrl = "";
  isTemplateOpen = false;
  isLibraryOpen = false;
  expandedNav = "";
  groupUrl = "";

  survey_text: string = "";
  survey_title: string = "";

  typesQuestionary: typeQuestionary[] = [];
  typeDimensions: typeQuestion[] = [];

  selectedQuestionnaireId: number = null;
  selectedCommunication: number = null;

  selectedQuestionsId: sm_question[] = [];
  questionsData: sm_question[] = [];

  selectedTemplateQuestions: sm_question[] = [];
  templateQuestions: sm_question[] = [];
  allTemplateQuestions: sm_question[] = [];
  isQuestionFromTemplateForm: boolean = false;
  dimensionTemplateQuestionId: typeQuestion = null;
  addedQuestionNames: number[] = [];

  questionType: number;

  changeSort(field: string, type: string) {
    this.sortField = field;
    this.sortType = type;
    this.getTemplatesFilter();
  }

  onOpenTemplate() {
    this.isTemplateOpen = !this.isTemplateOpen;
  }

  onOpenLibrary() {
    this.isLibraryOpen = !this.isLibraryOpen;
  }

  loadtypeQuestioonary = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getQuestionnaireTypesForEntity(StoreLayout.currentEntityId);
      if ((response.status === 201 || response.status === 200) && response?.data !== undefined) {
        this.settypeQuestionary(response.data);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  clearStore = () => {
    this.project_id = 0;
    this.templatesData = [];
    this.sortField = "";
    this.sortType = "";
    this.searchedValue = "";
    this.resultSearchValue = "";
    this.templateType = "all";
    this.selectedType = null;
    this.navUrl = "engageRocket";
    this.qUrl = "";
    this.isTemplateOpen = false;
    this.isLibraryOpen = false;
    this.expandedNav = "";
    this.groupUrl = "";
    this.survey_text = "";
    this.survey_title = "";
    this.typesQuestionary = [];
    this.typeDimensions = [];
    this.selectedQuestionnaireId = null;
    this.selectedCommunication = null;
    this.selectedQuestionsId = [];
    this.questionsData = [];
    this.selectedTemplateQuestions = [];
    this.templateQuestions = [];
    this.allTemplateQuestions = [];
    this.isQuestionFromTemplateForm = false;
    this.dimensionTemplateQuestionId = null;
    this.questionType = null;
    this.fromTemplate = false;
  };

  loadDimensionQuestions = () => {
    MainStore.changeLoader(true);
    getQuestionDimention().then((x) => {
      MainStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.setTypeDimension(x.data);
      }
    });
  };

  selectErTemplate = (id: number) => {
    this.selectedQuestionnaireId = id;
    this.getQuestionsFromTemplateId();
  };

  getQuestionsFromTemplateId = async () => {
    try {
      MainStore.changeLoader(true);
      if (!this.selectedQuestionnaireId) {
        throw new Error();
      }
      const response = await getQuestionsFromTemplateId(this.selectedQuestionnaireId);
      if ((response.status === 201 || response.status === 200) && response?.data !== undefined) {
        this.changeValue("allTemplateQuestions", response.data);
        this.changeValue("templateQuestions", response.data);
        this.changeValue("isQuestionFromTemplateForm", true);
      } else {
        throw new Error();
      }
    } catch {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  onSetQUrl = (nav: string) => {
    this.qUrl = nav;
  };

  onClickQuestionBtn = (nav: string, group: string, id: number) => {
    this.changeValue("allTemplateQuestions", []);
    this.changeValue("isQuestionFromTemplateForm", false);

    this.selectedType = null;
    this.navUrl = nav;
    this.groupUrl = group;

    this.selectedQuestionnaireId = null;
    this.selectedCommunication = null;
    this.resultSearchValue = "";
    if (nav === "question") {
      this.changeQuestionType(id);
    } else {
      this.getTemplatesFilter();
    }
  };

  onClickBtn = (nav: string, group: string) => {
    this.setTemplatesData([]);
    this.changeValue("allTemplateQuestions", []);
    this.changeValue("isQuestionFromTemplateForm", false);
    this.selectedType = null;
    this.navUrl = nav;
    this.groupUrl = group;

    this.selectedQuestionnaireId = null;
    this.selectedCommunication = null;
    this.resultSearchValue = "";
    if (nav === "question") {
      this.changeQuestionType(0);
    } else {
      this.getTemplatesFilter();
    }
  };

  settypeQuestionary = (data: typeQuestionary[]) => {
    this.typesQuestionary = data;
  };

  setTypeDimension = (data: typeQuestion[]) => {
    this.typeDimensions = data;
  };

  setProjectId = (id: number) => {
    this.project_id = id;
  };

  setAddedQuestionJsons = (questions: Question[]) => {
    this.addedQuestionNames = questions.map((x) => {
      return Number(x.name.split("^")[0]);
    });
  };

  keyPress = (e: any) => {
    if (e.keyCode == 13) {
      this.onSearchClicked();
    }
  };

  changeTemplateType = (type: typeQuestionary) => {
    this.selectedType = type;
    this.getTemplatesFilter();
  };

  changeQuestionType = (id: number) => {
    this.questionType = id;
    this.getQuestionsFIltered();
  };

  changeQuestionDimensionFilter = (type: typeQuestion) => {
    this.dimensionTemplateQuestionId = type;
    if (type === null) {
      return (this.templateQuestions = this.allTemplateQuestions);
    }
    this.templateQuestions = this.allTemplateQuestions.filter((x) => x.dimension_id === type.id);
  };

  getTemplatesFilter = () => {
    let nav = this.navUrl === null || this.navUrl === "" ? "engageRocket" : this.navUrl;
    let group = this.templateType;
    MainStore.changeLoader(true);
    getTemplatesFilter(
      nav,
      group,
      StoreLayout.currentEntityId,
      this.resultSearchValue,
      this.sortField,
      this.sortType,
      this.selectedType?.id
    ).then((x) => {
      MainStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.setTemplatesData(x.data);
      }
    });
  };

  getQuestionsFIltered = () => {
    MainStore.changeLoader(true);
    getQuestionLibrary(this.questionType).then((x) => {
      MainStore.changeLoader(false);
      if (x?.data !== undefined) {
        this.setQuestionData(x.data);
      }
    });
  };

  setCheckQuestion = (id: number) => {
    let questions = this.selectedQuestionsId;
    let index = questions.findIndex((x) => x.id === id);
    if (index > -1) {
      // only splice array when item is found
      questions.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      let new_ques = this.questionsData.filter((x) => x.id == id);
      questions = [...new_ques, ...questions];
    }
    this.selectedQuestionsId = questions;
  };

  setCheckTemplateQuestion = (id: number) => {
    let questions = this.selectedTemplateQuestions;
    let index = questions.findIndex((x) => x.id === id);
    if (index > -1) {
      // only splice array when item is found
      questions.splice(index, 1); // 2nd parameter means remove one item only
    } else {
      let new_ques = this.templateQuestions.filter((x) => x.id === id);
      questions = [...new_ques, ...questions];
    }
    this.selectedTemplateQuestions = questions;
  };

  countCheckedCheckboxes = () => {
    return this.selectedTemplateQuestions.filter((z) => z.id).length;
  };

  onSearchClicked = () => {
    this.resultSearchValue = this.searchedValue;
    this.searchedValue = "";
    this.getTemplatesFilter();
  };

  setTemplatesData = (data: templ_template_comms[]) => {
    this.templatesData = data;
  };

  setQuestionData = (data: sm_question[]) => {
    this.questionsData = data;
  };

  selectAllQuesions = () => {
    this.selectedQuestionsId = this.questionsData.filter(
      (x) => !this.addedQuestionNames.includes(x.id)
    );
  };

  deselectAllQuestions = () => {
    this.selectedQuestionsId = [];
  };

  selectAllTemplateQuesions = () => {
    this.selectedTemplateQuestions = this.templateQuestions.filter(
      (x) => !this.addedQuestionNames.includes(x.id)
    );
  };

  deselectAllTemplateQuestions = () => {
    this.selectedTemplateQuestions = [];
  };

  setChecked = (type: string, id: number) => {
    if (type == "Communication") {
      if (this.selectedCommunication != id) {
        this.selectedCommunication = id;
      } else {
        this.selectedCommunication = null;
      }
    } else {
      if (this.selectedQuestionnaireId != id) {
        this.selectedQuestionnaireId = id;
      } else {
        this.selectedQuestionnaireId = null;
      }
    }
  };

  changeValue = (name: string, value: any) => {
    this[name] = value;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

const store = new Store();
export default store;
