const BackSpaceIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="12" fill="#959BA2" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 11.25H8.8725L13.065 7.0575L12 6L6 12L12 18L13.0575 16.9425L8.8725 12.75H18V11.25Z"
        fill="#F5F6FA"
      />
    </svg>
  );
};

export default BackSpaceIcon;
