import { FC, useState, useRef, ChangeEvent } from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react";
import styled from "styled-components";
import ImageIcon from "@mui/icons-material/Image";

import { DeleteIcon } from "features/Employees/AttributeList/components/DeleteIcon";
import RichTextWithTabsAll from "../../RichTextWithTabs";
import empty from "assets/images/er-logo-square.png";
import CustomButton from "components/button/Button";
import { FolderImageIcon } from "./FolderImageIcon";
import store from "./../../store";

type FirstTabProps = {};

const FirstTab: FC<FirstTabProps> = observer((props) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [errorFileText, seterrorFileText] = useState<string>("");
  const [errorWidthText, seterrorWidthText] = useState<string>("");
  const [errorHeightText, seterrorHeightText] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      store.changeFile(file);
      if (file !== undefined) {
        const blob = new Blob([file!]);
        const imageUrl = URL.createObjectURL(blob);
        setImageSrc(imageUrl);
        setSelectedFile(file!);
      }
      if (file.size > 5000000) {
        seterrorFileText("Image not uploaded as it is larger than 5MB.");
      } else {
        seterrorFileText("");
      }
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const image = new Image();
          image.src = e.target?.result as string;

          image.onload = () => {
            const dimensions = {
              width: image.width,
              height: image.height,
            };

            if (dimensions.width > 650) {
              seterrorWidthText("The image width should not exceed 650px!");
            } else {
              seterrorWidthText("");
            }
            if (dimensions.height > 150) {
              seterrorHeightText("The image height should not exceed 150px!");
            } else {
              seterrorHeightText("");
            }
          };
        };
        reader.readAsDataURL(file);
      }
    }
    store.changeValue("checkLeave", true);
  };

  const handleClearState = () => {
    setSelectedFile(null);
    setImageSrc(null);
    seterrorFileText("");
    seterrorHeightText("");
    seterrorWidthText("");
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Wrapped>
      <Header id="Template_Communication_Schedule_EmailSettings_Header_Label">
        Email settings
      </Header>
      <SubHeader id="Template_Communication_Schedule_EmailSettings_Header_ImageLabel">
        HEADER IMAGE
      </SubHeader>
      <ImageText id="Template_Communication_Schedule_EmailSettings_Header_ImageText">
        This will appear at the top of emails sent out to respondents. (Optional)
      </ImageText>
      <ImageWrapper>
        {selectedFile || (store.AllInfo && store.AllInfo.header_logo) ? (
          <LogoWrapper>
            <StyledImage
              id="CommunicationTemplateScheduleEmailSettingsImage"
              src={imageSrc || (store.AllInfo?.header_logo ? store.AllInfo.header_logo : empty)}
              alt="Selected image"
            />
          </LogoWrapper>
        ) : (
          <LogoWrapperNoImage>
            <StyledImageIcon />
            <BlockText>
              <NoImageText id="Template_Communication_Schedule_EmailSettings_NoUploadImage_Text">
                No image uploaded
              </NoImageText>
            </BlockText>
          </LogoWrapperNoImage>
        )}

        <StyledInput
          type="file"
          id="Template_Communication_Schedule_EmailSettings_Image_Input"
          ref={fileInputRef}
          accept=".jpg, .png"
          onChange={handleFileChange}
        />
      </ImageWrapper>

      <BottomImage>
        <ReplaceImage>
          <StyledImageChangeButton
            id="Template_Communication_Schedule_EmailSettings_Image_ReplaceButton"
            onClick={handleButtonClick}
          >
            <FolderImageIcon />
            Replace image
          </StyledImageChangeButton>
          <StyledImageChangeButton
            id="Template_Communication_Schedule_EmailSettings_Image_DeleteButton"
            onClick={() => {
              store.deleteFile();
              handleClearState();
            }}
          >
            <DeleteIcon />
            Delete
          </StyledImageChangeButton>
        </ReplaceImage>

        <MaxSizeImage id="Template_Communication_Schedule_EmailSettings_Image_MaxSize_Text">
          Max file size: 650px(width) x 150px(height), 5MB
        </MaxSizeImage>
      </BottomImage>
      <TypographyBox id="Template_Communication_Schedule_EmailSettings_Image_Error_Messages">
        <TypographyStyled id="Template_Communication_Schedule_EmailSettings_Image_Error_File">
          {errorFileText}
        </TypographyStyled>
        <TypographyStyled id="Template_Communication_Schedule_EmailSettings_Image_Error_Width">
          {errorWidthText}
        </TypographyStyled>
        <TypographyStyled id="Template_Communication_Schedule_EmailSettings_Image_Error_Height">
          {errorHeightText}
        </TypographyStyled>
      </TypographyBox>
      <SubHeader id="Template_Communication_Schedule_EmailSettings_Image_Footer_Label">
        FOOTER
      </SubHeader>

      <RichTextWithTabsAll
        data={store.FooterData}
        width={100}
        changeValue={(value, _, index) => store.changeFooterData(value, index)}
        field="footer_email"
        variantTestId="settings"
        boxId="Template_Communication_Schedule_EmailSettings_RichText"
        langNameId="Template_Communication_Schedule_EmailSettings_Footer_TabLabel"
        editorId="Template_Communication_Schedule_EmailSettings_Footer_Input"
      />

      <ButtonWrapper>
        <StyledNextButton
          variant="contained"
          id="Template_Communication_Schedule_EmailSettings_Save_Button"
          disabled={
            !(!errorFileText && !errorHeightText && !errorWidthText) || !store.changedFirstTab
          }
          onClick={() => store.SaveFooterData()}
        >
          Save changes
        </StyledNextButton>
        <StyledCancelButton
          variant="outlined"
          id="Template_Communication_Schedule_EmailSettings_Cancel_Button"
          disabled={!store.changedFirstTab}
          onClick={() => {
            store.onCancelFirstTab();
            store.changeFile(null);
            handleClearState();
          }}
        >
          Cancel
        </StyledCancelButton>
      </ButtonWrapper>
    </Wrapped>
  );
});

export default FirstTab;

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 400px;
  border: 1px dashed var(--colorPaletteBlueBackground1);
`;

const StyledInput = styled.input`
  display: none;
`;

const BlockText = styled.div`
  font-size: 12px;
  display: flex;
`;

const Wrapped = styled.div`
  margin: 10px 30px;
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 30px;
  margin-top: 10px;
  color: var(--colorNeutralForeground1);
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledCancelButton = styled(CustomButton)`
  width: 100%;
  max-width: 75px;
  margin-left: 160px;
`;

const StyledNextButton = styled(CustomButton)`
  width: 100%;
  margin-right: 20px;
  max-width: 150px;
`;

const SubHeader = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
  color: var(--colorNeutralForeground2);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
`;

const StyledImageIcon = styled(ImageIcon)`
  color: var(--colorNeutralForeground11);
  font-size: 40px !important;
`;

const NoImageText = styled.p`
  font-size: 12px;
  color: var(--colorNeutralForeground10);
  display: block;
  margin: 0;
`;

const LogoWrapper = styled.div``;

const LogoWrapperNoImage = styled.div`
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid var(--graySelectBoxBorder);
  border-radius: 8px;
`;

const ImageText = styled.div`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: var(--colorNeutralForeground1);
`;

const ImageWrapper = styled.div``;

const BottomImage = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: var(--colorNeutralForeground1);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ReplaceImage = styled.div`
  font-weight: 400;
  font-size: 16px;
`;

const StyledImageChangeButton = styled(CustomButton)`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const MaxSizeImage = styled.div`
  color: var(--colorNeutralForeground5);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-right: 185px;
`;

const TypographyStyled = styled.span`
  font-size: 12px;
  color: var(--colorPaletteRedForeground2);
`;
const TypographyBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
`;
