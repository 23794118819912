import http from "api/https";
import { createUrl } from "../../helpers/url";

export const GetMyInfo = async (): Promise<any> => {
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/permission/account_info",
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/accounts/GetMyInfo",
    });
    return http.get(url);
  }
};
