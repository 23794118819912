import { FC } from "react";
import { useLocation } from "react-router-dom";

import styled from "styled-components";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

type HeaderProps = {};
const Header: FC<HeaderProps> = () => {
  let { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const project_id = queryParams.get("project_id");
  const survey_id = queryParams.get("survey_id");

  return (
    <Container>
      <BackButtonWrapper href={`/dashboard?project_id=${project_id}&survey_id=${survey_id}`}>
        <BackIcon>
          <KeyboardBackspaceIcon />
        </BackIcon>
        <BackLabel>Back to dashboard</BackLabel>
      </BackButtonWrapper>
      <Title>Survey recipients list</Title>
      <p></p>
    </Container>
  );
};

export default Header;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  height: 72px;
  background-color: var(--colorPaletteVioletBackground1);
  padding: 24px 41px;
`;

const Title = styled.span`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  color: var(--colorNeutralBackground1);
`;

const BackButtonWrapper = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const BackLabel = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorPaletteGrayBackground1);
`;

const BackIcon = styled.span`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background-color: var(--colorBrandForeground1);

  svg {
    color: var(--colorNeutralBackground1);
    font-size: 18px;
  }
`;
