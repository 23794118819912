import { Button } from "@mui/material";
import { observer } from "mobx-react";
import { TTrendsQuestion } from "types/dashboard";
import { FC, useEffect, useState } from "react";

import store from "./store";
import styled from "styled-components";

type SelectedQuestionsProps = {
  selectedquestionsList: number[];
  localQuestionsList: TTrendsQuestion[];
};

const SelectedQuestions: FC<SelectedQuestionsProps> = observer((props) => {
  const [list, setList] = useState<TTrendsQuestion[]>([]);

  useEffect(() => {
    const newList = props.localQuestionsList
      .map((dimension) => {
        const newList = dimension.list.map((question) => {
          if (props.selectedquestionsList && props.selectedquestionsList.includes(question.id)) {
            return question;
          } else return null;
        });

        return { ...dimension, list: newList.filter((el) => el) };
      })
      .filter((dimension) => dimension.list.length > 0);

    setList(newList);
  }, [props.localQuestionsList, props.selectedquestionsList]);

  return (
    <Container>
      <Title>Question:</Title>
      {list && list.length > 0 && (
        <List>
          {list.map((dimension, dimensionIndex) => (
            <Row key={dimension.dimension + dimensionIndex} $isFirst={dimensionIndex === 0}>
              <Dimension>{dimension.dimension}</Dimension>
              <QuestionList>
                {dimension.list.map((question, questionIndex) => (
                  <Question key={question.id} $isFirst={questionIndex === 0}>
                    {question.title}
                  </Question>
                ))}
              </QuestionList>
            </Row>
          ))}
        </List>
      )}
      {store.ratingQuestionsList && store.ratingQuestionsList.length > 1 && (
        <SelectButton
          variant="text"
          onClick={() => store.setData(true, "isOpenSelectQuestionPopup")}
        >
          Select
        </SelectButton>
      )}
    </Container>
  );
});

export default SelectedQuestions;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 0px 48px 32px 48px;
`;

const Title = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  margin: 2px 16px 0px 0px;
`;

const SelectButton = styled(Button)`
  text-transform: none !important;
  padding: 0px !important;
  margin-left: 10px !important;
`;

const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 5px 0px var(--colorNeutralBackground3);
  padding: 8px;
`;

const Row = styled.div<{ $isFirst: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 120px 1fr;
  ${(props) => !props.$isFirst && "  margin-top: 8px"};
`;

const Dimension = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
`;

const QuestionList = styled.p`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Question = styled.p<{ $isFirst: boolean }>`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  ${(props) => !props.$isFirst && "margin-top: 2px"};
`;
