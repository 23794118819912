import { FC } from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import CustomButton from "components/button/Button";
import AddRecipientsIcon from "./icons/AddRecipientsIcon";
import store from "../../store";
import { createUrl } from "helpers/url";

type ReminderPopupProps = {
  closePopup: () => void;
};

const ReminderPopup: FC<ReminderPopupProps> = observer((props) => {
  const navigate = useNavigate();

  const navigateHandler = () => {
    store.setData("changedThirdTab", false);
    store.changeReminderModal(false);
    if (!store.changedThirdTab) {
      const url = createUrl({
        path: "/recipients",
        query: {
          id: store.idSurvey.toString(),
        },
      });
      navigate(url);
    }
  };

  return (
    <PopupWrapper>
      <StyledTitle
        id={`Survey_EditCreate_Communication_ScheduleCommunications_Reminder_PopUp_Title`}
      >
        Reminder will not be sent out as no recipients are in this group
      </StyledTitle>
      <BoxStyled>
        <p id={`Survey_EditCreate_Communication_ScheduleCommunications_Reminder_PopUp_Subtitle`}>
          This reminder cannot be saved till recipients have been added.
        </p>
      </BoxStyled>
      <BtnBox>
        <CustomButton
          id={`Survey_EditCreate_Communication_ScheduleCommunications_Reminder_PopUp_ChangeGroupBtn`}
          variant="contained"
          onClick={() => props.closePopup()}
        >
          Change group
        </CustomButton>
        <CustomButton onClick={navigateHandler}>
          <LinkStyled
            id={`Survey_EditCreate_Communication_ScheduleCommunications_Reminder_PopUp_AddRecepientsLink`}
          >
            Add recipients
          </LinkStyled>
          <AddRecipientsIcon />
        </CustomButton>
      </BtnBox>
    </PopupWrapper>
  );
});

export default ReminderPopup;

const PopupWrapper = styled.div`
  padding: 40px;
`;

const StyledTitle = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin: 0;
`;

const BoxStyled = styled(Box)`
  margin-top: 30px;
`;

const BtnBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 30px;
`;

const LinkStyled = styled.p`
  font-style: normal;
  color: var(--colorBrandForeground1);
  font-weight: 500;
  font-size: 16px;
  margin-right: 10px;
`;
