import styled from "styled-components";
import Lookup from "./LookUp";
import usePagination from "@mui/material/usePagination/usePagination";

export interface PaginationProps {
  showtotalpage?: boolean;
  totalPages?: number;
  onChange: (pageNumber: number) => void;
  activePage: number;
  pageRangeDisplayed?: number;
  itemsCountPerPage: number;
  totalItemsCount: number;
  id?: string;
  setCount?: (num: number) => void;
}

const Pagination = (props: PaginationProps) => {
  const paginationVariats = [10, 25, 50, 100];
  const { items } = usePagination({
    page: props.activePage,
    count: Math.ceil(props.totalPages || 1),
    onChange: (_, pageNumber) => {
      props.onChange(pageNumber);
    },
  });

  return (
    <StyledPagination
      id={(props.id ? props.id : "") + "PaginationFooter"}
      className="table-pagination-wrapper"
      showtotalpage={props.showtotalpage}
    >
      <StyledRows>
        <PageProgress id={(props.id ? props.id : "") + "PaginationViewing"}>
          {props.activePage * props.itemsCountPerPage < props.totalItemsCount
            ? "Viewing " +
              (props.activePage * props.itemsCountPerPage - props.itemsCountPerPage + 1) +
              "-" +
              props.activePage * props.itemsCountPerPage +
              " of " +
              props.totalItemsCount
            : "Viewing " +
              (props.activePage * props.itemsCountPerPage - props.itemsCountPerPage + 1) +
              "-" +
              props.totalItemsCount +
              " of " +
              props.totalItemsCount}
        </PageProgress>
        {props.setCount && (
          <ContainerLookup id={(props.id ? props.id : "") + "PaginationRowsPer"}>
            <PageProgress>ROWS PER PAGE:</PageProgress>
            <Lookup
              id={(props.id ? props.id : "") + "PaginationRowsSelect"}
              data={paginationVariats}
              value={props.itemsCountPerPage}
              errorText=""
              error={false}
              onChange={(e) => props.setCount(Number(e.target.value))}
            />
          </ContainerLookup>
        )}
      </StyledRows>
      <nav>
        <List>
          {items.map(({ page, type, selected, ...item }, index) => {
            let children = null;
            if (type === "start-ellipsis" || type === "end-ellipsis") {
              children = (
                <StyledButtonPrevNext 
                style={{ cursor: "default" }} variant={type} type="button">
                  ...
                </StyledButtonPrevNext>
              );
            } else if (type === "page") {
              children = (
                <StyledButtonPrevNext
                  variant={type}
                  type="button"
                  style={
                    selected
                      ? {
                          background: "var(--colorPaletteGrayBackground1)",
                          color: "var(--colorNeutralForeground6)",
                        }
                      : null
                  }
                  {...item}
                >
                  {page}
                </StyledButtonPrevNext>
              );
            } else {
              children = (
                <StyledButtonPrevNext
                  style={{
                    cursor: item.disabled ? "not-allowed" : "pointer",
                  }}
                  variant={type}
                  type="button"
                  {...item}
                >
                  {type === "next" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                    >
                      <path d="M1 11L6 6L1 1" stroke="#707882" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                    >
                      <path d="M6 11L1 6L6 1" stroke="#707882" />
                    </svg>
                  )}
                </StyledButtonPrevNext>
              );
            }
            return <li key={index}>{children}</li>;
          })}
        </List>
      </nav>
    </StyledPagination>
  );
};

export default Pagination;

const StyledButtonPrevNext = styled.button<{ variant?: string }>`
  cursor: pointer;
  background: var(--colorNeutralBackground1);
  color: var(--colorNeutralForeground2);
  border: none;
  border-right: 1px solid var(--colorNeutralForeground4);
  &:last-child {
    border-right: none;
  }
  ${({ variant }) => {
    switch (variant) {
      case "next":
        return `
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          padding: 12px 14px 9px 14px;
        `;
      case "previous":
        return `
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          padding: 12px 14px 9px 14px;
        `;
      default:
        return `
          border-radius: 0%;
          padding: 10.5px 14px;
        `;
    }
  }}
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
`;

const StyledPagination = styled.div<{ showtotalpage?: boolean }>`
  width: 100%;
  display: ${({ showtotalpage }) => (showtotalpage ? "flex" : "inline-flex")};
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
`;

const StyledRows = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  .MuiFormControl-root {
    width: 65px;
    select {
      padding-left: 6px;
      border: none;
    }
  }
`;

const ContainerLookup = styled.div`
  display: flex;
  align-items: center;
`;

const PageProgress = styled.span`
  color: var(--colorNeutralForeground2);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
`;
