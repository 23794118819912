import { FC } from "react";
import { MillstoneRecipientSheduleScreen } from "features/Survey/MillstoneRecipientShedule";

type MillstoneRecipientShedulePageProps = {};

const MillstoneRecipientShedulePage: FC<MillstoneRecipientShedulePageProps> = () => {
  return <MillstoneRecipientSheduleScreen />;
};

export default MillstoneRecipientShedulePage;
