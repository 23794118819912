import { FC } from "react";
import { IconXlsx } from "./IconXlsx";

import styled from "styled-components";
import ErrorIcon from "@mui/icons-material/Error";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { observer } from "mobx-react"

type ErrorsProps = {
  file: File;
};

const Errors: FC<ErrorsProps> = observer((props) => {
  const formName = "Errors";

  return (
    <ErrorsWrapper id={`${formName}_errorsWrapper`}>
      <Content id={`${formName}_errorsContent`}>
        <Header id={`${formName}_errorsHeader`}>Errors found in file</Header>
        <FileWrapper id={`${formName}_fileWrapper`}>
          <IconXlsx id={`${formName}_iconXlsx`}/>
          <p id={`${formName}_fileName`}>{props.file?.name || "File not found"}</p>
        </FileWrapper>
        <WarningContainer id={`${formName}_warningContainer`}>
          <ErrorIcon id={`${formName}_errorIcon`}/>
          <p id={`${formName}_errorText`}>This list cannot be uploaded due to the following errors.</p>
        </WarningContainer>

        {StoreLayout.importErrors?.some(x => x.code === "unit_does_not_match") &&
          <Unit id={`${formName}_unitDoesNotMatch`}>
            <UnitHeader id={`${formName}_unitDoesNotMatch`}>Unit hierarchy relationship does not match</UnitHeader>
            {StoreLayout.importErrors?.filter(x => x.code === "unit_does_not_match").map((x, index) => {
              return <UnitRowText id={`${formName}_unitDoesNotMatch_${index}`}>{x.value}</UnitRowText>
            })}
          </Unit>
        }

        {StoreLayout.importErrors?.some(x => x.code === "invalid_file") &&
          <Unit id={`${formName}_unitInvalidFile`}>
            <UnitHeader id={`${formName}_unitHeaderInvalidFile`}>Something went wrong, check your file</UnitHeader>
          </Unit>
        }

        {StoreLayout.importErrors?.some(x => x.code === "no_unit_structure") &&
          <Unit id={`${formName}_unitNoUnitStructure`}>
            <UnitHeader id={`${formName}_unitHeaderNoUnitStructure`}>Unit hierarchy has not been configured yet</UnitHeader>
          </Unit>
        }

        {StoreLayout.importErrors?.some(x => x.code === "no_structure_type") &&
          <Unit id={`${formName}_unitNoStructureType`}>
            <UnitHeader id={`${formName}_unitHeaderNoStructureType`}>Need to setup your organization structure type</UnitHeader>
          </Unit>
        }

        {StoreLayout.importErrors?.some(x => x.code === "empty_email") &&
          <Unit id={`${formName}_unitEmptyEmail`}>
            <UnitHeader id={`${formName}_unitHeaderEmptyEmail`}>Email/unique ID can’t be blank</UnitHeader>
            {StoreLayout.importErrors?.filter(x => x.code === "empty_email").map((x, index) => {
              return <UnitRowText id={`${formName}_unitRowTextEmptyEmail_${index}`}>Row {x.row_index}</UnitRowText>
            })}
          </Unit>
        }

        {StoreLayout.importErrors?.some(x => x.code === "empty_fullname") &&
          <Unit id={`${formName}_unitEmptyFullname`}>
            <UnitHeader id={`${formName}_unitHeaderEmptyFullname`}>Full name can’t be blank</UnitHeader>
            {StoreLayout.importErrors?.filter(x => x.code === "empty_fullname").map((x, index) => {
              return <UnitRowText id={`${formName}_unitRowTextEmptyFullname_${index}`}>Row {x.row_index}</UnitRowText>
            })}
          </Unit>
        }

        {StoreLayout.importErrors?.some(x => x.code === "preferred_name") &&
          <Unit id={`${formName}_unitEmptyPreferredName`}>
            <UnitHeader id={`${formName}_unitHeaderEmptyPreferredName`}>Preferred name can’t be blank</UnitHeader>
            {StoreLayout.importErrors?.filter(x => x.code === "preferred_name").map((x, index) => {
              return <UnitRowText id={`${formName}_unitRowTextEmptyPreferredName_${index}`}>Row {x.row_index}</UnitRowText>
            })}
          </Unit>
        }

        {StoreLayout.importErrors?.some(x => x.code === "duplicated_email" || x.code === "duplicated_unique_id") &&
          <Unit id={`${formName}_unitDuplicatedEmail`}>
            <UnitHeader id={`${formName}_unitHeaderDuplicate`}>Duplicate emails or unique IDs</UnitHeader>
            {StoreLayout.importErrors?.some(x => x.code === "duplicated_email") && <><UnitSubHeader id={`${formName}_unitSubHeaderDuplicateEmails`}>Duplicate emails:</UnitSubHeader>
              {Object.values(StoreLayout.importErrors?.filter(x => x.code === "duplicated_email").reduce((acc, obj) => ({ ...acc, [obj.value]: obj }), {})).map((x: any, index) => {
                return <UnitRowText id={`${formName}_unitRowTextDuplicateEmail_${index}`}>{x.value}</UnitRowText>
              })}
            </>}

            {StoreLayout.importErrors?.some(x => x.code === "duplicated_unique_id") && <><UnitSubHeader id={`${formName}_unitSubHeaderDuplicateUniqueIDs`}>Duplicate unique IDs:</UnitSubHeader>
              {Object.values(StoreLayout.importErrors?.filter(x => x.code === "duplicated_unique_id").reduce((acc, obj) => ({ ...acc, [obj.value]: obj }), {})).map((x: any, index) => {
                return <UnitRowText id={`${formName}_unitRowTextDuplicateUniqueID-${index}`}>{x.value}</UnitRowText>
              })}
            </>}
          </Unit>
        }

        {StoreLayout.importErrors?.some(x => x.code === "incorrect_email") &&
          <Unit id={`${formName}_unitIncorrectEmail`}>
            <UnitHeader id={`${formName}_unitHeaderIncorrectEmail`}>Incorrect emails</UnitHeader>
            {StoreLayout.importErrors?.filter(x => x.code === "incorrect_email").map((x, index) => {
              return <UnitRowText id={`${formName}_unitRowTextIncorrectEmail-${index}`}><b>Row {x.row_index}</b> - {x.value}</UnitRowText>
            })}
          </Unit>
        }
        {StoreLayout.importErrors?.some(x => x.code === "date_format") &&
          <Unit id={`${formName}_unitIncorrectDateFormat`}>
            <UnitHeader id={`${formName}_unitHeaderIncorrectDateFormat`}>Start date is in the wrong format</UnitHeader>
            <UnitSubHeader id={`${formName}_unitSubHeaderIncorrectDateFormat`}>Please ensure that start date is provided in the format “dd/mm/yyyy“ e.g. 21/01/2020</UnitSubHeader>
            {StoreLayout.importErrors?.filter(x => x.code === "date_format").map((x, index) => {
              return <UnitRowText id={`${formName}_unitRowTextIncorrectDateFormat-${index}`}><b>Row {x.row_index}</b></UnitRowText>
            })}
          </Unit>
        }
        {StoreLayout.importErrors?.some(x => x.code === "managers_not_found") &&
          <Unit id={`${formName}_managersNotFound`}>
            <UnitHeader id={`${formName}_unitHeaderManagersNotFound`}>Managers not found</UnitHeader>
            <UnitSubHeader id={`${formName}_unitSubHeaderManagersNotFound`}>Managers need to exist on the list as an employee before they can be added as managers</UnitSubHeader>
            {StoreLayout.importErrors?.filter(x => x.code === "managers_not_found").map((x, index) => {
              return <UnitRowText id={`${formName}_unitRowTextManagersNotFound-${index}`}><b>{x.row_index}</b>{x.value}</UnitRowText>
            })}
          </Unit>
        }
         {/* <Unit>
          
          <br />
          <UnitRowText>Row 2</UnitRowText>
          <UnitRowText>Row 3</UnitRowText>
          <UnitRowText>Row 4</UnitRowText>
          <UnitRowText>Row 23</UnitRowText>
          <UnitRowText>Row 39</UnitRowText>
        </Unit>
        <Unit>
          <UnitHeader>Duplicate emails or unique IDs</UnitHeader>
          <UnitSubHeader>Duplicate emails:</UnitSubHeader>
          <UnitRowText>homer.doh@strangefruit.com</UnitRowText>
          <UnitRowText>trish.law@strangefruit.com</UnitRowText>
          <UnitRowText>poy.triyanapachu@strangefruit.com</UnitRowText>
          <UnitSubHeader>Duplicate unique ID:</UnitSubHeader>
          <UnitRowText>113</UnitRowText>
          <UnitRowText>1923</UnitRowText>
        </Unit>
        <Unit>
          <UnitHeader>Existing employee details not updated</UnitHeader>
          <UnitSubHeader $bottomMargin={true}>
            Employee details cannot be updated due to the following:
          </UnitSubHeader>
          <UnitRowText>
            <b>Jerome (Jerome Chan Alex Grosse)</b>
          </UnitRowText>
          <UnitRowText>
            <b>Row 4</b> has same unique ID – 1324
          </UnitRowText>
          <UnitRowText>
            <b>Row 6</b> has the same email – jerome_chan@strangefruit.com
          </UnitRowText>
          <br />
          <UnitRowText>
            <b>Jerome (Jerome Chan Alex Grosse)</b>
          </UnitRowText>
          <UnitRowText>
            <b>Row 4</b> has same unique ID – 1324
          </UnitRowText>
          <UnitRowText>
            <b>Row 6</b> has the same email – jerome_chan@strangefruit.com
          </UnitRowText>
        </Unit>
        <Unit>
          <UnitHeader>Employees can’t be their own managers</UnitHeader>
          <br />
          <UnitRowText>manny.manager@strangefruit.com</UnitRowText>
          <UnitRowText>polly.pushinki@strangefruit.com</UnitRowText>
        </Unit>
        <Unit>
          <UnitHeader>Managers not found</UnitHeader>
          <UnitSubHeader $bottomMargin={true}>
            Managers need to exist on the list as an employee before they can be added as managers
          </UnitSubHeader>
          <UnitRowText>manny.manager@strangefruit.com</UnitRowText>
          <UnitRowText>polly.pushinki@strangefruit.com</UnitRowText>
        </Unit>
        <Unit>
          <UnitHeader>Manager’s email address and unique ID don’t match</UnitHeader>
          <UnitSubHeader $bottomMargin={true}>
            Employee details cannot be updated as there is conflicting manager details
          </UnitSubHeader>
          <UnitRowText>
            <b>Jerome (Jerome Chan Alex Grosse)</b>
          </UnitRowText>
          <UnitRowText>Manager’s email: homer.doh@strangefruit.com</UnitRowText>
          <UnitRowText>Manager’s Unique ID: 1424</UnitRowText>
        </Unit>
        <Unit>
          <UnitHeader>Attribute columns don’t match</UnitHeader>
          <br />
          <UnitRowText>Column K</UnitRowText>
          <UnitRowText>Column L</UnitRowText>
          <UnitRowText>Column M</UnitRowText>
          <UnitRowText>Column N</UnitRowText>
          <UnitRowText>Column P</UnitRowText>
        </Unit>
        <Unit>
          <UnitHeader>Levels do not match</UnitHeader>
          <UnitSubHeader $bottomMargin={true}>
            Level details cannot be updated due to the following:
          </UnitSubHeader>
          <UnitRowText>
            <b>Row 4</b> Level 2 does not fall under Level 1
          </UnitRowText>
          <UnitRowText>
            <b>Row 6</b> Level 3 does not fall under Level 2
          </UnitRowText>
        </Unit> */}
      </Content>
    </ErrorsWrapper>
  );
});


export default Errors;

const ErrorsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 520px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px 0px 20px 0px;
`;

const FileWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 28px;

  p {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: var(--colorNeutralForeground1);
  }

  svg {
    margin-right: 10px;
  }
`;

const WarningContainer = styled.div`
  width: 100%;
  padding: 20px 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: var(--colorNeutralBackground1);

  p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--colorNeutralForeground2);
  }

  svg {
    color: var(--colorPaletteRedForeground2);
    margin-right: 10px;
  }
`;

const Unit = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
  margin-top: 20px;
  padding-bottom: 15px;
`;

const UnitHeader = styled.h2`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  color: var(--colorNeutralForeground2);
  margin: 0px 0px 0px 0px;
`;

const UnitRowText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const UnitSubHeader = styled.p<{ $bottomMargin?: boolean }>`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
  margin: ${(props) => (props.$bottomMargin ? "0px 0px 20px 0px" : "20px 0px 6px 0px")};
`;
