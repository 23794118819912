export const DASHBOARD_MENU = [
  {
    label: "Overview",
    value: "overview",
  },
  {
    label: "Responses",
    value: "responses",
  },
  {
    label: "Hotspots",
    value: "hotspots",
  },
  {
    label: "Trend results",
    value: "trend",
  },
  {
    label: "Prioritize",
    value: "prioritize",
  },
];

export const RESPONSES_TAB_MENU = [
  {
    label: "Scored questions",
    value: "scored_questions",
  },
  {
    label: "Open-ended",
    value: "open_ended",
  },
  {
    label: "Others",
    value: "others",
  },
];

export const DASHBOARD_MENU_VALUES = {
  overview: "overview",
  responses: "responses",
  hotspots: "hotspots",
  trends: "trend",
  prioritize: "prioritize",
};

export const PROJECT_STATUSES = {
  ACTIVE: 0,
  COMPLETED: 1,
  TEST: 3,
};

export const EMPTY_STRING_FILTERS = [
  {
    unit_or_attr: null,
    segments: [],
  },
];

export const PROJECT_TYPES = {
  oneOff: 0,
  frequency: 1,
  milestones: 2,
};
