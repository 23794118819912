import { makeAutoObservable } from "mobx";
import { getRolesEmployee } from 'api/useGetRolesEmployee';
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { PermissionsForUser } from "types/permission";
import MainStore from "MainStore";

class Store {

  full_name: string = ""
  preferred_name: string = ""
  data: PermissionsForUser = null

  constructor() {
    makeAutoObservable(this);
  }
  
  clearStore = () => {
  }

  async loadEmployee(idEmployee: number) {
    try {
      const response = await getRolesEmployee(idEmployee, StoreLayout.currentWorkspaceId);
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        this.data = response.data
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }
  
  handleChange(name: string, value: any){
    this[name] = value
  }

}

const store = new Store();
export default store;
