export const QUESTION_TYPE = [
    {
        label: "Overall score",
        value: 0,
    },
    {
        label: "Scored question",
        value: 1,
    },
    {
        label: "eNPS question",
        value: 2,
    },
    {
        label: "MCQ",
        value: 3,
    },
    {
        label: "Yes/No",
        value: 4,
    },
];

export const QUESTION_TYPE_VALUES = {
    overal_score: 0,
    scored_question: 1,
    enps_question: 2,
    msq: 3,
    yes_no: 4,
};

export const TREND_ACROSS = [
    {
        label: "Surveys",
        value: "SURVEYS",
    },
    {
        label: "Months",
        value: "MONTHS",
    },
    {
        label: "Quarters of the year",
        value: "QUARTERS",
    },
    {
        label: "Years",
        value: "YEARS",
    },
];

export const SCORE_TYPE_ENPS = [
    {
        label: "eNPS",
        value: "ENPS",
    },
    {
        label: "Promoters",
        value: "PROMOTERS",
    },
    {
        label: "Passives",
        value: "PASSIVES",
    },
    {
        label: "Detractors",
        value: "DETRACTORS",
    },
];

export const SCORE_TYPE_ENPS_VALUES = {
    ENPS: "eNPS",
    PROMOTERS: "Promoters",
    PASSIVES: "Passives",
    DETRACTORS: "Detractors",
};

export const SCORE_TYPE_OVERAL_SCORED = [
    {
        label: "Favorable",
        value: "FAVORABLE",
    },
    {
        label: "Unfavorable",
        value: "UNFAVORABLE",
    },
];

export const SCORE_TYPE_OVERAL_SCORED_VALUES = {
    FAVORABLE: "Favorable score",
    UNFAVORABLE: "Unfavorable score",
};

export const COMPARE_WITH_BENCHMARK = [
    {
        label: "All companies",
        value: "all_companies",
    },
    {
        label: "Healthcare",
        value: "healthcare",
    },
    {
        label: "Mercer Benchmark",
        value: "mercer_benchmark",
    },
];

export const TRENDS_MSQ_WHO_SELECTED = [
    {
        label: "Annually",
        value: 0,
    },
    {
        label: "Monthly",
        value: 1,
    },
    {
        label: "Bi-weekly",
        value: 2,
    },
    {
        label: "Weekly",
        value: 3,
    },
];

export const TRENDS_YES_NO_WHO_SELECTED = [
    {
        label: "Yes",
        value: 0,
    },
    {
        label: "No",
        value: 1,
    },
];

export const GRAPH_LABELS = {
    favorable_score: "Favorable score",
    eNPS: "eNPS",
    percent_respondents: "% Respondents",
};

export const TOOLTIP_TEXT =
    "Autonomy: Can you observe any meaningful relationship between what you do and the overall goals of the company?";
export const TOOLTIP_TEXT_BENCHMARK =
    "Autonomy: I am empowered to make decisions to do my job effectively.";

export const TOOLTIP_TYPE_VALUE = "value";
export const TOOLTIP_TYPE_BENCHMARK = "benchmark";
