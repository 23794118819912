import http from "api/https";


export const dublicateCommunicationTemplate = (id:number, entity_id:number): Promise<any> => {
  let data = {
    id: id,
    entity_id: entity_id
  }
  if(http.isAPIGO){
    return http.postGO('/template/dublicate_communication_template', data)
  } else {
    return http.post('/templ_template_comms/DublicateCommunicationTemplate', data)
  }
};
