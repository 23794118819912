import { SEGMENT } from "constants/dashboard-hotspots";
import { useGetBenchmarkList } from "api/dasboard/useGetBenchmarkList";
import { useGetHotspotsQuestions } from "api/dasboard/useGetHotspotsQuestions";
import { useGetDashboardHotspots } from "api/dasboard/useGetDashboardHotspots";
import { getSurveyAttributeAsync } from "api/dasboard/useGetSurveyAttribute";
import { getSurveyResponseCountAsync } from "api/dasboard/useGetSurveyResponseCount";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { THotspotsData, THotspotsQuestion } from "types/dashboard";
import { ROLE_LIST } from "constants/role-list";


import topStore from "../store";
import MainStore from "MainStore";

class Store {
    selectedCompany: number = 0;

    isOpenColumnsSetupPopup: boolean = false;
    isOpenLoadingPopup: boolean = false;
    isOpenSetupSegmnetsPopup: boolean = false;

    respondentsList = [];
    questionsList: THotspotsQuestion[] = null;

    graphData: THotspotsData = null;

    overviewBenchmark: number = undefined;
    isAllCompaniesBenchmark: boolean = true;
    benchmarkFilterList: { value: number; label: string }[] = [];

    //Columns setup popup
    typeColumnsSetup: string = "diff_score";
    isOveralScore: boolean = true;
    isENPS: boolean = true;
    isFavourableScore: boolean = true;
    isActiveEnps: boolean = true;

    //Select segments popup
    selectedSegments: { attribute: string; mode: string; value: string[] }[] = [];
    attributeList: {
        label: string;
        value: number;
        list: { id: number; value: string; name?: string; level?: number }[];
    }[] = [];

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => [topStore.selectedSurvey, topStore.activeTab, this.selectedSegments],
            () => {
                if (topStore.selectedSurvey && topStore.activeTab === "hotspots") {
                    this.getHotspotSurveyResponseCount(topStore.selectedSurvey.value);
                }
            }
        );
    }

    setData = (value: any, field: string) => {
        this[field] = value;
    };

    getSurveyAttributes = async (survey_id: number) => {
        try {
            const response = await getSurveyAttributeAsync(survey_id);

            this.attributeList = response.map((el) => ({
                value: el.id,
                label: el.name,
                list: el.values,
            }));
        } catch (error) {
            console.error(error);
        }
    };

    getSelectedQuestionsIds = () => {
        let questionIds = [];
        const arr = this.questionsList.map((dimension) => {
            const arr = dimension.list
                .map((question) => {
                    if (question.checked) return question.questionId;
                })
                .filter((el) => el);
            return arr;
        });
        questionIds = [].concat(...arr);
        return questionIds;
    };

    getQuestionsList = async (survey_id: number) => {
        try {
            const response = await useGetHotspotsQuestions(survey_id);
            if (response.status === 200 || response.status === 201) {
                const dimensions = response.data.map((question) => ({
                    dimensionId: question.dimension_id,
                    dimensionName: question.dimension_idNavName,
                }));

                const uniqDimensions = dimensions.reduce((o, i) => {
                    if (!o.find((v) => v.dimensionId == i.dimensionId)) {
                        o.push(i);
                    }
                    return o;
                }, []);

                const newQuestionList = response.data.map((question) => ({
                    dimensionId: question.dimension_id,
                    dimensionName: question.dimension_idNavName,
                    questionId: question.id,
                    question: question.title,
                }));

                const result = uniqDimensions.map((el) => {
                    const list = newQuestionList.map((item) => {
                        if (item.dimensionId === el.dimensionId) {
                            return {
                                questionId: item.questionId,
                                question: item.question,
                                checked: true,
                                type_question_code: item.type_question_code,
                            };
                        } else return null;
                    });

                    return {
                        dimensionId: el.dimensionId,
                        dimensionName: el.dimensionName,
                        list: list.filter((i) => i),
                    };
                });

                this.questionsList = result;
            } else throw new Error();
        } catch (error) {
            console.error(error);
        }
    };

    getData = async (surveyId: number, role: string, overviewBenchmark: number) => {
        try {
            MainStore.changeLoader(true);
            const filters = this.selectedSegments
                .map((el, index) => {
                    if (el.attribute)
                        return {
                            id: index,
                            name: el.attribute,
                            segments: typeof el.value === "string" ? [el.value] : el.value,
                        };
                    else return null;
                })
                .filter((x) => x);
            const response = await useGetDashboardHotspots({
                survey_id: surveyId,
                attributes: filters,
                viewType: role,
                rowSettings: {
                    overalScore: this.isOveralScore,
                    eNPS: this.isENPS,
                    questions: this.getSelectedQuestionsIds(),
                },
                benchmarkType: overviewBenchmark,
            });
            if (response.status === 200 || response.status === 201) {
                runInAction(() => {
                    this.graphData = {
                        data: response.data.data.map((el) => {
                            const newList = el.list.map((item) => ({ ...item, visibility: false }));
                            return {
                                ...el,
                                list: newList,
                            };
                        }),
                        categories: response.data.categories.map((categor) => {
                            const dimension =
                                response.data.data[0] &&
                                response.data.data[0].list.find(
                                    (el) => el.dimension === categor && el.type === SEGMENT
                                );
                            return {
                                name: categor,
                                collapse: false,
                                type: (dimension && dimension.type) || null,
                                countQuestions: (dimension && dimension.countQuestions) || null,
                            };
                        }),
                    };
                });
            } else throw new Error();
        } catch (error) {
            console.error(error);
        } finally {
            MainStore.changeLoader(false);
        }
    };

    getBenchmarkList = async (survey_id: number) => {
        try {
            MainStore.changeLoader(true);
            const response = await useGetBenchmarkList(survey_id);
            if (response.status === 200 || response.status === 201) {
                runInAction(() => {
                    this.benchmarkFilterList = response.data.map((el) => ({ value: el.id, label: el.name }));
                    this.overviewBenchmark =
                        response.data && response.data.length > 0 ? response.data[0].id : null;
                });
            } else throw new Error();
        } catch (error) {
            console.error(error);
        } finally {
            MainStore.changeLoader(false);
        }
    };

    getHotspotSurveyResponseCount = async (id: number) => {
        MainStore.changeLoader(true);

        // it's crutch, remove it on refactor
        if (typeof this.overviewBenchmark !== "number") {
            await this.getBenchmarkList(id)
        }

        const filters = this.selectedSegments.map((el, index) => ({
            id: index,
            name: el.attribute,
            segments: Array.isArray(el.value) ? el.value : [el.value],
        }));

        const requestData = {
            survey_id: id,
            attributes: filters,
            viewType: topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
            benchmarkType: this.overviewBenchmark,
        };

        const response = await getSurveyResponseCountAsync(requestData);

        runInAction(() => {
            this.isActiveEnps = response.questions.some((el) => el.type === "enps");
            if (!this.isActiveEnps) {
                this.isENPS = false;
            }
        });

        MainStore.changeLoader(false);
    };
}

const store = new Store();

export default store;
