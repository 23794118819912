import styled from "styled-components";

const StyledSvg = styled.svg``;

export const CheckDescIcon = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <StyledSvg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 5.51562C18.55 5.51562 17.74 6.94768 18.07 8.01177L14.52 11.5521C14.22 11.4626 13.78 11.4626 13.48 11.5521L10.93 9.0162C11.27 7.9521 10.46 6.51011 9 6.51011C7.55 6.51011 6.73 7.94216 7.07 9.0162L2.51 13.5411C1.44 13.2129 0 14.0184 0 15.4604C0 16.5544 0.9 17.4494 2 17.4494C3.45 17.4494 4.26 16.0173 3.93 14.9532L8.48 10.4184C8.78 10.5079 9.22 10.5079 9.52 10.4184L12.07 12.9543C11.73 14.0184 12.54 15.4604 14 15.4604C15.45 15.4604 16.27 14.0284 15.93 12.9543L19.49 9.42393C20.56 9.75211 22 8.94658 22 7.50459C22 6.41066 21.1 5.51562 20 5.51562Z"
      fill="#1493A4"
    />
    <path
      d="M14 6.51376L14.94 4.45518L17 3.53032L14.94 2.60545L14 0.546875L13.08 2.60545L11 3.53032L13.08 4.45518L14 6.51376Z"
      fill="#1493A4"
    />
    <path
      d="M2.5 8.50365L3 6.51469L5 6.01745L3 5.52021L2.5 3.53125L2 5.52021L0 6.01745L2 6.51469L2.5 8.50365Z"
      fill="#1493A4"
    />
  </StyledSvg>
);
