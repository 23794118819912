import { FC, ReactElement, useEffect } from "react";
import store from './store'
import { Button, TextField } from "@mui/material";
import { observer } from "mobx-react"
import FileInput from "../../../components/uploadImage";
import { Box, Dialog, Grid, IconButton } from '@mui/material'
import Stepper from "../../../components/stepper";
import CloseIcon from '@mui/icons-material/Close';
import WestIcon from '@mui/icons-material/West';



type ModalChnagePasswordProps = {
  openPanel: boolean;
  onBtnCancelClick: () => void;
  onSaved: () => void;
  workspacename: string;
};

const ModalCreateWorkspace: FC<ModalChnagePasswordProps> = observer((
  props
): ReactElement => {
  useEffect(() => {
    return () => {
      store.clearStore()
    }
  }, []);

  return (
    <Dialog
      open={props.openPanel}
      onClose={() => { }}
      maxWidth={'lg'} fullWidth
      aria-label="Create workspace modal"
    >
      <Grid container style={{ padding: 30 }}>
        <Grid item xs={12} md={12}>
          <Box display={"flex"} justifyContent={"flex-end"} >
            <IconButton onClick={() => {
              props.onBtnCancelClick()
              store.clearStore()
            }}>
              <CloseIcon style={{ fontSize: 30 }} />
            </IconButton>
          </Box>

        </Grid>
        <Grid item xs={12} md={6}>

          <Box style={{ margin: "20px 10px 50px 70px" }} >
            <img alt={"entityAddEmage"} width={470} height={580} src={"/assets/entityAdd.jpg"} />
          </Box>

        </Grid>
        <Grid item xs={12} md={6}>


          <Box style={{ margin: "50px 10px 0px 20px", width: 400 }} >
            {/* <StyledModalTitle id="titleId">Create entity under {props.workspacename}</StyledModalTitle> */}
            <Box style={{ marginBottom: 20 }}>
              <Stepper value={store.stepperValue} steps={2} changeValue={(value) => store.changeStepperValue(value)} hideButtons />
            </Box>

            {store.stepperValue > 0 && <Box style={{ marginBottom: 20 }}>
              <Button onClick={() => store.changeStepperValue(store.stepperValue - 1)}>
                <WestIcon style={{ marginRight: 5 }} /> {store.stepperValue === 1 ? "Back to entity name" : "Back to sign in method"}
              </Button>
            </Box>}

            <Box style={{ fontSize: 30, fontWeight: 600 }}>
              {store.stepperValue === 0 ? "Create a entity under" + props.workspacename : "Upload company logo"}
            </Box>

            {store.stepperValue === 0 && <Box style={{ fontSize: 16, fontWeight: 600, marginTop: 20, marginBottom: 30 }}>
              Create a new entity to get started. Entity name can be geographical e.g. Singapore, Asia Pacific or by company subsidiary names.
            </Box>}

            {store.stepperValue === 0 && <TextField
              name="name"
              id="name"
              placeholder="Enter name"
              fullWidth
              label="Entity name"
              variant="standard"
              helperText={store.errorname}
              error={store.errorname !== ""}
              type="text"
              value={store.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                store.changeName(e.target.value)
              }}
            />}


            {store.stepperValue === 1 && <FileInput
              errorText={store.errorfile}
              onFileSelect={(file) => {
                store.changeFile(file)
              }}
              label={"Company logo (Optional)"}
              secondaryLabel={"Only .jpg and .png formats with max file size of 1MB accepted"}
            />}

            <Box style={{ marginTop: 40 }}>
              <Button disabled={store.errorname !== "" || store.errorfile !== "" || store.disabledButton == true} variant="contained" fullWidth style={{ marginBottom: 10 }} onClick={() => {
                store.saveEntity(props)
              }}>{store.stepperValue === 1 ? "Create entity" : "Next"}</Button>
              <Button fullWidth onClick={() => {
                store.clearStore()
                props.onBtnCancelClick()
              }} variant="outlined">Cancel</Button>
            </Box>

          </Box>
        </Grid>

      </Grid>
    </Dialog>
  );
});

export default ModalCreateWorkspace;
