import { FC, ReactElement } from "react";
import storeSegments from "./../storeSegments";
import store from "./store";
import { observer } from "mobx-react";
import { TextField } from "@mui/material";
import CustomButton from "components/button/Button";
import styled from "styled-components";
import CustomModal from "components/modal/Modal";
import CombinePopup from "./combinePopup";
import { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateField } from "@mui/x-date-pickers/DateField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type EditSegmentNameProps = {};

const EditSegmentName: FC<EditSegmentNameProps> = observer((): ReactElement => {
  const handleHideAndCancelModal = () => {
    storeSegments.changeEditSegmentPanel(false);
    store.clearStore();
  };

  return (
    <CustomModal
      open={storeSegments.editSegmentPanel}
      hide={handleHideAndCancelModal}
      aria-label="Create workspace modal"
    >
      <CombinePopup />

      <MainBlock>
        <Header id="AttributesSegments_PopUpEdit_Title">Attribute</Header>

        {!store.attribute?.is_date_attribute && (
          <TextField
            value={store.name}
            variant="standard"
            placeholder="Attribute name"
            fullWidth
            error={store.errorName !== ""}
            helperText={store.errorName}
            onChange={(e) => store.changeName(e.target.value)}
            id="AttributesSegments_PopUpEdit_Input"
          />
        )}

        {store.attribute?.is_date_attribute && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledDateField
              id="AttributesSegments_PopUpEdit_Date"
              value={store.name}
              helperText={store.errorName}
              onChange={(newValue: Dayjs) => store.changeDate(newValue)}
            />
          </LocalizationProvider>
        )}

        <Actions>
          <ButtonWrapper>
            <CustomButton
              disabled={store.errorName !== ""}
              id="AttributesSegments_PopUpEdit_Save"
              onClick={() => store.checkNameUniqueness()}
              variant="contained"
            >
              Save
            </CustomButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <CustomButton
              id="AttributesSegments_PopUpEdit_Cansel"
              onClick={handleHideAndCancelModal}
              variant="contained"
              color="inherit"
            >
              Cancel
            </CustomButton>
          </ButtonWrapper>
        </Actions>
      </MainBlock>
    </CustomModal>
  );
});

const MainBlock = styled.div`
  margin: 70px;
`;

const StyledDateField = styled(DateField)`
  width: 100%;

  input {
    padding: 8px 14px !important;
  }
`;

const Header = styled.div`
  font-size: 26px;
  margin-bottom: 50px;
  font-weight: 500;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 10px;
`;

export default EditSegmentName;
