// import { Popover } from "@engagerocketco/nebula";
// import { PopoverProps } from "@engagerocketco/nebula/build/components/Popover";
import { ReactElement } from "react";
import Autocomplete, { AutocompleteProps } from "./Autocomplete";
import styled from "styled-components";
import Popover from '@mui/material/Popover';

const SearchContainer = styled.div`
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
  background-color: var(--colorNeutralBackground1);
  color: var(--colorNeutralForeground6);
  display: flex;
  height: 50px;
`;

const SearchIcon = styled.span`
  transform: rotate(90deg);
  padding: 16px;
`

const SearchInput = styled.input`
  all: unset;
  border: 0;
  color: var(--colorNeutralForeground3);
  padding: 0 16px 0 0;
`

const Separator = styled.hr`
  background-color: var(--colorNeutralBackground1);
  margin: 0 8px;
  border: 1px solid var(--colorNeutralStroke1);
`

const SuggestionList = styled.ul<{ $isOpen: boolean }>`
  background-color: var(--colorNeutralBackground1);
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 240px;
  overflow-y: auto;
`

const SuggestionItem = styled.li<{ $isActive: boolean }>`
  background-color: var(--colorNeutralBackground1);
  color: var(--colorNeutralForeground3);
  font-size: var(--fontSizeH6);
  padding: 12px 24px;
  line-height: 24px;
  cursor: pointer;

  &:hover {
    background-color: var(--colorPalleteLightBlue);
  }

  ${({ $isActive }): string => $isActive ? `
    background-color: var(--colorNeutralBackground2);
  ` : ""}
`

type PopoverAutocompleteProps = {
  autocompleteProps: AutocompleteProps
  popoverProps: any
}

const PopoverAutocomplete = ({
  autocompleteProps,
  popoverProps,
}: PopoverAutocompleteProps): ReactElement => {
  return (
    <Popover {...popoverProps}>
      <Autocomplete
        {...autocompleteProps}
        renderSearchInput={(props): ReactElement => (
          <>
            <SearchContainer>
              <SearchIcon className="material-icons-outlined">search</SearchIcon>
              <SearchInput {...props} placeholder="Type to search..." />
            </SearchContainer>
            <Separator />
          </>
        )}
        renderSuggestionList={({
          getItemProps,
          getMenuProps,
          highlightedIndex,
          items,
        }): ReactElement => (
          <SuggestionList {...getMenuProps()}>
            {items.map((item, index) => (
              <SuggestionItem
                key={item.label}
                $isActive={highlightedIndex === index}
                {...getItemProps({
                  index,
                  item,
                })}
              >
                {item.label}
              </SuggestionItem>
            ))}
          </SuggestionList>
        )}
      />
    </Popover>
  )
}

export default PopoverAutocomplete
