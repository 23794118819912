import { FC } from "react";
import { useNavigate } from "react-router";

import styled from "styled-components";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

type BackToMainLayoutHeaderProps = {};

const BackToMainLayoutHeader: FC<BackToMainLayoutHeaderProps> = () => {
  const navigate = useNavigate();
  return (
    <BackToMainLayoutHeaderWrapper>
      <BackButton onClick={() => navigate("/dashboard")}>
        <KeyboardBackspaceIcon />
      </BackButton>
      <BackToModule>Back to module</BackToModule>
    </BackToMainLayoutHeaderWrapper>
  );
};

export default BackToMainLayoutHeader;

const BackToMainLayoutHeaderWrapper = styled.div`
  width: 100%;
  height: 64px;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--colorPaletteVioletBackground1);
`;

const BackToModule = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralBackground1);
`;

const BackButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--colorBrandForeground1);
  border: none;
  margin-right: 8px;

  svg {
    fill: var(--colorNeutralBackground1);
    width: 16px;
  }
`;
