import { FC } from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react";
import { ExcelIcon } from "./ExcelIcon";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import CustomModal from "components/modal/Modal";
import CustomSwitch from "components/switch/Switch";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import storeEmployees from "features/Employees/store";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import DisconnectHrisPopup from "./DisconnectHrisPopup";
import { Link } from "react-router-dom";

type HrisAccordionProps = {};

const HrisAccordion: FC<HrisAccordionProps> = observer(() => {
  const disconnectHrisHandler = (): void => {
    store.setOpenDisconnectModal(false);
    storeEmployees.setHrisToggle(false);
    StoreLayout.setLockEdit(false);
  };

  const swithChange = (): void => {
    if (storeEmployees.hrisToggle) {
      store.setOpenDisconnectModal(true);
    } else {
      storeEmployees.connectHris();
    }
  };

  return (
    <Box>
      <CustomModal
        open={store.openDisconnectModal}
        hide={(flag) => store.setOpenDisconnectModal(flag)}
        children={
          <DisconnectHrisPopup
            disconnect={disconnectHrisHandler}
            closePopup={() => store.setOpenDisconnectModal(false)}
          />
        }
      />

      <AccordionStyle>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="EmployeesList_HrisEmployee_Panel"
        >
          {storeEmployees.curentEntityHris?.hris ? (
            <>
              <TypographyStyle id="EmployeesList_HrisEmployee_Panel_Title">
                HRIS:
                <StyledCustomSwitch checked={storeEmployees.hrisToggle} onChange={swithChange} />
                <p style={{ fontWeight: 400 }}>
                  {storeEmployees.curentEntityHris?.hris || "asdfadfa"}
                </p>
              </TypographyStyle>
            </>
          ) : (
            <>
              <TypographyStyle id="EmployeesList_HrisEmployee_Panel_Title">
                HRIS:
                <span>
                  {storeEmployees.hrisLinkClicked ? (
                    <p style={{ fontWeight: 400 }}>We wil be in touch</p>
                  ) : (
                    <StyledLink
                      id="EmployeesList_HrisEmployee_Panel_Link"
                      to=""
                      onClick={(e) => {
                        e.stopPropagation();
                        StorePermission.features.merge_dev.hasFeature &&
                          storeEmployees.hrisLinkNotified();
                      }}
                    >
                      Link to HRIS
                    </StyledLink>
                  )}
                </span>
              </TypographyStyle>
            </>
          )}
        </AccordionSummary>
        <AccordionDetails>
          {storeEmployees.curentEntityHris?.hris ? (
            <>
              {storeEmployees.hrisToggle ? (
                <TextStyle id="EmployeesList_HrisEmployee_Panel_Body">
                  Disable HRIS sync in order to make changes to the employee list.
                </TextStyle>
              ) : (
                <TextStyle id="EmployeesList_HrisEmployee_Panel_Body">
                  If an employee is on the HRIS, their attributes will be overwritten once
                  reconnected to HRIS. Employees not on HRIS will remain as-is.
                </TextStyle>
              )}
            </>
          ) : (
            <>
              {storeEmployees.hrisLinkClicked ? (
                <TextStyle id="EmployeesList_HrisEmployee_Panel_Body">
                  We have notified your Customer Success Manager of your request and get in touch
                  within 2-3 business days.
                </TextStyle>
              ) : (
                <TextStyle id="EmployeesList_HrisEmployee_Panel_Body">
                  We will notify your Customer Success Manager of your request and get in touch
                  within 2-3 business days.
                </TextStyle>
              )}
            </>
          )}

          <BoxExcelStyle>
            <LabelIconWrapper>
              <ExcelIcon id="EmployeesList_HrisEmployee_Panel_Employee" style={{ margin: 0 }} />
              <TextStyle
                style={{ color: "var(--colorNeutralForeground1)" }}
                id="EmployeesList_HrisEmployee_Panel_Employee_EmployeFormatText"
              >
                Employees (xlsx):
              </TextStyle>
            </LabelIconWrapper>
            <StyledLinkImport
              id="EmployeesList_HrisEmployee_Panel_Employee_ImportLink"
              to={!storeEmployees.hrisToggle ? "/employees-import" : ""}
              $active={storeEmployees.hrisToggle}
            >
              Import
            </StyledLinkImport>
            <TextStyle>|</TextStyle>
            <StyledLink
              id="EmployeesList_HrisEmployee_Panel_Employee_ExportLink"
              to=""
              onClick={() => store.exportExcel()}
            >
              Export
            </StyledLink>
          </BoxExcelStyle>
        </AccordionDetails>
      </AccordionStyle>
    </Box>
  );
});

const AccordionStyle = styled(Accordion)`
  width: 550px;
  margin: 20px 0;
  box-shadow: none !important;
  border: 1px solid var(--colorPaletteBlueBackground1) !important;
  &:first-of-type {
    margin-top: 10px !important;
  }
`;

const TypographyStyle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorNeutralForeground2);

  display: flex;
  align-items: center;
  gap: 16px;
`;

const TextStyle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const StyledLink = styled(Link)`
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  color: var(--colorBrandForeground1) !important;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledLinkImport = styled(Link)<{ $active?: boolean }>`
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  color: ${(props) =>
    props.$active ? "var(--colorBrandForeground2)" : "var(--colorBrandForeground1)"} !important;
  pointer-events: ${(props) => (props.$active ? "none" : "auto")} !important;

  &:hover {
    text-decoration: underline;
  }
`;

const BoxExcelStyle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-top: 10px;
`;

const LabelIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledCustomSwitch = styled(CustomSwitch)`
  margin-left: 10px !important;
`;

export default HrisAccordion;
