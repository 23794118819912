import http from "../https";

export const changeRoleToUsers = (
  userIds: number[],
  idRole: number,
  oldRoleId: number,
  real_id: number,
  access_level_code: string
): Promise<any> => {
  if(http.isAPIGO){
    return http.postGO("/permission/role/ChangeRoleToUsers", {
      idRole: idRole - 0,
      userIds: userIds,
      oldRoleId: oldRoleId,
      real_id: real_id,
      access_level_code: access_level_code,
    });
  }else{
    return http.post("/role/ChangeRoleToUsers", {
      idRole: idRole,
      userIds: userIds,
      oldRoleId: oldRoleId,
      real_id: real_id,
      access_level_code: access_level_code,
    });
  }
};

export const changeRoleFromRoleId = (
  oldRoleId: number,
  idRole: number,
  real_id: number,
  access_level_code: string
): Promise<any> => {
  return http.post("/role/ChangeRoleFromRoleId", {
    idRole: idRole,
    oldRoleId: oldRoleId,
    real_id: real_id,
    access_level_code: access_level_code,
  });
};
