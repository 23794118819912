import http from "../https";

import { TDashboardGlobalFilter } from "types/dashboard";

export const getSurveyAttributeAsync = async (
  survey_id: number
): Promise<TDashboardGlobalFilter[]> => {
  const response = await http.get(
    `/dashboard/attributes`,
    {},
    {
      params: {
        survey_id,
      },
    }
  );

  if ([200, 201].includes(response.status)) {
    return response.data;
  }

  throw new Error(`Failed to fetch attributes (HTTP status code: ${response.status})`);
};
