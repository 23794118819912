import { FC, useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CustomButton from "components/button/Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import MillstoneSheduleTable from "./MillstoneSheduleTable";
import SchedulePopup from "./ShedulePopup";
import storeSurvey from "features/Survey/SurveyList/store";
import storeRecipients from "../Recipients/store";
import store from "./store";

type MillstoneRecipientSheduleScreenProps = {};

const MillstoneRecipientSheduleScreen: FC<MillstoneRecipientSheduleScreenProps> = observer(() => {
  const navigate = useNavigate();

  const query = useQuery();
  const surveyId = query.get("id") || "0";
  const idSurvey = Number(surveyId);
  const projectId = query.get("project_id") || "0";
  const idProject = Number(projectId);

  if (isNaN(idSurvey) || idSurvey === 0) {
    // if idSurvey not created
    if (isNaN(idProject) || idProject === 0) {
      window.location.href = "/projects";
    }
  }

  useEffect(() => {
    if (idSurvey) {
      store.setIdSurvey(idSurvey);
    } else {
      store.setIdProject(idProject);
    }
    store.doLoad();
  }, []);

  useEffect(() => {
    storeRecipients.getSurveyInformation(idSurvey);
  }, [idSurvey]);

  return (
    <Container>
      <SchedulePopup
        open={store.openEditSchedule}
        hide={(flag) => {
          store.onChangeOpenEditSchedule(false);
        }}
      />
      <Content>
        {localStorage.getItem("surveyAction") === "edit" && (
          <Box>
            <BackButton onClick={() => navigate("/projects?project_id=" + storeSurvey.project_id)}>
              {"< Back to project"}
            </BackButton>
          </Box>
        )}
        <HeaderWrapper>
          <Header>{storeRecipients.Survey?.name}</Header>
          <Box>
            <StyledSearchInput
              data-testid={`MilstoneRecepient_Serch_Container`}
              size="small"
              placeholder="Search for name/ email"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton data-testid={`MilstoneRecepient_Serch_Btn`} onClick={() => store.onSearchClicked()}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={store.searchNameEmail}
              onKeyDown={store.keyPress}
              onChange={(e) => store.setData("searchNameEmail", e.target.value)}
            />
          </Box>
        </HeaderWrapper>

        {store.resultSearchValue !== "" && (
          <ResultSearch>
            <SearchText data-testid={`MilstoneRecepient_Search_Value`}>
              "<SearchTextValue>{store.resultSearchValue}</SearchTextValue>" can be found in the
              following employees
            </SearchText>

            <RemoveSearch>
              <CustomButton
              data-testid={`MilstoneRecepient_Search_ValueRemove`}
                onClick={() => {
                  store.changeValue("resultSearchValue", "");
                  store.getMilestoneData();
                }}
              >
                Remove search
              </CustomButton>
            </RemoveSearch>
          </ResultSearch>
        )}

        <AfterHeaderWrapper>
          <Box>
            <Label data-testid={`MilstoneRecepient_Subtitle`}>Milestone Recipient schedule</Label>
            <ManageEntitiesutton 
            data-testid={`MilstoneRecepient_ManageEmployeesBtn`} 
            onClick={() => window.open("/employees", "_blank")}>
              Manage employees <OpenInNewIcon />
            </ManageEntitiesutton>
          </Box>
          <Box>
            <ExportButton data-testid={`MilstoneRecepient_Export_Btn`} onClick={() => store.exportRecepientsExcel()} variant="outlined">
              Export all recipients [.xlsx]
            </ExportButton>
            <AddButton
              data-testid={`MilstoneRecepient_EditBtn`}
              variant="outlined"
              onClick={() => {
                navigate("/recipients?id=" + store.surveyId);
              }}
            >
              Edit recipients
            </AddButton>
          </Box>
        </AfterHeaderWrapper>
        <MillstoneSheduleTable />
      </Content>
    </Container>
  );
});

export default MillstoneRecipientSheduleScreen;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResultSearch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--colorPaletteBlueBackground3);
  margin-top: 24px;
  height: 50px;
`;

const SearchText = styled.div`
  margin-left: 16px;
`;
const SearchTextValue = styled.span`
  font-weight: 500;
`;

const RemoveSearch = styled.div`
  margin-right: 24px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1380px;
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

const BackButton = styled(CustomButton)`
  text-decoration: underline !important;
  color: var(--colorNeutralForeground2) !important;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
`;

const Header = styled.h1`
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  color: var(--colorNeutralForeground1);
  margin: 0;
  padding: 5px 0px 8px 0px;
`;

const StyledSearchInput = styled(TextField)`
  width: 100% !important;
  max-width: 246px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 4px !important;
  margin: 0px !important;

  div {
    border-radius: 4px !important;
  }

  input {
    padding: 8px 11px !important;
    border-radius: 4px !important;
    font-family: Roboto;
    font-size: 14px;
  }

  svg {
    font-size: 18px;
    color: var(--colorNeutralForeground6);
  }
`;

const AfterHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 13px;
`;

const ExportButton = styled(CustomButton)`
  height: 34px !important;
  border: 1px solid var(--colorNeutralForeground6) !important;
  margin-right: 20px !important;
`;

const AddButton = styled(CustomButton)`
  height: 34px !important;
`;

const ManageEntitiesutton = styled(CustomButton)`
  padding: 0px !important;
  margin: 0px 0px -2px 12px !important;
  white-space: nowrap;

  svg {
    margin-left: 4px;
    font-size: 18px;
  }

  &:hover {
    background-color: transparent !important;
    text-decoration: underline !important;
  }
`;

const Label = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
  text-transform: uppercase;
`;
