import http from "../https";
import { AxiosResponse } from "axios";

class UnexpectedApiError extends Error {
  constructor(response: AxiosResponse<any, any>) {
    super();
  }
}

export const editSegmentName = async (
  attributeId: number,
  oldName: string,
  newName: string,
): Promise<any> => {
  const data = {
    attribute_id: attributeId,
    old_name: oldName,
    new_name: newName,
  };
  if (http.isAPIGO) {
    const response = await http.postGO(`/employee/entity_attributes/EditSegmentName`, data);
    if ([200, 201].includes(response.status)) {
      return response.data;
    }
    throw new UnexpectedApiError(response);
  } else {
    const response = await http.post(`/entity_attributes/EditSegmentName`, data);
    if ([200, 201].includes(response.status)) {
      return response.data;
    }
    throw new UnexpectedApiError(response);
  }
};
