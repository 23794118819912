import http from "api/https";


export const getImpersonatedInfo = (): Promise<any> => {
  try {
    if (http.isAPIGO) {
      return http.getGO("/permission/ImpersonationInfo");
    } else {
      return http.get('/employees/ImpersonationInfo');
    }
  } catch (error) {
    throw error;
  }
};
