const LetterIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 0.667969H1.33333C0.6 0.667969 0.00666667 1.26797 0.00666667 2.0013L0 10.0013C0 10.7346 0.6 11.3346 1.33333 11.3346H12C12.7333 11.3346 13.3333 10.7346 13.3333 10.0013V2.0013C13.3333 1.26797 12.7333 0.667969 12 0.667969ZM11.9948 3.33724L6.66146 6.67057L1.32812 3.33724V2.00391L6.66146 5.33724L11.9948 2.00391V3.33724Z"
        fill="#1477F8"
      />
    </svg>
  );
};

export default LetterIcon;
