import http from "api/https";
import { createUrl } from "../../helpers/url";
import { AxiosResponse } from "axios";

class UnexpectedApiError extends Error {
  constructor(response: AxiosResponse<any, any>) {
    super();
  }
}

export const checkUniqueSegmentNameInAttriubue = async (
  attributeId: number,
  name: string,
): Promise<any> => {
  const query: { name: string; attribute_id: string } = {
    name: name,
    attribute_id: attributeId.toString(),
  };
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/employee/entity_attributes/CheckUniqueSegmentNameInAttriubue",
      query: query,
    });
    const response = await http.getGO(url);
    if ([200, 201].includes(response.status)) {
      return response.data;
    }
    throw new UnexpectedApiError(response);
  } else {
    const url = createUrl({
      path: "/entity_attributes/CheckUniqueSegmentNameInAttriubue",
      query: query,
    });
    const response = await http.get(url);
    if ([200, 201].includes(response.status)) {
      return response.data;
    }
    throw new UnexpectedApiError(response);
  }
};
