import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { Button, Dialog, DialogContent, TextField } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import ErrorIcon from "@mui/icons-material/Error";

type EditTopicPopupProps = {
  localFilters: { id: number; name: string; segments: string[] }[];
};

const EditTopicPopup: FC<EditTopicPopupProps> = observer((props) => {
  const [topic, setTopic] = useState<string>("");
  const [isShowWarning, setIsShowWarning] = useState<boolean>(false);

  useEffect(() => {
    setTopic(store.activeOpenEndedTopic && store.activeOpenEndedTopic?.Title);
  }, [store.activeOpenEndedTopic, store.isOpenEditTopicPopup]);

  useEffect(() => {
    if (
      store.openEndedTopicsList.find(
        (el) => el.Title?.toLocaleLowerCase() === topic?.toLocaleLowerCase()
      ) &&
      topic?.toLocaleLowerCase() !== store.activeOpenEndedTopic?.Title?.toLocaleLowerCase()
    ) {
      setIsShowWarning(true);
    } else setIsShowWarning(false);
  }, [topic]);

  const renameTopic = () => {
    store.editTopicName(topic, props.localFilters);
  };

  return (
    <StyledDialog open={store.isOpenEditTopicPopup}>
      <DialogTitle>
        Edit topic for all responses under ‘{store.activeOpenEndedTopic?.Title}’
      </DialogTitle>
      <StyledDialogContent>
        <TextFieldStyled
          variant="standard"
          fullWidth
          defaultValue={store.activeOpenEndedTopic?.Title}
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
        />
        {isShowWarning && (
          <Warning>
            <ErrorIcon />
            <WarningText>
              You already have a topic with the same name. By saving changes, responses previously
              under ‘{store.activeOpenEndedTopic?.Title}’ will be merged with ‘{topic}’.
            </WarningText>
          </Warning>
        )}
      </StyledDialogContent>
      <ButtonWrapper>
        <SaveButton
          onClick={renameTopic}
          variant="contained"
          type="submit"
          disabled={
            Boolean(!topic) ||
            topic?.toLocaleLowerCase() === store.activeOpenEndedTopic?.Title?.toLocaleLowerCase()
          }
        >
          Save changes
        </SaveButton>
        <CancelButton
          variant="outlined"
          onClick={() => {
            store.setData(false, "isOpenEditTopicPopup");
          }}
        >
          Cancel
        </CancelButton>
      </ButtonWrapper>
    </StyledDialog>
  );
});

export default EditTopicPopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 560px !important;
    overflow: hidden !important;
    border-radius: 10px !important;
  }

  .MuiDialogContent-root {
    padding: 0px 64px 64px 64px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const DialogTitle = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px 0px 16px 0px;
  padding: 48px 64px 24px 64px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0px 64px 48px 64px;
`;

const SaveButton = styled(Button)`
  height: 36px;
  width: 100%;
  max-width: 130px !important;
  text-transform: none !important;
`;

const CancelButton = styled(Button)`
  height: 36px;
  margin-left: 20px !important;
  border-color: var(--colorNeutralForeground5) !important;
  color: var(--colorNeutralForeground2) !important;
  text-transform: none !important;
`;

const TextFieldStyled = styled(TextField)`
  color: var(--colorNeutralBackground3) !important;
  width: 484px;
`;

const Warning = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 20px 1fr;
  padding: 18px;
  margin-top: 16px;
  background-color: var(--colorPalleteLightBlue);
  column-gap: 10px;

  svg {
    color: var(--colorPaletteYellowForeground1);
    margin-right: 12px;
  }
`;

const WarningText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;
