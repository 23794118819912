import { EmployeeAttribute } from "types/employee";
import http from "../https";

export const addOrUpdateAttribute = (data: EmployeeAttribute): Promise<any> => {
  if (http.isAPIGO) {
    if (typeof data.id === "number") {
      return http.putGO("/employee/entity_attributes", data);
    } else {
      return http.postGO("/employee/entity_attributes", data);
    }
  } else {
    return http.post("/entity_attributes/AddOrUpdate", data);
  }
};
