import { FC } from "react";
import ListTemplates from '../../features/Templates/ListTemplates'

type TemplatesPageProps = {};

const TemplatesPage: FC<TemplatesPageProps> = () => {
  return <div>
    <ListTemplates />
  </div>;
};

export default TemplatesPage;
