import { FC } from "react";

import styled from "styled-components";
import ErrorIcon from "@mui/icons-material/Error";

type EmptyTrendCardProps = {
  isHaveFilters?: boolean;
  isMissingQuestions?: boolean;
  isOnlyOpenendQns?: boolean;
};

const EmptyTrendCard: FC<EmptyTrendCardProps> = (props) => {
  const returnLabel = () => {
    if (props.isOnlyOpenendQns)
      return `Trend results will be available for this project when surveys incorporate questions beyond open-ended questions.`;
    if (props.isHaveFilters)
      return `This chart cannot be shown due to the filters applied. Try removing some filters to see if
    the chart would show.`;
    if (props.isMissingQuestions) return `This chart cannot be displayed due to missing questions.`;
    return `This chart cannot be displayed due to missing data.`;
  };

  return (
    <Container>
      <EmptyLabelWrapper $isHaveFilters={props.isHaveFilters}>
        <ErrorIcon />
        <EmptyLabelText>{returnLabel()}</EmptyLabelText>
      </EmptyLabelWrapper>
    </Container>
  );
};

export default EmptyTrendCard;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 280px;
`;

const EmptyLabelWrapper = styled.div<{ $isHaveFilters: boolean }>`
  width: 100%;
  max-width: 398px;
  padding: 17px;
  display: grid;
  grid-template-columns: 20px 1fr;
  column-gap: 8px;
  background-color: var(--colorPalleteLightBlue);
  align-items: ${(props) => (props.$isHaveFilters ? "start" : "center")};

  svg {
    color: var(--colorPaletteYellowForeground1);
    margin-right: 12px;
  }
`;

const EmptyLabelText = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
`;
