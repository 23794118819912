import * as React from 'react';
import store from './store'
import { Box, Grid } from '@mui/material';
import { observer } from 'mobx-react';
import TreeView from './TreeView';
import styled from "styled-components";
import UnitHeads from './unitHeads';
import { ExcelIcon } from "../Employee/ListEmployee/ExcelIcon";
import Link from "@mui/joy/Link";
import NavStore from "./../store";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";



const CustomizedTreeView = observer(() => {

  React.useEffect(() => {
    store.doLoad()
    return () => {
      store.clearStore()
    }
  }, [])


  return (
    <Box sx={{ mb: 3 }}>
      <Grid container>
        <Grid item md={12} lg={8} xl={6}>
          <Header id={`Employees_OrganizationalStructure_Title`}>
            Setting up organizational structure
          </Header>
          {store.levelStructure && (
            <BoxStyled>
              <BoxExcelStyle>
                <ExcelIcon style={{ marginTop: 0 }} />{" "}
                <p id={`Employees_OrganizationalStructure_ExelTitle`} style={{ margin: "0 10px 0 0" }}>Employees (xlsx):</p>
                <BoxStyledLink>
                  <LinkStyled
                  id={`Employees_OrganizationalStructure_Export_Link`}
                    href="#basics"
                    onClick={() =>
                      store.saveExcelStructure()
                    }
                  >
                    Export
                  </LinkStyled>
                </BoxStyledLink>
              </BoxExcelStyle>
            </BoxStyled>
          )}
          {NavStore.importLock && <TemplateWrapper data-testid='StructureList_ImportEmployee_Information'>
              Employee List is being updated at the moment. Certain functionalities will not be available until the update is complete.
          </TemplateWrapper>}
          <Box sx={{ mt: 2, mb: 20 }}>
            <TreeView />
          </Box>
        </Grid>
        <Grid item md={12} lg={4} xl={6}>
          <UnitHeads />
        </Grid>
      </Grid>
    </Box>
  );
})

const Header = styled.h1`
  font-family: Roboto;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const BoxExcelStyle = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px 0px 0px;
`;
const BoxStyled = styled(Box)`
display: flex;
justify-content: end;
max-width: 800px;
`
const BoxStyledLink = styled(Box)`
display: flex;
alignItems: center;
gap: 2;
flex-wrap: wrap;
margin: 0 10px 0 0;
font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 14px;
`
const LinkStyled = styled(Link)`
color: #1976d2; 
fontSize: 14px;
font-family: Roboto;
font-weight: 500;
`

const TemplateWrapper = styled.div`
  width: 100%;
  padding: 20px 30px;
  background-color: var(--colorNeutralBackground1);
  border-radius: 4px;
  border: 1px solid var(--colorNeutralForeground4);
  display: flex;
  flex-direction: column;
`;

export default CustomizedTreeView