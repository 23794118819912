import { FC, useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { DialogTitle, DialogContent } from "@mui/material";

import store from "./store";
import { useLocation } from "react-router";
import CustomButton from "components/button/Button";
import { Autocomplete as AutocompleteComponent } from "components/autocomplete-tags/Autocomplete";

type editModalPropsType = {};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const QuestionModal: FC<editModalPropsType> = observer((props) => {
  let query = useQuery();
  let idTemplate = query.get("id") ? query.get("id") : "0";

  useEffect(() => {
    store.loadTags();
    store.templateId = +idTemplate;
  }, []);

  return (
    <StyledModalContent aria-label={"Create workspace form"}>
      <StyledDialogTitle id="titleId">Survey settings</StyledDialogTitle>

      <AutocompleteComponent
        data={store.tags}
        selected={store.selectedTagNames}
        onChange={(value: string[]) => store.changeSelectedTagName(value)}
        onRemove={(index: number) =>
          store.changeSelectedTagName(store.selectedTagNames.filter((_, i: number) => index !== i))
        }
      />

      <ButtonWrapper>
        <StyledNextButton
          variant="contained"
          onClick={() =>
            store.onSave((id) => (window.location.href = `/questionnaire-template?id=${id}`))
          }
        >
          Save changes
        </StyledNextButton>
      </ButtonWrapper>
    </StyledModalContent>
  );
});

const StyledModalContent = styled(DialogContent)`
  padding: 0 !important;
`;

const StyledDialogTitle = styled(DialogTitle)`
  padding: 20px 0 0 !important;
`;

const StyledNextButton = styled(CustomButton)`
  width: 180px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  text-align: center;
`;

export default QuestionModal;
