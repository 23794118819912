import http from "api/https";

export const ChangeAccessCommTemplate = async (
  access_code: string,
  template_id: number,
) => {
  let data = {
    template_id: template_id,
    access_code: access_code
  }
  
  return http.post("/templ_template_comms/ChangeAccessCommTemplate", data, );
};
