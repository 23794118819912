import { FC } from "react";
import { observer } from "mobx-react";
import { Pagination } from "@mui/material";

import store from "./store";
import styled from "styled-components";

type RecipientsTableProps = {};

const RecipientsTable: FC<RecipientsTableProps> = observer(() => {
  const returnBottomPaginationValue = () => {
    if (store.requestData.activePage === 1) return "1";
    else return (store.requestData.activePage - 1) * 10;
  };

  const returnTopPaginationValue = () => {
    if (store.requestData.activePage === store.requestData.totalPages)
      return store.requestData.totalItemsCount;
    else if (store.requestData.activePage === 1) return "10";
    else return store.requestData.activePage * 10;
  };

  return (
    <Container>
      <Card>
        <CardTitleRow>
          <CardTitle>FULL NAME & EMAIL</CardTitle>
        </CardTitleRow>
        {store.recipientsList && store.recipientsList.length > 0 ? (
          store.recipientsList.map((recipient, index, array) => (
            <RecipientRow key={index + recipient.email} $last={Boolean(index === array.length - 1)}>
              <NameRow>
                <RecipientName $isFirst>
                  {recipient.preferred || "Preferred name not set"}{" "}
                </RecipientName>
                <RecipientName>({recipient.name || "Name not set"})</RecipientName>
              </NameRow>
              <RecipientEmail>{recipient.email}</RecipientEmail>
            </RecipientRow>
          ))
        ) : (
          <EmptyList>
            <EmptyText>List is empty</EmptyText>
          </EmptyList>
        )}
      </Card>
      <Paginator>
        <PaginationText>
          Viewing {returnBottomPaginationValue()} - {returnTopPaginationValue()} of{" "}
          {store.requestData.totalItemsCount}
        </PaginationText>
        <Pagination
          variant="outlined"
          shape="rounded"
          page={store.requestData.activePage}
          count={store.requestData.totalPages}
          onChange={(e, page) =>
            store.setData("requestData", { ...store.requestData, activePage: page })
          }
        />
      </Paginator>
    </Container>
  );
});

export default RecipientsTable;

const Container = styled.div`
  width: 100%;
  min-width: 461px;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  margin: 26px 25px 0px 0px;
`;

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--colorNeutralBackground1);
  border: 1px solid var(--colorNeutralBackground5);
  border-radius: 8px;
`;

const PaginationText = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.73px;
  color: var(--colorNeutralForeground2);
  text-transform: uppercase;
`;

const CardTitleRow = styled.div`
  width: 100%;
  padding: 21px 32px 11px 32px;
`;

const CardTitle = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
  text-transform: uppercase;
`;

const RecipientRow = styled.div<{ $last?: boolean }>`
  width: 100%;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  padding: 8px 32px;
  ${(props) => !props.$last && `border-bottom: 1px solid var(--colorNeutralForeground4)`};
`;

const RecipientName = styled.p<{ $isFirst?: boolean }>`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  ${(props) => props.$isFirst && "margin-right: 4px"};
`;

const RecipientEmail = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
`;

const NameRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Paginator = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .MuiButtonBase-root {
    margin: 0;
    width: 36px;
    height: 36px;
    border: 1px solid var(--colorNeutralForeground4);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--colorNeutralForeground2);
    background-color: var(--colorNeutralBackground1);
    border-radius: 0;
  }

  .Mui-selected {
    background-color: var(--colorPaletteGrayBackground1) !important;
  }
`;

const EmptyList = styled.div`
  width: 100%;
  min-height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;
