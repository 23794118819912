import { observer } from "mobx-react";
import { TEnpsTableItem } from "types/dashboard";
import { FC, useEffect, useState } from "react";
import { DETRACTORS, PASSIVES, PROMOTERS } from "constants/dashboard-overview";

import store from "../../store";
import styled from "styled-components";

type EnpsTableProps = {};

const EnpsTable: FC<EnpsTableProps> = observer(() => {
  const { isENPSEmpty } = store;
  const [activeTab, setActiveTab] = useState<string>(PROMOTERS);
  const [tableData, setTableData] = useState<TEnpsTableItem[]>([]);

  useEffect(() => {
    if (activeTab && store.projectInfo && store.projectInfo.enps) {
      const filteredData = store.projectInfo.enps?.categories.filter(
        (item) => item.name === activeTab
      );
      setTableData(filteredData);
    }
  }, [store.projectInfo, activeTab]);

  if (isENPSEmpty) {
    return null;
  }

  return (
    <Container>
      <TabsList>
        <TabItem $active={Boolean(activeTab === PROMOTERS)} onClick={() => setActiveTab(PROMOTERS)}>
          Promoters
        </TabItem>
        <TabItem $active={Boolean(activeTab === PASSIVES)} onClick={() => setActiveTab(PASSIVES)}>
          Passives
        </TabItem>
        <TabItem
          $last={true}
          $active={Boolean(activeTab === DETRACTORS)}
          onClick={() => setActiveTab(DETRACTORS)}
        >
          Detractors
        </TabItem>
      </TabsList>
      <Content>
        <ContentBox $left>
          <TopRow>
            <TopRowText>Top 3</TopRowText>
            <StyledIcon src="/assets/green-round-like.png" />
          </TopRow>
          <ContentRow>
            <Label>{(tableData[0] && tableData[0].top_dimensions[0]?.name) || "-"}</Label>
            {tableData[0] && typeof tableData[0].top_dimensions[0]?.percent === "number" && (
              <Text>{tableData[0].top_dimensions[0]?.percent}%</Text>
            )}
          </ContentRow>
          <ContentRow>
            <Label>{(tableData[0] && tableData[0].top_dimensions[1]?.name) || "-"}</Label>

            {tableData[0] && typeof tableData[0].top_dimensions[1]?.percent === "number" && (
              <Text>{tableData[0].top_dimensions[1]?.percent}%</Text>
            )}
          </ContentRow>
          <ContentRow>
            <Label>{(tableData[0] && tableData[0].top_dimensions[2]?.name) || "-"}</Label>
            {tableData[0] && typeof tableData[0].top_dimensions[2]?.percent === "number" && (
              <Text>{tableData[0].top_dimensions[2]?.percent}%</Text>
            )}
          </ContentRow>
        </ContentBox>
        <ContentBox>
          <TopRow>
            <TopRowText>Bottom 3</TopRowText>
            <StyledIcon src="/assets/red-round-warning.png" />
          </TopRow>
          <ContentRow>
            <Label>{(tableData[0] && tableData[0].bottom_dimensions[0]?.name) || "-"}</Label>
            {tableData[0] && typeof tableData[0].bottom_dimensions[0]?.percent === "number" && (
              <Text>{tableData[0].bottom_dimensions[0]?.percent}%</Text>
            )}
          </ContentRow>
          <ContentRow>
            <Label>{(tableData[0] && tableData[0].bottom_dimensions[1]?.name) || "-"}</Label>

            {tableData[0] && typeof tableData[0].bottom_dimensions[1]?.percent === "number" && (
              <Text>{tableData[0].bottom_dimensions[1]?.percent}%</Text>
            )}
          </ContentRow>
          <ContentRow>
            <Label>{(tableData[0] && tableData[0].bottom_dimensions[2]?.name) || "-"}</Label>
            {tableData[0] && typeof tableData[0].bottom_dimensions[2]?.percent === "number" && (
              <Text>{tableData[0].bottom_dimensions[2]?.percent}%</Text>
            )}
          </ContentRow>
        </ContentBox>
      </Content>
    </Container>
  );
});

export default EnpsTable;

const Container = styled.div`
  width: 50%;

  @media (max-width: 1440px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const TabsList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const TabItem = styled.span<{ $active: boolean; $last?: boolean }>`
  padding: 8px 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: ${(props) =>
    props.$active ? `var(--colorBrandForeground1)` : `var(--colorNeutralForeground2)`};
  background-color: ${(props) =>
    props.$active ? `var(--colorNeutralBackground1)` : `var(--colorNeutralForeground4)`};
  cursor: pointer;
  border: 1px solid var(--colorPaletteBlueBackground1);
  border-bottom: none;
  border-radius: 8px 8px 0px 0px;
  margin-right: ${(props) => (props.$last ? `0px` : "8px")};
  transition: all 0.3s;
`;

const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 24px 16px;
  border: 1px solid var(--colorPaletteBlueBackground1);
  height: calc(100% - 37px);
  column-gap: 12px;
`;

const ContentBox = styled.div<{ $left?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${(props) => props.$left && `border-right: 1px solid var(--colorPaletteBlueBackground1)`};
  padding-right: ${(props) => props.$left && "12px"};
`;

const ContentRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-right: 8px;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
`;

const TopRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
`;

const TopRowText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
  margin-right: 8px;
`;

const StyledIcon = styled.img`
  width: 26px;
  height: 26px;
`;
