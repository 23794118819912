import { FC, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import styled from "styled-components";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import store from "./store";
import structureStore from "./Structure/store";
import ActiveSeats from "./ActiveSeats";
import { AttributeListIcon } from "./AttributeList/AttributeListIcon";
import { EmployeeListIcon } from "./EmployeeListIcon";
import { EMPLOYEES_TABS } from "../../constants/menu";
import { ORG_STRUCTURE_CODES } from "constants/entityManagementTypes";

type LeftNavigationBarProps = {
  changeTab: (tab: string) => void;
  activeTab: string;
};

const LeftNavigationBar: FC<LeftNavigationBarProps> = observer((props) => {
  const navigate = useNavigate();

  useEffect(() => {
    structureStore.getLevelStructure();
  }, []);

  const activeVerification =
    (store.saved_org_structure_code === ORG_STRUCTURE_CODES.unit_based &&
      !structureStore.levelStructure) ||
    !store.curentEntityOrgType;

  const handleNavigation = (e: React.MouseEvent<HTMLAnchorElement>, path: string) => {
    e.preventDefault();
    if (activeVerification) {
      return;
    }
    navigate(path);
  };

  return (
    <LeftNavigationBarWrapper>
      <MenuItem
        id="Eployees_navigate_employeeList"
        to="/employees?nav=employeeList"
        onClick={(e) => handleNavigation(e, "/employees?nav=employeeList")}
        $active={Boolean(props.activeTab === EMPLOYEES_TABS.employeeList)}
        $disabled={activeVerification}
      >
        <EmployeeListIcon />
        Employee List
      </MenuItem>
      <MenuItem
        id="Eployees_navigate_attributeList"
        to="/employees?nav=attributeList"
        onClick={(e) => handleNavigation(e, "/employees?nav=attributeList")}
        $active={Boolean(props.activeTab === EMPLOYEES_TABS.attributeList)}
        $disabled={activeVerification}
      >
        <AttributeListIcon />
        Attribute List
      </MenuItem>
      {store.saved_org_structure_code === ORG_STRUCTURE_CODES.unit_based && (
        <MenuItem
          data-testid='Eployees_navigate_OrganizationalStructure'
          to="/employees?nav=structure"
          $active={Boolean(props.activeTab === EMPLOYEES_TABS.structureList)}
        >
          <AccountTreeIcon fontSize="small" />
          Organizational structure
        </MenuItem>
      )}

      <ActiveSeats />
    </LeftNavigationBarWrapper>
  );
});

export default LeftNavigationBar;

const getColor = (props: { $disabled?: boolean; $active?: boolean }) => {
  if (props.$disabled) {
    return "var(--colorNeutralForeground9)";
  }
  if (props.$active) {
    return "var(--colorBrandForeground1)";
  }
  return "var(--colorNeutralForeground1)";
};

const LeftNavigationBarWrapper = styled.div`
  width: 100%;
  max-width: 202px;
  height: 366px;
  background-color: var(--colorNeutralBackground1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 8px 8px 0;
  padding: 20px 0;
`;

const MenuItem = styled(RouterLink)<{ $active?: boolean; $disabled?: boolean }>`
  width: 100%;
  padding: 12px 25px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  border-left: ${(props) =>
    props.$active && !props.$disabled
      ? "6px solid var(--colorBrandForeground1)"
      : "6px solid transparent"};

  color: ${(props) => getColor(props)};

  svg {
    margin-right: 8px;
    path {
      fill: ${(props) => getColor(props)};
    }
  }
`;
