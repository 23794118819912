import { FC, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";

import store from "./../../store";
import Lookup from "./LookUp";
import PreviewIcon from "../../PreviewIcon";
import { ReminderMessage } from "types/templates";
import CustomButton from "components/button/Button";
import RichTextWithTabsAll from "../../RichTextWithTabs";
import ChevronCircleIcon from "features/Survey/Recipients/ChevronCircleIcon";
import { DeleteIcon } from "features/Employees/AttributeList/components/DeleteIcon";

type ReminderEditProps = {
  reminder: ReminderMessage;
  index: number;
};

const ReminderEdit: FC<ReminderEditProps> = observer((props) => {
  const [error, setError] = useState<boolean>(false);

  const timeVerification = props.reminder.time_send_reminder
    ? dayjs(props.reminder.time_send_reminder)
    : null;

  return (
    <Wrapped>
      <TopOfEdit>
        <TopLeft>
          <ReminderMessageTop id={"Template_Communication_Schedule_Reminder_Edit_Text"}>
            Reminder email {props.index + 1}
          </ReminderMessageTop>
          <LinkPreview
            id={"Template_Communication_Schedule_Reminder_Edit_Remove_Button"}
            onClick={() => store.DeleteReminderSubmit(props.index)}
          >
            <DeleteIcon />
            Delete
          </LinkPreview>
        </TopLeft>
        <TopRight>
          <LinkPreview
            id={"Template_Communication_Schedule_Reminder_Edit_Close_Button"}
            onClick={() => store.changeOpenReminderIndex(props.index, false)}
          >
            Hide
            <ChevronCircleIcon style={{ marginLeft: 8 }} />
          </LinkPreview>
        </TopRight>
      </TopOfEdit>

      <SubHeader style={{ marginTop: 33 }}>RECIPIENTS FOR REMINDER</SubHeader>

      <LookUpWrapper>
        <Lookup
          id={"Template_Communication_Schedule_Reminder_Edit_Recipients_Input"}
          data={store.ReminderGroups}
          value={props.reminder.reminder_recipients_group_id}
          errorText=""
          error={false}
          onChange={(e) => store.changeReminderGroups(Number(e.target.value), props.index)}
        />
      </LookUpWrapper>

      <SubHeader
        id={"Template_Communication_Schedule_Reminder_Edit_Recipients_Label"}
        style={{ marginTop: 30 }}
      >
        SEND AFTER INVITE EMAIL
      </SubHeader>

      <SendAfter>
        <LookUpWrapper>
          <Lookup
            id={"Template_Communication_Schedule_Reminder_Edit_Reminder_Days_Input"}
            data={store.ReminderDays}
            value={props.reminder.reminder_days_id}
            errorText=""
            error={false}
            onChange={(e) => store.changeReminderDays(Number(e.target.value), props.index)}
          />
        </LookUpWrapper>
        <TimeSend>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimeField"]}>
              <WrappedTimeField
                value={timeVerification}
                closeOnSelect={false}
                slotProps={{
                  textField: {
                    placeholder: "Select time",
                    helperText:
                      error && !props.reminder.time_send_reminder && "This is a required field",
                    error: error && !props.reminder.time_send_reminder,
                  },
                }}
                onChange={(e) => store.changeReminderTime(e, props.index)}
              />
            </DemoContainer>
          </LocalizationProvider>
          <TimeZone id={"Template_Communication_Schedule_Reminder_Edit_LocalTimezone"}>
            Local timezone: UTC {store.timezone_message}
          </TimeZone>
        </TimeSend>
      </SendAfter>

      <SubHeader
        id={"Template_Communication_Schedule_Reminder_Edit_Subject_Header"}
        style={{ marginBottom: 16 }}
      >
        EMAIL SUBJECT
      </SubHeader>

      <EditorWrapper>
        <RichTextWithTabsAll
          onlyText
          data={props.reminder.reminder_message_languages}
          width={100}
          changeValue={(value, field, index) =>
            store.changeReminderData(value, field, props.index, index)
          }
          field="subject_email"
          variantTestId="reminder"
          boxId="Template_Communication_Schedule_Reminder_RichText"
          langNameId="Template_Communication_Schedule_Reminder_TabLabel"
          editorId="Template_Communication_Schedule_Reminder_Input"
        />
      </EditorWrapper>

      <SubHeader
        id={"Template_Communication_Schedule_Reminder_Edit_Content_Header"}
        style={{ margin: "40px 0 16px" }}
      >
        EMAIL CONTENT
      </SubHeader>

      <EditorWrapper>
        <RichTextWithTabsAll
          data={props.reminder.reminder_message_languages}
          width={200}
          changeValue={(value, field, index) =>
            store.changeReminderData(value, field, props.index, index)
          }
          field="body_email"
          variantTestId="reminder"
          boxId="Template_Communication_Schedule_Reminder_RichText"
          langNameId="Template_Communication_Schedule_Reminder_TabLabel"
          editorId="Template_Communication_Schedule_Reminder_Input"
        />
      </EditorWrapper>

      <BottomWrapper>
        <ButtonWrapper>
          <StyledNextButton
            id={"Template_Communication_Schedule_Reminder_Edit_Save_Button"}
            variant="contained"
            onClick={() => {
              if (!props.reminder.time_send_reminder) {
                setError(true);
              } else {
                store.SaveReminderData();
              }
            }}
          >
            Save changes
          </StyledNextButton>
          <StyledCancelButton
            id={"Template_Communication_Schedule_Reminder_Edit_Cancel_Button"}
            variant="text"
            onClick={() => store.changeOpenReminderIndex(props.index, false)}
          >
            Cancel
          </StyledCancelButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <LinkPreview
            id={"Template_Communication_Schedule_Reminder_Edit_PreviewEmail_Link"}
            onClick={() => store.LoadPreviewMessage(props.reminder.id_comms_reminder, false)}
          >
            <PreviewIcon />
            <LinkPreviewText>Preview email</LinkPreviewText>
          </LinkPreview>
        </ButtonWrapper>
      </BottomWrapper>
    </Wrapped>
  );
});

const Wrapped = styled.div`
  border: 1px solid var(--graySelectBoxBorder);
  min-height: 100px;
  border-radius: 5px;
  padding: 10px 15px;
`;

const BottomWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LinkPreviewText = styled.span`
  margin-left: 3px;
`;

const LinkPreview = styled.a`
  margin-left: 5px;
  color: var(--colorBrandForeground1);
  display: flex;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledCancelButton = styled(CustomButton)`
  color: var(--colorNeutralForeground2) !important;
`;

const StyledNextButton = styled(CustomButton)`
  width: 100%;
  margin-right: 20px;
  max-width: 150px;
`;

const TopOfEdit = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const TopLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const TopRight = styled.div``;

const EditorWrapper = styled.div``;

const ReminderMessageTop = styled.div``;

const SubHeader = styled.div`
  color: var(--colorNeutralForeground2);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
`;

const SendAfter = styled.div`
  display: flex;
  align-items: start;
  gap: 10px;

  .MuiStack-root {
    padding-top: 10px;
  }
`;

const LookUpWrapper = styled.div`
  padding-top: 10px;
  .MuiFormControl-root {
    margin: 0 !important;
  }
`;

const TimeSend = styled.div``;

const WrappedTimeField = styled(TimePicker)`
  .MuiOutlinedInput-root {
    margin-bottom: 5px;
  }
  input {
    padding: 11px;
  }
  p {
    margin: 0;
  }
`;

const TimeZone = styled.div`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
  color: var(--colorNeutralForeground5);
`;

export default ReminderEdit;
