import { Formik, Form } from "formik";
import { FC, ReactElement } from "react";

import styled from "styled-components";
import * as Yup from "yup";
import CustomModal from "../../../components/modal/Modal";
import CheckPassword from "../../../components/check-password/CheckPassword";
import Button from "../../../components/button/Button";
import CustomInput from "../../../components/input/Input";

const schema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Oops, your password does not meet our requirements."),
  newPassword: Yup.string()
    .required("Oops, your password does not meet our requirements.")
    .min(8, "Oops, your password does not meet our requirements.")
    .matches(
      /^(?=.*[a-z])/,
      "Oops, your password does not meet our requirements."
    )
    .matches(
      /^(?=.*[A-Z])/,
      "Oops, your password does not meet our requirements."
    )
    .matches(
      /^(?=.*[0-9])/,
      "Oops, your password does not meet our requirements."
    )
    .matches(
      /^(?=.*[!@#\$%\^&\*])/,
      "Oops, your password does not meet our requirements."
    ),
  reNewPassword: Yup.string()
    .required("Oops, your passwords do not match.")
    .oneOf([Yup.ref("newPassword")], "Oops, your passwords do not match."),
});

type ModalChnagePasswordProps = {
  open: boolean;
  hide: (state: boolean) => void;
};

const ModalChnagePassword: FC<ModalChnagePasswordProps> = (
  props
): ReactElement => {
  return (
    <CustomModal open={props.open} hide={props.hide}>
      <StyledModalContent aria-label={"Reset password form"}>
        <StyledModalTitle id="titleId">
          Change password for y.lang@consultancy.co
        </StyledModalTitle>
        <Formik
          initialValues={{ password: "", newPassword: "", reNewPassword: "" }}
          validationSchema={schema}
          onSubmit={() => {}}
        >
          {({ isValid, dirty }) => (
            <Form>
              <InputLabel>Current password</InputLabel>
              <StyledInput
                name="password"
                id="password"
                placeholder="Enter your current password"
                variant="standard"
                type="password"
                autoComplete="off"
              />

              <CheckPassword />

              <InputLabel>New password</InputLabel>
              <StyledInput
                name="newPassword"
                id="newPassword"
                placeholder="Enter new password"
                variant="standard"
                type="password"
                autoComplete="off"
              />
              <InputLabel>Confirm new password</InputLabel>
              <StyledInput
                name="reNewPassword"
                id="reNewPassword"
                placeholder="Enter new password again"
                variant="standard"
                type="password"
                autoComplete="off"
              />
              <StyledButton
                type="submit"
                // disabled={!dirty || !isValid}
                text=" Ok, I’m done"
                variant="contained"
              />
            </Form>
          )}
        </Formik>
      </StyledModalContent>
    </CustomModal>
  );
};

export default ModalChnagePassword;

const StyledModalTitle = styled.span`
  font-family: Roboto;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
`;

const StyledModalContent = styled.div``;

const StyledInput = styled(CustomInput)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 40px !important;
`;

const InputLabel = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
  padding: 24px 0px 10px 0px;
`;
