import { FC } from "react";

import styled from "styled-components";

type CheckPasswordProps = {};

const CheckPassword: FC<CheckPasswordProps> = () => {
  return (
    <CheckPasswordWrapper>
      <LabelHeader>Ensure that your password:</LabelHeader>
      <Text>
        {`Is at least 8 characters long and contains one Uppercase and lowercase
        letter (A, z), one numeric character (0-9) and one special character (!,
        %, @, #, etc.)`}
      </Text>
    </CheckPasswordWrapper>
  );
};

export default CheckPassword;

const CheckPasswordWrapper = styled.div`
  width: 100%;
  border: 1px solid var(--greenBorderColor);
  padding: 8px;
  margin: 32px 0px 0px 0px;
  background: var(--grenBackgroundColor);
  display: flex;
  flex-direction: column;
  cursor: default;
`;

const LabelHeader = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  margin-bottom: 6px;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;
