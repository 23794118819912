import { makeAutoObservable, reaction } from "mobx";
import { getOneEntity } from "../../../api/go/useGetOneEntity";
import { addOrEditEntity } from "../../../api/go/useAddOrEditEntity";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import MainStore from "MainStore";
import { TEntity } from "types/workspace";
import { checkEntityNameUnique } from "api/go/useCheckEntityNameUnique";

class Store {

  id = 0;
  name = "";
  errorname = "";
  file = null;
  errorfile = "";
  logo_url = "";
  workspaceId = 0;
  errorDelayCheckName: boolean = false;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.name,
      async (newName) => {
        if (newName === "") return;
        await this.delayedCheckNameUniqueness(newName);
      },
      { delay: 1000 }
    );
  }

  clearStore() {
    this.id = 0;
    this.name = "";
    this.errorname = "";
    this.file = null;
    this.errorfile = "";
    this.errorDelayCheckName = false;
    this.logo_url = "";
  }

  async doLoad(id: number) {
    try {
      MainStore.changeLoader(true);
      const response = await getOneEntity(id);
      if (response.status === 200 || response.status === 201) {
        this.setData(response.data)
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }
  
  private async delayedCheckNameUniqueness(newName: string) {
    try {
      const response = await checkEntityNameUnique(newName, this.workspaceId, this.id);
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        if (response.data.is_exists) {
          this.changeField("errorname", "There is an existing entity with the same name.");
        } else {
          this.changeField("errorname", "");
        }
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      this.changeField("errorDelayCheckName", false)
    }
  }

  changeField = (field: string, value: any) => {
    this[field] = value;
  }


  setData(data: TEntity) {
    this.name = data.name
    this.logo_url = data.logo_url
    this.id = data.id
    this.workspaceId = data.workspace_id
  }

  changeFile(value: any) {
    if (value == null) {
      if (this.errorfile != "") {
        this.errorfile = "";
      }
      this.logo_url = null;
      this.file = null;
      return;
    }
    if (value.size > 1000000) {
      this.errorfile = "Image not uploaded as it is larger than 1MB.";
    } else {
      this.file = value;
      if (this.errorfile != "") {
        this.errorfile = "";
      }
    }
  }

  changeName(value: string) {
    if (value == "") {
      this.errorname = "Field Required"
    } else {
      this.changeField("errorDelayCheckName", true)
      if (this.errorname != "") {
        this.errorname = ""
      }
    }
    this.name = value
  }

  async saveEntity(props: any) {
    var entity = {
      id: this.id,
      name: this.name,
      workspace_id: StoreLayout.currentWorkspaceId,
      file: this.file,
      logo_url: this.logo_url
    };

    try {
      MainStore.changeLoader(true);
      const response = await addOrEditEntity(entity);
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.clearStore();
        props.onSaved();
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }
}

const store = new Store();
export default store;
