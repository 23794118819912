import { makeAutoObservable } from "mobx";
import { getPreviousImports } from "api/useGetPreviousImports";
import MainStore from "MainStore";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import dayjs from "dayjs";
import { getTimeZone } from "utils/timeFormatter";

class Store {
  previousImports = [];

  constructor() {
    makeAutoObservable(this);
  }

  get formattedDate() {
    return this.previousImports.map(
      (x) =>
        dayjs(x.imported_at)
          .format("DD MMM YYYY [at] h:mm A")
          .toString() +
        ", UTC " +
        getTimeZone()
    );
  }

  loadPreviousImports = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getPreviousImports(StoreLayout.currentEntityId);
      if (response.status === 201 || response.status === 200) {
        this.previousImports = response.data || [];
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
}

const store = new Store();

export default store;
