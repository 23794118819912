import http from "api/https";

export const inviteUsersToRole = (
  idRole: number,
  real_id: number,
  emails: string[],
  access_level_code: string,
  region_id: number
): Promise<any> => {
  if (http.isAPIGO) {
    return http.postGO("/permission/role/InviteUsersToRole", {
      idRole: idRole,
      real_id: real_id,
      access_level_code: access_level_code,
      emails: emails,
      region_id: region_id,
    });
  } else {
    return http.post("/role/InviteUsersToRole", {
      idRole: idRole,
      real_id: real_id,
      access_level_code: access_level_code,
      emails: emails,
      region_id: region_id,
    });
  }
};
