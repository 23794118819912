import http from "api/https";
import { createUrl } from "../../helpers/url";

export const GetOneTemplateQuestion = async (id: number): Promise<any> => {
  const query: { id: string } = { id: id.toString() };

  if (http.isAPIGO) {
    const url = createUrl({
      path: "/template/get_templ_quest_with_tags",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/templ_template_questionary/GetWithTags",
      query: query,
    });
    return http.get(url);
  }
};
