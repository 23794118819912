import http from "api/https";
import { createUrl } from "../../helpers/url";

export const GetAttibutesByEntityAndEmployee = (
  entity_id: number,
  employee_id: number
): Promise<any> => {
  const query: { entity_id: string; employee_id: string } = {
    entity_id: entity_id.toString(),
    employee_id: employee_id.toString(),
  };
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/employee/entity_attributes/getByIdEntityAndIdEmployee",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/entity_attributes/getByIdEntityAndIdEmployee",
      query: query,
    });
    return http.get(url);
  }
};
