import { TGetDashboardResponcesOpenEndedTopicsAsyncResponse } from "types/dashboard";

import http from "../https";

type TRequestBody = {
    survey_id: number;
    attributes: {
        id: number;
        name: string;
        segments: string[];
    }[];
    question_id: number;
    benchmarkType?: number;
};
export const getDashboardResponcesOpenEndedTopicsAsync = async (
    data: TRequestBody
): Promise<TGetDashboardResponcesOpenEndedTopicsAsyncResponse> => {
    try {
        const response = await http.post(`/Dashboard/responces/openend/responces/topics`, data);

        if ([200, 201].includes(response.status)) {
            return response.data;
        }

        throw new Error(`Failed to fetch topics (HTTP status code: ${response.status})`);
    } catch (error) {
        console.error(error);
    }
};
