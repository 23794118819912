import http from "api/https";
import { createUrl } from "../../helpers/url";

export const GetOneEmployee = (id: number, entity_id: number): Promise<any> => {
  const query: { employee_id: string; entity_id: string } = {
    employee_id: id.toString(),
    entity_id: entity_id.toString(),
  };
  if (http.isAPIGO) {
    const url = createUrl({
      path: "/employee/employees/GetOneEmployeeWithRoles",
      query: query,
    });
    return http.getGO(url);
  } else {
    const url = createUrl({
      path: "/employees/GetOneEmployeeWithRoles",
      query: query,
    });
    return http.get(url);
  }
};
