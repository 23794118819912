import { observer } from "mobx-react";
import { MultipleSelect } from "components/multiselect/MultiSelect";
import { getUnitSegmentsName } from "utils/dashboard/useGetSegmentName";
import { DASHBOARD_MENU_VALUES } from "constants/dashboard-menu";
import { FC, useEffect, useMemo, useState } from "react";
import { TDasbboardFilter, TDashboardGlobalFilter } from "types/dashboard";
import { Autocomplete, Dialog, DialogContent, TextField } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import ClearIcon from "@mui/icons-material/Clear";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import CustomButton from "components/button/Button";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

type FiltersPopupProps = {
  open: boolean;
  handleHide: () => void;
  surveyId: number;
};

const FiltersPopup: FC<FiltersPopupProps> = observer((props) => {
  const [localFilters, setLocalFilters] = useState<TDasbboardFilter[]>([
    {
      unit_or_attr: null,
      segments: [],
    },
  ]);

  const closePopup = () => {
    setLocalFilters([
      {
        unit_or_attr: null,
        segments: [],
      },
    ]);
    props.handleHide();
  };

  useEffect(() => {
    props.open && setLocalFilters(store.filters);
  }, [props.open]);

  useEffect(() => {
    if (props.surveyId && store.activeTab !== DASHBOARD_MENU_VALUES.trends)
      store.getSurveyAttributes(props.surveyId);
    else if (StoreLayout.currentEntityId && store.activeTab === DASHBOARD_MENU_VALUES.trends)
      store.getProjectAttributes(store.selectedProject.id);
  }, [props.surveyId, store.selectedProject]);

  const addFilter = () => {
    if (
      localFilters[localFilters.length - 1].unit_or_attr &&
      localFilters[localFilters.length - 1].segments.length > 0
    ) {
      setLocalFilters((prev) => [
        ...prev,
        {
          unit_or_attr: null,
          segments: [],
        },
      ]);
    }
  };

  const removeFilter = (removeIndex: number) => {
    if (localFilters.length === 1) {
      setLocalFilters([
        {
          unit_or_attr: null,
          segments: [],
        },
      ]);
    } else {
      setLocalFilters((prev) => prev.filter((_, index) => index !== removeIndex));
    }
  };

  const changeUnitOrAttribute = (changeIndex: number, value: TDashboardGlobalFilter) => {
    setLocalFilters((prev) =>
      prev.map((filter, index) => {
        if (index === changeIndex) {
          return {
            unit_or_attr: value,
            segments: [],
          };
        } else return filter;
      })
    );
  };

  const changeSegments = (changeIndex: number, field: string, value: string | string[]) => {
    setLocalFilters((prev) =>
      prev.map((filter, index) => {
        if (index === changeIndex) {
          return { ...filter, [field]: value };
        } else return filter;
      })
    );
  };

  const filteringLocalListFilters = useMemo(() => {
    const filteredArray = store.filterAttributesList.filter(
      (filter) =>
        !localFilters.some((localFilter) => localFilter.unit_or_attr?.name === filter?.name)
    );
    return filteredArray;
  }, [localFilters, store.filterAttributesList]);

  const getSegmentsList = (filter: TDasbboardFilter) => {
    if (filter.unit_or_attr?.name === "Unit")
      return (
        filter.unit_or_attr?.values.map((el) => ({
          label: `${el?.name} (Level ${el.level})`,
          value: el.id.toString(),
        })) ?? []
      );

    return filter.unit_or_attr?.values.map((el) => el.value) ?? [];
  };

  const applyFilters = () => {
    store.changeFilters(localFilters);
    closePopup();
  };

  return (
    <StyledDialog open={props.open}>
      <CloseBtnWrapper>
        <CloseButton onClick={closePopup}>
          <ClearIcon />
        </CloseButton>
      </CloseBtnWrapper>
      <DialogTitle>Select filters</DialogTitle>

      <StyledDialogContent>
        {store.activeTab === DASHBOARD_MENU_VALUES.trends && (
          <TrendsLabel>
            <ErrorOutlineIcon />
            <TrendsLabelText>
              Filters on ‘Trend results’ are based on respondents within all surveys of a project
            </TrendsLabelText>
          </TrendsLabel>
        )}
        {localFilters.map((filter, index) => (
          <FilterColumn key={index}>
            {index !== 0 && <Divider>and</Divider>}
            <FilterRow>
              <StyledAutocomplete
                disablePortal
                id="select_unit_or_attribute"
                onChange={(_, value: TDashboardGlobalFilter) => changeUnitOrAttribute(index, value)}
                value={filter.unit_or_attr}
                options={filteringLocalListFilters}
                getOptionLabel={(option: any) => option.name}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} placeholder="Select attribute" />}
                ListboxProps={{ style: { maxHeight: 150, overflow: "auto" } }}
              />
              {filter.unit_or_attr && (
                <MultipleSelect
                  options={getSegmentsList(filter)}
                  selectedList={filter.segments}
                  onChange={changeSegments}
                  name="segments"
                  index={index}
                />
              )}
              {localFilters.length === 1 &&
                !localFilters[0].unit_or_attr &&
                localFilters[0].segments.length === 0 ? null : (
                <RemoveFilterButton onClick={() => removeFilter(index)}>Remove</RemoveFilterButton>
              )}
            </FilterRow>
            {filter.segments.length > 0 && (
              <SegmentsRow>
                {filter.segments.map((segment, indexSegment) => (
                  <SelectedSegment key={segment + indexSegment}>
                    {getUnitSegmentsName(filter, segment, store.filterAttributesList)}
                  </SelectedSegment>
                ))}
              </SegmentsRow>
            )}
          </FilterColumn>
        ))}
        <AddFilterButton onClick={addFilter}>+ Add filter</AddFilterButton>
        <ButtonWrapper>
          <ApplyCustomButton
            onClick={applyFilters}
            variant="contained"
            type="submit"
            disabled={
              localFilters.length > 1 &&
              !localFilters[localFilters.length - 1].unit_or_attr &&
              localFilters[localFilters.length - 1].segments.length === 0
            }
          >
            Apply filters (
            {localFilters.length === 1 && !localFilters[0].segments.length
              ? 0
              : localFilters.length}
            )
          </ApplyCustomButton>
        </ButtonWrapper>
      </StyledDialogContent>
    </StyledDialog>
  );
});

export default FiltersPopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 586px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 0px 48px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const DialogTitle = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
  padding: 10px 48px;
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 10px 20px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 0px 60px 0px;
`;

const ApplyCustomButton = styled(CustomButton)`
  width: 100%;
  height: 36px;
`;

const StyledAutocomplete = styled(Autocomplete)`
  width: 175px !important;
  margin-right: 12px;

  input {
    border-radius: 2px !important;
    padding: 0px !important;
    font-family: Roboto !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 175px !important;
  }

  .MuiOutlinedInput-root {
    border-radius: 2px !important;
    padding: 7px 9px !important;
    max-height: 34px !important;
  }
`;

const FilterRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const RemoveFilterButton = styled(CustomButton)`
  margin-left: 12px !important;
`;

const AddFilterButton = styled(CustomButton)`
  max-width: 100px;
  margin-top: 12px !important;
`;

const FilterColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const SelectedSegment = styled.span`
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 8px 2px 0px;
  background-color: var(--colorPaletteBlueBackground4);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  color: var(--colorNeutralForeground1);
`;

const SegmentsRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 12px;
  flex-wrap: wrap;
`;

const Divider = styled.span`
  margin-bottom: 16px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
  text-transform: uppercase;
`;

const TrendsLabel = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  svg {
    font-size: 16px;
    margin: 0px;
    color: var(--colorNeutralForeground2);
  }
`;

const TrendsLabelText = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
  margin-left: 2px;
`;
