import http from "api/https";
import { createUrl } from "../../helpers/url";

export const getQuestionLibrary = (
  dimension_id:number,
): Promise<any> => {
  const query: { dimension_id: string } = { dimension_id: dimension_id.toString() };
  if(http.isAPIGO){
    const url = createUrl({
      path: "/template/GetTemplatesBydimension_id",
      query: query,
    });
    return http.getGO(url);
  }
  else{
    const url = createUrl({
      path: "/templ_question/GetTemplatesBydimension_id",
      query: query,
    });
    return http.get(url);
  }
};
