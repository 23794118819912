import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Box, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomButton from "components/button/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import store from "./store";
import { useNavigate } from "react-router";
import { createUrl } from "helpers/url";

type LeftAccordionsProps = {};

const LeftAccordions: FC<LeftAccordionsProps> = observer((props) => {
  const navigate = useNavigate();

  const accordionItems = [
    { key: "shared", label: "Shared templates" },
    { key: "entity", label: "Entity templates" },
  ];

  const navigateHandler = (key: string, type: string) => {
    const url = createUrl({ path: "/templates", query: { nav: key, group: type } });
    navigate(url);
    store.onClickBtn(key, type);
  };

  return (
    <MainBox>
      <AccordionBox>
        <StyledAccordion
          expanded={store.expandedNav === "engageRocket"}
          id={"Templates_EngageRocketAccordion"}
          onChange={store.changeAccordion("engageRocket")}
        >
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id={"Templates_EngageRocketAccordion_Button"}
          >
            <StyledLink id={"Templates_EngageRocketAccordion_Label"}>
              EngageRocket templates
            </StyledLink>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <StyledCustomButton
              id={"Templates_EngageRocketAccordion_Questionnaire"}
              endIcon={
                store.groupUrl === "questionnaire" &&
                store.navUrl === "engageRocket" && <StyledNavigateNextIcon />
              }
              variant="text"
              active={store.groupUrl === "questionnaire" && store.navUrl === "engageRocket"}
              onClick={() => navigateHandler("engageRocket", "questionnaire")}
            >
              <p>Questionnaires</p>
            </StyledCustomButton>
          </StyledAccordionDetails>
        </StyledAccordion>
        {accordionItems.map(
          ({ key, label }: { key: "entity" | "shared"; label: string }, index: number) => (
            <StyledAccordion
              key={index}
              expanded={store.expandedNav === key}
              id={`Templates_${key}Accordion`}
              onChange={store.changeAccordion(key)}
            >
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={`Templates_${key}Accordion_Button`}
              >
                <StyledLink id={`Templates_${key}Accordion_Label`}>{label}</StyledLink>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                {["all", "questionnaire", "communication"].map((type) => (
                  <StyledCustomButton
                    id={`Templates_${key}Accordion_${type}`}
                    endIcon={
                      store.groupUrl === type && store.navUrl === key && <StyledNavigateNextIcon />
                    }
                    active={store.groupUrl === type && store.navUrl === key}
                    onClick={() => navigateHandler(key, type)}
                  >
                    <p>
                      {type === "all"
                        ? `View all templates`
                        : type.charAt(0).toUpperCase() + type.slice(1) + "s"}
                    </p>
                  </StyledCustomButton>
                ))}
              </StyledAccordionDetails>
            </StyledAccordion>
          )
        )}
      </AccordionBox>
    </MainBox>
  );
});

export default LeftAccordions;

const MainBox = styled(Box)`
  width: 100%;
  max-width: 235px;
  min-height: 90vh;
  background-color: var(--colorNeutralBackground1);
`;

const AccordionBox = styled(Box)`
  max-width: 225px;
  background-color: var(--colorPaletteBlueBackground1);
`;

const StyledAccordion = styled(Accordion)`
  margin: 0 !important;
  width: 225px;
  border-radius: 0 !important;
  box-shadow: none !important;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: var(--colorPaletteBlueBackground1) !important;
  height: 60px !important;
`;

const StyledLink = styled.div`
  color: var(--colorNeutralForeground2);
  font-size: 14px !important;
  font-weight: 500 !important;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0px !important;
`;

const StyledCustomButton = styled(CustomButton)<{ active?: boolean }>`
  width: 225px;
  height: 73px;
  margin: 0px;
  background-color: var(--colorPalleteLightBlue) !important;
  color: var(--colorNeutralForeground2) !important;
  padding-left: 0 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between !important;

  &:hover {
    text-decoration: none !important;
  }

  > p {
    display: flex;
    align-items: center;
    padding-left: 20px;
    height: 30px;
    background: ${(props) =>
      props.active
        ? "linear-gradient(90deg, var(--colorPaletteVioletBackground2) 3%, var(--colorPalleteLightBlue) 1%)"
        : "none"};
  }
`;

const StyledNavigateNextIcon = styled(NavigateNextIcon)`
  margin-left: 30px;
`;
