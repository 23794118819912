import React, { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Checkbox, FormControlLabel } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import SendInvitesRecipientsPopup from "../MillstoneRecipientShedule/SendInvitesPopup";
import Pagination from "components/pagination/Pagination";
import CustomTableRecipients from "./CustomTableRecipients";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TColumn, TEmployee } from "types/recipients";

type ImportRecipientsTableProps = {};

const ImportRecipientsTable: FC<ImportRecipientsTableProps> = observer(() => {
  const [sortModel, setSortModel] = React.useState({ field: null, sort: null });

  const selectionChangeHandler = (isSelected?: boolean, row?: TEmployee) => {
    store.changeSelectedRecipients(isSelected,row)
  };

  const changeSort = (field: string, sort: string) => {

    store.setData("importSortField", field);

    var order = false
    if (sort == "desc") {
      order = true
    }

    store.setData("importSortOrder", order)

    store.getAllEmployees()
  };


  const columns = [
    {
      width: 20,
      render: (row: TEmployee) => {
        const isSelected = store.importSelectionRows.includes(row.id);
        return (
          <CheckBoxContainer>
            <StyledFormControlLabel
              control={
                row.export ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="20"
                    viewBox="0 0 20 21"
                    fill="none"
                    style={{ marginLeft: 14, cursor: "not-allowed" }}
                  >
                    <rect y="0.5" width="20" height="20" rx="2" fill="#D9DDE7" />
                    <path
                      d="M5.83203 10.9167L8.83457 13.9193C9.0083 14.093 9.29613 14.0705 9.44076 13.8719L14.5442 6.86377"
                      stroke="#F9FAFC"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                ) : (
                  <StyledCheckBox
                    id={"Survey_EditCreate_Recipietns_Import_Table_CheckBox_" + row.id}
                    checked={isSelected}
                  />
                )
              }
              label={false}
            />
          </CheckBoxContainer>
        );
      },
    },
    {
      id: "full_name",
      label: (
        <StyledContainerLabelColumn>
          <p id="Survey_EditCreate_Recipietns_Import_Table_FullName_Text">FULL NAME</p>
          <BlockSortStyle>
            <KeyboardArrowUpIconStyle
              id="Survey_EditCreate_Recipietns_Import_Table_FullName_ArrowUp"
              style={
                sortModel.field === "full_name" && sortModel.sort === "asc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("full_name", "asc")}
            />
            <KeyboardArrowDownIconStyle
              id="Survey_EditCreate_Recipietns_Import_Table_FullName_ArrowDown"
              style={
                sortModel.field === "full_name" && sortModel.sort === "desc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("full_name", "desc")}
            />
          </BlockSortStyle>
        </StyledContainerLabelColumn>
      ),
    },
    {
      id: "preferred_name",
      label: (
        <span id="Survey_EditCreate_Recipietns_Import_Table_PreferredName">PREFERRED NAME</span>
      ),
    },
    {
      id: "email",
      label: (
        <StyledContainerLabelColumn>
          <p id="Survey_EditCreate_Recipietns_Import_Table_Email_Text">EMAIL ADDRESS</p>
          <BlockSortStyle>
            <KeyboardArrowUpIconStyle
              id="Survey_EditCreate_Recipietns_Import_Table_Email_ArrowUp"
              style={
                sortModel.field === "email" && sortModel.sort === "asc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("email", "asc")}
            />
            <KeyboardArrowDownIconStyle
              id="Survey_EditCreate_Recipietns_Import_Table_Email_ArrowDown"
              style={
                sortModel.field === "email" && sortModel.sort === "desc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("email", "desc")}
            />
          </BlockSortStyle>
        </StyledContainerLabelColumn>
      ),
    },
  ] as TColumn[];

  return (
    <Container>
      <TableHeader>
        <CheckBoxContainer>
          <StyledFormControlLabel
            control={
              <StyledCheckBox
                id="Survey_EditCreate_Recipietns_Import_Table_SelectAll_CheckBox"
                disabled={store.importRecipientsList.length === 0}
                checked={store.selectAllRecipients}
                onChange={(e) => {
                  store.setData("selectAllRecipients", e.target.checked);
                  store.setData(
                        "importSelectionRows",
                        e.target.checked
                          ? store.importRecipientsList.map((item: TEmployee) => item.id)
                          : []
                      );
                }}
              />
            }
            label={
              <span id="Survey_EditCreate_Recipietns_Import_Table_SelectAll_Label_CheckBox">
                Select all
              </span>
            }
          />
        </CheckBoxContainer>
        <AddButton
          id="Survey_EditCreate_Recipietns_Import_Table_AddToRecipientList_Button"
          disabled={store.importSelectionRows.length === 0}
          onClick={() => {
            if (store.is_running_survey) store.openRecipientsModal(true);
            else store.addRecipients(false);

          }}
        >
          {`Add to recipient list (${store.getCountForAddRecipients()}) >`}
        </AddButton>
      </TableHeader>
      <div style={{ width: "100%" }}>
        <CustomTableRecipients
          data={store.importRecipientsList}
          columns={columns}
          changeHandler={selectionChangeHandler}
          variant="addRecipient"
          noDataTitle={{
            description:
              "No employees match the provided details. Please try using a different search keyword.",
          }}
          id="ImportRecipients_"
        />
      </div>
      <Pagination
        id="Survey_EditCreate_Recipietns_Import_Table"
        activePage={store.activePage}
        itemsCountPerPage={store.countPerPage}
        onChange={(value) => {
          store.setActivePage(value);
          store.getAllEmployees();
        }}
        pageRangeDisplayed={store.countPerPage}
        totalItemsCount={store.countTotal}
        totalPages={store.countTotal / store.countPerPage}
        setCount={(value) => {
          store.setActivePage(1);
          store.setCountPerPage(Number(value));
          store.getAllEmployees();
        }}
      />
      <SendInvitesRecipientsPopup
        open={store.addRecipientsListModal}
        hide={() => store.closeRecipientsModal(false)}
      />
    </Container>
  );
});

export default ImportRecipientsTable;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const StyledContainerLabelColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const BlockSortStyle = styled.span`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 10px;
  height: 20px;
  top: 0;
  left: 14px;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
`;

const KeyboardArrowUpIconStyle = styled(KeyboardArrowUpIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: -6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: 6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const AddButton = styled(CustomButton)`
  width: fit-content !important;
  padding: 0px !important;
  justify-content: flex-start !important;

  &:hover {
    background-color: transparent !important;
    text-decoration: underline !important;
  }

  :disabled {
    text-decoration: none !important;
    svg {
      color: var(--colorNeutralForeground5) !important;
    }
  }
`;

const CheckBoxContainer = styled.div``;

const StyledCheckBox = styled(Checkbox)`
  padding: 0px 10px !important;
  color: var(--colorBrandForeground1) !important;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);

  .MuiCheckbox-root {
    &:hover {
      background-color: transparent !important;
    }
  }
`;

const TableHeader = styled.div`
  width: 100%;
  padding: 16px;
  background-color: var(--grenBackgroundColor2);
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);

  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 0px, 0px, 1px, 0px;
`;
