import * as React from "react";
import { useEffect } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { observer } from "mobx-react";
import dayjs from "dayjs";
import styled from "styled-components";

type BasicTimeFieldProps = {
  onChange: (e) => void;
  value: string;
  label?: string;
};

function convertTimeStringToDate(timeString: string) {
  const [hours, minutes] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

const BasicTimeField: React.FC<BasicTimeFieldProps> = observer((props) => {
  const initialTime = convertTimeStringToDate(props.value || "00:00");
  const [value, setValue] = React.useState(initialTime);

  useEffect(() => {
    if (props.value === null) {
      props.onChange(dayjs(value));
    }
  }, [props.value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["TimeField"]}>
        <WrappedTimeField
          value={dayjs(value)}
          label={props.label ? props.label : "Select time"}
          closeOnSelect={false}
          onChange={(newValue) => props.onChange(newValue)}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
});

const WrappedTimeField = styled(TimePicker)`
  input {
    padding: 11px;
  }
  label {
    position: absolute;
    top: -3px;
  }
`;

export default BasicTimeField;
