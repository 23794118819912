import { FC } from "react";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import store from "./store";
import { BackToTemplatesIcon } from './BackToTemplatesIcon'
import { observer } from "mobx-react";
import SelectStore from 'features/Survey/SelectTemplate/storeSurvey'

type ReplaceQuestionsConfirmProps = {
};

const ReplaceQuestionsConfirm: FC<ReplaceQuestionsConfirmProps> = observer((props) => {
  return (
    <PopupWrapper id="Replace_Questions_Form">
      <BackToTemplatesWithIcon id="Replace_Questions_BackToTemplates_Button" onClick={() => { store.backToTemplates() }}>
        <BackToTemplatesIcon />

        <BackToTemplates id="Replace_Questions_BackToTemplates_Label">
          Back to templates and library
        </BackToTemplates>
      </BackToTemplatesWithIcon>

      <BodyConfirm id="Replace_Questions_BackToTemplates_Body">

        <ReplaceQuestion id="Replace_Questions_ReplaceQuestion_Label">
          Replace existing questions?
        </ReplaceQuestion>

        <ReplaceQuestionDescription id="Replace_Questions_Description_Text">
          Would you like to replace your current questionnaire with the questions selected?
        </ReplaceQuestionDescription>

        <ReplaceButtons>
          <ButtonWrapp>
            <CustomButton variant="contained" id="Replace_Questions_NoAdd_Button" onClick={() => {
              store.confirmReplaceQuestions("add")
              SelectStore.clearStore()
            }}>
              No, add to questions
            </CustomButton>
          </ButtonWrapp>
          <ButtonWrapp>
            <CustomButton variant="outlined" id="Replace_Questions_Replace_Button" onClick={() => {
              store.confirmReplaceQuestions("replace")
              SelectStore.clearStore()
            }}>
              Yes, replace all questions
            </CustomButton>
          </ButtonWrapp>
        </ReplaceButtons>
      </BodyConfirm>

    </PopupWrapper>
  );
});

const PopupWrapper = styled.div`
  width: 500px;
  height: 400px;
`

const BackToTemplatesWithIcon = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin: 20px;
`

const ReplaceQuestion = styled.div`
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  margin-top: 70px;
`

const ReplaceQuestionDescription = styled.div`
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-top: 30px;
`

const ButtonWrapp = styled.div`
  margin-right: 10px;
`

const ReplaceButtons = styled.div`
  display: flex;
  margin-top: 120px;
`

const BodyConfirm = styled.div`
  margin: 50px 50px 0 50px;
`

const BackToTemplates = styled.div`
  color: var(--colorNeutralForeground5, #959BA2);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
`



export default ReplaceQuestionsConfirm;