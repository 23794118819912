import { FC } from "react";
import { observer } from "mobx-react";

import store from "../store";
import styled from "styled-components";

type SelectColumnsTypeProps = {};

const SelectColumnsType: FC<SelectColumnsTypeProps> = observer(() => {
    return (
        <Container>
            <Card $active={Boolean(store.typeColumnsSetup === "diff_score")}>
                <CheckWrapper>
                    <div />
                    <CheckedLabel $active={Boolean(store.typeColumnsSetup === "diff_score")}>
                        {store.typeColumnsSetup === "diff_score" && <InsideCheked />}
                    </CheckedLabel>
                </CheckWrapper>
                <Title $active={Boolean(store.typeColumnsSetup === "diff_score")}>
                    Different score types & questions
                </Title>
                <Description>Compare scores across selected segments.</Description>
            </Card>
            <Card
                $active={Boolean(store.typeColumnsSetup === "segments")}
                style={{ cursor: "not-allowed" }}
            >
                <CheckWrapper>
                    <CoomingSoon>(Coming soon)</CoomingSoon>
                    <CheckedLabel $active={Boolean(store.typeColumnsSetup === "segments")}>
                        {store.typeColumnsSetup === "segments" && <InsideCheked />}
                    </CheckedLabel>
                </CheckWrapper>
                <Title $active={Boolean(store.typeColumnsSetup === "segments")}>
                    Segments from 1 attribute{" "}
                </Title>
                <Description>Deep-dive into 1 attribute and focus on a single score.</Description>
            </Card>
        </Container>
    );
});

export default SelectColumnsType;

const Container = styled.div`
  width: 100%;
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
`;

const Card = styled.div<{ $active?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 18px 24px 24px;
  border-radius: 10px;
  ${(props) => props.$active && "box-shadow: 0px 2px 8px 0px var(--colorNeutralForeground6)"};
  ${(props) => !props.$active && "border: 1px solid var(--colorGrayForeground7)"};
  background-color: ${(props) =>
        props.$active ? "var(--colorPaletteGrayBackground1)" : "var(--colorNeutralBackground1)"};
  cursor: pointer;
`;

const Title = styled.p<{ $active?: boolean }>`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 8px;
  color: ${(props) =>
        props.$active ? "var(--colorPaletteVioletBackground1)" : "var(--colorNeutralForeground5)"};
`;

const Description = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
  color: var(--colorNeutralForeground1);
`;

const CheckWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CheckedLabel = styled.div<{ $active?: boolean }>`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid
    ${(props) => (props.$active ? "var(--colorNeutralForeground5)" : "var(--colorGrayForeground6)")};
  background-color: ${(props) => (props.$active ? "transparent" : "var(--colorGrayForeground7)")};
`;

const InsideCheked = styled.span`
  width: 10px;
  height: 10px;
  background-color: var(--colorNeutralForeground5);
  border-radius: 50%;
`;

const CoomingSoon = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground5);
`;
