import styled from "styled-components";

const StyledSvg = styled.svg`
  min-width: 17px;
`;

export const QuestionIcon = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
    <StyledSvg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 23 22" fill="none">
        <circle cx="8" cy="8" r="7.5" stroke="#A9AFC6" />
        <path d="M8.3457 9.59766H7.25586C7.25977 9.22266 7.29297 8.91602 7.35547 8.67773C7.42188 8.43555 7.5293 8.21484 7.67773 8.01562C7.82617 7.81641 8.02344 7.58984 8.26953 7.33594C8.44922 7.15234 8.61328 6.98047 8.76172 6.82031C8.91406 6.65625 9.03711 6.48047 9.13086 6.29297C9.22461 6.10156 9.27148 5.87305 9.27148 5.60742C9.27148 5.33789 9.22266 5.10547 9.125 4.91016C9.03125 4.71484 8.89062 4.56445 8.70312 4.45898C8.51953 4.35352 8.29102 4.30078 8.01758 4.30078C7.79102 4.30078 7.57617 4.3418 7.37305 4.42383C7.16992 4.50586 7.00586 4.63281 6.88086 4.80469C6.75586 4.97266 6.69141 5.19336 6.6875 5.4668H5.60352C5.61133 5.02539 5.7207 4.64648 5.93164 4.33008C6.14648 4.01367 6.43555 3.77148 6.79883 3.60352C7.16211 3.43555 7.56836 3.35156 8.01758 3.35156C8.51367 3.35156 8.93555 3.44141 9.2832 3.62109C9.63477 3.80078 9.90234 4.05859 10.0859 4.39453C10.2695 4.72656 10.3613 5.12109 10.3613 5.57812C10.3613 5.92969 10.2891 6.25391 10.1445 6.55078C10.0039 6.84375 9.82227 7.11914 9.59961 7.37695C9.37695 7.63477 9.14062 7.88086 8.89062 8.11523C8.67578 8.31445 8.53125 8.53906 8.45703 8.78906C8.38281 9.03906 8.3457 9.30859 8.3457 9.59766ZM7.20898 11.4551C7.20898 11.2793 7.26367 11.1309 7.37305 11.0098C7.48242 10.8887 7.64062 10.8281 7.84766 10.8281C8.05859 10.8281 8.21875 10.8887 8.32812 11.0098C8.4375 11.1309 8.49219 11.2793 8.49219 11.4551C8.49219 11.623 8.4375 11.7676 8.32812 11.8887C8.21875 12.0098 8.05859 12.0703 7.84766 12.0703C7.64062 12.0703 7.48242 12.0098 7.37305 11.8887C7.26367 11.7676 7.20898 11.623 7.20898 11.4551Z" fill="#A9AFC6" />
    </StyledSvg>
);
