import http from "api/https";

export type UpdateAccountType = {
  id: number,
  full_name: string,
  preferred_name: string,
  email: string,
};


export const UpdateAccount = async (data: UpdateAccountType) => {
  let dataUpd = {
    id: data.id,
    full_name: data.full_name,
    preferred_name: data.preferred_name,
    email: data.email,
  };
  if (http.isAPIGO) {
    return http.postGO("/permission/accountUpdateName", data);
  } else {
    return http.post("/accounts/AddOrUpdate", data);
  }
}
