import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";

import RichTextWithTabsAll from "../../RichTextWithTabs";
import CustomButton from "components/button/Button";
import PreviewIcon from "../../PreviewIcon";
import store from "./../../store";

type SecondTabProps = {};

const SecondTab: FC<SecondTabProps> = observer(() => {
  return (
    <Wrapped>
      <Header id="Template_Communication_Schedule_Invite_Header_Label">Invite email</Header>

      <SubHeader id="Template_Communication_Schedule_Invite_Subject_Label">EMAIL SUBJECT</SubHeader>

      <RichTextWithTabsAll
        onlyText
        data={store.InviteData}
        width={100}
        changeValue={store.changeInviteData}
        field="subject_email"
        variantTestId="invite"
        boxId="Template_Communication_Schedule_InviteSubject_RichText"
        langNameId="Template_Communication_Schedule_InviteSubject_TabLabel"
        editorId="Template_Communication_Schedule_EmailSettings_Subject_Input"
      />

      <SubHeader id="Template_Communication_Schedule_Invite_EmailContent_Label">
        EMAIL CONTENT
      </SubHeader>

      <RichTextWithTabsAll
        data={store.InviteData}
        width={200}
        changeValue={store.changeInviteData}
        field="body_email"
        variantTestId="invite"
        boxId="Template_Communication_Schedule_InviteSubject_RichText"
        langNameId="Template_Communication_Schedule_InviteSubject_TabLabel"
        editorId="Template_Communication_Schedule_EmailSettings_Subject_Input"
      />

      <BottomWrapper>
        <ButtonWrapper>
          <StyledNextButton
            id="Template_Communication_Schedule_Invite_Save_Button"
            variant="contained"
            onClick={() => store.SaveInviteData()}
          >
            Save changes
          </StyledNextButton>

          <StyledCancelButton
            id="Template_Communication_Schedule_Invite_Cancel_Button"
            variant="outlined"
            disabled={!store.changedSecondTab}
            onClick={() => store.onCancelSecondTab()}
          >
            Cancel
          </StyledCancelButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <LinkPreview
            id="Template_Communication_Schedule_Invite_Preview_Button"
            onClick={() => store.LoadPreviewMessage(null, false)}
          >
            <PreviewIcon />
            <LinkPreviewText>Preview email</LinkPreviewText>
          </LinkPreview>
        </ButtonWrapper>
      </BottomWrapper>
    </Wrapped>
  );
});

export default SecondTab;

const Wrapped = styled.div`
  margin: 10px 30px;
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: var(--colorNeutralForeground1);
`;

const BottomWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LinkPreviewText = styled.span`
  margin-left: 3px;
`;

const LinkPreview = styled.a`
  margin-left: 5px;
  color: var(--colorBrandForeground1);
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledCancelButton = styled(CustomButton)``;

const StyledNextButton = styled(CustomButton)``;

const SubHeader = styled.div`
  margin-bottom: 10px;
  margin-top: 30px;
  color: var(--colorNeutralForeground2);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
`;
