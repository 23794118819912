export const EnterIconTags = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16" fill="none">
      <path
        d="M0.646446 11.6464C0.451184 11.8417 0.451184 12.1583 0.646446 12.3536L3.82843 15.5355C4.02369 15.7308 4.34027 15.7308 4.53553 15.5355C4.7308 15.3403 4.7308 15.0237 4.53553 14.8284L1.70711 12L4.53553 9.17157C4.7308 8.97631 4.7308 8.65973 4.53553 8.46447C4.34027 8.2692 4.02369 8.2692 3.82843 8.46447L0.646446 11.6464ZM11 11.5L1 11.5L1 12.5L11 12.5L11 11.5Z"
        fill="#959BA2"
      />
      <path d="M10.5 12L10.5 1" stroke="#959BA2" stroke-linecap="round" />
    </svg>
  );
};
