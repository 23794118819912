import { FC } from "react";
import AddEditTemplates from '../../features/Templates/CommunicationTemplates/AddEditTemplates'

type CreateCommunicationTemplatePageProps = {};

const CreateCommunicationTemplatePage: FC<CreateCommunicationTemplatePageProps> = () => {
  return <div>
    <AddEditTemplates />

  </div>;
};

export default CreateCommunicationTemplatePage;
