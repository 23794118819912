import { makeAutoObservable } from "mobx";
import { removeFromRoles } from "../../../../api/go/useRemoveFromRoles";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import MainStore from "MainStore";
import { ACCESS_LEVEL_TYPE } from "constants/access-level-type";

class Store {
  constructor() {
    makeAutoObservable(this);
  }

  changeValue = (name: string, value: any) => {
    this[name] = value;
  };

  clearStore() {}

  async removeFromRoleSubmit(idRole: number, idUsers: number[], onSaved: () => void) {
    try {
      const response = await removeFromRoles(idRole, idUsers, StoreLayout.currentEntityId, ACCESS_LEVEL_TYPE.entity);
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        onSaved()
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }
}

const store = new Store();
export default store;
