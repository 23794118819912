import { FC } from "react";
import { EmployeeImportScreen } from "features/EmployeeImport";

type EmployeeImportPageProps = {};

const EmployeeImportPage: FC<EmployeeImportPageProps> = () => {
  return <EmployeeImportScreen />;
};

export default EmployeeImportPage;
