import http from "api/https";

export const deleteSegments = (attributeId: number, segments: string[]): Promise<any> => {
  if (http.isAPIGO) {
    return http.removeGO("/employee/entity_employee_option_attributes/DeleteSegments", {
      attribute_id: attributeId,
      segments: segments,
    });
  } else {
    return http.remove("/entity_employee_option_attributes/DeleteSegments", {
      attribute_id: attributeId,
      segments: segments,
    });
  }
};
