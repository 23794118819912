import { FC } from "react";
import { observer } from "mobx-react";
import { SURVEY_STATUS_DASHBOARD } from "constants/survey-status";

import store from "../../store";
import styled from "styled-components";
import PeopleIcon from "@mui/icons-material/People";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CardLoader } from "features/Dashboard/CardLoader";

type ParticipationCardProps = {};

const ParticipationCard: FC<ParticipationCardProps> = observer(() => {

  const percent =
    (store.projectInfo?.participation && store.projectInfo?.participation?.percent) || "0";

  const recipientsResponded =
    (store.projectInfo?.participation && store.projectInfo?.participation.recipients_responded) ||
    "0";

  const recipientsTotal =
    (store.projectInfo?.participation && store.projectInfo?.participation.recipients_total) || "0";

  const content = (
    <>
      <MainPercentRow>
        <MainPercent>{percent}%</MainPercent>
        {store.projectInfo?.participation &&
          typeof store.projectInfo?.participation?.diff === "number" && (
            <>
              {store.projectInfo.participation.diff <= 0 ? (
                <StyledArrowDropDownIcon />
              ) : (
                <StyledArrowDropUpIcon />
              )}
              <SecondaryPercent>{store.projectInfo.participation.diff}%</SecondaryPercent>
            </>
          )}
      </MainPercentRow>
      <SectorTitle>Recipients</SectorTitle>
      <Row>
        <StyledPeopleIcon />
        <Text>
          {recipientsResponded}/{recipientsTotal} responded
        </Text>
      </Row>
    </>
  )

  const isPending = store.isParticipationLoading || !store.projectInfo
  const isHistorical = Boolean(store.selectedSurvey?.status === SURVEY_STATUS_DASHBOARD.Historical)

  return (
    <Card>
      <TitleRow>
        <Title>Participation</Title>
        <Descripiton
          $isHistorical={isHistorical}
        >
          {isHistorical
            ? "No participation data available for historical survey"
            : "Updated every minute"}
        </Descripiton>
      </TitleRow>
      {!isHistorical && (
        <Content>
          {isPending ? <CardLoader contentHeight={172} /> : content}
        </Content>
      )}
    </Card>
  );
});

export default ParticipationCard;

const Card = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--colorNeutralBackground1);
`;

const TitleRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-right: 4px;
`;

const Descripiton = styled.p<{ $isHistorical: boolean }>`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
  text-align: ${(props) => (props.$isHistorical ? "right" : "left")};
  ${(props) => props.$isHistorical && "margin-bottom: -20px;"}
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

const MainPercentRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
`;

const MainPercent = styled.p`
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
`;

const SecondaryPercent = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-bottom: 2px;
`;

const StyledArrowDropUpIcon = styled(ArrowDropUpIcon)`
  color: var(--colorPaletteGreenBackground2);
  font-size: 24px !important;
`;

const SectorTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
  margin: 16px 0px 4px 0px;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const StyledPeopleIcon = styled(PeopleIcon)`
  color: var(--colorNeutralForeground2);
  font-size: 18px !important;
  margin: 0px 8px 2px 0px;
`;

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  color: var(--colorPaletteRedForeground1);
  font-size: 24px !important;
`;
