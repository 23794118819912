import { makeAutoObservable } from "mobx";
import { GetSurveyTags, SurveyTagsType } from "api/go/useGetSurveyTags";
import { updateTemplateQuestion } from "api/go/useUpdateTemplateQuestions";
import { GetOneTemplateQuestion } from "api/go/useGetOneTemplQuestion";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import storeComm from "features/Templates/QuestionnaireTemplates/store";
import MainStore from "MainStore";

class Store {
  templateId: number;
  tags: SurveyTagsType[] = [];
  selectedTagNames: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  loadSettingbyid() {
    let id = this.templateId === null || this.templateId === undefined ? 0 : this.templateId;
    if (id === 0) return;
    GetOneTemplateQuestion(id).then((x) => {
      if (x?.data !== undefined) {
        this.templateId = x.data.id;

        this.selectedTagNames = this.tags
          .filter((value) => x.data.tags.some((value2) => value2.id === value.id))
          .map((x) => x.name);
      }
    });
  }

  handleChange(name: string, value: any) {
    this[name] = value;
  }

  onSave(navigate: (id) => void) {
    let template = {
      id: this.templateId,
      tagNames: this.selectedTagNames,
      entity_id: StoreLayout.currentEntityId,
    };
    MainStore.changeLoader(true);
    updateTemplateQuestion(template).then((x) => {
      MainStore.changeLoader(false);
      if (x?.data !== undefined) {
        MainStore.setSnackbar("Settings successfully saved!");
        this.clearStore();
        storeComm.setModalOpen(false);
        if (this.templateId === 0) {
          navigate(x.data);
        }
      }
    });
  }

  loadTags = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await GetSurveyTags();
      this.tags = response;
      this.loadSettingbyid();
    } catch {
      MainStore.setSnackbar("Can't found tags", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  changeTemplateId = (id: number) => {
    this.templateId = id;
  };

  clearStore() {
    this.tags = [];
    this.selectedTagNames = [];
  }

  changeSelectedTagName = (value: string[]) => {
    this.selectedTagNames = value;
  };
}

const store = new Store();
export default store;
