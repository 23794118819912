import { FC, useEffect } from "react";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import store from "./store"
import { observer } from "mobx-react";
import MtmLookup from "./mtmLookup";
import TableRoles from "./TableRoles";
import { useNavigate } from "react-router-dom";

type ProjectPermissionsProps = {
  closePopup: () => void;
  projectId: number;
  projectName: string;
};

const ProjectPermissions: FC<ProjectPermissionsProps> = observer((props) => {
  const navigate = useNavigate();

  useEffect(() => {
    store.changeValue("projectName", props.projectName)
    store.changeValue("projectId", props.projectId)
    store.loadRolesWithAccessesToProject()
    if (store.projectId !== props.projectId) {
    }
    return () => store.clearStore();
  }, [props.projectId])


  return (
    <PopupWrapper>


      <Header id="SyrveyProject_PermissionsPopUp_Title">Project permissions <ProjectName>{store.projectName}</ProjectName></Header>

      <GiveAccess id="SyrveyProject_PermissionsPopUp_SubTitle">Give access to</GiveAccess>

      <MtmLookup />

      <RolesWithAccessText id="SyrveyProject_PermissionsPopUp_Body_Text"> Roles with access </RolesWithAccessText>

      <TableRolesWrapper>
        <TableRoles />
      </TableRolesWrapper>

      <Actions>
        <CustomButton id="SyrveyProject_PermissionsPopUp_PermissionRoles" variant="outlined" onClick={() => { window.open('/entityPermissions?nav=role','_blank')}}>
          Permission roles
        </CustomButton>
        <CustomButton
          id="SyrveyProject_PermissionsPopUp_SaveChangesBtn"
          variant="contained" disabled={!store.permissionChanged} onClick={() => {
          store.onSavePermissions(() => props.closePopup())
        }}>
          Save changes
        </CustomButton>
      </Actions>

    </PopupWrapper>
  );
});

const PopupWrapper = styled.div`
  margin: 30px 10px;
`

const Header = styled.div`
  color: var(--ER-Theme-sys-light-on-secondary-container, #3E4450);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  margin: 10px 0;
`

const ProjectName = styled.span`
  color: var(--ER-Theme-sys-light-on-secondary-container, #3E4450);
  /* ER Theme/title/large */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
`

const GiveAccess = styled.div`
  color: var(--ER-Theme-sys-light-on-secondary-container, #3E4450);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-top: 20px;
`

const RolesWithAccessText = styled.div`
  color: var(--ER-Theme-sys-light-on-secondary-container, #3E4450);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-top: 40px;
`

const TableRolesWrapper = styled.div`
  margin: 20px 0;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`

export default ProjectPermissions;
