import { FC, useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Tabs, Tab, Box, IconButton } from "@mui/material";
import { EditorProvider, Editor } from "react-simple-wysiwyg";

import ChevronCircleIcon from "features/Survey/Recipients/ChevronCircleIcon";
import { FooterTemplateLanguage, TemplateLanguage } from "types/templates";
import EditorFull from "react-simple-wysiwyg";
import store from "./store";

type ScrollButtonComponents = {
  direction: "left" | "right";
  disabled: boolean;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <StyledBox>{children}</StyledBox>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const ScrollButton = (props: ScrollButtonComponents) => {
  if ((props.direction === "left" && !props.disabled) || props.direction === "right") {
    return (
      <IconButton {...props} style={{ padding: 12 }}>
        <ChevronCircleIcon position={props.direction} />
      </IconButton>
    );
  }
  return null;
};

type RichTextWithTabsProps = {
  data: TemplateLanguage[] | FooterTemplateLanguage[];
  field: string;
  width: number;
  onlyText?: boolean;
  changeValue: (value: string, field: string, index: number) => void;
  variantTestId: string;
  boxId: string;
  langNameId: string;
  editorId: string;
};

const RichTextWithTabsAll: FC<RichTextWithTabsProps> = observer((props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const itemsId = (id: string, index: number): string => {
    if (props.variantTestId === "settings") {
      return `${id}_${index}`;
    }
    return `${id}_${props.field}_${index}`;
  };

  return (
    <FullWidth id={props.boxId}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          ScrollButtonComponent={ScrollButton}
        >
          {props.data.map((language: TemplateLanguage | FooterTemplateLanguage, i: number) => (
            <CustomTab
              onClick={() => store.changeLastLanguage(language.language_id)}
              $selected={value === i}
              key={language.language_id}
              label={
                <span id={itemsId(props.langNameId, i)}>
                  {language.language_name + (language.is_default ? " (Default)" : "")}
                </span>
              }
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
      </Box>
      {props.data.map((language: TemplateLanguage | FooterTemplateLanguage, i: number) => (
        <CustomTabPanel key={language.language_id} value={value} index={i}>
          {props.onlyText ? (
            <EditorProvider>
              <Editor
                id={itemsId(props.editorId, i)}
                style={{ minHeight: props.width }}
                value={language[props.field]}
                onChange={(e) => props.changeValue(e.target.value, props.field, i)}
              />
            </EditorProvider>
          ) : (
            <EditorFull
              id={itemsId(props.editorId, i)}
              style={{ minHeight: props.width }}
              value={language[props.field]}
              onChange={(e) => props.changeValue(e.target.value, props.field, i)}
            />
          )}
        </CustomTabPanel>
      ))}
    </FullWidth>
  );
});

export default RichTextWithTabsAll;

const FullWidth = styled.div`
  width: 100%;
  .MuiTabs-indicator {
    display: none !important;
  }
`;

const StyledBox = styled(Box)`
  .rsw-editor {
    border-radius: 0 0 4px 4px;
    min-height: auto !important;
  }
  .rsw-ce {
    min-height: auto !important;
    padding: 16px 11px;
  }
`;

const CustomTab = styled(Tab)<{ $selected: boolean | undefined }>`
  border-radius: 5px 5px 0 0 !important;
  margin-right: 10px !important;
  background-color: ${(props) =>
    props.$selected
      ? "var(--colorNeutralBackground1)"
      : "var(--colorPaletteGrayBackground1)"} !important;
  color: ${(props) =>
    props.$selected
      ? "var(--colorNeutralForeground1)"
      : "var(--colorNeutralForeground2)"} !important;
  border: ${(props) =>
    props.$selected
      ? "1px solid var(--colorBrandForeground1)"
      : "1px solid var(--colorPaletteBlueBackground1)"} !important;
  border-bottom: 0 !important;
  text-transform: none !important;
`;
