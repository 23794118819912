import { DataGrid, GridColDef } from '@mui/x-data-grid';
import store from './store'
import { observer } from 'mobx-react';
import styled from 'styled-components';

const columns: GridColDef[] = [
  {
    field: 'full_name',
    headerName: 'FULL NAME (PREFFERED NAME)',
    flex: 1,
    renderCell: (param) => {
      return <TableColumn id={`GrantPermissions_ManagersTable_Column_FullName_${param.row.id}`}>
        {param.row.full_name != null && <span id={`GrantPermissions_ManagersTable_FullName_${param.row.id}`}>{param.row.full_name}</span>}
        {param.row.preffered_name != null && <PrefferedName id={`GrantPermissions_ManagersTable_PrefferedName_${param.row.id}`}></PrefferedName>}
      </TableColumn>
    },
    renderHeader: (param) => {
      return <TableHeader id={`GrantPermissions_ManagersTable_Header_FullName`}>FULL NAME (PREFFERED NAME)</TableHeader>
    }
  },
  {
    field: 'email',
    headerName: 'EMAIL ADDRESS',
    flex: 1,
    renderCell: (param) => {
      return <TableColumn id={`GrantPermissions_ManagersTable_Column_Email_${param.row.id}`}>
        {param.row.email}
      </TableColumn>
    },
    renderHeader: (param) => {
      return <TableHeader id={`GrantPermissions_ManagersTable_Header_Email`}>EMAIL</TableHeader>
    }
  },
];

const DataGridDemo = observer(() => {
  return (
    <TableWrapper id="GrantPermissions_ManagersTable">
      <DataGrid
        rows={store.managers}
        columns={columns}
        hideFooter
        rowSelectionModel={store.selectedData}
        onRowSelectionModelChange={(rows) => {
          store.changeValue('selectedData', rows)
        }}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </TableWrapper>
  );
})


const TableWrapper = styled.div`
  height: 300px;
  width: 100%;
`

const TableColumn = styled.span`
  color: var(--ER-Theme-sys-light-on-secondary-container, #3E4450);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`
const PrefferedName = styled.span`
  color: var(--ER-Theme-sys-light-on-tertiary-container, #707882);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

const TableHeader = styled.span`
  color: var(--ER-Theme-sys-light-on-secondary-container, #3E4450);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

export default DataGridDemo;
