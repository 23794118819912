import { FC } from "react";
import { observer } from "mobx-react";

import store from "./store";
import styled from "styled-components";
import PeopleIcon from "@mui/icons-material/People";

type ParticipationCardProps = {};

const ParticipationCard: FC<ParticipationCardProps> = observer(() => {
  return (
    <Container>
      <Title>Participation rate</Title>
      <Card>
        <CardTitleRow>
          <CardTitlePercent>
            {(store.surveyInfo && store.surveyInfo.percent) || "0"}%
          </CardTitlePercent>
          <CardTitleCounter>
            <PeopleIcon />
            {(store.surveyInfo && store.surveyInfo.recipients_responded) || "0"}/
            {(store.surveyInfo && store.surveyInfo.recipients_total) || "0"}
          </CardTitleCounter>
        </CardTitleRow>
        <CardColumn>
          <Counter $color="var(--colorPaletteGreenBackground1)">
            {(store.surveyInfo && store.surveyInfo.recipients_responded) || "0"}
          </Counter>
          <Text>participated in the survey</Text>
        </CardColumn>
        <CardColumn>
          <Counter>
            {store.surveyInfo &&
              store.surveyInfo.recipients_total - store.surveyInfo.recipients_responded}
          </Counter>
          <Text>did not participate in the survey</Text>
        </CardColumn>
      </Card>
    </Container>
  );
});

export default ParticipationCard;

const Container = styled.div`
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
  margin-bottom: 11px;
  text-transform: uppercase;
`;

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 35px 39px;
  background-color: var(--colorNeutralBackground1);
  border: 1px solid var(--colorNeutralBackground5);
  border-radius: 8px;
`;

const CardTitleRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--colorNeutralBackground5);
  padding: 0px 0px 10px 0px;
`;

const CardTitlePercent = styled.p`
  font-family: Roboto;
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-right: 10px;
`;

const CardTitleCounter = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  color: var(--colorNeutralForeground2);
  display: flex;
  align-items: center;

  svg {
    color: var(--colorNeutralForeground2);
    font-size: 18px;
    margin: 0px 4px 3px 0px;
  }
`;

const CardColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const Counter = styled.p<{ $color?: string }>`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  color: ${(props) => (props.$color ? props.$color : `var(--colorNeutralForeground5)`)};
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
`;
