export const useGetFullDayHook = (shortDay: string) => {
    let fullDay = "Monday";

    switch (shortDay.toLocaleLowerCase()) {
        case "mon":
            return (fullDay = "Monday");
        case "tue":
            return (fullDay = "Tuesday");
        case "wed":
            return (fullDay = "Wednesday");
        case "thu":
            return (fullDay = "Thursday");
        case "fri":
            return (fullDay = "Friday");
        case "sat":
            return (fullDay = "Saturday");
        case "sun":
            return (fullDay = "Sunday");
        default:
            return (fullDay = "Monday");
    }

    return fullDay;
};
