export const topLabels = `<div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
<span
  style="
    padding: 5px 9px;
    background-color: #F5F6FA;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #3e4450;
    text-transform: uppercase;
  "
  >Deprioritize</span
>
<span
  style="
    padding: 5px 9px;
    background-color: #FAEDD4;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #3e4450;
    text-transform: uppercase;
  "
  >2nd priority</span
>
</div>
`;

export const bottomLabels = `<div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
<span
  style="
    padding: 5px 9px;
    background-color: #D2E8ED;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #3e4450;
    text-transform: uppercase;
  "
  >nice-to-work-on</span
>
<span
  style="
    padding: 5px 9px;
    background-color: #F7A79E;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #3e4450;
    text-transform: uppercase;
  "
  >top priority</span
>
</div>
`;
