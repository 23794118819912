import { FC } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { Autocomplete, Chip, Container, Divider, InputAdornment, TextField } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";

import CustomButton from "components/button/Button";
import CustomModal from "components/modal/Modal";
import ReplaceQuestionsConfirm from "./MessageValid";
import store from "./../store";
import { QUESTION_TYPES } from "constants/question-types";
import { QuestionIcons } from "./QuestionTypeIcons";
import storeSurvey from "features/Survey/SurveyCreation/store";
import { SURVEY_STATUS } from "constants/survey-status";
import { QuestionType } from "components/surveyEditor/sharedTypes"
import { sm_question } from "types/surveys";

type DimensionsProps = {};

const Dimensions: FC<DimensionsProps> = observer(() => {
  const {
    openDimensionErrorMessage,
    changeOpenDimensionErrorMessage,
    QuestionDimensions,
    SurveyQuestionDimensions,
    changeDimension,
    changeCalculateScore,
  } = toJS(store);

  const isCompleteSurvey = storeSurvey.Survey?.status_idCode === SURVEY_STATUS.complete;

  const filteredDimensionList = (question: sm_question): string[] => {
    if (question.type_question_code === QuestionType.enps) {
      return QuestionDimensions.filter((el) => el.name === QuestionType.enps).map((x) => x.name);
    } else {
      return QuestionDimensions.map((x) => x.name);
    }
  };

  return (
    <div>
      <Container>
        <CustomModal
          open={openDimensionErrorMessage}
          hide={() => changeOpenDimensionErrorMessage(false)}
          maxWidth="sm"
        >
          <ReplaceQuestionsConfirm onClose={() => changeOpenDimensionErrorMessage(false)} />
        </CustomModal>

        <MainWrapper>
          <Header>Tag dimensions and set up overall score</Header>

          {QuestionDimensions.length === 0 ? (
            <>
              <DescriptionText id="Survey_Cteation_Dimensions_NotHaveQuestionDimensions_Title">
                <WrappedErrorOutlineIcon />
                You can now set up your dimensions and overall score even after you've launched your
                survey
              </DescriptionText>

              <NoQuestionsDiv>
                <NoQuestionsText id="Survey_Cteation_Dimensions_NotHaveQuestionDimensions_Message">No questions added</NoQuestionsText>

                <NoQuestionsGetStarted id="Survey_Cteation_Dimensions_NotHaveQuestionDimensions_AddQuestions">
                  Get started by adding questions on <CustomButton id="Survey_Cteation_Dimensions_NotHaveQuestionDimensions_AddQuestions_Button">1.1 Questions</CustomButton> tab
                </NoQuestionsGetStarted>
              </NoQuestionsDiv>
            </>
          ) : (
            <>
              <QuestionJustText id="Survey_Cteation_Dimensions_Title">QUESTIONS</QuestionJustText>

              <Questions>
                {SurveyQuestionDimensions.map((question, index) => {
                  return (
                    <QuestionBlock id={"Survey_Cteation_Dimensions_Question_" + index} key={question.id}>
                      <QuestionTop>
                        <QuestionDimension>
                          {question.dimension_idNavName ? (
                            <Chip
                              sx={{ backgroundColor: "#EEEFFF" }}
                              label={question.dimension_idNavName}
                              {...(!isCompleteSurvey && 
                                question.type_question_code !== QuestionType.enps &&
                                { onDelete: () => changeDimension(null, index) })}
                            />
                          ) : (
                            <>
                              <AddDimensionText id={"Survey_Cteation_Dimensions_Question_Dimensions_Select_Template_Label_" + index}>
                                Add a dimension to the question and hit enter after the text
                              </AddDimensionText>
                              <StyledAutocomplete
                                id={"Survey_Cteation_Dimensions_Question_Dimensions_Select_Template_Filter" + index}
                                options={filteredDimensionList(question)}
                                freeSolo
                                multiple
                                disabled={isCompleteSurvey}
                                renderInput={(params) => (
                                  <StyledTextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {params.InputProps.endAdornment}
                                          <InputAdornment position="end">
                                            <button
                                             id={"Survey_Cteation_Dimensions_Question_Dimensions_Select_Template_Filter_Button" + index}
                                              onClick={() => {
                                                changeDimension(
                                                  String(params.inputProps.value),
                                                  index,
                                                );
                                              }}
                                            >
                                              Enter
                                            </button>
                                          </InputAdornment>
                                        </>
                                      ),
                                    }}
                                  />
                                )}
                                renderTags={(value: string[], getTagProps) =>
                                  value.map((option: string, index: number) => (
                                    <Chip
                                      variant="outlined"
                                      label={option}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                defaultValue={
                                  question.dimension_idNavName ? [question.dimension_idNavName] : []
                                }
                                onChange={(e, value: any) => {
                                  changeDimension(value[0], index);
                                }}
                                popupIcon={<KeyboardArrowDownIcon />}
                              />
                            </>
                          )}
                        </QuestionDimension>
                        <QuestionCountTowards>
                          {question.type_question_code === QUESTION_TYPES.rating &&
                            question.isQuestionCondition && (
                              <TextInsteadCheckbox id={"Survey_Cteation_Dimensions_Question_hasConditional_Description_" + index}>
                                This question has conditional logic and will not count to the
                                overall or dimension scores.
                              </TextInsteadCheckbox>
                            )}
                          {question.type_question_code === QUESTION_TYPES.rating &&
                            !question.isQuestionCondition && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    id={"Survey_Cteation_Dimensions_Question_OverallScore_Checkbox_" + index}
                                    checked={question.isCalculateScore}
                                    onChange={(e) => {
                                      changeCalculateScore(e.target.checked, index);
                                    }}
                                  />
                                }
                                label={<span id={"Survey_Cteation_Dimensions_Question_OverallScore_Checkbox_Label_" + index}>Count towards overall score</span>}
                              />
                            )}
                        </QuestionCountTowards>
                      </QuestionTop>
                      <Divider />
                      <QuestionText>
                        <QuestionTextIndex id={"Survey_Cteation_Dimensions_Question_Index_" + index}>{index + 1 + ". "}</QuestionTextIndex>
                        <QuestionTextName id={"Survey_Cteation_Dimensions_Question_Name_" + index}>{question.title}</QuestionTextName>
                      </QuestionText>
                      <StyledQuestionType id={"Survey_Cteation_Dimensions_Question_Type_" + index}>
                        {QuestionIcons[question.type_question_code]}
                        <QuestionTypeText id={"Survey_Cteation_Dimensions_Question_TypeText_" + index}>{question.type_question_idNavName}</QuestionTypeText>
                      </StyledQuestionType>
                    </QuestionBlock>
                  );
                })}
              </Questions>
            </>
          )}
        </MainWrapper>
      </Container>
    </div>
  );
});

export default Dimensions;

const MainWrapper = styled.div`
  margin: 100px 0;
`;

const WrappedErrorOutlineIcon = styled(ErrorOutlineIcon)`
  color: var(--colorBrandForeground1);
  margin-right: 10px;
`;

const AddDimensionText = styled.span`
  color: var(--colorNeutralForeground2);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

const StyledAutocomplete = styled(Autocomplete)`
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;

  input {
    border-radius: 2px !important;
    padding: 0 !important;
    line-height: 20px;
  }

  .MuiOutlinedInput-root {
    border-radius: 2px !important;
  }
`;

const StyledTextField = styled(TextField)`
  ::placeholder {
    color: var(--colorNeutralForeground5);
    font-weight: 400 !important;
  }
`;

const NoQuestionsDiv = styled.div`
  border: 1px solid var(--colorNeutralBackground3);
  height: 150px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const NoQuestionsGetStarted = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: var(--colorNeutralForeground2);
`;

const NoQuestionsText = styled.div`
  font-size: 22px;
  margin-bottom: 10px;
  font-weight: 500;
  color: var(--colorNeutralForeground1);
`;

const Header = styled.h1`
  font-size: 36px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0;
`;

const DescriptionText = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: center;
  background-color: white;
  font-size: 16px;
  font-weight: 400;
  margin: 20px 0;
  line-height: 36px;
  padding: 5px 20px;
  text-align: left;
  color: var(--colorNeutralForeground1);
`;

const QuestionJustText = styled.div`
  margin: 20px 0;
  font-size: 14px;
  color: var(--colorNeutralForeground2);
  font-weight: 400;
`;

const Questions = styled.div`
  margin: 50px 0;
`;

const QuestionBlock = styled.div`
  border: 1px solid var(--colorPaletteBlueBackground1);
  border-radius: 4px;
  padding: 15px;
  margin: 10px 0;
  background-color: var(--colorNeutralBackground1);
`;

const QuestionTop = styled.div`
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
`;

const QuestionDimension = styled.div`
  width: 450px;
`;

const QuestionCountTowards = styled.div`
  display: flex;
  align-items: center;
`;

const TextInsteadCheckbox = styled.span`
  width: 350px;
  color: var(--colorNeutralForeground2, #707882);
  text-align: right;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

const QuestionText = styled.div`
  margin: 20px 10px;
`;

const QuestionTextIndex = styled.span`
  font-size: 12px;
  color: var(--colorNeutralForeground2);
`;

const QuestionTextName = styled.span`
color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

const StyledQuestionType = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
`;

const QuestionTypeText = styled.span`
  margin-left: 10px;
  font-size: 14px;
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;
