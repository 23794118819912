import { observer } from "mobx-react";
import { FC } from "react";
import { Box } from "@mui/material";
import styled from "styled-components";
import empty from "assets/images/er-logo-square.png";

import {
  templ_template_comms,
} from "types/templates";
import store from "./storeSurvey";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import { TEMPLATE_TYPES } from "constants/template-types";

import erLogo from 'assets/images/er-logo-img.svg';

type TemplateItemProps = {
  template: templ_template_comms;
  index: number;
};

const TemplateItem: FC<TemplateItemProps> = observer((props) => {

  const hasFeature = (props.template?.type_id_code === null) ||
    (props.template?.type_id_code === TEMPLATE_TYPES.surveys
      && StorePermission.features.survey_template.hasFeature) ||
    (props.template?.type_id_code === TEMPLATE_TYPES.milestones
      && StorePermission.features.milestone_template.hasFeature) ||
    (props.template?.type_id_code === TEMPLATE_TYPES.reviews
      && StorePermission.features.review_template.hasFeature);

  return (
    <Container id={"SelectTemplateEngageRocket_" + props.index}>
      <TopContainer>
        <ImageWrapper id={"SelectTemplateEngageImageButton_" + props.index} onClick={() => {
          if (hasFeature) {
            store.selectErTemplate(props.template.id)
          }
        }}>

          <TemplateImage
            src={props.template.logo_url ? props.template.logo_url : empty}
          />
        </ImageWrapper>
        <InfoWrapper id={"SelectTemplateEngageRocketBody_" + props.index}>
          <HeaderWrapper id={"SelectTemplateEngageHeaderButton_" + props.index} onClick={() => {
            if (hasFeature) {
              store.selectErTemplate(props.template.id)
            }
          }}>
            <Header id={"SelectTemplateEngageRocketHeaderLabel_" + props.index}>{props.template.name}</Header>

          </HeaderWrapper>
          <HeaderDescription id={"SelectTemplateEngageRocketDescriptions_" + props.index}>
            <span id={"SelectTemplateEngageRocketQuestionsCount_" + props.index}>{props.template.questions_count}</span> questions, &nbsp;
            <span id={"SelectTemplateEngageRocketEstimation_" + props.index}>{props.template.estimation ? props.template.estimation : '0 + mins'}</span>
          </HeaderDescription>
          <LabelWrapper>
            <Label id={"SelectTemplateEngageRocketType_" + props.index}>{props.template.type_questionary_name}</Label>
            <LabelText id={"SelectTemplateEngageRocketRecommendedFrequency_" + props.index}>
              Recommended frequency: <span id={"SelectTemplateEngageRocketRecommenedFrequencyValue_" + props.index}>{props.template.frequency_name}</span>
            </LabelText>
          </LabelWrapper>
        </InfoWrapper>
      </TopContainer>
      <Box>
        <Description id={`SelectTemplateEngageRocketDescription_${props.index}`}>{props.template.description.length < 50 ? props.template.description : props.template.description.slice(0, 50) + "..."}</Description>
      </Box>
    </Container>
  );
});

export default TemplateItem;

const Container = styled.div`
  width: 100%;
  min-width: 435px;
  padding: 14px;
  border: 1px solid var(--colorPaletteBlueBackground1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const Description = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: var(--colorNeutralForeground2);
`;

const TopContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 122px 1fr;
  margin-bottom: 10px;
  column-gap: 10px;
`;

const ImageWrapper = styled.div`
  cursor: pointer;
  img {
    height: 100%;
    width: 122px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const TemplateImage = styled.img``;

const HeaderWrapper = styled.div`
cursor: pointer;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
`;

const Header = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorNeutralForeground1);
`;

const HeaderDescription = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: var(--colorNeutralForeground1);
`;

const LabelWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: var(--colorNeutralForeground1);
  background-color: var(--colorPaletteBlueBackground1);
  border-radius: 36px;
  padding: 0 8px;
`;

const LabelText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: var(--colorNeutralForeground2);
  margin-left: 8px;
`;
