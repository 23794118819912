import { observer } from "mobx-react";
import { FC, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Dialog, DialogContent } from "@mui/material";
import Pagination from "components/pagination/Pagination";

import store from "../../store";
import styled from "styled-components";
import ClearIcon from "@mui/icons-material/Clear";
import CustomButton from "components/button/Button";

type ResendEmailPopupProps = {};

const ResendEmailPopup: FC<ResendEmailPopupProps> = observer(() => {
  const columns: GridColDef[] = [
    {
      field: "full_name",
      headerName: "FULL NAME (PREFERRED NAME)",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.4,
      renderCell: (params) => (
        <p id={"Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Resend_Popup_Table_fullName_" + params.row.id}>
          {params.row.full_name} ({params.row.preferred_name})
        </p>
      ),
    },
    {
      field: "email",
      headerName: "EMAIL ADDRESS",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.3,
    },
  ];

  useEffect(() => {
    store.idSurvey && store.isOpenResendEmailPoup && store.getAllResendEmail(store.idSurvey);
  }, [store.idSurvey, store.isOpenResendEmailPoup]);

  useEffect(() => {
    return () => store.setData("selectedResendEmail", []);
  }, [store.isOpenResendEmailPoup]);

  return (
    <StyledDialog fullWidth maxWidth={"lg"} open={store.isOpenResendEmailPoup}>
      <CloseBtnWrapper>
        <CloseButton
          id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Resend_Popup_Close_Button"
          onClick={() => {
            store.setData("isOpenResendEmailPoup", false);
          }}
        >
          <ClearIcon />
        </CloseButton>
      </CloseBtnWrapper>
      <DialogTitle id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Resend_Popup_Title">Resend email</DialogTitle>

      <StyledDialogContent>
        <TableHeader>
          <TableHeaderText id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Resend_Popup_Table_Title">Select recipients:</TableHeaderText>
          <TableHeaderCounter id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Resend_Popup_Table_Counter">
            Selected: {store.selectedResendEmail.length} of {store.countTotal}
          </TableHeaderCounter>
        </TableHeader>
        <div style={{ width: "100%" }}>

          <StyledDataGrid
            rows={store.resendEmailsList}
            columns={columns}
            checkboxSelection
            onRowSelectionModelChange={(ids) => store.setData("selectedResendEmail", ids)}
            rowSelectionModel={store.selectedResendEmail}
            disableColumnFilter
            hideFooterPagination={true}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
            }
            localeText={{
              noRowsLabel: "No emails",
            }}
            hideFooter
          />

          <Pagination
            activePage={store.activePage}
            itemsCountPerPage={store.countPerPage}
            onChange={(value) => {
              store.setActivePage(value);
              store.getAllResendEmail(store.idSurvey);
            }}
            pageRangeDisplayed={store.countPerPage}
            totalItemsCount={store.countTotal}
            totalPages={store.countTotal / store.countPerPage}
            setCount={(value) => {
              store.setActivePage(1);
              store.setCountPerPage(Number(value));
              store.getAllResendEmail(store.idSurvey);
            }}
          />

        </div>
        <TableDescription id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Resend_Popup_Table_Description">
          Emails will only be sent to individuals who have not yet responded to the surveys. Those
          who have already responded will not receive any resend emails.
        </TableDescription>
        <ButtonWrapper>
          <ResendCustomButton
            id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Resend_Popup_ResendEmail_Button"
            onClick={store.resendEmail}
            variant="contained"
            type="submit"
            disabled={store.selectedResendEmail.length < 1}
          >
            Resend email
          </ResendCustomButton>
          <StyledCancelButton
            id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Resend_Popup_Cancel_Button"
            variant="outlined"
            onClick={() => {
              store.setData("isOpenResendEmailPoup", false);
            }}
          >
            Cancel
          </StyledCancelButton>
        </ButtonWrapper>
      </StyledDialogContent>
    </StyledDialog>
  );
});

export default ResendEmailPopup;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 660px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 0px 22px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const DialogTitle = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px;
  padding: 0px 22px 10px 22px;
`;

const CloseBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 0px 20px;
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: all 0.3s;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: var(--colorNeutralForegroundInverted1);
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 38px 0px 60px 0px;
`;

const ResendCustomButton = styled(CustomButton)`
  height: 36px;
  width: 100%;
  max-width: 130px !important;
`;

const StyledCancelButton = styled(CustomButton)`
  height: 36px;
  margin-left: 20px !important;
  border-color: var(--colorNeutralForeground5) !important;
  color: var(--colorNeutralForeground2) !important;
`;

const TableDescription = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-top: 10px;
`;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const TableHeaderText = styled.p<{ $isCounter?: boolean }>`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground2);
`;

const TableHeaderCounter = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
  text-transform: uppercase;
`;

const StyledDataGrid = styled(DataGrid)`
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 0px !important;
  height: 300px !important;
  border: none !important;

  .MuiDataGrid-columnHeaderTitleContainer {
    background-color: var(--colorPaletteBlueBackground3);
  }

  .MuiDataGrid-columnHeaders {
    background-color: var(--colorPaletteBlueBackground3);
    border-radius: 0px !important;
  }

  .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-cell:focus,
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-cell {
    cursor: pointer !important;
  }

  .Mui-selected {
    background-color: transparent !important;
  }
  .MuiCheckbox-root {
    color: var(--colorBrandForeground1) !important;
  }

  .MuiDataGrid-root {
    border: none !important;
  }
  .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .Mui-odd {
    background-color: var(--colorNeutralForeground8);
  }

  .MuiDataGrid-overlay {
    font-family: Roboto !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--colorNeutralForeground5) !important;
  }

  .MuiDataGrid-virtualScroller {
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--colorGrayForeground6);
      border-radius: 20px;
      border: 3px solid var(--colorGrayForeground6);
    }
  }
`;
