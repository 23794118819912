import { FC } from "react";

import styled from "styled-components";

type DisableTrendsScreenProps = {};

const DisableTrendsScreen: FC<DisableTrendsScreenProps> = () => {
    return (
        <Container>
            <Text>
                Trend results will be available for this project when surveys incorporate questions beyond
                open-ended questions.
            </Text>
        </Container>
    );
};

export default DisableTrendsScreen;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: var(--colorNeutralForeground2);
  max-width: 327px;
`;
