import { FC } from "react";

import RecipientsPage from "features/Dashboard/recipientsPage/RecipientsPage";

type DashboardRecipientsPageProps = {};

const DashboardRecipientsPage: FC<DashboardRecipientsPageProps> = () => {
  return <RecipientsPage />;
};

export default DashboardRecipientsPage;
