import { FC, ReactElement, useEffect } from "react";
import store from './store'
import { observer } from "mobx-react"
import Dialog from '../../../../components/modal/Modal'
import { Box } from "@mui/material";
import CustomButton from "components/button/Button";
import { RoleUser } from "types/permission";
import ChangeRolePopup from "../ChangeRole/popupChangeRole";
import styled from "styled-components";


type DeleteRoleProps = {
  id?: string;
  openPanel: boolean;
  onBtnCancelClick: () => void;
  onSaved: (id: number) => void;
  onClose: () => void;
  idRole: number;
  roles: RoleUser[];
  roleName?: string;
};

const DeleteRole: FC<DeleteRoleProps> = observer((
  props
): ReactElement => {

  useEffect(() => {
    if (!props.openPanel) {
      store.clearStore()
    } else {
      store.roleName = props.roleName
      var role = props.roles.filter(x => x.id === props.idRole)
      if (role.length !== 0) {
        store.countUsers = role[0].users.length
      }
    }
  }, [props.openPanel])


  return (
    <Dialog
    id={`EntityPermission_DeleteRolPopUp_Close_Btn`}
      open={props.openPanel}
      hide={() => { props.onClose() }}
      aria-label="Create entity modal"
    >

      <ChangeRolePopup
       id={props.id && `${props.id}_PopUpDeleteRole`}
        openPanel={store.changeRolePanel}
        roles={props.roles}
        onBtnCancelClick={() => store.changeChangeRolePanel(false)}
        isDeleteRole={true}
        countUsers={store.countUsers}
        roleName={props.roleName}
        onClose={() => store.changeChangeRolePanel(false)}
        onSaved={() => {
          store.onRoleChanged()
          store.countUsers = 0
        }}
        idRole={props.idRole}
      />

      <MainWrapper>

        <Header id={`EntityPermission_DeleteRolPopUp_Title`}>
          Are you sure you want to delete the permission role "{props.roleName}"?
        </Header>

        {store.countUsers === 0 ? "" :
          <Box>

            <Body id={`EntityPermission_DeleteRolPopUp_Body`}>
              All {store.countUsers} added admins will be removed from this role immediately. If this is their only role, they will lose access to the platform.
            </Body>

            <CustomButton
              id={`EntityPermission_DeleteRolPopUp_Change_Btn`}
              disabled={store.errornew_new_idRole !== ""}
              onClick={() => {
                store.changeChangeRolePanel(true)

              }} variant="outlined" >
              Change role of these admins first
            </CustomButton>
          </Box>}

        <ButtonsWrapper>

          <ButtonWrapper>
            <CustomButton
            id={`EntityPermission_DeleteRolPopUp_DeleteRole_Btn`}
              disabled={store.errornew_new_idRole !== ""}
              onClick={() => {
                store.deleteRoleSubmit(props.idRole, (id) => {
                  props.onSaved(id)
                })
              }} variant="contained" >
              Delete role
            </CustomButton>
          </ButtonWrapper>

          <ButtonWrapper>
            <CustomButton 
            id={`EntityPermission_DeleteRolPopUp_Cansel_Btn`}
            onClick={() => { props.onBtnCancelClick() }} variant="contained" color="inherit">
              Cancel
            </CustomButton>
          </ButtonWrapper>

        </ButtonsWrapper>

      </MainWrapper>

    </Dialog >
  );
});

const MainWrapper = styled.div`
  margin: 50px 50px 20px 50px;
`;

const Header = styled.h1`
  font-family: Roboto;
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const Body = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 20px 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 70px;
`;

const ButtonWrapper = styled.div`
  margin: 8px;
`;

export default DeleteRole;
