import { FC, ReactElement, useEffect } from "react";
import store from './store'
import { observer } from "mobx-react"
import Dialog from '../../../../components/modal/Modal'
import CustomButton from "components/button/Button";
import { RoleUser } from "types/permission";
import SelectRole from "./selectRole";
import styled from "styled-components";


type ChangeRoleProps = {
  openPanel: boolean;
  onBtnCancelClick: () => void;
  onSaved: (id: number) => void;
  onClose: () => void;
  idRole: number;
  isDeleteRole: boolean;
  roles: RoleUser[];
  countUsers?: number;
  userIds?: number[];
  roleName?: string;
  userEmail?: string;
};

const ChangeRole: FC<ChangeRoleProps> = observer((
  props
): ReactElement => {

  useEffect(() => {
    if (!props.openPanel) {
      store.clearStore()
    }
  }, [props.openPanel])

  useEffect(() => {
    store.setRoles(props.roles, props.idRole)
  }, [props.idRole])


  return (
    <Dialog
      open={props.openPanel}
      hide={() => { props.onClose() }}
      aria-label="Create workspace modal"
    >

      <MainWrapper>
        <Header id="WorkspacePermissionsRoles_CreateEditRolesRolePopUp_Title">
          {props.isDeleteRole ? <> Change role of all {props.countUsers} users?</> :
            <>Change role of {props.countUsers} users</>}
        </Header>

        <SelectRole
          data={store.roles}
          value={store.new_idRole}
          label={"Role to change to:"}
          error={store.errornew_new_idRole !== ""}
          errorText={store.errornew_new_idRole}
          name={"new_idRole"}
          onChange={(e) => {
            store.changeValue("new_idRole", e.target.value)
            const role = store.roles.filter(x => x.id === e.target.value - 0)[0]
            store.changeValue("new_idRoleName", role.roleName)
          }}
        />

        <ButtonsWrapper>

          <ButtonWrapper>
            <CustomButton
            id="WorkspacePermissionsRoles_CreateEditRolesRolePopUp_ChangeBtn"
              disabled={store.errornew_new_idRole !== ""}
              onClick={() => {
                if (store.new_idRole === 0) {
                  return store.errornew_new_idRole = "Value cannot be blank"
                }
                if (props.isDeleteRole) {
                  store.changeRoleFromRoleId(props.idRole, props.roleName, (x) => {
                    props.onSaved(x)
                  })
                } else {
                  store.changeRoleToUsers(props.userIds, props.idRole, props.countUsers, props.userEmail, props.roleName, (x) => {
                    props.onSaved(x)
                  })
                }
              }} variant="contained" >
              Proceed to change role
            </CustomButton>
          </ButtonWrapper>

          <ButtonWrapper>
            <CustomButton
            id="WorkspacePermissionsRoles_CreateEditRolesRolePopUp_CanselBtn"
            onClick={() => { props.onBtnCancelClick() }} variant="contained" color="inherit">
              Cancel
            </CustomButton>
          </ButtonWrapper>

        </ButtonsWrapper>
      </MainWrapper>
    </Dialog >
  );
});

const MainWrapper = styled.div`
  margin: 40px 40px 20px 40px;
`;

const Header = styled.h1`
  font-family: Roboto;
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0 0 30px 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 40px;
`;

const ButtonWrapper = styled.div`
  margin: 8px;
`;


export default ChangeRole;
