import React, { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import Accordion from "@mui/material/Accordion";
import DialogContent from "@mui/material/DialogContent";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { WorkspaceIcon } from "./WorkspaceIcon";
import empty from "assets/images/er-logo-square.png";
import { ChevronIconSideBar } from "./ChevronIconSideBar";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import shortStringLength from "components/shortStringLength/shortStringLength";
import store from "./StoreLayout";

type WorkspacesSideBarProps = {
  open: boolean;
  handleHide: (state: boolean) => void;
};

const WorkspacesSideBar: FC<WorkspacesSideBarProps> = observer((props) => {
  React.useEffect(() => {
    store.doLoad();
  }, []);

  return (
    <>
      <StyledDialog
        $isImpersonated={store.isImpersonated}
        open={props.open}
        onClose={() => props.handleHide(false)}
      >
        <StyledDialogContent dividers>
          {store.data.map((account, index) => (
            <AccountWrapper key={account.id}>
              <AccountName>{account.email}</AccountName>
              <WorkspacesList>
                {account.workspaces.map(
                  (workspace) =>
                    workspace && (
                      <StyledAccordion key={workspace.id}>
                        <AccordionSummary
                          expandIcon={<ChevronIconSideBar />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <WorkspaceHead>
                            <StyledWorkspaceLogo src={workspace.logo_url ?? empty} />
                            {shortStringLength(workspace.name, 45, "bottom-start")}
                          </WorkspaceHead>
                        </AccordionSummary>
                        <AccordionDetails>
                          {workspace.has_access && (
                            <EntityWrapper $entity={false}>
                              <WorkspaceIcon style={{ marginLeft: 8 }} />
                              <WorkspaceName
                                id={`WorkSpace_accountEmail_${index}`}
                                onClick={() => {
                                  store.selectWorkspace(workspace);
                                }}
                              >
                                Workspace
                                {store.currentWorkspaceId === workspace.id &&
                                  !store.currentEntityId && <StyledCheckCircleIcon />}
                              </WorkspaceName>
                            </EntityWrapper>
                          )}
                          {workspace.entities
                            .slice()
                            .sort((a, b) => a.id - b.id)
                            .map((entity) => (
                              <EntityWrapper
                                key={entity.id}
                                $entity
                                onClick={() => {
                                  if (store.currentEntityId !== entity.id) {
                                    store.selectEntity(entity, workspace);
                                  }
                                }}
                              >
                                <StyledWorkspaceLogo src={entity.logo_url ?? empty} />
                                <EntityName>
                                  {shortStringLength(entity.name, 45, "bottom-start")}
                                  {store.currentEntityId === entity.id && <StyledCheckCircleIcon />}
                                </EntityName>
                              </EntityWrapper>
                            ))}
                        </AccordionDetails>
                      </StyledAccordion>
                    )
                )}
              </WorkspacesList>
            </AccountWrapper>
          ))}
        </StyledDialogContent>
      </StyledDialog>
    </>
  );
});

export default WorkspacesSideBar;

const StyledDialog = styled(Dialog)<{ $isImpersonated: boolean }>`
  ${(props) =>
    props.$isImpersonated ? "height: calc(100vh - 112px)" : "height: calc(100vh - 72px)"};
  ${(props) => (props.$isImpersonated ? "top: 112px !important" : "top: 72px !important")};

  .MuiBackdrop-root {
    ${(props) =>
      props.$isImpersonated ? "height: calc(100vh - 112px)" : "height: calc(100vh - 72px)"};
    ${(props) => (props.$isImpersonated ? "top: 112px !important" : "top: 72px !important")};
  }
  .MuiPaper-root {
    width: 100%;
    height: 100%;
    max-height: none;
    max-width: 362px;
    margin: 0;
    border-radius: 0;
  }
  .MuiDialog-container {
    justify-content: flex-start;
  }
  .MuiDialogContent-root {
    padding: 0;
    border: none;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const AccountWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const AccountName = styled.span`
  width: 100%;
  padding: 10px 24px;
  background-color: var(--colorPaletteVioletBackground3);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralBackground1);
`;

const WorkspacesList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 16px 48px 16px;

  .MuiPaper-root {
    border: none;
    box-shadow: none;

    &:before {
      display: none;
    }
  }

  .MuiButtonBase-root {
    padding: 0;
  }

  .MuiAccordionDetails-root {
    padding: 8px 0;
  }

  .MuiButtonBase-root {
    border-bottom: 1px solid var(--colorPaletteBlueBackground1);
  }
`;

const StyledAccordion = styled(Accordion)``;

const WorkspaceHead = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralBackgroundInverted3);
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
  }
`;

const StyledWorkspaceLogo = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 4px;
`;

const EntityWrapper = styled.div<{ $entity: boolean }>`
  width: 100%;
  min-height: 56px;
  padding: 8px 8px 8px 16px;
  box-shadow: 0 2px 8px 0 var(--colorNeutralBackground3);
  margin-top: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  background-color: var(--colorNeutralBackground1);
  transition: background-color 0.3s ease;

  svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: var(--colorPalleteLightBlue);
  }
`;

const EntityName = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 8px;
`;

const WorkspaceName = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorPaletteVioletBackground3);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 8px;
`;

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  width: 18px !important;
  height: 18px !important;
  margin-left: 5px;
  color: var(--colorBrandForeground1);
`;
