import { makeAutoObservable } from "mobx";
import { getUsersGroupedByRoles } from "../../../api/go/useGetUsersGroupedByRoles";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { InvitedUser, RoleUser, User } from "types/permission";
import MainStore from "MainStore";
import { ACCESS_LEVEL_TYPE } from "constants/access-level-type";

class Store {
  data: RoleUser[] = [];
  invitedUsers: InvitedUser[] = [];
  selectedData = [];
  searchedValue = "";
  resultSearchValue = "";
  addEditRoleNamePanel = false;
  changeRolePanel = false;
  deleteRolePanel = false;
  inviteUsersPanel = false;
  removeFromRolePanel = false;
  inviteAgainPanel = false;
  checkEmployeePanel = false;
  currentRoleId = 0;
  currentRoleName = "";
  currentUser: User = null;
  currentUserId: number = 0;
  tabId: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  changeValue = (field: string, value: any) => {
    this[field] = value;
  };

  changeAddEditRoleNamePanel = (flag: boolean) => {
    this.addEditRoleNamePanel = flag;
    if (!flag) {
      this.currentRoleId = 0;
      this.currentRoleName = "";
    }
  };

  changeDeleteRolePanel = (flag: boolean) => {
    this.deleteRolePanel = flag;
    if (!flag) {
      this.currentRoleId = 0;
    }
  };

  changeCheckEmployeePanel = (flag: boolean) => {
    this.checkEmployeePanel = flag;
    if (!flag) {
      this.currentUserId = 0;
    }
  };

  changeRemoveFromRolePanel = (flag: boolean) => {
    this.removeFromRolePanel = flag;
    if (!flag) {
      this.currentRoleId = 0;
      this.currentRoleName = "";
      this.selectedData = [];
    }
  };

  changeChangeRolePanel = (flag: boolean) => {
    this.changeRolePanel = flag;
    if (!flag) {
      this.currentRoleId = 0;
    }
  };

  changeInviteUsersPanel = (flag: boolean) => {
    this.inviteUsersPanel = flag;
    if (!flag) {
      this.currentRoleId = 0;
    }
  };

  changeInviteAgainPanel = (flag: boolean) => {
    this.inviteAgainPanel = flag;
    if (!flag) {
      this.currentRoleId = 0;
    }
  };

  onSaved = () => {
    this.changeAddEditRoleNamePanel(false);
    this.loadData();
  };

  onRoleChanged = () => {
    this.changeChangeRolePanel(false);
    this.loadData();
  };
  onRemovedFromRole = () => {
    this.changeRemoveFromRolePanel(false);
    this.loadData();
  };

  onRoleDeleted = () => {
    this.changeDeleteRolePanel(false);
    this.loadData();
    this.changeValue("tabId", 0);
  };

  onIvitedUsers = () => {
    this.changeInviteUsersPanel(false);
    this.loadData();
  };

  onInvitedAgain = () => {
    this.changeInviteAgainPanel(false);
    this.loadData();
  };

  keyPress = (e: any) => {
    if (e.keyCode == 13) {
      // on enter clicked
      this.onSearchClicked();
    }
  };

  onSearchClicked = () => {
    this.resultSearchValue = this.searchedValue;
    this.searchedValue = "";
    this.loadData();
  };

  clearStore() {
    this.data = [];
    this.selectedData = [];
  }

  async loadData() {
    try {
      const response = await getUsersGroupedByRoles(
        StoreLayout.currentWorkspaceId,
        ACCESS_LEVEL_TYPE.workspace,
        this.resultSearchValue,
        StoreLayout.currentRegionId
      );
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        this.data = response.data.roles;
        this.invitedUsers = response.data.inviteds;
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }
}

const store = new Store();
export default store;
