import { FC, ReactElement } from "react";
import store from "./store";
import { observer } from "mobx-react";
import CustomButton from "components/button/Button";
import styled from "styled-components";
import CustomModal from "components/modal/Modal";

type CombinePopupProps = {};

const CombinePopup: FC<CombinePopupProps> = observer((props): ReactElement => {
  return (
    <CustomModal
      width="522px"
      minHeightContent={260}
      open={store.openCombinePopup}
      hide={() => store.changeCombinePopup(false)}
      aria-label="Combine segment popup"
    >
      <MainBlock>
        <Header>A segment with the same name already exists.</Header>

        <ButtonWrapper>
          <CustomButton
            disabled={store.errorName !== ""}
            onClick={() => store.save(true)}
            variant="contained"
          >
            Combine both segments
          </CustomButton>
        </ButtonWrapper>
      </MainBlock>
    </CustomModal>
  );
});

const MainBlock = styled.div`
  padding: 40px 42px;
`;

const Header = styled.div`
  font-size: 26px;
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  margin-top: 35px;
`;

export default CombinePopup;
