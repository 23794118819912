import http from "api/https";
import { Dayjs } from "dayjs";

export const SetRecepientMilestoneSchedule = async (
  survey_id: number,
  employee_id: number,
  date: string | null | Dayjs,
  schedule_type: string
): Promise<any> => {
  let data = {
    survey_id: survey_id,
    employee_id: employee_id,
    date: date,
    schedule_type: schedule_type,
  };
  if (http.isAPIGO) {
    return http.postGO("/sm/sm_survey_recepients/SetRecepientMilestoneSchedule", data);
  } else {
    return http.post("/sm_survey_recepients/SetRecepientMilestoneSchedule", data);
  }
};
