import { FC, useEffect } from "react";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import { Box, Typography } from "@mui/material";
import store from "../store";
import { observer } from "mobx-react";
import { QRCode } from "react-qrcode-logo";
import empty from "assets/images/er-logo-square.png";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";

type QRCodePopupProps = {
  closePopup: () => void;
};

const QRCodePopup: FC<QRCodePopupProps> = observer((props) => {
  useEffect(() => {
    store.getLink();
  }, []);

  useEffect(() => {
    store.getCurrentEntityLogo(StoreLayout.currentEntityId);
  }, []);

  const downloadCode = () => {
    const canvas: any = document.getElementById("Susvey_Project_AccessLinkHub_Popup_QRImage");
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = StoreLayout.currentEntityName + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <PopupWrapper id="Susvey_Project_AccessLinkHub_Popup">
      <BoxStyled>
        <Styledh2 id="Susvey_Project_AccessLinkHub_Popup_Title">Share Access Link Hub</Styledh2>
      </BoxStyled>

      <BoxStyled>
        <StyledImg
          id="Susvey_Project_AccessLinkHub_Popup_Entity_Img"
          src={store.entityLogoUrl ?? empty}
        />
        <Typography id="Susvey_Project_AccessLinkHub_Popup_WorkspaceAndEntity_name">
          {StoreLayout.currentWorkspaceName}: {StoreLayout.currentEntityName}
        </Typography>
      </BoxStyled>
      <QRBox>
        <QRImage value={store.QRCodeLink} id={"Susvey_Project_AccessLinkHub_Popup_QRImage"} />
      </QRBox>
      <BoxStyled>
        <CenterTypography id={"Susvey_Project_AccessLinkHub_Popup_QRCodeLink"}>
          {store.QRCodeLink}
        </CenterTypography>
      </BoxStyled>
      <BtnBox>
        <CustomButton
          id="Susvey_Project_AccessLinkHub_Popup_DownloadCode_Button"
          variant="outlined"
          onClick={() => {
            downloadCode();
          }}
        >
          Download QR code [PNG]
        </CustomButton>
        <CustomButton
          id="Susvey_Project_AccessLinkHub_Popup_LinkCopy_Button"
          onClick={() => {
            navigator.clipboard.writeText(store.QRCodeLink);
            store.copyMessage();
          }}
          variant="outlined"
        >
          {store.QRLincCopyMessage}
        </CustomButton>
      </BtnBox>
    </PopupWrapper>
  );
});

const PopupWrapper = styled.div`
  height: 550px;
`;
const CenterTypography = styled(Typography)`
  margin: auto !important;
  background-color: var(--colorNeutralForeground4);
  padding: 20px;
  border-radius: 5px;
`;

const Styledh2 = styled.h2`
  margin: 50px 50px 0px 70px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
`;
const BoxStyled = styled(Box)`
  margin: 20px;
  display: flex;
  justify-content: start;
  align-items: center;
`;

const QRImage = styled(QRCode)`
  size={256}
  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
  value={value}
  viewBox={"0 0 256 256"}
`;

const QRBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;
const BtnBox = styled(Box)`
  display: flex;
  justify-content: space-around;
`;

const StyledImg = styled.img`
  margin: 0px 20px 0px 70px;
  width: 50px;
  height: 50px;
`;

export default QRCodePopup;
