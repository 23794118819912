import { FC } from "react";
import { Outlet } from "react-router-dom";

import styled from "styled-components";
import SimpleHeader from "./components/simple-header/SimpleHeader";
import { observer } from "mobx-react";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";

type BackToMainLayoutWrapperProps = {};

const SimpleWrapper: FC<BackToMainLayoutWrapperProps> = observer(() => {
  return (
    <AppMainWapper>
      <SimpleHeader />
      <AppContent $isImpersonated={StoreLayout.isImpersonated}>
        <Outlet />
      </AppContent>
    </AppMainWapper>
  );
});

export default SimpleWrapper;

const AppMainWapper = styled.div`
  overflow: hidden;
`;

const AppContent = styled.div<{ $isImpersonated: boolean }>`
  width: 100%;
  ${(props) => props.$isImpersonated ? "height: calc(100vh - 112px)" : "height: calc(100vh - 72px)"};
  overflow: auto;
  position: relative;
`;
