import React from "react";
import Radio, { RadioProps } from "@mui/material/Radio";
import CustomRadioIcon from "./Icons/CustomRadioIcon";
import CustomCheckedRadioIcon from "./Icons/CustomCheckedRadioIcon";

const CustomRadioButton = (props: RadioProps) => {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<CustomCheckedRadioIcon />}
      icon={<CustomRadioIcon />}
      {...props}
    />
  );
};

export default CustomRadioButton;
