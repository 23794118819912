import { FC, useEffect } from "react";
import { Outlet } from "react-router-dom";

import styled from "styled-components";
import BackToMainLayoutHeader from "./BackToMainLayoutHeader";
import HeaderMenu from "./HeaderMenu";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";

type BackToMainLayoutWrapperProps = {};

const BackToMainLayoutWrapper: FC<BackToMainLayoutWrapperProps> = (props) => {
  

  return (
    <AppMainWapper>
      <BackToMainLayoutHeader />
      <HeaderMenu />
      <AppContent>
        <Outlet />
      </AppContent>
    </AppMainWapper>
  );
};

export default BackToMainLayoutWrapper;

const AppMainWapper = styled.div``;

const AppContent = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
  overflow: auto;
  position: relative;
`;
