import { FC } from "react";
import { observer } from "mobx-react-lite";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Autocomplete from "@mui/material/Autocomplete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

type TriggerAddRecipientsProps = {};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const TriggerAddRecipients: FC<TriggerAddRecipientsProps> = observer(() => {
  return (
    <Container>
      <StyledAccordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="Survey_Project_CreatePopup_TriggerAddRecipients_OpenButton"
        >
          <AccordionTitle id="Survey_Project_CreatePopup_TriggerAddRecipients_Title">Automated setup</AccordionTitle>
        </AccordionSummary>
        <AccordionDetails>
          <SeacrhInputsWrapper>
            <StyledAutocomplete
              disablePortal
              id="Survey_Project_CreatePopup_TriggerAddRecipients_Select_Attribute"
              onChange={(e, value: any) => {
                store.setData([], "selected_attribute_segments");
                store.setData(value, "selected_attribute");
              }}
              value={store.selected_attribute}
              options={store.entity_attributes}
              getOptionLabel={(option: any) => option.name}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} placeholder="Select attribute" />}
            />
            <p id="Survey_Project_CreatePopup_TriggerAddRecipients_Select_Equals">equals</p>
            <StyledAutocomplete
              multiple
              disableCloseOnSelect
              limitTags={0}
              id="Survey_Project_CreatePopup_TriggerAddRecipients_Select_Segment"
              onChange={(e, option: { value: string; id: number }[]) =>
                store.setData(option, "selected_attribute_segments")
              }
              renderTags={() => (
                <SelectedCounter>
                  {store.selected_attribute_segments.length} selected
                </SelectedCounter>
              )}
              value={store.selected_attribute_segments}
              options={store.selected_attribute ? store.selected_attribute.values : []}
              getOptionLabel={(option: { value: string; id: number }) => option.value}
              renderOption={(props, option: { value: string; id: number }, { selected }) => (
                <StyledOptionItem {...props}>
                  <StyledCheckBox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 2 }}
                    checked={selected}
                  />
                  {option.value}
                </StyledOptionItem>
              )}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} placeholder="Select segment" />}
              isOptionEqualToValue={(
                option: { value: string; id: number },
                value: { value: string; id: number }
              ) => option.value === value.value}
            />
          </SeacrhInputsWrapper>

          <Description id="Survey_Project_CreatePopup_TriggerAddRecipients_Select_Description">
            Employees that meet this trigger criteria will be added as recipients to running or
            upcoming surveys within this project. If an employee’s attributes change during an
            upcoming or running survey the employee will be removed.
          </Description>
          <CheckBoxContainer>
            <StyledFormControlLabel
              control={
                <StyledCheckBox
                  id="Survey_Project_CreatePopup_TriggerAddRecipients_TriggersAreNotRequired_CheckBox"
                  checked={store.automaticTriggers}
                  onChange={() => store.setData(!store.automaticTriggers, "automaticTriggers")}
                />
              }
              label={<span id="Survey_Project_CreatePopup_TriggerAddRecipients_TriggersAreNotRequired_CheckBox_Label">Automated triggers are not required for this project</span>}
            />
          </CheckBoxContainer>
        </AccordionDetails>
      </StyledAccordion>
    </Container>
  );
});

export default TriggerAddRecipients;

const Container = styled.div`
  width: 100%;
  margin: 10px 0px 30px 0px;
`;

const StyledAccordion = styled(Accordion)`
  box-shadow: none !important;
  border: 1px solid var(--colorNeutralForeground4);
`;

const AccordionTitle = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--colorNeutralForeground2);
`;

const CheckBoxContainer = styled.div`
  width: 100%;
`;

const StyledCheckBox = styled(Checkbox)`
  padding: 0px 10px !important;
  color: var(--colorBrandForeground1) !important;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  font-family: Roboto !;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);

  .MuiCheckbox-root {
    &:hover {
      background-color: transparent !important;
    }
  }
`;

const Description = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
  margin: 10px 0px;
`;

const SeacrhInputsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  p {
    margin: 0px 10px;
  }
`;

const StyledAutocomplete = styled(Autocomplete)`
  input {
    border-radius: 2px !important;
    padding: 0px !important;
    font-family: Roboto !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .MuiOutlinedInput-root {
    border-radius: 2px !important;
  }
`;

const StyledOptionItem = styled.li`
  padding: 8px !important;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const SelectedCounter = styled.p`
  margin: 0px 8px 0px 0px !important;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;
