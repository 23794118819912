
import { FC, ReactElement, useEffect } from "react";
import store from './store'
import { observer } from "mobx-react"
import Dialog from '../../../components/modal/Modal'
import CustomButton from "components/button/Button";
import styled from "styled-components";
import Table from './table'
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { ORG_STRUCTURE_CODES } from "constants/entityManagementTypes"

type GrantPermissionsProps = {
  openPanel: boolean;
  onClose: () => void;
};

const GrantPermissions: FC<GrantPermissionsProps> = observer((props): ReactElement => {

  useEffect(() => {
    if (props.openPanel == true) {
      store.getCurrentEntity(StoreLayout.currentEntityId)
      store.doLoad()
    } else {
      store.clearStore()
    }
  }, [props.openPanel])

  return (
    <Dialog
      open={props.openPanel}
      maxWidth={'md'}
      hide={() => { props.onClose() }}
      aria-label="Grant permissions modal"
    >
      <Wrapper>

        <Header id="OrganizationalStructure_PopUpzNewUnitHeads_Title">
          {store.saved_org_structure_code === ORG_STRUCTURE_CODES.manager_based ? "New managers found " : "New unit heads found "}
          <CountEmails id="OrganizationalStructure_PopUpzNewUnitHeads_Title_Count" >
            ({store.managers.length})
          </CountEmails>
        </Header>

        <HeaderWrapper>
          <Body id="OrganizationalStructure_PopUpzNewUnitHeads_Body">
            {store.saved_org_structure_code === ORG_STRUCTURE_CODES.manager_based ?
              <span id="OrganizationalStructure_PopUpzNewUnitHeads_Body_1">Select managers to grant them user access rights to the platform</span> :
              <span id="OrganizationalStructure_PopUpzNewUnitHeads_Body_2">Select unit heads to grant them user access rights to the platform</span>}
          </Body>
          <SelectedMessage id="OrganizationalStructure_PopUpzNewUnitHeads_Select">
            SELECTED : {store.selectedData.length} OF {store.managers.length}
          </SelectedMessage>
        </HeaderWrapper>

        <Table />

        <InfoText id="OrganizationalStructure_PopUpzNewUnitHeads_FooterText">
          {store.saved_org_structure_code === ORG_STRUCTURE_CODES.manager_based ?
            <span id="OrganizationalStructure_PopUpzNewUnitHeads_FooterText_1">Managers granted user access rights will receive an invitation email.</span> :
            <span id="OrganizationalStructure_PopUpzNewUnitHeads_FooterText_2">Unit heads granted user access rights will receive an invitation email.</span>}
        </InfoText>

        <CustomButton
        id="OrganizationalStructure_PopUpzNewUnitHeads_AddAsUserInvite_Btn"
          variant="contained"
          disabled={store.selectedData.length === 0}
          onClick={() => {
            store.inviteUsersSubmit()
          }}>
          + Add as user and invite
        </CustomButton>
      </Wrapper>
    </Dialog >
  );
});


const Wrapper = styled.div`
  padding: 40px 10px 20px 10px;
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`;

const Header = styled.div`
  color: var(--ER-Theme-sys-light-on-secondary-container, #3E4450);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
`;

const CountEmails = styled.span`
  color: var(--ER-Theme-sys-light-on-secondary-container, #3E4450);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
`

const Body = styled.div`
  color: var(--ER-Theme-sys-light-on-tertiary-container, #707882);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

const SelectedMessage = styled.div`
  color: var(--ER-Theme-sys-light-on-tertiary-container, #707882);
  text-align: right;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  text-transform: uppercase;
`

const InfoText = styled.div`
  color: var(--ER-Theme-sys-light-on-tertiary-container, #707882);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin: 20px 0;
`;

export default GrantPermissions;
