export const ChevronIconSideBar = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" transform="matrix(1 0 0 -1 0 28)" fill="#F5F6FA" />
      <g clip-path="url(#clip0_2001_21300)">
        <path
          d="M18.59 10.5898L14 15.1698L9.41 10.5898L8 11.9998L14 17.9998L20 11.9998L18.59 10.5898Z"
          fill="#1477F8"
        />
      </g>
      <defs>
        <clipPath id="clip0_2001_21300">
          <rect width="24" height="24" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  );
};
