import { FC, ReactElement, useEffect } from "react";
import store from './store'
import { observer } from "mobx-react"
import Dialog from '../../../../components/modal/Modal'
import { TextField } from "@mui/material";
import CustomButton from "components/button/Button";
import styled from "styled-components";

type AddEditRoleNameProps = {
  openPanel: boolean;
  onBtnCancelClick: () => void;
  onSaved: (id: number) => void;
  onClose: () => void;
  roleName: string;
  idRole: number;
};

const AddEditRoleName: FC<AddEditRoleNameProps> = observer((
  props
): ReactElement => {

  useEffect(() => {
    if(props.roleName){
      store.changeValue("name", props.roleName)
    }
  }, [props.roleName])

  useEffect(() => {
    store.changeValue("idRole", props.idRole)
  }, [props.idRole])

  useEffect(() => {
    return () => {
      store.clearStore()
    }
  }, [])

  return (
    <Dialog
      open={props.openPanel}
      hide={() => { props.onClose() }}
      aria-label="Create workspace modal"
    >
      <MainWrapper>
        <Header
         id="WorkspacePermissionsRoles_CreateEditRolesPopUp_Title"
        >
          Create custom workspace role
        </Header>

        <TextField
          id="WorkspacePermissionsRoles_CreateEditRolesPopUp_Input"
          value={store.name}
          variant="standard"
          placeholder="Role name"
          error={store.errorname !== ""}
          helperText={store.errorname}
          fullWidth
          onChange={(e) => { store.changeValue("name", e.target.value) }}
        />
        <ButtonsWrapper>

          <ButtonWrapper>
            <CustomButton
            id="WorkspacePermissionsRoles_CreateEditRolesPopUp_OkBtn"
            disabled={store.errorname !== "" || store.isCheckingDelay} onClick={() => {
              store.saveRole(props.idRole, (x) => {
                props.onSaved(x)
                store.clearStore()
              })
            }} variant="contained" >
              Ok, i'm done
            </CustomButton>
          </ButtonWrapper>

          <ButtonWrapper>
            <CustomButton
            id="WorkspacePermissionsRoles_CreateEditRolesPopUp_CanselBtn"
            onClick={() => {
              props.onBtnCancelClick()
              store.clearStore()
            }} variant="contained" color="inherit">
              Cancel
            </CustomButton>
          </ButtonWrapper>

        </ButtonsWrapper>

      </MainWrapper>

    </Dialog >
  );
});

const MainWrapper = styled.div`
  margin: 50px 50px 20px 50px;
`;

const Header = styled.h1`
  font-family: Roboto;
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px 0 30px 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 60px;
`;

const ButtonWrapper = styled.div`
  margin: 8px;
`;

export default AddEditRoleName;
