import React, { FC } from "react";
import { Dialog, Divider, Badge } from "@mui/material";
import styled from "styled-components";
import DialogContent from "@mui/material/DialogContent";
import store from "./store"
import CustomButton from "components/button/Button";
import { observer } from "mobx-react";
import { Email } from "@mui/icons-material";
import BusinessIcon from '@mui/icons-material/Business';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StarsIcon from '@mui/icons-material/Stars';
import SettingsIcon from '@mui/icons-material/Settings';
import LaunchIcon from '@mui/icons-material/Launch';
import { Link as RouterLink } from 'react-router-dom';

type CheckEmployeePopupProps = {
  openPanel: boolean;
  onChangePanel: (state: boolean) => void;
  idEmployee: number;
};

const CheckEmployeePopup: FC<CheckEmployeePopupProps> = observer((props) => {

  React.useEffect(() => {
    if (props.openPanel) {
      store.loadEmployee(props.idEmployee)
    }

  }, [props.openPanel])

  return (
    <StyledDialog
      open={props.openPanel}
      onClose={() => {
        props.onChangePanel(false);
        store.clearStore()
      }}
    >
      <StyledDialogContent dividers>

        <MainContent>
          <Header id="EntityPermissions_EntityUsers_rightPopUpSettings_Title">
            All permission settings for {store.data != null && `${store.data.preffered_name} (${store.data.full_name})`}
          </Header>

          <EntityHeader id="EntityPermissions_EntityUsers_rightPopUpSettings_Email">Email</EntityHeader>
          <RoleNameIcon><Email /><RoleName>{store.data != null && store.data.email}</RoleName></RoleNameIcon>
          <Divider />

          <EntityHeader id="EntityPermissions_EntityUsers_rightPopUpSettings_Workspace">Workspace</EntityHeader>

          {store.data != null && store.data.entities.filter(x => x.access_level_code == "workspace")[0].roles.map(role => {
            return <RoleNameIcon key={role.id}>
              {role.is_default ? <BadgeWrapper
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <BadgeStarsIcon sx={{ fontSize: 15 }} />
                }
              >
                <AccountCircleIconWrapper sx={{ fontSize: 20, }} />
              </BadgeWrapper> : <BadgeWrapper
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <BadgeSettingsIcon sx={{ fontSize: 15 }} />
                }
              >
                <BusinessIconIcon sx={{ fontSize: 20 }} />
              </BadgeWrapper>}

              <RouterLinkWrapper id="EntityPermissions_EntityUsers_rightPopUpSettings_NavigateLink" target="_blank" to={"/workspacePermissions?nav=user&idRole=" + role.id} >
                <RoleName id="EntityPermissions_EntityUsers_rightPopUpSettings_RoleName">{role.name}</RoleName>
                <LaunchIconWrapper sx={{ fontSize: 15 }} />
              </RouterLinkWrapper>
            </RoleNameIcon>
          })}

          <Divider />

          {store.data != null && store.data.entities.filter(entity => entity.access_level_code == "entity").map(entity => {
            return <div key={entity.real_id + entity.name}>
              <EntityHeader >Entity: {entity.name}</EntityHeader>
              {entity.roles.length == 0 ?
                <NoPermissionText>Employee - No permission granted</NoPermissionText> :
                entity.roles.map((role, index) => {
                  return <RoleNameIcon key={role.id}>
                    {role.is_default ? <BadgeWrapper
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={
                        <BadgeStarsIcon />
                      }
                    >
                      <AccountCircleIconWrapper sx={{ fontSize: 20, }} />
                    </BadgeWrapper> : <BadgeWrapper
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={
                        <BadgeSettingsIcon sx={{ fontSize: 15 }} />
                      }
                    >
                      <BusinessIconIcon sx={{ fontSize: 20 }} />
                    </BadgeWrapper>}

                    <RouterLinkWrapper id={`EntityPermissions_EntityUsers_rightPopUpSettings_LinkNavigate_${index}`} target="_blank" to={"/entityPermissions?nav=user&idRole=" + role.id} >
                      <RoleName id={`EntityPermissions_EntityUsers_rightPopUpSettings_RoleName_${index}`}>{role.name}</RoleName>
                      <LaunchIconWrapper sx={{ fontSize: 15 }} />
                    </RouterLinkWrapper>
                  </RoleNameIcon>
                })}
            </div>
          })}

        </MainContent>

        <ButtonWrapper>
          <StyledNextButton
            variant="contained"
            onClick={() => {
              props.onChangePanel(false)
              store.clearStore()
            }}
          >
            Ok, i'm done
          </StyledNextButton>
        </ButtonWrapper>

      </StyledDialogContent>
    </StyledDialog>
  );
})

export default CheckEmployeePopup;

const StyledDialog = styled(Dialog)`

.MuiPaper-root {
  width: 100%;
  height: 100%;
  max-height: none;
  max-width: 500px;
  margin: 0px;
  border-radius: 0px;
}
.MuiDialog-container {
  justify-content: flex-end;
}
.MuiDialogContent-root {
  padding: 0px;
  border: none;
}
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 40px 50px;
  display: flex;
  align-items: center;
`;

const MainContent = styled.div`
  margin: 80px 50px;
`;
const Header = styled.h1`
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const StyledNextButton = styled(CustomButton)`
  width: 100%;
  margin-right: 20px;
  max-width: 150px;
`;

const NoPermissionText = styled.div`
font-size: 16px;
color: black;
margin-bottom: 20px;
`;



const BadgeWrapper = styled(Badge)`
margin: 0 10px 0px 5px;
`;


const BadgeSettingsIcon = styled(SettingsIcon)`
background-color: white;
color: #6E31FF;
border-radius: 10px;
`;

const BadgeStarsIcon = styled(StarsIcon)`
background-color: white;
color: #6E31FF;
border-radius: 10px;
`;
const BusinessIconIcon = styled(BusinessIcon)`
color: #6E31FF;
`;
const AccountCircleIconWrapper = styled(AccountCircleIcon)`
color: #6E31FF;
`;


const EntityHeader = styled.div`
font-family: Roboto;
font-size: 14px;
color: gray;
margin: 20px 0 10px 0;
`;

const RoleNameIcon = styled.div`
display: flex;
align-items: center;
margin: 0 0 10px 0;
`;

const RoleName = styled.span`
&:hover {
  text-decoration: underline;
  color: blue;
};
font-family: Roboto;
font-size: 18px;
color: black;
`;

const LaunchIconWrapper = styled(LaunchIcon)`
font-size: 16px;
color: blue;
`;

const RouterLinkWrapper = styled(RouterLink)`
  display: flex;
  align-items: center;
`;

