import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react';
import store from './store'
import {
  Badge
} from '@mui/material';
import DataGrid from './table'
import { Paper } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings';
import InviteTable from './inviteTable'
import WorkIcon from '@mui/icons-material/Work';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StarIcon from '@mui/icons-material/Star';
import { AdminIcon } from '../Roles/Icons/AdminIcon';
import { DEFAULT_ROLES } from 'constants/default-roles';
import { RoleIcon } from '../Roles/Icons/RoleIcon';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
          {/* <Typography>{children}</Typography> */}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `EntityPermission_Users_Grid_Tab_${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const RoleTabs = observer(() => {

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    store.changeTabId(newValue)
    store.changeSelectedData([])
  };


  return (
    <Box sx={{ width: '100%', marginBottom: 20 }}>
      <Paper>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="scrollable" value={store.tabId} onChange={handleChange} aria-label="basic tabs example">
            <Tab label={"Invited(" + store.invitedUsers.length + ")"} {...a11yProps(0)} />

            {store.data.slice().sort((a, b) => a.id - b.id).map((role, index) => {
              return <Tab
              key={role.id} label={

                <Box display={"flex"} alignItems={"center"}>
                  {(role.isDefaultRole || role.roleCode === DEFAULT_ROLES.managers || role.roleCode === DEFAULT_ROLES.unit_heads)
                    ? <AdminIcon /> : <RoleIcon />}
                  <span id={"EntityPermissions_EntityUsers_Tabs_RoleName_Label"}>{role.roleName + "(" + role.users?.length + ")"}</span>
                </Box>
              } {...a11yProps(index + 1)} />
            })}
          </Tabs>
        </Box>
        <CustomTabPanel value={store.tabId} index={0}>

          <InviteTable />

        </CustomTabPanel>

        {store.data.slice().sort((a, b) => a.id - b.id).map((role, index) => {
          return <CustomTabPanel key={role.id} value={store.tabId} index={index + 1}>

            <DataGrid data={role} />

          </CustomTabPanel>
        })}


      </Paper>
    </Box>
  );
})

export default RoleTabs