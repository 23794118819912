import { useGetDaboardTrends } from "api/dasboard/useGetDaboardTrends";
import { DEFAULT_TRENDS_CARD } from "__mocks__/dashboard";
import { makeAutoObservable, runInAction } from "mobx";
import { TQuestion, TRequestTrendGraphData, TTrendsCard, TTrendsQuestion } from "types/dashboard";

import MainStore from "MainStore";
import { useGetDaboardTrendsQuestions } from "api/dasboard/useGetDaboardTrendsQuestions";
class Store {
    isOpenSelectQuestionPopup: boolean = false;

    activeSelectQuestionListDimension: TTrendsQuestion = null;

    cardList: TTrendsCard[] = [DEFAULT_TRENDS_CARD];

    graphDataList = [];

    msqQuestionsList = [];

    enpsQuestionsList: TQuestion[] = [];
    yesNoQuestionsList: TQuestion[] = [];
    overralQuestionsList: TQuestion[] = [];
    multipleQuestionsList: TQuestion[] = [];
    singleSelectQuestionsList: TQuestion[] = [];
    ratingQuestionsList: TQuestion[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setData = (value: any, field: string) => {
        this[field] = value;
    };

    saveChangesPopup = (
        changeIndex: number,
        field: string,
        value: any,
        activeSelectQuestionListDimension: TTrendsQuestion
    ) => {
        this.cardList = this.cardList.map((el, index) => {
            if (changeIndex === index) {
                return {
                    ...el,
                    [field]: value,
                };
            } else return el;
        });

        this.isOpenSelectQuestionPopup = false;
        this.activeSelectQuestionListDimension = activeSelectQuestionListDimension;
    };

    addNewChartCard = () => {
        this.cardList = [...this.cardList, DEFAULT_TRENDS_CARD];
    };

    removeChartCard = (indexCard: number) => {
        this.cardList = this.cardList.filter((_, index) => index !== indexCard);
    };

    changeFuulChartCardSettings = (changeIndex: number, card: TTrendsCard) => {
        this.cardList = this.cardList.map((el, index) => {
            if (changeIndex === index) {
                return card;
            } else return el;
        });
    };

    changeChartCardSettings = (changeIndex: number, field: string, value: any) => {
        this.cardList = this.cardList.map((el, index) => {
            if (changeIndex === index) {
                return {
                    ...el,
                    [field]: value,
                };
            } else return el;
        });
    };

    clearStore = () => {
        this.cardList = [DEFAULT_TRENDS_CARD];
    };

    getGraphData = async (requestGraphData: TRequestTrendGraphData, cardIndex: number) => {
        try {
            MainStore.changeLoader(true);
            const response = await useGetDaboardTrends(requestGraphData);
            if (response.status === 200 || response.status === 201) {
                runInAction(() => {
                    this.graphDataList[cardIndex] = response.data;
                });
            } else throw new Error();
        } catch (error) {
            this.graphDataList[cardIndex] = [];
            console.error(error);
        } finally {
            MainStore.changeLoader(false);
        }
    };

    getQuestions = async (project_id: number) => {
        try {
            MainStore.changeLoader(true);
            const response = await useGetDaboardTrendsQuestions(project_id);
            if (response.status === 200 || response.status === 201) {
                runInAction(() => {
                    this.enpsQuestionsList = response.data.enps;
                    this.yesNoQuestionsList = response.data.yesNo;
                    this.overralQuestionsList = response.data.overral;
                    this.multipleQuestionsList = response.data.multiple;
                    this.singleSelectQuestionsList = response.data.singleSelect;
                    this.ratingQuestionsList = response.data.rating;

                    this.cardList = this.cardList.map((el) => ({
                        ...el,
                        selectedQuestion: response.data.overral.length > 0 ? response.data.overral[0].id : [],
                    }));
                });
            } else throw new Error();
        } catch (error) {
            console.error(error);
        } finally {
            MainStore.changeLoader(false);
        }
    };
}

const store = new Store();

export default store;
