import { FC, useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import store from "./store";
import { Divider, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import PageGrid from "./PageGrid";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CustomButton from "components/button/Button";
import LeftAccordions from "./LeftAccordions";
import InfoIcon from "@mui/icons-material/Info";
import { LinkIcon } from "./components/LinkIcon";
import CustomModal from "components/modal/Modal";
import NonTestPopup from "./components/NonTestPopup";
import DeletePopup from "./components/DeletePopup";
import { useLocation } from "react-router-dom";
import { SurveyList } from "features/Survey/SurveyList";
import CreateProjectPopup from "features/Project/CreateProjectPopup/CreateProjectPopup";
import EditProjectPopup from "features/Project/EditProject/EditProject";

import QRCodePopup from "./components/QRCodePopup";
import SetupStructurePopup from "./components/ConfirmPopup";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { RemoveIcon } from "./components/RemoveIcon";
import ProjectPermissions from "./components/ProjectPermissions/ProjectPermissions";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import { toJS } from "mobx";

type ProjectListProps = {};

const ProjectList: FC<ProjectListProps> = observer(() => {
  const {
    project_id,
    QRCodeModalOpen,
    openSetupOrgStructureConfirm,
    searchedValue,
    data,
    resultSearchValue,
    disableControlsOrgStructure,
    openCreateProjectPopup,
    editSettingsPopup,
    selectedRow,
    openNonTestPopup,
    openDeletePopup,
    openPermissionsPopup,
  } = toJS(store);
  let query = useQuery();

  useEffect(() => {
    let project_id = query.get("project_id") != null ? +query.get("project_id") - 0 : 0;
    store.setProjectId(project_id);
    store.doLoad();
    store.GetSurveyTags();
  }, []);

  useEffect(() => {
    store.getCurrentEntity(StoreLayout.currentEntityId);
  }, []);

  return (
    <>
      <LeftAccordions />

      {project_id !== 0 ? (
        <SurveyList project_id={project_id} orgStructureCode={store.orgStructureCode} />
      ) : (
        <MainContent>
          <CustomModal
            id="Survey_QRCodePopup"
            open={QRCodeModalOpen}
            hide={(flag) => store.setQRModelOpen(flag)}
            children={<QRCodePopup closePopup={() => store.setQRModelOpen(false)}></QRCodePopup>}
          />
          <CustomModal
            id="Survey_Project_SetupStructurePopup"
            open={openSetupOrgStructureConfirm}
            hide={(flag) => store.setOpenModalConfirm(flag)}
            children={
              <SetupStructurePopup
                closePopup={() => store.setQRModelOpen(false)}
              ></SetupStructurePopup>
            }
          />
          <MainWrapper>
            <HeaderLefSide>
              <Header id="Survey_Title">Survey projects</Header>
              <AccessLinkButton>
                <CustomLinkButtonStyled
                  id="Survey_Project_AccessLinkHub_Button"
                  variant="outlined"
                  onClick={() => store.setQRModelOpen(true)}
                >
                  <LinkIcon />
                  Access Link Hub
                </CustomLinkButtonStyled>
              </AccessLinkButton>
              <Tooltip
                id="Survey_Project_AccessLinkHub_Info_Modal"
                placement="top"
                title="All surveys can be accessed by employees’ Unique ID through this Access Link Hub"
              >
                <InfoIcon
                  id="Survey_Project_AccessLinkHub_Info"
                  fontSize="small"
                  sx={{ color: "#959BA2" }}
                />
              </Tooltip>
            </HeaderLefSide>

            <TextFieldStyled
              id="Survey_Project_SearchProject"
              size="small"
              placeholder="Search for project/survey name"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      id="Survey_Project_SearchProject_Button"
                      onClick={() => store.onSearchClicked()}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={searchedValue}
              onKeyDown={store.keyPress}
              onChange={(e) => store.changeValue("searchedValue", e.target.value)}
            />
          </MainWrapper>

          <Divider />

          {resultSearchValue !== "" && (
            <ResultSearch id="Survey_ResultSearch">
              {data.length === 0 ? (
                <SearchText id="Survey_Project_ResultSearch_Text">
                  "
                  <SearchTextValue id="Survey_Project_ResultSearch_TextValue">
                    {resultSearchValue}
                  </SearchTextValue>
                  " No project or survey was found to match the provided details. Please try using a
                  different search keyword.
                </SearchText>
              ) : (
                <SearchText id="Survey_Project_ResultSearch_Text">
                  "
                  <SearchTextValue id="Survey_Project_ResultSearch_TextValue">
                    {resultSearchValue}
                  </SearchTextValue>
                  " can be found in the following project(s)
                </SearchText>
              )}

              <RemoveSearch>
                <CustomButtonStyled
                  id="Survey_Project_ResultSearch_Remove_Button"
                  onClick={() => {
                    store.changeValue("resultSearchValue", "");
                    store.getProjects();
                  }}
                >
                  <RemoveIcon />
                  Remove search
                </CustomButtonStyled>
              </RemoveSearch>
            </ResultSearch>
          )}

          <ProjectListWrapper>
            <ButtonsWrapper>
              <TypographyStyled id="Survey_Project_Table_Label">
                Projects created within this entity.
              </TypographyStyled>
              <ButtonWrapper>
                <CustomButton
                  id="Survey_Project_CreateNewProject_Button"
                  variant="contained"
                  disabled={
                    disableControlsOrgStructure || !StorePermission.features.surveys.hasFeature || !StorePermission.entityPermissions.createAndEditSurvey.hasPermission
                  }
                  onClick={() => store.changeOpenCreateProjectPopup(true)}
                >
                  + Create new project
                </CustomButton>
              </ButtonWrapper>
            </ButtonsWrapper>

            <CreateProjectPopup
              open={openCreateProjectPopup}
              handleHide={(reload) => {
                store.changeOpenCreateProjectPopup(false);
                if (reload === true) {
                  store.doLoad();
                }
              }}
            />
            <EditProjectPopup
              open={editSettingsPopup}
              id_project={selectedRow === null ? 0 : selectedRow.id}
              handleHide={(reload) => {
                store.changeEditSettingsPopup(false);
                if (reload === true) {
                  store.doLoad();
                }
              }}
            />

            <PageGrid />
          </ProjectListWrapper>

          <CustomModal
            open={openNonTestPopup}
            hide={(flag) => store.openClickNonTestPopup(flag)}
            children={<NonTestPopup closePopup={() => store.openClickNonTestPopup(false)} />}
          />

          <CustomModal
            open={openDeletePopup}
            width="522px"
            hide={(flag) => {
              store.openClickDeletePopup(flag);
              store.clearDeleteValues();
            }}
            children={
              <DeletePopup
                closePopup={() => {
                  store.openClickDeletePopup(false);
                  store.clearDeleteValues();
                }}
              />
            }
          />

          <CustomModal
            open={openPermissionsPopup}
            maxWidth="md"
            hide={(flag) => store.openClickPermissionsPopup(flag)}
            children={
              <ProjectPermissions
                projectName={selectedRow?.name}
                projectId={selectedRow?.id}
                closePopup={() => store.openClickPermissionsPopup(false)}
              />
            }
          />
        </MainContent>
      )}
    </>
  );
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default ProjectList;

const MainContent = styled.div`
  margin: 0 50px 150px 250px;
  position: relative;
`;

const MainWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  padding-top: 50px;
  justify-content: space-between;
`;

const ResultSearch = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: var(--colorPaletteBlueBackground3);
  margin-top: 12px;
  height: 36px;
  position: absolute;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  background-color: #f5feff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px;
`;

const SearchText = styled.div`
  margin-left: 16px;
`;

const SearchTextValue = styled.span`
  font-weight: 500;
`;

const RemoveSearch = styled.div`
  margin-right: 24px;
`;

const HeaderLefSide = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.div`
  color: var(--colorNeutralForeground1);
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;

const AccessLinkButton = styled.div`
  margin-left: 15px;
  margin-right: 15px;
`;

const TypographyStyled = styled(Typography)`
  color: #707882;
  margin-left: 20px !important;
`;

const CustomButtonStyled = styled(CustomButton)`
  display: flex;
  align-items: center;
`;

const CustomLinkButtonStyled = styled(CustomButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 5px 12px;
  height: 34px;
`;

const TextFieldStyled = styled(TextField)`
  width: 300px;

  .MuiInputBase-root {
    padding-right: 8px !important;
  }
`;

const ProjectListWrapper = styled.div`
  position: relative;
  top: 60px;
`;
