import { makeAutoObservable } from "mobx";
import { inviteUsersToRole } from "../../../../api/go/useInviteUsersToRole";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import MainStore from "MainStore";
import { ACCESS_LEVEL_TYPE } from "constants/access-level-type";

class Store {
  emails: string[] = [];
  value: string;

  constructor() {
    makeAutoObservable(this);
  }

  changeFieldValue = (value: string) => {
    this.value = value;
  };

  changeValue = (name: string, value: any) => {
    this[name] = value;
  };

  addEmail = (email?: string) => {
    this.emails = [...this.emails, this.value];
    this.value = "";
  };

  changeEmail = (email: string[]) => {
    this.emails = email;
  };

  deleteEmail = (index: number) => {
    this.emails = [...this.emails.slice(0, index), ...this.emails.slice(index + 1)];
  };

  clearStore() {}

  inviteUserSubmit = async (idRole: number, onSaved: () => void) => {
    try {
      MainStore.changeLoader(true);
      const response = await inviteUsersToRole(
        idRole,
        StoreLayout.currentEntityId,
        this.emails,
        ACCESS_LEVEL_TYPE.entity,
        StoreLayout.currentRegionId
      );
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        onSaved();
        MainStore.changeLoader(false);
      } else {
        MainStore.setSnackbar(response.response.data?.error, "error");
        throw new Error(response?.response.status || "");
      }
    } catch (err) {
      if (err.message !== "422") {
        MainStore.setSnackbar("Something went wrong!", "error");
      }
    } finally {
      MainStore.changeLoader(false);
    }
  };
}

const store = new Store();
export default store;
