import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import AttributeTable from "./tableAtributes";
import CustomModal from "components/modal/Modal";
import { EditIcon } from "./components/EditIcon";
import EditSegmentName from "./EditSegment/popup";
import CustomButton from "components/button/Button";
import { DeleteIcon } from "./components/DeleteIcon";
import DeleteSegmentsPopup from "./DeleteSegmentsPopup";
import { NOT_EDITABLE_ATTRIBUTES } from "constants/attribute-types";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import store from "./storeSegments";
import storeAttribute from "./store";
import CombinePopup from "./EditSegment/combinePopup";
import NavStore from "./../store";

const SegmentsSide: FC<any> = observer((props) => {
  const disabledEdit =
    store.attribute === null ||
    store.selected.length !== 1 ||
    store.attribute.is_manager_based ||
    store.attribute.is_unit_heads_emails ||
    store.attribute.name === NOT_EDITABLE_ATTRIBUTES.start_date_tenure ||
    store.attribute.name === NOT_EDITABLE_ATTRIBUTES.tenure ||
    store.attribute.is_unit_based ||
    store.openEditSegment !== null;

  const disabledDelete =
    store.attribute === null ||
    store.selected.length === 0 ||
    store.attribute.is_manager_based ||
    store.attribute.is_unit_heads_emails ||
    store.attribute.name === NOT_EDITABLE_ATTRIBUTES.start_date_tenure ||
    store.attribute.name === NOT_EDITABLE_ATTRIBUTES.tenure ||
    store.attribute.is_unit_based ||
    store.openEditSegment !== null;


  return (
    <SegmentWrapper>
      <SegmentsHeader>
        <SegmentsHeaderLeft id="Attribute_AttributeList_Segments_Title">
          {storeAttribute.selected?.name && (
            <Header id="Attribute_AttributeList_Segments_Title_Segments">
              {storeAttribute.selected?.name}
            </Header>
          )}
          <SubHeader2 id="Attribute_AttributeList_Segments_Title_Segments">segments</SubHeader2>
          <SubHeader id="Attribute_AttributeList_SegmentsSubtitle">
            Segments are created based on data input on the employee list.
          </SubHeader>
        </SegmentsHeaderLeft>
        <SegmentsHeaderRight>
          <StyledTextField
            id="Attribute_AttributeList_Segments_SearchInput"
            size="small"
            placeholder="Search for segment"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    id="Attribute_AttributeList_Segments_SearchInput_Btn"
                    onClick={() => store.onSearchClicked()}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={store.searchedValue}
            onKeyDown={(e) => e.keyCode === 13 && store.onSearchClicked()}
            onChange={(e) => store.changeValue("searchedValue", e.target.value)}
          />
        </SegmentsHeaderRight>
      </SegmentsHeader>

      {store.resultSearchValue !== "" && (
        <ResultSearch>
          {store.data.length === 0 ? (
            <SearchText id="Attribute_AttributeList_Segments_SearchInput_SearchText">
              "<SearchTextValue>{store.resultSearchValue}</SearchTextValue>" No segments was found
              to match the provided details. Please try using a different search keyword.
            </SearchText>
          ) : (
            <SearchText id="Attribute_AttributeList_Segments_SearchInput_SearchText">
              "<SearchTextValue>{store.resultSearchValue}</SearchTextValue>" can be found in the
              following segments
            </SearchText>
          )}

          <RemoveSearch>
            <CustomButton
              id="Attribute_AttributeList_Segments_SearchRemove_Btn"
              onClick={() => {
                store.changeValue("resultSearchValue", "");
                store.onSearchClicked();
              }}
            >
              Remove search
            </CustomButton>
          </RemoveSearch>
        </ResultSearch>
      )}

      <ButtonsWrapper>
        {!StoreLayout.lockEdit && (
          <>
            <BtnWrapp>
              <CustomButton
                id="Attribute_AttributeList_Segments_Edit_Btn"
                disabled={disabledEdit || (NavStore.importLock ?? false)}
                onClick={() => store.setOpenEditSegment()}
              >
                <EditIcon color={disabledEdit ? "#00000042" : "#1477F8"} />
                Edit
              </CustomButton>
            </BtnWrapp>

            <Splitter />

            <BtnWrapp>
              <CustomButton
                id="Attribute_AttributeList_Segments_Delete_Btn"
                disabled={disabledDelete || (NavStore.importLock ?? false)}
                onClick={() => store.setDeleteRow()}
              >
                <DeleteIcon color={disabledDelete ? "#00000042" : "#1477F8"} />
                Delete
              </CustomButton>
            </BtnWrapp>
          </>
        )}
      </ButtonsWrapper>

      <AttributeTable />

      <EditSegmentName />

      <CombinePopup />

      <CustomModal
        id="Attribute_AttributeList_Segments_PopUpDelete"
        open={store.deleteSegmentsPopup}
        hide={(flag) => store.changeDeleteSegmentsPopup(false)}
        children={<DeleteSegmentsPopup />}
      />
    </SegmentWrapper>
  );
});

export default SegmentsSide;

const StyledTextField = styled(TextField)`
  background-color: var(--colorNeutralBackground1);
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`;

const BtnWrapp = styled.div`
  margin: 0 4px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const ResultSearch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--colorPaletteBlueBackground3);
  margin-top: 24px;
  height: 50px;
`;

const SearchText = styled.div`
  margin-left: 16px;
`;
const SearchTextValue = styled.span`
  font-weight: 500;
`;

const RemoveSearch = styled.div`
  margin-right: 24px;
`;

const Splitter = styled.div`
  width: 1px;
  height: 33px;
  background: var(--colorPaletteBlueBackground1);
`;

const SegmentWrapper = styled.div`
  background-color: var(--colorNeutralBackground4);
  padding-right: 40px;
  width: 100%;
  height: 100%;
  padding-top: 80px;
  padding-left: 40px;
`;

const SegmentsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SegmentsHeaderLeft = styled.div``;

const SegmentsHeaderRight = styled.div``;

const Header = styled.p`
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  display: inline;
  color: var(--colorNeutralForeground1);
  margin: 0px;
  margin-right: 10px;
`;

const SubHeader2 = styled.p`
  display: inline;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--colorNeutralForeground1);
`;

const SubHeader = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  color: var(--colorNeutralForeground2);
`;
