import { FC, useEffect } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import Autocomplete from "@mui/material/Autocomplete";
import { Accordion, AccordionSummary, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ClearIcon } from "@mui/x-date-pickers";

import store from "./store";
import ChevronCircleIcon from "./ChevronCircleIcon";
import CustomButton from "components/button/Button";
import { TRecipientsFilter } from "types/recipients";
import { MultipleSelect } from "components/multiselect/MultiSelect";
import { ORG_STRUCTURE_CODES } from "constants/entityManagementTypes";
import storeEmployees from "features/Employees/store";

type FiltersProps = {};

const Filters: FC<FiltersProps> = observer(() => {
  const idEntity = localStorage.getItem("idEntity");

  useEffect(() => {
    store.loadAttributes();
    storeEmployees.getCurrentEntity(Number(idEntity));
  }, []);

  const getAttributesOptions = (index: number) => {
    return store.Attributes.map((x) => x.name).filter((attributeName) => {
      const attributeValues =
        store.Attributes.find((attr) => attr.name === attributeName)?.values || [];
      const selectedValues = store.filters
        .filter((_, filterIndex) => filterIndex !== index)
        .reduce((acc, filter) => {
          if (filter.select_attribute === attributeName) {
            return acc.concat(filter.select_segments);
          }
          return acc;
        }, []);
      const isAttributeFullySelected = attributeValues?.every((attrValue) =>
        selectedValues.includes(attrValue.value)
      );
      return !isAttributeFullySelected;
    });
  };

  const getSegmentOptions = (filter: TRecipientsFilter, index: number) => {
    return filter.select_attribute
      ? store.Attributes.find((x) => x.id > 0 && x.name === filter.select_attribute)
          ?.values?.map((x) => x.value)
          .filter(
            (option) =>
              !store.filters.some((otherFilter, otherIndex) => {
                return (
                  index !== otherIndex &&
                  otherFilter.select_attribute === filter.select_attribute &&
                  otherFilter.select_segments.includes(option)
                );
              })
          ) || []
      : [];
  };

  const segmentValue = (filter: TRecipientsFilter) =>
    filter.select_segments.length > 1
      ? filter.select_segments.length + " segments"
      : filter.select_segments[0];

  const removeVerification =
    store.filters.length === 1 &&
    !store.filters[0].select_attribute &&
    !store.filters[0].select_segments.length;

  const addFilterVerification =
    store.filters.length < 6 &&
    store.filters[0].select_attribute &&
    store.filters[0].select_segments.length > 0;

  const applyVerification =
    !store.filters[store.filters.length - 1].select_attribute ||
    !store.filters[store.filters.length - 1].select_segments.length;

  const placeholderSelects =
    storeEmployees.saved_org_structure_code === ORG_STRUCTURE_CODES.unit_based
      ? "Select unit or"
      : "Select";

  return (
    <Container>
      <AccordionSummary
        style={{ minHeight: "43px", height: "43px" }}
        expandIcon={<ChevronCircleIcon />}
        aria-controls="panel1a-content"
        id="Recipients_List_FilterRecipients_Panel"
      >
        <Header id="Survey_EditCreate_Recipietns_Import_Filter_Title">Filter by segments</Header>
      </AccordionSummary>
      <FiltersList>
        {store.filters.map((filter, index) => (
          <>
            <FilterItem key={index}>
              <StyledAutocomplete
                id="Survey_EditCreate_Recipietns_Import_Filter_Select_Attribute"
                options={getAttributesOptions(index)}
                renderInput={(params) => (
                  <StyledTextField {...params} placeholder={placeholderSelects + " attribute"} />
                )}
                value={filter.select_attribute}
                onChange={(_, value: string) => {
                  store.changeFilterValues(index, "select_attribute", value);
                }}
                popupIcon={<KeyboardArrowDownIcon />}
              />
              <StyledMultipleSelect
                options={getSegmentOptions(filter, index)}
                value={segmentValue(filter)}
                selectedList={filter.select_segments}
                onChange={store.changeFilterValues}
                name="select_segments"
                index={index}
                placeholder={placeholderSelects + " segment"}
              />
              {!removeVerification && (
                <RemoveFilterButton
                  id="Survey_EditCreate_Recipietns_Import_Filter_RemoveFilter_Button"
                  onClick={() => {
                    store.removeFilter(index);
                    store.getAllEmployees();
                  }}
                >
                  Remove
                </RemoveFilterButton>
              )}
            </FilterItem>
            <StyledViewFilters>
              {filter.select_segments.length > 0 &&
                filter.select_segments.map((segment, indexSegment) => (
                  <StyledSegmentsWrapper key={indexSegment}>
                    <p>{segment}</p>
                    <CustomButton onClick={() => store.removeSegment(index, indexSegment)}>
                      <ClearIcon />
                    </CustomButton>
                  </StyledSegmentsWrapper>
                ))}
            </StyledViewFilters>
          </>
        ))}
        <ButtonsWrap>
          {addFilterVerification && (
            <AddFilterButton
              id="Survey_EditCreate_Recipietns_Import_Filter_AddFilter_Button"
              onClick={() => store.addFilter()}
            >
              + Add filter
            </AddFilterButton>
          )}
          <CustomButton
            id="Survey_EditCreate_Recipietns_Import_Filter_AddFilter_Button"
            variant="outlined"
            disabled={applyVerification}
            onClick={() => {
                store.getAllEmployees(); 
                store.setResetSelectAllEmployee();
              }
            }
            style={{ backgroundColor: "var(--colorNeutralBackground1)" }}
          >
            Apply filter
          </CustomButton>
        </ButtonsWrap>
      </FiltersList>
    </Container>
  );
});

export default Filters;

const Container = styled(Accordion)`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px !important;
  border: 1px solid var(--colorPaletteBlueBackground1) !important;
  background: rgba(255, 255, 255, 0.2) !important;
  box-shadow: none !important;
  margin-top: 20px !important;
`;

const Header = styled.span`
  color: var(--colorNeutralForeground2);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  font-style: normal;
`;

const FiltersList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const StyledViewFilters = styled.div`
  width: 500px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const StyledSegmentsWrapper = styled.div`
  border-radius: 4px;
  background: var(--colorPaletteBlueBackground2);
  padding: 2px 8px;
  width: max-content;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  button {
    padding: 0;
    min-width: 0;
    color: var(--colorNeutralForeground1);
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const StyledAutocomplete = styled(Autocomplete)`
  max-width: 225px;
  width: 100% !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  border: none !important;

  input {
    border-radius: 2px !important;
    padding: 0 !important;
    font-family: Roboto, sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    height: 16px;
  }
  .MuiOutlinedInput-root {
    border-radius: 2px !important;
  }
`;
const StyledMultipleSelect = styled(MultipleSelect)`
  max-width: 225px;
  width: 100% !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;

  input {
    border-radius: 2px !important;
    padding: 0 !important;
    font-family: Roboto, sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .MuiOutlinedInput-root {
    border-radius: 2px !important;
  }
`;

const ButtonsWrap = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
`;

const FilterItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;

const RemoveFilterButton = styled(CustomButton)`
  white-space: nowrap;
  width: fit-content !important;
  padding: 0 !important;
  justify-content: flex-start !important;
  color: var(--colorBrandForeground1) !important;
  margin-left: 10px !important;

  &:hover {
    background-color: transparent !important;
    text-decoration: underline !important;
  }

  svg {
    font-size: 20px;
  }
`;

const StyledTextField = styled(TextField)`
  ::placeholder {
    color: var(--colorNeutralForeground5);
    font-weight: 400 !important;
    font-family: Roboto, sans-serif !important;
  }
`;

const AddFilterButton = styled(CustomButton)`
  padding-left: 0 !important;
`;
